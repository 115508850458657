import React from "react";
import { FCC } from "utils/models";

interface IColorCircleLabelText {
  color: string;
}

const ColorCircleLabelText: FCC<IColorCircleLabelText> = ({
  color,
  children,
}) => {
  return (
    <>
      <span
        style={{
          position: "relative",
          paddingLeft: "25px",
        }}>
        <div
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: color,
            borderRadius: "100%",
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
        {children}
      </span>
    </>
  );
};

export default ColorCircleLabelText;
