import React, { useEffect, useRef, useState } from "react";
import mapStyleJson from "./mapStyle.json";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "./MapWrapper";
import { FCC } from "utils/models";

interface IInnerMap {
  center: google.maps.LatLngLiteral;
  zoom: number;
  mapCallback: (map: google.maps.Map) => void;
}

const InnerMap: FCC<IInnerMap> = (props) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [google, setGoogle] = useState<null | any>(null);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  const options: google.maps.MapOptions = {
    center: props.center,
    zoom: props.zoom,
    styles: mapStyleJson,
    fullscreenControl: false,
    zoomControl: false,
  };

  useEffect(() => {
    if (mapRef.current && google) {
      const map = new google.maps.Map(mapRef.current, options);
      props.mapCallback(map);
      setMap(map);
    }
  }, [google]);

  useEffect(() => {
    if (!map) return;
    if (map.getZoom() !== props.zoom) map.setZoom(props.zoom);
    map.setCenter(props.center); // todo center value compare
  }, [props.zoom, props.center, map]);

  return (
    <div
      ref={mapRef}
      style={{ height: "700px", borderRadius: "10px" }}
      id="map">
      {props.children}
    </div>
  );
};

export default InnerMap;
