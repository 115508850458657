import ActivitiesAdd from "views/datasets/components/activitiesAdd/ActivitiesAdd";
import ActivitiesEdit from "views/datasets/components/activitiesEdit/ActivitiesEdit";
import ActivitiesList from "views/datasets/components/activitiesList/ActivitiesList";
import AddressesList from "views/datasets/components/addressesList/AddressesList";
import ChainsAdd from "views/datasets/components/chainsAdd/ChainsAdd";
import ChainsEdit from "views/datasets/components/chainsEdit/ChainsEdit";
import ChainsList from "views/datasets/components/chainsList/ChainsList";
import QuestionsAdd from "views/datasets/components/questionsAdd/QuestionsAdd";
import QuestionsEdit from "views/datasets/components/questionsEdit/QuestionsEdit";
import LocationsList from "views/datasets/components/locationsList/LocationsList";
import QuestionsList from "views/datasets/components/questionsList/QuestionsList";
import { IRouteEntity } from "../../utils/models";
import LocationAdd from "../../views/datasets/components/location/LocationAdd";
import LocationEdit from "../../views/datasets/components/location/LocationEdit";
import AddressAdd from "views/datasets/components/addressAdd/AddressAdd";

const datasetRoutes: IRouteEntity[] = [
  {
    path: "/datasets",
    breadcrumb: "Zbiory danych",
    redirectTo: "/datasets/addresses",
  },
  {
    path: "/datasets/locations",
    breadcrumb: "Lokalizacje",
    Component: LocationsList,
  },
  {
    path: "/datasets/locations/add",
    breadcrumb: "Dodaj lokalizację",
    Component: LocationAdd,
  },
  { path: "/datasets/locations/:locationId", breadcrumb: undefined },
  {
    path: "/datasets/locations/:locationId/edit",
    breadcrumb: "Edytuj lokalizację",
    Component: LocationEdit,
  },
  {
    path: "/datasets/addresses",
    breadcrumb: "Adresy",
    Component: AddressesList,
  },
  {
    path: "/datasets/addresses/add",
    breadcrumb: "Dodaj adres",
    Component: AddressAdd,
  },
  {
    path: "/datasets/addresses/add-for-entity/:hash",
    breadcrumb: "Dodaj adres (powiązany z obiektem)",
    Component: AddressAdd,
  },
  { path: "/datasets/addresses/:id", breadcrumb: undefined },
  {
    path: "/datasets/addresses/:id/edit",
    breadcrumb: "Edytuj adres",
    Component: AddressAdd,
  },
  {
    path: "/datasets/addresses/:id/edit-for-entity/:hash",
    breadcrumb: "Edytuj adres (powiązany z obiektem)",
    Component: AddressAdd,
  },
  {
    path: "/datasets/activity",
    breadcrumb: "Aktywności",
    Component: ActivitiesList,
  },
  {
    path: "/datasets/activity/add",
    breadcrumb: "Dodaj aktywność",
    Component: ActivitiesAdd,
  },
  { path: "/datasets/activity/:id", breadcrumb: undefined },
  {
    path: "/datasets/activity/:id/edit",
    breadcrumb: "Edytuj aktywność",
    Component: ActivitiesEdit,
  },
  { path: "/datasets/networks", breadcrumb: "Sieci", Component: ChainsList },
  {
    path: "/datasets/networks/add",
    breadcrumb: "Dodaj sieć",
    Component: ChainsAdd,
  },
  { path: "/datasets/networks/:id", breadcrumb: undefined },
  {
    path: "/datasets/networks/:id/edit",
    breadcrumb: "Edytuj sieć",
    Component: ChainsEdit,
  },
  {
    path: "/datasets/questions",
    breadcrumb: "Pytania",
    Component: QuestionsList,
  },
  {
    path: "/datasets/questions/add",
    breadcrumb: "Dodaj pytanie",
    Component: QuestionsAdd,
  },
  { path: "/datasets/questions/:id", breadcrumb: undefined },
  {
    path: "/datasets/questions/:id/edit",
    breadcrumb: "Edytuj pytanie",
    Component: QuestionsEdit,
  },
];
export default datasetRoutes;
