import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateCategory } from "../../../../utils/models";
import { createCategoryState } from "../../../../utils/states";
import { useHistory, useParams } from "react-router-dom";
import CategoryConnection from "../../../../utils/connections/categories";
import { handleToast } from "../../../../utils/helpers";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Form from "../../../../global/form/Form";
import Input from "../../../../global/atoms/Input";
import Select from "../../../../global/atoms/Select";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";

interface IAddCategory {}

const AddCategory: FunctionComponent<IAddCategory> = () => {
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ICreateCategory>(createCategoryState);

  if (id !== undefined) {
    // edit
    useEffect(() => {
      CategoryConnection.getFormEditCategory(id).then((data) => {
        setData({
          customerId: data.data.model.customer.id,
          name: data.data.model.name,
        });
        setForm(data.data);
      });
    }, []);
  } else {
    // new
    useEffect(() => {
      CategoryConnection.getFormAddCategory().then((data) => {
        const ccs = createCategoryState;
        const formData = data.data;
        ccs.customerId = formData.customers.options[0].id; // default customer id - organizacja
        setForm(formData);
        setData({
          ...ccs,
        });
      });
    }, []);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (id !== undefined) {
      handleToast(CategoryConnection.updateCategory(id, data), setErrors).then(
        () => history.push("/products/category")
      );
    } else {
      handleToast(CategoryConnection.createCategory(data), setErrors).then(() =>
        history.push("/products/category")
      );
    }
  };

  if (form === "loading") return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Nazwa"}
            name={"name"}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={"Klient"}
            name={"customerId"}
            value={
              data.customerId ? data.customerId : form.customers.value ?? ""
            }
            options={form.customers.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default AddCategory;
