import React, { FunctionComponent, useState } from "react";
import { Nav } from "rsuite";
import ContractProjectDocumentAssignedList from "./ContractProjectDocumentAssignedList";
import ContractProjectDocumentUnAssignedList from "./ContractProjectDocumentUnAssignedList";

interface IContractProjectDocumentNav {}

const Notifications: FunctionComponent<IContractProjectDocumentNav> = () => {
  const VIEW_ASSIGNED_LIST = "view_assigned_list";
  const VIEW_UNASSIGNED_LIST = "view_unassigned_mailer";
  const [view, setView] = useState<
    "view_assigned_list" | "view_unassigned_mailer"
  >(VIEW_ASSIGNED_LIST);

  const handleChangeView = (
    view: "view_assigned_list" | "view_unassigned_mailer"
  ) => {
    setView(view);
  };

  return (
    <>
      <Nav
        appearance={"default"}
        activeKey={view}
        onSelect={handleChangeView}
        style={{ marginBottom: "10px" }}>
        <Nav.Item eventKey={VIEW_ASSIGNED_LIST}>Przypisane</Nav.Item>
        <Nav.Item eventKey={VIEW_UNASSIGNED_LIST}>Nieprzypisane</Nav.Item>
      </Nav>

      {view === VIEW_ASSIGNED_LIST && <ContractProjectDocumentAssignedList />}
      {view === VIEW_UNASSIGNED_LIST && (
        <ContractProjectDocumentUnAssignedList />
      )}
    </>
  );
};

export default Notifications;
