import React, { FunctionComponent, useState } from "react";
import {
  IWarehouseAvailabilityData,
  IWarehouseAvailabilityFilters,
} from "utils/models";
import { _paginate30 } from "utils/states";
import WarehouseConnection from "utils/connections/warehouse";
import { Table } from "rsuite";
import UniversalFilters, {
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { getProjectIdFromUrl, handleSortColumn } from "utils/helpers";
import Pagination from "../../../../global/pagination/Pagination";
import ToolTipPhoto from "../../../../global/ToolTipPhoto";

interface IWarehouseList {}

interface IFilters extends IWarehouseAvailabilityFilters {
  groupId: string;
}

const WarehouseList: FunctionComponent<IWarehouseList> = () => {
  const [data, setData] = useState<IWarehouseAvailabilityData | null>(null);
  const defaultFilters = {
    brandId: "",
    itemId: "",
    groupId: "",
    ..._paginate30,
  };

  const [filters, setFilters] = useState<IFilters>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      <Table
        loading={loading}
        autoHeight
        // @ts-ignore
        data={data?.data ?? []}
        sortColumn={filters.requestOrder.field}
        sortType={filters.requestOrder.order}
        onSortColumn={(dataKey, sortType) =>
          handleSortColumn(dataKey, sortType, setFilters)
        }>
        <Table.Column flexGrow={1} minWidth={300} sortable>
          <Table.HeaderCell> Produkt </Table.HeaderCell>
          <Table.Cell dataKey="name">
            {(rowData) => {
              return (
                <>
                  <ToolTipPhoto photoData={rowData.photo} />
                  &nbsp;{rowData.name}
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Grupa</Table.HeaderCell>
          <Table.Cell dataKey="itemGroup" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Stan</Table.HeaderCell>
          <Table.Cell dataKey="availability" />
        </Table.Column>
        <Table.Column width={120}>
          <Table.HeaderCell>Przychód</Table.HeaderCell>
          <Table.Cell dataKey="incoming" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Rozchód</Table.HeaderCell>
          <Table.Cell dataKey="outcoming" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Strata</Table.HeaderCell>
          <Table.Cell dataKey="lost" />
        </Table.Column>
      </Table>
      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      <UniversalFilters
        load={WarehouseConnection.availabilityList}
        formGet={WarehouseConnection.availabilityFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectWarehouse_${getProjectIdFromUrl()}`}
        elements={[
          {
            type: FILTER_SELECT,
            stateKey: "itemId",
            formKey: "items",
            label: "Produkt",
          },
          {
            type: FILTER_SELECT,
            stateKey: "brandId",
            formKey: "brands",
            label: "Marka",
          },
          {
            type: FILTER_SELECT,
            stateKey: "groupId",
            formKey: "itemGroups",
            label: "Grupa produktu",
          },
        ]}
      />
    </>
  );
};

export default WarehouseList;
