import React, { FunctionComponent } from "react";
import { getUserFromSession, isPublicView } from "../../utils/helpers";
import UserConnection from "../../utils/connections/user";
import Button from "rsuite/Button";
import SwitchButtonHorizontalGroup from "../atoms/switchButton/SwitchButtonHorizontalGroup";
import SwitchButton from "../atoms/switchButton/SwitchButton";
import { IUserRole } from "../../utils/models";
import { logout } from "redux/store";
import { useHistory } from "react-router-dom";
import { Col, IconButton, Popover, Row, Whisper } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import FunnelIcon from "@rsuite/icons/Funnel";
import { ToastNotificationPush, ToastTypes } from "../ToastNotification";
interface IUser {}

const User: FunctionComponent<IUser> = () => {
  const history = useHistory();

  const handleLogOut = () => {
    UserConnection.logout().then(() => {
      localStorage.removeItem("user");
      logout();
      history.push("/");
    });
  };
  const handlePasswordChange = () => {
    window.location.href = "/user/change-own-password";
  };

  const userData = getUserFromSession();
  if (userData === null) return <></>;

  const handleChangeRole = (newRoleId: string) => {
    if (newRoleId === userData.currentRoleId) return false;

    UserConnection.switchRole({ roleId: newRoleId }).then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
      window.location.href = "/";
    });
  };

  const userInitials =
    userData.user.firstName.charAt(0).toUpperCase() +
    userData.user.lastName.charAt(0).toUpperCase();

  return (
    <div style={{ display: "block", position: "relative" }}>
      <Whisper
        enterable
        trigger={"click"}
        placement="auto"
        speaker={
          <Popover style={{ minWidth: "300px" }}>
            <div>
              {userData.user.firstName} {userData.user.lastName},{" "}
              {userData.user.username}
            </div>
            <SeparatorEmpty />

            {userData?.user?.roles?.length > 0 && (
              <>
                <Row>
                  <Col xs={24}>
                    <SwitchButtonHorizontalGroup>
                      {userData.user.roles.map(
                        (item: IUserRole, index: any) => {
                          return (
                            <SwitchButton
                              key={index}
                              style={{ borderRadius: 0 }}
                              isChecked={
                                item.role.roleId === userData.currentRoleId
                              }
                              onClick={handleChangeRole.bind(
                                null,
                                item.role.roleId
                              )}>
                              {item.role.roleName}
                            </SwitchButton>
                          );
                        }
                      )}
                    </SwitchButtonHorizontalGroup>
                  </Col>
                </Row>
                <SeparatorEmpty />
              </>
            )}
            <Row>
              <Col xs={12}>
                <Button
                  size={"sm"}
                  block
                  appearance={"ghost"}
                  onClick={handlePasswordChange}
                  style={{ marginRight: "10px" }}>
                  Zmień hasło
                </Button>
              </Col>
              <Col xs={8}>
                <Button
                  size={"sm"}
                  block
                  appearance={"ghost"}
                  onClick={handleLogOut}>
                  Wyloguj
                </Button>
              </Col>
              <Col xs={4}>
                <IconButton
                  style={{ height: "30px" }}
                  size={"sm"}
                  title={
                    "Możesz wyczyścić wszystkie\n (we wszystkich widokach list) \n ustawione przez siebie filtry "
                  }
                  icon={<FunnelIcon />}
                  block
                  appearance={"ghost"}
                  onClick={() => {
                    const user = localStorage.getItem("user");
                    localStorage.clear();
                    if (user) localStorage.setItem("user", user);
                    ToastNotificationPush(
                      ToastTypes.success,
                      "Filtry użytkownika zostały wyczyszczone"
                    );
                  }}></IconButton>
              </Col>
            </Row>
          </Popover>
        }>
        <Button
          appearance={"ghost"}
          disabled={isPublicView()}
          style={{ borderColor: "#ddd" }}
          id={"openProfileButton"}>
          {userInitials}
        </Button>
      </Whisper>
    </div>
  );
};

export default User;
