import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WhiteCard from "global/atoms/WhiteCard";
import Select from "global/atoms/Select";
import { ISelectViewResponse } from "utils/models";
import Input from "global/atoms/Input";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import { Col, Row, Table } from "rsuite";
import DocumentsConnection from "utils/connections/documents";
import Spinner from "global/atoms/Spinner/Spinner";
import styles from "../orders/styles.module.scss";

export interface IDocumentDetailsForm {
  users: ISelectViewResponse;
  paymentTypes: ISelectViewResponse;
  saleTypes: ISelectViewResponse;
  model: any;
}

export const SUMMARY_TITLE = "Podsumowanie";

export const createSummaryRowData = (model: any) => {
  return {
    itemName: SUMMARY_TITLE,
    price: {
      amount: "",
      tax: "",
      amountGross: "",
      discount: model.price.discount,
      amountTotal: model.price.amountTotal,
      taxTotal: model.price.taxTotal,
      amountGrossTotal: model.price.amountGrossTotal,
    },
    itemVatRate: {
      name: "",
    },
    itemUnit: {
      name: "",
    },
  };
};

export const getCellComponentWithPriceValue = (value: any, rowData: any) => {
  if (value === "") {
    return <span />;
  }
  const priceFormat = Number(value).toFixed(2);
  if (rowData.itemName === SUMMARY_TITLE) {
    return <b>{priceFormat}</b>;
  }
  return <span>{priceFormat}</span>;
};

export const getDocumentPositionsBody = (positions: Array<any>) => {
  return (
    <>
      <Table autoHeight data={positions ?? []}>
        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Produkt
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey={"itemName"}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <b>{rowData.itemName}</b>;
              }
              return <span>{rowData.itemName}</span>;
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Netto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(
                rowData.price.amount,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Vat
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(rowData.price.tax, rowData);
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Brutto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(
                rowData.price.amountGross,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Liczba
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return (
                <span>
                  {rowData.itemAmount} {rowData.itemUnit.name}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Rabat
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.discount,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Netto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.amountTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Vat
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.taxTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Brutto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.amountGrossTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </>
  );
};

const DocumentDetails: FunctionComponent = () => {
  const [form, setForm] = useState<IDocumentDetailsForm | null>(null);
  const { id, documentId } = useParams<{ id: string; documentId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    DocumentsConnection.editForm(documentId, id).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);
      setForm(response.data);
    });
  }, []);

  if (form === null || positions === null) return <Spinner />;

  const getAddressString = (address: {
    street: string;
    houseNumber: string;
    apartmentNumber: string;
    postcode: string;
    city: string;
  }): string => {
    if (!address) return "";
    return (
      [
        address?.street,
        address?.houseNumber,
        address?.apartmentNumber ? " lok. " + address?.apartmentNumber : "",
      ].join(" ") +
      ", " +
      [address.postcode, address.city].join(" ")
    );
  };

  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={8}>
            <Input
              value={form.model.documentNumber}
              placeholder={"Numer"}
              name={"documentNumber"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.user.fullName}
              placeholder={"Wystawił"}
              name={"user"}
              disabled={true}
            />
          </Col>
          <Col xs={form.model.externalDocumentNumber ? 4 : 8}>
            <Select
              placeholder={"Rodzaj dokumentu"}
              name={"saleTypeId"}
              value={form.model.saleType.id}
              options={form.saleTypes.options}
              disabled={true}
            />
          </Col>
          {form.model.externalDocumentNumber && (
            <Col xs={form.model.externalDocumentNumber ? 4 : 8}>
              <Input
                value={form.model.externalDocumentNumber}
                placeholder={"Numer zewnętrzny"}
                name={"externalDocumentNumber"}
                disabled={true}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col xs={8}>
            <Input
              value={form.model.documentDate}
              placeholder={"Data wystawienia"}
              name={"documentDate"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.deliveryDate}
              placeholder={"Data dostawy"}
              name={"deliveryDate"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.paymentDate}
              placeholder={"Data płatności"}
              name={"paymentDate"}
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={16}>
            <Input
              value={form.model.description}
              placeholder={"Uwagi"}
              name={"description"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.countPrinted}
              placeholder={"Ilość wydruków"}
              name={"countPrinted"}
              disabled={true}
            />
          </Col>
        </Row>

        <SeparatorLine size={2} className={styles.separator} />
        {!!form.model.documentSeller && (
          <>
            <Row>
              <Col xs={8}>
                <Input
                  value={form.model?.documentSeller?.name}
                  placeholder={"Sprzedawca"}
                  name={"documentSellerName"}
                  disabled={true}
                />
              </Col>
              <Col xs={8}>
                <Input
                  value={getAddressString(form.model.documentSeller)}
                  placeholder={"Adres sprzedawcy"}
                  name={"documentSellerAddress"}
                  disabled={true}
                />
              </Col>
              <Col xs={8}>
                <Input
                  value={form.model.documentSeller.nip}
                  placeholder={"Nip"}
                  name={"documentSellerNip"}
                  disabled={true}
                />
              </Col>
            </Row>
          </>
        )}
        {!form.model.saleType.type.startsWith("PAR") && (
          <>
            {!!form.model.documentPayer && (
              <Row>
                <Col xs={8}>
                  <Input
                    value={form.model?.documentPayer?.name}
                    placeholder={"Nabywca"}
                    name={"documentPayerName"}
                    disabled={true}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    value={getAddressString(form.model.documentPayer)}
                    placeholder={"Adres nabywcy"}
                    name={"documentPayerAddress"}
                    disabled={true}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    value={form.model.documentPayer?.nip}
                    placeholder={"Nip"}
                    name={"documentPayerNip"}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
            {!!form.model.documentRecipient && (
              <Row>
                <Col xs={8}>
                  <Input
                    value={form.model.documentRecipient.name}
                    placeholder={"Odbiorca"}
                    name={"documentRecipientName"}
                    disabled={true}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    value={getAddressString(form.model.documentRecipient)}
                    placeholder={"Adres odbiorcy"}
                    name={"documentRecipientAddress"}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {form.model.saleType.type.startsWith("PAR") && (
          <>
            <Row>
              <Col xsOffset={16} xs={8}>
                <Input
                  value={form.model.nip}
                  placeholder={"Nip nabywcy"}
                  name={"nip"}
                  disabled={true}
                />
              </Col>
            </Row>
          </>
        )}

        <SeparatorLine size={2} className={styles.separator} />

        {getDocumentPositionsBody(positions)}
      </WhiteCard>
    </>
  );
};

export default DocumentDetails;
