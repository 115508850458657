import React, { FunctionComponent, useState } from "react";
import ProjectsConnection from "utils/connections/projects";
import { IGetProjects, IProjects } from "utils/models";
import { generatePath, useHistory } from "react-router-dom";
import Pagination from "global/pagination/Pagination";
import { getProjectsState } from "utils/states";
import { Table } from "rsuite";

import styles from "./styles.module.scss";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  BatchActionDropdownItem,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import {
  deepClone,
  handleSortColumn,
  handleToast,
} from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import IconButton from "../../../../global/atoms/IconButton";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { Dropdown } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import CopyIcon from "@rsuite/icons/Copy";
import CloneProjectModal from "../cloneModal/CloneProjectModal";
interface IProjectsList {}

const ProjectsList: FunctionComponent<IProjectsList> = () => {
  const [data, setData] = useState<IProjects | null | "error">(null);
  const [filters, setFilters] = useState<IGetProjects>(
    deepClone(getProjectsState)
  );
  const [filtersForm, setFiltersForm] = useState<any>("loading");
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");

  const handleGoToEdit = (projectId: string) => {
    history.push(
      generatePath("/projects/:id/edit/basic", {
        id: projectId,
      })
    );
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(ProjectsConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      setTriggerLoad(Date.now());
    });
  };

  const isDisabled = (elm: string): boolean => {
    return filtersForm?.disabledElements?.includes(elm);
  };
  const copyModalOpen = (project) => {
    if (project) {
      setProjectId(project.id);
      setProjectName(project.name);
      setIsModalOpen(true);
    }
  };
  return (
    <>
      <HeaderButtons>
        {!isDisabled("addNewButton") || !isDisabled("deleteSelectedButton") ? (
          <OptionsDropdown>
            <Dropdown.Item
              icon={<PlusIcon />}
              disabled={isDisabled("addNewButton")}
              onClick={() => history.push(generatePath("/project/add"))}>
              Utwórz nowy projekt
            </Dropdown.Item>
            <BatchActionDropdownItem
              callback={handleDeleteSelected}
              icon={<TrashIcon />}
              disabled={isDisabled("deleteSelectedButton")}
              chosenRows={chosenToDelete}
              title={`Usuń zaznaczone projekty (${chosenToDelete.length})`}
              message={"Czy na pewno chcesz usunąć zaznaczone projekty?"}
            />
          </OptionsDropdown>
        ) : null}
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            bordered
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            {!isDisabled("deleteSelectedButton") ? (
              <Table.Column width={40} align="left">
                <Table.HeaderCell depth={0} width={0}>
                  <ToggleAllCheckbox
                    data={data?.data ?? []}
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                  />
                </Table.HeaderCell>
                <Table.Cell width={100} depth={0}>
                  {(rowData) => (
                    <ToggleSingleCheckbox
                      state={chosenToDelete}
                      setState={setChosenToDelete}
                      selectedId={rowData.id}
                    />
                  )}
                </Table.Cell>
              </Table.Column>
            ) : null}
            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Nazwa
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/projects/${rowData.id}/visits`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell width={1} depth={0} dataKey="customer">
                {(rowData) => <span>{rowData.customer.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={120} align="right" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Status
              </Table.HeaderCell>
              <Table.Cell width={150} depth={0} dataKey="status">
                {(rowData) => (
                  <span
                    className={styles.status}
                    style={{
                      color: rowData.projectStatus.color,
                      borderColor: rowData.projectStatus.color,
                    }}>
                    {rowData.projectStatus.name}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            {(!isDisabled("deleteSelectedButton") ||
              !isDisabled("cloneButton") ||
              !isDisabled("editButton")) && (
              <Table.Column verticalAlign={"middle"} align="right">
                <Table.HeaderCell depth={0} width={50}>
                  Akcje
                </Table.HeaderCell>
                <Table.Cell depth={0} width={50}>
                  {(rowData) => (
                    <ActionsContainer gapSize={0}>
                      {!isDisabled("cloneButton") && (
                        <CopyIcon
                          width={"20px"}
                          height={"20px"}
                          style={{
                            cursor: "pointer",
                            color: "#e09616",
                          }}
                          onClick={copyModalOpen.bind(null, rowData)}
                        />
                      )}
                      {!isDisabled("editButton") && (
                        <IconButton
                          icon={"settings"}
                          onClick={handleGoToEdit.bind(null, rowData.id)}
                        />
                      )}
                      {!isDisabled("deleteSelectedButton") && (
                        <DeleteSingleButton
                          callback={handleDeleteSelected.bind(null, rowData.id)}
                          form={filtersForm}
                        />
                      )}
                    </ActionsContainer>
                  )}
                </Table.Cell>
              </Table.Column>
            )}
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={ProjectsConnection.getProjects}
        formGet={ProjectsConnection.getFormFilterProjects}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getProjectsState}
        setResultData={setData}
        filterStorageKey={"projectList"}
        getFormCallback={(_form) => setFiltersForm(_form)}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
          {
            type: FILTER_SELECT,
            stateKey: "customerId",
            formKey: "customers",
            label: "Klient",
          },
          {
            type: FILTER_SELECT,
            stateKey: "projectStatusId",
            formKey: "projectStatuses",
            label: "Status",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data rozpoczęcia",
            stateKey: "startDate",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data zakończenia",
            stateKey: "endDate",
          },
        ]}
      />
      <CloneProjectModal
        projectName={projectName}
        projectId={projectId}
        open={isModalOpen}
        handleClose={(reload: boolean) => {
          setIsModalOpen(false);
          if (reload) setTriggerLoad(Date.now());
        }}
      />
    </>
  );
};

export default ProjectsList;
