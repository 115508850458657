import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const StorageDeliveryTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Dane podstawowe",
        name: "basic",
        active: true,
        options: [
          {
            name: "product",
            label: "Produkt",
            active: true,
          },
          {
            name: "productGroup",
            label: "Produkt Grupa",
            active: true,
          },
          {
            name: "storageUser",
            label: "Użytkownik (Przyjmujący)",
            active: true,
          },
          {
            name: "storageUserGroup",
            label: "Przyjmujący (Grupa)",
            active: true,
          },
          {
            name: "income",
            label: "Przychód",
            active: true,
          },
          {
            name: "price",
            label: "Cena",
            active: true,
          },
          {
            name: "deliveryDate",
            label: "Data dostawy",
            active: true,
          },
          {
            name: "deliveryState",
            label: "Statua dostawy",
            active: true,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={32} />
    </>
  );
};

export default StorageDeliveryTemplate;
