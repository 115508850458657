import {
  IDependOnQuestionValueCondition,
  IQuestionItem,
  IVisitPosition,
} from "utils/models";
import {
  BOOLEAN_QUESTION,
  DECIMAL_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
} from "./QuestionTypeConsts";
import { RawValue } from "../VisitEditForm";
import { useContext } from "react";
import {
  IVisitEditContext,
  IVisitEditErrorContext,
  IVisitEditPanelContext,
  IVisitEditPhotoContext,
  IVisitEditStaticContext,
  IVisitFormState,
  VisitEditContext,
  VisitEditErrorContext,
  VisitEditFormContext,
  VisitEditPanelContext,
  VisitEditPhotoContext,
  VisitEditStaticContext,
} from "../VisitEditContextWrapper";
import { isArray, isEmpty } from "lodash";

type TFormState = {
  [name: string]: RawValue | Date;
};

type TQuestionFlatState = {
  [refQuestionId: string]: IQuestionItem<any>;
};

const recurConditionSucceeded = (
  q: IQuestionItem,
  conditions: Array<IDependOnQuestionValueCondition>,
  _formState: TFormState
): boolean => {
  const value = _formState[q.refQuestionId] ?? null;

  const condition = conditions[0]; // todo: siblings 'OR' conditions
  const comparableValue =
    q.questionType === BOOLEAN_QUESTION
      ? condition.boolValue
        ? 1
        : 0
      : condition.intValue ?? 0;

  let passed = false;
  switch (condition.comparisonFormula) {
    case ">":
      passed = (value as number) > comparableValue;
      break;
    case "<":
      passed = (value as number) < comparableValue;
      break;
    case ">=":
      passed = (value as number) >= comparableValue;
      break;
    case "<=":
      passed = (value as number) <= comparableValue;
      break;
    case "=":
      passed = (value as number) == comparableValue;
      break;
  }
  if (!passed) return false;

  if (condition.and !== null) {
    return recurConditionSucceeded(q, [condition.and], _formState);
  }

  return true;
};

export const isQuestionDisabled = (
  question: IQuestionItem,
  questionsFlatState: TQuestionFlatState,
  formState: TFormState
): boolean => {
  if (!question.isEdit) return true; // not editable
  if (!question.dependOnQuestion || question.dependOnQuestion.id === "") {
    return false; /// is not dependent
  }

  const dq = getDependentOnQuestion(
    question.dependOnQuestion.refQuestionId,
    questionsFlatState
  );
  if (!dq) return false; // dependent on question not found = not disabled anymore

  switch (dq.questionType) {
    case BOOLEAN_QUESTION:
    case INTEGER_QUESTION:
    case DECIMAL_QUESTION: {
      if (!question.dependOnQuestion.valueConditions.length) return false; // should not happen
      return !recurConditionSucceeded(
        dq,
        question.dependOnQuestion.valueConditions,
        formState
      );
    }
    case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
    case DICTIONARY_QUESTION: {
      const value =
        (formState[dq.refQuestionId] as string | Array<string>) ?? null;
      if (isEmpty(value)) return true;
      const options: Array<{ id: string }> =
        question.dependOnQuestion.options ?? []; //option from settings to compare it
      if (!options.length) return false; // there is no options to depend on, so skip
      if (isArray(value)) {
        return !options.filter((o) => o.id === value.find((v) => v === o.id))
          .length;
      }
      return !options.filter((o) => o.id === value).length;
    }
    default:
      return !dq.values.length;
  }
};

export const getDependentOnQuestion = (
  dependOnQuestionId: string,
  questionsFlatState: {
    [refQuestionId: string]: IQuestionItem<any>;
  }
): IQuestionItem | null => {
  return (
    Object.values(questionsFlatState).find(
      (q) => q.refQuestionId === dependOnQuestionId
    ) ?? null
  );
};

export const hasErrors = (
  items: IVisitPosition[],
  errors: { [key: string]: Array<string> } | null
) => {
  let _hasErrors = false;

  function _errs(items: IVisitPosition[]) {
    items.forEach((item) => {
      const refID = item.question.refQuestionId;
      Object.keys(errors ?? {}).forEach((key) => {
        if (key.includes(refID)) {
          _hasErrors = true;
        }
      });
      if (item.positions) _errs(item.positions);
    });
  }

  _errs(items);
  return _hasErrors;
};

export function usePanelContext() {
  return useContext(VisitEditPanelContext) as IVisitEditPanelContext;
}

export function useStaticContext() {
  return useContext(VisitEditStaticContext) as IVisitEditStaticContext;
}

export function useErrorContext() {
  return useContext(VisitEditErrorContext) as IVisitEditErrorContext;
}

export function useEditContext() {
  return useContext(VisitEditContext) as IVisitEditContext;
}

export function usePhotoContext() {
  return useContext(VisitEditPhotoContext) as IVisitEditPhotoContext;
}

export function useFormStateContext() {
  return useContext(VisitEditFormContext) as IVisitFormState;
}

export const parseToDate = (date: string | number | Date): Date | undefined => {
  if (!date) return undefined;
  if (/^\d{4}-\d{2}-\d{2}/.test(date as string)) {
    return new Date(date as string);
  }
  if (/^\d+$/.test(date as string)) {
    return new Date(parseInt(date as string) * 1000);
  }
  return date as Date;
};
