import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OrganizationsConnection from "utils/connections/organizations";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import Input from "global/atoms/Input";
import Select from "global/atoms/Select";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import Spinner from "global/atoms/Spinner/Spinner";
import ImageFormSelect from "../../../../global/atoms/ImageFormSelect";
import styles from "../../../projects/components/editProject/styles.module.scss";
import Section from "global/atoms/section/Section";
import CheckBox from "../../../../global/atoms/checkbox/CheckBox";
import ColorPicker from "../../../../global/atoms/ColorPicker";
import AddressPicker from "global/AddressPicker";
import { Col, Row } from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

interface IOrganizationsEdit {}

interface IOrganizationsData {
  name: string;
  countryId: string;
  logoUrl: string;
  addressId: string;
  color: string;
  nip: string;
  phone: string;
  code: string;
  fax: string;
  email: string;
  www: string;
  currencyId: string;
  repoDir: string;
  ftpAddr: string;
  ftpUser: string;
  ftpPass: string;
  ftpPort: string;
  isEnableUniqueDevice: boolean;
  isEnableTimeControll: boolean;
  isEnableMobileDeviceNotification: boolean;
  isEnableMobileSaleNotification: boolean;
  isEnableSynchro: boolean;
  isEnableLogRequestFromMobile: boolean;
  isEnableTimeTablePivot: boolean;
  isEnableCheckMemoryUsage: boolean;
  isEnableSmsChanel: boolean;
  isAppVisibleProjectList: boolean;
  isAppVisibleMenuTimeTable: boolean;
  isAppVisibleMenuStatement: boolean;
  isAppVisibleMenuTarget: boolean;
  isAppVisibleMenuDashboard: boolean;
  isAppVisibleMenuDocuments: boolean;
  isAppVisibleMenuFiles: boolean;
  isAppVisibleMenuUserStore: boolean;
  isAppVisibleVisitProjectName: boolean;
  isAppVisibleVisitProperties: boolean;
  isAppVisibleActivitySearchQuestion: boolean;
  isAppVisibleLargeTextFields: boolean;
}

const defaultAddOrganizationState: IOrganizationsData = {
  name: "",
  currencyId: "",
  code: "",
  color: "",
  logoUrl: "",
  fax: "",
  nip: "",
  www: "",
  addressId: "",
  email: "",
  phone: "",
  countryId: "",
  repoDir: "",
  ftpAddr: "",
  ftpUser: "",
  ftpPass: "",
  ftpPort: "",
  isEnableUniqueDevice: false,
  isEnableTimeControll: false,
  isEnableMobileDeviceNotification: false,
  isEnableMobileSaleNotification: false,
  isEnableTimeTablePivot: false,
  isEnableSynchro: false,
  isEnableLogRequestFromMobile: false,
  isEnableCheckMemoryUsage: false,
  isEnableSmsChanel: false,
  isAppVisibleProjectList: true,
  isAppVisibleMenuTimeTable: true,
  isAppVisibleMenuStatement: true,
  isAppVisibleMenuTarget: true,
  isAppVisibleMenuDashboard: true,
  isAppVisibleMenuDocuments: true,
  isAppVisibleMenuFiles: true,
  isAppVisibleMenuUserStore: true,
  isAppVisibleVisitProjectName: true,
  isAppVisibleVisitProperties: true,
  isAppVisibleActivitySearchQuestion: true,
  isAppVisibleLargeTextFields: true,
};

const OrganizationsEdit: FunctionComponent<IOrganizationsEdit> = () => {
  const [data, setData] = useState<IOrganizationsData>(
    defaultAddOrganizationState
  );
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any>("loading");
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      OrganizationsConnection.getFormEditOrganization(id).then((response) => {
        const data = response.data;
        const model = data.model;
        setData({
          ...data.model,
          /* to rewrite */
          countryId: model.country.id,
          addressId: model.address?.id,
          currencyId: model.currency?.id,
        });
        setForm(data);
      });
    } else {
      OrganizationsConnection.getFormAddOrganization().then((data) => {
        const addState = defaultAddOrganizationState;
        addState.currencyId = data.data.currencies.value;
        setData(addState);
        setForm(data.data);
      });
    }
  }, []);

  if (data === null || form === "loading") return <Spinner />;

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const redirectUrl = "/superadmin/organizations";
    if (isEdit) {
      handleToast(
        OrganizationsConnection.updateOrganization(id, data),
        setErrors
      ).then(() => history.push(redirectUrl));
    } else {
      handleToast(
        OrganizationsConnection.createOrganization(data),
        setErrors
      ).then(() => history.push(redirectUrl));
    }
  };

  const handleChangeOptionCheckBox = (optionName: string) => {
    setData({
      ...data,
      ...{ [optionName]: !data?.[optionName] },
    });
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Row>
            <Col xs={3}>
              Logo organizacji
              <ImageFormSelect
                label={"Wybierz logo"}
                state={data}
                setState={setData}
                name={"logoUrl"}
              />
            </Col>
            <Col xs={20}>
              <Input
                type={"text"}
                placeholder={"Nazwa"}
                name={"name"}
                value={data.name}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={20}>
              <AddressPicker
                value={data.addressId}
                onSelected={(addressId: string | null) => {
                  setData((d) => ({ ...d, addressId: addressId ?? "" }));
                }}
              />
            </Col>
            <Col xs={4}>
              Kolor organizacji
              <ColorPicker
                stateKey={"color"}
                title={" "}
                state={data}
                setState={setData}
              />
            </Col>
          </Row>

          <Section title={"Dane organizacji"} className={styles.optionsSells}>
            <Col xs={12}>
              <Select
                placeholder={"Kraj"}
                name={"countryId"}
                value={data.countryId}
                options={form.countries.options}
                disabled={false}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Select
                placeholder={"Waluta"}
                name={"currencyId"}
                value={data.currencyId}
                options={form.currencies.options}
                disabled={false}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Fax"}
                name={"fax"}
                value={data.fax}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Nip"}
                name={"nip"}
                value={data.nip}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Www"}
                name={"www"}
                value={data.www}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Numer telefonu"}
                name={"phone"}
                value={data.phone}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Kod organizacji"}
                name={"code"}
                value={data.code}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"E-mail"}
                name={"email"}
                value={data.email}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <SeparatorEmpty />
          </Section>

          <Section title={"Opcje organizacji"} className={styles.optionsSells}>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableUniqueDevice}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableUniqueDevice"
                )}>
                Kontrola unikalności urządzenia (blokada aplikacji)
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableMobileDeviceNotification}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableMobileDeviceNotification"
                )}>
                Kontrola unikalności urządzenia (powiadomienia)
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableMobileSaleNotification}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableMobileSaleNotification"
                )}>
                Kontrola dokumentów sprzedaży (powiadomienia)
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableTimeControll}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableTimeControll"
                )}>
                Kontrola daty z serwera (blokada dokumentu)
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableSynchro}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableSynchro"
                )}>
                Wymagana codzienna synchronizacja aplikacji
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableLogRequestFromMobile}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableLogRequestFromMobile"
                )}>
                Logowanie wizyt z aplikacji
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableSmsChanel}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableSmsChanel"
                )}>
                Włączony kanał dystrybucji SMS
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableTimeTablePivot}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableTimeTablePivot"
                )}>
                Przeliczanie warstwy widoku harmonogramu
              </CheckBox>
            </Col>
            <Col xs={12}>
              <CheckBox
                isChecked={data.isEnableCheckMemoryUsage}
                onClick={handleChangeOptionCheckBox.bind(
                  null,
                  "isEnableCheckMemoryUsage"
                )}>
                Sprawdzanie użycia pamięci w aplikacji mobilnej
              </CheckBox>
            </Col>
            <SeparatorEmpty />
          </Section>
          <Section title={"Konfiguracja widoków"}>
            <Section title={"Konfiguracja widoków w aplikacji"}>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleProjectList}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleProjectList"
                  )}>
                  Lista projektów
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuTimeTable}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuTimeTable"
                  )}>
                  Menu Harmonogram
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuStatement}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuStatement"
                  )}>
                  Menu Statusy
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuTarget}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuTarget"
                  )}>
                  Menu Targety
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuDashboard}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuDashboard"
                  )}>
                  Menu Dashboard
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuDocuments}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuDocuments"
                  )}>
                  Menu Dokumenty
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuFiles}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuFiles"
                  )}>
                  Menu Pliki
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleMenuUserStore}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleMenuUserStore"
                  )}>
                  Menu Magazyn Użytkownika
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleVisitProperties}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleVisitProperties"
                  )}>
                  Właściwości wizyty
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleVisitProjectName}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleVisitProjectName"
                  )}>
                  Nazwa projektu w aktywnościach
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleActivitySearchQuestion}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleActivitySearchQuestion"
                  )}>
                  Wyszukiwanie pytań w aktywnościach
                </CheckBox>
              </Col>
              <Col xs={12}>
                <CheckBox
                  isChecked={data.isAppVisibleLargeTextFields}
                  onClick={handleChangeOptionCheckBox.bind(
                    null,
                    "isAppVisibleLargeTextFields"
                  )}>
                  Rozszerzone pola tekstowe
                </CheckBox>
              </Col>
              <SeparatorEmpty />
            </Section>
          </Section>
          <Section title={"Repozytorium"} className={styles.optionsSells}>
            <Col xs={12}>
              <Input
                placeholder={"Ftp adres"}
                name={"ftpAddr"}
                value={data.ftpAddr}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Ftp user"}
                name={"ftpUser"}
                value={data.ftpUser}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Ftp pass"}
                name={"ftpPass"}
                value={data.ftpPass}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Ftp port"}
                name={"ftpPort"}
                value={data.ftpPort}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Input
                placeholder={"Katalog"}
                name={"repoDir"}
                value={data.repoDir}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <SeparatorEmpty />
          </Section>
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default OrganizationsEdit;
