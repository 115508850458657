import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const UserMobileAuthenticationsTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Dane użytkownika",
        name: "user",
        active: true,
        options: [
          {
            name: "user_id",
            label: "Identyfikator uzytkownika",
            active: true,
          },
          {
            name: "user_login",
            label: "Login użytkownika",
            active: true,
          },
          {
            name: "user_fullname",
            label: "Dane osobwe",
            active: true,
          },
          {
            name: "user_is_active",
            label: "Aktywny/Nieaktywny",
            active: false,
          },
        ],
      },
      {
        label: "Ogólne",
        name: "general",
        active: true,
        options: [
          {
            name: "app_version",
            label: "Wersja aplikacji mobilej",
            active: true,
          },
          {
            name: "created_at",
            label: "Data logowania (serwera)",
            active: true,
          },
        ],
      },
      {
        label: "Dane urządzenia",
        name: "device",
        active: true,
        options: [
          {
            name: "device_name",
            label: "Nazwa urządzenia",
            active: true,
          },
          {
            name: "device_type",
            label: "Typ oprogramowania",
            active: true,
          },
          {
            name: "device_system",
            label: "Wersja oprogramowania",
            active: true,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={16} />
    </>
  );
};

export default UserMobileAuthenticationsTemplate;
