import { IAddress, IQuestionItem, ISelect } from "../../../../utils/models";

export interface ILocationProjectForm {
  disabledElements: string[];
  model: {
    name: string;
    recipientAddress: IAddress;
    payerAddress: IAddress;
    address: IAddress;
  };
  id: string;
  customers: ISelect;
  locationRoles: { count: number; data: [] };
  locationTypes: ISelect;
  networks: ISelect;
  projectNetworks: ISelect;
  regions: ISelect;
  unassignedQuestions: { count: number; data: Array<IQuestionItem> };
}

export interface IQuestionsData {
  assignedQuestions: { count: number; data: Array<IQuestionItem> };
  unassignedQuestions: { count: number; data: Array<IQuestionItem> };
}
export interface ILocationAddress {
  //payerName:{id:string,name:string};
  //payerName:{id:string,name:string};
  addressId: string;
  payerAddressId: string;
  recipientName: string;
  recipientAddressId: string;
  payerName: string;
}

export const locationAddress: ILocationAddress = {
  addressId: "",
  payerName: "",
  payerAddressId: "",
  recipientName: "",
  recipientAddressId: "",
};
export const defaultQuestions: IQuestionsData = {
  assignedQuestions: { count: 0, data: [] },
  unassignedQuestions: { count: 0, data: [] },
};

export interface ILocationProjectData {
  id: string;
  name: string;
  description: string;
  networkId: string;
  locationTypeId: string;
  invoiceName: string;
  code: string;
  email: string;
  phone: string;
  // networkGeneralId: string
  projectLocationNetworkId: string;
  networkLocationCode: string;
  contactPerson: string;
  nip: string;
  recipientAddressId: string;
  incrementId: number;
  assignedQuestions: { count: number; data: Array<IQuestionItem> };
  unassignedQuestions: { count: number; data: Array<IQuestionItem> };
}

export const createLocationProjectState: Partial<ILocationProjectData> = {
  id: "",
  projectLocationNetworkId: "",
  networkLocationCode: "",
  phone: "",
  description: "",
  networkId: "",
  code: "",
  contactPerson: "",
  email: "",
  invoiceName: "",
  locationTypeId: "",
  nip: "",
  incrementId: 0,
};

export interface IProjectLocationRequestData {}
export interface ILocationProjectRegionData {
  customerId: string;
  regionId: string;
  regionName: string;
  customerName: string;
}
export interface ILocationProjectCustomerData {
  customerId: string;
  code: string;
  customerName: string;
}
export interface IProjectLocationCustomerAndRegionData {
  locationCustomers: ILocationProjectCustomerData;
  locationCustomerRegions: Array<ILocationProjectRegionData>;
}

export interface ILocationRoles {
  count: number;
  data: Array<{
    roleId: string;
    roleName: string;
    assigned: Array<{ id: string; name: string }>;
    unassigned: Array<{ id: string; name: string }>;
  }>;
}
export interface IRolesRequest {
  roles: Array<{
    roleId: string;
    assigned: Array<{ id: string }>;
    unassigned: Array<{ id: string }>;
  }>;
}
