import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useRef } from "react";
import TileContextMenu, { EnumScope } from "./TileContextMenu";
import _ from "lodash";
import TileSocket from "./TileSocket";
import { useControls, useTransformContext } from "react-zoom-pan-pinch";
import { IDay, TileWidth } from "../ScheduleViewBoard";

export interface IDataMockup {
  id: string;
  date: Date;
  location: string;
  frequency: number;
  sort: number | null;
}

export interface IDndItem {
  id: string | null;
  location: {
    id: string;
    name: string;
    address: string;
    code: string;
  } | null;
  rowIndex: number;
  date: dayjs.Dayjs;
  frequency: null | number;
  isMovable: boolean;
  isEditable: boolean;
}

interface IBoardTable {
  dateFrom: Date;
  dateTo: Date;
  scale: number;
  data: IDay[];
  dataModified: (data: IDay[]) => void;
  triggerReload: () => void;
  userId: string;
}

export const detailedScaleVal = 2;

const BoardTable: FunctionComponent<IBoardTable> = (props) => {
  const boardContext = useTransformContext();
  const boardControlls = useControls();

  const dataRows = useRef<{ [dataId: string]: number }>({}); // reference to row data
  const numDays = dayjs(props.dateTo).diff(dayjs(props.dateFrom), "day") + 1;
  const header = Array.from({ length: numDays }, (_, i) => {
    return dayjs(props.dateFrom).add(i, "day");
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      boardControlls.setTransform(0, 0, 1);
    }

    const scale = boardContext.getContext().state.scale;
    const xModifier = TileWidth * scale + 2; // 2px for border size

    if (scale === detailedScaleVal) {
      if (event.key === "ArrowLeft") {
        boardControlls.setTransform(
          boardContext.getContext().state.positionX + xModifier,
          boardContext.getContext().state.positionY,
          boardContext.getContext().state.scale,
          50
        );
      }

      if (event.key === "ArrowRight") {
        boardControlls.setTransform(
          boardContext.getContext().state.positionX - xModifier,
          boardContext.getContext().state.positionY,
          boardContext.getContext().state.scale,
          50
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // const thRefs = useMemo(
  //   () => Array.from({ length: header.length }).map(() => React.createRef()),
  //   [header]
  // );

  const getDataRowsNumber = (): number => {
    let maxRows = 0;

    props.data.forEach((d) => {
      let hits = 0;
      props.data.forEach((d1) => {
        if (dayjs(d.date).isSame(d1.date, "day")) {
          hits++;
        }
      });
      if (hits > maxRows) maxRows = hits;
    });

    return maxRows;
  };

  const monthsRowRender = () => {
    const monthDays: { [monthString: string]: number } = {};
    header.every((d) => {
      monthDays[d.format("MMMM")] = monthDays[d.format("MMMM")]
        ? monthDays[d.format("MMMM")] + 1
        : 1;
      return true;
    });
    return (
      <tr id="months-row">
        {Object.entries(monthDays).map(([month, daysCount], i) => (
          <th colSpan={daysCount} key={`th-month-${i}`}>
            {month}
          </th>
        ))}
      </tr>
    );
  };

  const cellRowRender = (rowNum: number) => {
    return header.map((d, i) => {
      const dataItem: IDay | undefined = _.cloneDeep(props.data)
        .filter(
          (_d) =>
            dayjs(_d.date).isSame(d, "day") &&
            dataRows.current[_d.id] === undefined
        )
        .sort((a, b) => a.order - b.order)
        .shift();

      if (dataItem) {
        dataRows.current[dataItem.id] = rowNum;
      }

      const dndItem: IDndItem = {
        id: dataItem?.id,
        rowIndex: rowNum,
        location: dataItem
          ? {
              id: dataItem.locationId,
              name: dataItem.locationName,
              address: dataItem.locationAddress,
              code: dataItem.locationCode,
            }
          : null,
        frequency: dataItem?.frequencies?.[0],
        date: d,
        isMovable: dataItem?.isMovable,
        isEditable: dataItem?.isEditable,
      };

      return (
        <TileSocket
          triggerReload={props.triggerReload}
          key={`td-${d.format("DDMMYYYY")}-${i}`}
          scale={props.scale}
          userId={props.userId}
          dndItem={dndItem}
          dataModified={props.dataModified}
          data={props.data}
          dataRows={dataRows}
        />
      );
    });
  };

  const renderTableBody = () => {
    dataRows.current = {}; // reset

    const length = props.data?.length ? getDataRowsNumber() + 1 : 1;
    return Array.from({ length: length }, (_, i) => (
      <tr>{cellRowRender(i)}</tr>
    ));
  };

  const daysRowRender = () => {
    const handleOnClick = (id: string) => {
      if (props.scale === detailedScaleVal) {
        boardControlls.setTransform(0, 0, 1);
      } else {
        // scale up
        boardControlls.setTransform(0, 0, detailedScaleVal, 1);
        // scroll to element
        setTimeout(() => {
          boardControlls.setTransform(
            document.getElementById(id).offsetLeft * -1 * 2,
            0,
            detailedScaleVal,
            1
          );
        }, 60);
        // zoomToElement(ref.current, detailedScaleVal);
      }
    };

    return (
      <tr id="month-row">
        {header.map((d, i) => {
          const id = `th-${d.format("DDMMYYYY")}`;
          return (
            <th
              className={`th-week-${d.week() % 2} ${
                d.isSame(dayjs(), "day") ? "today" : ""
              }`}
              id={id}
              key={`thk-${i}`}>
              {props.scale < detailedScaleVal ? (
                <div onClick={() => handleOnClick(id)}>
                  <div style={{ color: "#b8b8b8" }}>{d.format("DD")}</div>
                  {d.format("dd")}
                </div>
              ) : (
                <div className={"full-weekday"}>
                  <TileContextMenu
                    userId={props.userId}
                    scope={EnumScope.COLUMN}
                    item={null}
                    data={props.data}
                    date={d}
                    triggerReload={props.triggerReload}
                    dataModified={props.dataModified}>
                    <div onClick={() => handleOnClick(id)}>
                      <div>{d.format("dddd")}</div>
                      <div style={{ color: "#b8b8b8" }}>
                        {d.format("MM-DD")}
                      </div>
                    </div>
                  </TileContextMenu>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    );
  };

  return (
    <table id="schedules" className={`scale-${props.scale}`}>
      <thead>
        {monthsRowRender()}
        {daysRowRender()}
      </thead>
      <tbody>{renderTableBody()}</tbody>
    </table>
  );
};

export default BoardTable;
