import { SET_APP } from "../actions";
import { appState } from "../states";

const appReducer = (state = appState, action: any) => {
  switch (action.type) {
    case SET_APP:
      return action.payload;
    default:
      return state;
  }
};

export default appReducer;
