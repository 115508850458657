import { ISubmitReviewDataPosition } from "utils/connections/visitReviews";
import { IFeedbackDetails, IPhotoReviewFeedback } from "utils/models";

export const getKPIFeedback = (form: {
  feedbackDetails: IFeedbackDetails[];
}): IFeedbackDetails => {
  const tmp = form.feedbackDetails.find(
    (fd) => fd.feedbackQuestion.questionType === "INTEGER_QUESTION"
  );
  if (!tmp) throw "Expected value not present!";
  return tmp;
};

export const getKPIValueFromBulk = (feed: IPhotoReviewFeedback[]): number => {
  const vals: number[] = [];

  feed.forEach((f) => {
    const tmp = getKPIFeedback({ feedbackDetails: f.feedbackDetails });
    if (tmp && (tmp.values ?? []).length > 0) {
      // @ts-ignore
      vals.push(parseInt(tmp?.values[0].value ?? ""));
    }
  });

  const sum = vals.reduce((acc, v) => acc + v, 0);
  return Math.round(sum / vals.length);
};

export const getKPIReason = (form: {
  feedbackDetails: IFeedbackDetails[];
}): IFeedbackDetails | null => {
  const tmp = form.feedbackDetails.find(
    (fd) =>
      fd.feedbackQuestion.questionType ===
      "DICTIONARY_MULTIPLE_ANSWERS_QUESTION"
  );
  // if (!tmp) throw 'Expected value not present!';
  return tmp ?? null;
};

export const parseFeedbackDetailsForRequest = (
  feedbacks: IFeedbackDetails[]
): ISubmitReviewDataPosition[] => {
  return feedbacks.map((f) => {
    return {
      feedbackQuestionId: f.feedbackQuestion.id,
      values: f.values ?? [],
    };
  });
};
