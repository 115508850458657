import React, { FunctionComponent, useEffect, useState } from "react";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Form from "../../../../global/form/Form";
import Input from "../../../../global/atoms/Input";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import { ICreateCategory } from "../../../../utils/models";
import { createCategoryState } from "../../../../utils/states";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleToast } from "../../../../utils/helpers";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import CategoryConnection from "../../../../utils/connections/categories";
import Select from "../../../../global/atoms/Select";

interface IEditCategory {}

const EditCategory: FunctionComponent<IEditCategory> = () => {
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const ccs = createCategoryState;
  ccs.customerId = form.customers?.options[0].customerId; // default - organizacja
  const [data, setData] = useState<ICreateCategory>(ccs);

  useEffect(() => {
    CategoryConnection.getFormEditCategory(id).then((data) => {
      setData({
        customerId: data.data.model.customer?.id
          ? data.data.model.customer?.id
          : data.data.customers.value ?? "",
        name: data.data.model.name,
      });
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleToast(CategoryConnection.updateCategory(id, data), setErrors).then(
      () => history.push("/products/category")
    );
  };

  if (form === "loading") return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Nazwa"}
            name={"name"}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={"Klient"}
            name={"customerId"}
            value={data.customerId}
            options={form.customers.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default EditCategory;
