import React, { FunctionComponent } from "react";
import UniversalModal from "global/atoms/UniversalModal";
import LocationProjectModalAddNew from "./LocationProjectModalAddNew";
import { SetState } from "../../../../utils/models";
import { Col } from "rsuite";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";

interface IVisitsModalAdd {
  open: boolean;
  setOpen: SetState<boolean>;
  setRefLocationId: SetState<string>;
}

const LocationProjectModalAdd: FunctionComponent<IVisitsModalAdd> = (props) => {
  return (
    <>
      <UniversalModal
        size={"full"}
        displayFooterButtons={false}
        open={props.open}
        title={
          <>
            <Col md={12} xs={24}>
              <h1>Dodaj nową lokalizację do projektu</h1>
            </Col>

            <Col md={12} xs={24}>
              <div style={{ textAlign: "right", marginBottom: "15px" }}>
                <ButtonOutlined onClick={() => props.setOpen(false)}>
                  Anuluj
                </ButtonOutlined>
              </div>
            </Col>
          </>
        }
        body={
          <LocationProjectModalAddNew
            setOpen={props.setOpen}
            setRefLocationId={props.setRefLocationId}
          />
        }
        customFooterButtons={<></>}
      />
    </>
  );
};

export default LocationProjectModalAdd;
