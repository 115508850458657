import { IRouteEntity } from "utils/models";
import ContractEquipmentList from "../../../views/contract/equipments/ContractEquipmentList";
import ContractEquipmentEdit from "../../../views/contract/equipments/ContractEquipmentEdit";
import ContractDictionariesNavigations from "../../../views/contract/dictionaries/ContractDictionaryNavigations";

const path = "/contracts/equipments";

const routes: IRouteEntity[] = [
  {
    path: "/contracts/dictionaries",
    breadcrumb: "Wyposażenie",
    Component: ContractDictionariesNavigations,
  },
  {
    path: `${path}`,
    breadcrumb: "Wyposażenie",
    Component: ContractEquipmentList,
  },
  {
    path: `${path}/add`,
    breadcrumb: "Wyposażenie dodaj",
    Component: ContractEquipmentEdit,
  },
  {
    path: `${path}/:id/edit`,
    breadcrumb: "Wyposażenie zmień",
    Component: ContractEquipmentEdit,
  },
];

export default routes;
