import React, { FunctionComponent } from "react";
import { Button, Modal as RModal } from "rsuite";
import { getQuestionIconByType } from "./IconHelper";
import { IQuestionItem } from "../../utils/models";
import { ModalSize } from "rsuite/esm/Modal/Modal";

interface IModal {
  title: string | JSX.Element;
  body: any;
  open: boolean;
  overflow?: boolean | undefined;
  onSubmit?: () => void;
  onClose?: () => void;
  size?: ModalSize;
  displayFooterButtons?: boolean;
  submitButtonDisabled?: boolean;
  customFooterButtons?: JSX.Element;
  customHeader?: JSX.Element;
  static?: boolean;
}

const UniversalModal: FunctionComponent<IModal> = (props) => {
  return (
    <div className="modal-container">
      <RModal
        overflow={props?.overflow}
        open={props.open}
        backdrop={props.static ? "static" : undefined}
        onClose={props.onClose}
        size={props.size ?? "md"}>
        <RModal.Header closeButton={props.onClose !== undefined}>
          <RModal.Title>{props.title}</RModal.Title>
          {props?.customHeader}
        </RModal.Header>
        <RModal.Body
          style={
            !props.displayFooterButtons && !props.customFooterButtons
              ? { paddingBottom: 0 }
              : {}
          }>
          {props.body}
        </RModal.Body>
        {(props.displayFooterButtons ?? true) && (
          <RModal.Footer>
            <Button onClick={props.onClose} appearance="subtle">
              Anuluj
            </Button>
            <Button
              disabled={props.submitButtonDisabled === true}
              onClick={props.onSubmit}
              appearance="primary">
              Zatwierdź
            </Button>
          </RModal.Footer>
        )}
        {props.customFooterButtons && (
          <RModal.Footer>{props.customFooterButtons}</RModal.Footer>
        )}
      </RModal>
    </div>
  );
};

export const getQuestionConfigTitle = (
  question: IQuestionItem | undefined
): JSX.Element => {
  if (!question) return <>{"Konfiguracja pytania"}</>;
  return (
    <>
      {question?.questionType
        ? getQuestionIconByType(question?.questionType)
        : ""}
      {`${question?.questionName} - Konfiguracja pytania`}
    </>
  );
};

export default UniversalModal;
