import React, { FunctionComponent, useState } from "react";
import { IGetProducts, IProducts } from "utils/models";
import ProductsConnection from "utils/connections/products";
import { getProductsState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { generatePath, useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { deepClone, handleSortColumn } from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import ToolTipPhoto from "../../../../global/ToolTipPhoto";

interface IProductsList {}

const ProductsList: FunctionComponent<IProductsList> = () => {
  const [data, setData] = useState<IProducts | null>(null);
  const defaultFilters = deepClone(getProductsState);
  const [filters, setFilters] = useState<IGetProducts>(defaultFilters);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleEdit = (event: any) => {
    history.push(
      generatePath("/products/product/:id/edit", {
        id: event.currentTarget.dataset.id,
      })
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/products/product/add"}
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: ProductsConnection.batchDelete,
            callback: () => {
              setChosenToDelete([]);
              setTriggerLoad(Date.now());
            },
          }}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            loading={loading}
            fillHeight
            data={data?.data}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={300} align="left" sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey={"name"}>
                {(rowData) => (
                  <>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <ToolTipPhoto photoData={rowData.photo} />
                      &nbsp;
                      <div>
                        <ButtonListRedirect
                          redirectUrl={`/products/product/${rowData.id}/edit`}>
                          {rowData.name}
                        </ButtonListRedirect>
                      </div>
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell>Klient</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => <span>{rowData.customer.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable>
              <Table.HeaderCell>Grupa</Table.HeaderCell>
              <Table.Cell dataKey={"itemGroup.name"}>
                {(rowData) => <span>{rowData.itemGroup.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={125} align="left">
              <Table.HeaderCell>Kategoria</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => <span>{rowData.itemCategory?.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable>
              <Table.HeaderCell>Marka</Table.HeaderCell>
              <Table.Cell dataKey={"brand.name"}>
                {(rowData) => {
                  return <span>{rowData?.brand?.name ?? "-"}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable>
              <Table.HeaderCell>Netto</Table.HeaderCell>
              <Table.Cell dataKey={"price.amount"}>
                {(rowData) => {
                  return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell>VAT</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell>Brutto</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span>{Number(rowData.price.amountGross).toFixed(2)}</span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <img
                      src={settings}
                      style={{ cursor: "pointer" }}
                      alt={"Idź do"}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={ProductsConnection.getProducts}
        formGet={ProductsConnection.getFiltersProducts}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={"productsProduct"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "GTIN",
            stateKey: "gtin",
          },
          {
            type: FILTER_SELECT,
            label: "Kategoria",
            stateKey: "itemCategoryId",
            formKey: "itemCategories",
          },
          {
            type: FILTER_SELECT,
            label: "Marka",
            stateKey: "brandId",
            formKey: "brands",
          },
          {
            type: FILTER_SELECT,
            label: "Klient",
            stateKey: "customerId",
            formKey: "customers",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "ID produktu",
            stateKey: "itemId",
          },
        ]}
      />
    </>
  );
};

export default ProductsList;
