import VisitPhotoReview from "../../views/visitReviews/components/VisitPhotoReview";
import { IRouteEntity } from "../../utils/models";

const visitReviewRoutes: IRouteEntity[] = [
  {
    path: "/visit-reviews",
    breadcrumb: "Ocena zdjęć",
    Component: VisitPhotoReview,
  },
];

export default visitReviewRoutes;
