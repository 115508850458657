import React, { FunctionComponent, useState } from "react";
import { IGetRoles, IRoles } from "utils/models";
import { getRolesState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import RolesConnection from "utils/connections/roles";
import Table from "rsuite/Table";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import { handleSortColumn, handleToast } from "../../../../utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import IconButton from "../../../../global/atoms/IconButton";
import { Checkbox, Dropdown, Message } from "rsuite";
import { confirmModalCallback } from "../../../../redux/store";
import TrashIcon from "@rsuite/icons/Trash";

interface IRoleList {}

const RoleList: FunctionComponent<IRoleList> = () => {
  const [data, setData] = useState<IRoles | null>(null);
  const [filters, setFilters] = useState<IGetRoles>(getRolesState);
  const [loading, setLoading] = useState(true);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [selected, setSelected] = useState<Array<string>>([]);
  const history = useHistory();

  const handleEdit = (id: string) => {
    history.push(`/admin/roles/${id}/edit`);
  };
  const handleDeleteSelected = () => {
    confirmModalCallback(
      <Message
        type="warning"
        showIcon
        header={`Czy na pewno chcesz usunąć zaznaczone grupy? (${selected.length})`}>
        <div style={{ textAlign: "left", fontSize: "0.8em" }}>
          Operacja jest nieodwracalna!
          <br /> Usunięcie grupy, spowoduje odpięcie powiązań z Użytkownikiem,
          Projektem i Lokalizacją
        </div>
      </Message>,
      () => {
        const data = selected.map((id) => ({
          id: id,
        }));
        handleToast(RolesConnection.batchDelete(data)).then(() => {
          setTriggerLoad(Date.now());
          setSelected([]);
        });
      }
    );
  };
  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={"/admin/roles/add"}>
          <Dropdown.Item
            icon={<TrashIcon />}
            onClick={handleDeleteSelected}
            disabled={selected.length === 0}>
            Usuń zaznaczone grupy ({selected.length})
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            loading={loading}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={70} align="center">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={selected}
                  setState={setSelected}
                  keyIdName={"roleId"}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  if (rowData.roleType.type !== "ADMIN")
                    return (
                      <ToggleSingleCheckbox
                        state={selected}
                        setState={setSelected}
                        selectedId={rowData.roleId}
                      />
                    );
                  return <Checkbox disabled />;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="left" sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="roleName">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/admin/roles/${rowData.roleId}/edit`}>
                    {rowData.roleName}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Grupa</Table.HeaderCell>
              <Table.Cell dataKey="roleType">
                {(rowData) => {
                  return (
                    <span
                      className={styles.status}
                      style={{
                        color: rowData.roleType.color,
                        borderColor: rowData.roleType.color,
                      }}>
                      {rowData.roleType.name}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} sortable>
              <Table.HeaderCell>Priorytet grupy</Table.HeaderCell>
              <Table.Cell dataKey="hierarchyLevel" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Rap. czasu pracy</Table.HeaderCell>
              <Table.Cell dataKey="isEnableWorkTimeHistory">
                {(rowData) => (
                  <span
                    className={`${styles.status} ${
                      rowData.isEnableWorkTimeHistory ? styles.statusActive : ""
                    }`}>
                    {rowData.isEnableWorkTimeHistory ? "TAK" : "NIE"}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="center"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer gapSize={5}>
                    <IconButton
                      icon={"settings"}
                      onClick={handleEdit.bind(null, rowData.roleId)}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={RolesConnection.getRoles}
        formGet={RolesConnection.getFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getRolesState}
        setResultData={setData}
        filterStorageKey={"adminRoleList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
        ]}
      />
    </>
  );
};

export default RoleList;
