import React from "react";
import { FCC, SetState } from "utils/models";
import { Col, Form } from "rsuite";
import Section from "global/atoms/section/Section";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { ILocationProjectData, ILocationProjectForm } from "./_states";
import SelectPicker from "rsuite/SelectPicker";
import { Helmet } from "react-helmet-async";
import { inputMessage, InputText } from "./_helper";
import { deepClone } from "../../../../utils/helpers";

interface ILocationProjectEditProp {
  data: ILocationProjectData;
  setData: SetState<ILocationProjectData>;
  form: ILocationProjectForm;
  errors: any;
  setErrors: SetState<any>;
}

const LocationProjectForm: FCC<ILocationProjectEditProp> = ({
  data,
  setData,
  form,
  ...props
}) => {
  const isFormDisabled =
    form?.disabledElements?.includes("SAVE_LOCATION_CHANGES") ?? true;
  // const isUserFormDisabled = form?.disabledElements?.includes("SAVE_LOCATION_USERS_CHANGES") ?? true;

  if (form === undefined) return <Spinner />;

  return (
    <>
      <Section title={"Nowa lokalizacja"} titleSize={"1.1em"}>
        <Form
          fluid
          onChange={(rec) => {
            const _data = deepClone(rec);
            const _errors = deepClone(props.errors);
            setData((d) => ({ ...d, ...rec }));
            Object.keys(_errors).forEach((key) => {
              if (_data?.[key]) delete _errors[key];
            });
            props.setErrors(_errors);
          }}>
          <Col xs={20}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Nazwa</Form.ControlLabel>
              <Form.Control
                name="name"
                value={data.name}
                disabled={isFormDisabled}
              />
              {inputMessage("name", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="nip">
              <Form.ControlLabel>NIP</Form.ControlLabel>
              <Form.Control
                name="nip"
                value={data.nip}
                disabled={isFormDisabled}
              />
              {inputMessage("nip", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={24}>
            <Form.Group controlId="description">
              <Form.ControlLabel>Opis</Form.ControlLabel>
              <Form.Control
                accepter={InputText}
                name="description"
                value={data.description}
                disabled={isFormDisabled}
              />
              {inputMessage("description", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="locationTypeId">
              <Form.ControlLabel>Typ lokalizacji</Form.ControlLabel>
              <Form.Control
                block
                accepter={SelectPicker}
                placeholder={"Typ lokalizacji"}
                name={"locationTypeId"}
                labelKey={"name"}
                valueKey={"id"}
                value={data.locationTypeId}
                data={form.locationTypes.options ?? []}
                disabled={isFormDisabled}
              />
              {inputMessage("locationTypeId", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="networkId">
              <Form.ControlLabel>Sieć (lokalizacji)</Form.ControlLabel>
              <Form.Control
                block
                accepter={SelectPicker}
                placeholder={"Sieć (lokalizacji)"}
                name={"networkId"}
                labelKey={"name"}
                valueKey={"id"}
                value={data.networkId}
                data={form.networks.options ?? []}
                disabled={isFormDisabled}
              />
              {inputMessage("networkId", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="networkId">
              <Form.ControlLabel>Sieć projektowa</Form.ControlLabel>
              <Form.Control
                block
                accepter={SelectPicker}
                placeholder={"Sieć projektowa"}
                name={"projectLocationNetworkId"}
                labelKey={"name"}
                valueKey={"id"}
                value={data.projectLocationNetworkId}
                data={form.projectNetworks.options ?? []}
                disabled={isFormDisabled}
              />
              {inputMessage("projectLocationNetworkId", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="phone">
              <Form.ControlLabel>Telefon</Form.ControlLabel>
              <Form.Control
                name="phone"
                value={data.phone}
                disabled={isFormDisabled}
              />
              {inputMessage("phone", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="code">
              <Form.ControlLabel>Zewnętrzny kod lokalizacji</Form.ControlLabel>
              <Form.Control
                name="code"
                value={data.code}
                disabled={isFormDisabled}
              />
              {inputMessage("code", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="networkLocationCode">
              <Form.ControlLabel>Kod sieci dla lokalizacji</Form.ControlLabel>
              <Form.Control
                name="networkLocationCode"
                value={data.networkLocationCode}
                disabled={isFormDisabled}
              />
              {inputMessage("networkLocationCode", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="email">
              <Form.ControlLabel>
                Email (adresy rozdzielone średnikiem)
              </Form.ControlLabel>
              <Form.Control
                name="email"
                value={data.email}
                disabled={isFormDisabled}
              />
              {inputMessage("email", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            <Form.Group controlId="contactPerson">
              <Form.ControlLabel>Osoba do kontaktu</Form.ControlLabel>
              <Form.Control
                name="contactPerson"
                value={data.contactPerson}
                disabled={isFormDisabled}
              />
              {inputMessage("contactPerson", props.errors)}
            </Form.Group>
          </Col>
          <Col xs={8}>
            {data?.id && (
              <>
                <Form.Group controlId="incrementId">
                  <Form.ControlLabel>Numer porządkowy</Form.ControlLabel>
                  <Form.Control
                    name="incrementId"
                    value={data.incrementId}
                    disabled={true}
                  />
                  {inputMessage("contactPerson", props.errors)}
                </Form.Group>
              </>
            )}
          </Col>
          <SeparatorLine size={1.5} />
          {props.children}
          <SeparatorEmpty />
        </Form>
      </Section>
      <Helmet>
        <style>{`.rs-form-group{margin-top:15px}`}</style>
      </Helmet>
    </>
  );
};

export default LocationProjectForm;
