import React, { StrictMode } from "react";
import "./index.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import Security from "views/security/Security";
import Loader from "./Portal";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

// const container = document.getElementById("root");
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// const root = createRoot(container); // do not use it causing freezing issue with rsuite (v5) components
const root = document.getElementById("root");

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Security />
        <Loader />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  root
);
