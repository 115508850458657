import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import ReportTemplateConnection from "../../../../utils/connections/reportTemplate";
import { useParams } from "react-router-dom";
import { IconButton, Table } from "rsuite";
import ProgressBar from "../../../../global/atoms/ProgressBar";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localePl from "dayjs/locale/pl";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

interface IAsyncReportResults {
  reportType: number;
  reloadReports: boolean;
  setReloadReports: any;
}

interface IReportResult {
  id: string;
  filePath: string;
  templateName: string;
  progress: number;
  createdAt: string;
  fileName: string;
}

const AsyncReportResults: FunctionComponent<IAsyncReportResults> = ({
  reportType,
  setReloadReports,
  reloadReports,
}) => {
  dayjs.extend(relativeTime);
  dayjs.locale(localePl);

  const { id } = useParams<{ id: string }>();
  const [reports, setReports] = useState<Array<IReportResult>>([]);
  const [loading, setLoading] = useState(false);
  const interval = useRef<any>(null);

  const loadLatestReports = () => {
    setLoading(true);
    ReportTemplateConnection.getAsyncReportResults(id, reportType).then(
      (response) => {
        setReports(response.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadLatestReports();
  }, []);

  useEffect(() => {
    if (reloadReports) {
      loadLatestReports();
      setReloadReports(false);
    }
  }, [reloadReports]);

  useEffect(() => {
    loadLatestReports();
    interval.current = setInterval(() => {
      loadLatestReports();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleDownloadFile = (reportResultId: string, fileName: string) => {
    ReportTemplateConnection.getAsyncFile(reportResultId, fileName);
  };

  return (
    <>
      <Table loading={loading} autoHeight data={reports}>
        <Table.Column width={180} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Utworzono
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="createdDate">
            {(rowData) => {
              if (dayjs(rowData.createdDate).diff() * -1 < 1000 * 60) {
                return dayjs(rowData.createdDate).fromNow();
              }
              return rowData.createdDate;
            }}
          </Table.Cell>
        </Table.Column>

        <Table.Column minWidth={180} flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Szablon
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="templateName" />
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell depth={0} width={0}>
            Progres
          </Table.HeaderCell>
          <Table.Cell dataKey="progress">
            {(rowData) => <ProgressBar completed={rowData.progress} />}
          </Table.Cell>
        </Table.Column>

        <Table.Column width={120} align="right">
          <Table.HeaderCell depth={0} width={0}>
            Akcje
          </Table.HeaderCell>
          <Table.Cell width={120} depth={0} dataKey="progress">
            {(rowData: IReportResult) => (
              <div style={{ textAlign: "right" }}>
                {rowData.progress >= 100 && rowData.filePath !== "" && (
                  <IconButton
                    icon={<FileDownloadIcon color="rgba(255, 175, 56, 0.9)" />}
                    size={"lg"}
                    onClick={handleDownloadFile.bind(
                      null,
                      rowData.id,
                      rowData.fileName
                    )}
                    appearance={"subtle"}
                  />
                )}
                {rowData.progress >= 100 && rowData.filePath === "" && (
                  <span>Brak danych</span>
                )}
              </div>
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <SeparatorEmpty />
    </>
  );
};

export default AsyncReportResults;
