import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Message, Modal } from "rsuite";
import { deepClone, handleToast } from "utils/helpers";
import ProjectsConnection from "utils/connections/projects";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

import {
  defaultRequestData,
  optionsProject,
  optionsTask,
  renderOptions,
  requestDataSetOptionsChange,
} from "./CloneModalHelper";

export interface ICloneProjectModal {
  projectId: string;
  open: boolean;
  handleClose: (reload: boolean) => void;
  projectName: string;
}

function CloneProjectModal(props: ICloneProjectModal) {
  const options = [...optionsProject, ...optionsTask];
  const [requestData, setRequestData] = useState(defaultRequestData(options));

  useEffect(() => {
    setRequestData(defaultRequestData(options));
  }, [props.open]);

  const requestDataChange = (propName: string, checked: boolean, option) => {
    let s = deepClone(requestData);
    s = {
      ...s,
      ...requestDataSetOptionsChange(propName, checked, option, s, options),
    };
    s = { ...s, [propName]: checked };
    setRequestData((d) => ({ ...d, ...s }));
  };
  const onSubmit = () => {
    handleToast(
      ProjectsConnection.cloneProject(props.projectId, requestData)
    ).then(() => {
      props.handleClose(true);
    });
  };

  return (
    <Modal
      size={"lg"}
      open={props.open}
      onClose={() => props.handleClose(false)}>
      <Modal.Header>
        <Modal.Title>
          <div>
            <div>
              Kopiowanie projektu: <strong>{props.projectName}</strong>
            </div>
            <SeparatorEmpty />
            <Message type={"warning"} showIcon>
              Skopiowane zostaną wybrane elementy projektu.
            </Message>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderOptions(options, requestData, requestDataChange)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.handleClose(false)} appearance="ghost">
          Anuluj
        </Button>
        <Button onClick={onSubmit} appearance="primary">
          Utwórz kopie
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CloneProjectModal;
