import React, { CSSProperties, FunctionComponent } from "react";
import { IFilter } from "../ItemsList";
import BasicFilter from "./BasicFilter";
import { SelectPicker } from "rsuite";
import { IOptionBase, SetState } from "utils/models";

interface ITaskQuestionFilter {
  filter: IFilter;
  setFilter: SetState<IFilter>;
  customers: IOptionBase[];
}

const TaskQuestionFilter: FunctionComponent<ITaskQuestionFilter> = (props) => {
  // const [loading, setLoading] = useState(true);
  // const [customers, setCustomers] = useState<IOptionBase[]>([]);

  const wrapperStyle: CSSProperties = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "flex-end",
  };

  // useEffect(() => {
  //   ActivitiesConnection.getCustomers(props.allCustomerIds).then((res) => {
  //     setCustomers(res.data.filter(d => props.customerIds.includes(d.id)));
  //     setLoading(false);
  //   });
  // }, [])

  const handleSelected = (value: string | null) => {
    props.setFilter({
      ...props.filter,
      customerId: value ?? "",
    });
  };

  return (
    <div style={wrapperStyle}>
      <SelectPicker
        // disabled={loading}
        block
        // loading={loading}
        cleanable={true}
        searchable={false}
        placeholder={"Wybierz klienta"}
        name={"clientId"}
        labelKey={"name"}
        valueKey={"id"}
        style={{ width: "100%" }}
        value={props.filter.customerId}
        onChange={handleSelected}
        data={props.customers}
      />
      <BasicFilter
        filter={props.filter}
        setFilter={props.setFilter}
        maxWidth={"100%"}
      />
    </div>
  );
};

export default TaskQuestionFilter;
