import React, { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import settings from "../../../../assets/svg/settings.svg";
import { useHistory } from "react-router-dom";

interface IItem {
  id: string;
  name: string;
}
interface IItemBody {
  item: IItem;
  checked: Array<string>;
  handleToggleCheckbox: (itemId: string) => void;
}

const UserItemBody: FunctionComponent<IItemBody> = (props) => {
  const history = useHistory();
  const getCheckboxClass = (item: IItem) => {
    return `${styles.checkboxItem} ${
      props.checked.includes(item.id) ? styles.checked : ""
    }`;
  };

  return (
    <>
      <div className={styles.itemRow} key={props.item.id}>
        <div className={styles.wrapper}>
          <span
            data-id={props.item.id}
            onClick={props.handleToggleCheckbox.bind(null, props.item.id)}
            className={getCheckboxClass(props.item)}
          />
          <span className={styles.itemName}>{props.item.name}</span>
        </div>

        <button
          className={styles.button}
          onClick={() => history.push(`/admin/users/${props.item.id}/edit`)}>
          <img className={styles.actionIcon} src={settings} alt={"Idź do"} />
        </button>
      </div>
    </>
  );
};
export default UserItemBody;
