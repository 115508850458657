import React, { FunctionComponent, useState } from "react";
import { IGetCustomers } from "utils/models";
import { getCustomersState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import CustomersConnection from "utils/connections/customers";
import { getAddressFullName, handleSortColumn } from "utils/helpers";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import styles from "../rolesList/styles.module.scss";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ICustomersList {}

const CustomersList: FunctionComponent<ICustomersList> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IGetCustomers>(getCustomersState);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  const handleEdit = (event: any) => {
    history.push(`/admin/customers/${event.currentTarget.dataset.id}/edit`);
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: CustomersConnection.deleteCustomers,
            callback: () => {
              setTriggerLoad(Date.now());
            },
          }}
          addPath={"/admin/customers/add"}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Nazwa
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/admin/customers/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={300} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Adres
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="customerAddress">
                {(rowData) => {
                  return <span>{getAddressFullName(rowData.address)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Typ
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0} dataKey="customerTypeName">
                {(rowData) => <span>{rowData.customerType.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Email
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0} dataKey="email">
                {(rowData) => <span>{rowData.email}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={180} sortable>
              <Table.HeaderCell>Rap. czasu pracy</Table.HeaderCell>
              <Table.Cell dataKey="isEnableWorkTimeHistory">
                {(rowData) => (
                  <span
                    className={`${styles.status} ${
                      rowData.isEnableWorkTimeHistory ? styles.statusActive : ""
                    }`}>
                    {rowData.isEnableWorkTimeHistory ? "TAK" : "NIE"}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column verticalAlign={"middle"} align="right" fixed="right">
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={CustomersConnection.getCustomers}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getCustomersState}
        setResultData={setData}
        formGet={CustomersConnection.getListForm}
        filterStorageKey={"adminCustomers"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default CustomersList;
