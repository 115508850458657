import React, { FunctionComponent, useState } from "react";
import { IBrands, IGetBrands } from "utils/models";
import { getBrandsState } from "utils/states";
import BrandsConnection from "utils/connections/brands";
import Pagination from "global/pagination/Pagination";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { generatePath, useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { deepClone } from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import { ButtonSort } from "../../../../utils/sortHelper";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IBrandsList {}

const BrandsList: FunctionComponent<IBrandsList> = () => {
  const [data, setData] = useState<IBrands | null>(null);
  const defaultFilters = deepClone(getBrandsState);
  const [filters, setFilters] = useState<IGetBrands>(defaultFilters);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);

  const handleEdit = (event: any) => {
    history.push(
      generatePath("/products/brand/:id/edit", {
        id: event.currentTarget.dataset.id,
      })
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/products/brand/add"}
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: BrandsConnection.batchDelete,
            callback: () => {
              setChosenToDelete([]);
              setTriggerLoad(Date.now());
            },
          }}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table loading={loading} data={data?.data ?? []} fillHeight>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data ?? []}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"name"}
                  filters={filters}
                  setFilters={setFilters}>
                  Nazwa
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/products/brand/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <ActionsContainer>
                    <img
                      src={settings}
                      style={{ cursor: "pointer" }}
                      alt={"Idź do"}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={BrandsConnection.getBrands}
        formGet={BrandsConnection.getListForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        triggerLoad={triggerLoad}
        filterStorageKey={"productsBrandsList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
        ]}
      />
    </>
  );
};

export default BrandsList;
