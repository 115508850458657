import axios, { AxiosPromise, AxiosResponse } from "axios";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
} from "../../../../utils/helpers";
import AxiosHelper from "../../../../utils/AxiosHelper";
import { IProjectRole } from "../../../../utils/models";
import {
  ILocationProjectForm,
  IProjectLocationRequestData,
  IRolesRequest,
} from "./_states";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;

export interface IAssignUsersToProject {
  roles: Array<{
    roleId: string;
    assigned: Array<{ id: string }>;
    unassigned: Array<{ id: string }>;
  }>;
}

const ProjectLocationConnection = {
  getProject: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}`);
  },
  getFormAddNewProjectLocation: (
    projectId: string
  ): AxiosPromise<ILocationProjectForm> => {
    return AxiosHelper.get(`${baseUrl}/${projectId}/locations/form`);
  },
  getAssignedRoles: (
    projectId: string
  ): Promise<
    AxiosResponse<{ projectRoles: { data: Array<IProjectRole> } }>
  > => {
    return axios.get(`${baseUrl}/${projectId}/users/roles/assigned`, {
      headers: getAuthHeader(),
    });
  },
  getAssignedUsers: (projectId: string, roleId: string) => {
    return axios.get(`${baseUrl}/${projectId}/users/roles/${roleId}/assigned`, {
      headers: getAuthHeader(),
    });
  },
  getUnassignedUsers: (projectId: string, roleId: string) => {
    return axios.get(
      `${baseUrl}/${projectId}/users/roles/${roleId}/unassigned`,
      {
        headers: getAuthHeader(),
      }
    );
  },

  getFormEditRoles: (
    projectId: string
  ): Promise<
    AxiosResponse<{
      disabledElements: [];
      projectRoles: { options: Array<IProjectRole> };
    }>
  > => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/roles/form`
    );
  },
  createProjectNewLocation: (
    projectId: string,
    data: IProjectLocationRequestData
  ) => {
    return AxiosHelper.post(`${baseUrl}/${projectId}/locations`, data);
  },

  assignUsersToLocation: (
    projectId: string,
    locationId: string,
    data: IRolesRequest
  ) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/locations/${locationId}/users/roles`,
      data,
      { headers: getAuthHeader() }
    );
  },
};
export default ProjectLocationConnection;
