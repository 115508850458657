import React, { CSSProperties } from "react";
import styles from "../atoms.module.scss";
import { FCC } from "utils/models";

interface ISwitchButtonProps {
  onClick?: any;
  isChecked?: boolean;
  id?: any;
  style?: CSSProperties;
}

const SwitchButton: FCC<ISwitchButtonProps> = ({
  isChecked = false,
  onClick,
  children,
  id,
  style,
}) => {
  return (
    <div
      data-id={id}
      className={isChecked ? styles.switchButtonActive : styles.switchButton}
      style={style ?? {}}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default SwitchButton;
