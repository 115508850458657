import React, { FunctionComponent, useEffect, useState } from "react";
import Button from "rsuite/Button";
import SelectPicker from "rsuite/SelectPicker";
import Table from "rsuite/Table";
import Row from "rsuite/Row";
import Col from "rsuite/Col";
import TrashIcon from "@rsuite/icons/Trash";
import LocationsConnection from "../../../../utils/connections/locations";
import {
  IAddLocationCustomerRegion,
  ICreateLocation,
  ILocationCustomerRegion,
} from "../../../../utils/models";

import { handleToast } from "../../../../utils/helpers";

import { confirmModalCallback } from "../../../../redux/store";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { ILocationForm } from "./LocationForm";
import { RowDataType } from "rsuite/esm/Table";

interface ILocationRegionForm {
  locationState: ICreateLocation;
  setLocationState: (data: ICreateLocation) => void;
  locationForm: ILocationForm;
  disabled?: boolean;
}

const LocationRegionForm: FunctionComponent<ILocationRegionForm> = (props) => {
  //const {locationId} = useParams<{locationId: string}>();
  const [addState, setAddState] = useState<IAddLocationCustomerRegion>({
    regionId: null,
    customerId: null,
  });

  useEffect(() => {
    // console.log(props.locationForm.regions.options[0].id);
    if (props.locationForm.regions.options.length === 1) {
      setAddState((data) => ({
        ...data,
        regionId: props.locationForm.regions.options[0].id,
      }));
    }
    if (props.locationForm.customers.options.length === 1) {
      setAddState((data) => ({
        ...data,
        customerId: props.locationForm.customers.options[0].id,
      }));
    }
  }, []);

  const addNew = () => {
    props.setLocationState({
      ...props.locationState,
      locationCustomerRegions: [
        {
          locationId: props.locationForm.id,
          regionId: addState.regionId ?? "",
          customerId: addState.customerId ?? "",
          regionName:
            props.locationForm.regions.options.find(
              (co) => co.id === addState.regionId
            )?.name ?? "-",
          customerName:
            props.locationForm.customers.options.find(
              (co) => co.id === addState.customerId
            )?.name ?? "-",
        },
        ...props.locationState.locationCustomerRegions,
      ],
    });
  };

  const getData = (): Array<ILocationCustomerRegion> => {
    return props.locationState.locationCustomerRegions ?? [];
  };

  const filteredRegions = (): Array<any> => {
    return props.locationForm.regions?.options ?? [];
    //return props.locationForm.regions?.options.filter(c => (!(getData()?.map(d => d.regionId)??[]).includes(c.id))) ?? []
  };
  const filteredCustomers = (): Array<any> => {
    return (
      props.locationForm.customers?.options.filter(
        (c) => !(getData()?.map((d) => d.customerId) ?? []).includes(c.id)
      ) ?? []
    );
  };

  const removeItem = (itemId: string): void => {
    confirmModalCallback("Czy na pewno usunąć element?", () => {
      const promise =
        LocationsConnection.removeLocationCustomerRegionItem(itemId);
      handleToast(promise).then(() => {
        props.setLocationState({
          ...props.locationState,
          locationCustomerRegions: [
            ...props.locationState.locationCustomerRegions.filter(
              (lc) => lc.id !== itemId
            ),
          ],
        });
      });
    });
  };

  return (
    <Section title={"Region klienta dla lokalizacji"} titleSize={"1.1em"}>
      {!props.disabled && filteredCustomers().length === 0 ? (
        <div style={{ textAlign: "center", color: "#aaa" }}>
          Brak możliwości dodania przypisań regionów.
        </div>
      ) : props.disabled === true ? (
        <></>
      ) : (
        <Row>
          <Col xs={11}>
            <SelectPicker
              block
              cleanable={false}
              value={addState.customerId}
              readOnly={filteredCustomers().length === 1}
              data={
                filteredCustomers().map((c) => ({
                  label: c.name,
                  value: c.id,
                })) ?? []
              }
              placeholder={"Klient..."}
              onSelect={(customerId) => {
                setAddState((data) => ({
                  ...data,
                  customerId: customerId,
                }));
              }}
            />
          </Col>
          <Col xs={11}>
            <SelectPicker
              block
              cleanable={false}
              value={addState.regionId}
              readOnly={filteredRegions().length === 1}
              data={
                filteredRegions().map((c) => ({
                  label: c.name,
                  value: c.id,
                })) ?? []
              }
              placeholder={"Region..."}
              onSelect={(regionId) => {
                setAddState((data) => ({
                  ...data,
                  regionId: regionId,
                }));
              }}
            />
          </Col>
          <Col xs={2}>
            <Button
              type={"button"}
              disabled={
                Object.entries(addState).filter((val) => {
                  return val[1] !== null;
                }).length < Object.keys(addState).length
              }
              block
              appearance={"ghost"}
              onClick={addNew}>
              Dodaj
            </Button>
          </Col>
        </Row>
      )}
      <SeparatorEmpty size={1.5} />
      <Table autoHeight loading={getData() === null} data={getData() ?? []}>
        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell>Klient</Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="customerName" />
        </Table.Column>

        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Region
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="regionName" />
        </Table.Column>

        <Table.Column flexGrow={1} align="right">
          <Table.HeaderCell depth={0} width={0}>
            Opcje
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData: RowDataType<ILocationCustomerRegion>) => {
              if (props.disabled === true) return <></>;
              if (!rowData.id)
                return <small style={{ color: "#aaa" }}>Zapisz zmiany</small>;
              return (
                <TrashIcon
                  style={{ cursor: "pointer", color: "#ffaf38" }}
                  onClick={removeItem.bind(null, rowData?.id ?? "")}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Section>
  );
};

export default LocationRegionForm;
