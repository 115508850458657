import React, { FunctionComponent, useState } from "react";
import { IActivities } from "utils/models";
import ActivitiesConnection from "utils/connections/activities";
import { getActivitesState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import ActivitiesListHeader from "./ActivitiesListHeader";
import WhiteCard from "global/atoms/WhiteCard";
import ActivitiesListActivity from "./ActivitiesListActivity";
import UniversalFilters from "../../../../global/filters/UniversalFilters";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";

interface IActivitiesList {}

const ActivitiesList: FunctionComponent<IActivitiesList> = () => {
  const [data, setData] = useState<IActivities | null>(null);
  const [filters, setFilters] = useState(getActivitesState);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <WhiteCard mobileTransparent={true}>
        <ActivitiesListHeader />
        {loading ? (
          <SpinnerSmall />
        ) : data === null ? (
          <></>
        ) : (
          data.data.map((item) => (
            <ActivitiesListActivity
              id={item.id}
              key={item.id}
              name={item.name}
            />
          ))
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={ActivitiesConnection.getActivities}
        formGet={ActivitiesConnection.getFormFiltersActivities}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getActivitesState}
        setResultData={setData}
        filterStorageKey={"datasetsActivitiesList"}
        elements={[]}
      />
    </>
  );
};

export default ActivitiesList;
