import React, { FunctionComponent, useState } from "react";
import { IChains, IGetChains } from "utils/models";
import { getChainsState } from "utils/states";
import ChainsConnection from "utils/connections/chains";
import Pagination from "global/pagination/Pagination";
import Table from "rsuite/Table";
import arrow from "assets/svg/arrow_down_small.svg";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IChainsList {}

// todo: rename na network cały moduł
const ChainsList: FunctionComponent<IChainsList> = () => {
  const [data, setData] = useState<IChains | null | "error">(null);
  const [filters, setFilters] = useState<IGetChains>(getChainsState);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === "asc" ? "desc" : "asc",
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: "asc",
          },
        });
      }
    },
    handleEdit = (event: any) => {
      history.push(`/datasets/networks/${event.currentTarget.dataset.id}/edit`);
    };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/datasets/networks/add"}
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: ChainsConnection.batchDelete,
            callback: () => {
              setTriggerUpdate(Date.now());
              setChosenToDelete([]);
            },
          }}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table data={data?.data ?? []} fillHeight loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleToggleSort}
                  data-field={"name"}>
                  Nazwa
                  {filters.requestOrder.field === "name" &&
                    (filters.requestOrder.order === "asc" ? (
                      <img src={arrow} alt={"sort"} />
                    ) : (
                      <img
                        src={arrow}
                        alt={"sort"}
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ))}
                </span>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/datasets/networks/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <>
                    <ActionsContainer>
                      <img
                        src={settings}
                        style={{ cursor: "pointer" }}
                        alt={"Idź do"}
                        data-id={rowData.id}
                        onClick={handleEdit}
                      />
                    </ActionsContainer>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={ChainsConnection.getChains}
        formGet={ChainsConnection.getFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getChainsState}
        setResultData={setData}
        triggerLoad={triggerUpdate}
        filterStorageKey={"chainsLists"}
        elements={[
          {
            label: "Nazwa",
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
          },
        ]}
      />
    </>
  );
};

export default ChainsList;
