import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "../atoms.module.scss";
import arrowLeft from "../../../assets/svg/arrow_left.svg";
import arrowRight from "../../../assets/svg/arrow_right.svg";

interface ISelectSwitch {
  options: Array<{ id: string; name: string }>;
  stateKey: string;
  state: any;
  setState: React.Dispatch<any>;
  value: any;
}

const SelectSwitch: FunctionComponent<ISelectSwitch> = (props) => {
  const [arrDisabled, setArrDisabled] = useState({
    prev: false,
    next: false,
  });

  const arrDisabledEstablish = (optionId: string) => {
    const index = props.options.findIndex((o) => o.id == optionId);
    if (index === -1) return;

    const tmp = {
      prev: false,
      next: false,
    };

    if (index <= 0) tmp.prev = true;
    if (index >= props.options.length - 1) tmp.next = true;

    setArrDisabled(tmp);
  };

  useEffect(() => arrDisabledEstablish(props.state[props.stateKey]), []);
  useEffect(
    () => arrDisabledEstablish(props.state[props.stateKey]),
    [props.state[props.stateKey]]
  );

  const handleClick = (next: boolean) => {
    const index = props.options.findIndex(
      (o) => o.id == props.state[props.stateKey]
    );
    let newIndex: number;

    if (next) {
      if (index >= props.options.length - 1) newIndex = index;
      newIndex = index + 1;
    } else {
      if (index <= 0) newIndex = index;
      newIndex = index - 1;
    }

    props.setState({
      ...props.state,
      [props.stateKey]: props.options[newIndex].id,
    });
  };

  return (
    <>
      <div className={styles.selectMonth} style={{ userSelect: "none" }}>
        {!arrDisabled.prev ? (
          <img
            onClick={handleClick.bind(null, false)}
            className={styles.selectMonthButton}
            alt={"Poprzedni"}
            src={arrowLeft}
          />
        ) : (
          <div style={{ width: "32px" }} />
        )}

        <span>
          {props.options.find((o) => o.id == props.state[props.stateKey])?.name}
        </span>

        {!arrDisabled.next ? (
          <img
            onClick={handleClick.bind(null, true)}
            className={styles.selectMonthButton}
            style={{ filter: arrDisabled.next ? "greyscale(1)" : "none" }}
            alt={"Następny"}
            src={arrowRight}
          />
        ) : (
          <div style={{ width: "32px" }} />
        )}
      </div>
    </>
  );
};

export default SelectSwitch;
