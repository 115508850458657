import React, { CSSProperties } from "react";
import SpinnerSmall from "./SpinnerSmall";

interface ISpinnerProps {
  style?: CSSProperties;
  content?: string;
}

const Spinner: React.FC<ISpinnerProps> = ({ style, content }) => (
  <SpinnerSmall style={style} content={content} />
);

export default Spinner;
