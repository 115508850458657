import React, { FunctionComponent, useState } from "react";
import {
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
  handleSortColumn,
  handleToast,
} from "utils/helpers";
import { _paginate30 } from "utils/states";
import DocumentsConnection from "utils/connections/documents";
import { useParams } from "react-router-dom";
import { IHeader, IPagination, ISort } from "utils/models";
import { Button, Table } from "rsuite";
import BadgeStatus from "global/atoms/badge/BadgeStatus";
import Pagination from "global/pagination/Pagination";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  BatchActionButton,
  SingleActionButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import PermissionElementWrapper from "utils/PermissionElementWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import IconButton from "../../../../global/atoms/IconButton";
import styles from "../warehouse/styles.module.scss";

interface IDocumentData {
  deliveryDate: string;
  documentDate: string;
  documentId: string;
  documentNumber: string;
  saleType: any;
  location: any;
  paymentDate: string;
  paymentType: string;
  positions: any;
  user: any;
  externalDocumentNumber: string | null;
}

interface IDocumentsData {
  columns: any;
  header: IHeader;
  data: Array<IDocumentData>;
  count: number;
}

export interface IDocumentsFilters {
  documentNumber: string;
  userId: string;
  saleTypeId: string;
  paymentTypeId: string;
  locationId: string;
  payerId: string;
  recipientId: string;
  sellerId: string;
  dateFrom: number;
  dateTo: number;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

const DocumentsList: FunctionComponent = () => {
  const [data, setData] = useState<IDocumentsData | null>(null);
  const defaultFilters: IDocumentsFilters = {
    dateFrom: 0,
    dateTo: 0,
    documentNumber: "",
    saleTypeId: "",
    locationId: "",
    payerId: "",
    recipientId: "",
    sellerId: "",
    paymentTypeId: "",
    userId: "",
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IDocumentsFilters>(defaultFilters);
  const { id } = useParams<{ id: string }>();

  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  const handleCancelSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) toDelete = [{ id: deleteId }];

    handleToast(DocumentsConnection.batchDelete(id, toDelete)).then(() => {
      setChosenToDelete([]);
      setTriggerLoad(Date.now());
    });
  };

  const handleOpenInvoice = (documentId: string) => {
    const url = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${id}/sales/documents/${documentId}/invoice`;
    window.open(url, "_blank")?.focus();
  };

  const handleGetInvoicesZip = () => {
    DocumentsConnection.invoicesZip(id, chosenToDelete);
  };
  const locationFullAddressName = (address) => {
    return `${address?.street?.name} ${address?.houseNumber}${
      address?.apartmentNumber ? `/${address?.apartmentNumber}` : ""
    }, ${address?.postCode?.name} ${address?.city?.name}`;
  };
  return (
    <>
      <HeaderButtons>
        <PermissionElementWrapper name={"cancelSelectedButton"}>
          <BatchActionButton
            chosenRows={chosenToDelete}
            callback={handleCancelSelected}
            title={"Anuluj dokumenty"}
            message={"Czy na pewno anulować? "}
          />
        </PermissionElementWrapper>
        <PermissionElementWrapper name={"generateButton"}>
          <Button
            appearance={"primary"}
            onClick={handleGetInvoicesZip}
            disabled={!chosenToDelete.length}>
            Pobierz dokumenty zip
          </Button>
        </PermissionElementWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                  keyIdName={"documentId"}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.documentId}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={150} align="left" sortable>
              <Table.HeaderCell>
                <div style={{ display: "inline-block" }}>
                  <div>Numer</div>
                  <div className={styles.style_header}>numer zewnętrzny:</div>
                </div>
              </Table.HeaderCell>
              <Table.Cell dataKey="documentNumber">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/projects/${id}/documents/${rowData.documentId}/details`}>
                    <>
                      <div>{rowData.documentNumber}</div>
                      {rowData.externalDocumentNumber && (
                        <div className={styles.style}>
                          numer zewnętrzny: {rowData.externalDocumentNumber}
                        </div>
                      )}
                    </>
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={250} align="left" sortable>
              <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
              <Table.Cell dataKey="locationName">
                {(rowData) => {
                  return (
                    <>
                      <div>{rowData.location.name}</div>
                      <div className={styles.style}>
                        {locationFullAddressName(rowData.location?.address)}
                      </div>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={150} align="left" sortable>
              <Table.HeaderCell>Wystawił</Table.HeaderCell>
              <Table.Cell dataKey="fullName">
                {(rowData) => {
                  return <span>{rowData.user.fullName}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={100} align="left">
              <Table.HeaderCell>Netto</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={80}>
              <Table.HeaderCell>Vat</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell>Brutto</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span>{Number(rowData.price.amountGross).toFixed(2)}</span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={85} align="left" sortable>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="saleType">
                {(rowData) => (
                  <BadgeStatus color={rowData.status.color}>
                    {rowData.saleType.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column align="center" width={120} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey="documentDate" />
            </Table.Column>
            <Table.Column width={80} align="right">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <PermissionElementWrapper name={"generateButton"}>
                      <IconButton
                        disabled={rowData.invoicePath === null}
                        onClick={handleOpenInvoice.bind(
                          null,
                          rowData.documentId
                        )}
                        icon={"download"}
                        size={24}
                      />
                    </PermissionElementWrapper>
                    <PermissionElementWrapper name={"cancelSelectedButton"}>
                      <SingleActionButton
                        callback={handleCancelSelected.bind(
                          null,
                          rowData.documentId
                        )}
                        message={"Czy na pewno anulować? "}
                      />
                    </PermissionElementWrapper>
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={DocumentsConnection.list}
        formGet={DocumentsConnection.filterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectDocumentsList_${getProjectIdFromUrl()}`}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "documentNumber",
            label: "Numer",
          },
          {
            type: FILTER_SELECT,
            stateKey: "locationId",
            formKey: "locations",
            label: "Lokalizacja",
          },
          {
            type: FILTER_SELECT,
            stateKey: "payerId",
            formKey: "payers",
            label: "Płatnik",
          },
          {
            type: FILTER_SELECT,
            stateKey: "recipientId",
            formKey: "recipients",
            label: "Odbiorca",
          },
          {
            type: FILTER_SELECT,
            stateKey: "sellerId",
            formKey: "sellers",
            label: "Sprzedawca",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateFrom",
            label: "Data od",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateTo",
            label: "Data do",
          },
          {
            type: FILTER_SELECT,
            stateKey: "userId",
            formKey: "users",
            label: "Wystawił",
          },
          {
            type: FILTER_SELECT,
            stateKey: "saleTypeId",
            formKey: "saleTypes",
            label: "Rodzaj dokumentu",
          },
          {
            type: FILTER_SELECT,
            stateKey: "paymentTypeId",
            formKey: "paymentTypes",
            label: "Rodzaj płatności",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "postCodeName",
            label: "Kod pocztowy",
          },
          {
            type: FILTER_SELECT,
            stateKey: "voivodeshipId",
            formKey: "voivodeships",
            label: "Województwo",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "cityName",
            label: "Miejscowość",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "streetName",
            label: "Ulica",
          },

          {
            type: FILTER_INPUT_TEXT,
            stateKey: "districtName",
            label: "Powiat",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "communityName",
            label: "Gmina",
          },
        ]}
      />
    </>
  );
};

export default DocumentsList;
