import React, { FunctionComponent, useState } from "react";
import Modal from "rsuite/Modal";
import DatePicker from "rsuite/DatePicker";
import Col from "rsuite/Col";
import Button from "rsuite/Button";
import SchedulesConnection from "utils/connections/schedules";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import { handleToastRedux } from "../../../utils/helpers";
import download from "downloadjs";
import { useDispatch } from "react-redux";
import SpinnerSmall from "../../../global/atoms/Spinner/SpinnerSmall";

dayjs.locale(localePl);

export interface IScheduleExportData {
  dateFrom: Date;
  dateTo: Date;
}

export interface IExportScheduleModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
}

const scheduleExportData = {
  dateFrom: new Date(dayjs().valueOf()),
  dateTo: new Date(dayjs().add(1, "month").valueOf()),
};

const ModalExportSchedule: FunctionComponent<IExportScheduleModal> = (
  props
) => {
  const [modalData, setModalData] =
    useState<IScheduleExportData>(scheduleExportData);
  const [isWorking, setIsWorking] = useState(false);
  const dispatch = useDispatch();
  const handleFile = () => {
    setIsWorking(true);
    handleToastRedux(
      SchedulesConnection.getFile({ ...props.data, ...modalData }),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      props.setOpen(false);
      setIsWorking(false);
    });
  };

  return (
    <>
      <Modal
        {...props}
        size={"sm"}
        onClose={() => {
          props.setOpen(false);
        }}>
        <Modal.Header>
          <h4>Eksport harmonogramu - miesięczny</h4>
        </Modal.Header>

        <Modal.Body style={{ overflowX: "hidden" }}>
          {isWorking && (
            <SpinnerSmall
              style={{
                position: "absolute",
                textAlign: "center",
                width: "100%",
                zIndex: 10,
              }}
            />
          )}
          <Col xs={12}>
            Data od:
            <DatePicker
              isoWeek
              format={"yyyy-MM-dd"}
              oneTap
              value={modalData.dateFrom}
              block
              placeholder={"data Od.."}
              onChange={(value) =>
                setModalData({ ...modalData, dateFrom: value })
              }
              shouldDisableDate={(date) =>
                dayjs(date).isBefore(dayjs().add(-1, "day"))
              }
            />
          </Col>
          <Col xs={12}>
            Data do:
            <DatePicker
              isoWeek
              format={"yyyy-MM-dd"}
              oneTap
              value={modalData.dateTo}
              block
              placeholder={"data Do.."}
              onChange={(value) =>
                setModalData({ ...modalData, dateTo: value })
              }
              shouldDisableDate={(date) =>
                dayjs(date).isAfter(dayjs().add(1, "month")) ||
                dayjs(date).isBefore(modalData?.dateFrom ?? dayjs())
              }
            />
          </Col>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <Button appearance={"ghost"} onClick={handleFile}>
            Pobierz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalExportSchedule;
