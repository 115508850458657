import { IRouteEntity } from "utils/models";
import ContractServiceTypeList from "../../../views/contract/serviceType/ContractServiceTypeList";
import ContractServiceTypeEdit from "../../../views/contract/serviceType/ContractServiceTypeEdit";

const path = "/contracts/services/types";

const routes: IRouteEntity[] = [
  {
    path: `${path}`,
    breadcrumb: "Serwisy",
    Component: ContractServiceTypeList,
  },
  {
    path: `${path}/add`,
    breadcrumb: "Serwis dodaj",
    Component: ContractServiceTypeEdit,
  },
  {
    path: `${path}/:id/edit`,
    breadcrumb: "Serwis zmień",
    Component: ContractServiceTypeEdit,
  },
];

export default routes;
