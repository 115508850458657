import React, { FC } from "react";
import { useHistory } from "react-router-dom";

const GlobalReportsRedirectComponent: FC = () => {
  const history = useHistory();

  const menuPositions =
    JSON.parse(localStorage.getItem("menuPositions") ?? "[]") ?? [];

  const subMenu = menuPositions.find((m) => m.url === location.pathname);
  if (subMenu?.positions?.length) {
    history.replace(subMenu.positions[0]?.url);
  }
  return (
    <>
      <h6>Raporty globalne</h6>
    </>
  );
};

export default GlobalReportsRedirectComponent;
