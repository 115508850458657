import { SET_PROJECT } from "../actions";
import { IProject } from "../../utils/models";

const projectReducer = (
  state: undefined | null | IProject,
  action: any
): IProject | null => {
  if (action.type === SET_PROJECT) return action.payload;
  return state ?? null;
};

export default projectReducer;
