import React, { FunctionComponent } from "react";
import { IUserMultiSelectRawEntityParsed } from "../../../../utils/models";
import styles from "../styles.module.scss";

interface IUserGlobalItemBody {
  item: IUserMultiSelectRawEntityParsed;
  checked: Array<string>;
  handleToggleCheckbox: (itemId: string) => void;
}

const UserGlobalItemBody: FunctionComponent<IUserGlobalItemBody> = (props) => {
  const getCheckboxClass = (item: IUserMultiSelectRawEntityParsed) => {
    return `${styles.checkboxItem} ${
      props.checked.includes(item.id) ? styles.checked : ""
    }`;
  };

  return (
    <>
      <div className={styles.itemRow} key={props.item.userId}>
        <div className={styles.wrapper}>
          <span
            data-id={props.item.userId}
            onClick={props.handleToggleCheckbox.bind(null, props.item.userId)}
            className={getCheckboxClass(props.item)}
          />
          <span className={styles.itemName}>{props.item.userName}</span>
        </div>
      </div>
    </>
  );
};
export default UserGlobalItemBody;
