import React, { memo, useContext } from "react";
import { FCC, IPhotoReviewRequestData, IQuestionItem } from "utils/models";
import { TypeActivityType } from "utils/types";
import { IconButton } from "rsuite";
import TaskIcon from "@rsuite/icons/Task";
import { photoReviewRequestDataState } from "utils/states";
import VisitReviewsConnection from "utils/connections/visitReviews";
import dayjs from "dayjs";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";
import {
  IVisitEditPhotoContext,
  VisitEditPhotoContext,
} from "../../VisitEditContextWrapper";
import FormGroup from "rsuite/FormGroup";
import SeparatorEmpty from "../../../../../../../global/atoms/separators/SeparatorEmpty";

interface IVisitReviewButton {
  question: IQuestionItem;
  itemId: string | undefined;
  activityType: TypeActivityType;
  visitId: string;
}

const VisitReviewButton: FCC<IVisitReviewButton> = (props) => {
  // skip if no value or unsaved value
  if (
    props.question.questionType !== "PHOTO_QUESTION" ||
    props.question.values.filter((v) => v.refQuestionAnswerId !== "").length ===
      0
  ) {
    return <></>;
  }

  // no photo review for location activity
  if (props.activityType === "LOCATION") return <></>;

  const { setPhotoReviewModal } = useContext(
    VisitEditPhotoContext
  ) as IVisitEditPhotoContext;

  const onQuestionPhotoReview = () => {
    const requestData: IPhotoReviewRequestData = {
      ...photoReviewRequestDataState,
      visitId: props.visitId,
      dateRange: {
        dateFrom: "2000-01-01",
        dateTo: "3000-01-01",
      },
    };

    VisitReviewsConnection.getPhotoReviewData(requestData).then((result) => {
      const data = result.data;
      if (data.count === 1) {
        let phs = data.data[0].photoSimple;
        phs = phs.filter(
          (ph) =>
            (props.itemId ? ph.itemId === props.itemId : true) &&
            ph.visitQuestionId === props.question.refQuestionId
        );

        setPhotoReviewModal({
          open: true,
          photoSimple: phs,
          visit: data.data[0].visit,
          toggler: dayjs().unix(),
          nextId: null,
          prevId: null,
        });
      } else {
        ToastNotificationPush(
          ToastTypes.error,
          "Nieoczekiwana odpowiedź serwera"
        );
      }
    });
  };

  return (
    <FormGroup>
      <IconButton
        icon={<TaskIcon color={"#ffaf38"} />}
        onClick={onQuestionPhotoReview}
        size={"xs"}>
        Oceń zdjęcia
      </IconButton>
      <SeparatorEmpty size={0.3} />
    </FormGroup>
  );
};

export default memo(VisitReviewButton);
