import DoubleSidePicker from "global/atoms/dooubleSidePicker/DoubleSidePicker";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import _ from "lodash";
import React, { FunctionComponent, useState } from "react";
import { Button, Modal, Panel, SelectPicker } from "rsuite";
import TagPicker from "rsuite/esm/TagPicker";
import {
  IAlertEntityActivities,
  IAlertEntityStore,
  SetState,
} from "utils/models";
import { IAlertFormActivityQuestion } from "./AlertForm";
import { BOOLEAN_QUESTION } from "../../visits/visitsEdit/VisitElements/QuestionTypeConsts";

const QUESTION_OPTIONS = [
  { label: "Dowolna odpowiedź", value: -1 },
  { label: "TAK", value: 1 },
  { label: "NIE", value: 0 },
];

interface IAlertFormActivities {
  disabled?: boolean;
  stateActivities: Array<IAlertEntityActivities>;
  setAlertState: SetState<IAlertEntityStore>;
  formActivities: Array<IAlertFormActivityQuestion> | null;
  questionPickerDisabled: boolean;
}

interface IAlertQuestionSettings extends IAlertFormActivityQuestion {
  answerBoolNumValue: -1 | 0 | 1;
  answerIncludeBoolNumValue: -1 | 0 | 1;
}

const AlertFormActivities: FunctionComponent<IAlertFormActivities> = (
  props
) => {
  const [questionSettingsState, setQuestionSettingsState] =
    useState<null | IAlertQuestionSettings>(null);
  const [questionItemClear, setQuestionItemClear] = useState(0);
  // const { alertId } = useParams<{ alertId: string }>();

  const getActivityByPrefix = (activityPrefix: string) => {
    return props.stateActivities.find(
      (activity) => activity.prefix === activityPrefix
    );
  };

  const getActivities = (): Array<{
    value: string;
    label: string;
    taskName: string;
  }> => {
    if (!props.formActivities) return [];
    const res: Array<{ value: string; label: string; taskName: string }> = [];
    props.formActivities.forEach((u) => {
      if (
        res.find((activity) => activity.value === u.activityPrefix) ===
        undefined
      ) {
        res.push({
          value: u.activityPrefix,
          label: u.activityName + " - " + u.taskName,
          taskName: u.taskName,
        });
      }
    });
    return res;
  };

  const findActivityName = (activityPrefix: string): string => {
    if (!props.formActivities) return activityPrefix;
    return (
      props.formActivities.find(
        (item) => item.activityPrefix === activityPrefix
      )?.activityName ?? activityPrefix
    );
  };

  const findActivityType = (activityPrefix: string): string => {
    if (!props.formActivities) return "";
    return (
      props.formActivities.find(
        (item) => item.activityPrefix === activityPrefix
      )?.activityType ?? ""
    );
  };

  const handleActivityChange = (selected: Array<string> | null) => {
    const tmp: IAlertEntityActivities[] = [];
    if (selected) {
      selected.forEach((activityPrefix) => {
        const currentActivity = props.stateActivities.find(
          (activity) => activity.prefix === activityPrefix
        );
        if (currentActivity === undefined) {
          tmp.push({
            prefix: activityPrefix,
            activityType: findActivityType(activityPrefix),
            activityQuestions: undefined,
          });
        } else {
          tmp.push(currentActivity);
        }
      });
    }
    props.setAlertState((s) => ({
      ...s,
      activities: tmp,
    }));
  };

  const getActivityQuestions = (
    activityPrefix: string
  ): {
    assigned: IAlertFormActivityQuestion[];
    unassigned: IAlertFormActivityQuestion[];
  } => {
    if (!props.formActivities) return { assigned: [], unassigned: [] };

    const stateActivityQuestions =
      getActivityByPrefix(activityPrefix)?.activityQuestions?.map(
        (a) => a.prefix
      ) ?? [];
    const allActivityQuestions = props.formActivities.filter(
      (a) => a.activityPrefix === activityPrefix
    );

    return {
      assigned:
        allActivityQuestions.filter((a) =>
          stateActivityQuestions.includes(a.activityQuestionPrefix)
        ) ?? [],
      unassigned:
        allActivityQuestions.filter(
          (a) => !stateActivityQuestions.includes(a.activityQuestionPrefix)
        ) ?? [],
    };
  };

  const activityQuestionsPickerOnChange = (
    activityPrefix: string,
    assignedIds: string[],
    unAssignedIds: string[]
  ) => {
    const activity = getActivityByPrefix(activityPrefix);
    if (!activity || !props.formActivities) {
      throw "Undefined activity/form error!";
    }

    if (assignedIds.length > 0) {
      if (activity.activityQuestions === undefined)
        activity.activityQuestions = [];

      props.formActivities
        .filter((a) => a.activityPrefix === activityPrefix) // only from current activity
        .filter((a) => assignedIds.includes(a.activityQuestionPrefix)) // only selected questions
        .filter(
          (a) =>
            !activity.activityQuestions
              ?.map((aq) => aq.prefix)
              .includes(a.activityQuestionPrefix)
        ) // skip already added
        .forEach((aq) => {
          if (activity.activityQuestions) {
            activity.activityQuestions.push({
              prefix: aq.activityQuestionPrefix,
              boolAnswerValue: null,
              boolAnswerIncludedValue: null,
            });
          }
        });
    }

    if (unAssignedIds.length > 0) {
      activity.activityQuestions =
        activity.activityQuestions?.filter(
          (a) => !unAssignedIds.includes(a.prefix)
        ) ?? [];
    }

    props.setAlertState((s) => {
      const _state = _.cloneDeep(s);
      const activityIndex = _state.activities.findIndex(
        (a) => a.prefix === activityPrefix
      );
      if (activityIndex < -1) throw "Undefined activity";
      _state.activities[activityIndex] = activity;
      return _state;
    });
  };

  const questionSettings = (activityQuestionPrefix: string) => {
    const data =
      props.formActivities?.find(
        (a) => a.activityQuestionPrefix === activityQuestionPrefix
      ) ?? null;

    let answerValue: -1 | 0 | 1 = -1;
    props.stateActivities.forEach((a) => {
      a.activityQuestions?.forEach((q) => {
        if (q.prefix === activityQuestionPrefix) {
          if (q.boolAnswerValue !== null) {
            answerValue = q.boolAnswerValue ? 1 : 0;
          }
        }
      });
    });

    let answerIncludeValue: -1 | 0 | 1 = -1;
    props.stateActivities.forEach((a) => {
      a.activityQuestions?.forEach((q) => {
        if (q.prefix === activityQuestionPrefix) {
          if (q.boolAnswerIncludedValue !== null) {
            answerIncludeValue = q.boolAnswerIncludedValue ? 1 : 0;
          }
        }
      });
    });

    setQuestionSettingsState(
      data
        ? {
            ...data,
            answerBoolNumValue: answerValue,
            answerIncludeBoolNumValue: answerIncludeValue,
          }
        : null
    );
  };

  const questionModalSubmit = () => {
    props.setAlertState((s) => {
      const _activities = _.cloneDeep(s.activities);
      const activityIndex = _activities.findIndex(
        (a) => a.prefix === questionSettingsState?.activityPrefix
      );
      const stateQuestionIndex = _activities[
        activityIndex
      ]?.activityQuestions?.findIndex(
        (q) => q.prefix === questionSettingsState?.activityQuestionPrefix
      );
      if ((stateQuestionIndex ?? -1) > -1) {
        try {
          // @ts-ignore
          _activities[activityIndex].activityQuestions[
            stateQuestionIndex ?? ""
          ].boolAnswerValue =
            questionSettingsState?.answerBoolNumValue == -1
              ? null
              : questionSettingsState?.answerBoolNumValue == 1;

          // @ts-ignore
          _activities[activityIndex].activityQuestions[
            stateQuestionIndex ?? ""
          ].boolAnswerIncludedValue =
            questionSettingsState?.answerIncludeBoolNumValue == -1
              ? null
              : questionSettingsState?.answerIncludeBoolNumValue == 1;
        } catch (e) {
          console.warn("questionModalSubmit error", e);
        }
      }

      return {
        ...s,
        activities: _activities,
      };
    });

    questionModalClose();
  };

  const questionModalClose = () => {
    setQuestionItemClear(Date.now);
    setQuestionSettingsState(null);
  };

  const getQuestionPickerType = (
    activityPrefix: string
  ): "activity" | "questions" => {
    const activityQuestion =
      getActivityByPrefix(activityPrefix)?.activityQuestions;
    return activityQuestion === undefined ? "activity" : "questions";
  };

  return (
    <Panel
      shaded
      header={
        <>
          {"Zadania"}{" "}
          <small style={{ color: "#aaa" }}>{"(Aktywność - Zadanie)"}</small>
        </>
      }>
      <Modal
        keyboard={false}
        open={questionSettingsState !== null}
        onClose={questionModalClose}>
        <Modal.Header>
          <Modal.Title>Zdefiniuj oczekiwaną odpowiedź na pytanie</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {questionSettingsState?.questionType !== BOOLEAN_QUESTION ? (
            <div>-brak opcji-</div>
          ) : (
            <div>
              {questionSettingsState?.activityType !== "ITEM" && (
                <>
                  <small>
                    Powiadomienie generowane dla odpowiedzi na pytanie:
                  </small>
                  <SelectPicker
                    block
                    disabled={props.disabled}
                    cleanable={false}
                    searchable={false}
                    value={questionSettingsState.answerBoolNumValue}
                    valueKey={"value"}
                    // @ts-ignore
                    onChange={(selected: 1 | 0 | -1) => {
                      setQuestionSettingsState((s) => {
                        if (!s) return null;
                        return {
                          ...s,
                          answerBoolNumValue: selected,
                        };
                      });
                    }}
                    // @ts-ignore
                    data={QUESTION_OPTIONS}
              />
                </>
              )}
              {questionSettingsState?.activityType == "ITEM" && (
              <>  <small>Pokazuj tylko produkty z odpowiedzią:</small>
                  <SelectPicker
                    block
                    disabled={props.disabled}
                    cleanable={false}
                    searchable={false}
                    value={questionSettingsState.answerIncludeBoolNumValue}
                    valueKey={"value"}
                    // @ts-ignore
                    onChange={(selected: 1 | 0 | -1) => {
                      setQuestionSettingsState((s) => {
                        if (!s) return null;
                        return {
                          ...s,
                          answerIncludeBoolNumValue: selected,
                        };
                      });
                    }}
                    // @ts-ignore
                    data={QUESTION_OPTIONS}
                  />
            </>
          )}
            </div>
          )}
        </Modal.Body>
        {!props.disabled && (
          <Modal.Footer>
            <Button
              onClick={questionModalSubmit}
              disabled={
                questionSettingsState?.questionType !== BOOLEAN_QUESTION
              }
              appearance="primary">
              Zatwierdź
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <TagPicker
        style={{ width: "100%" }}
        disabled={props.disabled}
        placeholder={"Wszystkie zadania"}
        value={props.stateActivities.map((activity) => activity.prefix)}
        onChange={handleActivityChange}
        data={getActivities()}
        groupBy={"taskName"}
      />

      <SeparatorEmpty size={2} />

      {props.stateActivities.map((activity) => activity.prefix).length > 0 &&
        props.stateActivities.map((activity) => {
          const activityPrefix = activity.prefix;
          const activityQuestions = getActivityQuestions(activityPrefix);

          return (
            <div
              key={`picker-${activityPrefix}`}
              style={{ marginLeft: "25px" }}>
              <SeparatorEmpty size={1} />
              <strong>{findActivityName(activityPrefix)}</strong>&nbsp;-&nbsp;
              <SelectPicker
                data={[
                  { value: "activity", label: "Wszystkie pytania" },
                  { value: "questions", label: "Wybrane pytania" },
                ]}
                size={"xs"}
                cleanable={false}
                searchable={false}
                disabled={props.disabled} //props.questionPickerDisabled
                value={getQuestionPickerType(activityPrefix)}
                onChange={(value) => {
                  props.setAlertState((prevState) => {
                    const tmpActivities = _.cloneDeep(prevState.activities);
                    const currentActivity = tmpActivities.find(
                      (activity) => activity.prefix === activityPrefix
                    );
                    if (currentActivity) {
                      currentActivity.activityQuestions =
                        value?.toString() === "activity" ? undefined : [];
                    }

                    return { ...prevState, activities: tmpActivities };
                  });
                }}
              />
              {getQuestionPickerType(activityPrefix) === "questions" && (
                <>
                  <SeparatorEmpty size={0.5} />
                  <DoubleSidePicker
                    form={props.formActivities}
                    filtersHidden={true}
                    itemType={"taskQuestionAlert"}
                    disabled={props.disabled}
                    emptyMessageAssigned={"Brak przypisanych pytań"}
                    emptyMessageNotAssigned={"Brak pytań w systemie"}
                    pickedItemCallback={questionSettings}
                    clearPickedItemTrigger={questionItemClear}
                    assigned={activityQuestions.assigned}
                    unassigned={activityQuestions.unassigned}
                    // unassigned={activityQuestions.unassigned.filter(
                    //   (q) => q.questionType == BOOLEAN_QUESTION
                    // )}
                    onChange={(assignedIds, unAssignedIds) =>
                      activityQuestionsPickerOnChange(
                        activityPrefix,
                        assignedIds,
                        unAssignedIds
                      )
                    }
                  />
                </>
              )}
            </div>
          );
        })}
    </Panel>
  );
};

export default AlertFormActivities;
