import React, { useEffect, useState } from "react";
import WhiteCard from "../../../../../global/atoms/WhiteCard";
import Section from "../../../../../global/atoms/section/Section";
import styles from "../../visitsReport/styles.module.scss";
import { deepClone, handleToast } from "../../../../../utils/helpers";
import Input from "../../../../../global/atoms/Input";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import ActionsContainer from "../../../../../global/atoms/ActionsContainer";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../../../global/atoms/Spinner/Spinner";
import ReportTemplateConnection from "../../../../../utils/connections/reportTemplate";
import ButtonGoBack from "../../../../../global/atoms/ButtonGoBack";
import { Checkbox, Col, Dropdown, IconButton, Row } from "rsuite";
import { FCC, SetState } from "utils/models";
import SortableList from "../../../../../global/atoms/dnd/SortableList";
import HeaderButtons from "../../../../../global/atoms/headerButtons/HeaderButtons";
import NavOptionsWrapper from "../../../../../utils/NavOptionsWrapper";
import { DragHandle } from "../../../../../global/atoms/SortableItem";
import FunnelIcon from "@rsuite/icons/Funnel";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmModalCallback } from "../../../../../redux/store";
import EditIcon from "@rsuite/icons/Edit";

export interface IReportTemplateData {
  name: string;
  data: any;
  projectId: string;
  type: number;
}

export type columnsDataOptionsType = Array<{
  name: string;
  label: string;
  active: boolean;
}>;

export type columnsDataType = Array<{
  label: string;
  name: string;
  active: boolean;
  options: columnsDataOptionsType;
}>;

export interface IBaseTemplateState {
  templateName: string;
  columnsData: columnsDataType;
}

export interface IBaseTemplate {
  state: IBaseTemplateState;
  setState: SetState<IBaseTemplateState>;
  type: number;
  initialState?: IBaseTemplateState;
  sortable?: boolean;
}

const BaseTemplate: FCC<IBaseTemplate> = ({
  state,
  setState,
  children,
  type,
  initialState,
  sortable = false,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const { id, templateId } = useParams<{ id: string; templateId: string }>();
  const history = useHistory();

  useEffect(() => {
    if (templateId) {
      handleToast(ReportTemplateConnection.getTemplateById(templateId)).then(
        (response) => {
          const data = response.data;
          setState({
            templateName: data.name,
            columnsData: data.data.columnsData,
          });
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, []);

  const handleToggleSection = (index, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].active = checked;
    setState({ ...state, columnsData: dataClone });
  };
  const toggleAll = (section): void => {
    let toSet = false;
    if (!allSelected(section)) toSet = true;
    const newOptions = section.options.map((el) => ({ ...el, active: toSet }));
    const ColumnsData = deepClone(state.columnsData);
    const newColumnsData = ColumnsData.map((s) =>
      s.name == section.name ? { ...s, options: newOptions } : s
    );
    setState((s) => ({ ...s, columnsData: newColumnsData }));
  };

  const allSelected = (item) => {
    if (item.length == 0) return undefined;
    return (
      item?.options?.filter((e: any) => e.active).length == item.options.length
    );
  };

  const handleToggleOption = (index, value, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].options[value].active = checked;
    setState({ ...state, columnsData: dataClone });
  };
  const redirectUrl = `/projects/${id}/data-exports/${type}`;
  const handleSave = () => {
    if (state.templateName === "") {
      return false;
    }

    const dataPost = {
      projectId: id,
      name: state.templateName,
      data: state,
      type: type,
    };

    if (templateId !== undefined) {
      handleToast(
        ReportTemplateConnection.updateTemplate(templateId, dataPost)
      ).then(() => {
        history.push(redirectUrl);
      });
      return true;
    }
    handleToast(ReportTemplateConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };
  const onSortEnd = (index, sortedOptions) => {
    const data = deepClone(state);
    data.columnsData[index].options = sortedOptions;
    setState(data);
  };

  if (loading) return <Spinner />;
  const handleDelete = () => {
    confirmModalCallback(`Czy chcesz usunąć szablon`, () => {
      handleToast(
        ReportTemplateConnection.removeTemplate(templateId).then(() => {
          history.push(redirectUrl);
        }),
        undefined,
        `Błąd! szablon "${state.templateName} nie został usunięty"`,
        `Usunięto szablon "${state.templateName}"`
      );
    });
  };
  return (
    <>
      <HeaderButtons>
        <ActionsContainer>
          {templateId && (
            <IconButton
              appearance={"ghost"}
              onClick={handleDelete}
              icon={<TrashIcon />}>
              Usuń szablon
            </IconButton>
          )}
          {!!initialState && templateId && (
            <IconButton
              appearance={"ghost"}
              disabled={!initialState}
              onClick={() => {
                setState({ ...state, columnsData: initialState.columnsData });
              }}
              icon={<FunnelIcon />}>
              Ustaw wartości domyślne
            </IconButton>
          )}
          <ButtonGoBack>Anuluj</ButtonGoBack>
          <IconButton
            icon={<EditIcon />}
            appearance={"primary"}
            onClick={handleSave}
            disabled={!state.templateName}>
            Zapisz
          </IconButton>
        </ActionsContainer>
        <NavOptionsWrapper>
          {!!initialState && (
            <Dropdown.Item
              onClick={() => {
                setState(initialState);
              }}
              icon={<FunnelIcon />}>
              Resetuj formularz
            </Dropdown.Item>
          )}
        </NavOptionsWrapper>
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={24}>
            <Input
              name={"templateName"}
              value={state.templateName}
              placeholder={"Nazwa szablonu"}
              setState={setState}
              state={state}
              customError={
                !state.templateName ? "*Pole nazwa jest wymagane" : ""
              }
            />
          </Col>
        </Row>
        <Row>
          {state.columnsData.map((item, index) => (
            <>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} key={index}>
                <SeparatorEmpty />
                <Section title={item.label} className={styles.sectionContainer}>
                  <div className={!item.active ? styles.sectionDisabled : ""} />
                  <Checkbox
                    style={{ margin: 0 }}
                    className={styles.topRightCornerCheckbox}
                    checked={item.active}
                    value={index}
                    onChange={handleToggleSection}
                  />
                  <Checkbox
                    checked={allSelected(item)}
                    value={index}
                    onChange={toggleAll.bind(null, item)}>
                    Zaznacz/Odznacz wszystkie
                  </Checkbox>
                  <SortableList
                    dragHandleActivator
                    disabled={!sortable}
                    onSortEnd={onSortEnd.bind(null, index)}
                    data={item.options}
                    idKeyName={"name"}
                    mapFunction={(option, indexOption) => (
                      <div
                        key={`${index}-${indexOption}`}
                        style={{ display: "flex" }}>
                        <Checkbox
                          style={{ width: "100%" }}
                          key={index + " " + indexOption}
                          checked={option.active}
                          value={indexOption}
                          onChange={handleToggleOption.bind(null, index)}>
                          {option.label}
                        </Checkbox>
                        {sortable && <DragHandle />}
                      </div>
                    )}
                  />
                </Section>
              </Col>
            </>
          ))}
        </Row>
        {children}
        <SeparatorEmpty size={1.5} />
      </WhiteCard>
    </>
  );
};

export default BaseTemplate;
