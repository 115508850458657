import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  IContractEquipmentFilterFormModel,
  IContractEquipmentModel,
} from "../../models/contract/contractEquipmentModels";

export const path = `/contracts/equipments`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractEquipmentConnection = {
  getContractEquipment: (data: IContractEquipmentFilterFormModel) => {
    return AxiosHelper.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContractEquipment: (data: IContractEquipmentModel) => {
    return AxiosHelper.post(baseUrl, data, {
      headers: getAuthHeader(),
    });
  },
  updateContractEquipment: (id: string, data: IContractEquipmentModel) => {
    return AxiosHelper.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersContractEquipment: () => {
    return AxiosHelper.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddContractEquipment: () => {
    return AxiosHelper.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractEquipment: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return AxiosHelper.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
  delete: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}`, { ids: data });
  },
  getListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  getFilterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },
};

export default ContractEquipmentConnection;
