import React, { FunctionComponent, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ICreateProject } from "utils/models";
import { createProjectState } from "utils/states";
import ProjectsConnection from "utils/connections/projects";
import { handleSetErrors, handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import Input from "global/atoms/Input";
import Select from "global/atoms/Select";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import LocalDatePicker from "../../../../global/atoms/LocalDatePicker";
import { beforeToday } from "rsuite/esm/DateRangePicker/disabledDateUtils";

interface IAddProject {}

const AddProject: FunctionComponent<IAddProject> = () => {
  const [data, setData] = useState<ICreateProject>(createProjectState);
  const [form, setForm] = useState<any>("loading");
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    ProjectsConnection.getFormCreateProject()
      .then((data) => {
        setForm(data.data);
        data.data?.header &&
          dispatch({ type: "SET_HEADER", payload: data.data.header });
      })
      .catch((err) => handleSetErrors(err, setErrors));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(ProjectsConnection.createProject(data), setErrors).then(
      (data) => {
        history.push(
          generatePath("/projects/:id/edit/basic/?trip", {
            id: data.data.projectId,
          })
        );
      }
    );
  };

  if (form === "loading") return null;

  return (
    <>
      <WhiteCard padding={true}>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapperFields}>
            <Input
              style={{ gridColumn: "span 2" }}
              type={"text"}
              name={"name"}
              value={data.name}
              placeholder={"Nazwa"}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={"Klient"}
              disabled={false}
              name={"customerId"}
              value={data.customerId}
              options={form.customers.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={"Status"}
              disabled={false}
              name={"projectStatusId"}
              value={data.projectStatusId}
              options={form.projectStatuses.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <LocalDatePicker
              label={"Data rozpoczęcia"}
              state={data}
              value={
                data.startDate ? new Date(data.startDate * 1000) : new Date()
              }
              stateKey={"startDate"}
              emptyValue={null}
              setState={setData}
              errors={errors}
              disabledDate={beforeToday()}
            />

            <LocalDatePicker
              label={"Data zakończenia"}
              state={data}
              stateKey={"endDate"}
              emptyValue={null}
              setState={setData}
              errors={errors}
              disabledDate={(date: Date) =>
                date < new Date(new Date().toISOString().slice(0, 10)) ||
                date < new Date((data.startDate ?? 0) * 1000)
              }
            />
          </div>
          <ActionsContainer style={{ marginTop: "16px" }}>
            <ButtonSubmit />
          </ActionsContainer>
        </form>
      </WhiteCard>
    </>
  );
};

export default AddProject;
