import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { ICreateBrand, IGetBrands } from "../models";
import { AxiosPromise } from "axios";

const BrandsConnection = {
  getBrands: (data: IGetBrands) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createBrand: (data: ICreateBrand) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateBrand: (id: string, data: ICreateBrand) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/${id}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFiltersBrandsList: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormAddBrand: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditBrand: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands`,
      {
        headers: { ...getAuthHeader() },
        data: { brands: data },
      }
    );
  },
  getListForm: (): AxiosPromise => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/brands/list/form`
    );
  },
};

export default BrandsConnection;
