import React, { FunctionComponent, useEffect, useState } from "react";
import { IQuestionItem, SetState } from "utils/models";
import ProjectLocationConnection from "./_connections";
import {
  doubleSidePickerOnChange,
  handleSetErrors,
  handleToast,
} from "utils/helpers";
import ActionsContainer from "global/atoms/ActionsContainer";
import { Col, IconButton, Steps } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import _ from "lodash";
import LocationProjectForm from "./LocationProjectForm";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { useParams } from "react-router-dom";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import AddressSelectSection from "./AddressSelectSection";
import {
  createLocationProjectState,
  defaultQuestions,
  ILocationAddress,
  ILocationProjectCustomerData,
  ILocationProjectData,
  ILocationProjectForm,
  ILocationProjectRegionData,
  ILocationRoles,
  IProjectLocationRequestData,
  IQuestionsData,
  IRolesRequest,
  locationAddress,
} from "./_states";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import LocationProjectRegionForm from "./LocationProjectRegionForm";
import LocationProjectCustomerForm from "./LocationProjectCustomerForm";

interface ILocationProjectModalAddNew {
  setOpen: SetState<boolean>;
  setRefLocationId: SetState<string>;
}

const LocationProjectModalAddNew: FunctionComponent<
  ILocationProjectModalAddNew
> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [locationData, setLocationData] = useState<ILocationProjectData>(
    createLocationProjectState as ILocationProjectData
  );
  const [locationAddressData, setLocationAddressData] =
    useState<ILocationAddress>(locationAddress);
  const [customerData, setCustomerData] = useState<
    Array<ILocationProjectCustomerData>
  >([]);
  const [regionData, setRegionData] = useState<
    Array<ILocationProjectRegionData>
  >([]);
  const [locationQuestionsData, setLocationQuestionsData] =
    useState<IQuestionsData>(defaultQuestions);
  const [form, setForm] = useState<ILocationProjectForm>();
  const [users, setUsers] = useState<ILocationRoles>();
  const [errors, setErrors] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pickedQuestion, setPickedQuestion] = useState<IQuestionItem>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clearPickedQuestionTrigger, setClearPickedQuestionTrigger] =
    useState(0);

  const loadForm = (): Promise<any> => {
    return ProjectLocationConnection.getFormAddNewProjectLocation(id).then(
      (resp) => {
        setLocationQuestionsData((d) => ({
          ...d,
          unassignedQuestions: { ...resp.data.unassignedQuestions },
        }));
        setForm(resp.data);

        // @ts-ignore
        setUsers(resp.data.locationRoles);
        setUsers({
          count: resp.data.locationRoles.count,
          data: resp.data.locationRoles.data.map((item: any) => {
            return {
              roleId: item.roleId,
              roleName: item.roleName,
              assigned: [],
              unassigned: item.users,
            };
          }),
        });
      }
    );
  };
  useEffect(() => {
    loadForm();
  }, []);
  const handleSubmit = () => {
    const data: IProjectLocationRequestData = {
      ...locationData,
      ...locationAddressData,
      locationCustomers: customerData,
      locationCustomerRegions: regionData,
      locationQuestions: {
        all: true,
        assignedCount: locationQuestionsData.assignedQuestions.count,
        assigned: locationQuestionsData.assignedQuestions.data,
        unassigned: locationQuestionsData.unassignedQuestions.data.map(
          (el) => ({ id: el.id })
        ),
      },
    };

    if (id)
      handleToast(ProjectLocationConnection.createProjectNewLocation(id, data))
        .then((resp) => {
          if (resp.status == 201) {
            const locationAdded = resp.data.locationId;
            ProjectLocationConnection.assignUsersToLocation(
              id,
              resp.data.locationId,
              getUsersRequest()
            ).then((resp) => {
              console.log(resp);
              if (resp.status == 200) {
                props.setRefLocationId(locationAdded);
                props.setOpen(false);
              }
            });
          }
        })
        .catch((err) => handleSetErrors(err, setErrors));
  };
  const getUsersRequest = (): IRolesRequest => {
    return {
      roles:
        users?.data?.map((role) => ({
          roleId: role.roleId,
          assigned: role.assigned?.map((user) => ({ id: user.id })) ?? [],
          unassigned: role.unassigned?.map((user) => ({ id: user.id })) ?? [],
        })) ?? [],
    };
  };

  const onRoleUserChange = (
    locationRole: any,
    assignedUserIds: Array<string>,
    unAssignedUserIds: Array<string>
  ) => {
    setUsers((state: any) => ({
      ...state,
      data: state.data.map((item: any) => {
        if (item.roleId === locationRole.roleId) {
          const allUsers = [...item.assigned, ...item.unassigned];
          return {
            ...item,
            assigned: allUsers.filter((i) => assignedUserIds.includes(i.id)),
            unassigned: allUsers.filter((i) =>
              unAssignedUserIds.includes(i.id)
            ),
          };
        } else return item;
      }),
    }));
  };

  const getDisabledElements = (): Array<string> => {
    return form?.disabledElements ?? [];
  };

  const isDisabled = (elm: string): boolean => {
    return getDisabledElements().includes(elm);
  };

  const handlePickedQuestion = (questionId: string) => {
    const question: IQuestionItem | undefined =
      locationData?.assignedQuestions?.data?.find((q) => q.id === questionId);
    if (question) setPickedQuestion(question);
  };

  const [step, setStep] = useState(0);
  const locationStep = 0;
  const questionsStep = 1;
  const customerStep = 2;
  const permissionsStep = 3;
  const lastStep = permissionsStep;

  const isFormDisabled =
    form?.disabledElements?.includes("SAVE_LOCATION_CHANGES") ?? true;

  const locationStepHasErrors = () => {
    const locationNames = Object.keys(locationData);
    const errorsHas = Object.keys(errors).flatMap((name) => {
      return locationNames.includes(name);
    });
    return errorsHas.length;
  };

  if (!form) return <SpinnerSmall />;
  return (
    <>
      <Col xs={3}>
        <Steps vertical current={step} style={{ opacity: ".6" }}>
          <Steps.Item
            style={{ cursor: "pointer" }}
            onClick={() => {
              setStep(locationStep);
            }}
            title="Dodanie lokalizacji"
            description={
              <div style={{ color: locationStepHasErrors() ? "red" : "" }}>
                Ustaw parametry lokalizacji{" "}
                <span>
                  {Object.keys(errors).length > 0
                    ? Object.keys(errors).length
                    : ""}
                </span>
              </div>
            }
          />
          <Steps.Item
            title="Przypisania pytań"
            onClick={() => {
              setStep(questionsStep);
            }}
          />
          <Steps.Item
            title="Przypisania wg. klienta"
            onClick={() => {
              setStep(customerStep);
            }}
          />
          <Steps.Item
            title="Przypisanie uprawnień"
            onClick={() => {
              setStep(permissionsStep);
            }}
          />
        </Steps>
        {errors?.message && <span>errors.message</span>}
        <IconButton
          onClick={() => {
            if (step == lastStep) handleSubmit();
            else setStep(step + 1);
          }}
          appearance={"ghost"}
          icon={step == lastStep ? <EditIcon /> : <ArrowRightLineIcon />}>
          {step == lastStep ? "Zapisz lokalizację" : "Dalej"}
        </IconButton>
      </Col>
      <Col xs={21} style={{ overflow: "auto", maxHeight: "70vh" }}>
        {step == locationStep && (
          <LocationProjectForm
            setErrors={setErrors}
            data={locationData}
            setData={setLocationData}
            form={form}
            errors={errors}>
            <AddressSelectSection
              errors={errors}
              setData={setLocationAddressData}
              data={locationAddressData}
              disabled={isFormDisabled}
              form={form}
              saveExitCallback={loadForm}
            />
          </LocationProjectForm>
        )}

        {step == questionsStep && form?.unassignedQuestions && (
          <DoubleSidePicker
            disabled={isDisabled("SAVE_LOCATION_CHANGES")}
            heading={"Pytania do lokalizacji"}
            itemType={"locationQuestion"}
            emptyMessageAssigned={"Nie przypisano żadnych lokalizacji"}
            emptyMessageNotAssigned={"Brak pytań w projekcie"}
            assigned={locationQuestionsData.assignedQuestions?.data ?? []}
            unassigned={locationQuestionsData.unassignedQuestions.data ?? []}
            clearPickedItemTrigger={clearPickedQuestionTrigger}
            pickedItemCallback={handlePickedQuestion}
            onChange={(assignedIds, unAssignedIds) => {
              doubleSidePickerOnChange(
                assignedIds,
                unAssignedIds,
                "assignedQuestions",
                "unassignedQuestions",
                locationQuestionsData,
                setLocationQuestionsData
              );
            }}
          />
        )}
        {step == customerStep && (
          <>
            <LocationProjectCustomerForm
              setData={setCustomerData}
              data={customerData}
              disabled={isFormDisabled}
              customers={form.customers}
            />
            <SeparatorLine size={1.5} />
            <LocationProjectRegionForm
              setData={setRegionData}
              data={regionData}
              disabled={isFormDisabled}
              regions={form.regions}
              customers={form.customers}
            />
          </>
        )}
        {step == permissionsStep && form?.locationRoles && (
          <>
            <Section title={""}>
              {users?.data.map((item: any) => {
                if (
                  isDisabled("SAVE_LOCATION_USERS_CHANGES") &&
                  _.isEmpty(item.users)
                ) {
                  return <></>;
                }
                return (
                  <>
                    <DoubleSidePicker
                      key={item.id}
                      itemType={"user"}
                      heading={`Grupa: ${item.roleName}`}
                      assigned={item?.assigned ?? []}
                      unassigned={item?.unassigned ?? []}
                      disabled={isDisabled("SAVE_LOCATION_USERS_CHANGES")}
                      onChange={(assignedIds, unassignedIds) => {
                        onRoleUserChange(item, assignedIds, unassignedIds);
                      }}
                      emptyMessageAssigned={"Brak przypisanych użytkowników"}
                      emptyMessageNotAssigned={"Brak użytkowników w projekcie"}
                    />
                    <SeparatorEmpty />
                  </>
                );
              })}
            </Section>
          </>
        )}
      </Col>
      <SeparatorEmpty />
      <ActionsContainer>
        <IconButton
          onClick={() => {
            if (step == lastStep) handleSubmit();
            else setStep(step + 1);
          }}
          appearance={"ghost"}
          icon={<EditIcon />}>
          {step == lastStep ? "Zapisz lokalizację" : "Dalej"}
        </IconButton>
      </ActionsContainer>
    </>
  );
};

export default LocationProjectModalAddNew;
