import React, { FunctionComponent, useEffect, useState } from "react";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import { getUserId, parseTimeForBackend } from "../../../../utils/helpers";
import WarehouseConnection from "../../../../utils/connections/warehouse";
import { useParams } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import DatePicker from "rsuite/esm/DatePicker";
import dayjs from "dayjs";
import { Form, Message, SelectPicker, Col, Row } from "rsuite";
import FormGroup from "rsuite/FormGroup";
import { Helmet } from "react-helmet-async";
import WarehouseAddItems from "./WarehouseAddItems";

interface IItem {
  itemId: string;
  price: number;
  amount: number;
}

interface IOptions {
  id: string;
  name: string;
  type: string;
}

interface IUserOptions extends IOptions {
  roleId: string;
}

interface IRoleOptions extends IOptions {}

interface IUsers {
  options: Array<IUserOptions>;
  value: string;
  disabled: boolean;
}

interface IRoles {
  options: Array<IRoleOptions>;
  value: string;
  disabled: boolean;
}

interface IStoreOperations {
  options: Array<IOptions>;
}

interface IGroups {
  options: Array<{
    id: string;
    isDefault: boolean;
    name: string;
    type: string;
  }>;
}

export interface IWarehouseAddData {
  userId: string;
  userFromId: string;
  userToId: string;
  roleFromId: string;
  roleToId: string;
  description: string;
  type: string;
  positions: Array<IItem>;
  groupType: string;
  operationDate: number;
}

interface IForm {
  usersFrom: IUsers;
  usersTo: IUsers;
  userStoreOperationTypes: IStoreOperations;
  itemGroups: IGroups;
  rolesFrom: IRoles;
  rolesTo: IRoles;
  disabledElements: Array<string>;
}

const USER_ROLE_FROM = "USER_ROLE_FROM";
const USER_ROLE_TO = "USER_ROLE_TO";
const USER_FROM = "USER_FROM";
const USER_TO = "USER_TO";

export const TYPE_INCOMING = "INCOMING";
export const TYPE_OUTCOMING = "OUTCOMING";
export const TYPE_SHIFT = "SHIFT";
export const TYPE_LOST = "LOST";

const WarehouseAdd: FunctionComponent = () => {
  const getDefaultState = (): IWarehouseAddData => {
    return {
      type: null,
      userId: getUserId(),
      description: "",
      positions: [],
      roleFromId: null,
      userFromId: null,
      roleToId: null,
      userToId: null,
      groupType: null,
      operationDate: 0,
    };
  };

  const [data, setData] = useState<IWarehouseAddData>(getDefaultState());
  const [form, setForm] = useState<IForm>();
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<any>(null);
  const [reloadItems, setReloadItems] = useState<boolean>(false);

  useEffect(() => {
    WarehouseConnection.getFormOptionsWarehouse(id).then((response) => {
      const formData = response.data;
      setForm((form) => ({ ...form, ...formData }));
    });
  }, []);
  const handleChangeUserOrRoles = () => {
    setReloadItems(true);
  };
  const handleChangeGroup = (value: string) => {
    setData((data) => ({
      ...data,
      groupType: value,
      positions: [],
    }));
  };
  const handleChangeOperationType = (value) => {
    if (value)
      WarehouseConnection.getFormUsersAndRolesWarehouse(id, {
        type: value,
      }).then((response) => {
        const formData = response.data;
        setForm({ ...form, ...formData });
        setData((d) => ({
          ...d,
          ...{
            type: value,
            positions: [],
            operationDate: parseTimeForBackend(new Date().getTime()),
            userFromId: formData.usersFrom.options.find(
              (option) => option.id == formData.usersFrom.value
            )?.id,
            userToId: formData.usersTo.options.find(
              (option) => option.id == formData.usersTo.value
            )?.id,
            roleFromId: formData.rolesFrom.options.find(
              (option) => option.id == formData.rolesFrom.value
            )?.id,
            roleToId: formData.rolesTo.options.find(
              (option) => option.id == formData.rolesTo.value
            )?.id,
          },
        }));
        setReloadItems(true);
      });
  };
  const disabledOrIsAdminEnabled = (type: string) => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const session = JSON.parse(user);
      const currentRole = session.user.roles.find((row) => {
        return row.role.roleId == session.currentRoleId;
      });
      if (currentRole?.role.roleType.type == "ADMIN") return false;
    }
    return form.disabledElements.includes(type);
  };

  if (!form) return <Spinner />;
  return (
    <>
      <WhiteCard padding={true}>
        <Message type={"warning"} showIcon style={{ borderRadius: 20 }}>
          Przyjęcie towaru na magazyn może wykonać użytkownik z grupy: Klient
          lub Centrala
        </Message>
        <Form
          fluid
          onChange={(inputs) => {
            if (inputs.roleFromId === null) inputs.userFromId = null;
            if (inputs.roleToId === null) inputs.userToId = null;
            setData({ ...data, ...inputs });
          }}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Form.ControlLabel>Typ operacji</Form.ControlLabel>
                <Form.Control
                  block
                  name={"type"}
                  data={form.userStoreOperationTypes.options}
                  labelKey={"name"}
                  valueKey={"id"}
                  value={data.type}
                  onChange={handleChangeOperationType}
                  accepter={SelectPicker}
                  errorMessage={errors?.type}
                  errorPlacement={"bottomEnd"}
                />
              </FormGroup>
            </Col>
          </Row>
          {!!data.type && (
            <>
              {![TYPE_INCOMING].includes(data.type) && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Form.ControlLabel>
                        Grupa użytkownika (wydanie)
                      </Form.ControlLabel>
                      <Form.Control
                        block
                        name={"roleFromId"}
                        disabled={disabledOrIsAdminEnabled(USER_ROLE_FROM)}
                        data={form.rolesFrom?.options}
                        labelKey={"name"}
                        valueKey={"id"}
                        value={data.roleFromId}
                        accepter={SelectPicker}
                        errorMessage={errors?.roleFromId}
                        errorPlacement={"bottomEnd"}
                        onChange={() => {
                          setData((data) => ({ ...data, userFromId: null }));
                          handleChangeUserOrRoles();
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Form.ControlLabel>
                        Użytkownik (wydanie)
                      </Form.ControlLabel>
                      <Form.Control
                        block
                        name={"userFromId"}
                        data={form.usersFrom?.options.filter(
                          (el) => el.roleId == data.roleFromId
                        )}
                        labelKey={"fullName"}
                        valueKey={"id"}
                        value={data.userFromId}
                        accepter={SelectPicker}
                        errorMessage={errors?.userFromId}
                        errorPlacement={"bottomEnd"}
                        onChange={handleChangeUserOrRoles}
                        disabled={
                          disabledOrIsAdminEnabled(USER_FROM) ||
                          !data.roleFromId
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {![TYPE_OUTCOMING, TYPE_LOST].includes(data.type) && (
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Form.ControlLabel>
                        Grupa użytkownika (przyjęcie)
                      </Form.ControlLabel>
                      <Form.Control
                        block
                        name={"roleToId"}
                        disabled={form.disabledElements.includes(USER_ROLE_TO)}
                        data={form.rolesTo?.options}
                        labelKey={"name"}
                        valueKey={"id"}
                        value={data.roleToId}
                        accepter={SelectPicker}
                        errorMessage={errors?.roleToId}
                        errorPlacement={"bottomEnd"}
                        onChange={() => {
                          setData((data) => ({ ...data, userToId: null }));
                          handleChangeUserOrRoles();
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Form.ControlLabel>
                        Użytkownik (przyjęcie)
                      </Form.ControlLabel>
                      <Form.Control
                        block
                        name={"userToId"}
                        data={form.usersTo?.options.filter(
                          (el) => el.roleId == data.roleToId
                        )}
                        labelKey={"fullName"}
                        valueKey={"id"}
                        value={data.userToId}
                        accepter={SelectPicker}
                        errorMessage={errors?.userToId}
                        errorPlacement={"bottomEnd"}
                        onChange={handleChangeUserOrRoles}
                        disabled={
                          !data.roleToId ||
                          form.disabledElements.includes(USER_TO)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Form.ControlLabel>
                      Grupa produktu (filtr)
                    </Form.ControlLabel>
                    <Form.Control
                      block
                      name={"groupType"}
                      data={form.itemGroups?.options}
                      labelKey={"name"}
                      valueKey={"type"}
                      value={data.groupType}
                      accepter={SelectPicker}
                      errorMessage={errors?.groupType}
                      errorPlacement={"bottomEnd"}
                      onChange={handleChangeGroup}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Form.ControlLabel>Data operacji</Form.ControlLabel>
                    <Form.Control
                      block
                      isoWeek
                      cleanable={false}
                      oneTap
                      disabled
                      name={"operationDate"}
                      value={
                        data.operationDate > 0
                          ? dayjs.unix(data.operationDate).toDate()
                          : undefined
                      }
                      onChange={(_date) => {
                        setData((s) => ({
                          ...s,
                          operationDate: dayjs(_date).unix(),
                        }));
                      }}
                      accepter={DatePicker}
                      errorMessage={errors?.operationDate}
                      errorPlacement={"bottomEnd"}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </Form>
        {!!data.type && (
          <WarehouseAddItems
            data={data}
            setData={setData}
            reloadItems={reloadItems}
            setReloadItems={setReloadItems}
            setErrors={setErrors}
          />
        )}
      </WhiteCard>
      <Helmet>
        <style>
          {` .rs-form-control-label {
          margin-top: 15px;
        }`}
        </style>
      </Helmet>
    </>
  );
};

export default WarehouseAdd;
