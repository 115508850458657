import React, { FunctionComponent, useState } from "react";
import { IAddress, IAddresses, IGetAddresses } from "utils/models";
import AddressesConnection from "utils/connections/addresses";
import Pagination from "global/pagination/Pagination";
import { getAddressesState } from "utils/states";
import Table from "rsuite/Table";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { deepClone, getAddressFullName } from "../../../../utils/helpers";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import Checkbox from "rsuite/Checkbox";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import { ButtonSort } from "../../../../utils/sortHelper";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

export type chosenAddressDataType = { id: string; name: string };

const AddressesList: FunctionComponent = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const defaultFilters: IGetAddresses = deepClone(getAddressesState);
  const [filters, setFilters] = useState<IGetAddresses>(defaultFilters);
  const [chosenAddress, setChosenAddress] = useState<chosenAddressDataType>({
    id: "",
    name: "",
  });

  const [data, setData] = useState<IAddresses | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  const handleEdit = (event: any) => {
    history.push(`/datasets/addresses/${event.currentTarget.dataset.id}/edit`);
  };

  const handleChooseAddress = (address: IAddress) => {
    setChosenAddress({ id: address.id, name: getAddressFullName(address) });
  };

  const handleRowClassName = (rowData: IAddress): string => {
    if (rowData === undefined || chosenAddress.id !== rowData.id)
      return "tablePointer";
    return `tableRowSelected tablePointer`;
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/datasets/addresses/add"}
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: AddressesConnection.batchDelete,
            callback: () => {
              setTriggerUpdate(Date.now());
              setChosenToDelete([]);
            },
          }}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            onRowClick={handleChooseAddress}
            rowClassName={handleRowClassName}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"city"}
                  filters={filters}
                  setFilters={setFilters}>
                  Miasto
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData: IAddress) => (
                  <ButtonListRedirect
                    redirectUrl={`/datasets/addresses/${rowData.id}/edit`}>
                    {rowData.city.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"street"}
                  filters={filters}
                  setFilters={setFilters}>
                  Ulica
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData: IAddress) => <span>{rowData.street?.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Numer
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <span>
                    {rowData.houseNumber && rowData.apartmentNumber
                      ? `${rowData.houseNumber}/${rowData.apartmentNumber}`
                      : rowData.houseNumber
                      ? `${rowData.houseNumber}`
                      : ""}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={160} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"postcode"}
                  filters={filters}
                  setFilters={setFilters}>
                  Kod pocztowy
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData: IAddress) => {
                  return <span>{rowData.postCode.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"voivodeship"}
                  filters={filters}
                  setFilters={setFilters}>
                  województwo
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => <span>{rowData.voivodeship.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={160} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Zweryfikowany
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => <Checkbox checked={rowData.verified} disabled />}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={75} align="center">
              <Table.HeaderCell>Koord.</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => <Checkbox checked={!!rowData.coords} disabled />}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              resizable={true}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <>
                    <ActionsContainer>
                      <img
                        src={settings}
                        style={{ cursor: "pointer" }}
                        alt={"Idź do"}
                        data-id={rowData.id}
                        onClick={handleEdit}
                      />
                    </ActionsContainer>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        // @ts-ignore
        load={AddressesConnection.getAddresses}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getAddressesState}
        setResultData={setData}
        formGet={AddressesConnection.getFormFilterAddress}
        triggerLoad={triggerUpdate}
        filterStorageKey={"datasetsAddresses"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Identyfikator adresu",
            stateKey: "id",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Ulica",
            stateKey: "street",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Numer domu",
            stateKey: "houseNumber",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Numer mieszkania",
            stateKey: "apartmentNumber",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Kod pocztowy",
            stateKey: "postcode",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Miasto",
            stateKey: "city",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Powiat",
            stateKey: "district",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Gmina",
            stateKey: "community",
          },
          {
            type: FILTER_SELECT,
            label: "Województwo",
            stateKey: "voivodeshipId",
            formKey: "voivodeships",
          },
          {
            type: FILTER_SELECT,
            label: "Kraj",
            stateKey: "country",
            formKey: "countries",
          },
        ]}
      />
    </>
  );
};

export default AddressesList;
