import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Loader, Message, Row, Steps } from "rsuite";
import ReportTemplateConnection from "utils/connections/reportTemplate";
import {
  FormGroupDatePicker,
  FormGroupMultiSelect,
  FormGroupSelect,
  FormGroupSubmit,
  FormRequired,
  IFormRequredFields,
} from "utils/formHelper";
import { ISelect } from "utils/models";

interface IMssAvailableReportFilter {
  onSubmit: (reportSettings: IState) => void;
}

export const MssAvailableReportFilterType = 19;

interface IOptions {
  tasks: ISelect;
  activities: ISelect<{
    taskId: string;
    name: string;
    id: string;
    type: string;
  }>;
  questions: ISelect<{ activityId: string; name: string; id: string }>;
  item_categories: ISelect;
  brands: ISelect;
  networks: ISelect;
  locations: ISelect<{ name: string; id: string; networkId: string }>;
  supervisor: ISelect;
  area_manager: ISelect;
  przedstawiciel_handlowy: ISelect;
}

interface IState {
  // step 0
  dateFrom: Date | null;
  dateTo: Date | null;

  // step 1
  taskId: string | null;
  activityId: string | null;
  questionId: string | null;

  networkIds: Array<string>;
  locationIds: Array<string>;
  roleIds: Array<string>;
  categoryIds: Array<string>;
  brandIds: Array<string>;
}

const MssAvailableReportFilter: FunctionComponent<IMssAvailableReportFilter> = (
  props
) => {
  const [step, setStep] = useState(0);
  const [options, setOptions] = useState<IOptions | null>(null);
  const defaultState: IState = {
    dateFrom: null,
    dateTo: null,

    taskId: null,
    activityId: null,
    questionId: null,

    networkIds: [],
    locationIds: [],
    roleIds: [],
    categoryIds: [],
    brandIds: [],
  };
  const [state, setState] = useState<IState>(defaultState);

  useEffect(() => {
    switch (step) {
      case 0:
        break;
      case 1:
        getOptions();
        break;
      case 2:
        break;
    }
  }, [step]);

  const isGenerating = step === 2;

  const getOptions = () => {
    ReportTemplateConnection.getReportOptions(
      MssAvailableReportFilterType,
      state.dateFrom ?? new Date(),
      state.dateTo ?? new Date()
    ).then((res) => {
      setOptions(res.data);
      setState((s) => ({
        ...defaultState,
        dateFrom: s.dateFrom,
        dateTo: s.dateTo,
      }));
    });
  };

  const onSubmit = (checkStatus: boolean) => {
    if (state.dateFrom && state.dateTo && step === 0) {
      setStep(1);
    } else if (checkStatus && step === 1) {
      setStep(2);
      props.onSubmit(state);
    }
  };

  const onFormChange = (formValue: IState) => {
    setState(formValue);
  };

  const FormRequiredFields: IFormRequredFields = {
    date: ["dateFrom", "dateTo"],
    select: ["taskId", "activityId", "questionId"],
  };

  return (
    <>
      <SeparatorEmpty size={5} />
      <Row>
        <Col md={6} xs={12}>
          <Steps vertical current={step} style={{ opacity: ".6" }}>
            <Steps.Item
              style={isGenerating ? {} : { cursor: "pointer" }}
              onClick={() => {
                if (isGenerating === false) setStep(0);
              }}
              title="Wybór zakresu"
              description="Wybierz zakres dat jakich dotyczy badanie dostępności"
            />
            <Steps.Item title="Ustawienia" />
            <Steps.Item title="Generowanie" />
          </Steps>
        </Col>

        <Col md={6} xs={12}>
          <FormRequired
            requiredFields={FormRequiredFields}
            onSubmit={onSubmit}
            state={state}
            onChange={onFormChange}>
            {
              // STEP 0 - DATA FROM - TO
              step === 0 && (
                <>
                  <FormGroupDatePicker
                    label={"Data od"}
                    fieldName={"dateFrom"}
                  />
                  <FormGroupDatePicker label={"Data do"} fieldName={"dateTo"} />
                  <FormGroupSubmit label={"Zatwierdź"} />
                </>
              )
            }

            {
              // STEP 1 - config
              step === 1 && (
                <>
                  {options === null ? (
                    <Loader
                      content={"Trwa ładowanie daych do filtra..."}
                      vertical
                    />
                  ) : (
                    <>
                      <Message showIcon type="info">
                        Dane do ustawień pochodzą z wizyt z wczesniej wybranego
                        zakresu dat.
                        <Button
                          size={"sm"}
                          onClick={() => setStep(0)}
                          appearance="subtle">
                          Cofnij
                        </Button>
                      </Message>
                      <SeparatorEmpty size={2} />

                      <FormGroupSelect
                        label={"Wybór Zadania"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"taskId"}
                        options={options.tasks.options}
                      />

                      <FormGroupSelect
                        label={"Wybór aktywności"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"activityId"}
                        disabled={state.taskId === null}
                        options={options.activities.options.filter(
                          (a) => a.taskId === state.taskId
                        )}
                      />

                      <FormGroupSelect
                        label={"Wybór pytania"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"questionId"}
                        disabled={state.activityId === null}
                        options={options.questions.options.filter(
                          (q) => q.activityId === state.activityId
                        )}
                      />

                      <SeparatorLine size={1} />

                      <FormGroupMultiSelect
                        label={"Kategorie produktu"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"categoryIds"}
                        disabled={state.questionId === null}
                        options={options.item_categories.options}
                      />

                      <FormGroupMultiSelect
                        label={"Marki"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"brandIds"}
                        disabled={state.questionId === null}
                        options={options.brands.options}
                      />

                      <SeparatorLine size={1} />

                      <FormGroupMultiSelect
                        label={"Sieci"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"networkIds"}
                        disabled={state.questionId === null}
                        options={options.networks.options}
                      />

                      <FormGroupMultiSelect
                        label={"Lokalizacje"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"locationIds"}
                        disabled={_.isEmpty(state.networkIds)}
                        options={options.locations.options.filter((l) =>
                          state.networkIds.includes(l.networkId)
                        )}
                      />

                      <SeparatorLine size={1} />

                      <FormGroupMultiSelect
                        label={"Supervisor"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"roleIds"}
                        disabled={state.questionId === null}
                        options={options.supervisor.options}
                      />

                      <FormGroupMultiSelect
                        label={"Area manager"}
                        labelKey={"name"}
                        valueKey={"id"}
                        fieldName={"roleIds"}
                        disabled={state.questionId === null}
                        options={options.area_manager.options}
                      />

                      <FormGroupMultiSelect
                        label={"Przedstawiciel handlowy"}
                        labelKey={"name"}
                        valueKey={"userRoleId"}
                        fieldName={"roleIds"}
                        disabled={state.questionId === null}
                        options={options.przedstawiciel_handlowy.options}
                      />

                      <FormGroupSubmit
                        disabled={isGenerating}
                        label={"Generuj raport"}
                      />
                    </>
                  )}
                </>
              )
            }

            {
              // STEP 2 - generate
              step === 2 && (
                <>
                  <h5>Generowanie raportu zlecone!</h5>
                  <small>
                    Mozesz śledzić aktualny postęp i pobrać raport z listy
                    ponizej
                  </small>
                  <br />
                  <br />
                  <Button
                    appearance={"ghost"}
                    size={"xs"}
                    onClick={() => {
                      setStep(0);
                      setState(defaultState);
                    }}>
                    Wygeneruj nowy raport
                  </Button>
                </>
              )
            }
          </FormRequired>
        </Col>
      </Row>
    </>
  );
};

export default MssAvailableReportFilter;
