import { Icon } from "@rsuite/icons";
import React, { CSSProperties } from "react";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { Tooltip, Whisper } from "rsuite";
import {
  faAlignJustify,
  faCamera,
  faClock,
  faCalendar,
  faSortNumericUp,
  faCheck,
  faSpellCheck,
  faGift,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";
import { TypeProductGroupType, TypeQuestion } from "../../utils/types";

export const getQuestionIconByType = (
  questionType: TypeQuestion
): JSX.Element => {
  switch (questionType) {
    case "BOOLEAN_QUESTION":
      return IconSvg(faCheck, "Pytanie logiczne");
    case "TEXT_QUESTION":
    case "TEXTAREA_QUESTION":
      return IconSvg(faAlignJustify, "Pytanie tekstowe");
    case "INTEGER_QUESTION":
    case "DECIMAL_QUESTION":
      return IconSvg(faSortNumericUp, "Pytanie liczbowe");
    // return iconSvg(faListOl, 'Pytanie liczbowe');
    case "PHOTO_QUESTION":
      return IconSvg(faCamera, "Pytanie zdjęciowe");
    case "DICTIONARY_QUESTION":
    case "DICTIONARY_MULTIPLE_ANSWERS_QUESTION":
      return IconSvg(faSpellCheck, "Pytanie słownikowe");
    // return iconSvg(faListUl, 'Pytanie słownikowe');
    case "DATE_QUESTION":
      return IconSvg(faCalendar, "Pytanie data");
    case "TIME_QUESTION":
      return IconSvg(faClock, "Pytanie czas");
    default:
      return <></>;
  }
};

export const getProductIconByType = (
  groupType: TypeProductGroupType
): JSX.Element => {
  switch (groupType) {
    case "GIFT":
      return IconSvg(faGift, "Gratis");
    case "POS":
      return IconSvg(faCashRegister, "Point of sale materials (POSM)"); // faStore
    default: //'PRODUCT'
      return <></>;
  }
};

// @ts-ignore
const FaSvgIcon = ({ faIcon, ...rest }): RefAttributes<SVGElement> => {
  const width = faIcon.icon[0];
  const height = faIcon.icon[1];
  const svgPathData = faIcon.icon[4];

  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="1.25em"
      height="1.25em">
      {
        // @ts-ignore
        <path d={svgPathData} />
      }
    </svg>
  );
};

export const IconSvg = (
  faIcon: IconDefinition,
  hint?: string,
  spin?: boolean,
  color?: string,
  styles?: CSSProperties
): JSX.Element => {
  switch (color) {
    case "error":
      color = "red";
      break;
    case "primary":
      color = "#FFAF38";
  }

  const icon = (
    // @ts-ignore // todo --
    <Icon spin={spin ?? false} as={FaSvgIcon} faIcon={faIcon} fill={color} />
  );

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        padding: "0 5px 0 5px",
        ...styles,
      }}>
      {hint ? (
        <Whisper
          placement="leftEnd"
          trigger="hover"
          speaker={<Tooltip>{hint}</Tooltip>}>
          <span>{icon}</span>
        </Whisper>
      ) : (
        icon
      )}
    </div>
  );
};

export default IconSvg;
