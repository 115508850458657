import React, { FunctionComponent } from "react";
import Alerts from "views/projects/components/editProject/Alerts/Alerts";

interface IAutoNotifications {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AutoNotifications: FunctionComponent<IAutoNotifications> = (props) => {
  return <Alerts decreaseContainerSizePx={40} />;
};

export default AutoNotifications;
