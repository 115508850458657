import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./global/layout/Layout";
import AppCore from "AppCore";
import ErrorPage from "ErrorPage";
import VisitView from "views/public/VisitView";
import VisitPhotos from "views/public/VisitPhotos";
import NotificationVisitView from "views/public/NotificationVisitView";
import { FCC } from "utils/models";

const AppPublic: FCC = () => {
  return (
    <AppCore>
      <Layout>
        <Switch>
          <Route
            exact
            path={
              "/public/visit/:organizationId/:projectId/:visitId/:projectReportId"
            }
            component={VisitView}
          />
          ,
          <Route
            exact
            path={"/public/visit/:organizationId/:projectId/:visitId"}
            component={VisitView}
          />
          ,
          <Route
            exact
            path={
              "/public/visit-photos/:organizationId/:projectId/:visitId/:questionId"
            }
            component={VisitPhotos}
          />
          <Route
            exact
            path={"/public/notification-visit/:notificationSourceId/:visitId"}
            component={NotificationVisitView}
          />
          ,
          <Route exact path="*" component={ErrorPage} />
        </Switch>
      </Layout>
    </AppCore>
  );
};

export default AppPublic;
