import React, { FunctionComponent } from "react";
import TableTooltipWrapper from "../../../../global/table/TableTooltipWrapper";
import styles from "./styles.module.scss";
import { IContinuityReportDayPosition } from "../../../../utils/models";
import { Popover, Whisper } from "rsuite";

interface ITimeTableElement {
  dayPosition: IContinuityReportDayPosition;
}

const statusMapper: { [index: string]: { color: string; hint: string } } = {
  a: {
    color: "#457B9D",
    hint: "Dzień zawiera choć jedną aktywność w tolerowanym dystansie.",
  },
  b: {
    color: "#EEBA0B",
    hint: "Dzień nie zawiera żadnej aktywności w tolerowanym dystansie.",
  },
  c: {
    color: "#A8DADC",
    hint: "Żadna z wizyt nie zawiera informacji o położeniu użytkownika",
  },
  d: {
    color: "#1D3557",
    hint: "Użytkownik nie wysłał żadnej wizyty tego dnia",
  },
  e: {
    color: "#9d4583",
    hint: "W jednej z wizyt nie została potwierdzona realizacja.",
  },
  p: { color: "#F1FAEE", hint: "Zaplanowane wizyty" },
};

const TimeTableElement: FunctionComponent<ITimeTableElement> = (props) => {
  const statusObj = statusMapper[props.dayPosition.status.toLocaleLowerCase()];

  return (
    <>
      <Whisper
        trigger={"click"}
        placement={"bottomEnd"}
        enterable
        speaker={
          <Popover title={"Wizyty"}>
            {props.dayPosition.positionVisits.map((visit) => (
              <div key={visit.visit.id}>
                <a
                  href={`/projects/${visit.visit.projectId}/visits/${visit.visit.id}/edit/`}
                  target="_blank"
                  rel="noreferrer">
                  {visit.visit.startDate} - {visit.visit.locationName}
                  &nbsp;({visit.status})
                </a>
              </div>
            ))}
          </Popover>
        }>
        <div style={{ height: "100%" }}>
          <TableTooltipWrapper
            hint={statusObj?.hint ?? ""}
            style={{
              background: statusObj?.color ?? "#fff",
              transform: "translate(-56%)",
            }}
            classWrapper={styles.timeTableElement}
            styleWrapper={{ background: statusObj?.color ?? "#fff" }}>
            {props.dayPosition.scheduledHours}
            {props.dayPosition.status.toLocaleLowerCase()}
          </TableTooltipWrapper>
        </div>
      </Whisper>
    </>
  );
};

export default TimeTableElement;
