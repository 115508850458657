import React, { FunctionComponent, useState } from "react";
import CategoriesConnection from "../../../../utils/connections/categories";
import { IGetCategories, ICategories } from "../../../../utils/models";
import { deepClone, handleSortColumn } from "../../../../utils/helpers";
import { getCategoriesState } from "../../../../utils/states";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { Table } from "rsuite";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import settings from "../../../../assets/svg/settings.svg";
import Pagination from "../../../../global/pagination/Pagination";
import { generatePath, useHistory } from "react-router-dom";
import ProductsConnection from "../../../../utils/connections/products";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ICategoriesList {}

const CategoriesList: FunctionComponent<ICategoriesList> = () => {
  const defaultFilters = deepClone(getCategoriesState);
  const [data, setData] = useState<ICategories | null>(null);
  const [filters, setFilters] = useState<IGetCategories>(defaultFilters);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleEdit = (event: any) => {
    history.push(
      generatePath("/products/category/:id/edit", {
        id: event.currentTarget.dataset.id,
      })
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/products/category/add"}
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: CategoriesConnection.batchDelete,
            callback: () => {
              setChosenToDelete([]);
              setTriggerLoad(Date.now());
            },
          }}
        />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={300} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Nazwa
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/products/category/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={300} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell
                width={300}
                depth={0}
                dataKey="customerName"></Table.Cell>
            </Table.Column>

            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <ActionsContainer>
                    <img
                      src={settings}
                      style={{ cursor: "pointer" }}
                      alt={"Idź do"}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={CategoriesConnection.getCategories}
        formGet={ProductsConnection.getFiltersProducts}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={"productsCategoriesList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
          {
            type: FILTER_SELECT,
            stateKey: "customerId",
            formKey: "customers",
            label: "Klient",
          },
        ]}
      />
    </>
  );
};

export default CategoriesList;
