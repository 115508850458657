import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InstancesConnection from "utils/connections/instances";
import PublicConnection from "utils/connections/public";
import { getOrganizationId, isPublicView } from "utils/helpers";
import { IVisitData, IVisitStatus } from "utils/models";
import { IVisitCommentItem } from "../VisitEdit";

function useVisitData(
  parOrganizationId: string | undefined,
  parProjectId: string | undefined
): [
  visitData: IVisitData | null,
  disabledElements: Array<string>,
  hiddenElements: Array<string>,
  visitStatuses: IVisitStatus[] | null,
  comments: Array<IVisitCommentItem>,
] {
  const { id, organizationId, projectId, visitId, projectReportId } =
    useParams<{
      id: string;
      organizationId: string;
      projectId: string;
      visitId: string;
      projectReportId: string;
    }>();
  const _organizationId = organizationId
    ? organizationId
    : parOrganizationId
    ? parOrganizationId
    : getOrganizationId();
  const _projectId = projectId ? projectId : parProjectId ?? "";
  const [visitData, setVisitData] = useState<IVisitData | null>(null);
  const [disabledElements, setDisabledElements] = useState<Array<string>>([]);
  const [hiddenElements, setHiddenElements] = useState<Array<string>>([]);
  const [visitStatuses, setVisitStatuses] = useState<IVisitStatus[] | null>(
    null
  );
  const [comments, setComments] = useState<Array<IVisitCommentItem>>([]);

  useEffect(() => {
    if (isPublicView()) {
      if (_projectId && _organizationId)
        PublicConnection.getVisitData(
          _organizationId,
          _projectId,
          visitId,
          projectReportId
        ).then((data) => {
          setComments(data.data.comments?.data ?? []);
          if (data.data.model) {
            setVisitData(data.data.model);
          } else {
            ToastNotificationPush(
              ToastTypes.error,
              "Nie obsługiwany format danych"
            );
          }
        });
    } else {
      InstancesConnection.getFormEditInstance(id, visitId).then((data) => {
        const model = data.data.model;
        setComments(data.data.comments?.data ?? []);
        if (model) {
          setVisitData(model);
          setDisabledElements(data.data.disabledElements);
          setHiddenElements(data.data.hiddenElements);
          setVisitStatuses(data.data.visitStatuses.options ?? []);
        } else {
          ToastNotificationPush(
            ToastTypes.error,
            "Nie obsługiwany format danych"
          );
        }
      });
    }
  }, [id, visitId, _projectId, _organizationId]);

  return [visitData, disabledElements, hiddenElements, visitStatuses, comments];
}

export default useVisitData;
