import { _paginate30 } from "utils/states";
import {
  IContractSalaryFilterFormModel,
  IContractSalaryModel,
  IRate,
  ISalary,
} from "../../models/contract/contractSalaryModels";

export const getContractSalaryFilterState: IContractSalaryFilterFormModel = {
  project: "",
  ..._paginate30,
};

export const rate: IRate = {
  type: "",
  settlement: "HOUR",
  amount: 0,
  extraAmount: 0,
  isGross: false,
};

export const salary: ISalary = {
  name: "",
  type: "",
  description: "",
  days: [],
  baseRate: rate,
  bonusRate: rate,
  extraRate: rate,
};
export const contractSalaryState: IContractSalaryModel = {
  id: "",
  salary: salary,
};
