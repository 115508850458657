import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  IContractServiceTypeFilterFormModel,
  IContractServiceTypeModel,
} from "../../models/contract/contractServiceTypeModels";

export const path = `/contracts/services/types`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractServiceTypeConnection = {
  getContractServiceType: (data: IContractServiceTypeFilterFormModel) => {
    return AxiosHelper.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContractServiceType: (data: IContractServiceTypeModel) => {
    return AxiosHelper.post(baseUrl, data, {
      headers: getAuthHeader(),
    });
  },
  updateContractServiceType: (id: string, data: IContractServiceTypeModel) => {
    return AxiosHelper.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersContractServiceType: () => {
    return AxiosHelper.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddContractServiceType: () => {
    return AxiosHelper.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractServiceType: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return AxiosHelper.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
  delete: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}`, { ids: data });
  },
  getListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  getFilterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },
};

export default ContractServiceTypeConnection;
