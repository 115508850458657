import React, { FunctionComponent, useEffect, useState } from "react";
import { IconButton, Nav, Popover, Whisper } from "rsuite";
import LogsWebList from "./LogsWebList";
import LogsAppList from "./LogsAppList";
import LogsCmdList from "./LogsCmdList";
import { Helmet } from "react-helmet-async";
import ClipboardJS from "clipboard";
import { FormatOptions, prettyPrintJson } from "pretty-print-json";
import CopyIcon from "@rsuite/icons/Copy";
import CodeIcon from "@rsuite/icons/Code";
import LogsEventList from "./LogsEventList";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";

const getJsonTooltip = (json: any): JSX.Element => {
  const options: FormatOptions = {
    lineNumbers: false,
  };

  return (
    <Popover>
      <div style={{ position: "relative" }}>
        <IconButton
          appearance={"primary"}
          icon={<CopyIcon />}
          onClick={() => {
            ToastNotificationPush(ToastTypes.success, "Skopiowano do schowka!");
          }}
          id={"copy"}
          data-clipboard-text={JSON.stringify(json)}
        />
        <div style={{ overflow: "auto" }}>
          <div style={{ maxHeight: "500px" }}>
            <pre>
              <div
                className={"json-container"}
                dangerouslySetInnerHTML={{
                  __html: prettyPrintJson.toHtml(json, options),
                }}
              />
            </pre>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export const jsonButton = (jsonString: string): JSX.Element => {
  try {
    const json = JSON.parse(jsonString);
    return (
      <>
        <Whisper
          placement={"left"}
          trigger={"click"}
          speaker={getJsonTooltip(json)}>
          <IconButton appearance={"subtle"} icon={<CodeIcon />} />
        </Whisper>
      </>
    );
  } catch (exception) {
    return <></>;
  }
};

const LogsList: FunctionComponent = () => {
  const VIEW_WEB = "view_web";
  const VIEW_APP = "view_app";
  const VIEW_CMD = "view_cmd";
  const VIEW_EVENT = "view_event";
  const [view, setView] = useState<
    "view_web" | "view_app" | "view_cmd" | "view_event"
  >(VIEW_WEB);

  const handleChangeView = (view: "view_web" | "view_app" | "view_cmd") => {
    setView(view);
  };

  useEffect(() => {
    new ClipboardJS("#copy");
  }, []);

  return (
    <>
      <Nav
        appearance={"default"}
        activeKey={view}
        onSelect={handleChangeView}
        style={{ marginBottom: "10px" }}>
        <Nav.Item eventKey={VIEW_WEB}>Platforma www</Nav.Item>
        <Nav.Item eventKey={VIEW_APP}>Aplikacja mobilna</Nav.Item>
        <Nav.Item eventKey={VIEW_CMD}>Procesy systemu</Nav.Item>
        <Nav.Item eventKey={VIEW_EVENT}>Kolejka zadań</Nav.Item>
      </Nav>

      {view === VIEW_WEB && <LogsWebList />}
      {view === VIEW_APP && <LogsAppList />}
      {view === VIEW_CMD && <LogsCmdList />}
      {view === VIEW_EVENT && <LogsEventList />}

      <Helmet>
        <style>
          {`
          .json-container           { font-family: menlo, consolas, monospace; font-style: normal; font-weight: bold; line-height: 1.4em; font-size: 0.7rem; padding: 10px; }
          a.json-link               { text-decoration: none; border-bottom: 1px solid; outline: none; }
          a.json-link:hover         { background-color: transparent; outline: none; }
          .json-key, .json-string, .json-number, .json-boolean, .json-null, .json-mark, a.json-link, ol.json-lines >li { transition: all 400ms; }

          .json-key                         { color: indianred; }
          .json-string                      { color: darkkhaki; }
          .json-number                      { color: deepskyblue; }
          .json-boolean                     { color: mediumseagreen; }
          .json-null                        { color: darkorange; }
          .json-mark                        { color: silver; }
          a.json-link                       { color: mediumorchid; }
          a.json-link:visited               { color: slategray; }
          a.json-link:hover                 { color: violet; }
          a.json-link:active                { color: slategray; }

          #copy {
            position: absolute;
            right: 0;
            top: 0;
            opacity: .25;
          }

          #copy:hover {
            opacity: 1;
          }
        `}
        </style>
      </Helmet>
    </>
  );
};

export default LogsList;
