import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const OrdersTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Zamówienie",
        name: "order",
        active: true,
        options: [
          {
            name: "documentNumber",
            label: "Numer dokumentu",
            active: true,
          },
          {
            name: "customer",
            label: "Dystrybutor",
            active: true,
          },
          {
            name: "user",
            label: "Wystawił",
            active: true,
          },
          {
            name: "documentDate",
            label: "Data dokumentu",
            active: true,
          },
          {
            name: "deliveryDate",
            label: "Data dostarczenia",
            active: true,
          },
        ],
      },
      {
        label: "Lokalizacja",
        name: "location",
        active: true,
        options: [
          {
            name: "location_increment_number",
            label: "Nr porządkowy lokalizacji",
            active: false,
          },
          {
            name: "name",
            label: "Nazwa lokaizacji",
            active: true,
          },
          {
            name: "networkName",
            label: "Nazwa sieci",
            active: true,
          },
          {
            name: "street",
            label: "Ulica",
            active: true,
          },
          {
            name: "houseNumber",
            label: "Numer budynku",
            active: true,
          },
          {
            name: "apartmentNumber",
            label: "Numer mieszkania",
            active: true,
          },
          {
            name: "city",
            label: "Miejscowość",
            active: true,
          },
          {
            name: "postcode",
            label: "Kod pocztowy",
            active: true,
          },
          {
            name: "community",
            label: "Gmina",
            active: true,
          },
          {
            name: "district",
            label: "Powiat",
            active: true,
          },
          {
            name: "voivodeship",
            label: "Województwo",
            active: true,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={1} />
    </>
  );
};

export default OrdersTemplate;
