import React, { FunctionComponent, useEffect, useState } from "react";
import { IFilter } from "../ItemsList";
import { IUserMultiSelectRawEntity } from "../../../../utils/models";
import Select from "../../Select";
import BasicFilter from "./BasicFilter";
import { getProjectIdFromUrl } from "../../../../utils/helpers";
import { Button, Checkbox } from "rsuite";
import SeparatorEmpty from "../../separators/SeparatorEmpty";

interface IUserGlobalFilter {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  usersData: Array<IUserMultiSelectRawEntity>;
  onlyUsersFromLocations?: boolean;
  onlyCurrentUser?: boolean;
}

const UserGlobalFilter: FunctionComponent<IUserGlobalFilter> = (props) => {
  const [filterActive, setFilterActive] = useState(false);

  const projects: { [projectId: string]: string } = {};
  const networks: { [networkId: string]: string } = {};
  const cities: { [cityId: string]: string } = {};
  const roles: { [roleId: string]: string } = {};
  const users: { [userId: string]: string } = {};
  const rolesConnected: { [roleConnectedId: string]: string } = {};
  const filterRefDiv = document.getElementById("filterRefDiv");

  useEffect(() => {
    if (props.onlyUsersFromLocations) {
      props.setFilter({
        ...props.filter,
        linkedUsersOption: true,
      });
    }
  }, []);
  if (filterActive) {
    props.usersData.forEach((u) => {
      projects[u.projectId] = u.projectName;
    });

    /* filtered users */
    const filteredUsers = props.usersData
      .filter((u) => {
        if (props.filter.projectId != "")
          return u.projectId === props.filter.projectId;
        return true;
      })
      .filter((u) => {
        if (props.filter.cityName != "")
          return u.cityId === props.filter.cityName;
        return true;
      })
      .filter((u) => {
        if (props.filter.roleId != "") return u.roleId === props.filter.roleId;
        return true;
      })
      .filter((u) => {
        if (props.filter.networkId != "")
          return u.networkId === props.filter.networkId;
        return true;
      });
    const locations: { [locationId: string]: string } = {};
    /* filtered locations */
    filteredUsers
      .filter((u) =>
        props.filter.userId ? u.userId === props.filter.userId : true
      )
      .forEach((u) => {
        if (u.locationId) locations[u.locationId] = u.locationId;
      });

    /* filtered networks */
    props.usersData
      .filter((u) => {
        if (props.filter.projectId != "")
          return u.projectId === props.filter.projectId;
        return true;
      })
      .filter((u) =>
        props.filter.roleId ? u.roleId === props.filter.roleId : true
      )
      .filter((u) =>
        props.filter.cityName ? u.cityId === props.filter.cityName : true
      )
      .forEach((u) => {
        if (u.networkId) networks[u.networkId] = u.networkName;
      });

    /* filtered cities */
    props.usersData
      .filter((u) => {
        if (props.filter.projectId != "")
          return u.projectId === props.filter.projectId;
        return true;
      })
      .filter((u) => {
        if (props.filter.networkId != "")
          return u.networkId === props.filter.networkId;
        return true;
      })
      .forEach((u) => {
        if (u.cityId) cities[u.cityName] = u.cityName;
      });

    /* filtered roles */
    props.usersData
      .filter((u) =>
        props.filter.projectId ? u.projectId === props.filter.projectId : true
      )
      .filter((u) =>
        props.filter.networkId ? u.networkId === props.filter.networkId : true
      )
      .filter((u) =>
        props.filter.cityName ? u.cityId === props.filter.cityName : true
      )
      .filter((u) => (props.filter.linkedUsersOption ? !!u.locationId : true))
      .forEach((u) => {
        roles[u.roleId] = u.roleName;
      });

    /* filtered users */
    filteredUsers.forEach((u) => {
      if (u.userId) users[u.userId] = u.userName;
    });

    /* filtered connected roles */
    props.usersData
      .filter(
        (u) =>
          locations[u.locationId] &&
          (props.filter.roleId ? u.roleId !== props.filter.roleId : true) &&
          (props.filter.projectId
            ? u.projectId === props.filter.projectId
            : true) &&
          (props.filter.networkId
            ? u.networkId === props.filter.networkId
            : true) &&
          (props.filter.cityName ? u.cityId === props.filter.cityName : true)
      )
      .forEach((u) => {
        rolesConnected[u.roleId] = u.roleName;
      });
  }

  const sorted = (unsorted: {
    [id: string]: string;
  }): { id: string; name: string }[] => {
    const elementArray = Object.entries(unsorted);
    elementArray.sort((a, b) => a[1].localeCompare(b[1]));
    return elementArray.map((el) => ({ id: el[0], name: el[1] }));
  };

  const urlProjectId = getProjectIdFromUrl() ?? "";

  useEffect(() => {
    if (urlProjectId) {
      props.setFilter({
        ...props.filter,
        projectId: urlProjectId,
      });
    }
  }, []);

  const handleFilterDisable = () => {
    if (filterActive)
      props.setFilter({
        ...props.filter,
        projectId: urlProjectId,
        roleId: "",
        userId: "",
        networkId: "",
        cityName: "",
        roleConnectedId: "",
      });

    setFilterActive((f) => !f);
  };

  useEffect(() => {
    filterActive &&
      filterRefDiv &&
      filterRefDiv.scrollIntoView({ behavior: "smooth" });
  }, [filterActive]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <BasicFilter
            filter={props.filter}
            setFilter={props.setFilter}
            maxWidth={"100%"}
          />
          <div
            style={{ width: "200px", display: "flex", alignItems: "flex-end" }}>
            <Button
              appearance={filterActive ? "primary" : "ghost"}
              onClick={handleFilterDisable}>
              Filtry
            </Button>
          </div>
        </div>
        {filterActive && (
          <div style={{ width: "100%" }}>
            <SeparatorEmpty />
            <Checkbox
              disabled={props.onlyUsersFromLocations}
              checked={
                props.onlyUsersFromLocations || props.filter.linkedUsersOption
              }
              onChange={(value, checked) => {
                props.setFilter({
                  ...props.filter,
                  linkedUsersOption: checked,
                });
              }}>
              Szukaj powiązanych grup użytkowników w lokalizacjach
            </Checkbox>
            {projects && !urlProjectId && (
              <Select
                name={"selectProject"}
                options={sorted(projects)}
                placeholder={"Projekt"}
                value={props.filter.projectId}
                onChange2={(selectedOption) => {
                  props.setFilter({
                    ...props.filter,
                    projectId: selectedOption.value?.toString() ?? "",
                  });
                }}
              />
            )}
            {networks && (
              <Select
                name={"selectNetwork"}
                options={sorted(networks)}
                placeholder={"Sieć"}
                value={props.filter.networkId}
                onChange2={(selectedOption) => {
                  props.setFilter({
                    ...props.filter,
                    networkId: selectedOption.value?.toString() ?? "",
                  });
                }}
              />
            )}
            {cities && (
              <Select
                name={"selectCity"}
                options={sorted(cities)}
                placeholder={"Miasto"}
                value={props.filter.cityName}
                onChange2={(selectedOption) => {
                  props.setFilter({
                    ...props.filter,
                    cityName: selectedOption.value?.toString() ?? "",
                  });
                }}
              />
            )}
            {!props.onlyCurrentUser && roles && (
              <Select
                name={"selectRole"}
                options={sorted(roles)}
                placeholder={"Grupa"}
                value={props.filter.roleId}
                onChange2={(selectedOption) => {
                  props.setFilter({
                    ...props.filter,
                    roleId: selectedOption.value?.toString() ?? "",
                  });
                }}
              />
            )}
            {!props.onlyCurrentUser &&
              props.filter.linkedUsersOption &&
              users && (
                <Select
                  name={"selectUser"}
                  options={sorted(users)}
                  placeholder={"Użytkownik"}
                  value={props.filter.userId}
                  onChange2={(selectedOption) => {
                    props.setFilter({
                      ...props.filter,
                      userId: selectedOption.value?.toString() ?? "",
                    });
                  }}
                />
              )}
            {props.filter.linkedUsersOption && rolesConnected && (
              <Select
                name={"selectConnectedRole"}
                options={sorted(rolesConnected)}
                placeholder={"Grupa powiązana"}
                value={props.filter.roleConnectedId}
                onChange2={(selectedOption) => {
                  props.setFilter({
                    ...props.filter,
                    roleConnectedId: selectedOption.value?.toString() ?? "",
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default UserGlobalFilter;
