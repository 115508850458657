// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CihvJV2I7Hg91umBywW3{display:flex;justify-content:space-between}.pOx4WzEuaGez1I4mJGmD{background-color:rgba(0,0,0,0) !important}.UsbPYc8z0QqBCSsuHdxn{color:#fff !important}", "",{"version":3,"sources":["webpack://./src/views/schedules/schedule.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,yCAAA,CAEF,sBACE,qBAAA","sourcesContent":[".statusFilterRow {\n  display: flex;\n  justify-content: space-between;\n}\n\n.statusButton {\n  background-color: transparent !important;\n}\n.statusButtonActive {\n  color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusFilterRow": "CihvJV2I7Hg91umBywW3",
	"statusButton": "pOx4WzEuaGez1I4mJGmD",
	"statusButtonActive": "UsbPYc8z0QqBCSsuHdxn"
};
export default ___CSS_LOADER_EXPORT___;
