import React, { FunctionComponent, useEffect, useState } from "react";

import WhiteCard from "global/atoms/WhiteCard";
import Connection from "../../../utils/connections/contract/contractConnection";
import { useHistory, useParams } from "react-router-dom";
import { IFormContract } from "../../../utils/models/contract/contractModels";

import { useDispatch } from "react-redux";
import Section from "../../../global/atoms/section/Section";
import Form from "rsuite/Form";
import Col from "rsuite/Col";
import Input from "rsuite/Input";
import Row from "rsuite/Row";
import SelectPicker from "rsuite/SelectPicker";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import ActionsContainer from "../../../global/atoms/ActionsContainer";
import ButtonSubmit from "../../../global/atoms/ButtonSubmit";
import { handleToast } from "../../../utils/helpers";
import { contractDocumentPath } from "../../../routes/contract/contract";

interface IContractEdit {}

interface IContractor {
  customer: { id: string; name: string };
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  phone: string;
  id: string;
  idCard: string;
  pesel: string;
}

interface IDataContract {
  category: { id: string; name: string };
  contractor: IContractor;
  principal: IContractor;
  status: { id: string; name: string };
  type: { id: string; name: string };
  startDate: Date;
  contractProjectContainerId: string;
  number: string;
  projectName: string;
}

interface IDataToSend {
  status: string;
}

const ContractEdit: FunctionComponent<IContractEdit> = () => {
  const [data, setData] = useState<IDataContract>();
  const [dataToSend, setDataToSend] = useState<IDataToSend>({ status: "" });
  const [form, setForm] = useState<IFormContract>();
  const [errors, setErrors] = useState<any>({});

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const readOnly = true;

  useEffect(() => {
    Connection.getFormEditContract(id).then((response) => {
      setForm(response.data);
      setData(response.data.model);
      response.data?.header &&
        dispatch({
          type: "SET_HEADER",
          payload: {
            ...response.data.header,
            name: `${response.data.model.projectName} ${response.data.model.number}`,
          },
        });
    });
  }, []);

  const handleSubmit = () => {
    handleToast(Connection.updateContract(id, dataToSend), setErrors).then(() =>
      history.push(contractDocumentPath)
    );
    return true;
  };
  if (!data || !form) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form onSubmit={handleSubmit}>
          <Section title={`Umowa`}>
            <Row>
              <Col xs={12}>
                <div>
                  Projekt:
                  <Input disabled={readOnly} value={data.projectName} />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Rodzaj projektu:
                  <SelectPicker
                    block
                    disabled={readOnly}
                    data={form.types.options ?? []}
                    value={data.type.id}
                    labelKey={"name"}
                    valueKey={"id"}
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Rodzaj umowy:
                  <SelectPicker
                    block
                    disabled={readOnly}
                    data={form.categories.options ?? []}
                    value={data.category.id}
                    labelKey={"name"}
                    valueKey={"id"}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div>
                  Status:
                  <SelectPicker
                    block
                    data={form.statuses.options ?? []}
                    value={
                      dataToSend.status ? dataToSend.status : data.status.id
                    }
                    labelKey={"name"}
                    valueKey={"id"}
                    onChange={(value: any) =>
                      setDataToSend({ ...dataToSend, status: value })
                    }
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Numer:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.number}
                  />
                </div>
              </Col>
            </Row>
          </Section>
          <SeparatorEmpty size={0.75} />
          <Section title={"Kontraktor"}>
            <Row>
              <Col xs={12}>
                <div>
                  Pesel:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.pesel}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <SeparatorEmpty size={0.75} />
              <Col xs={12}>
                <div>
                  Imię:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.firstName}
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Nazwisko:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.lastName}
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Dokument tożsamości:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.idCard}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div>
                  Email:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.email}
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Telefon:
                  <Input
                    disabled={readOnly}
                    type={"text"}
                    placeholder={"brak"}
                    value={data.contractor.phone}
                  />
                </div>
                <SeparatorEmpty size={0.75} />
                <div>
                  Klient:
                  <SelectPicker
                    block
                    disabled={readOnly}
                    data={form.customers.options ?? []}
                    value={data.contractor.customer.id}
                    labelKey={"name"}
                    valueKey={"id"}
                  />
                </div>
              </Col>
            </Row>
          </Section>
          <SeparatorEmpty size={0.75} />
          <ActionsContainer>
            <ButtonSubmit
              form={form}
              disabled={
                !dataToSend.status || dataToSend.status == data.status.id
              }
            />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default ContractEdit;
