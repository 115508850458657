/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  CSSProperties,
  FunctionComponent,
  PropsWithChildren,
} from "react";
import { Panel } from "rsuite";

interface ISection {
  title: string | JSX.Element;
  className?: any;
  style?: CSSProperties;
  titleSize?: string;
}

// @depracated todo: obsolete - cleanup (use Panel)
const Section: FunctionComponent<PropsWithChildren<ISection>> = ({
  title,
  children,
  className,
  style,
  titleSize,
}) => {
  return (
    <Panel header={title} bordered style={{ position: "relative" }}>
      {children}
    </Panel>
  );
};

export default Section;
