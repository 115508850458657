import React, { useEffect, useState } from "react";
import TasksConnection from "utils/connections/tasks";
import { useParams } from "react-router-dom";
import { getProjectTasksState } from "utils/states";
import { FCC, ITask } from "utils/models";
import { Drawer, IconButton, Panel, Tooltip, Whisper } from "rsuite";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";

import { handleToast } from "utils/helpers";
import styles from "./styles.module.scss";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import SortableList from "../../../../global/atoms/dnd/SortableList";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import { DragHandle } from "../../../../global/atoms/SortableItem";

interface ITasksList {
  setSortableActive: (boolean) => void;
  sortableActive: boolean;
  goRefresh: () => void;
}

const TasksListSortable: FCC<ITasksList> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Array<ITask>>();
  const [isChanged, setIsChanged] = useState(false);
  const getData = () => {
    TasksConnection.getTasksForProject(getProjectTasksState).then((resp) => {
      setData(resp.data.data);
    });
  };

  const handleSubmitSorted = () => {
    const postData: Array<{ id: string }> =
      data?.map((item) => ({
        id: item.id,
      })) ?? [];
    handleToast(TasksConnection.updateBatchTaskOrder(id, postData)).then(() => {
      props.goRefresh();
      setIsChanged(false);
    });
  };
  const onSortEnd = (sortedData: Array<ITask>) => {
    setData(sortedData);
    setIsChanged(true);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Drawer
        placement={"bottom"}
        size={"lg"}
        open={props.sortableActive}
        onClose={() => {
          props.setSortableActive(false);
        }}>
        <Drawer.Header>
          <Drawer.Title>Sortowanie zadań</Drawer.Title>
          <Drawer.Actions>
            <IconButton
              icon={<CloseOutlineIcon />}
              appearance={"ghost"}
              onClick={() => {
                props.setSortableActive(false);
              }}>
              Zamknij
            </IconButton>
            <IconButton
              icon={<CheckOutlineIcon />}
              disabled={!isChanged}
              appearance={"primary"}
              onClick={() => {
                props.setSortableActive(false);
                handleSubmitSorted();
                props.goRefresh();
                setIsChanged(false);
              }}>
              Zapisz
            </IconButton>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 0 }}>
          <Panel bordered style={{}} bodyFill className={styles.containerTask}>
            <section
              className={styles.containerHeader}
              style={{ padding: "10px" }}>
              <span
                style={{
                  flex: 1,
                  minWidth: "200px",
                  marginLeft: "50px",
                  textAlign: "left",
                }}>
                NAZWA
              </span>
              <span style={{ width: 150, textAlign: "left" }}>Status</span>
              <span style={{ width: 150, textAlign: "left" }}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Liczba lokalizacji w których występuje zadanie
                    </Tooltip>
                  }>
                  <span>LOK.</span>
                </Whisper>
              </span>
              <span style={{ width: 50, textAlign: "left" }}>Lp.</span>
              <span style={{ width: 150, textAlign: "left" }}>Użytkownicy</span>
              <span style={{ width: 150, textAlign: "left" }}>
                Okres ważności
              </span>
              <span style={{ width: 150, textAlign: "center" }}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Zakończ na koniec tygodnia</Tooltip>}>
                  <span>ZNKT</span>
                </Whisper>
              </span>
            </section>
            <section>
              {!data ? (
                <Spinner />
              ) : (
                <SortableList
                  onSortEnd={onSortEnd}
                  data={data}
                  idKeyName={"id"}
                  dragHandleActivator={true}
                  mapFunction={(rowData) => (
                    <>
                      <section className={styles.containerTaskItem}>
                        <DragHandle />
                        <span
                          style={{
                            flex: 1,
                            minWidth: "200px",
                            textAlign: "left",
                            height: "20px",
                            color: "#ffaf38",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}>
                          {
                            <a href={`/projects/${id}/tasks/${rowData.id}`}>
                              {rowData.name}
                            </a>
                          }
                        </span>
                        <span style={{ width: 150, textAlign: "center" }}>
                          {" "}
                          <BadgeStatus color={rowData.taskStatus.color}>
                            {rowData.taskStatus.name}
                          </BadgeStatus>
                        </span>
                        <span style={{ width: 150, textAlign: "center" }}>
                          {rowData.locationsCount}
                        </span>
                        <span style={{ width: 50, textAlign: "center" }}>
                          {rowData.number}
                        </span>
                        <span style={{ width: 150, textAlign: "center" }}>
                          {rowData.usersCount}
                        </span>
                        <span style={{ width: 150, textAlign: "center" }}>
                          {rowData.expirationDays}
                        </span>
                        <span style={{ width: 150, textAlign: "center" }}>
                          {rowData.isExpirationEndOfWeek ? (
                            <CheckOutlineIcon
                              width={"15px"}
                              height={"15px"}
                              style={{
                                color: "#e09616",
                              }}
                            />
                          ) : (
                            <CloseOutlineIcon
                              width={"15px"}
                              height={"15px"}
                              style={{
                                color: "#e09616",
                              }}
                            />
                          )}
                        </span>
                      </section>
                    </>
                  )}></SortableList>
              )}
            </section>
          </Panel>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default TasksListSortable;
