import React, { FunctionComponent, useState } from "react";
import GlobalReportTemplate, {
  IReportTemplateState,
} from "./GlobalReportTemplate";

interface IState extends IReportTemplateState {}

const GlobalVisitsReportTemplate: FunctionComponent = () => {
  const initialState = {
    templateName: "",
    columnsData: [
      {
        label: "Projekt",
        name: "project",
        active: true,
        options: [
          {
            name: "projectName",
            label: "Projekt",
            active: true,
          },
          {
            name: "customerName",
            label: "Klient",
            active: true,
          },
          {
            name: "visitId",
            label: "Identyfikator zadania",
            active: true,
          },
          {
            name: "visitStatus",
            label: "Status zadania (kod)",
            active: true,
          },
          {
            name: "visitStatusName",
            label: "Status zadania (nazwa)",
            active: true,
          },
          {
            name: "locationId",
            label: "ID Lokalizacji",
            active: true,
          },
          {
            name: "customerId",
            label: "ID Klienta",
            active: true,
          },
          {
            name: "startDate",
            label: "Data rozpoczęcia",
            active: true,
          },
          {
            name: "endDate",
            label: "Data zakończenia",
            active: true,
          },
          {
            name: "expirationDate",
            label: "Termin realizacji wizyty",
            active: true,
          },
          {
            name: "reportingUser",
            label: "Przeznaczone dla",
            active: true,
          },
          {
            name: "locationName",
            label: "Nazwa potoczna",
            active: true,
          },
          {
            name: "networkName",
            label: "Sieć",
            active: true,
          },
          {
            name: "city",
            label: "Miejscowość",
            active: true,
          },
          {
            name: "address",
            label: "Adres",
            active: true,
          },
          {
            name: "activityName",
            label: "Nazwa zadania",
            active: true,
          },
          {
            name: "questionName",
            label: "Szczególy zadania",
            active: true,
          },
          {
            name: "questionType",
            label: "Rodzaj zadania (kod)",
            active: true,
          },
          {
            name: "questionTypeName",
            label: "Rodzaj zadania (nazwa)",
            active: true,
          },
          {
            name: "questionAnswer",
            label: "Odpowiedz",
            active: true,
          },
          {
            name: "itemEan",
            label: "Produkty (EAN)",
            active: true,
          },
          {
            name: "itemName",
            label: "Produkty (Nazwa)",
            active: true,
          },
          {
            name: "taskName",
            label: "Zadanie (Nazwa)",
            active: false,
          },
        ],
      },
      {
        label: "Opcje",
        name: "extendsOptions",
        active: false,
        options: [
          {
            name: "photoComments",
            label: "Komentarze do zdjęć",
            active: false,
          },
        ],
      },
    ],
  };
  const [state, setState] = useState<IState>(initialState);
  return (
    <>
      <GlobalReportTemplate
        sortable
        initialState={initialState}
        state={state}
        setState={setState}
      />
    </>
  );
};

export default GlobalVisitsReportTemplate;
