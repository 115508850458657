import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters from "global/filters/UniversalFilters";
import Pagination from "global/pagination/Pagination";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { IconButton, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;
import WorkTimeHistoryConnection, {
  IWTHFileRequestData,
  IWTHListRequestData,
} from "utils/connections/workTimeHistoryConnection";
import { IBodyResponse } from "utils/models";
import { _paginate30 } from "utils/states";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { AxiosPromise } from "axios";
import { useDispatch } from "react-redux";
import { handleToastRedux } from "utils/helpers";
import download from "downloadjs";
import dayjs from "dayjs";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IDataEntity {
  customerName: string;
  userName: string;
  userRoleName: string;
  firstActivity: string;
  lastActivity: string;
  workTime: string;
}

const WorkTimeHistory: FunctionComponent = () => {
  const defaultRequestData: IWTHListRequestData = {
    customerId: "",
    roleId: "",
    userId: "",
    dateFrom: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
    ..._paginate30,
  };

  const [requestData, setRequestData] =
    useState<IWTHListRequestData>(defaultRequestData);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IBodyResponse<IDataEntity> | null>(null);
  const dispatch = useDispatch();

  const downloadFile = (
    connection: (requestData: IWTHFileRequestData) => AxiosPromise<any>
  ) => {
    handleToastRedux(connection(requestData), dispatch).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
    });
  };

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          icon={<FileDownloadIcon />}
          onClick={downloadFile.bind(null, WorkTimeHistoryConnection.getFile)}>
          .xlsx
        </IconButton>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            loading={loading}
            autoHeight={true}>
            <Column flexGrow={1}>
              <HeaderCell>Klient</HeaderCell>
              <Cell>{(rowData) => rowData.customerName}</Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Nazwa użytkownika</HeaderCell>
              <Cell>{(rowData) => rowData.userName}</Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Rola</HeaderCell>
              <Cell>{(rowData) => rowData.userRoleName}</Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Pierwsza aktywność</HeaderCell>
              <Cell dataKey={"firstActivity"} />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Ostatnia aktywność</HeaderCell>
              <Cell dataKey={"lastActivity"} />
            </Column>
            <Column flexGrow={1} align={"right"}>
              <HeaderCell>Czas pracy [g:m]</HeaderCell>
              <Cell dataKey={"workTime"} />
            </Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        formGet={WorkTimeHistoryConnection.form}
        load={WorkTimeHistoryConnection.list}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setData}
        filterStorageKey={"workTimeHistoryList"}
        elements={[
          {
            label: "Klient",
            type: "select",
            formKey: "customers",
            stateKey: "customerId",
            required: true,
          },
          {
            label: "Uzytkownik",
            type: "select",
            formKey: "users",
            stateKey: "userId",
          },
          {
            label: "Grupa użytkownika",
            type: "select",
            formKey: "roles",
            stateKey: "roleId",
          },
          {
            label: "Data od",
            type: "input_date",
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateFrom",
          },
          {
            label: "Data do",
            type: "input_date",
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateTo",
          },
        ]}
      />
    </>
  );
};
export default WorkTimeHistory;
