import React, { memo, useEffect } from "react";
import { Col, Panel } from "rsuite";
import { FCC, IVisitPosition } from "utils/models";
import QuestionBody from "./Atoms/QuestionBody";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { PanelNameErrorWrapper } from "../VisitEditForm";
import {
  getDependentOnQuestion,
  hasErrors,
  useEditContext,
  useErrorContext,
  usePanelContext,
  useStaticContext,
} from "./VisitHelpers";
import Spinner from "global/atoms/Spinner/Spinner";

interface IVisitProductPanel {
  item: IVisitPosition;
  index: number;
}

const VisitProductPanel: FCC<IVisitProductPanel> = (props) => {
  const key = `product-panel-${props.item.question.refQuestionId}-${props.item.itemId}`;

  const {
    readOnly,
    visitId,
    comments,
    questionsFlatState,
    hiddenElements,
    disabledElements,
  } = useStaticContext();

  const { disabledQuestions } = useEditContext();
  const errCtx = useErrorContext();
  const panelCtx = usePanelContext();

  useEffect(() => {
    panelCtx.registerPanelId(key);
  }, []);

  return (
    <Panel
      collapsible
      expanded={panelCtx.openPanels.includes(key)}
      onSelect={() => panelCtx.panelToggle(key)}
      eventKey={key}
      id={key}
      key={key}
      header={
        <div>
          <PanelNameErrorWrapper
            hasErrors={hasErrors(props.item.positions, errCtx.errors)}>
            {props.item.name}
          </PanelNameErrorWrapper>

          {props.item.level + props.item.levelToOrder > 0 && (
            <div>
              {props.item.level > 0 && (
                <small>
                  Min. stan <strong>{props.item.level}</strong>
                </small>
              )}
              &nbsp;
              {props.item.levelToOrder > 0 && (
                <small>
                  Min. zamówienie <strong>{props.item.levelToOrder}</strong>
                </small>
              )}
            </div>
          )}
        </div>
      }>
      {!panelCtx.openPanels.includes(key) ? (
        <Spinner />
      ) : (
        <Col
          xs={24}
          style={{ paddingLeft: "15px", borderLeft: "12px solid #eee" }}>
          {props.item.positions.map((q2) => (
            <QuestionBody
              key={`product-question-body-${props.item.itemId}-${q2.question.refQuestionId}`}
              activityType={"ITEM"}
              question={q2.question}
              item={props.item}
              isDisabled={disabledQuestions.includes(q2.question.refQuestionId)}
              readOnly={readOnly}
              dependedOnQuestion={
                getDependentOnQuestion(
                  q2.question.dependOnQuestion.refQuestionId,
                  questionsFlatState
                ) ?? undefined
              }
              hiddenElements={hiddenElements}
              disabledElements={disabledElements}
              comments={comments.filter(
                (c) => c.visitQuestionId === q2.question.refQuestionId
              )}
              visitId={visitId ?? ""}
            />
          ))}
        </Col>
      )}
      <SeparatorEmpty size={1} />
    </Panel>
  );
};

export default memo(VisitProductPanel);
