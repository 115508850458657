import dayjs from "dayjs";
import React, { FunctionComponent } from "react";
import { Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;
import { IScheduleItem } from "../Schedule";
import objectHash from "object-hash";

interface IScheduleViewMonth {
  dateFrom: Date;
  dateTo: Date;
  data: Array<IScheduleItem>;
}

interface IParsedData {
  date: dayjs.Dayjs;
  hasVisit: boolean;
}

const ScheduleViewMonth: FunctionComponent<IScheduleViewMonth> = (props) => {
  const header: Array<dayjs.Dayjs> = [];

  const numDays = dayjs(props.dateTo).diff(dayjs(props.dateFrom), "day");
  const dataParsed = Array.from({ length: numDays }, (_, i) => {
    const date = dayjs(props.dateFrom).add(i, "day");
    header.push(date);
    return { date, hasVisit: false };
  });

  return (
    <>
      <Table data={dataParsed} autoHeight rowHeight={25} bordered>
        <Column width={20} align="center" fixed>
          <HeaderCell style={{ fontSize: "0.7em" }}>L.p</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        {header.map((d) => {
          return (
            <Column width={25} key={objectHash(d)}>
              <HeaderCell style={{ fontSize: "0.6em" }}>
                {d.format("dd")}
              </HeaderCell>
              <Cell style={{ padding: 0 }}>
                {(rowData: IParsedData) => {
                  if (d.isSame(rowData.date)) {
                    return <div style={{ background: "yellow" }}>&nbsp;</div>;
                  }
                }}
              </Cell>
            </Column>
          );
        })}
      </Table>
    </>
  );
};

export default ScheduleViewMonth;
