import React, { FunctionComponent, useEffect, useState } from "react";
import { handleSetErrors, handleToast } from "utils/helpers";
import { IActivityAdd, IFormActivityState } from "utils/models";
import ActivitiesConnection from "utils/connections/activities";
import { addActivityState } from "utils/states";
import ActionsContainer from "global/atoms/ActionsContainer";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import Spinner from "global/atoms/Spinner/Spinner";
import { Button, IconButton } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ActivityHeaderForm from "./ActivityHeaderForm";

interface IActivityAddComponent {
  handleDisableAddNew: () => void;
  projectId: string;
  taskId: string;
  addedCallback: (activityId: string, state: IActivityAdd) => void;
}

const ActivityAdd: FunctionComponent<IActivityAddComponent> = ({
  handleDisableAddNew,
  projectId,
  taskId,
  addedCallback,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}) => {
  const [form, setForm] = useState<any | "loading">("loading");
  const [state, setState] = useState<IActivityAdd | IFormActivityState>(
    addActivityState
  );
  const [errors, setErrors] = useState<any | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    ActivitiesConnection.getFormAddActivity(projectId, taskId).then((data) => {
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
      setState({
        ...addActivityState,
        activityTypeId: data.data.activityTypes.value,
        activityGroupId: data.data.activityGroups.value,
      });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(
      ActivitiesConnection.createActivityForTask(projectId, taskId, state)
    )
      .then((response) => {
        addedCallback(response.activityId, state);
        handleDisableAddNew();
      })
      .catch((err) => handleSetErrors(err, setErrors));
  };

  if (form === "loading") return <Spinner />;

  return (
    <div className={styles.wrapperActivityAd}>
      {
        <div
          style={{
            position: "relative",
            paddingBottom: "24px",
            overflow: "hidden",
          }}>
          <ActivityHeaderForm
            state={state}
            setState={setState}
            form={form}
            errors={errors}
          />
          <SeparatorEmpty />
          <ActionsContainer>
            <Button appearance={"ghost"} onClick={handleDisableAddNew}>
              Anuluj
            </Button>
            <IconButton
              appearance={"primary"}
              icon={<EditIcon />}
              onClick={handleSubmit}>
              Zapisz
            </IconButton>
          </ActionsContainer>
        </div>
      }
    </div>
  );
};

export default ActivityAdd;
