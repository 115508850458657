import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { IVisitsReportTemplateData } from "../../views/projects/components/visitsReport/VisitsReportTemplateEdit";
import { IVisitsReportSave } from "../../views/projects/components/visitsReport/VisitsReport";

const VisitsReportConnection = {
  saveTemplate: (data: IVisitsReportTemplateData) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/template`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateTemplate: (templateId: string, data: IVisitsReportTemplateData) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getGenerateReportViewData: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/view/generate_report/${projectId}`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getTemplateById: (templateId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  saveReport: (data: IVisitsReportSave) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report`,
      data
    );
  },
  getLatestReports: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/latest/${projectId}`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFile: (visitReportResultId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/file/${visitReportResultId}`,
      {
        headers: getAuthHeader(),
        responseType: "blob",
      }
    );
  },
  getVisits: (filters) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/view/find_visits`,
      filters
    );
  },
  deleteTemplate: (templateId: string) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`
    );
  },
};

export default VisitsReportConnection;
