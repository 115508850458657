import React, { FunctionComponent, useState } from "react";
import WhiteCard from "global/atoms/WhiteCard";
import Pagination from "global/pagination/Pagination";
import ScTable from "global/table/ScTable";
import { Table, Checkbox } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory, useParams } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "global/filters/UniversalFilters";
import ButtonListRedirect from "global/atoms/ButtonListRedirect";
import NavOptionsWrapper from "utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "utils/toggleToDeleteHelper";
import connection from "utils/connections/contract/contractSalaryConnection";
import { IContractSalaryFilterFormModel } from "../../../utils/models/contract/contractSalaryModels";
import { getContractSalaryFilterState } from "../../../utils/states/contract/contractSalaryStates";
import styles from "../../admin/components/usersList/styles.module.scss";
import { contractProjectPath } from "../../../routes/contract/project";

interface IContractProjectSalariesList {}

const ContractProjectSalariesList: FunctionComponent<
  IContractProjectSalariesList
> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IContractSalaryFilterFormModel>(
    getContractSalaryFilterState
  );
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const path = `${contractProjectPath}/${id}/salaries`;
  const handleEdit = (event: any) => {
    history.push(`${path}/${event.currentTarget.dataset.id}/edit`);
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: connection.delete.bind(null, id),
            callback: () => {
              setTriggerLoad(Date.now());
            },
          }}
          addPath={`${path}/add`}
        />
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>
        {data === null ? (
          <></>
        ) : (
          <ScTable data={data?.data ?? []} loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} align="left">
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`${contractProjectPath}/${id}/salaries/${rowData.id}/edit`}>
                    {rowData.salary.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} align="left">
              <Table.HeaderCell>Nazwa projektu</Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData) => rowData.contractProjectName}
              </Table.Cell>
            </Table.Column>

            <Table.Column align="center">
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData) => {
                  return (
                    <span
                      className={styles.status}
                      style={{
                        color: rowData.salary.type.color,
                        borderColor: rowData.salary.type.color,
                      }}>
                      {rowData.salary.type.name}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.ColumnGroup header={"Podstawowe"}>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>Typ wynagrodzenia</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.baseRate.type.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>stawka</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.baseRate.settlement.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.baseRate.amount}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.baseRate.amount}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>

            <Table.ColumnGroup header={"Premia"}>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell> Typ wynagrodzenia </Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.bonusRate.type.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>stawka</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.bonusRate.settlement.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.bonusRate.amount}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.bonusRate.extraAmount}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell> Brutto: </Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => {
                    return (
                      <Checkbox
                        readOnly={true}
                        checked={!rowData.salary.bonusRate.gross}
                      />
                    );
                  }}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>
            <Table.ColumnGroup header={"Dodatki"}>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>Typ wynagrodzenia</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.extraRate.type.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>stawka</Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.extraRate.settlement.name}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp; </Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.extraRate.amount}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} align="left">
                <Table.HeaderCell>&nbsp; </Table.HeaderCell>
                <Table.Cell width={100}>
                  {(rowData) => rowData.salary.extraRate.amount}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>

            <Table.Column verticalAlign={"middle"} align="center" fixed="right">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContractProjectSalariesList.bind(null, id)}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getContractSalaryFilterState}
        setResultData={setData}
        formGet={connection.getContractProjectSalariesFilterForm.bind(null, id)}
        filterStorageKey={"ContractProjectSalariesList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "contractProjectName",
            label: "Projekt",
          },
        ]}
      />
    </>
  );
};

export default ContractProjectSalariesList;
