// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FCPLno86XP33UdHC5FVA{border:1px solid #131315;border-radius:50px;padding:2px 10px;font-family:Poppins,sans-serif;font-size:12px;font-weight:500;letter-spacing:.6px}", "",{"version":3,"sources":["webpack://./src/global/atoms/badge/atoms.module.scss"],"names":[],"mappings":"AAEA,sBACE,wBAAA,CACA,kBAAA,CACA,gBAAA,CACA,8BAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":["@import '../../../styles/variable.scss';\n\n.badgeStatus {\n  border: 1px solid $colorActive;\n  border-radius: 50px;\n  padding: 2px 10px;\n  font-family: Poppins, sans-serif;\n  font-size: 12px;\n  font-weight: 500;\n  letter-spacing: 0.6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeStatus": "FCPLno86XP33UdHC5FVA"
};
export default ___CSS_LOADER_EXPORT___;
