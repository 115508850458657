// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oGB_rlbtXAmXwXqjdcS5 div{display:inline-block;margin-right:5px}.oGB_rlbtXAmXwXqjdcS5 div:not(:last-child)::after{content:\",\"}.SpMUvYJ3DJg1eejDvq2v{position:absolute;bottom:-3px;display:block;color:rgba(134,134,134,.78);font-size:.7em;margin-top:5px;margin-bottom:5px}.f_vMDqd2rzo1axp5NKxe{position:absolute;bottom:-5px;display:block;color:rgba(134,134,134,.78);font-size:.8em;margin-top:5px;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/views/projects/components/editProject/Alerts/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,oBAAA,CACA,gBAAA,CAGF,kDACE,WAAA,CAIF,sBACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,2BAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,2BAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".alertCellWraper div {\n  display: inline-block;\n  margin-right: 5px;\n}\n\n.alertCellWraper div:not(:last-child)::after {\n  content: ','\n}\n\n\n.subHeader {\n  position: absolute;\n  bottom: -3px;\n  display: block;\n  color: rgba(134,134,134,0.78);\n  font-size: 0.7em;\n  margin-top: 5px;\n  margin-bottom: 5px\n}\n\n.subCell {\n  position: absolute;\n  bottom: -5px;\n  display: block;\n  color: rgba(134,134,134,0.78);\n  font-size: 0.8em;\n  margin-top: 5px;\n  margin-bottom: 5px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertCellWraper": "oGB_rlbtXAmXwXqjdcS5",
	"subHeader": "SpMUvYJ3DJg1eejDvq2v",
	"subCell": "f_vMDqd2rzo1axp5NKxe"
};
export default ___CSS_LOADER_EXPORT___;
