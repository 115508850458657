import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Input, Row, SelectPicker } from "rsuite";
import LocationsConnection from "../../../../utils/connections/locations";
import {
  IAddLocationCustomer,
  ICreateLocation,
  ILocationCustomer,
  SetState,
} from "../../../../utils/models";
import Table from "rsuite/Table";
import { handleToast } from "../../../../utils/helpers";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmModalCallback } from "../../../../redux/store";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { RowDataType } from "rsuite/esm/Table";
import { ILocationForm } from "./LocationForm";

interface ILocationCustomerForm {
  locationState: ICreateLocation;
  setLocationState: SetState<ICreateLocation>;
  locationForm: ILocationForm;
  disabled?: boolean;
}

const LocationCustomerForm: FunctionComponent<ILocationCustomerForm> = (
  props
) => {
  const [addState, setAddState] = useState<IAddLocationCustomer>({
    customerId: null,
    code: null,
  });

  useEffect(() => {
    if (props.locationForm.customers.options.length === 1) {
      setAddState({
        ...addState,
        customerId: props.locationForm.customers.options[0].id,
      });
    }
  }, []);

  const addNew = () => {
    setAddState({
      ...addState,
      code: null,
    });

    props.setLocationState({
      ...props.locationState,
      locationCustomers: [
        {
          locationId: props.locationForm.id,
          customerId: addState.customerId ?? "",
          customerName:
            props.locationForm.customers.options.find(
              (co) => co.id === addState.customerId
            )?.name ?? "-",
          code: addState.code ?? "",
        },
        ...props.locationState.locationCustomers,
      ],
    });
  };

  const getData = (): Array<ILocationCustomer> => {
    return props.locationState.locationCustomers ?? [];
  };

  const filteredCustomers = (): Array<any> => {
    return (
      props.locationForm.customers?.options.filter(
        (c) => !(getData()?.map((d) => d.customerId) ?? []).includes(c.id)
      ) ?? []
    );
  };

  const removeItem = (itemId: string): void => {
    confirmModalCallback("Czy na pewno usunąć element?", () => {
      const promise = LocationsConnection.removeLocationCustomerItem(itemId);
      handleToast(promise).then(() => {
        props.setLocationState({
          ...props.locationState,
          locationCustomers: [
            ...props.locationState.locationCustomers.filter(
              (lc) => lc.id !== itemId
            ),
          ],
        });
      });
    });
  };

  return (
    <>
      <>
        <Section title={"Kod klienta dla lokalizacji"} titleSize={"1.1em"}>
          {!props.disabled && filteredCustomers().length === 0 ? (
            <div style={{ textAlign: "center", color: "#aaa" }}>
              Brak możliwości dodania nowych kodów.
            </div>
          ) : props.disabled === true ? (
            <></>
          ) : (
            <Row>
              <Col xs={11}>
                <SelectPicker
                  block
                  cleanable={false}
                  value={addState.customerId?.toString() ?? ""}
                  readOnly={filteredCustomers().length === 1}
                  data={
                    filteredCustomers().map((c) => ({
                      label: c.name,
                      value: c.id,
                    })) ?? []
                  }
                  placeholder={"Klient"}
                  onSelect={(customerId) => {
                    setAddState({
                      ...addState,
                      customerId: customerId,
                    });
                  }}
                />
              </Col>
              <Col xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Wewnętrzny kod klienta"}
                  name={"code"}
                  value={addState.code?.toString() ?? ""}
                  onChange={(value) =>
                    setAddState({
                      ...addState,
                      code: value,
                    })
                  }
                />
              </Col>
              <Col xs={2}>
                <Button
                  type={"button"}
                  disabled={
                    Object.entries(addState).filter((val) => {
                      return val[1] !== null;
                    }).length < Object.keys(addState).length
                  }
                  block
                  appearance={"ghost"}
                  onClick={addNew}>
                  Dodaj
                </Button>
              </Col>
            </Row>
          )}

          <SeparatorEmpty size={1.5} />

          <Table autoHeight loading={getData() === null} data={getData() ?? []}>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="customerName" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell depth={0} width={0}>
                Wewnętrzny kod klienta
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="code" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <Table.HeaderCell depth={0} width={0}>
                Opcje
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData: RowDataType<ILocationCustomer>) => {
                  if (props.disabled === true) return <></>;
                  if (!rowData.id)
                    return (
                      <small style={{ color: "#aaa" }}>Zapisz zmiany</small>
                    );
                  return (
                    <TrashIcon
                      style={{ cursor: "pointer", color: "#ffaf38" }}
                      onClick={removeItem.bind(null, rowData?.id ?? "")}
                    />
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </Section>
      </>
    </>
  );
};

export default LocationCustomerForm;
