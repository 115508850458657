import { SET_LOADING } from "../actions";
import { loadingState } from "../states";

const appReducer = (state = loadingState, action: any) => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default appReducer;
