import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

import { IRole } from "../../../../utils/models";
import styles from "./styles.module.scss";
import { confirmModalCallback } from "../../../../redux/store";
import { Checkbox } from "rsuite";

interface ICustomRoles {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  options: Array<any>;
  errors?: any;
  hierarchyLevel: number;
}

const CustomRoles: FunctionComponent<ICustomRoles> = ({
  state,
  setState,
  options,
  errors,
  hierarchyLevel,
}) => {
  const handleToggleRole = (roleId: string) => {
      const mapped = state.roles.map(
        (item: { roleId: string; default: boolean }) => ({
          roleId: item.roleId,
          default: item.default,
        })
      );
      //TODO: udoskonalić, jeśli usunie się rolę która ma default, to niech default przjedzie na jakąś inną
      const mapped2 = state.roles.map(
        (item: { roleId: string; default: boolean }) => item.roleId
      );
      if (mapped2.includes(roleId)) {
        confirmModalCallback(
          <>
            {
              "Odłączenie grupy od użytkownika spowoduje usunięcie powiązań w projektach!"
            }
            <br />
            {"Nie zapomnij zapisać zmian."}
          </>,
          () => {
            const filtered = mapped.filter(
              (item: { roleId: string; default: boolean }) =>
                item.roleId !== roleId
            );
            setState({
              ...state,
              roles: filtered.map((item: any) => ({
                roleId: item.roleId,
                default: item.default,
              })),
            });
          }
        );
      } else {
        setState({
          ...state,
          roles: [
            ...state.roles,
            { roleId: roleId, default: !state.roles.length },
          ],
        });
      }
    },
    handleToggleRoleRadio = (event: any) => {
      setState({
        ...state,
        roles: state.roles.map((item: any) => {
          if (item.roleId === event.currentTarget.dataset.id) {
            return { roleId: item.roleId, default: true };
          } else {
            return { roleId: item.roleId, default: false };
          }
        }),
      });
    },
    handleToggleRoleAll = () => {
      if (state.roles.length) {
        setState({ ...state, roles: [] });
      } else {
        setState({
          ...state,
          roles: options
            .filter((item: IRole) => item.hierarchyLevel >= hierarchyLevel)
            .map((item: IRole, idx: number) => {
              if (idx) {
                return { roleId: item.roleId, default: false };
              } else {
                return { roleId: item.roleId, default: true };
              }
            }),
        });
      }
    };

  return (
    <section>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <Checkbox
            checked={state.roles.length}
            onChange={handleToggleRoleAll}
          />
          Nazwa Grupy
        </div>
        Grupa Domyślna
      </div>
      {options.map((item: IRole) => (
        <div
          key={item.roleId}
          className={styles.wrapper}
          style={{
            backgroundColor:
              hierarchyLevel <= item.hierarchyLevel ? "white" : "#F7F7FA",
          }}>
          <div className={styles.flex}>
            <Checkbox
              disabled={hierarchyLevel > item.hierarchyLevel}
              id={item.roleId}
              checked={state.roles
                .map((item: any) => item.roleId)
                .includes(item.roleId)}
              onChange={handleToggleRole.bind(null, item.roleId)}
            />
            {item.roleName}
          </div>
          {state.roles.map((stateItem: any) => {
            if (stateItem.roleId === item.roleId) {
              return (
                <span
                  onClick={handleToggleRoleRadio}
                  data-id={item.roleId}
                  data-name={name}
                  className={
                    stateItem.default
                      ? styles.radioButtonActive
                      : styles.radioButton
                  }
                />
              );
            }
          })}
        </div>
      ))}
      {errors && <span className={styles.errors}>{errors["roles"]}</span>}
    </section>
  );
};

export default CustomRoles;
