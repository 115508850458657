import React from "react";
import { deepClone } from "../../../../utils/helpers";
import { Checkbox, Col } from "rsuite";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

interface ICloneOptions {
  name: string;
  label: string;
  active: boolean;
  onCheck?: Record<string, boolean>;
  onUncheck?: Record<string, boolean>;
  checkedDirect?: Record<string, boolean>;
}

interface IClone {
  label: string;
  name: string;
  active: boolean;
  options: Array<ICloneOptions>;
}

export const optionsProject: Array<IClone> = [
  {
    label: "Projekt",
    name: "project",
    active: true,
    options: [
      {
        name: "isCloneProjectUsers",
        label: "Prawa dostępu użytkowników",
        active: true,
        onCheck: {},
        onUncheck: {
          isCloneProjectLocationUsers: false,
          isCloneProjectLinkedUsers: false,
        },
      },
      {
        name: "isCloneProjectLinkedUsers",
        label: "Powiązania pomiędzy użytkownikami",
        active: true,
        onCheck: { isCloneProjectUsers: true },
        onUncheck: {},
      },
      {
        name: "isCloneProjectItems",
        label: "Produkty projektu",
        active: true,
        onCheck: { isCloneItems: true },
        checkedDirect: {
          isCloneItems: false,
          isCloneListingItemHighlightNetwork: false,
          isCloneListingItemLevelNetwork: false,
          isCloneListingProductNetwork: false,
        },
      },
      {
        name: "isCloneProjectLocations",
        label: "Lokalizacje projektu",
        active: true,
        onCheck: {},
        onUncheck: {
          isCloneProjectLocationQuestions: false,
          isCloneProjectLocationUsers: false,
        },
        checkedDirect: {
          isCloneLocations: false,
          isCloneListingProductNetwork: false,
          isCloneListingQuestionNetwork: false,
        },
      },
      {
        name: "isCloneProjectLocationQuestions",
        label: "Pytania do lokalizacji",
        active: true,
        onCheck: { isCloneProjectLocations: true },
        onUncheck: {},
      },
      {
        name: "isCloneProjectLocationUsers",
        label: "Powiązania użytkowników w lokalizacjach",
        active: true,
        onCheck: {
          isCloneProjectUsers: true,
          isCloneProjectLocations: true,
        },
        onUncheck: {},
      },
      {
        name: "isCloneTasks",
        label: "Zadania projektu",
        active: true,
        onCheck: {},
        onUncheck: {
          isCloneItems: false,
          isCloneLocations: false,
          isCloneQuestions: false,
          isCloneListingItemHighlightNetwork: false,
          isCloneListingItemLevelNetwork: false,
          isCloneListingProductNetwork: false,
          isCloneListingQuestionNetwork: false,
        },
      },
      {
        name: "isCloneVisitReportTemplates",
        label: "Szablony raportów projektu",
        active: true,
        onCheck: {},
        onUncheck: {},
      },
    ],
  },
];
export const optionsTask: Array<IClone> = [
  {
    label: "Aktywności",
    name: "activity",
    active: true,
    options: [
      {
        name: "isCloneQuestions",
        label: "Pytania",
        active: true,
        onCheck: { isCloneTasks: true },
        onUncheck: { isCloneListingQuestionNetwork: false },
      },
      {
        name: "isCloneItems",
        label: "Produkty",
        active: true,
        onCheck: { isCloneProjectItems: true, isCloneTasks: true },
        onUncheck: {
          isCloneListingItemHighlightNetwork: false,
          isCloneListingItemLevelNetwork: false,
          isCloneListingProductNetwork: false,
        },
        checkedDirect: {},
      },
      {
        name: "isCloneLocations",
        label: "Lokalizacje",
        active: true,
        onCheck: {
          isCloneProjectLocations: true,
          isCloneTasks: true,
          isCloneProjectUsers: true,
        },
        onUncheck: {
          isCloneListingQuestionNetwork: false,
          isCloneListingProductNetwork: false,
        },
        checkedDirect: {
          isCloneProjectLocationQuestions: true,
          isCloneProjectLocationUsers: true,
        },
      },
    ],
  },
  {
    name: "cloneListing",
    label: "Listingi",
    active: false,
    options: [
      {
        name: "isCloneListingItemHighlightNetwork",
        label: "Listing - produkty wyróżnienie",
        active: false,
        onCheck: {
          isCloneTasks: true,
          isCloneItems: true,
          isCloneProjectItems: true,
          isCloneLocations: true,
          isCloneProjectLocations: true,
        },
        onUncheck: {},
      },
      {
        name: "isCloneListingItemLevelNetwork",
        label: "Listing - minimalne stany produktów",
        active: false,
        onCheck: {
          isCloneTasks: true,
          isCloneItems: true,
          isCloneProjectItems: true,
          isCloneLocations: true,
          isCloneProjectLocations: true,
        },
        onUncheck: {},
      },
      {
        name: "isCloneListingProductNetwork",
        label: "Listing - produkty",
        active: false,
        onCheck: {
          isCloneTasks: true,
          isCloneItems: true,
          isCloneProjectItems: true,
          isCloneLocations: true,
          isCloneProjectLocations: true,
        },
        onUncheck: {},
      },
      {
        name: "isCloneListingQuestionNetwork",
        label: "Listing - pytania",
        active: false,
        onCheck: {
          isCloneTasks: true,
          isCloneItems: true,
          isCloneProjectItems: true,
          isCloneLocations: true,
          isCloneProjectLocations: true,
        },
        onUncheck: {},
      },
    ],
  },
];
export const requestDataSetOptionsChange = (
  propName: string,
  checked: boolean,
  option: ICloneOptions,
  request: Record<string, boolean>,
  options: Array<IClone>
) => {
  let s = deepClone(request);

  if (option.onUncheck && !checked) {
    for (const [checkedOption, value] of Object.entries(option.onUncheck)) {
      if (
        options.find(
          (option) => option?.options?.find((pos) => pos.name == checkedOption)
        )
      ) {
        s = { ...s, [checkedOption]: value };
      }
    }
  }
  if (option.onCheck && checked) {
    for (const [checkedOption, value] of Object.entries(option.onCheck)) {
      if (
        options.find(
          (option) => option?.options?.find((pos) => pos.name == checkedOption)
        )
      ) {
        s = { ...s, [checkedOption]: value };
      }
    }
  }
  /**
   * if checkedOption=true direct change to checked else switch to off
   * */
  if (option.checkedDirect) {
    for (const [checkedOption, value] of Object.entries(option.checkedDirect)) {
      if (
        options.find(
          (option) => option?.options?.find((pos) => pos.name == checkedOption)
        )
      ) {
        s = { ...s, [checkedOption]: value ? checked : false };
      }
    }
  }
  s = { ...s, [propName]: checked };
  return s;
};

export const defaultRequestData = (options) => {
  let _defaultData;
  options.map((el) =>
    el.options.map((option) => {
      _defaultData = { ..._defaultData, [option.name]: option.active };
    })
  );
  return _defaultData;
};

export const renderOptions = (options, requestData, requestDataChange) => {
  return (
    <>
      {" "}
      {options.map((element, indexSection) => (
        <Col xs={12} key={`idx-${element.name}-${indexSection}`}>
          <Section title={element.label}>
            {element.options.map((option, index) => (
              <Checkbox
                key={`ida-${element.name}-${index}`}
                style={{ width: "100%" }}
                checked={requestData[option.name]}
                onChange={(value, checked) =>
                  requestDataChange(
                    option.name,
                    checked,
                    option,
                    requestData,
                    options
                  )
                }>
                {option.label}
              </Checkbox>
            ))}
          </Section>
          <SeparatorEmpty />
        </Col>
      ))}
    </>
  );
};
