import { getBaseApiUrl, getOrganizationId } from "../helpers";
import AxiosHelper from "../AxiosHelper";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;
const ProjectLocationUsersConnection = {
  getProjectUsersRolesLinkedFilterForm: (projectId: string) => {
    return AxiosHelper.get(
      `${baseUrl}/${projectId}/users/roles/linked/filter/form`
    );
  },
  getProjectUsersRolesLinkedListForm: (projectId: string, data) => {
    return AxiosHelper.post(
      `${baseUrl}/${projectId}/users/roles/linked/list/form`,
      data
    );
  },
  putProjectUsersRolesLinked: (projectId: string, data) => {
    return AxiosHelper.put(`${baseUrl}/${projectId}/users/roles/linked`, data);
  },
};
export default ProjectLocationUsersConnection;
