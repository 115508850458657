import ContinuityReport from "../../views/globalReports/components/ContinuityReport/ContinuityReport";
import { IRouteEntity } from "../../utils/models";
import VisitReport from "../../views/globalReports/components/VisitReport/VisitReport";
import WorkTimeHistory from "views/globalReports/components/WorkTimeHistory/WorkTimeHistory";
import PhotoReport from "views/globalReports/components/PhotoReport/PhotoReport";
import GlobalVisitsReportTemplate from "../../views/globalReports/templates/GlobalVisitsReportTemplate";
import LocationWorkTimeHistory from "../../views/globalReports/components/LocationWorkTimeHistory/LocationWorkTimeHistory";
import GlobalReportsRedirectComponent from "../../views/globalReports/GlobalReportsRedirectComponent";

const globalReportsRoutes: IRouteEntity[] = [
  {
    path: "/global-reports",
    breadcrumb: "Raporty globalne",
    Component: GlobalReportsRedirectComponent,
  },
  {
    path: "/global-reports/continuity-report",
    breadcrumb: "Raport ciągłości",
    Component: ContinuityReport,
  },
  {
    path: "/global-reports/photo-report",
    breadcrumb: "Raport zdjęciowy",
    Component: PhotoReport,
  },
  {
    path: "/global-reports/visit-report",
    breadcrumb: "Raport wizyt (zbiorczy)",
    Component: VisitReport,
  },
  {
    path: "/global-reports/visit-report/templates/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu wizyt (globalny)",
    Component: GlobalVisitsReportTemplate,
  },
  {
    path: "/global-reports/visit-report/templates/add",
    breadcrumb: "Dodaj szablon raportu wizyt (globalny)",
    Component: GlobalVisitsReportTemplate,
  },
  {
    path: "/global-reports/work-time-history",
    breadcrumb: "Raport czasu pracy",
    Component: WorkTimeHistory,
  },
  {
    path: "/global-reports/location-work-time-history",
    breadcrumb: "Czas pracy w lokalizacji",
    Component: LocationWorkTimeHistory,
  },
];

export default globalReportsRoutes;
