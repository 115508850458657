import React, { memo, useState } from "react";
import { FCC, IPhotoReviewErrors } from "utils/models";
import PhotoReviewModal from "views/visitReviews/components/PhotoReviewModal";

import VisitReviewsConnection, {
  ISubmitReviewData,
} from "utils/connections/visitReviews";
import { IRoot } from "redux/models";
import { parseFeedbackDetailsForRequest } from "views/visitReviews/helper";
import { handleToastErrorCallback } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { SET_PHOTO_REVIEW } from "redux/actions";
import { usePhotoContext } from "./VisitHelpers";

interface IVisitEditPhotoReview {}
const VisitEditPhotoReview: FCC<IVisitEditPhotoReview> = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state: IRoot) => state.photoReview);
  const { setPhotoReviewModal, photoReviewState } = usePhotoContext();

  const [formErrors, setFormErrors] = useState<IPhotoReviewErrors>({
    KPI: [],
    SLA: [],
  });

  const handleSubmitReview = () => {
    if (!modalData) throw new Error("Photo review state is not defined");

    const data: ISubmitReviewData = {
      refAnswerIds: modalData.refAnswerIds,
      type: modalData.feedbackType,
      note: modalData.note,
      positions: parseFeedbackDetailsForRequest(modalData.feedbackDetails),
    };

    handleToastErrorCallback(
      VisitReviewsConnection.submitReview(data),
      (errors) => {
        if (errors) {
          setFormErrors({
            ...formErrors,
            [modalData.feedbackType]: errors,
          });
        }
      }
    ).then(() => {
      dispatch({ type: SET_PHOTO_REVIEW, payload: null });
      setPhotoReviewModal(null);
    });
  };

  return (
    <PhotoReviewModal
      onClose={() => {
        setPhotoReviewModal(null);
      }}
      modalData={photoReviewState}
      errors={formErrors} // todo: --
      submitReview={handleSubmitReview}
      noNav={true}
    />
  );
};

export default memo(VisitEditPhotoReview);
