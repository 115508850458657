import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Form } from "rsuite";
import { PHOTO_QUESTION } from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";
import { IQuestionItem } from "../../../../utils/models";
import { isArray } from "lodash";
import { getQuestionIconByType } from "../../../../global/atoms/IconHelper";
import QuestionInput from "./QuestionInput";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";

interface IQuestionForm {
  question: IQuestionItem;
  defaultValueEdit?: boolean;
  type?: "default" | "project";
  getData: (data: IQuestionItem) => void;
}

const QuestionFormInLine: FunctionComponent<IQuestionForm> = ({
  question,
  defaultValueEdit = false,
  type = "default",
  getData,
}) => {
  const [questionData, setQuestionData] = useState<IQuestionItem>(question);
  useEffect(() => {
    getData(questionData);
  }, [questionData]);

  const projectSettings = useSelector(
    (root: IRoot) => root.project?.projectSettings
  );

  return (
    <>
      <Col xs={8}>
        {getQuestionIconByType(question?.questionType)}
        <b>{question.questionName}</b>
      </Col>
      {type !== "project" && (
        <>
          {questionData.questionType !== PHOTO_QUESTION && (
            <Col xs={16}>
              <Form
                fluid
                onChange={(value: { [refQuestionId: string]: any }) => {
                  let tmp = value[questionData.refQuestionId];
                  if (isArray(tmp)) {
                    tmp = tmp.map((v) => ({
                      name: questionData.questionName,
                      value: v,
                      refQuestionAnswerId: "",
                    }));
                  } else {
                    tmp = [
                      {
                        name: questionData.questionName,
                        value: tmp,
                        refQuestionAnswerId: "",
                      },
                    ];
                  }

                  setQuestionData((q) => ({
                    ...q,
                    values: tmp,
                  }));
                }}>
                <QuestionInput
                  question={questionData}
                  isDisabled={
                    !!projectSettings?.isEnableHistoryQuestionAnswers ||
                    !defaultValueEdit
                  }
                />
              </Form>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default QuestionFormInLine;
