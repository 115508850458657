import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { handleToastRedux, isPublicView, isSuperAdmin } from "utils/helpers";
import InstancesConnection from "utils/connections/instances";
import styles from "./styles.module.scss";
import Spinner from "global/atoms/Spinner/Spinner";
import {
  IVisitPosition,
  IVisitStatus,
  FCC,
  IPhotoQuestionValue,
} from "../../../../../utils/models";
import VisitStoreDocuments from "./VisitElements/VisitStoreDocuments";
import VisitOrders from "./VisitElements/VisitOrders";
import VisitDocuments from "./VisitElements/VisitDocuments";
import objectHash from "object-hash";
import {
  Button,
  ButtonGroup,
  Dropdown,
  IconButton,
  Message,
  Whisper,
  Tooltip,
  Panel,
} from "rsuite";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import VisitMap, {
  visitMapErrorFallback,
  visitMapOnError,
} from "./VisitElements/VisitMap";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { TypeVisitStatus } from "utils/types";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import download from "downloadjs";
import { isEmpty } from "lodash";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import OptionsDropdown from "global/atoms/OptionsDropdown";
import { FormElementBase, FormElementVisitEdit } from "utils/FormElement";
import VisitDistances from "./VisitElements/VisitDistances";
import { Helmet } from "react-helmet-async";
import useVisitData from "./hooks/useVisitData";
import VisitEditPhotoReview from "./VisitElements/VisitEditPhotoReview";
import PushMessageIcon from "@rsuite/icons/PushMessage";
import InfoOutline from "@rsuite/icons/InfoOutline";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import VisitEditForm from "./VisitEditForm";
import VisitDetails from "./VisitElements/VisitDetails";
import { isQuestionDisabled } from "./VisitElements/VisitHelpers";
import { IPhotoQuestionValueExtended } from "./VisitElements/Atoms/PhotoQuestion";
import { PHOTO_QUESTION } from "./VisitElements/QuestionTypeConsts";

export const QUESTION_LOCATION = "QUESTION_LOCATION";
export const QUESTION_PRODUCT = "QUESTION_PRODUCT";
export type TypeQuestion = "QUESTION_LOCATION" | "QUESTION_PRODUCT";

export interface IVisitPositionExtended extends IVisitPosition {
  grandType: TypeQuestion;
  parentKey: string;
}

dayjs.extend(weekOfYear);
import VisitEditContextWrapper, {
  IVisitEditPhotoContext,
  IVisitEditStaticContext,
  IVisitFormState,
  IVisitState,
} from "./VisitEditContextWrapper";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import EditIcon from "@rsuite/icons/Edit";
import VisitsModalChangeDeadline from "../visitActions/VisitsModalChangeDeadLine";
import { IRoot } from "../../../../../redux/models";

// todo: out
export const extractPositionsQuestions = (
  positions: IVisitPosition[],
  type: TypeQuestion,
  parentKey: string
): IVisitPositionExtended[] => {
  const tmp: IVisitPositionExtended[] = [];

  const __extractPositionsQuestions = (
    positions: IVisitPosition[],
    _parentKey: string
  ) => {
    positions.forEach((pos) => {
      if (pos.type !== "QUESTION" && pos.positions.length > 0) {
        return __extractPositionsQuestions(pos.positions, objectHash(pos));
      } else if (pos.type === "QUESTION") {
        tmp.push({
          ...pos,
          grandType: type,
          parentKey: _parentKey,
        });
      }
      // else throw '__extractPositionsQuestions - unknown scenario' - np pytania do lokalizacji brak...
    });
  };
  __extractPositionsQuestions(positions, parentKey);
  return tmp;
};

type TypeComment = "VISIT" | "QUESTION";

export interface IVisitCommentItem {
  visitId: string;
  visitQuestionId: string;
  questionName: string;
  activityName: string;
  userName: string;
  commentType: TypeComment;
  isRead: boolean;
  comment: string;
  visitStatus: TypeVisitStatus;
  visitStatusName: string;
  images: Array<string>;
  createdAt: string;
}

const VisitEdit: FCC<{
  openAll?: boolean;
  exclusivelyClosed?: Array<string>;
  readOnly?: boolean;
  parOrganizationId?: string;
  parProjectId?: string;
}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, visitId } = useParams<{ id: string; visitId: string }>();
  const [deadLineModalOpen, setDeadLineModalOpen] = useState(false);
  const [formState, setFormState] = useState<IVisitFormState>({});
  const [staticState, setStaticState] = useState<IVisitEditStaticContext>({
    disabledElements: [],
    hiddenElements: [],
    questionsNestedState: [],
    questionsFlatState: {},
    itemsFlatState: {},
    generalRefIds: [],
    locationRefIds: [],
    comments: [],
    visitId: "",
    readOnly: props.readOnly ?? false,
  });

  const [state, setState] = useState<IVisitState>({
    panelIds: ["visit-details"],
    openPanels: ["visit-details"],
    newStatus: null,
    loading: false,
    errors: {},
    disabledQuestions: [],
  });

  const [photoState, setPhotoState] = useState<
    Omit<IVisitEditPhotoContext, "setVisitPhotos" | "setPhotoReviewModal">
  >({
    photoReviewState: null,
    visitPhotos: [],
  });
  useEffect(() => {
    if (props.openAll) {
      setState((s) => ({
        ...s,
        openPanels: state.panelIds.filter(
          (f) => !props.exclusivelyClosed?.includes(f)
        ),
      }));
    }
  }, [state.panelIds]);
  const [visitData, disabledElements, hiddenElements, visitStatuses, comments] =
    useVisitData(props.parOrganizationId, props.parProjectId);

  const projectSettings = useSelector(
    (root: IRoot) => root.project?.projectSettings
  );

  useEffect(() => {
    if (!visitData) return;

    const generalQuestions = extractPositionsQuestions(
      visitData.positions ?? [],
      QUESTION_PRODUCT,
      "0"
    );

    const locationQuestions = extractPositionsQuestions(
      visitData.locationQuestions ?? [],
      QUESTION_LOCATION,
      "0"
    );

    const questionValues = [...generalQuestions, ...locationQuestions];
    const tmpStatic: IVisitEditStaticContext = {
      readOnly: props.readOnly ?? false,
      visitId: visitData.id,
      comments: comments,
      disabledElements: disabledElements,
      hiddenElements: hiddenElements,
      locationRefIds: locationQuestions.map((q) => q.question.refQuestionId),
      generalRefIds: generalQuestions.map((q) => q.question.refQuestionId),
      questionsNestedState: [
        ...visitData.locationQuestions,
        ...visitData.positions,
      ],
      itemsFlatState: questionValues.reduce((acc, q) => {
        if (!isEmpty(q.positions)) {
          q.positions.forEach((p) => {
            if (p.itemId) acc[p.itemId] = p;
          });
        }
        return acc;
      }, {}),
      questionsFlatState: questionValues.reduce((acc, q) => {
        acc[q.question.refQuestionId] = q.question;
        return acc;
      }, {}),
    };

    setStaticState((s) => ({ ...s, ...tmpStatic }));

    setPhotoState((s) => ({
      ...s,
      visitPhotos: Object.values(tmpStatic.questionsFlatState)
        .filter((q) => q.questionType === PHOTO_QUESTION)
        .reduce((acc: IPhotoQuestionValueExtended[], val) => {
          return [
            ...acc,
            ...val.values.map((v) => ({
              ...(v as IPhotoQuestionValue),
              refQuestionId: val.refQuestionId,
              fileKey: v.refQuestionAnswerId,
            })),
          ] as IPhotoQuestionValueExtended[];
        }, []),
    }));
  }, [visitData, hiddenElements, disabledElements]);

  const setDisabledQuestions = (fd: IVisitFormState) => {
    const _disabledQuestions = [] as Array<string>;

    Object.values(staticState.questionsFlatState).forEach((question) => {
      //check paren question is disabled
      if (
        _disabledQuestions.includes(question.dependOnQuestion.refQuestionId)
      ) {
        _disabledQuestions.push(question.refQuestionId);
        return;
      }
      const isDisabled = isQuestionDisabled(
        question,
        staticState.questionsFlatState,
        fd
      );

      //only if questions dependency is sorted dependently
      if (isDisabled) {
        _disabledQuestions.push(question.refQuestionId);
      }
    });

    setState((s) => ({ ...s, disabledQuestions: _disabledQuestions }));
  };

  const isSaveForbidden = (): boolean => {
    return (
      (disabledElements.includes(FormElementBase.saveButton) ||
        disabledElements.includes(FormElementVisitEdit.saveChangesButton)) &&
      hiddenElements.includes(FormElementVisitEdit.statusSelector)
    );
  };

  const isVPartialSaveEnabled = (): boolean => {
    return (
      !isEmpty(state.errors) &&
      !hiddenElements.includes(FormElementVisitEdit.savePartialButton)
    );
  };

  const redirectToFeedback = (): void => {
    history.push(
      `/projects/${id}/visits/${visitId}/actions?feedbackStatus=GENERAL_FEEDBACK_TO-REVIEW`
    );
  };
  const isReadonly = (): boolean => {
    return (
      disabledElements.includes(FormElementVisitEdit.saveChangesButton) ||
      (props.readOnly ?? false)
    );
  };

  const downloadPdf = () => {
    handleToastRedux(
      InstancesConnection.getVisitPdfBlob(visitData?.id ?? ""),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
    });
  };

  const openPublic = () => {
    window.open(InstancesConnection.getVisitPublicUrl(visitData?.id ?? ""));
  };

  const SubmitButtons = () => {
    if (!visitData) return <></>;

    const StatusRenderMenu = () => {
      const onStatusSelect = (eventKey) => {
        const selectedStatus = eventKey as TypeVisitStatus | undefined;
        const _status = visitStatuses?.find((vs) => vs.id === selectedStatus);
        if (!_status) throw "Unknown status!";
        setState((s) => ({
          ...s,
          newStatus: _status,
        }));
      };
      const renderDropdownHeader = (props, ref) => {
        return (
          <>
            <ButtonGroup>
              <Button appearance="primary" style={{ background: status.color }}>
                <div style={{ background: status.color }}>
                  {status.name.toLocaleUpperCase()}
                </div>
              </Button>
              <IconButton
                {...props}
                ref={ref}
                style={{
                  background: status.color + "aa",
                  lineHeight: "17px",
                }}
                icon={<ArrowDownIcon color="white" />}
              />
            </ButtonGroup>
          </>
        );
      };
      return (
        <Dropdown
          placement={window.innerWidth > 726 ? "bottomStart" : "topStart"}
          renderToggle={renderDropdownHeader}
          onSelect={onStatusSelect}>
          {visitStatuses &&
            visitStatuses.map((status) => (
              <Dropdown.Item
                eventKey={status.id}
                key={`dpi-${status.id}`}
                value={status.status}>
                {status.name}
              </Dropdown.Item>
            ))}
        </Dropdown>
      );
    };

    let status: IVisitStatus = state.newStatus
      ? state.newStatus
      : visitData.status;
    if (visitData.changeStatus) status = visitData.changeStatus;

    return (
      <>
        <div className={`${styles.flattingVisitButtonsHeader}`}>
          {(!hiddenElements.includes(FormElementVisitEdit.statusSelector) ||
            isSuperAdmin()) &&
            !isPublicView() && <StatusRenderMenu />}

          <IconButton
            icon={
              isVPartialSaveEnabled() ? (
                <InfoOutline />
              ) : isEmpty(state.errors) ? (
                <CheckIcon />
              ) : (
                <CloseIcon />
              )
            }
            title={
              isVPartialSaveEnabled()
                ? "Zapis częściowy z pominięciem brakujących odpowiedzi"
                : ""
            }
            type="submit"
            appearance={isVPartialSaveEnabled() ? "ghost" : "primary"}
            form="visit-edit-form"
            loading={state.loading}
            disabled={state.loading}>
            Zapisz
          </IconButton>
        </div>
      </>
    );
  };

  useEffect(() => {
    setDisabledQuestions(formState);
  }, [formState]);

  if (!visitData) return <Spinner />;

  const VisitRate = () => {
    if (!projectSettings?.isEnableVisitRate) return <></>;
    let total = 0;
    let visitRate = 0;
    const rate = visitData.positions.map((activity) => {
      const sum = activity.positions.reduce(
        (acc, obj) => acc + (obj.rate ?? 0),
        0
      );
      const sumValuesRate = activity.positions.reduce(
        (acc, obj) => acc + (obj.rate ?? 0),
        0
      );
      total += sum;
      visitRate += sumValuesRate;
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}>
            <strong style={{ width: "150px", textAlign: "left" }}>
              {activity.name}
            </strong>
            {!!sum && (
              <span>
                {!!sumValuesRate && `${(sumValuesRate / sum) * 100}%`}{" "}
                {sumValuesRate} / {sum}{" "}
              </span>
            )}
          </div>
        </>
      );
    });

    return (
      <Panel
        bordered
        shaded
        expanded
        collapsible={true}
        header={"Podsumowanie wizyty"}>
        <>
          <div
            style={{
              fontSize: "1.2em",
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}>
            <strong style={{ width: "150px" }}>Wynik ogólny</strong>
            {!!total && (
              <span>
                {!!visitRate && `${(visitRate / total) * 100}%`} {visitRate} /{" "}
                {total}{" "}
              </span>
            )}
          </div>
          {rate}
        </>
      </Panel>
    );
  };

  return (
    <>
      <HeaderButtons>
        {!isPublicView() && (
          <OptionsDropdown>
            <Dropdown.Item onClick={downloadPdf} icon={<FileDownloadIcon />}>
              .PDF
            </Dropdown.Item>
            <Dropdown.Item onClick={openPublic}>Widok publiczny</Dropdown.Item>
            <Dropdown.Item
              disabled={disabledElements.includes(
                FormElementVisitEdit.feedbackButton
              )}
              onClick={redirectToFeedback}>
              Dodaj komentarz do wizyty
            </Dropdown.Item>
            {!hiddenElements.includes(
              FormElementVisitEdit.changeDeadlineButton
            ) && (
              <>
                <Dropdown.Item
                  icon={<EditIcon />}
                  disabled={disabledElements.includes(
                    FormElementVisitEdit.changeDeadlineButton
                  )}
                  onClick={() => {
                    setDeadLineModalOpen(true);
                  }}>
                  Zmień termin realizacji wizyty
                </Dropdown.Item>
              </>
            )}
          </OptionsDropdown>
        )}

        <Whisper
          trigger={"hover"}
          placement="auto"
          speaker={
            <Tooltip>
              {(Object.keys(staticState?.questionsFlatState) ?? []).length > 500
                ? "Zbyt dużo elementów by rozwinąć wszystkie sekcje!"
                : state.openPanels.length !== state.panelIds.length
                ? "Rozwiń wszystkie sekcje"
                : "Zwiń wszystkie sekcje"}
            </Tooltip>
          }>
          <IconButton
            appearance={"ghost"}
            style={{ border: "1px solid rgb(221,221,221)" }}
            disabled={
              (Object.keys(staticState?.questionsFlatState) ?? []).length > 500
            }
            icon={
              <PushMessageIcon
                rotate={
                  state.openPanels.length !== state.panelIds.length ? 0 : 180
                }
              />
            }
            onClick={() => {
              if (state.openPanels.length !== state.panelIds.length) {
                setState((s) => ({
                  ...s,
                  openPanels: s.panelIds,
                }));
              } else setState((s) => ({ ...s, openPanels: [] }));
            }}
          />
        </Whisper>
        {!isPublicView() && !isSaveForbidden() && <SubmitButtons />}
      </HeaderButtons>

      <div style={{ paddingBottom: "15px" }}>
        <VisitEditContextWrapper
          state={state}
          setState={setState}
          staticState={staticState}
          formState={formState}
          setPhotoState={setPhotoState}
          photoState={photoState}>
          {isSaveForbidden() && (
            <>
              <Message showIcon type={"info"}>
                Nie masz uprawnień wymaganych do modyfikacji tej wizyty.
              </Message>
              <SeparatorEmpty size={1} />
            </>
          )}

          <VisitDetails visitData={visitData} />
          <SeparatorEmpty />
          {/* podsumowanie wizyty */}
          <VisitRate />

          <SeparatorEmpty />
          {/* podsumowanie wizyty */}
          {/* PYTANIA */}

          <VisitEditForm setFormState={setFormState} readOnly={isReadonly()} />

          {/* DOKUMENTY - FAKTURY */}
          <VisitDocuments documents={visitData.documents} />

          {/* ZAMÓWIENIA */}
          <VisitOrders orders={visitData.orders} />

          {/* DOKUMENTY MAGAZYNOWE */}
          <VisitStoreDocuments storeDocuments={visitData.storeDocuments} />

          {/* MAPA WIZYTY */}
          <ErrorBoundary
            FallbackComponent={visitMapErrorFallback}
            onError={visitMapOnError}>
            <VisitMap location={visitData.location} gps={visitData.gps} />
          </ErrorBoundary>

          <VisitDistances data={visitData.gps} />

          <VisitEditPhotoReview />
        </VisitEditContextWrapper>
      </div>
      <VisitsModalChangeDeadline
        visitId={visitId}
        isOpen={deadLineModalOpen}
        setIsOpen={setDeadLineModalOpen}
      />
      <Helmet>
        <style>{`
          .rs-form-control-label {
            padding:0  !important;
          }
          .rs-form-group {
            margin-bottom: 0 !important;
          }
          .rs-panel-group {
            box-shadow: var(--rs-panel-shadow);
          }

          .rs-uploader-picture-text .rs-uploader-file-item {
            padding-left: 100px;
          }
          .rs-uploader-picture-text .rs-uploader-file-item-preview {
            width: 100px;
          }
          .rs-uploader-picture-text .rs-uploader-file-item,
          .rs-uploader-picture-text .rs-uploader-file-item-preview {
            height: 100%;
          }

          .rs-uploader-file-item.rs-uploader-file-item-picture-text {
            height: 100px;
          }

          .rs-uploader-file-item-preview {
            display:flex;
            justify-content: center;
            align-items: center;
          }
          .rs-uploader-file-item-preview img {
            max-width: 100%;
            max-height: 100%;
          }

          .rs-comment-panel-body {
            padding-left: 10px;
            padding-right: 10px;
          } 

          .rs-question-panel-body {
            padding-left: 10px;
            padding-right: 10px;
          }

          .rs-comment-panel-header {
            cursor: pointer;
            user-select: none;
            display: flex;
            column-gap: 10px;
            color: #ffaf38;
          }
        `}</style>
      </Helmet>
    </>
  );
};

export default VisitEdit;
