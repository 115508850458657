import { AxiosManager, HttpMethod, default as axios } from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { IDocumentsFilters } from "../../views/projects/components/storeDocuments/StoreDocumentsList";

const StoreDocumentsConnection = {
  list: (data: IDocumentsFilters) => {
    const projectId = getProjectIdFromUrl();
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/store_documents/list`,
      data
    );
  },
  filterForm: () => {
    const projectId = getProjectIdFromUrl();
    return AxiosManager.send(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/store_documents/filter/form`
    );
  },
  editForm: (documentId: string, projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/store-documents/${documentId}/form`
    );
  },
  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/store-documents`,
      {
        headers: { ...getAuthHeader() },
        data: { documents: data },
      }
    );
  },
  getPdf: (storeDocumentId: string) => {
    return axios.getBlob(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/store-documents/${storeDocumentId}/pdf`
    );
    // return AxiosManager.send(
    //   HttpMethod.getBlob,
    //   `${getBaseApiUrl()}/organizations/${getOrganizationId()}/store-documents/${storeDocumentId}/pdf`
    // );
  },
};

export default StoreDocumentsConnection;
