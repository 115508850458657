import React, { FunctionComponent, useState } from "react";
import {
  IBodyResponse,
  ILogCMDRequestData,
  ILogForm,
} from "../../../../utils/models";
import { Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters from "../../../../global/filters/UniversalFilters";
import LogsConnection from "../../../../utils/connections/logs";
import { jsonButton } from "./LogsList";
import { handleSortColumn } from "../../../../utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ILogsCmdList {}

interface ILogRow {
  date: string;
  gid: string;
  id: string;
  memoryUsage: number;
  metaData: string;
  name: string;
  pid: string;
  scriptTime: number;
  status: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogsCmdList: FunctionComponent<ILogsCmdList> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<IBodyResponse<ILogRow>>({
    count: 0,
    data: [],
  });
  const [form, setForm] = useState<ILogForm | null>(null);

  const defaultRequestData: ILogCMDRequestData = {
    name: "",
    dateFrom: 0,
    dateTo: 0,
    requestPaginate: {
      page: 1,
      limit: 30,
    },
    requestOrder: {
      field: "",
      order: "asc",
    },
  };
  const [requestData, setRequestData] =
    useState<ILogCMDRequestData>(defaultRequestData);

  const getNameParsed = (processId: string): string => {
    return (
      form?.names?.options.find((n) => n.id === processId)?.name ?? processId
    );
  };

  return (
    <>
      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={logData.data}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey={"date"} />
            </Table.Column>

            <Table.Column flexGrow={2} sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData: ILogRow) => getNameParsed(rowData.name)}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Czas [s]</Table.HeaderCell>
              <Table.Cell dataKey={"scriptTime"} />
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Pamięć [Mb]</Table.HeaderCell>
              <Table.Cell dataKey={"memoryUsage"} />
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey={"status"} />
            </Table.Column>

            <Table.Column flexGrow={1} align={"right"}>
              <Table.HeaderCell>Meta dane</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) => jsonButton(rowData.metaData)}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={logData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={LogsConnection.listCmd}
        formGet={LogsConnection.listCmdForm}
        getFormCallback={(_form) => setForm(_form)}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setLogData}
        filterStorageKey={"logsCmdList"}
        elements={[
          {
            type: "select",
            label: "Proces",
            stateKey: "name",
            formKey: "names",
          },
          {
            type: "input_text",
            label: "Meta data",
            stateKey: "metaData",
          },
          {
            type: "input_date",
            label: "Data od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
          {
            type: "input_date",
            label: "Data do",
            stateKey: "dateTo",
            outputFormat: "phpTimestamp",
          },
        ]}
      />
    </>
  );
};

export default LogsCmdList;
