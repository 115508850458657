import React, { FunctionComponent } from "react";
import { Col, DatePicker, Form, Input, SelectPicker } from "rsuite";
import {
  IFormActivityState,
  IFormTaskActivityEdit,
  SetState,
} from "../../../../utils/models";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

interface IActivityItemForm {
  state: IFormActivityState;
  setState: SetState<IFormActivityState>;
  form: IFormTaskActivityEdit;
  errors: any;
  isEdit?: boolean;
}

const ActivityHeaderForm: FunctionComponent<IActivityItemForm> = ({
  state,
  setState,
  errors,
  form,
  isEdit,
}) => {
  const asTextarea = () => {
    return <Input as="textarea" style={{ width: "100%" }} rows={1} />;
  };
  return (
    <>
      <Form>
        <Col xs={12}>
          <Form.Group>
            <Form.ControlLabel>Nazwa</Form.ControlLabel>
            <Form.Control
              style={{ width: "100%" }}
              name={"name"}
              value={state.name}
              onChange={(value) => {
                setState((s) => ({ ...s, name: value || "" }));
              }}
              errorMessage={errors?.name}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Col xs={12} style={{ paddingLeft: "0px" }}>
            <Form.Group>
              <Form.ControlLabel>Aktywne od</Form.ControlLabel>
              <Form.Control
                isoWeek
                oneTap
                placeholder={"nie wyznaczono"}
                format={"yyyy-MM-dd"}
                shouldDisableDate={beforeToday()}
                style={{ width: "100%" }}
                name={"scheduleDate"}
                accepter={DatePicker}
                value={
                  state.scheduleDate
                    ? dayjs(state.scheduleDate).toDate()
                    : undefined
                }
                onChange={(value) => {
                  setState((s) => ({
                    ...s,
                    scheduleDate: value
                      ? dayjs(value).format("YYYY-MM-DD")
                      : null,
                  }));
                }}
                errorMessage={errors?.scheduleDate}
              />
            </Form.Group>
          </Col>
          <Col xs={12} style={{ paddingRight: "0px" }}>
            <Form.Group>
              <Form.ControlLabel>Aktywne do</Form.ControlLabel>
              <Form.Control
                isoWeek
                oneTap
                placeholder={"nie wyznaczono"}
                format={"yyyy-MM-dd"}
                shouldDisableDate={beforeToday()}
                style={{ width: "100%" }}
                name={"endDate"}
                accepter={DatePicker}
                value={
                  state.endDate ? dayjs(state.endDate).toDate() : undefined
                }
                onChange={(value) => {
                  setState((s) => ({
                    ...s,
                    endDate: value ? dayjs(value).format("YYYY-MM-DD") : null,
                  }));
                }}
                errorMessage={errors?.endDate}
              />
            </Form.Group>
          </Col>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Typ aktywności</Form.ControlLabel>
            <Form.Control
              placeholder={"Typ aktywności"}
              name={"activityTypeId"}
              value={state.activityTypeId}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              cleanable={false}
              searchable={false}
              disabled={isEdit}
              data={form.activityTypes.options}
              valueKey={"id"}
              labelKey={"name"}
              onChange={(value) => {
                setState((s) => ({ ...s, activityTypeId: value || "" }));
              }}
              errorMessage={errors?.activityTypeId}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Grupa</Form.ControlLabel>
            <Form.Control
              placeholder={"Wybierz"}
              name={"activityGroupId"}
              value={state.activityGroupId}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              cleanable={false}
              searchable={false}
              data={form.activityGroups.options}
              valueKey={"id"}
              labelKey={"name"}
              onChange={(value) => {
                setState((s) => ({ ...s, activityGroupId: value || "" }));
              }}
              errorMessage={errors?.activityGroupId}
            />
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group>
            <Form.ControlLabel>Opis</Form.ControlLabel>
            <Form.Control
              style={{ width: "100%" }}
              name={"description"}
              accepter={asTextarea}
              value={state.description}
              onChange={(value) => {
                setState((s) => ({ ...s, description: value || "" }));
              }}
              errorMessage={errors?.description}
            />
          </Form.Group>
        </Col>
      </Form>
      <Helmet>
        <style>
          {`
          label.rs-form-control-label{
            color: #575757;
            font-size: 14px;
          }
        `}
        </style>
      </Helmet>
    </>
  );
};

export default ActivityHeaderForm;
