import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IDay } from "../ScheduleViewBoard";
import UniversalModal from "global/atoms/UniversalModal";
import {
  FormGroupMultiSelect,
  FormGroupSelect,
  FormGroupSubmit,
  FormRequired,
} from "utils/formHelper";
import SchedulesConnection from "utils/connections/schedules";
import _ from "lodash";
import ActionsContainer from "global/atoms/ActionsContainer";
import { handleToast } from "utils/helpers";
import { Loader } from "rsuite";

interface IFormModal {
  date: dayjs.Dayjs;
  order: number;
  dataModified: (data: IDay[]) => void;
  onClose: (reload?: boolean) => void;
  userId: string;
  isEdit?: boolean;
}

interface IScheduleCreateModalData_location {
  id: string;
  name: string;
}
export interface IScheduleCreateModalData {
  frequencies: Array<{ id: string; name: string }>;
  taskLocationVariants: Array<{
    location: IScheduleCreateModalData_location;
    task: { id: string; name: string };
  }>;
}

export interface IScheduleRouteAddData {
  userId: string;
  dateFrom: number;
  elements: Array<{
    locationId: string;
    taskId: string;
    frequency: number;
  }>;
}

const FormModal: FunctionComponent<IFormModal> = (props) => {
  interface IFormState {
    taskIds: string[];
    locationId: string;
    userId: string;
    frequency: number;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formState, setFormState] = useState<IFormState>({
    taskIds: [],
    locationId: "",
    userId: "", // todo:
    frequency: 0,
  });

  const [modalData, setModalData] = useState<
    IScheduleCreateModalData | undefined
  >();

  useEffect(() => {
    SchedulesConnection.scheduleModalData(props.userId).then((res) => {
      setModalData(res.data);
    });
  }, []);

  const getLocationOptions = () => {
    const locs: IScheduleCreateModalData_location[] =
      modalData.taskLocationVariants.map((tlv) => tlv.location);
    const uniqueSet = new Set<string>();
    const uniqueLocationVariants: IScheduleCreateModalData_location[] = [];

    for (const loc of locs) {
      if (!uniqueSet.has(loc.id)) {
        uniqueSet.add(loc.id);
        uniqueLocationVariants.push(loc);
      }
    }

    return uniqueLocationVariants;
  };

  const getTaskOptions = () => {
    return modalData.taskLocationVariants
      .filter((tlv) => [formState.locationId].includes(tlv.location.id))
      .map((tlv) => tlv.task);
  };

  const handleSubmit = (status: boolean) => {
    if (!status) return false;
    const elements: IScheduleRouteAddData["elements"] = [];

    // formState.locationIds.forEach(locId => {
    modalData.taskLocationVariants
      .filter((tlv) => tlv.location.id === formState.locationId)
      .forEach((tlv) => {
        if (formState.taskIds.includes(tlv.task.id)) {
          elements.push({
            locationId: formState.locationId,
            taskId: tlv.task.id,
            frequency: formState.frequency,
          });
        }
      });
    // })

    handleToast(
      SchedulesConnection.scheduleRouteAdd({
        userId: props.userId,
        dateFrom: props.date.unix(),
        elements: elements,
      })
    ).then(() => {
      props.onClose(true);
    });
  };

  const getModalBody = () => {
    if (props.isEdit) {
      return <></>;
    } else {
      return (
        <>
          {!modalData && <Loader backdrop content="Ładowanie..." vertical />}
          <FormRequired
            requiredFields={{ select: [] }} // todo:  ["frequency", "taskIds", "locationIds"]
            onSubmit={handleSubmit}
            onChange={(_state: IFormState) => {
              console.log("on change", _state);
              setFormState((fs) => ({ ...fs, ..._state }));
            }}
            state={formState}>
            <FormGroupSelect
              label={"Lokalizacja"}
              fieldName={"locationId"}
              labelKey={"name"}
              valueKey={"id"}
              options={modalData ? getLocationOptions() : []}
            />
            {/* <FormGroupMultiSelect
              label={"Lokalizacje"}
              fieldName={"locationIds"}
              labelKey={"name"}
              valueKey={"id"}
              options={modalData ? getLocationOptions() : []}
            /> */}
            <FormGroupMultiSelect
              label={"Zadania"}
              disabled={_.isEmpty(formState.locationId)}
              fieldName={"taskIds"}
              labelKey={"name"}
              valueKey={"id"}
              options={modalData ? getTaskOptions() : []}
            />
            <FormGroupSelect
              label={"Cykliczność"}
              fieldName={"frequency"}
              labelKey={"name"}
              valueKey={"id"}
              disabled={_.isEmpty(formState.taskIds)}
              options={modalData?.frequencies ?? []}
            />

            <ActionsContainer>
              <FormGroupSubmit
                disabled={formState.frequency === 0}
                label={"Dodaj"}
              />
            </ActionsContainer>
          </FormRequired>
        </>
      );
    }
  };

  return (
    <UniversalModal
      title={
        <>
          {props.isEdit && "Edycja wpisu"}
          {!props.isEdit && (
            <>
              {`Dodaj nowy wpis`}
              <br />
              <small>{`${props.date.format("dddd, DD MMMM")}`}</small>
            </>
          )}
        </>
      }
      body={getModalBody()}
      open={true}
      displayFooterButtons={false}
      onClose={props.onClose}
    />
  );
};

export default FormModal;
