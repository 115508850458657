export enum FormElementBase {
  addNewButton = "addNewButton",
  deleteSelectedButton = "deleteSelectedButton",
  saveButton = "saveButton",
  deleteButton = "deleteButton",
  editButton = "editButton",
}

export enum FormElementVisitEdit { // VisitEdit.tsx
  statusSelector = "STATUS_SELECTOR",
  saveChangesButton = "SAVE_CHANGES_BUTTON",
  visitComment = "VISIT_COMMENT",
  questionComment = "QUESTION_COMMENT",
  photoQuestionShortReview = "SAVE_PARTIAL_BUTTON_SHORT_PHOTO_REVIEW",
  feedbackButton = "FEEDBACK_BUTTON",
  changeDeadlineButton = "CHANGE_DEADLINE_BUTTON",
  savePartialButton = "SAVE_PARTIAL_BUTTON",
}
