import React from "react";
import { Dropdown } from "rsuite";
import { handleToast } from "./helpers";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmModalCallback } from "../redux/store";
import { generatePath, useHistory } from "react-router-dom";
import PlusIcon from "@rsuite/icons/Plus";
import { AxiosPromise } from "axios";
import { FCC } from "./models";

interface IDropDownItemDeleteSelected {
  deleteSelected?: {
    chosenToDelete: Array<string>;
    promiseFunctionDelete: (data: Array<{ id: string }>) => AxiosPromise<any>;
    callback: () => void;
  };
  addPath?: string;
}

const NavOptionsWrapper: FCC<IDropDownItemDeleteSelected> = (props) => {
  const history = useHistory();
  let handleDeleteSelected;

  if (props.deleteSelected) {
    handleDeleteSelected = () => {
      confirmModalCallback(
        `Czy usunąć zaznaczone elementy? (${
          props.deleteSelected?.chosenToDelete.length ?? 0
        })`,
        () => {
          // @ts-ignore
          const data = props.deleteSelected.chosenToDelete.map((id) => ({
            id: id,
          }));
          // @ts-ignore
          handleToast(props.deleteSelected.promiseFunctionDelete(data)).then(
            props.deleteSelected?.callback
          );
        }
      );
    };
  }

  //@ts-ignore - dropdown as button
  return (
    <Dropdown appearance={"ghost"} title="Opcje" placement={"bottomEnd"}>
      {props.addPath && (
        // @ts-ignore
        <Dropdown.Item
          onClick={() => history.push(generatePath(props?.addPath ?? ""))}
          icon={<PlusIcon />}>
          Dodaj nowy
        </Dropdown.Item>
      )}
      {props.deleteSelected && handleDeleteSelected && (
        <Dropdown.Item
          disabled={props.deleteSelected.chosenToDelete.length === 0}
          onClick={handleDeleteSelected}
          icon={<TrashIcon />}>
          Usuń zaznaczone ({props.deleteSelected.chosenToDelete.length})
        </Dropdown.Item>
      )}
      {props.children && props.children}
    </Dropdown>
  );
};

export default NavOptionsWrapper;
