export const SET_APP = "SET_APP";
export const SET_MENU = "SET_MENU";
export const SET_LOADING = "SET_LOADING";
export const SET_HEADER = "SET_HEADER";
export const SET_FILTER = "SET_FILTER";
export const SET_TOAST = "SET_TOAST";
export const CANCEL_TOAST = "CANCEL_TOAST";
export const SET_PROJECT = "SET_PROJECT";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_PHOTO_REVIEW = "SET_PHOTO_REVIEW";
export const SET_DISABLED_ELEMENTS = "SET_DISABLED_ELEMENTS";

export const TOGGLE_CONFIRM_MODAL = "TOGGLE_CONFIRM_MODAL";
export const TOGGLE_LOADING_SCREEN = "TOGGLE_LOADING_SCREEN";

export const SET_ACTIVITY_EDIT = "SET_ACTIVITY_EDIT";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
