import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  IContractSalaryFilterFormModel,
  IContractSalaryModel,
} from "../../models/contract/contractSalaryModels";

export const path = `/contracts/projects`;
const controller = "salaries";
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractServiceTypeConnection = {
  getContractProjectSalariesList: (
    contractProjectId: string,
    data: IContractSalaryFilterFormModel
  ) => {
    return AxiosHelper.post(
      `${baseUrl}/${contractProjectId}/${controller}/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createContractProjectSalary: (
    contractProjectId: string,
    data: IContractSalaryModel
  ) => {
    return AxiosHelper.post(
      `${baseUrl}/${contractProjectId}/${controller}`,
      { salaries: [data] },
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateContractProjectSalary: (
    contractProjectId: string,
    data: IContractSalaryModel
  ) => {
    return AxiosHelper.put(
      `${baseUrl}/${contractProjectId}/${controller}`,
      { salaries: [data] },
      {
        headers: getAuthHeader(),
      }
    );
  },

  getFormAddContractSalary: (contractProjectId: string) => {
    return AxiosHelper.get(
      `${baseUrl}/${contractProjectId}/${controller}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditContractSalary: (contractProjectId: string, id: string) => {
    return AxiosHelper.get(
      `${baseUrl}/${contractProjectId}/${controller}/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },

  delete: (contractProjectId: string, data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(
      `${baseUrl}/${contractProjectId}/${controller}`,
      { ids: data }
    );
  },

  getContractProjectSalaryForm: (contractProjectId: string): AxiosPromise => {
    return AxiosHelper.get(
      `${baseUrl}/${contractProjectId}/${controller}/form`
    );
  },

  getContractProjectSalariesFilterForm: (
    contractProjectId: string
  ): AxiosPromise => {
    return AxiosHelper.get(
      `${baseUrl}/${contractProjectId}/${controller}/filter/form`
    );
  },
};

export default ContractServiceTypeConnection;
