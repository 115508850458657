import React, { FunctionComponent, memo, useEffect } from "react";
import styles from "../styles.module.scss";
import Panel from "rsuite/esm/Panel";
import { isEmpty } from "lodash";
import { PanelGroup } from "rsuite";
import Spinner from "global/atoms/Spinner/Spinner";
import { PanelNameErrorWrapper } from "../VisitEditForm";
import {
  hasErrors,
  useErrorContext,
  usePanelContext,
  useStaticContext,
} from "./VisitHelpers";
import VisitQuestionItemsPanelGroup from "./VisitQuestionItemsPanelGroup";
import SeparatorEmpty from "../../../../../../global/atoms/separators/SeparatorEmpty";

interface IVisitQuestionItemsComponent {}

const VisitQuestionItems: FunctionComponent<
  IVisitQuestionItemsComponent
> = () => {
  const { questionsNestedState } = useStaticContext();
  const { registerPanelId, panelToggle, openPanels } = usePanelContext();
  const { errors } = useErrorContext();

  useEffect(() => {
    if (!questionsNestedState.length) return;

    questionsNestedState
      .filter((item) => item.positions.length > 0)
      .forEach((item, index) => {
        registerPanelId(`main-panel-${index}`);
      });
  }, [questionsNestedState]);

  if (isEmpty(questionsNestedState)) return <Spinner />;

  const panelGroupOnSelect = (index: number) => {
    panelToggle(`main-panel-${index}`);
  };

  return (
    <>
      <SeparatorEmpty />
      <PanelGroup defaultActiveKey={`main-panel-0`}>
        {questionsNestedState
          .filter((item) => item.positions.length > 0)
          .map((item, index) => (
            <Panel
              header={
                <div className={styles.sectionHeadWrapper}>
                  <PanelNameErrorWrapper
                    hasErrors={hasErrors(item.positions, errors)}>
                    <h5>
                      <span className={styles.sectionHeadWrapperName}>
                        {item.name}
                      </span>
                      &nbsp;
                      <small>{item.subName}</small>
                    </h5>
                  </PanelNameErrorWrapper>
                </div>
              }
              collapsible
              eventKey={`main-panel-${index}`}
              expanded={openPanels.includes(`main-panel-${index}`)}
              onSelect={panelGroupOnSelect.bind(null, index)}
              id={`visit-question-items-${item.itemId}-${index}`}
              key={`visit-question-items-${item.itemId}-${index}`}>
              {openPanels.includes(`main-panel-${index}`) ? (
                <VisitQuestionItemsPanelGroup visitPosition={item} />
              ) : (
                <Spinner />
              )}
            </Panel>
          ))}
      </PanelGroup>
    </>
  );
};

export default memo(VisitQuestionItems);
