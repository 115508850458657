import React, { FunctionComponent, useEffect, useState } from "react";
import GlobalReportsConnection from "../../../../utils/connections/globalReportsConnection";
import { continuityReportRequestDateDefault } from "../../../../utils/states";
import UniversalFilters, {
  FILTER_SELECT,
  FILTER_SEPARATOR,
} from "../../../../global/filters/UniversalFilters";
import {
  IContinuityReportForm,
  IContinuityReportRequestData,
  IContinuityReportRow,
} from "../../../../utils/models";
import SelectSwitch from "../../../../global/atoms/select/SelectSwitch";
import Pagination from "../../../../global/pagination/Pagination";
import TableMonth from "../../../../global/table/TableMonth";
import ScColumn from "../../../../global/table/ScColumn";
import { Table } from "rsuite";
import commonStyles from "../../../../global/common.module.scss";
import styles from "./styles.module.scss";
import TimeTableElement from "./TimeTableElement";
import { Helmet } from "react-helmet-async";
import { Dropdown, Tooltip, Whisper } from "rsuite";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { useDispatch } from "react-redux";
import download from "downloadjs";
import { handleToastRedux } from "../../../../utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IData {
  count: number;
  data: Array<IContinuityReportRow>;
}

const ContinuityReport: FunctionComponent = () => {
  const roleShortMap = {
    // todo: poprawic
    administrator: "AM",
    superadministrator: "SA",
    "project manager": "PM",
    supervisor: "SV",
    wykonawca: "WK",
    centrala: "CT",
    klient: "KL",
  };

  const [data, setData] = useState<IData>({ count: 0, data: [] });
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState<IContinuityReportRequestData>(
    continuityReportRequestDateDefault
  );
  const [form, setForm] = useState<IContinuityReportForm>();
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [init, setInit] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  useEffect(() => {
    if (init) setTriggerUpdate(Date.now());
  }, [requestData.dateTimestamp]);

  const parseForm = (_form: IContinuityReportForm) => {
    setForm(_form);

    if (_form.dates.options.length) {
      const lastDateAvail = _form.dates.options[_form.dates.options.length - 1];
      // current month is not available in result data - switch to last available
      if (parseInt(lastDateAvail.id) !== requestData.dateTimestamp) {
        setRequestData({
          ...requestData,
          dateTimestamp: parseInt(lastDateAvail.id),
        });
        setTriggerUpdate(Date.now());
      }
    } else {
      setRequestData({
        ...requestData,
        dateTimestamp: 0,
      });
      setTriggerUpdate(Date.now());
    }

    setInit(true);
  };

  const mapData = (rowData: IContinuityReportRow) => {
    const dp = rowData.dayPositions.map((v) => {
      return {
        ...v,
        value: <TimeTableElement dayPosition={v} />,
      };
    });

    return {
      ...rowData,
      dayPositions: dp,
    };
  };

  const exportToFile = () => {
    handleToastRedux(
      GlobalReportsConnection.getFileBlob(requestData),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"], res.data.type);
    });
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item icon={<FileDownloadIcon />} onClick={exportToFile}>
            Eksportuj do pliku
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        beforeTableElements={
          <>
            {" "}
            {form && form?.dates.options.length > 0 && (
              <div className={styles.monthSelectorContainer}>
                <div>
                  <SelectSwitch
                    options={form?.dates.options ?? []}
                    stateKey={"dateTimestamp"}
                    value={requestData.dateTimestamp}
                    state={requestData}
                    setState={setRequestData}
                  />
                </div>
              </div>
            )}
          </>
        }
        table={
          <TableMonth
            monthTimeStamp={requestData.dateTimestamp * 1000}
            data={data?.data.map(mapData)}
            loading={loading}>
            <ScColumn align="left" width={100}>
              <Table.HeaderCell>Projekt</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.customerName}</Table.Cell>
            </ScColumn>

            <ScColumn align="left" flexGrow={1}>
              <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <Whisper
                    placement="top"
                    trigger="hover"
                    enterable
                    speaker={<Tooltip>{rowData.locationName}</Tooltip>}>
                    <span>{rowData.locationName}</span>
                  </Whisper>
                )}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" width={160}>
              <Table.HeaderCell>Użytkownicy</Table.HeaderCell>
              <Table.Cell>
                {(rowData) =>
                  rowData.users.map((user) => (
                    <div key={`u-${user.user.id}`}>
                      <small className={commonStyles.smallMuted}>
                        {
                          // @ts-ignore
                          roleShortMap[user.role.roleName] ?? ""
                        }
                        :&nbsp;
                      </small>
                      <small>{user.user.fullName}</small>
                    </div>
                  ))
                }
              </Table.Cell>
            </ScColumn>

            <ScColumn align="center" width={30}>
              <Table.HeaderCell>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Przepracowane z poprawną geolokalizacją (godziny/dni)
                    </Tooltip>
                  }>
                  <div className={styles.cellTooltipTarget}>G</div>
                </Whisper>
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <span className={styles.geoModifier}>
                    <div
                      className={styles.hSplit}
                      style={{ background: "#457B9D" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            Procent godzin przepracowanych (geo):{" "}
                            {`${
                              rowData.hoursWorkedGeoConfirmedPercentage ?? 0
                            }%`}
                          </Tooltip>
                        }>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.hoursWorkedGeoConfirmed ?? 0}
                        </div>
                      </Whisper>
                    </div>
                    <div
                      className={styles.dSplit}
                      style={{ background: "#5898c2" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            Procent dni przepracowanych (geo):{" "}
                            {`${
                              rowData.daysWorkedGeoConfirmedPercentage ?? 0
                            }%`}
                          </Tooltip>
                        }>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.daysWorkedGeoConfirmed ?? 0}
                        </div>
                      </Whisper>
                    </div>
                  </span>
                )}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="center" width={30}>
              <Table.HeaderCell>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Przepracowane (godziny/dni)</Tooltip>}>
                  <div className={styles.cellTooltipTarget}>P</div>
                </Whisper>
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <>
                    <div
                      className={styles.hSplit}
                      style={{ background: "#EEBA0B" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            Procent godzin przepracowanych:{" "}
                            {`${rowData.hoursWorkedPercentage ?? 0}%`}
                          </Tooltip>
                        }>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.hoursWorked ?? 0}
                        </div>
                      </Whisper>
                    </div>
                    <div
                      className={styles.dSplit}
                      style={{ background: "#f3ce59" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            Procent dni przepracowanych:{" "}
                            {`${rowData.daysWorkedPercentage ?? 0}%`}
                          </Tooltip>
                        }>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.daysWorked ?? 0}
                        </div>
                      </Whisper>
                    </div>
                  </>
                )}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="center" width={30}>
              <Table.HeaderCell>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Zaplanowane (godziny/dni)</Tooltip>}>
                  <div className={styles.cellTooltipTarget}>Z</div>
                </Whisper>
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <>
                    <div
                      className={styles.hSplit}
                      style={{ background: "#777777" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>Zaplanowanych godzin</Tooltip>}>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.hoursScheduled ?? 0}
                        </div>
                      </Whisper>
                    </div>
                    <div
                      className={styles.dSplit}
                      style={{ background: "#9f9f9f" }}>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>Zaplanowanych dni</Tooltip>}>
                        <div className={styles.cellTooltipTarget}>
                          {rowData.daysScheduled ?? 0}
                        </div>
                      </Whisper>
                    </div>
                  </>
                )}
              </Table.Cell>
            </ScColumn>
          </TableMonth>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={GlobalReportsConnection.get}
        formGet={GlobalReportsConnection.form}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={continuityReportRequestDateDefault}
        setResultData={setData}
        triggerLoad={triggerUpdate}
        getFormCallback={parseForm}
        filterStorageKey={"ContinuityReport_2"}
        ignoredStateKeys={["dateTimestamp"]}
        elements={[
          {
            label: "Klient",
            type: FILTER_SELECT,
            formKey: "customer",
            stateKey: "customerId",
          },
          {
            label: "Lokalizacja",
            type: FILTER_SELECT,
            formKey: "locations",
            stateKey: "locationId",
          },
          {
            type: FILTER_SEPARATOR,
            label: "",
            stateKey: "",
          },
          {
            type: FILTER_SELECT,
            stateKey: "roleId",
            formKey: "projectMerchRoles",
            label: "Grupa",
          },
          {
            type: FILTER_SELECT,
            stateKey: "userRoleId",
            formKey: "users",
            label: "Grupa -> Użytkownik",
            filter: "roleId",
          },
        ]}
      />

      <Helmet>
        <style>{`
        .rs-table-cell .rs-table-cell-content {
          font-size: .65em !important;
          line-height: 1.25em;
        }

        .rs-table-cell-header:nth-child(n+4) .rs-table-cell-content,
        .rs-table-cell:nth-child(n+4) .rs-table-cell-content {
          padding: 0px !important;
          height: 100% !important;
        }

        .rs-table-cell-header:nth-child(n+6) .rs-table-cell-content {
          display: flex;
          width: 100% !important;
          height: 100% !important;
          align-items: center;
          justify-content: center;
        }

        .rs-table-cell:nth-child(n+6) {
          border-left: 1px solid #ddd;
        }

        `}</style>
      </Helmet>
    </>
  );
};

export default ContinuityReport;
