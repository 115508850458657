export const MONTHS: { [index: number]: string } = {
  0: "Styczeń",
  1: "Luty",
  2: "Marzec",
  3: "Kwiecień",
  4: "Maj",
  5: "Czerwiec",
  6: "Lipiec",
  7: "Sierpień",
  8: "Wrzesień",
  9: "Październik",
  10: "Listopad",
  11: "Grudzień",
};

export const WEEK_DAYS_OBJ = [
  { name: "Poniedziałek", key: 1 },
  { name: "Wtorek", key: 2 },
  { name: "Środa", key: 3 },
  { name: "Czwartek", key: 4 },
  { name: "Piątek", key: 5 },
  { name: "Sobota", key: 6 },
  { name: "Niedziela", key: 0 },
];

export const WEEK_DAYS: { [index: number]: string } = {
  0: "Niedziela",
  1: "Poniedziałek",
  2: "Wtorek",
  3: "Środa",
  4: "Czwartek",
  5: "Piątek",
  6: "Sobota",
};

export const WEEK_DAYS_ISO: { [index: number]: string } = {
  1: "Poniedziałek",
  2: "Wtorek",
  3: "Środa",
  4: "Czwartek",
  5: "Piątek",
  6: "Sobota",
  7: "Niedziela",
};
