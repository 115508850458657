import React, { FunctionComponent, useState } from "react";
import CheckBox from "../../../global/atoms/checkbox/CheckBox";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import Modal from "rsuite/Modal";
import Button from "rsuite/Button";
import { MONTHS, WEEK_DAYS } from "../../../utils/dictionaries";
import { IModalData } from "../Schedule";
import { SetState } from "../../../utils/models";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

interface IScheduleModalRemove {
  modalData: IModalData | null;
  setModalData: SetState<IModalData | null>;
  onScheduleRemove: (
    timeTableId: string,
    date: Date,
    removeType: 1 | 2
  ) => void;
}

const ScheduleRemoveModal: FunctionComponent<IScheduleModalRemove> = (
  props
) => {
  const [removeOption, setRemoveOption] = useState<1 | 2>(2);

  const mdate = dayjs(props.modalData?.date ?? new Date());
  const dayNumber = mdate.date().toString();
  const monthName = MONTHS[mdate.month()];
  const dayName = WEEK_DAYS[mdate.isoWeekday()];

  const handleRemoveSchedule = () => {
    if (props.modalData?.date) {
      setRemoveOption(1);
      props.onScheduleRemove(
        props.modalData?.timeTableId ?? "",
        props.modalData?.date,
        removeOption
      );
    }
  };

  const onClose = () => {
    props.setModalData(null);
  };

  return (
    <>
      <Modal open={props.modalData?.type === "remove"} onClose={onClose}>
        {props.modalData?.date && (
          <>
            <div>
              <CheckBox
                isChecked={removeOption === 1}
                onClick={() => setRemoveOption(1)}>
                Usuń generowanie wizyty dnia {dayNumber} {monthName}, {dayName}?
              </CheckBox>
              <SeparatorEmpty />
              <CheckBox
                isChecked={removeOption === 2}
                onClick={() => setRemoveOption(2)}>
                Usuń generowanie wizyty dnia {dayNumber} {monthName}, {dayName},
                oraz późniejsze dni wynikające z ustawień reguły?
              </CheckBox>
            </div>
            <br />
          </>
        )}
        <Modal.Footer>
          <Button appearance={"ghost"} onClick={onClose}>
            Anuluj
          </Button>
          <Button appearance={"primary"} onClick={handleRemoveSchedule}>
            Potwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleRemoveModal;
