import { Form, Input } from "rsuite";
import React from "react";

export const InputText = () => {
  return <Input as={"textarea"} />;
};
export const inputMessage = (name, errors) => {
  return (
    <Form.ErrorMessage show={errors?.[name]} placement={"bottomEnd"}>
      {errors?.[name]}
    </Form.ErrorMessage>
  );
};
