import axios from "axios";
import { useState } from "react";
import SchedulesConnection from "utils/connections/schedules";
import { IVisitStatus } from "utils/models";
import { IScheduleRequestData } from "./useRequestData";

export interface IScheduleDynamicForm {
  maxDateTo: Date;
  users: Array<{ id: string; name: string; projects: string[] }>;
  customers: Array<{ id: string; name: string }>;
  locations: Array<{
    id: string;
    name: string;
    locationAddressString: string;
    projects: string[];
    networks: string[];
  }>;
  tasks: Array<{ id: string; name: string; projects: string[] }>;
  networks: Array<{ id: string; name: string }>;
  projects: Array<{ id: string; name: string; projects: string[] }>;
  statuses: Array<IVisitStatus>;
}

function useForm(): [
  IScheduleDynamicForm | null,
  (requestData: IScheduleRequestData, cb?: (() => void) | undefined) => void,
  boolean | null,
] {
  const [form, setForm] = useState<IScheduleDynamicForm | null>(null);
  const [dynamicFormLoading, setDynamicFormLoading] = useState<null | boolean>(
    true
  );

  const loadDynamicForm = (
    requestData: IScheduleRequestData,
    cb?: (() => void) | undefined
  ) => {
    setDynamicFormLoading(true);

    SchedulesConnection.dynamicForm(requestData)
      .then((r) => {
        setForm(r.data);
        setDynamicFormLoading(false);
        if (cb) cb();
      })
      .catch((e) => {
        if (!axios.isCancel(e)) setDynamicFormLoading(null);
      });
  };

  return [form, loadDynamicForm, dynamicFormLoading];
}

export default useForm;
