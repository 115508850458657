import BadgeStatus from "global/atoms/badge/BadgeStatus";
import React, { FunctionComponent } from "react";

interface IVisitFreequencyLegend {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitFreequencyLegend: FunctionComponent<IVisitFreequencyLegend> = (
  props
) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
      <div
        style={{ alignSelf: "flex-end", display: "flex", columnGap: "10px" }}>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#f5f2ad"}>
          Raz na tydzień
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#daec9f"}>
          Raz na 2 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#cae8f5"}>
          Raz na 3 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#f9ddc6"}>
          Raz na 4 tygodnie
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#e6e6e6"}>
          Wizyta jednorazowa
        </BadgeStatus>
        <BadgeStatus
          color={"#aaa"}
          borderColor={"#ddd"}
          backgroundColor={"#fff"}>
          Wizyta spoza harmonogramu
        </BadgeStatus>
      </div>
    </div>
  );
};

export default VisitFreequencyLegend;
