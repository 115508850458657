import { getBaseApiUrl, getOrganizationId } from "../helpers";
import AxiosHelper from "../AxiosHelper";
import { ISubmitOperation } from "../../views/projects/components/editProject/LocationsOperations";

const ProjectLocationUsersConnection = {
  getLocationReplaceFormData: (projectId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/locations/users/replace/form`
    );
  },
  operation: (projectId: string, data: ISubmitOperation) => {
    return AxiosHelper.patch(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/locations/users/replace`,
      data
    );
  },
};
export default ProjectLocationUsersConnection;
