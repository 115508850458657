import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl } from "../helpers";

const HintsConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getHints: (data: any) => {
    return axios.post(`${getBaseApiUrl()}/translates/hints/list`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createHint: (data: any) => {
    return axios.post(`${getBaseApiUrl()}/translates/hints`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateHint: (id: string, data: any) => {
    return axios.put(`${getBaseApiUrl()}/translates/hints/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersHintsList: () => {
    return axios.get(`${getBaseApiUrl()}/translates/hints/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddHint: () => {
    return axios.get(`${getBaseApiUrl()}/translates/hints/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditHint: (id: string) => {
    return axios.get(`${getBaseApiUrl()}/translates/hints/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
};

export default HintsConnection;
