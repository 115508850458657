import React, { FunctionComponent, useState } from "react";
import LocationForm from "./LocationForm";
import { handleToast } from "../../../../utils/helpers";
import LocationsConnection from "../../../../utils/connections/locations";
import { useHistory, useParams } from "react-router-dom";
import { ICreateLocation } from "../../../../utils/models";

interface ILocationEdit {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LocationEdit: FunctionComponent<ILocationEdit> = (props) => {
  const [errors, setErrors] = useState({});
  const { locationId } = useParams<{ locationId: string }>();
  const history = useHistory();

  const handleSubmit = (data: ICreateLocation) => {
    handleToast(
      LocationsConnection.editLocation(data, locationId),
      setErrors
    ).then(() => history.push("/datasets/locations"));
  };

  return (
    <>
      <LocationForm
        errors={errors}
        isEdit={true}
        dataPromise={LocationsConnection.getFormEditLocation}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default LocationEdit;
