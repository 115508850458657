import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HintsConnection from "utils/connections/hints";
import { handleSetErrors, handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import Input from "global/atoms/Input";
import Textarea from "global/atoms/Textarea";
import Form from "global/form/Form";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import ActionsContainer from "global/atoms/ActionsContainer";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";

interface IHintsEdit {}

const HintsEdit: FunctionComponent<IHintsEdit> = () => {
  const [data, setData] = useState<any | "loading">("loading");
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    HintsConnection.getFormEditHint(id)
      .then((data) => {
        setData({
          locale: data.data.model.locale,
          field: data.data.model.field,
          text: data.data.model.text,
        });
        data.data?.header &&
          dispatch({ type: "SET_HEADER", payload: data.data.header });
      })
      .catch((err) => handleSetErrors(err, setErrors));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(HintsConnection.updateHint(id, data), setErrors).then(() =>
      history.push("/superadmin/hints")
    );
  };

  if (data === "loading") return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Język (kod)"}
            name={"locale"}
            value={data.locale}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={"text"}
            placeholder={"Pole"}
            name={"field"}
            value={data.field}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={"Treść"}
            name={"text"}
            value={data.text}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default HintsEdit;
