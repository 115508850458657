import React, { FunctionComponent, useEffect, useState } from "react";
import ProjectFilesConnection from "../../../../utils/connections/projectFiles";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Input from "../../../../global/atoms/Input";
import Form from "../../../../global/form/Form";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Textarea from "../../../../global/atoms/Textarea";
import { handleToast } from "../../../../utils/helpers";
import { Col, Message, Row } from "rsuite";
import Select from "../../../../global/atoms/Select";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";

export interface IUpdateFileData {
  name: string;
  description: string;
  fileName: string;
  categoryId: string;
  categoryName: string;
  users: string[];
}

const FileEdit: FunctionComponent = () => {
  const { id, fileId } = useParams<{ id: string; fileId: string }>();
  const [form, setForm] = useState<any>(null);
  const [data, setData] = useState<IUpdateFileData | null>(null);
  const history = useHistory();

  useEffect(() => {
    ProjectFilesConnection.editFormViewData(fileId, id).then((response) => {
      setData(response.data.model);
      setForm(response.data);
    });
  }, []);

  if (data === null || form === null) return <Spinner />;

  const handleSubmit = () => {
    handleToast(ProjectFilesConnection.updateFile(fileId, id, data)).then(
      () => {
        history.push(`/projects/${id}/files`);
      }
    );
  };
  const funcUserMap = (user: {
    userName: string;
    userId: string;
  }): { name: string; id: string } => {
    return {
      ...user,
      name: user.userName,
      id: user.userId,
    };
  };
  const getUserAssigned = () => {
    if (!form?.users?.data) return [];
    return form.users.data
      .filter((u) => (data.users ?? []).indexOf(u.userId) >= 0)
      .map(funcUserMap);
  };

  const getUserUnAssigned = () => {
    if (!form?.users?.data) return [];
    return form.users.data
      .filter((u) => (data.users ?? []).indexOf(u.userId) < 0)
      .map(funcUserMap);
  };
  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={24}>
            <Form>
              <Input
                placeholder={"Nazwa pliku"}
                setState={setData}
                state={data}
                name={"fileName"}
                value={data.fileName}
                disabled={true}
              />
              <Input
                placeholder={"Nazwa własna"}
                setState={setData}
                state={data}
                name={"name"}
                value={data.name}
              />
              <Textarea
                placeholder={"Opis"}
                setState={setData}
                state={data}
                name={"description"}
                value={data.description}
              />
              <Select
                placeholder={"Kategoria"}
                options={form.categories.options}
                name={"categoryId"}
                value={data.categoryId}
                state={data}
                setState={setData}
              />
            </Form>
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        {!(data.users ?? []).length && (
          <Message type="info" showIcon style={{ marginBottom: "10px" }}>
            Nie wybrano konkretnych adresatów. Plik zostanie udostępniony
            wszystkim uczestnikom projekt
          </Message>
        )}
        <DoubleSidePicker
          loading={!form?.users?.data}
          heading={"Udostępnij plik"}
          emptyMessageAssigned={
            "Plik dostępny jest dla wszystkich użytkowników"
          }
          emptyMessageNotAssigned={"Brak użytkowników w systemie"}
          assigned={getUserAssigned()}
          unassigned={getUserUnAssigned()}
          form={form}
          onChange={(assignedIds) => setData({ ...data, users: assignedIds })}
          itemType={"userGlobal"}
        />
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          <ButtonSubmit onClick={handleSubmit} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default FileEdit;
