import React, { FunctionComponent, useState } from "react";
import WhiteCard from "global/atoms/WhiteCard";
import Pagination from "global/pagination/Pagination";
import ScTable from "global/table/ScTable";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory, useParams } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "global/filters/UniversalFilters";
import NavOptionsWrapper from "utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "utils/toggleToDeleteHelper";
import connection from "utils/connections/contract/contractServiceConnection";
import { IContractServiceTypeFilterFormModel } from "../../../utils/models/contract/contractServiceTypeModels";
import { getContractServiceTypeState } from "../../../utils/states/contract/contractServiceTypeStates";
import ButtonListRedirect from "../../../global/atoms/ButtonListRedirect";
import { contractDocumentPath } from "../../../routes/contract/contract";

interface IContractServiceTypeList {}

const ContractServiceList: FunctionComponent<IContractServiceTypeList> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IContractServiceTypeFilterFormModel>(
    getContractServiceTypeState
  );
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  const path = `${contractDocumentPath}/${id}/services`;

  const handleEdit = (event: any) => {
    history.push(`${path}/${event.currentTarget.dataset.id}/edit`);
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: connection.delete.bind(null, id),
            callback: () => {
              setTriggerLoad(Date.now());
            },
          }}
          addPath={`${path}/add`}
        />
      </HeaderButtons>
      <WhiteCard padding={false}>
        {data === null ? (
          <></>
        ) : (
          <ScTable data={data?.data ?? []} loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj usługi
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`${path}/${rowData.id}/edit`}>
                    {rowData.contractServiceTypeName}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Projekt
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => rowData.projectName}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Lokalizacja
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => rowData.locationName}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell>Daty: od - do:</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => `${rowData.startDate} - ${rowData.endDate}`}
              </Table.Cell>
            </Table.Column>

            <Table.Column verticalAlign={"middle"} align="right">
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContractServiceList.bind(null, id)}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getContractServiceTypeState}
        setResultData={setData}
        formGet={connection.getFilterForm.bind(null, id)}
        filterStorageKey={"ContractServiceTypeList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default ContractServiceList;
