import React, { FunctionComponent, useState } from "react";
import Pagination from "global/pagination/Pagination";
import CountriesConnection from "utils/connections/countries";
import { useHistory } from "react-router-dom";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import { ButtonSort } from "../../../../utils/sortHelper";
import { _paginate30 } from "utils/states";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ICountriesList {}

const CountriesList: FunctionComponent<ICountriesList> = () => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<any>({
    name: "",
    code: "",
    ..._paginate30,
  });
  const history = useHistory();

  const handleEdit = (event: any) => {
    history.push(
      `/superadmin/countries/${event.currentTarget.dataset.id}/edit`
    );
  };

  return (
    <>
      <PaginatedTableViewWrapper
        table={
          <Table fillHeight loading={loading} data={data?.data ?? []}>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"name"}
                  filters={filters}
                  setFilters={setFilters}>
                  Nazwa
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="name" />
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={CountriesConnection.getCountries}
        formGet={CountriesConnection.getFiltersCountriesList}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={{}}
        setResultData={setData}
        filterStorageKey={"superadminCountriesList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Kod",
            stateKey: "code",
          },
        ]}
      />
    </>
  );
};

export default CountriesList;
