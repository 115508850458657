import React, { CSSProperties, FunctionComponent } from "react";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  DatePicker,
  IconButton,
} from "rsuite";
import { addDays, subDays } from "date-fns";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

interface IScheduleDatePicker {
  dateFrom: Date;
  dateTo: Date;
  maxDateTo?: Date;
  onChange: (dateFrom: Date, dateTo: Date) => void;
}

export enum ViewMode {
  day = "day",
  week = "week",
  month = "month",
}

const getCurrentRangeLength = (dateFrom: Date, dateTo: Date): number => {
  const _dateFrom = dayjs(dayjs(dateFrom).format("YYYY-MM-DD"));
  const _dateTo = dayjs(dayjs(dateTo).format("YYYY-MM-DD"));
  const numberOfDays = _dateTo.diff(_dateFrom, "day");
  return numberOfDays + 1;
};

export const getViewType = (dateFrom: Date, dateTo: Date): ViewMode => {
  const currentRange = getCurrentRangeLength(dateFrom, dateTo);
  return currentRange === 1
    ? ViewMode.day
    : currentRange <= 7
    ? ViewMode.week
    : ViewMode.month;
};

export const iconStyle: CSSProperties = { color: "#FFAF38", fontSize: "1.5em" };
const ScheduleDatePicker: FunctionComponent<IScheduleDatePicker> = (props) => {
  const viewMode = getViewType(props.dateFrom, props.dateTo);

  const viewModeChange = (vm: ViewMode) => {
    if (vm === ViewMode.day) {
      props.onChange(
        dayjs(props.dateFrom).startOf("isoWeek").toDate(),
        dayjs(props.dateFrom).startOf("isoWeek").toDate()
      );
    } else if (vm === ViewMode.week) {
      props.onChange(
        dayjs(props.dateFrom).startOf("isoWeek").toDate(),
        dayjs(props.dateFrom).endOf("isoWeek").toDate()
      );
    } else if (vm === ViewMode.month) {
      props.onChange(
        dayjs(props.dateFrom).startOf("month").toDate(),
        dayjs(props.dateFrom).endOf("month").toDate()
      );
    }
  };

  const hasPrev = (): boolean => {
    return true;
  };

  const hasNext = (): boolean => {
    if (!props.dateFrom) return false;
    if (!props.maxDateTo) return true;
    return !dayjs(props.maxDateTo).isBefore(props.dateTo);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
      }}>
      <IconButton
        appearance={"subtle"}
        disabled={!hasPrev()}
        icon={<ArrowLeftLineIcon style={iconStyle} />}
        onClick={() => {
          const rangelen = getCurrentRangeLength(props.dateFrom, props.dateTo);
          props.onChange(
            subDays(props.dateFrom, rangelen),
            subDays(props.dateTo, rangelen)
          );
        }}
      />

      <DatePicker
        showWeekNumbers
        isoWeek
        block
        cleanable={false}
        ranges={[]}
        shouldDisableDate={(date) =>
          props.maxDateTo && dayjs(props.maxDateTo).isBefore(date)
        }
        format={viewMode === ViewMode.month ? "yyyy-MM" : undefined}
        oneTap={true}
        value={props.dateFrom}
        onChange={(value) => {
          if (viewMode === ViewMode.week) {
            props.onChange(
              dayjs(value).startOf("isoWeek").toDate(),
              dayjs(value).endOf("isoWeek").toDate()
            );
          } else if (viewMode === ViewMode.day) {
            props.onChange(value, value);
          } else if (viewMode === ViewMode.month) {
            props.onChange(
              dayjs(value).startOf("month").toDate(),
              dayjs(value).endOf("month").toDate()
            );
          }
        }}
      />

      <IconButton
        appearance={"subtle"}
        disabled={!hasNext()}
        icon={<ArrowRightLineIcon style={iconStyle} />}
        onClick={() => {
          const rangelen = getCurrentRangeLength(props.dateFrom, props.dateTo);
          props.onChange(
            addDays(props.dateFrom, rangelen),
            addDays(props.dateTo, rangelen)
          );
        }}
      />

      <div style={{ marginLeft: "20px" }}>
        <div style={{ color: "#999", marginRight: "10px" }}>Widok:</div>
        <div style={{ display: "inline-block" }}>
          <ButtonToolbar>
            <ButtonGroup size={"xs"}>
              {/*<Button onClick={() => viewModeChange(ViewMode.month)}*/}
              {/*        appearance={viewMode === ViewMode.month ? 'primary' : 'default'}>Miesiąc</Button>*/}
              <Button
                onClick={() => viewModeChange(ViewMode.week)}
                appearance={viewMode === ViewMode.week ? "primary" : "default"}>
                Tydzień
              </Button>
              <Button
                onClick={() => viewModeChange(ViewMode.day)}
                appearance={viewMode === ViewMode.day ? "primary" : "default"}>
                Dzień
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDatePicker;
