import { _paginate30 } from "utils/states";
import {
  IContractServiceFilterFormModel,
  IContractServiceModel,
} from "../../models/contract/contractServiceModels";

export const getContractFilterServiceState: IContractServiceFilterFormModel = {
  project: "",
  ..._paginate30,
};

export const createContractServiceState: IContractServiceModel = {
  projectId: "",
  locationId: "",
  rateSettlement: "",
  rateType: "",
  salaryType: "",
  contractServiceTypeId: "",
  expirationDates: {
    startDate: "",
    endDate: "",
  },
  salary: {
    type: "",
    name: "",
    description: "",
    days: [],
    baseRate: {
      type: "BASE",
      settlement: "HOUR",
      amount: 0,
      extraAmount: 0,
      isGross: false,
    },
    bonusRate: {
      type: "BONUS",
      settlement: "HOUR",
      amount: 0,
      extraAmount: 0,
      isGross: false,
    },
    extraRate: {
      type: "EXTRA",
      settlement: "HOUR",
      amount: 0,
      extraAmount: 0,
      isGross: false,
    },
  },
};
