import React from "react";
import { Checkbox as Chk } from "rsuite";
import { FCC } from "utils/models";

interface ICheckbox {
  label?: string;
  isChecked: boolean;
  isMixed?: boolean;
  id?: any;
  onClick?: (event: any) => void;
  onChange?: (value: any, checked: any) => void;
  className?: any;
  style?: any;
  data?: any;
  disabled?: boolean;
  plaintext?: boolean;
  inline?: boolean;
}

const Checkbox: FCC<ICheckbox> = (props) => {
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <Chk
        data-data={props.data}
        data-id={props.id}
        inline={props.inline ?? false}
        indeterminate={props.isMixed}
        className={props.className}
        onClick={props.onClick}
        onChange={props.onChange}
        checked={props.isChecked}
        disabled={props.disabled ?? false}
        plaintext={props.plaintext ?? false}>
        {props.children}
      </Chk>
    </>
  );
};

export default Checkbox;
