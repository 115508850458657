import React, { CSSProperties, FunctionComponent, useState } from "react";
import styles from "../../views/globalReports/components/ContinuityReport/styles.module.scss";

interface ITableTooltipWrapper {
  children: React.PropsWithChildren<any>;
  hint: string;
  style?: CSSProperties;
  styleWrapper?: CSSProperties;
  classWrapper?: any;
}
const TableTooltipWrapper: FunctionComponent<ITableTooltipWrapper> = (
  props
) => {
  const [hintHidden, setHintHidden] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setHintHidden(true)}
        onMouseLeave={() => setHintHidden(false)}
        className={`${props.classWrapper ?? ""}`}
        style={{ ...(props.styleWrapper ?? {}), display: "flex" }}>
        {props.children}
        <div
          style={{
            ...(props.style ?? {}),
            display: hintHidden ? "flex" : "none",
          }}
          className={styles.tooltipHint}>
          {props.hint}
        </div>
      </div>
    </>
  );
};

export default TableTooltipWrapper;
