import React, { FunctionComponent, useState } from "react";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { Dropdown, Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;
import PlusIcon from "@rsuite/icons/Plus";
import ProjectConnectionsConnection, {
  ProjectConnectionItem,
  ProjectConnectionsRequest,
} from "../../../../utils/connections/projectConnectionsConnection";
import { _paginate30 } from "../../../../utils/states";
import CreateProjectConnectionModal, {
  IProjectConnectionCreate,
} from "./createProjectConnectionModal";
import UniversalFilters, {
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { handleSortColumn, handleToast } from "../../../../utils/helpers";
import Pagination from "../../../../global/pagination/Pagination";
import { IBodyResponse } from "../../../../utils/models";
import dayjs from "dayjs";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IProjectConnections {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectConnections: FunctionComponent<IProjectConnections> = () => {
  const [addModal, setAddModal] = useState(false);
  const [listData, setListData] =
    useState<null | IBodyResponse<ProjectConnectionItem>>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [load, setLoad] = useState<boolean>(false);
  const [triggerLoad, setTriggerLoad] = useState(0);

  const defaultRequestData = {
    ..._paginate30,
    projectExternalId: "",
    projectId: "",
  };
  const [requestData, setRequestData] =
    useState<ProjectConnectionsRequest>(defaultRequestData);

  const handleCreate = (data: IProjectConnectionCreate) => {
    setAddModal(false);
    handleToast(ProjectConnectionsConnection.create(data)).then(() => {
      setTriggerLoad(Date.now());
    });
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item onClick={() => setAddModal(true)} icon={<PlusIcon />}>
            Dodaj powiązanie
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            data={listData?.data ?? []}
            loading={listData === null}
            sortColumn={requestData.requestOrder.field}
            // @ts-ignore
            sortType={requestData.requestOrder.order ?? undefined}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Column flexGrow={1} sortable>
              <HeaderCell>Lokalny projekt</HeaderCell>
              <Cell dataKey={"project.name"} />
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>Zewnętrzny projekt</HeaderCell>
              <Cell dataKey="projectExternal">
                {(rowData: ProjectConnectionItem) => {
                  return `${rowData.projectExternal.externalName} (${rowData.projectExternal.externalId})`;
                }}
              </Cell>
            </Column>

            <Column align={"right"} width={200} sortable>
              <HeaderCell>Data</HeaderCell>
              <Cell>
                {(rowData: ProjectConnectionItem) => {
                  return dayjs(parseInt(rowData.updatedAt) * 1000).format(
                    "YYYY-MM-DD HH:mm"
                  );
                }}
              </Cell>
            </Column>
          </Table>
        }
        pagination={
          <Pagination
            count={listData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={ProjectConnectionsConnection.list}
        formGet={ProjectConnectionsConnection.form}
        setLoading={setLoad}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setListData}
        triggerLoad={triggerLoad}
        filterStorageKey={"projectConnection_1"}
        elements={[
          {
            label: "Projekt lokalny",
            type: FILTER_SELECT,
            formKey: ["projects", "id"],
            stateKey: "projectId",
          },
          {
            label: "Projekt zewnętrzny",
            type: FILTER_SELECT,
            formKey: ["external_projects", "externalId"],
            stateKey: "projectExternalId",
            nameKey: ["externalName", "externalId"],
          },
        ]}
      />

      <CreateProjectConnectionModal
        open={addModal}
        setOpen={setAddModal}
        onSubmit={handleCreate}
      />
    </>
  );
};

export default ProjectConnections;
