import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const SaleResultTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Podsumowanie sprzedaży",
        name: "saleResult",
        active: false,
        options: [
          {
            name: "manager",
            label: "Kierownik regionu",
            active: false,
          },
          {
            name: "userName",
            label: "Nazwisko i imię PH",
            active: false,
          },
          {
            name: "sale",
            label: "Sprzedaż",
            active: false,
          },
          {
            name: "countPSD",
            label: "Ilość PSD",
            active: false,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={8} />
    </>
  );
};

export default SaleResultTemplate;
