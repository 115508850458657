import React, { FunctionComponent } from "react";
import { IDndItem, detailedScaleVal } from "./BoardTable";
import { useDrag } from "react-dnd";
import TileContextMenu, { EnumScope } from "./TileContextMenu";
import Whisper from "rsuite/esm/Whisper";
import Tooltip from "rsuite/esm/Tooltip";
import { IDay } from "../ScheduleViewBoard";

interface ITile {
  scale: number;
  dndItem: IDndItem;
  data: IDay[];
  dataModified: (data: IDay[]) => void;
  triggerReload: () => void;
  userId: string;
}

const Tile: FunctionComponent<ITile> = (props) => {
  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: "visit",
    item: props.dndItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isDetailedView = props.scale >= detailedScaleVal;

  if (isDragging) {
    // empty div for drag preview
    return <div ref={dragPreview}></div>;
  }

  let frequencyClass = "ui-worktime-once";
  switch (props.dndItem.frequency) {
    case 1:
      frequencyClass = "ui-worktime-oneweek";
      break;
    case 2:
      frequencyClass = "ui-worktime-once";
      break;
    case 3:
      frequencyClass = "ui-worktime-once";
      break;
    case 4:
      frequencyClass = "ui-worktime-once";
      break;
  }

  const className = `tile
    ${props.dndItem.isMovable ? "isMovable" : "isNotMovable"}
    ${props.dndItem.isEditable ? "isEditable" : "isNotEditable"}
  }`;

  switch (isDetailedView) {
    case true:
      return (
        <div
          className={className}
          ref={props.dndItem.isMovable ? drag : undefined}>
          <TileContextMenu
            scope={EnumScope.VISIT}
            data={props.data}
            dataModified={props.dataModified}
            date={props.dndItem.date}
            item={props.dndItem}
            userId={props.userId}
            triggerReload={props.triggerReload}>
            <div className={`inner-tile ${frequencyClass}`}>
              <div className={"details-view"}>
                {props.dndItem.location.code !== "" && (
                  <span className={"code"}>{props.dndItem.location.code}</span>
                )}
                {props.dndItem.location.name}
                <div>
                  <small className={"address"}>
                    {props.dndItem.location.address}
                  </small>
                </div>
              </div>
            </div>
          </TileContextMenu>
        </div>
      );
    case false:
      return (
        <div
          className={className}
          ref={props.dndItem.isMovable ? drag : undefined}>
          <Whisper
            speaker={
              <Tooltip>
                {props.dndItem.location.name} - {props.dndItem.location.address}
              </Tooltip>
            }>
            <div className={`inner-tile ${frequencyClass}`} />
          </Whisper>
        </div>
      );
  }
};

export default Tile;
