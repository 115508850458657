import React, { FunctionComponent, useEffect, useState } from "react";
import NotificationForm, { INotificationForm } from "./NotificationForm";
import NotificationsConnection from "../../../../utils/connections/notifications";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { handleToast } from "../../../../utils/helpers";
import { IEditNotification, INotification } from "../../../../utils/models";
import { useDispatch } from "react-redux";

const EditNotification: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [notificationState, setNotificationState] = useState<INotification>();
  const [notificationForm, setNotificationForm] = useState<INotificationForm>();
  const [errors, setErrors] = useState<Array<string> | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const tmp = [
      NotificationsConnection.getNotification(id),
      NotificationsConnection.getForm(),
    ];
    Promise.all(tmp).then((res) => {
      res[0].data.header &&
        dispatch({ type: "SET_HEADER", payload: res[0].data.header });
      setNotificationState(res[0].data);
      const form = res[1].data;
      // form.roles.options = form.roles.options.map((o:any) => ({id: o.roleId, name: o.roleName}));
      setNotificationForm(form);
    });
  }, []);

  const handleSubmit = (
    notification: INotification,
    assignedUsers: Array<string>
  ) => {
    setErrors(null);

    const resultData: IEditNotification = {
      ...notification,
      users: [],
    };

    if (assignedUsers.length) {
      resultData.users = assignedUsers;
    }

    handleToast(
      NotificationsConnection.editNotification(resultData),
      setErrors
    ).then(() => history.push("/notifications"));
  };

  if (!notificationForm || !notificationState) return <Spinner />;
  return (
    <NotificationForm
      handleSubmit={handleSubmit}
      form={notificationForm}
      setForm={setNotificationForm}
      errors={errors}
      assignedUserIds={notificationState.users ?? undefined}
      notification={notificationState}
      readOnly={notificationState.status !== "PREPARE"}
    />
  );
};

export default EditNotification;
