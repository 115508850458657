import { IGetPaginationBase } from "utils/models";
import AxiosHelper from "utils/AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "utils/helpers";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/global_reports/location_work_time_history`;

export interface ILocationWorkTimeHistoryListRequestData
  extends IGetPaginationBase {
  projectId: string;
  locationId: string;
  dateFrom: string;
  dateTo: string;

  userId: string;
}
export interface ILocationWorkTimeHistoryFileRequestData
  extends ILocationWorkTimeHistoryListRequestData {}

const WorkTimeHistoryConnection = {
  form: () => AxiosHelper.get(`${baseUrl}/form`),
  list: (requestData: ILocationWorkTimeHistoryListRequestData) =>
    AxiosHelper.post(`${baseUrl}/list`, requestData),
  getFile: (requestData: ILocationWorkTimeHistoryFileRequestData) =>
    AxiosHelper.getBlobPost(`${baseUrl}/get-file`, requestData),
};

export default WorkTimeHistoryConnection;
