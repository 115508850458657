import React, { CSSProperties, FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_APP } from "redux/actions";
import UserConnection from "utils/connections/user";
import logo from "./assets/images/logo.png";
import { Button, ButtonToolbar, Form, toaster } from "rsuite";
import Input, { InputTypes } from "global/atoms/Input";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";

interface ILogin {
  backUrl?: string;
}

const Login: FunctionComponent<ILogin> = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<any | null>(null);

  const styleContainer: CSSProperties = {
    width: "100%",
    height: "100vh",
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
  };

  const styleForm: CSSProperties = {
    width: "100%",
    maxWidth: "512px",
    padding: "64px 32px",
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    borderRadius: "16px",
    position: "relative",
  };

  const styleErrors: CSSProperties = {
    display: "block",
    color: "red",
    fontSize: "0.9rem",
    marginTop: "15px",
  };

  const styleLogo: CSSProperties = {
    margin: "0 auto",
    display: "block",
    marginBottom: "64px",
    width: "220px",
    maxWidth: "calc(100%)",
  };

  const handleChangeLogin = (
    event: { target: { value: React.SetStateAction<string> } },
    value
  ) => {
    setLogin(value);
  };

  const handleChangePassword = (
    event: { target: { value: React.SetStateAction<string> } },
    value
  ) => {
    setPassword(value);
  };

  const handleSubmitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);
    setErrors([]);
    UserConnection.login({ username: login, password: password, from: "WEB" })
      .then((data) => {
        setLoading(false);
        if (data.status == 201) {
          localStorage.setItem("user", JSON.stringify(data.data));
          dispatch({ type: SET_APP, payload: { logged: true } });
          location.replace(props?.backUrl ?? "/");
          toaster.clear();
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err?.response?.data);
      });
  };

  return (
    <>
      <div className="animated-login-background">
        {Array.from(Array(20).keys()).map((v) => (
          <span key={`animated-login-elm-${v}`}></span>
        ))}
      </div>

      <div style={styleContainer}>
        <form style={styleForm} onSubmit={handleSubmitForm}>
          <img src={logo} alt={"logo"} style={styleLogo} />

          <Input
            onChange={handleChangeLogin}
            value={login}
            name={"login"}
            disabled={loading}
            placeholder={"Login"}
            autocomplete="username"
          />
          {errors?.errors?.username && (
            <span style={styleErrors}>{errors?.errors?.username}</span>
          )}
          <Input
            onChange={handleChangePassword}
            value={password}
            name={"password"}
            disabled={loading}
            placeholder={"Hasło"}
            type={InputTypes.password}
            autocomplete="current-password"
          />
          {errors?.errors?.password && (
            <span style={styleErrors}>{errors.errors.password}</span>
          )}
          {errors?.message && <span style={styleErrors}>{errors.message}</span>}
          <SeparatorEmpty />
          <Form.Group>
            <ButtonToolbar>
              <Button
                block
                type={"submit"}
                appearance={"primary"}
                disabled={loading}
                loading={loading}>
                Zaloguj
              </Button>
            </ButtonToolbar>
          </Form.Group>
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              opacity: "0.3",
              left: 0,
              right: 0,
              textAlign: "center",
              fontSize: "0.8em",
            }}>
            v{process.env.VERSION}
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
