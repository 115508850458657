import React, { FunctionComponent, useEffect, useState } from "react";
import SwitchButton from "../../../../global/atoms/switchButton/SwitchButton";
import SwitchButtonHorizontalGroup from "../../../../global/atoms/switchButton/SwitchButtonHorizontalGroup";
import { generatePath, useHistory, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import WarehouseConnection from "../../../../utils/connections/warehouse";
import WarehouseList from "./WarehouseList";
import WarehouseUserList from "./WarehouseUserList";
import WarehouseShiftList from "./WarehouseShiftList";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Dropdown from "rsuite/Dropdown";
import WarehouseDeliveryList from "./WarehouseDeliveryList";
import ReloadIcon from "@rsuite/icons/Reload";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { confirmModalHandler } from "../../../../redux/store";
import Check from "@rsuite/icons/Check";
import TrashIcon from "@rsuite/icons/Trash";
import Spinner from "../../../../global/atoms/Spinner/Spinner";

const FULL_STORE_LIST = "defaultListButton";
const INCOMING_STORE_LIST = "incomingListButton";
const USER_STORE_LIST = "userListButton";
const SHIFT_LIST_BUTTON = "shiftListButton";

const ACCEPT_OPTION_MENU = "acceptOptionMenu";
const CANCEL_OPTION_MENU = "cancelOptionMenu";
const STORAGE_OPERATIONS_OPTION_MENU = "storageOperationsOptionMenu";

const WarehouseListHeader: FunctionComponent = () => {
  type viewType =
    | "defaultListButton"
    | "incomingListButton"
    | "userListButton"
    | "shiftListButton";
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [selectedView, setSelectedView] = useState<viewType | null>(null);
  const [disabledElements, setDisabledElements] = useState<Array<string>>([]);
  const [hiddenElements, setHiddenElements] = useState<Array<string>>([]);
  const [chosenDelivery, setChosenDelivery] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);

  useEffect(() => {
    WarehouseConnection.availabilityFilterForm().then((response) => {
      const disabledElements = response.data.disabledElements;
      setDisabledElements(disabledElements);
      setHiddenElements(response.data.hiddenElements);
      const availableButtons = getAvailableButtons(disabledElements);
      if (availableButtons.length <= 0) {
        history.push(`/projects/${id}`);
      } else {
        setSelectedView(availableButtons[0]);
      }
    });
  }, []);

  const handleChangeListView = (view: viewType) => {
    setSelectedView(view);
    setChosenDelivery([]);
  };

  const getAvailableButtons = (
    disabledElements: Array<string>
  ): Array<viewType> => {
    const allButtons: Array<viewType> = [
      FULL_STORE_LIST,
      INCOMING_STORE_LIST,
      USER_STORE_LIST,
      SHIFT_LIST_BUTTON,
    ];
    return allButtons.filter((item: viewType) => {
      return !disabledElements.includes(item);
    });
  };
  const handleConfirmDelivery = () => {
    const ids = chosenDelivery.map((el) => ({ id: el }));
    WarehouseConnection.confirmDelivery(id, ids).then((resp) => {
      if (resp.status == 200) {
        setTriggerLoad(Date.now());
      }
    });
  };
  const handleCancelDelivery = () => {
    const ids = chosenDelivery.map((el) => ({ id: el }));
    WarehouseConnection.cancelDelivery(id, ids).then((resp) => {
      if (resp.status == 200) {
        setTriggerLoad(Date.now());
      }
    });
  };
  if (selectedView === null) return <Spinner />;

  return (
    <>
      <HeaderButtons>
        <Dropdown appearance={"ghost"} title="Opcje" placement={"bottomEnd"}>
          <Dropdown.Item
            disabled={disabledElements.includes(STORAGE_OPERATIONS_OPTION_MENU)}
            onClick={() =>
              history.push(
                generatePath("/projects/:id/warehouse/add", { id: id })
              )
            }
            icon={<ReloadIcon />}>
            Operacje magazynowe
          </Dropdown.Item>
          {!hiddenElements.includes(ACCEPT_OPTION_MENU) && (
            <Dropdown.Item
              disabled={
                !chosenDelivery.length ||
                disabledElements.includes(ACCEPT_OPTION_MENU)
              }
              onClick={confirmModalHandler(
                `czy na pewno chcesz potwierdzić operacji? ${
                  !!chosenDelivery.length && `(${chosenDelivery.length})`
                }`,
                handleConfirmDelivery
              )}
              icon={<Check />}>
              Akceptuj operację{" "}
              {!!chosenDelivery.length && `(${chosenDelivery.length})`}
            </Dropdown.Item>
          )}
          {!hiddenElements.includes(CANCEL_OPTION_MENU) && (
            <Dropdown.Item
              disabled={
                !chosenDelivery.length ||
                disabledElements.includes(CANCEL_OPTION_MENU)
              }
              onClick={confirmModalHandler(
                `czy na pewno chcesz anulować operacji? ${
                  !!chosenDelivery.length && `(${chosenDelivery.length})`
                }`,
                handleCancelDelivery
              )}
              icon={<TrashIcon />}>
              Anuluj operację{" "}
              {!!chosenDelivery.length && `(${chosenDelivery.length})`}
            </Dropdown.Item>
          )}
        </Dropdown>
      </HeaderButtons>
      <div className={styles.headerContainer}>
        <SwitchButtonHorizontalGroup>
          {!disabledElements.includes(INCOMING_STORE_LIST) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, INCOMING_STORE_LIST)}
              isChecked={selectedView === INCOMING_STORE_LIST}>
              Dostawy
            </SwitchButton>
          )}
          {!disabledElements.includes(FULL_STORE_LIST) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, FULL_STORE_LIST)}
              isChecked={selectedView === FULL_STORE_LIST}>
              Stan magazynu
            </SwitchButton>
          )}
          {!disabledElements.includes(USER_STORE_LIST) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, USER_STORE_LIST)}
              isChecked={selectedView === USER_STORE_LIST}>
              Stan użytkownika
            </SwitchButton>
          )}
          {!disabledElements.includes(SHIFT_LIST_BUTTON) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, SHIFT_LIST_BUTTON)}
              isChecked={selectedView === SHIFT_LIST_BUTTON}>
              Przesunięcia
            </SwitchButton>
          )}
        </SwitchButtonHorizontalGroup>
      </div>
      <SeparatorEmpty />
      {selectedView === FULL_STORE_LIST && <WarehouseList />}
      {selectedView === INCOMING_STORE_LIST && (
        <WarehouseDeliveryList
          chosenDelivery={chosenDelivery}
          setChosenDelivery={setChosenDelivery}
          triggerLoad={triggerLoad}
        />
      )}
      {selectedView === USER_STORE_LIST && <WarehouseUserList />}
      {selectedView === SHIFT_LIST_BUTTON && (
        <WarehouseShiftList
          chosenDelivery={chosenDelivery}
          setChosenDelivery={setChosenDelivery}
          triggerLoad={triggerLoad}
        />
      )}
    </>
  );
};

export default WarehouseListHeader;
