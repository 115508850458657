import React, { FunctionComponent, useState } from "react";
import WhiteCard from "global/atoms/WhiteCard";
import Pagination from "global/pagination/Pagination";
import ScTable from "global/table/ScTable";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "global/filters/UniversalFilters";
import ButtonListRedirect from "global/atoms/ButtonListRedirect";
import NavOptionsWrapper from "utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "utils/toggleToDeleteHelper";
import connection from "utils/connections/contract/contractConnection";
import { IGetContract } from "../../../utils/models/contract/contractModels";
import { getContractState } from "../../../utils/states/contract/contractStates";
import { contractDocumentPath } from "../../../routes/contract/contract";

interface IContractList {}

const ContractList: FunctionComponent<IContractList> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IGetContract>(getContractState);
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);

  const handleEdit = (event: any) => {
    history.push(
      `${contractDocumentPath}/${event.currentTarget.dataset.id}/edit`
    );
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: connection.delete,
            callback: () => {
              setTriggerLoad(Date.now());
            },
          }}
          addPath={`${contractDocumentPath}/add`}
        />
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>
        {data === null ? (
          <></>
        ) : (
          <ScTable data={data?.data ?? []} loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Numer
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`${contractDocumentPath}/${rowData.id}/edit`}>
                    {rowData.number}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={120} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Projekt
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="projectName" />
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={120} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Kontraktor
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="contractor">
                {(rowData) => {
                  return (
                    <span>
                      {rowData.contractor.firstName +
                        " " +
                        rowData.contractor.lastName}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={120} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Zleceniodawca
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="contractor">
                {(rowData) => {
                  return (
                    <span>
                      {rowData.principal.firstName +
                        " " +
                        rowData.principal.lastName}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={250} align="left">
              <Table.HeaderCell>Okres</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <span>{rowData.startDate + " - " + rowData.endDate}</span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj Projektu
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="type">
                {(rowData) => {
                  return <span>{rowData.type.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={80} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Kategoria
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="category">
                {(rowData) => {
                  return <span>{rowData.category.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={80} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Status
              </Table.HeaderCell>
              <Table.Cell width={80} depth={0} dataKey="status">
                {(rowData) => {
                  return <span>{rowData.status.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column verticalAlign={"middle"} align="right">
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContract}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getContractState}
        setResultData={setData}
        formGet={connection.getFilterForm}
        filterStorageKey={"ContractList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "number",
            label: "Numer",
          },
          {
            type: FILTER_SELECT,
            stateKey: "category",
            label: "Kategoria",
            formKey: "categories",
          },
          {
            type: FILTER_SELECT,
            stateKey: "type",
            label: "Typ",
            formKey: "types",
          },
          {
            type: FILTER_SELECT,
            stateKey: "status",
            label: "Status",
            formKey: "statuses",
          },
        ]}
      />
    </>
  );
};

export default ContractList;
