import AxiosHelper, {
  AxiosManager,
  HttpMethod,
  default as axios,
} from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import {
  IActivityAdd,
  IFormActivitiesEditData,
  IFormTaskActivityEdit,
  IGetActivities,
} from "../models";
import { AxiosPromise, AxiosResponse } from "axios";

const ActivitiesConnection = {
  getActivities: (data: IGetActivities) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/activities/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getActivitiesForTask: (projectId: string, taskId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/list`,
      { headers: getAuthHeader() }
    );
  },
  updateActivityOrder: (
    projectId: string,
    taskId: string,
    data: Array<{ activityId: string; locked: boolean; active: boolean }>
  ) => {
    return AxiosHelper.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/order`,
      { activityIds: data },
      undefined,
      "activityLiveUpdate"
    );
  },
  createActivity: (data: IActivityAdd) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/activities`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  deleteActivity: (projectId: string, taskId: string, activityId: string) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createActivityForTask: (
    projectId: string,
    taskId: string,
    data: IActivityAdd
  ) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: any
  ) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormAddActivity: (projectId: string, taskId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditActivity: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/activities/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormFiltersActivities: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/activities/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormActivitiesEdit: (
    projectId: string,
    taskId: string
  ): Promise<AxiosResponse<IFormActivitiesEditData>> => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/list/form`
    );
  },
  getFormTaskActivityEdit: (
    taskId: string,
    activityId: string
  ): Promise<AxiosResponse<IFormTaskActivityEdit>> => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/form`
    );
  },
  toggleActivity: (taskId: string, activityId: string) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosManager.send(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/toggle`
    );
  },
  cloneActivity: (
    projectId: string,
    taskId: string,
    activityId: string,
    data: any
  ): AxiosPromise<any> => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/clone`,
      data
    );
  },
  getCustomers: (customerIds: string[]) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers-by-ids`,
      customerIds
    );
  },
};

export default ActivitiesConnection;
