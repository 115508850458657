import Spinner from "global/atoms/Spinner/Spinner";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

interface IMainMenuProxyRedirect {
  redirectTo: string;
}

const MainMenuProxyRedirect: FunctionComponent<IMainMenuProxyRedirect> = (
  props
) => {
  const history = useHistory();
  history.push(props.redirectTo);
  return <Spinner />;
};

export default MainMenuProxyRedirect;
