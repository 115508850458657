import React, { FunctionComponent, useState } from "react";
import { IBodySingleResponse, IGetQuestions } from "utils/models";
import { getQuestionsState } from "utils/states";
import Pagination from "global/pagination/Pagination";
import QuestionsConnection from "utils/connections/questions";
import { IconButton, Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  BatchActionDropdownItem,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { getQuestionIconByType } from "../../../../global/atoms/IconHelper";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import CopyIcon from "@rsuite/icons/Copy";
import { confirmModalCallback } from "redux/store";
import { handleSortColumn, handleToast } from "utils/helpers";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import TrashIcon from "@rsuite/icons/Trash";
import QuestionErrorToolTip from "./QuestionErrorToolTip";

interface IQuestionsList {}

const QuestionsList: FunctionComponent<IQuestionsList> = () => {
  const [data, setData] = useState<any | null | "error">(null);
  const [filters, setFilters] = useState<IGetQuestions>(getQuestionsState);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<
    Array<{ questionId: string; questionName: string; reasons: string[] }>
  >([]);

  const handleEdit = (event: any) => {
    history.push(`/datasets/questions/${event.currentTarget.dataset.id}/edit`);
  };

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(QuestionsConnection.batchDelete(toDelete), setErrors).then(
      () => {
        setTriggerUpdate(Date.now());
        setChosenToDelete([]);
      }
    );
  };

  const hasErrors = (id: string) => {
    return errors.find((e) => e.questionId == id);
  };

  const questionClone = (questionId: string) => {
    confirmModalCallback("Czy chcesz sklonować pytanie?", () => {
      handleToast(QuestionsConnection.clone(questionId)).then(
        (res: IBodySingleResponse<{ newQuestionId: string }>) => {
          history.push(
            `/datasets/questions/${res.data.newQuestionId}/edit?cloned`
          );
        }
      );
    });
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={"/datasets/questions/add"}>
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            icon={<TrashIcon />}
            disabled={chosenToDelete.length === 0}
            chosenRows={chosenToDelete}
            title={`Usuń zaznaczone pytania (${chosenToDelete.length})`}
            message={"Czy na pewno chcesz usunąć zaznaczone pytania?"}
          />
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40}>
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={250} sortable resizable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) => (
                  <>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      {hasErrors(rowData.id) && (
                        <QuestionErrorToolTip error={hasErrors(rowData.id)} />
                      )}
                      &nbsp;
                      <div>
                        <ButtonListRedirect
                          redirectUrl={`/datasets/questions/${rowData.id}/edit`}>
                          {rowData.name}
                        </ButtonListRedirect>{" "}
                      </div>
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} sortable resizable>
              <Table.HeaderCell>Klient</Table.HeaderCell>
              <Table.Cell dataKey="customer">
                {(rowData) => <span>{rowData.customer.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable resizable>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="questionTypeName">
                {(rowData) => (
                  <span>
                    {getQuestionIconByType(rowData.questionType.type)}
                    {rowData.questionType.name}
                  </span>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} sortable>
              <Table.HeaderCell>Aktywność</Table.HeaderCell>
              <Table.Cell dataKey="activityType">
                {(rowData) => <span>{rowData.activityType.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column align="right" fixed={"right"} width={100}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <IconButton
                      icon={<CopyIcon color={"#ffaf38"} />}
                      appearance={"subtle"}
                      onClick={questionClone.bind(null, rowData.id)}
                    />
                    <img
                      src={settings}
                      style={{ cursor: "pointer" }}
                      alt={"Idź do"}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={QuestionsConnection.getQuestions}
        formGet={QuestionsConnection.getQuestionsFiltersForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getQuestionsState}
        setResultData={setData}
        triggerLoad={triggerUpdate}
        filterStorageKey={"questionsList"}
        elements={[
          {
            label: "Nazwa",
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
          },
          {
            label: "Klient",
            type: FILTER_SELECT,
            stateKey: "customerId",
            formKey: "customers",
          },
          {
            label: "Typ pytania",
            type: FILTER_SELECT,
            stateKey: "questionTypeId",
            formKey: "questionTypes",
          },
          {
            label: "Aktywność",
            type: FILTER_SELECT,
            stateKey: "activityTypeId",
            formKey: "activityTypes",
          },
        ]}
      />
    </>
  );
};

export default QuestionsList;
