import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Modal from "rsuite/Modal";
import SchedulesConnection, {
  IScheduleUserReplacement,
} from "../../../utils/connections/schedules";
import { Table } from "rsuite";
import { handleToast } from "../../../utils/helpers";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import {
  BatchActionButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../utils/toggleToDeleteHelper";
import TrashIcon from "@rsuite/icons/Trash";
import dayjs from "dayjs";

const { Column, HeaderCell, Cell } = Table;

interface IModalUserReplacementList {
  open: boolean;
  onModalClose: () => void;
  requestData: IScheduleRequestData;
}

const ModalUserReplacementList: FunctionComponent<IModalUserReplacementList> = (
  props
) => {
  const [data, setData] = useState<null | Array<IScheduleUserReplacement>>(
    null
  );
  const [replacementToDelete, setReplacementToDelete] = useState<Array<string>>(
    []
  );

  useEffect(() => {
    if (props.open) loadData();
    else setData(null);
  }, [props.open]);

  const loadData = () => {
    SchedulesConnection.userReplacementList(props.requestData).then((r) => {
      setData(r.data);
    });
  };
  const handleBatchRemove = () => {
    const data = replacementToDelete.map((idToDel) => {
      return { id: idToDel };
    });
    handleToast(SchedulesConnection.userReplacementBatchRemove(data)).then(() =>
      loadData()
    );
  };
  const handleRemove = (row: IScheduleUserReplacement) => {
    handleToast(SchedulesConnection.userReplacementRemove(row.id)).then(() =>
      loadData()
    );
  };

  const renderEmpty = (): ReactNode => {
    return (
      <div style={{ padding: "30px", textAlign: "center", color: "#a6a6a6" }}>
        <span>{"Brak zastępstw dla danego zakresu"}</span>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={props.open}
        size={"full"}
        onClose={() => {
          setReplacementToDelete([]);
          props.onModalClose();
        }}>
        <Modal.Header>
          <h4>
            Zastępstwa&nbsp;&nbsp;
            <small>
              {dayjs(props.requestData.dateFrom).format("YYYY/MM/DD")}
              &nbsp;-&nbsp;
              {dayjs(props.requestData.dateTo).format("YYYY/MM/DD")}
            </small>
          </h4>
          {replacementToDelete.length > 0 && (
            <BatchActionButton
              callback={handleBatchRemove}
              icon={<TrashIcon />}
              chosenRows={replacementToDelete}
              title={`Usuń zaznaczone zastępstwa (${replacementToDelete.length})`}
              message={"Czy na pewno chcesz usunąć zaznaczone zastępstwa?"}
            />
          )}
        </Modal.Header>

        <Modal.Body style={{ overflowX: "hidden" }}>
          <Table
            data={data ?? []}
            style={{ width: "100%" }}
            renderEmpty={renderEmpty}
            cellBordered={true}
            autoHeight={true}
            loading={!data}>
            <Column width={70} align={"center"}>
              <HeaderCell>
                <ToggleAllCheckbox
                  data={data}
                  state={replacementToDelete}
                  setState={setReplacementToDelete}
                  keyIdName={"id"}
                />
              </HeaderCell>
              <Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={replacementToDelete}
                    setState={setReplacementToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Cell>
            </Column>
            <Column flexGrow={0.7}>
              <HeaderCell>Projekt</HeaderCell>
              <Cell dataKey={"project"}>
                {(rowData: IScheduleUserReplacement) => {
                  return rowData.projectName;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1.2}>
              <HeaderCell>Lokalizacja</HeaderCell>
              <Cell dataKey={"userFromName"}>
                {(rowData: IScheduleUserReplacement) => {
                  return rowData.locationName;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Użytkownik od</HeaderCell>
              <Cell dataKey={"userFromName"}>
                {(rowData: IScheduleUserReplacement) => {
                  return rowData.userFromName;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Użytkownik do</HeaderCell>
              <Cell dataKey={"userToName"}>
                {(rowData: IScheduleUserReplacement) => {
                  return rowData.userToName;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1.1}>
              <HeaderCell>Czas trwania</HeaderCell>
              <Cell>
                {(rowData: IScheduleUserReplacement) => {
                  return rowData.startDate + " - " + rowData.endDate;
                }}
              </Cell>
            </Column>

            <Column width={50} align={"center"}>
              <HeaderCell>Usuń</HeaderCell>
              <Cell>
                {(rowData: IScheduleUserReplacement) => {
                  return (
                    <DeleteSingleButton
                      message={`Czy napewno chcesz usunąć zastępstwo?`}
                      callback={handleRemove.bind(null, rowData)}
                    />
                  );
                }}
              </Cell>
            </Column>
          </Table>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}></Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUserReplacementList;
