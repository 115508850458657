import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { now } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { Button, Modal } from "rsuite";
import { ICoords } from "utils/models";
import Map, { IPoint } from "../../../../global/googleMap/Map";

interface IAddressCoordsModal {
  show: boolean;
  onClose: () => void;
  coords: ICoords | null;
  onSubmit: (coords: ICoords) => void;
}

const AddressCoordsModal: FunctionComponent<IAddressCoordsModal> = (props) => {
  const zoom = props.coords ? 16 : 7;
  const [mapPoint, setMapPoint] = useState<IPoint>();
  const defaultCenter = props.coords
    ? { lat: props.coords.latitude, lng: props.coords.longitude }
    : {
        lat: 51.919438,
        lng: 19.145136,
      };
  const onMapClick = (event) => {
    const newCoords = event.latLng.toJSON();
    setMapPoint((mapPoint) => ({
      ...mapPoint,
      lat: newCoords.lat,
      lon: newCoords.lng,
      id: now().toString(),
    }));
  };

  const handleSubmit = () => {
    props.onSubmit({
      latitude: mapPoint.lat,
      longitude: mapPoint.lon,
    });
  };

  return (
    <Modal open={props.show} size={"lg"} onClose={props.onClose}>
      <Modal.Body draggable={true}>
        <Map
          allowPointsCluster={false}
          onMapClick={onMapClick}
          center={defaultCenter}
          zoom={zoom}
          points={mapPoint ? [mapPoint] : []}></Map>
      </Modal.Body>
      <Modal.Footer>
        <SeparatorEmpty />
        <Button appearance={"ghost"} onClick={props.onClose}>
          Anuluj
        </Button>
        <Button
          appearance={"primary"}
          disabled={!mapPoint}
          onClick={handleSubmit}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressCoordsModal;
