import React, { FunctionComponent } from "react";

const LayoutPortal: FunctionComponent = () => {
  return (
    <>
      <div id={"layout-portal"} />
    </>
  );
};

export default LayoutPortal;
