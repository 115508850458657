import React from "react";
import { useSelector } from "react-redux";
import { IRoot } from "../../redux/models";

const TaskBreadcrumb: React.ComponentType = () => {
  const breadcrumb = useSelector((state: IRoot) => state.breadcrumb);
  return <>{breadcrumb.taskName ? breadcrumb.taskName : "..."}</>;
};

export default TaskBreadcrumb;
