import React from "react";
import WhiteCard from "global/atoms/WhiteCard";
import Form from "global/form/Form";
import Input from "global/atoms/Input";
import Select from "global/atoms/Select";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import { Checkbox, InputNumber } from "rsuite";
import { SetState } from "utils/models";
import { useParams } from "react-router-dom";
import RolePermissions from "global/RolePermissions";

interface IRoleData {
  roleTypeId: string;
  name: string;
  hierarchyLevel: number;
  isEnableWorkTimeHistory: boolean;
}

interface IRoleUpdate {
  handleSubmit: (event: any) => void;
  data: IRoleData;
  form: any | "loading";
  setData: SetState<IRoleData>;
  errors: any;
  isCreate?: boolean;
}

const RoleUpdate: React.FC<IRoleUpdate> = ({
  handleSubmit,
  data,
  form,
  setData,
  errors,
  isCreate,
}) => {
  const { id } = useParams<{ id: string }>();
  console.log(data);
  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Input
          type={"text"}
          placeholder={"Nazwa"}
          disabled={!isCreate}
          name={"name"}
          value={data.name}
          state={data}
          setState={setData}
          errors={errors}
        />
        <Select
          placeholder={"Rodzaj grupy"}
          name={"roleTypeId"}
          disabled={!isCreate}
          value={data.roleTypeId}
          state={data}
          options={form.roleTypes.options}
          setState={setData}
          errors={errors}
        />
        <InputNumber
          min={0}
          max={50}
          name={"hierarchyLevel"}
          value={data.hierarchyLevel}
          onChange={(value) =>
            setData((d) => ({
              ...d,
              hierarchyLevel: parseInt(value.toString()),
            }))
          }
        />
        <Checkbox
          checked={data.isEnableWorkTimeHistory}
          onChange={(value, chk) =>
            setData((d) => ({ ...d, isEnableWorkTimeHistory: chk }))
          }>
          Śledź czas pracy
        </Checkbox>
        <ActionsContainer>
          <RolePermissions roleId={id} />
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default RoleUpdate;
