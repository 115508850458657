import ActionsContainer from "global/atoms/ActionsContainer";
import LocalDatePicker from "global/atoms/LocalDatePicker";
import Select from "global/atoms/Select";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, IconButton, Row } from "rsuite";
import ReportTemplateConnection from "utils/connections/reportTemplate";
import { getProjectIdFromUrl } from "utils/helpers";
import { ISelect, SetState } from "utils/models";
import { IReportGenerateData } from "../Reports";
import EditIcon from "@rsuite/icons/Edit";
import TagPicker from "rsuite/TagPicker";
import Input from "rsuite/Input";

interface IReportLocationData extends IReportGenerateData {
  templateOptions: {
    networkIds: Array<string>;
    locationIds: Array<string>;
    userIds: Array<string>;
    locationName: string;
    city: string;
    street: string;
  };
}

interface IGeneralReportFilter {
  handleSubmit: () => void;
  state: IReportLocationData;
  setState: SetState<IReportGenerateData>;
}

interface IForm {
  locations: ISelect;
  networks: ISelect;
  users: ISelect;
}

const LocationReportFilter: FunctionComponent<IGeneralReportFilter> = (
  props
) => {
  const history = useHistory();
  const [themes, setThemes] = useState<ISelect | null>(null);
  const [form, setForm] = useState<IForm | null>(null);

  useEffect(() => {
    ReportTemplateConnection.getReportOptions(props.state.type).then((res) => {
      const tmp = res.data.templates;
      if (_.isEmpty(tmp.options)) {
        tmp.options = [{ id: "", name: "Szablon systemowy" }];
      }
      props.setState((s) => ({ ...s, templateId: tmp.options[0]?.id }));
      setThemes(tmp);
      setForm(res.data);
    });
  }, [props.state.type]);

  const handleEditTemplate = () => {
    history.push(
      `/projects/${getProjectIdFromUrl()}/report-template/${
        props.state.type
      }/edit/${props.state.templateId}`
    );
  };
  const handleAddTemplate = () => {
    history.push(
      `/projects/${getProjectIdFromUrl()}/report-template/${
        props.state.type
      }/add`
    );
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}>
        <Col xs={6}>
          <Select
            key={"template_" + (props.state.type ?? "none")}
            placeholder={
              !_.isEmpty(themes?.options)
                ? "Wybierz szablon"
                : "Brak szablonów - dodaj nowy"
            }
            name={"templateId"}
            value={props.state.templateId}
            options={themes?.options ?? []}
            state={props.state}
            setState={props.setState}
          />
        </Col>
        <Col xs={6}>
          <ActionsContainer>
            <IconButton
              icon={<EditIcon />}
              appearance={"ghost"}
              onClick={handleEditTemplate}
              disabled={!props.state.templateId}>
              Edytuj
            </IconButton>
            <Button appearance={"ghost"} block onClick={handleAddTemplate}>
              Dodaj nowy
            </Button>
          </ActionsContainer>
        </Col>
      </Row>
      <SeparatorEmpty size={1.5} />
      <Row>
        <Col xs={6}>
          <LocalDatePicker
            label={"Lokalizacja aktywna w projekcie od"}
            state={props.state}
            stateKey={"startDate"}
            emptyValue={""}
            placement={"bottom"}
            outputFormat={"yyyy-MM-dd"}
            setState={props.setState}
          />
        </Col>
        <Col xs={6}>
          <LocalDatePicker
            label={"Lokalizacja aktywna w projekcie do"}
            state={props.state}
            stateKey={"endDate"}
            emptyValue={""}
            placement={"bottom"}
            outputFormat={"yyyy-MM-dd"}
            setState={props.setState}
          />
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Sieć</Form.ControlLabel>
            <TagPicker
              block
              searchable
              data={form?.networks.options ?? []}
              valueKey={"id"}
              labelKey={"name"}
              value={props.state.templateOptions.networkIds}
              name={"network"}
              placement={"bottom"}
              onChange={(values) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: {
                    ...data.templateOptions,
                    networkIds: values,
                  },
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Lokalizacje</Form.ControlLabel>
            <TagPicker
              block
              searchable
              data={form?.locations.options ?? []}
              valueKey={"id"}
              labelKey={"name"}
              value={props.state.templateOptions.locationIds}
              name={"network"}
              placement={"bottom"}
              onChange={(values) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: {
                    ...data.templateOptions,
                    locationIds: values,
                  },
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Nazwa lokalizacji</Form.ControlLabel>
            <Input
              value={props.state.templateOptions.locationName}
              name={"locationName"}
              onChange={(value) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: {
                    ...data.templateOptions,
                    locationName: value,
                  },
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Miejscowość</Form.ControlLabel>
            <Input
              value={props.state.templateOptions.city}
              name={"city"}
              onChange={(value) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: { ...data.templateOptions, city: value },
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>Ulica</Form.ControlLabel>
            <Input
              value={props.state.templateOptions.street}
              name={"street"}
              onChange={(value) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: { ...data.templateOptions, street: value },
                }));
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.ControlLabel>{"Grupa -> Użytkownik"}</Form.ControlLabel>
            <TagPicker
              block
              searchable
              data={form?.users.options ?? []}
              valueKey={"id"}
              labelKey={"fullName"}
              groupBy={"type"}
              value={props.state.templateOptions.userIds}
              name={"userIds"}
              placement={"bottom"}
              onChange={(values) => {
                props.setState((data) => ({
                  ...data,
                  templateOptions: { ...data.templateOptions, userIds: values },
                }));
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <SeparatorEmpty size={1.5} />
      <Row
        style={{
          display: "flex",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}>
        <Col xs={6}>
          <Select
            placeholder={"Format"}
            name={"format"}
            value={props.state.format}
            options={[
              { id: "xlsx", name: "xlsx" },
              { id: "csv", name: "csv" },
            ]}
            setState={props.setState}
            state={props.state}
          />
        </Col>
        <Col xs={6}>
          <Button appearance={"primary"} block onClick={props.handleSubmit}>
            Wygeneruj raport
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LocationReportFilter;
