import React, { FunctionComponent, useState } from "react";
import { ICreateBrand } from "utils/models";
import { createBrandState } from "utils/states";
import { useHistory } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import BrandsConnection from "utils/connections/brands";
import Form from "global/form/Form";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Input from "global/atoms/Input";

interface IAddBrand {}

const AddBrand: FunctionComponent<IAddBrand> = () => {
  const [data, setData] = useState<ICreateBrand>(createBrandState);
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(BrandsConnection.createBrand(data), setErrors).then(() =>
      history.push("/products/brand")
    );
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Nazwa"}
            name={"name"}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default AddBrand;
