import React, { memo } from "react";
import { Panel } from "rsuite";
import { FCC, IQuestionItem, IVisitPosition } from "utils/models";
import QuestionBody from "./Atoms/QuestionBody";
import {
  getDependentOnQuestion,
  useEditContext,
  useStaticContext,
} from "./VisitHelpers";

interface IVisitQuestionPanel {
  visitPosition: IVisitPosition;
  question: IQuestionItem;
  index: number;
}

const VisitQuestionPanel: FCC<IVisitQuestionPanel> = (props) => {
  const {
    visitId,
    readOnly,
    comments,
    questionsFlatState,
    hiddenElements,
    disabledElements,
  } = useStaticContext();

  const { disabledQuestions } = useEditContext();

  return (
    <Panel classPrefix={"question-panel"} collapsible expanded={true}>
      <QuestionBody
        activityType={props.visitPosition.isLocation ? "LOCATION" : "GENERAL"}
        question={props.question}
        isDisabled={disabledQuestions.includes(props.question.refQuestionId)}
        dependedOnQuestion={
          getDependentOnQuestion(
            props.question.dependOnQuestion.refQuestionId,
            questionsFlatState
          ) ?? undefined
        }
        readOnly={readOnly}
        comments={comments.filter(
          (c) => c.visitQuestionId === props.question.refQuestionId
        )}
        hiddenElements={hiddenElements}
        disabledElements={disabledElements}
        visitId={visitId}
      />
    </Panel>
  );
};

export default memo(VisitQuestionPanel);
