import React, { useEffect, useRef, useState } from "react";
import commonStyles from "../common.module.scss";
import { FCC } from "utils/models";
import { Button } from "rsuite";

interface IFileInput {
  label: string;
  errors: any;
  form: any;
  setForm: any;
  acceptType: "xlsx" | "image";
  triggerReset?: number; // state timestamp
}

const FileInput: FCC<IFileInput> = (props) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileSelectLabel, setFileSelectLabel] = useState(props.label);

  const onFileSelected = (event: any) => {
    const _file: File = event.target.files[0];
    setFileSelectLabel(_file.name);
    props.setForm({
      ...props.form,
      file: _file,
    });
  };

  const onFileSelectorBtnClick = () => {
    if (inputFile) inputFile.current?.click();
  };

  const getAcceptFromType = (): string => {
    switch (props.acceptType) {
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "image":
        return "image/*";
    }
    return "*";
  };

  useEffect(() => {
    setFileSelectLabel(props.label);
  }, [props.triggerReset]);

  return (
    <>
      <input
        ref={inputFile}
        style={{ display: "none" }}
        type={"file"}
        multiple={false}
        accept={getAcceptFromType()}
        onChange={onFileSelected}
      />

      <Button appearance={"primary"} onClick={onFileSelectorBtnClick}>
        {fileSelectLabel}
      </Button>

      {
        //@ts-ignore
        props.errors && props.errors.file && (
          <div className={commonStyles.loadingSmallErr}>
            {props.errors.file.message}
          </div>
        )
      }
    </>
  );
};

export default FileInput;
