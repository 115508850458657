import { AxiosPromise } from "axios";
import AxiosHelper from "../AxiosHelper";
import {
  ILogCMDRequestData,
  ILogMobileRequestData,
  ILogRequestData,
} from "../models";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}`;

const LogsConnection = {
  list: (requestData: ILogRequestData): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/loggers/list`, requestData);
  },
  listForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/loggers/filter/form`);
  },
  listApp: (requestData: ILogMobileRequestData): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/loggers/mobile/list`, requestData);
  },
  listAppForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/loggers/mobile/filter/form`);
  },
  listCmd: (requestData: ILogCMDRequestData): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/loggers/process/list`, requestData);
  },
  listCmdForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/loggers/process/filter/form`);
  },
  listEvent: (requestData: ILogCMDRequestData): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/loggers/events/list`, requestData);
  },
  listEventForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/loggers/events/filter/form`);
  },
  listEventDelete: (id: string): AxiosPromise => {
    return AxiosHelper.delete(`${baseUrl}/loggers/events/delete`, {
      headers: { ...getAuthHeader() },
      data: { ids: [{ id: id }] },
    });
  },
  ping: (timestamp: number, event: string, timeout: number): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/loggers/ping`, {
      time: timestamp,
      event: event,
      timeout: timeout,
    });
  },
};

export default LogsConnection;
