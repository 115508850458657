import React, { FunctionComponent, memo, useEffect } from "react";
import { IVisitDocument } from "../../../../../../utils/models";
import SeparatorEmpty from "../../../../../../global/atoms/separators/SeparatorEmpty";
import styles from "../styles.module.scss";
import DocumentLink from "../DocumentLink";
import { Panel } from "rsuite";
import { usePanelContext } from "./VisitHelpers";

interface IVisitDocumentsComponent {
  documents: Array<IVisitDocument>;
}

const VisitDocuments: FunctionComponent<IVisitDocumentsComponent> = (props) => {
  if (!props.documents.length) return <></>;

  const { registerPanelId, panelToggle, openPanels } = usePanelContext();

  useEffect(() => {
    registerPanelId("documents");
  }, []);

  return (
    <>
      <SeparatorEmpty />
      <Panel
        collapsible
        shaded
        expanded={openPanels.includes("documents")}
        header={"Dokumenty"}
        onSelect={() => panelToggle("documents")}>
        {props.documents.map((item, idx: number) => (
          <div
            className={`${styles.container} ${
              idx === 0 ? styles.radiusTopContainer : ""
            }`}
            key={item.id}>
            <DocumentLink
              projectId={item.projectId}
              id={item.id}
              name={item.documentNumber}
            />
          </div>
        ))}
      </Panel>
    </>
  );
};

export default memo(VisitDocuments);
