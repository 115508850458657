import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  ICreateContract,
  ICreateContractUser,
  IDocumentValidator,
  IGetContract,
} from "../../models/contract/contractModels";

export const path = `/contracts`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

interface IDataToSend {
  status: string;
}

const ContractConnection = {
  getContract: (data: IGetContract) => {
    return AxiosHelper.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContract: (data: ICreateContract) => {
    return AxiosHelper.post(baseUrl, data, {
      headers: getAuthHeader(),
    });
  },
  updateContract: (id: string, data: IDataToSend) => {
    return AxiosHelper.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersContract: () => {
    return AxiosHelper.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddContract: () => {
    return AxiosHelper.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContract: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return AxiosHelper.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
  delete: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}`, { ids: data });
  },
  getListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  getFilterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },
  getContractUserData: (pesel: string): AxiosPromise => {
    return AxiosHelper.post(
      `${baseUrl}/users/find`,
      { pesel: pesel },
      { headers: getAuthHeader() }
    );
  },
  getContractSectionOneValid: (data: ICreateContractUser): AxiosPromise => {
    return AxiosHelper.post(
      `${baseUrl}/sections/validators/contractors`,
      data,
      { headers: getAuthHeader() }
    );
  },
  getContractSectionContractValid: (data: ICreateContract): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/sections/validators/properties`, data, {
      headers: getAuthHeader(),
    });
  },
  getContractSectionProjectValid: (data: ICreateContract): AxiosPromise => {
    return AxiosHelper.post(
      `${baseUrl}/sections/validators/projects`,
      { contractProjectId: data.contractProjectId },
      { headers: getAuthHeader() }
    );
  },
  getContractSectionDocumentValid: (data: IDocumentValidator): AxiosPromise => {
    return AxiosHelper.post(
      `${baseUrl}/sections/validators/documents/templates`,
      data,
      { headers: getAuthHeader() }
    );
  },
  getContractDocument: (contractId: string, data): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}/${contractId}/documents/list`, data);
  },
  getContractDocumentFilters: (contractId: string): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/${contractId}/documents/filter/form`);
  },
};

export default ContractConnection;
