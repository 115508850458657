import React, { FunctionComponent, useEffect, useState } from "react";
import ProjectsConnection from "../../../../utils/connections/projects";
import {
  IGetProjectProducts,
  IProjectProducts,
} from "../../../../utils/models";
import { _paginate30 } from "../../../../utils/states";
import { generatePath, useHistory } from "react-router-dom";
import { Table } from "rsuite";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import IconButton from "../../../../global/atoms/IconButton";
import Pagination from "../../../../global/pagination/Pagination";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import {
  BatchActionDropdownItem,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import {
  getProjectIdFromUrl,
  handleSortColumn,
  handleToast,
} from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters from "../../../../global/filters/UniversalFilters";
import { Dropdown, Nav } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

const TYPE_ASSIGNED = "assigned";
const TYPE_UNASSIGNED = "unassigned";

const Products: FunctionComponent = () => {
  const [data, setData] = useState<IProjectProducts | null>(null);
  const defaultFilters: IGetProjectProducts = {
    customerId: "",
    name: "",
    brandId: "",
    gtin: "",
    itemUnitId: "",
    itemVatRateId: "",
    itemGroupId: "",
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IGetProjectProducts>(defaultFilters);
  type IViewTypes = "assigned" | "unassigned";
  const history = useHistory();
  const [viewType, setViewType] = useState<IViewTypes>(TYPE_ASSIGNED);
  const [chosen, setChosen] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const loadFunc =
    viewType === TYPE_ASSIGNED
      ? ProjectsConnection.getAssignedProducts
      : ProjectsConnection.getUnassignedProducts;
  const [triggerLoad, setTriggerLoad] = useState(0);

  const handleMoveProducts = (type: IViewTypes) => {
    const data = chosen.map((item) => ({ itemId: item }));
    let promise;
    if (type === TYPE_ASSIGNED) {
      promise = ProjectsConnection.unassignProductsFromProject(data);
    } else {
      promise = ProjectsConnection.assignProductsToProject(data);
    }
    handleToast(promise).then(() => {
      if (type === TYPE_UNASSIGNED) setViewType(TYPE_ASSIGNED);
      setChosen([]);
      setTriggerLoad(Date.now());
    });
  };

  useEffect(() => {
    setTriggerLoad(Date.now());
  }, [viewType]);

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          {viewType === TYPE_ASSIGNED && (
            <BatchActionDropdownItem
              chosenRows={chosen}
              callback={handleMoveProducts.bind(null, TYPE_ASSIGNED)}
              title={`Odłącz zaznaczone produkty (${chosen.length})`}
              message={"Czy odłączyć produkty "}
            />
          )}
          {viewType === TYPE_UNASSIGNED && (
            <>
              <Dropdown.Item
                onClick={() => history.push("/products/product/add")}
                icon={<PlusIcon />}>
                Dodaj nowy produkt
              </Dropdown.Item>

              <BatchActionDropdownItem
                chosenRows={chosen}
                callback={handleMoveProducts.bind(null, TYPE_UNASSIGNED)}
                title={`Przypisz zaznaczone produkty (${chosen.length})`}
                message={"Czy przypisać produkty "}
              />
            </>
          )}
        </OptionsDropdown>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        beforeTableElements={
          <Nav
            appearance={"default"}
            activeKey={viewType}
            onSelect={(vt) => {
              setViewType(vt);
              setChosen([]);
            }}
            style={{ marginBottom: "10px" }}>
            <Nav.Item eventKey={TYPE_ASSIGNED}>Przypisane</Nav.Item>
            <Nav.Item eventKey={TYPE_UNASSIGNED}>Nieprzypisane</Nav.Item>
          </Nav>
        }
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosen}
                  setState={setChosen}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey={"id"}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosen}
                    setState={setChosen}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              width={500}
              align="left"
              sortable
              flexGrow={1}
              fullText>
              <Table.HeaderCell depth={0} width={0}>
                Nazwa
              </Table.HeaderCell>
              <Table.Cell depth={0} dataKey={"name"}>
                {(rowData) =>
                  viewType === TYPE_ASSIGNED ? (
                    <ButtonListRedirect
                      redirectUrl={generatePath(
                        "/projects/:id/edit/products/:productId",
                        {
                          id: getProjectIdFromUrl() ?? "",
                          productId: rowData.id,
                        }
                      )}>
                      {rowData.name}
                    </ButtonListRedirect>
                  ) : (
                    <>{rowData.name}</>
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" resizable>
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0}>
                {(rowData) => <span>{rowData.customer.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Grupa
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey={"itemGroup.name"}>
                {(rowData) => <span>{rowData.itemGroup.name}</span>}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Marka
              </Table.HeaderCell>
              <Table.Cell width={70} depth={0} dataKey={"brand.name"}>
                {(rowData) => {
                  return <span>{rowData?.brand?.name ?? "-"}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Netto
              </Table.HeaderCell>
              <Table.Cell width={70} depth={0}>
                {(rowData) => {
                  return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Vat
              </Table.HeaderCell>
              <Table.Cell width={80} depth={0}>
                {(rowData) => {
                  return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Brutto
              </Table.HeaderCell>
              <Table.Cell width={80} depth={0}>
                {(rowData) => {
                  return (
                    <span>{Number(rowData.price.amountGross).toFixed(2)}</span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            {viewType === TYPE_ASSIGNED && (
              <Table.Column fixed="right" flexGrow={1} align="right">
                <Table.HeaderCell depth={0} width={0}>
                  &nbsp;
                </Table.HeaderCell>
                <Table.Cell width={100} depth={0}>
                  {(rowData) => (
                    <ActionsContainer>
                      <IconButton
                        icon={"settings"}
                        onClick={() =>
                          history.push(
                            generatePath(
                              "/projects/:id/edit/products/:productId",
                              {
                                id: getProjectIdFromUrl() ?? "",
                                productId: rowData.id,
                              }
                            )
                          )
                        }
                      />
                    </ActionsContainer>
                  )}
                </Table.Cell>
              </Table.Column>
            )}
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={loadFunc}
        formGet={ProjectsConnection.getFiltersFormProducts}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`productsBrand_1_${getProjectIdFromUrl()}`}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: "input_text",
            stateKey: "name",
            label: "Nazwa",
          },
          {
            type: "input_text",
            stateKey: "gtin",
            label: "GTIN",
          },
          {
            type: "select",
            label: "Jednostka",
            stateKey: "itemUnitId",
            formKey: "itemUnits",
          },
          {
            type: "select",
            label: "Grupa",
            stateKey: "itemGroupId",
            formKey: "itemGroups",
          },
          {
            type: "select",
            label: "Vat",
            stateKey: "itemVatRateId",
            formKey: "itemVatRates",
          },
          {
            type: "select",
            label: "Klient",
            stateKey: "customerId",
            formKey: "customers",
          },
        ]}
      />
    </>
  );
};

export default Products;
