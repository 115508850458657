import React, { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "../../../../global/googleMap/MapWrapper";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import Map, { IPoint } from "../../../../global/googleMap/Map";
import {
  Button,
  Col,
  DatePicker,
  Message,
  Panel,
  Row,
  SelectPicker,
} from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import {
  IFormBodyResponse,
  IGpsSimple,
  IGpsUserViewRequest,
  ISelect,
} from "../../../../utils/models";
import GpsConnection from "../../../../utils/connections/gps";
import { handleToastRedux } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { getPointTypeName } from "../visits/visitsEdit/VisitElements/Atoms/MarkerClusterWindowContent";
import dayjs from "dayjs";
import FlexRows from "global/FlexRows";

interface IGps {}

interface IForm extends IFormBodyResponse {
  users: ISelect;
}

interface ILocalPoint extends IPoint, IGpsSimple {}

interface IInfoWindowExtended extends google.maps.InfoWindowOptions {
  point: ILocalPoint | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Gps: FunctionComponent<IGps> = (props) => {
  const dispatch = useDispatch();
  const [google, setGoogle] = useState<null | any>(null);
  const [points, setPoints] = useState<ILocalPoint[] | null>(null);
  const [zoom, setZoom] = useState<number>(7);
  const [mapCenter, setMapCenter] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [form, setForm] = useState<IForm | null>(null);
  const [request, setRequest] = useState<IGpsUserViewRequest>({
    userId: null,
    dateFrom: null,
    dateTo: null,
  });
  const pointIcon = "/png/dot_tiny.png";
  const [polylines, setPolylines] = useState<
    google.maps.PolylineOptions[] | undefined
  >(undefined);
  const mapInfoWindowInitial: IInfoWindowExtended = {
    content: null,
    position: null,
    point: null,
  };
  const [infoWindow, setInfoWindow] =
    useState<IInfoWindowExtended>(mapInfoWindowInitial);

  useEffect(() => {
    GpsConnection.form().then((result) => {
      setForm(result.data);
    });
  }, []);

  useEffect(() => {
    if (infoWindow.content !== null && infoWindow.point !== null) {
      setTimeout(() => {
        // @ts-ignore
        document
          .getElementById("static-btn-prev")
          ?.addEventListener("click", () => onPointNext(infoWindow.point));
        // @ts-ignore
        document
          .getElementById("static-btn-next")
          ?.addEventListener("click", () => onPointPrev(infoWindow.point));
      }, 100);
    }
  }, [infoWindow]);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  const getPoints = () => {
    setPoints(null);

    handleToastRedux(GpsConnection.points(request), dispatch).then((res) => {
      const _points: Array<ILocalPoint> = [];

      res.data
        //.filter((g: IGpsSimple) => (g.pointType === 'WORK_START'))
        .forEach((g: IGpsSimple) => {
          _points.push({
            ...g,
            selected: false,
            hidden: false,
            icon: pointIcon,
          });
        });

      setPoints(_points);

      // set polyline thru each point
      const opts: google.maps.PolylineOptions = {
        geodesic: true,
        strokeColor: "#FFAF38",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        path: _points.map((p) => ({ lat: p.lat, lng: p.lon })),
      };
      setPolylines([opts]);

      if (_points?.length) {
        // center calculate
        let sumLat = 0;
        let sumLon = 0;

        _points?.forEach((p) => {
          sumLat += p.lat;
          sumLon += p.lon;
        });

        setMapCenter({
          lat: sumLat / _points?.length,
          lng: sumLon / _points?.length,
        });

        // todo: zoom calculate base on points spread
        setZoom(20);
        setInfoWindowProxy(_points[0]);
      }
    });
  };

  const onPointNext = (point: ILocalPoint) => {
    if (!google || !points?.length) return;

    let i = points.findIndex((p) => point.id === p.id) + 1;
    if (i > points.length - 1) i = 0;

    setInfoWindowProxy(points[i]);
  };

  const onPointPrev = (point: ILocalPoint) => {
    if (!google || !points?.length) return;

    let i = points.findIndex((p) => point.id === p.id) - 1;
    if (i < 0) i = points.length - 1;

    setInfoWindowProxy(points[i]);
  };

  const setInfoWindowProxy = (point: ILocalPoint) => {
    if (!google) return;

    setInfoWindow({
      content: renderToStaticMarkup(
        <>
          <div>
            <strong>{getPointTypeName(point.pointType)}</strong>
            <div>{point.activityName ? `${point.activityName} - ` : ""}</div>
            <div>{dayjs(point.saveDate).format("YYYY-MM-DD HH:mm")}</div>
          </div>
          <div>
            <small>
              {point.lat}, {point.lon}
            </small>
          </div>
          <br />
          <Button id={"static-btn-next"} appearance={"subtle"}>
            Następny
          </Button>
          <Button id={"static-btn-prev"} appearance={"subtle"}>
            Poprzedni
          </Button>
        </>
      ),
      position: { lat: point.lat, lng: point.lon },
      pixelOffset: new google.maps.Size(0, -13),
      point: point,
    });
  };

  if (!google) return <Spinner />;
  return (
    <>
      <FlexRows>
        <Panel header={"Podgląd przebytej trasy raportującego"}>
          <Row>
            <Col xs={12}>
              <SelectPicker
                data={
                  form?.users.options.map((u) => ({
                    label: u.name,
                    value: u.id,
                  })) ?? []
                }
                onChange={(value) => {
                  setRequest({
                    ...request,
                    // @ts-ignore
                    userId: value,
                  });
                }}
                placeholder={"Wybierz raportującego"}
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={8}>
              <DatePicker
                style={{ width: "100%" }}
                isoWeek
                onChange={(value: Date | null) => {
                  const parsedVal = value ? value : null;
                  setRequest({
                    ...request,
                    dateFrom: parsedVal,
                    dateTo: parsedVal,
                  });
                }}
              />
            </Col>
            <Col xs={4}>
              <Button
                appearance={"primary"}
                disabled={!request.userId || !request.dateFrom}
                style={{ width: "100%" }}
                onClick={getPoints}>
                Wyświetl
              </Button>
            </Col>
          </Row>
          <div>
            {points !== null && points.length == 0 && (
              <>
                <SeparatorEmpty size={1} />
                <Message type={"warning"}>Brak wyników</Message>
              </>
            )}
          </div>
        </Panel>

        <Panel style={{ flexGrow: 1 }}>
          <Map
            center={mapCenter}
            allowPointsCluster={false}
            // @ts-ignore
            onPointClick={(p) => setInfoWindowProxy(p)}
            polylines={polylines ?? []}
            points={points ?? []}
            infoWindow={infoWindow}
            zoom={zoom}
          />
        </Panel>
      </FlexRows>
    </>
  );
};

export default Gps;
