import React, { FunctionComponent } from "react";
import DashboardVisitsStatusMap from "./components/DashboardVisitsStatusMap";
import DashboardVisitsList from "./components/DashboardVisitsList";
import SeparatorEmpty from "../../global/atoms/separators/SeparatorEmpty";

const Dashboard: FunctionComponent = () => {
  if (window.location.hostname === "mss.salescompanion.pl") {
    window.location.replace("/");
  }

  if (["test-mss.salescompanion.pl"].includes(window.location.hostname)) {
    return <></>;
  }

  return (
    <>
      <DashboardVisitsStatusMap />
      <SeparatorEmpty />
      <DashboardVisitsList />
    </>
  );
};

export default Dashboard;
