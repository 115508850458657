export const BOOLEAN_QUESTION = "BOOLEAN_QUESTION";
export const INTEGER_QUESTION = "INTEGER_QUESTION";
export const DECIMAL_QUESTION = "DECIMAL_QUESTION";
export const TEXT_QUESTION = "TEXT_QUESTION";
export const TEXTAREA_QUESTION = "TEXTAREA_QUESTION";
export const PHOTO_QUESTION = "PHOTO_QUESTION";
export const DICTIONARY_QUESTION = "DICTIONARY_QUESTION";
export const DICTIONARY_MULTIPLE_ANSWERS_QUESTION =
  "DICTIONARY_MULTIPLE_ANSWERS_QUESTION";
export const DATE_QUESTION = "DATE_QUESTION";
export const TIME_QUESTION = "TIME_QUESTION";
