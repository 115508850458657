import React, { CSSProperties, useEffect } from "react";
import "../../styles/rsuiteCustom.less";
import { default as HeaderComp } from "./components/Header";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../utils/ConfirmModal";
import LayoutPortal from "./components/LayoutPortal";
import Navigation from "./components/Navigation";
import { Container, Content } from "rsuite";
import { FCC } from "utils/models";

const Layout: FCC = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch({ type: "SET_FILTER", payload: null });
  }, [pathname]);

  const contentCss: CSSProperties = {
    overflowY: "scroll",
    padding: "20px",
  };

  return (
    <Container style={{ height: "100%" }}>
      <Navigation />
      <Container>
        <HeaderComp />
        <Content style={contentCss}>
          <div style={{ height: "100%" }}>{props.children}</div>
          <div id="modal-bin"></div>
          <ConfirmModal />
          <LayoutPortal />
        </Content>
      </Container>
    </Container>
  );
};

export default Layout;
