import React, { FunctionComponent, useEffect, useState } from "react";
import { IEditUser, IChangePass } from "utils/models";
import { editUserState, passChangeState } from "utils/states";
import UserConnection from "utils/connections/user";
import WhiteCard from "global/atoms/WhiteCard";
import { getUserFromSession, handleToast } from "utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import CustomRoles from "../customRoles/CustomRoles";
import Spinner from "global/atoms/Spinner/Spinner";
import EditUserCommonFields from "../usersAdd/EditUserCommonFields";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { confirmModalHandler } from "../../../../redux/store";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import Dropdown from "rsuite/Dropdown";
import SelectPicker from "rsuite/SelectPicker";
import TrashIcon from "@rsuite/icons/Trash";
import CopyIcon from "@rsuite/icons/Copy";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ToastNotification, {
  ToastTypes,
} from "../../../../global/ToastNotification";

interface IEditUserComponent {}

const EditUser: FunctionComponent<IEditUserComponent> = () => {
  const [data, setData] = useState<IEditUser>(editUserState);
  const [passData, setPassData] = useState<IChangePass>(passChangeState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const userData = getUserFromSession();
  const currentUserRole = userData?.user?.roles?.find(
    (r) => r.role.roleId == userData?.currentRoleId
  );
  const hierarchyLevel = currentUserRole?.role.hierarchyLevel;

  useEffect(() => {
    UserConnection.getFormEditUser(id)
      .then((data) => {
        setData({
          ...data.data.model.userDetails,
          id: data.data.model.id,
          firstName: data.data.model.firstName,
          lastName: data.data.model.lastName,
          username: data.data.model.username,
          userStatusId: data.data.model.userStatus.id,
          userWebReportingStatusId: data.data.model.userWebReportingStatus.id,
          roles: data.data.model.roles.map((item: any) => ({
            roleId: item.role.roleId,
            default: item.default,
          })),
          salePrefix: data.data.model.salePrefix,
          customerId:
            data.data.model.customer?.id !== undefined
              ? data.data.model.customer?.id
              : "",
          apkVersion: data.data.model.apkVersion,
          ipaVersion: data.data.model.ipaVersion,
        });
        setForm(data.data);
      })
      .catch((err) => {
        if (err.response.status == 403) {
          ToastNotification(
            ToastTypes.info,
            "",
            "Brak uprawnień do edycji użytkownika"
          );
        } else {
          ToastNotification(
            ToastTypes.error,
            "",
            `Server zwrócił nieoczekiwaną odpowiedź (ERR: ${err.response.status}), skontaktuj sie z administratorem`
          );
        }
        history.push("/admin/users");
      });
  }, []);
  const handleSubmit = () => {
      handleToast(UserConnection.editUser(id, data), setErrors).then(() =>
        history.push("/admin/users")
      );
    },
    handleSubmitPassChange = () => {
      handleToast(
        UserConnection.changePassword(id, passData),
        setErrors,
        undefined,
        "Hasło zostało zmienione"
      ).then(() => history.push("/admin/users"));
    };

  if (form === "loading") return <Spinner />;

  const handleRemoveToken = () => {
    handleToast(UserConnection.logoutUser(id));
  };

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<TrashIcon />}
            onClick={confirmModalHandler(
              "czy na pewno chcesz usunąć?",
              handleRemoveToken
            )}
            // disabled={!chosenVisits.length}
          >
            Usuń token urządzenia
          </Dropdown.Item>
          <Dropdown.Item
            icon={<CopyIcon />}
            onClick={() => history.push(`/admin/users/${id}/replace`)}
            // disabled={!chosenVisits.length}
          >
            Zamiana
          </Dropdown.Item>
        </OptionsDropdown>
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Form
          fluid
          onSubmit={handleSubmit}
          onChange={(value) => {
            setData({ ...data, ...value });
          }}>
          <EditUserCommonFields data={data} errors={errors} form={form} />
          <SeparatorEmpty />
          <Form.Group>
            <Form.ControlLabel>{'Wersja aplikacji "APK"'}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"wersja..."}
              errorMessage={errors?.apkVersion}
              cleanable={false}
              searchable={false}
              value={data.apkVersion ?? form.apkVersions.value}
              name={"apkVersion"}
              accepter={SelectPicker}
              data={form.apkVersions.options}
              valueKey={"id"}
              labelKey={"name"}
            />
          </Form.Group>
          <CustomRoles
            hierarchyLevel={hierarchyLevel ?? 0}
            errors={errors}
            state={data}
            setState={setData}
            options={form.roles.options}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      <WhiteCard style={{ marginTop: "24px" }} padding={true}>
        <Form
          fluid
          onSubmit={handleSubmitPassChange}
          onChange={(values) => {
            setPassData({ ...passData, ...values });
          }}>
          <Form.Group>
            <Form.ControlLabel>{"Nowe hasło"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"hasło..."}
              errorMessage={errors?.newPassword}
              type={"password"}
              value={passData.newPassword}
              name={"newPassword"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Powtórz nowe hasło"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"powtórz hasło..."}
              errorMessage={errors?.repeatNewPassword}
              type={"password"}
              value={passData.repeatNewPassword}
              name={"repeatNewPassword"}
              accepter={Input}
            />
          </Form.Group>
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default EditUser;
