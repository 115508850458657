import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { ICreateProduct, IGetProducts } from "../models";

export const createUpdateData = (data: ICreateProduct) => {
  return {
    ...data,
    ...{
      name: data.name,
      price: {
        amount: Number(data.amount),
        currency: data.currency,
      },
      dimension: {
        length: Number(data.length),
        width: Number(data.width),
        height: Number(data.height),
      },
    },
  };
};
const ProductsConnection = {
  getProducts: (data: IGetProducts) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createProduct: (data: ICreateProduct) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items`,
      createUpdateData(data),
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateProduct: (id: string, data: ICreateProduct) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items/${id}`,
      createUpdateData(data),
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFiltersProducts: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormAddProduct: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditProduct: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/items`,
      {
        headers: { ...getAuthHeader() },
        data: { items: data },
      }
    );
  },
};

export default ProductsConnection;
