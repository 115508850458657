import React, { FunctionComponent } from "react";
import CSS from "csstype";
import styles from "./atoms.module.scss";

interface ITextarea {
  style?: CSS.Properties;
  placeholder: string;
  name: string;
  onChange?: (event: any) => void;
  value: string;
  setState?: any;
  state?: any;
  errors?: any;
  max?: number;
  disabled?: boolean;
  debugData?: any;
  rows?: number;
}

const Textarea: FunctionComponent<ITextarea> = ({
  style,
  placeholder,
  value,
  onChange,
  name,
  setState,
  state,
  errors,
  max,
  disabled = false,
  debugData,
  rows,
}) => {
  const handleChange = (event: any) => {
    setState({ ...state, [name]: event.currentTarget.value });
  };

  return (
    <>
      <div
        className={styles.textareaContainer}
        style={{
          ...style,
          height: "80%",
        }}>
        <label htmlFor={name}>{placeholder}</label>
        <textarea
          style={{ color: disabled ? "silver" : "black" }}
          onChange={onChange ? onChange : handleChange}
          value={value}
          className={styles.textarea}
          name={name}
          maxLength={max}
          disabled={disabled}
          data-debug={debugData}
          rows={rows ?? 2}
        />
        {errors && (
          <span className={styles.errors} style={{ marginTop: "0px" }}>
            {errors[name]}
          </span>
        )}
      </div>
    </>
  );
};

export default Textarea;
