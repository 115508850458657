import React, { FunctionComponent, useRef } from "react";
import axios from "axios";
import { NotCancelErrorPromise, getAuthHeader } from "../../utils/helpers";
import { Button } from "rsuite";
import {
  ToastNotificationPush,
  ToastRequesterPush,
  ToastTypes,
} from "global/ToastNotification";
import { v4 as uuid } from "uuid";

interface ISingleFileUploadProps {
  saveUrl: string;
  savedFileCallback: any;
  label: string;
  disabled?: boolean;
  maxFileSizeMB?: number;
  handleError?: (e: any) => void;
}

const FileUpload: FunctionComponent<ISingleFileUploadProps> = ({
  saveUrl,
  savedFileCallback,
  label,
  disabled = false,
  maxFileSizeMB = -1, // no limit
  handleError,
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (event: any) => {
    for (const key in event.target.files) {
      const data = new FormData();
      const item = event.target.files[key];
      if (!(item instanceof File)) {
        continue;
      }

      if (!handleError) {
        handleError = (errs) => {
          console.warn(errs);
        };
      }

      data.append("file", item);
      const toastId = uuid();

      ToastRequesterPush(
        toastId,
        ToastTypes.loading,
        undefined,
        undefined,
        "Wysyłanie pliku"
      );
      axios
        .post(saveUrl, data, { headers: getAuthHeader() })
        .then((res) => {
          ToastRequesterPush(toastId, ToastTypes.success);
          savedFileCallback(res.data);
        }, NotCancelErrorPromise)
        .catch(() => {
          ToastNotificationPush(ToastTypes.error, "Błąd, nie przesłano pliku!");
          ToastRequesterPush(toastId, ToastTypes.error);
        });
    }
  };

  const handleSelectFilesTrigger = () => {
    // @ts-ignore: Object is possibly 'null'.
    inputFile?.current.click();
  };

  return (
    <>
      <input
        ref={inputFile}
        style={{ display: "none" }}
        type={"file"}
        multiple={true}
        onChange={handleFileUpload}
      />
      <Button
        appearance={"primary"}
        disabled={disabled}
        onClick={handleSelectFilesTrigger}>
        {label}
      </Button>
      {maxFileSizeMB != -1 && (
        <>
          &nbsp;<small>Maksymalny rozmiar pliku: {maxFileSizeMB}MB</small>
        </>
      )}
    </>
  );
};

export default FileUpload;
