import React from "react";
import { useSelector } from "react-redux";
import { IRoot } from "../../../redux/models";

const ContractProjectIdBreadcrumb: React.ComponentType = () => {
  const header = useSelector((state: IRoot) => state.header);
  return <strong>{header?.name ? header?.name : "..."}</strong>;
};

export default ContractProjectIdBreadcrumb;
