import React, { FunctionComponent, useEffect, useState } from "react";
import { IActivityAdd, IFormMaker } from "../../../../utils/models";
import ActivitiesConnection from "../../../../utils/connections/activities";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import {
  defaultStateMapper,
  formActionsMapper,
  formMapper,
} from "../../../../utils/helpers";
import { addActivityState } from "../../../../utils/states";
import { useHistory } from "react-router-dom";
import { SET_LOADING } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

interface IActivitiesAdd {}

const ActivitiesAdd: FunctionComponent<IActivitiesAdd> = () => {
  const [form, setForm] = useState<{
    header: { name: string; actions: Array<IFormMaker> };
    fields: Array<IFormMaker>;
    actions: Array<IFormMaker>;
  }>({ header: { name: "", actions: [] }, fields: [], actions: [] });
  const [data, setData] = useState<IActivityAdd>(addActivityState);
  const [error, setError] = useState<any | null>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: { isLoading: true } });
    Promise.all([
      ActivitiesConnection.getFormAddActivity("", "")
        .then((data) => {
          setForm(data.data);
          data.data?.header &&
            dispatch({ type: "SET_HEADER", payload: data.data.header });
          setData({
            ...addActivityState,
            ...defaultStateMapper(data.data.fields),
          });
        })
        .catch((err) => setError(err.response)),
    ])
      .then(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    dispatch({ type: SET_LOADING, payload: { isLoading: true } });
    Promise.all([
      ActivitiesConnection.createActivity(data).then(() =>
        history.push("/datasets/activity")
      ),
    ])
      .then(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      });
  };

  if (!form.fields.length) return null;

  return (
    <>
      <WhiteCard padding={true}>
        <form onSubmit={handleSubmit}>
          {form.fields.map((item) => formMapper(item, data, setData, error))}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gridColumnGap: "24px",
            }}>
            {form.actions.map((item) => formActionsMapper(item))}
          </div>
        </form>
      </WhiteCard>
    </>
  );
};

export default ActivitiesAdd;
