import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";
import React, { FunctionComponent } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { getProjectIdFromUrl } from "utils/helpers";

interface INoCustomTemplates {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NoCustomTemplates: FunctionComponent<INoCustomTemplates> = (props) => {
  const history = useHistory();
  const projectId = getProjectIdFromUrl();
  const { reportType } = useParams<{ reportType: string }>();

  if (projectId) {
    history.push(
      generatePath("/projects/:id/data-exports/:reportType", {
        id: projectId,
        reportType: reportType,
      })
    );
  }

  ToastNotificationPush(
    ToastTypes.info,
    "Brak możliwości utworzenia szablonu dla tego raportu."
  );
  return <></>;
};

export default NoCustomTemplates;
