import React, { FunctionComponent } from "react";
import { IGetProjectLocations } from "utils/models";
import { getProjectsLocationsState } from "utils/states";
import Locations from "../components/editProject/Locations";

interface IMyLocations {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyLocations: FunctionComponent<IMyLocations> = (props) => {
  const fixedFilters: IGetProjectLocations = {
    ...getProjectsLocationsState,
    // roleId: getUserFromSession()?.currentRoleId ?? '',
    // reportingUserId: getUserFromSession()?.userId ?? ''
  };

  const hiddenFilters: Array<string> = [
    // 'reportingUserId',
    // 'roleId'
  ];

  const locationUrl = "/projects/:id/locations/:locationId";

  return (
    <Locations
      fixedFilters={fixedFilters}
      hiddenFilters={hiddenFilters}
      filterStorageKey={`myLocationsFilter_${fixedFilters.reportingUserId}_${fixedFilters.roleId}`}
      locationUrl={locationUrl}
    />
  );
};

export default MyLocations;
