import React from "react";
import { Message, toaster } from "rsuite";
import store from "redux/store";
import { CANCEL_TOAST, SET_TOAST } from "redux/actions";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";

export enum ToastTypes {
  loading = "loading",
  info = "info",
  success = "success",
  error = "error",
  warning = "warning",
}

// generic use configurable notification toast
export const ToastNotificationPush = (
  type: ToastTypes,
  header?: string,
  message?: string,
  duration = 3000
): void => {
  toaster.push(
    <Message
      showIcon
      header={header ?? ""}
      type={type === ToastTypes.loading ? ToastTypes.info : type}
      style={{ zIndex: 1001, minWidth: "200px" }}
      closable>
      {message ?? ""}
    </Message>,
    {
      duration: duration,
      placement: type === ToastTypes.error ? "topCenter" : "bottomEnd",
    }
  );
};

// next toast will override previous one, pre-set icons and header (default use case, 1. processing, 2. result - success / error)
export const ToastRequesterPush = (
  id: string,
  type: ToastTypes,
  message?: string,
  duration?: number,
  customHeader?: string
) => {
  let placement: PlacementType = "bottomEnd";
  let _header = "";

  if (!customHeader) {
    if (type === ToastTypes.success) _header = "Sukces!";
    //else if (type === ToastTypes.error) _header = "Błąd!";
    else if (type === ToastTypes.loading) _header = "Przetwarzanie...";
  } else _header = customHeader;

  if (!duration) {
    if ([ToastTypes.error, ToastTypes.loading].includes(type)) duration = 0;
    if (type === ToastTypes.success) duration = 1500;
    else duration = 3000;
  }

  if (type === ToastTypes.error) {
    placement = "topCenter";
  }
  //TODO
  // implementation in ToastRequesterRedux.tsx
  if (type !== ToastTypes.error) {
    store.dispatch({
      type: SET_TOAST,
      payload: {
        id: id,
        header: _header,
        type: type,
        duration: duration,
        message: message,
        placement: placement,
      },
    });
  }
};

export const ToastRequesterCancelled = (id: string) => {
  store.dispatch({
    type: CANCEL_TOAST,
    payload: id,
  });
};

export default ToastNotificationPush;
