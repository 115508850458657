import React, { CSSProperties, FunctionComponent } from "react";

interface IPaginatedTableViewWrapper {
  table: JSX.Element;
  pagination: JSX.Element;
  beforeTableElements?: JSX.Element;
  decreaseContainerSizePx?: number;
}

// display table and pagination consuming entire content container, table must have fillHeight attr
const PaginatedTableViewWrapper: FunctionComponent<
  IPaginatedTableViewWrapper
> = (props) => {
  const _style: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: props.decreaseContainerSizePx
      ? "calc(100% - " + props.decreaseContainerSizePx + "px)"
      : "100%",
  };

  return (
    <div style={_style}>
      {props.beforeTableElements && <div>{props.beforeTableElements}</div>}
      <div style={{ flexGrow: 1 }}>{props.table}</div>
      <div>{props.pagination}</div>
    </div>
  );
};

export default PaginatedTableViewWrapper;
