import ActionsContainer from "global/atoms/ActionsContainer";
import LocalDatePicker from "global/atoms/LocalDatePicker";
import Select from "global/atoms/Select";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, IconButton, Row } from "rsuite";
import ReportTemplateConnection from "utils/connections/reportTemplate";
import { getProjectIdFromUrl } from "utils/helpers";
import { ISelect, SetState } from "utils/models";
import { IReportGenerateData } from "../Reports";
import EditIcon from "@rsuite/icons/Edit";

interface IGeneralReportFilter {
  handleSubmit: () => void;
  state: IReportGenerateData;
  setState: SetState<IReportGenerateData>;
}

const GeneralReportFilter: FunctionComponent<IGeneralReportFilter> = (
  props
) => {
  const history = useHistory();
  const [themes, setThemes] = useState<ISelect | null>(null);

  useEffect(() => {
    ReportTemplateConnection.getReportOptions(props.state.type).then((res) => {
      const tmp = res.data.templates;
      if (_.isEmpty(tmp.options)) {
        tmp.options = [{ id: "", name: "Szablon systemowy" }];
      }
      props.setState((s) => ({ ...s, templateId: tmp.options[0]?.id }));
      setThemes(tmp);
    });
  }, [props.state.type]);

  const handleEditTemplate = () => {
    history.push(
      `/projects/${getProjectIdFromUrl()}/report-template/${
        props.state.type
      }/edit/${props.state.templateId}`
    );
  };
  const handleAddTemplate = () => {
    history.push(
      `/projects/${getProjectIdFromUrl()}/report-template/${
        props.state.type
      }/add`
    );
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}>
        <Col xs={6}>
          <Select
            key={"template_" + (props.state.type ?? "none")}
            placeholder={
              !_.isEmpty(themes?.options)
                ? "Wybierz szablon"
                : "Brak szablonów - dodaj nowy"
            }
            name={"templateId"}
            value={props.state.templateId}
            options={themes?.options ?? []}
            state={props.state}
            setState={props.setState}
          />
        </Col>
        <Col xs={6}>
          <ActionsContainer>
            <IconButton
              icon={<EditIcon />}
              appearance={"ghost"}
              onClick={handleEditTemplate}
              disabled={!props.state.templateId}>
              Edytuj
            </IconButton>
            <Button appearance={"ghost"} block onClick={handleAddTemplate}>
              Dodaj nowy
            </Button>
          </ActionsContainer>
        </Col>
      </Row>
      <SeparatorEmpty size={1.5} />
      <Row>
        <Col xs={6}>
          <LocalDatePicker
            label={"Data rozpoczęcia"}
            state={props.state}
            stateKey={"startDate"}
            emptyValue={""}
            placement={"bottom"}
            outputFormat={"yyyy-MM-dd"}
            setState={props.setState}
          />
        </Col>
        <Col xs={6}>
          <LocalDatePicker
            label={"Data zakończenia"}
            state={props.state}
            stateKey={"endDate"}
            emptyValue={""}
            placement={"bottom"}
            outputFormat={"yyyy-MM-dd"}
            setState={props.setState}
          />
        </Col>
      </Row>
      <SeparatorEmpty size={1.5} />
      <Row
        style={{
          display: "flex",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}>
        <Col xs={6}>
          <Select
            placeholder={"Format"}
            name={"format"}
            value={props.state.format}
            options={[
              { id: "xlsx", name: "xlsx" },
              { id: "csv", name: "csv" },
            ]}
            setState={props.setState}
            state={props.state}
          />
        </Col>
        <Col xs={6}>
          <Button appearance={"primary"} block onClick={props.handleSubmit}>
            Wygeneruj raport
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralReportFilter;
