import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateBrand } from "utils/models";
import { createBrandState } from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import BrandsConnection from "utils/connections/brands";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import Form from "global/form/Form";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Input from "global/atoms/Input";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";

interface IEditBrand {}

const EditBrand: FunctionComponent<IEditBrand> = () => {
  const [data, setData] = useState<ICreateBrand>(createBrandState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    BrandsConnection.getFormEditBrand(id).then((data) => {
      setData({ name: data.data.model.name });
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(BrandsConnection.updateBrand(id, data), setErrors).then(() =>
      history.push("/products/brand")
    );
  };

  if (form === "loading") return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Nazwa"}
            name={"name"}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default EditBrand;
