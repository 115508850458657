import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Message, Modal } from "rsuite";
import ActivitiesConnection from "utils/connections/activities";
import { deepClone, handleToast } from "utils/helpers";

import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import {
  defaultRequestData,
  optionsTask,
  renderOptions,
  requestDataSetOptionsChange,
} from "./CloneModalHelper";

export interface ICloneActivityModal {
  activityId: string;
  projectId: string;
  taskId: string;
  open: boolean;
  handleClose: (reload: boolean) => void;
  activityName?: string;
}

export default function CloneActivityModal(props: ICloneActivityModal) {
  const options = optionsTask;
  const [requestData, setRequestData] = useState(defaultRequestData(options));

  useEffect(() => {
    setRequestData(defaultRequestData(options));
  }, [props.open]);

  const requestDataChange = (propName: string, checked: boolean, option) => {
    let s = deepClone(requestData);
    s = {
      ...s,
      ...requestDataSetOptionsChange(propName, checked, option, s, options),
    };
    s = { ...s, [propName]: checked };
    setRequestData((d) => ({ ...d, ...s }));
  };

  const onSubmit = () => {
    handleToast(
      ActivitiesConnection.cloneActivity(
        props.projectId,
        props.taskId,
        props.activityId,
        requestData
      )
    ).then(() => {
      props.handleClose(true);
    });
  };

  return (
    <Modal
      size={"lg"}
      open={props.open}
      onClose={props.handleClose.bind(null, false)}>
      <Modal.Header>
        <Modal.Title>
          <div>
            <div>
              Kopiowanie zadania: <strong>{props?.activityName}</strong>
            </div>
            <SeparatorEmpty />
            <Message type={"warning"} showIcon>
              Skopiowane zostaną wybrane elementy aktywności.
            </Message>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderOptions(options, requestData, requestDataChange)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.handleClose(false)} appearance="ghost">
          Anuluj
        </Button>
        <Button onClick={onSubmit} appearance="primary">
          Utwórz kopie
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
