import React, { FunctionComponent, useEffect, useState } from "react";
import { notificationState } from "../../../../utils/states";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { handleToast } from "../../../../utils/helpers";
import NotificationsConnection from "../../../../utils/connections/notifications";
import { ICreateNotification, INotification } from "../../../../utils/models";
import { useDispatch } from "react-redux";
import NotificationForm, { INotificationForm } from "./NotificationForm";

const CreateNotification: FunctionComponent<ICreateNotification> = () => {
  const [notification, setNotification] =
    useState<INotification>(notificationState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<INotificationForm | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    NotificationsConnection.getForm().then((response) => {
      const _data = response.data;

      _data?.header && dispatch({ type: "SET_HEADER", payload: _data.header });

      notification.sourceId = _data.model.sourceId ?? "";
      notification.sourceType = _data.model.sourceType ?? "";

      if (notification.sendChannel == "") {
        // set default
        notification.sendChannel = _data.sendChannels.options[0].id;
      }

      // _data.roles.options = _data.roles.options.map((o:any) => ({id: o.roleId, name: o.roleName}));

      setNotification(notification);
      setForm((d) => ({ ...d, ..._data }));
    });
  }, []);

  const handleSubmit = (
    notification: INotification,
    assignedUsers: Array<string>
  ) => {
    const resultData: ICreateNotification = {
      ...notification,
      users: [],
    };

    if (assignedUsers.length) {
      resultData.users = assignedUsers;
    }

    handleToast(
      NotificationsConnection.createNotification(resultData),
      setErrors
    ).then(() => history.push("/notifications"));
  };

  if (form === null) return <Spinner />;

  return (
    <NotificationForm
      handleSubmit={handleSubmit}
      form={form}
      setForm={setForm}
      errors={errors}
      notification={notification}
      onlyUsersFromLocations={form?.model?.onlyUsersFromLocations ?? false}
    />
  );
};

export default CreateNotification;
