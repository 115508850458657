import React, { FunctionComponent, useRef, useState } from "react";
import UserConnection from "utils/connections/user";
import Pagination from "global/pagination/Pagination";
import { IGetUsers, IUsers } from "utils/models";
import { getUsersState } from "utils/states";
import Table from "rsuite/Table";
import styles from "./styles.module.scss";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import {
  deepClone,
  handleSortColumn,
  handleToast,
} from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { Dropdown } from "rsuite";
import UsersAssignMobileVersion from "./UsersAssignMobileVersion";

interface IUsersList {}

const UsersList: FunctionComponent<IUsersList> = () => {
  const [data, setData] = useState<IUsers | null>(null);
  const defaultFilters = deepClone(getUsersState);
  const [filters, setFilters] = useState<IGetUsers>(defaultFilters);
  const ref = useRef(null);
  const [selected, setSelected] = useState<Array<string>>([]);

  const [loading, setLoading] = useState(true);
  const [assignMobileVersionModalOpen, setAssignMobileVersionModalOpen] =
    useState(false);
  const [triggerLoad, setTriggerLoad] = useState(0);

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <HeaderButtons>
        <NavOptionsWrapper
          addPath={"/admin/users/add"}
          deleteSelected={{
            chosenToDelete: selected,
            promiseFunctionDelete: UserConnection.batchDelete,
            callback: () => {
              UserConnection.getUsers(filters).then((data) => {
                setData(data?.data);
              });
              setSelected([]);
            },
          }}>
          <Dropdown.Item
            disabled={!selected.length}
            onClick={() => {
              setAssignMobileVersionModalOpen(true);
            }}>
            Przypisz wersje aplikacji mobilnej ({selected.length})
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={70} align="center">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={selected}
                  setState={setSelected}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={selected}
                    setState={setSelected}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={200} align="left" sortable>
              <Table.HeaderCell depth={0} width={0}>
                Użytkownik
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="username">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/admin/users/${rowData.id}/edit`}>
                    {rowData.lastName} {rowData.firstName} ({rowData.username})
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              flexGrow={2}
              verticalAlign={"middle"}
              minWidth={250}
              align="left">
              <Table.HeaderCell depth={0} width={0}>
                Grupy
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => {
                  return (
                    <>
                      {rowData.roles.map((item: any) => {
                        const classNames = item.default
                          ? styles.roleDefault
                          : styles.role;
                        return (
                          <span key={item.role.roleId} className={classNames}>
                            {item.role.roleName}
                          </span>
                        );
                      })}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>{" "}
            <Table.Column
              verticalAlign={"middle"}
              resizable={true}
              width={120}
              align="left"
              sortable>
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0} dataKey="customerName">
                {(rowData) => {
                  return <span>{rowData.customer.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              resizable={true}
              width={150}
              align="right"
              sortable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="userStatus">
                {(rowData) => {
                  return (
                    <span
                      className={styles.status}
                      style={{
                        color: rowData.userStatus.color,
                        borderColor: rowData.userStatus.color,
                      }}>
                      {rowData.userStatus.name}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column resizable={true} width={150} sortable align={"right"}>
              <Table.HeaderCell>APK</Table.HeaderCell>
              <Table.Cell dataKey="apkVersion" />
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UsersAssignMobileVersion
        open={assignMobileVersionModalOpen}
        onClose={() => setAssignMobileVersionModalOpen(false)}
        onSubmit={(versionId) => {
          setAssignMobileVersionModalOpen(false);
          handleToast(
            UserConnection.setUserBatchMobileAppVersion({
              versionId: versionId,
              userIds: selected,
            })
          ).then(() => {
            setTriggerLoad(Date.now());
          });
        }}
      />

      <UniversalFilters
        load={UserConnection.getUsers}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        triggerLoad={triggerLoad}
        formGet={UserConnection.getFormFilters}
        filterStorageKey={"UsersList"}
        elements={[
          {
            label: "Użytkownik",
            stateKey: "username",
            type: FILTER_INPUT_TEXT,
          },
          {
            label: "Email",
            stateKey: "email",
            type: FILTER_INPUT_TEXT,
          },
          {
            label: "Status",
            stateKey: "userStatusId",
            type: FILTER_SELECT,
            formKey: "userStatuses",
          },
          {
            label: "Grupa",
            stateKey: "roleId",
            type: FILTER_SELECT,
            formKey: "roles",
          },
          {
            label: "Raportowanie www",
            stateKey: "userWebReportingStatusId",
            type: FILTER_SELECT,
            formKey: "userWebReportingStatuses",
          },
          {
            label: "Wersja APK",
            stateKey: "apkVersion",
            type: FILTER_SELECT,
            formKey: "apkVersions",
          },
        ]}
      />
    </div>
  );
};

export default UsersList;
