import dayjs from "dayjs";
import UniversalModal from "global/atoms/UniversalModal";
import React, { FunctionComponent, useState } from "react";
import { DatePicker } from "rsuite";

interface IMoveAllModal {
  date: dayjs.Dayjs;
  onClose: () => void;
  onSubmit: (date: dayjs.Dayjs) => void;
}

const MoveAllModal: FunctionComponent<IMoveAllModal> = (props) => {
  const [dateTo, setDateTo] = useState<Date>(null);

  const getBody = () => {
    return (
      <div>
        <DatePicker
          cleanable={false}
          oneTap
          block
          value={dateTo ?? undefined}
          onChange={(date: Date) => setDateTo(date)}
          disabledDate={(date: Date) => {
            return dayjs(date).isBefore("today");
          }}
        />
        <br />
      </div>
    );
  };
  return (
    <UniversalModal
      size={"xs"}
      onClose={props.onClose}
      onSubmit={() => props.onSubmit(dayjs(dateTo))}
      title={`Przesuń wszystkie z ${props.date.format("YYYY-MM-DD")} na:`}
      body={getBody()}
      open={true}
    />
  );
};

export default MoveAllModal;
