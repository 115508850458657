import React, { FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "rsuite";
import { FormElementBase } from "utils/FormElement";

interface IButtonAddNew {
  redirectUrl?: string;
  form?: any;
}

const ButtonAddNew: FunctionComponent<IButtonAddNew> = ({
  redirectUrl,
  form,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleAddItem = () => {
    const url = redirectUrl !== undefined ? redirectUrl : `${pathname}/add`;
    history.push(url);
  };

  return (
    <Button
      appearance={"primary"}
      disabled={form?.disabledElements?.includes(FormElementBase.addNewButton)}
      onClick={handleAddItem}>
      Dodaj nowy
    </Button>
  );
};

export default ButtonAddNew;
