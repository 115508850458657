import React, { FunctionComponent, useState } from "react";
import { Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters from "../../../../global/filters/UniversalFilters";
import LogsConnection from "../../../../utils/connections/logs";
import {
  IBodyResponse,
  ILogForm,
  ILogRequestData,
} from "../../../../utils/models";
import { jsonButton } from "./LogsList";
import { handleSortColumn } from "../../../../utils/helpers";
import { _paginate30 } from "utils/states";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ILogsWebList {}

interface ILogRow {
  callType: string;
  date: string;
  eventType: string;
  metaData: string;
  platformName: string;
  serialize: string;
  sourceName: string;
  userId: string;
  userName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogsWebList: FunctionComponent<ILogsWebList> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<IBodyResponse<ILogRow>>({
    count: 0,
    data: [],
  });
  const [form, setForm] = useState<ILogForm | null>(null);

  const defaultRequestData: ILogRequestData = {
    userId: "",
    callType: "",
    sourceName: "",
    eventType: "",
    dateFrom: 0,
    dateTo: 0,
    ..._paginate30,
  };
  const [requestData, setRequestData] =
    useState<ILogRequestData>(defaultRequestData);

  return (
    <>
      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={logData.data}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey={"date"} />
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Zdarzenie</Table.HeaderCell>
              <Table.Cell dataKey="eventTypeName">
                {(rowData: ILogRow) => {
                  return (
                    <>
                      {form?.events?.options?.find(
                        (eo) => eo.id === rowData.eventType
                      )?.name ?? rowData.eventType}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Moduł (pochodzenie)</Table.HeaderCell>
              <Table.Cell dataKey="sourceName">
                {(rowData: ILogRow) => {
                  return (
                    <>
                      {form?.sources?.options?.find(
                        (eo) => eo.id === rowData.sourceName
                      )?.name ?? rowData.sourceName}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Wykonał</Table.HeaderCell>
              <Table.Cell dataKey={"userName"} />
            </Table.Column>

            <Table.Column flexGrow={1} align={"right"}>
              <Table.HeaderCell>Model</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) => jsonButton(rowData.serialize)}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align={"right"}>
              <Table.HeaderCell>Meta dane</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) => jsonButton(rowData.metaData)}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={logData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={LogsConnection.list}
        formGet={LogsConnection.listForm}
        getFormCallback={(_form) => setForm(_form)}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setLogData}
        filterStorageKey={"logsList"}
        elements={[
          {
            type: "select",
            label: "Użytkownik",
            stateKey: "userId",
            formKey: "users",
          },
          {
            type: "select",
            label: "Zdarzenie",
            stateKey: "eventType",
            formKey: "events",
          },
          {
            type: "select",
            label: "Źródło",
            stateKey: "sourceName",
            formKey: "sources",
          },
          {
            type: "input_text",
            label: "Meta data",
            stateKey: "metaData",
          },
          {
            type: "input_date",
            label: "Data od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
          {
            type: "input_date",
            label: "Data do",
            stateKey: "dateTo",
            outputFormat: "phpTimestamp",
          },
        ]}
      />
    </>
  );
};

export default LogsWebList;
