import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import GlobalReportsConnection from "utils/connections/globalReportsConnection";
import { ISelect } from "utils/models";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import PlusIcon from "@rsuite/icons/Plus";
import {
  PhotoReportFormFields,
  IGeneratePhotoReportState,
} from "./PhotoReport";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  IconButton,
  Placeholder,
  Schema,
  SelectPicker,
  TagPicker,
} from "rsuite";
import _ from "lodash";

interface IDrawerNewPhotoReport {
  onSubmit: (state: IGeneratePhotoReportState) => void;
}

interface ISelectFilterable {
  id: string;
  name: string;
  filteredOut: boolean;
}

interface IForm {
  templates: ISelect<ISelectFilterable>;
  customers: ISelect<ISelectFilterable>;
  projects: ISelect<ISelectFilterable>;
  networks: ISelect<ISelectFilterable>;
  zwo: ISelect<ISelectFilterable>;
  merch: ISelect<ISelectFilterable>;
  sv: ISelect<ISelectFilterable>;
}

const DrawerNewPhotoReport: FunctionComponent<IDrawerNewPhotoReport> = (
  props
) => {
  const stateDefault: IGeneratePhotoReportState = {
    templateOptions: [],
    templateId: null,
    customerId: null,
    projects: [],
    userRoles: null,
    startDate: null,
    endDate: null,
    networkId: null,
    projectId: null,
    format: "xlsx",
    type: 18,
  };
  const [state, setState] = useState<IGeneratePhotoReportState>(stateDefault);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [form, setForm] = useState<null | IForm>(null);
  const [submitColor, setSubmitColor] = useState<
    | undefined
    | "red"
    | "orange"
    | "yellow"
    | "green"
    | "cyan"
    | "blue"
    | "violet"
  >(undefined);

  useEffect(() => {
    if (drawerOpen) {
      Promise.all([
        GlobalReportsConnection.photoReportTemplates(),
        GlobalReportsConnection.photoReportForm(),
      ]).then((res) => {
        setForm({
          ...res[1].data,
          templates: res[0].data.templates,
        });
      });
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (!form) return;

    const formClone = _.cloneDeep(form);
    const filteredOutMapper = (v: ISelectFilterable) => ({
      ...v,
      filteredOut: false,
    });
    formClone.projects.options =
      formClone.projects.options.map(filteredOutMapper);
    formClone.networks.options =
      formClone.networks.options.map(filteredOutMapper);

    if (state.customerId) {
      formClone.projects.options = formClone.projects.options.map((p) => ({
        ...p,
        // @ts-ignore
        filteredOut: p.customer_id !== state.customerId,
      }));
    }

    setForm(formClone);
  }, [state]);

  const onChange = (formValue: any) => {
    setState((s) => ({
      ...s,
      ...formValue,
    }));
  };

  const fieldRequired = Schema.Types.StringType().isRequired(
    "To pole jest wymagane"
  );
  const formModel = Schema.Model({
    [PhotoReportFormFields.templateId]: fieldRequired,
    // [PhotoReportFormFields.customerId]: fieldRequired,
    // [PhotoReportFormFields.projectId]: fieldRequired,
    // [PhotoReportFormFields.networkId]: fieldRequired,
  });
  const submitRef = useRef();

  const onSubmit = (checkStatus: boolean) => {
    if (!checkStatus) {
      setSubmitColor("red");
      setTimeout(() => setSubmitColor(undefined), 1000);
    } else {
      const clonedState = _.cloneDeep(state);
      if (clonedState.userRoles === null) clonedState.userRoles = [];
      props.onSubmit(clonedState);
      setDrawerOpen(false);
      setState(stateDefault);
    }
  };

  const FormGroupPicker = (props: {
    field: PhotoReportFormFields;
    data: ISelect<ISelectFilterable>;
    label: string;
    accepter?: any;
  }) => {
    const _accepter = props.accepter ?? SelectPicker;
    return (
      <Form.Group>
        <Form.ControlLabel>{props.label}</Form.ControlLabel>
        <Form.Control
          name={props.field}
          accepter={_accepter}
          placeholder={"Wybierz"}
          data={props.data.options.filter((v) => !v.filteredOut)}
          style={{ minWidth: "300px" }}
          labelKey={"name"}
          valueKey={"id"}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <HeaderButtons>
        <IconButton
          icon={<PlusIcon />}
          appearance={"ghost"}
          onClick={() => setDrawerOpen(true)}>
          Nowy raport
        </IconButton>
      </HeaderButtons>

      <Drawer
        placement={"right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Generuj raport zdjęciowy</Drawer.Title>
          <Drawer.Actions>
            <Button
              onClick={() => {
                setDrawerOpen(false);
                setState(stateDefault);
              }}>
              Anuluj
            </Button>
            <Button
              onClick={() => {
                if (submitRef && submitRef.current) {
                  // @ts-ignore
                  submitRef.current.click();
                }
              }}
              appearance="primary"
              color={submitColor}>
              Generuj
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {form === null ? (
            <Placeholder.Paragraph
              style={{ marginTop: 10, marginLeft: 60 }}
              rows={5}
            />
          ) : (
            // @ts-ignore
            <Form
              model={formModel}
              onSubmit={onSubmit}
              onChange={onChange}
              formValue={state}>
              {/* @ts-ignore */}
              <Button
                style={{ display: "none" }}
                type="submit"
                ref={submitRef}
              />

              <FormGroupPicker
                field={PhotoReportFormFields.templateId}
                data={form.templates}
                label={"Szablon"}
              />
              <hr />
              <Form.Group>
                <Form.ControlLabel>{"Data do"}</Form.ControlLabel>
                <Form.Control
                  name={PhotoReportFormFields.startDate}
                  accepter={DatePicker}
                  placeholder={"Wybierz"}
                  style={{ minWidth: "300px" }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>{"Data do"}</Form.ControlLabel>
                <Form.Control
                  name={PhotoReportFormFields.endDate}
                  accepter={DatePicker}
                  placeholder={"Wybierz"}
                  style={{ minWidth: "300px" }}
                />
              </Form.Group>
              <hr />
              <FormGroupPicker
                field={PhotoReportFormFields.customerId}
                data={form.customers}
                label={"Klient"}
              />
              <FormGroupPicker
                field={PhotoReportFormFields.projectId}
                data={form.projects}
                label={"Projekt"}
              />
              <FormGroupPicker
                field={PhotoReportFormFields.networkId}
                data={form.networks}
                label={"Sieci"}
              />
              <hr />
              <Form.Group>Wybierz uzytkownikow</Form.Group>
              <div style={{ paddingLeft: 0 }}>
                <FormGroupPicker
                  field={PhotoReportFormFields.userRoles}
                  data={form.zwo}
                  label={"ZWO"}
                  accepter={TagPicker}
                />
                <FormGroupPicker
                  field={PhotoReportFormFields.userRoles}
                  data={form.merch}
                  label={"MERCH"}
                  accepter={TagPicker}
                />
                <FormGroupPicker
                  field={PhotoReportFormFields.userRoles}
                  data={form.sv}
                  label={"SV"}
                  accepter={TagPicker}
                />
              </div>
            </Form>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default DrawerNewPhotoReport;
