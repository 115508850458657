import React, { FunctionComponent } from "react";
import { Col, Input, Row, SelectPicker } from "rsuite";
import Form from "rsuite/Form";

interface IEditUserCommonFieldsProps {
  data: any;
  errors: any;
  form: any;
}

const EditUserCommonFields: FunctionComponent<IEditUserCommonFieldsProps> = ({
  data,
  errors,
  form,
}) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.ControlLabel>{"Imię"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"imię..."}
              errorMessage={errors?.firstName}
              value={data.firstName}
              name={"firstName"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Nazwisko"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"nazwisko..."}
              errorMessage={errors?.lastName}
              value={data.lastName}
              name={"lastName"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Login"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"login..."}
              errorMessage={errors?.username}
              value={data.username}
              name={"username"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"E-mail"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"e-mail..."}
              errorMessage={errors?.email}
              value={data.email}
              name={"email"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Telefon"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"telefon..."}
              errorMessage={errors?.phone}
              value={data.phone}
              name={"phone"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              {"Prefix dokumentów projektu"}
            </Form.ControlLabel>
            <Form.Control
              block
              placeholder={"prefix..."}
              errorMessage={errors?.salePrefix}
              cleanable={false}
              disabled={data.id}
              value={data.salePrefix}
              name={"salePrefix"}
              accepter={Input}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group>
            <Form.ControlLabel>{"Status"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"status..."}
              errorMessage={errors?.userStatusId}
              cleanable={false}
              value={data.userStatusId}
              name={"userStatusId"}
              accepter={SelectPicker}
              data={form.userStatuses.options}
              valueKey={"id"}
              labelKey={"name"}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Klient"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"status..."}
              errorMessage={errors?.customerId}
              cleanable={false}
              value={data.customerId}
              name={"customerId"}
              accepter={SelectPicker}
              data={form.customers.options}
              valueKey={"id"}
              labelKey={"name"}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Pesel"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"pesel..."}
              errorMessage={errors?.pesel}
              value={data.pesel}
              name={"pesel"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Dowód osobisty"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"dowód osobisty..."}
              errorMessage={errors?.idCard}
              value={data.idCard}
              name={"idCard"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              {"Raportowanie przez platformę web"}
            </Form.ControlLabel>
            <Form.Control
              block
              placeholder={"wybierz..."}
              errorMessage={errors?.userWebReportingStatusId}
              cleanable={false}
              value={data.userWebReportingStatusId}
              name={"userWebReportingStatusId"}
              accepter={SelectPicker}
              data={form.userWebReportingStatuses.options}
              valueKey={"id"}
              labelKey={"name"}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default EditUserCommonFields;
