// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".optcI17zK1qMaRFu6YW1{text-orientation:mixed;writing-mode:vertical-rl;cursor:help}.srLMsT4XZI7DcLifInp3{height:254px;overflow-y:auto;-ms-overflow-style:none;scrollbar-width:none}.srLMsT4XZI7DcLifInp3::-webkit-scrollbar{display:none}", "",{"version":3,"sources":["webpack://./src/views/projects/components/taskActivitiesEdit/listing/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,wBAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,uBAAA,CACA,oBAAA,CAEF,yCACE,YAAA","sourcesContent":[".verticalText {\n  text-orientation: mixed;\n  writing-mode: vertical-rl;\n  cursor: help;\n}\n\n.entityHeader {\n  height: 254px;\n  overflow-y: auto;\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n  scrollbar-width: none; /* Firefox */\n}\n.entityHeader::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verticalText": "optcI17zK1qMaRFu6YW1",
	"entityHeader": "srLMsT4XZI7DcLifInp3"
};
export default ___CSS_LOADER_EXPORT___;
