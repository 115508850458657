import { Popover, Whisper } from "rsuite";
import React, { FunctionComponent } from "react";

interface IPictureData {
  photoData: string;
}
const ToolTipPhoto: FunctionComponent<IPictureData> = ({ photoData }) => {
  const photoSrc = () => {
    return isPhotoSrc() ? photoData : "/jpeg/placeholder.jpeg";
  };
  const isPhotoSrc = () => {
    return photoData?.startsWith("data");
  };
  const photo = () => {
    return (
      <Popover
        full
        arrow={false}
        style={{
          borderRadius: "5px",
          backgroundColor: "#ffffff",
          color: "#afafaf",
          border: "1px solid #afafaf",
          boxShadow: "1px 2px 15px silver",
          padding: "0px",
        }}>
        {isPhotoSrc() ? (
          <img
            style={{
              borderRadius: "5px",
              maxWidth: "300px",
              maxHeight: "300px",
            }}
            src={photoSrc().toString()}
            alt=""
          />
        ) : (
          <span style={{ padding: "10px" }}>Brak zdjęcia</span>
        )}
      </Popover>
    );
  };

  return (
    <Whisper
      placement={"right"}
      controlId="control-id-hover"
      trigger="hover"
      enterable
      speaker={photo()}>
      <img
        id={"control-id-hover"}
        style={{ width: "30px", height: "30px" }}
        src={photoSrc()}
        alt=""
      />
    </Whisper>
  );
};
export default ToolTipPhoto;
