import { AxiosManager, HttpMethod, default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import {
  IChangeOwnPass,
  IChangePass,
  ICreateUser,
  IEditUser,
  IGetUsers,
} from "../models";
import { AxiosPromise } from "axios";

interface ILogin {
  username: string;
  password: string;
  from: string;
}

const UserConnection = {
  login: (data: ILogin) => {
    return axios.post(`${getBaseApiUrl()}/login`, data);
  },
  logout: () => {
    return axios.delete(`${getBaseApiUrl()}/logout`, {
      headers: getAuthHeader(),
    });
  },
  createUser: (data: ICreateUser) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  editUser: (id: string, data: IEditUser) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${id}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  changePassword: (id: string, data: IChangePass) => {
    return axios.put(`${getBaseApiUrl()}/user/change-pass/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  changeOwnPassword: (data: IChangeOwnPass) => {
    return axios.put(`${getBaseApiUrl()}/user/change-pass/me`, data, {
      headers: getAuthHeader(),
    });
  },
  addRoles: (userId: string, data: { roles: Array<{ roleId: string }> }) => {
    return axios.patch(
      `${getBaseApiUrl()}/organizations/users/${userId}/roles/assign`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getRoles: (userId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${userId}/roles`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getUsers: (data: IGetUsers) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/list`,
      data,
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  getFormCreateUser: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormEditUser: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormChangePassword: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/password/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormFilters: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  switchRole: (data: { roleId: string }) => {
    return axios.post(`${getBaseApiUrl()}/session/switch-role`, data, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users`,
      {
        headers: { ...getAuthHeader() },
        data: { users: data },
      }
    );
  },
  logoutUser: (userId: string): AxiosPromise<any> => {
    return axios.delete(`${getBaseApiUrl()}/users/${userId}/logout/device`);
  },

  getFormReplacementUser: (userId: string): AxiosPromise<any> => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${userId}/replace/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getUserReplacementList: (
    userRoleId: string,
    data: any
  ): AxiosPromise<any> => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${userRoleId}/replace/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getUserReplacementFilterForm: (userRoleId: string): AxiosPromise<any> => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/${userRoleId}/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  changeUserAssigment: (data: any): AxiosPromise<any> => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/replace`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getMobileAppVersions: () =>
    AxiosManager.send(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/mobile-app-versions`
    ),
  setUserBatchMobileAppVersion: (data: {
    versionId: string;
    userIds: Array<string>;
  }) =>
    AxiosManager.send(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/users/assign-mobile-app-version`,
      data
    ),
};

export default UserConnection;
