import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "../../styles.module.scss";
import stylesAtom from "../../../../../global/atoms/atoms.module.scss";
import { IFeedbackDetails } from "utils/models";
import { Checkbox, Col, Rate, Row } from "rsuite";
import { cloneDeep } from "lodash";

interface IKPIReviewForm {
  reviewTitle: string;
  reviewValue: number;
  reasonObj: IFeedbackDetails | null;
  onChange?: (starValue: number, reasonAnswers: string[]) => void;
  readOnly?: boolean;
  errors?: any;
}

const KPIReviewForm: FunctionComponent<IKPIReviewForm> = (props) => {
  const [starValue, setStarValue] = useState<number>(props.reviewValue);
  const [starEditing, setStarEditing] = useState<boolean>(!props.reviewValue);
  const initReasonAnswers = cloneDeep(
    props.reasonObj?.values
      ?.filter((v) => v.value !== "")
      .map((v) => v.value) ?? []
  );
  const [reasonAnswers, setReasonAnswers] =
    useState<string[]>(initReasonAnswers);

  const onStarClick = (value: number) => {
    if (props.readOnly) return false;
    setStarEditing(false);
    setStarValue(value);
    if (props.onChange) props.onChange(value, reasonAnswers);
  };

  const handleStarRatingReset = () => {
    if (props.readOnly) return false;
    setStarEditing(true);
    setStarValue(NaN);
    if (props.onChange) props.onChange(NaN, reasonAnswers);
  };

  useEffect(() => {
    if (!props.onChange) return;
    if (initReasonAnswers.join() !== reasonAnswers.join()) {
      props.onChange(starValue, reasonAnswers);
    }
  }, [reasonAnswers]);

  const reasonPassingConditions = () => {
    const val =
      props.reasonObj?.feedbackQuestion.dependsOn.valueConditions[0].intValue ??
      -1;
    return starValue < val;
  };

  return (
    <div className={`${styles.starRatingGrandWrapper}`}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <p>{props.reviewTitle}</p>
          <span className={`${styles.starRatingWrapper}`}>
            <Rate
              onChange={onStarClick}
              readOnly={!starEditing}
              value={starValue}
            />
            &nbsp;
            {!props.readOnly && !starEditing && (
              <strong
                onClick={handleStarRatingReset}
                style={{
                  cursor: "pointer",
                  color: "red",
                  position: "absolute",
                  fontSize: "1.3em",
                }}>
                ×
              </strong>
            )}
          </span>
          {props.errors && props.errors.positions && (
            <div className={stylesAtom.errors}>
              {props.errors.positions[0].value}
            </div>
          )}
        </Col>

        {props.reasonObj && reasonPassingConditions() && (
          <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "left" }}>
            <p style={{ textAlign: "center" }}>
              {props.reasonObj.feedbackQuestion.name}
            </p>
            {props.errors?.feedbackDetails && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                {props.errors.feedbackDetails}
              </span>
            )}
            {props.reasonObj.feedbackQuestion.answers.map((answer) => (
              <div key={`reason-answer-checkbox-${answer.id}`}>
                <Checkbox
                  readOnly={props.readOnly}
                  checked={
                    reasonAnswers.findIndex((ra) => ra === answer.id) > -1
                  }
                  onChange={(value, checked) => {
                    const tmp = reasonAnswers.filter((ra) => ra !== answer.id);
                    if (checked) {
                      tmp.push(answer.id);
                    }
                    setReasonAnswers(tmp ?? []);
                  }}>
                  {answer.name}
                </Checkbox>
              </div>
            ))}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default KPIReviewForm;
