import { combineReducers, createStore, Reducer } from "redux";
import menuReducer from "./reducers/menuReducer";
import appReducer from "./reducers/appReducer";
import loadingReducer from "./reducers/loadingReducer";
import headerReducer from "./reducers/headerReducer";
import filterReducer from "./reducers/filterReducer";
import {
  CANCEL_TOAST,
  SET_APP,
  SET_BREADCRUMB,
  SET_DISABLED_ELEMENTS,
  SET_FORM_ERRORS,
  SET_PHOTO_REVIEW,
  TOGGLE_CONFIRM_MODAL,
  TOGGLE_LOADING_SCREEN,
} from "./actions";
import { confirmModalState, loadingScreenState } from "./states";
import { IConfirmModal } from "./models";
import { ReactComponentElement } from "react";
import toastReducer from "./reducers/toastReducer";
import activityReducer from "./reducers/activityReducer";
import projectReducer from "./reducers/projectReducer";

const reducers: Reducer = combineReducers({
  app: appReducer,
  menu: menuReducer,
  loading: loadingReducer,
  header: headerReducer,
  filter: filterReducer,
  toast: toastReducer,
  cancelToast: (state = null, action) => {
    if (action.type === CANCEL_TOAST) return action.payload; // id
    return state;
  },
  activity: activityReducer,
  project: projectReducer,
  disabledElements: (state = [], action) => {
    if (action.type === SET_DISABLED_ELEMENTS) return action.payload;
    return state;
  },
  breadcrumb: (state = {}, action) => {
    if (action.type === SET_BREADCRUMB) return action.payload;
    return state;
  },
  formErrors: (state = {}, action) => {
    if (action.type === SET_FORM_ERRORS) return action.payload;
    return state;
  },
  confirmModal: (state = confirmModalState, action) => {
    if (action.type === TOGGLE_CONFIRM_MODAL) return action.payload;
    return state;
  },
  loadingScreen: (state = loadingScreenState, action) => {
    if (action.type === TOGGLE_LOADING_SCREEN) return action.payload;
    return state;
  },
  photoReview: (state = null, action) => {
    if (action.type === SET_PHOTO_REVIEW) return action.payload;
    return state;
  },
});

const store = createStore(reducers);

export const confirmModalCallback = (
  question: string | React.ReactComponentElement<any>,
  callback: any
) => {
  const data: IConfirmModal = {
    open: true,
    callback: callback,
    question: question,
  };
  store.dispatch({
    payload: data,
    type: TOGGLE_CONFIRM_MODAL,
  });
};

export const dispatchDisabledElements = (disabledElements: Array<string>) => {
  store.dispatch({
    type: SET_DISABLED_ELEMENTS,
    payload: disabledElements,
  });
};

export const confirmModalHandler = (
  question: string | ReactComponentElement<any>,
  callback: any
) => {
  return confirmModalCallback.bind(null, question, callback);
};

export const logout = (tokenExpired = false) => {
  store.dispatch({
    type: SET_APP,
    payload: { logged: false, tokenExpired: tokenExpired },
  });
};

export default store;
