import { IRouteEntity } from "utils/models";
import ContractList from "views/contract/contracts/ContractList";
import ContractEdit from "views/contract/contracts/ContractEdit";
import ContractCreate from "views/contract/contracts/ContractCreate";
import ContractIdBreadcrumb from "./ContractIdBreadcrumb";
import ContractDocumentList from "../../../views/contract/contracts/ContractDocumentList";
import ContractServiceList from "../../../views/contract/contracts/ContractServiceList";
import ContractServiceEdit from "../../../views/contract/contracts/ContractServiceEdit";

export const contractDocumentPath = "/contracts/documents";
const path = `${contractDocumentPath}/:id/services`;

const routes: IRouteEntity[] = [
  { path: "/contracts", breadcrumb: "E-Umowa" },
  { path: contractDocumentPath, breadcrumb: "Umowy", Component: ContractList },
  {
    path: `${contractDocumentPath}/add`,
    breadcrumb: "Dodaj umowę",
    Component: ContractCreate,
  },
  {
    path: `${contractDocumentPath}/:id/edit`,
    breadcrumb: ContractIdBreadcrumb,
    Component: ContractEdit,
  },
  {
    path: `${contractDocumentPath}/:id/documents`,
    breadcrumb: ContractIdBreadcrumb,
    Component: ContractDocumentList,
  },

  { path: `${path}`, breadcrumb: "Serwisy", Component: ContractServiceList },
  {
    path: `${path}/add`,
    breadcrumb: "Serwis dodaj",
    Component: ContractServiceEdit,
  },
  {
    path: `${path}/:serviceId/edit`,
    breadcrumb: "Serwis edycja",
    Component: ContractServiceEdit,
  },
];

export default routes;
