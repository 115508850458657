import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, List, Row } from "rsuite";
import _ from "lodash";
import VisitsConnection from "utils/connections/visits";
import { TypeVisitStatus } from "utils/types";

interface IVisitComments {
  visitId: string;
  projectId: string;
}

const VisitCommentsList: FunctionComponent<IVisitComments> = (props) => {
  type TypeComment = "VISIT" | "QUESTION";

  interface IVisitCommentItem {
    visitId: string;
    visitQuestionId: string;
    questionName: string;
    activityName: string;
    userName: string;
    commentType: TypeComment;
    isRead: boolean;
    comment: string;
    visitStatus: TypeVisitStatus;
    visitStatusName: string;
    images: Array<string>;
    createdAt: string;
  }

  const getComments = (visitId: string, cacheKey?: string) => {
    const [comments, setComments] = useState<Array<IVisitCommentItem>>([]);

    useEffect(() => {
      if (cacheKey && localStorage.getItem(cacheKey)) {
        setComments(JSON.parse(localStorage.getItem(cacheKey) ?? "[]"));
      } else {
        VisitsConnection.getComments(visitId, props.projectId).then(
          (result) => {
            setComments(result.data);
          }
        );
      }
    }, []);

    return comments;
  };

  const comments = getComments(props.visitId, props.visitId);
  return (
    <>
      {!_.isEmpty(comments) && (
        <>
          <List
            bordered
            hover
            size={"sm"}
            style={{ marginLeft: "10px", marginRight: "10px" }}>
            {comments.map((comm) => (
              <>
                <List.Item size={"sm"}>
                  <Row>
                    <Col md={12} xs={24}>
                      {comm.comment}
                    </Col>
                    <Col md={12} xs={24}>
                      <small>
                        {comm.userName} ({comm.createdAt})
                      </small>
                    </Col>
                  </Row>
                </List.Item>
              </>
            ))}
          </List>
        </>
      )}

      {_.isEmpty(comments) && (
        <div style={{ textAlign: "center", color: "#aaa" }}>
          - Brak komentarzy-
        </div>
      )}
    </>
  );
};

export default VisitCommentsList;
