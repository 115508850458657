import AxiosHelper from "../AxiosHelper";
import { AxiosPromise } from "axios";
import { getBaseApiUrl, getOrganizationId } from "../helpers";
import { IFilterOrder, IFilterPaginate, ISelect } from "../models";
import { IProjectConnectionCreate } from "../../views/admin/components/projectConnections/createProjectConnectionModal";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projectmatrix`;

export interface ProjectConnectionItem {
  id: string;
  project: {
    id: string;
    name: string;
    // todo: rest of project obj
  };
  projectExternal: {
    id: string;
    externalId: string;
    externalName: string;
    externalType: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface ProjectConnectionsRequest
  extends IFilterOrder,
    IFilterPaginate {
  projectId: string;
  projectExternalId: string;
}

export interface ProjectConnectionAddForm {
  projects: ISelect<{ id: string; name: string }>;
  external_projects: ISelect<{
    externalName: string;
    externalType: string;
    externalId: string;
    id: string;
  }>;
}

const ProjectConnectionsConnection = {
  list: (
    requestData: ProjectConnectionsRequest
  ): AxiosPromise<ProjectConnectionItem[]> => {
    return AxiosHelper.post(`${baseUrl}/list`, requestData);
  },
  filterForm: (): AxiosPromise<ProjectConnectionAddForm> => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },
  form: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  create: (data: IProjectConnectionCreate): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl}`, data);
  },
};

export default ProjectConnectionsConnection;
