import React, { FunctionComponent } from "react";
import { Dropdown } from "rsuite";

interface IOptionsButton {
  children: React.PropsWithChildren<any>;
}

// todo: do zastąpienia przez NavOptionsWrapper
const OptionsDropdown: FunctionComponent<IOptionsButton> = (props) => {
  return (
    <>
      {
        //@ts-ignore - dropdown as button
        <Dropdown appearance={"ghost"} title="Opcje" placement={"bottomEnd"}>
          {props.children}
        </Dropdown>
      }
    </>
  );
};

export default OptionsDropdown;
