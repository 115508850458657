import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_SELECT,
} from "global/filters/UniversalFilters";
import Pagination from "global/pagination/Pagination";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { IconButton, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;
import {
  ILocationWorkTimeHistoryListRequestData,
  ILocationWorkTimeHistoryFileRequestData,
} from "utils/connections/workTimeHistoryLocationConnection";
import { IBodyResponse } from "utils/models";
import { _paginate30 } from "utils/states";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { AxiosPromise } from "axios";
import { useDispatch } from "react-redux";
import { handleToastRedux } from "utils/helpers";
import download from "downloadjs";
import dayjs from "dayjs";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import WorkTimeHistoryLocationConnection from "utils/connections/workTimeHistoryLocationConnection";

interface IDataEntity {
  customerName: string;
  userName: string;
  userRoleName: string;
  firstActivity: string;
  lastActivity: string;
  workTime: string;
}

const LocationWorkTimeHistory: FunctionComponent = () => {
  const defaultRequestData: ILocationWorkTimeHistoryListRequestData = {
    projectId: "",
    locationId: "",
    userId: "",
    dateFrom: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
    ..._paginate30,
  };

  const [requestData, setRequestData] =
    useState<ILocationWorkTimeHistoryFileRequestData>(defaultRequestData);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IBodyResponse<IDataEntity> | null>(null);
  const dispatch = useDispatch();

  const downloadFile = (
    connection: (
      requestData: ILocationWorkTimeHistoryFileRequestData
    ) => AxiosPromise<any>
  ) => {
    handleToastRedux(connection(requestData), dispatch).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
    });
  };

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          icon={<FileDownloadIcon />}
          onClick={downloadFile.bind(
            null,
            WorkTimeHistoryLocationConnection.getFile
          )}>
          .xlsx
        </IconButton>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            loading={loading}
            autoHeight={true}>
            <Column flexGrow={1}>
              <HeaderCell>Data</HeaderCell>
              <Cell>{(rowData) => rowData.date}</Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Nazwa użytkownika</HeaderCell>
              <Cell>{(rowData) => rowData.userName}</Cell>
            </Column>
            <Column flexGrow={1} align={"center"}>
              <HeaderCell>Czas pracy [g:m:s]</HeaderCell>
              <Cell dataKey={"workTime"} />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Rozpoczęcie pracy</HeaderCell>
              <Cell dataKey={"startTime"} />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Zakończenie pracy</HeaderCell>
              <Cell dataKey={"endTime"} />
            </Column>
            <Column flexGrow={2}>
              <HeaderCell>Lokalizacja</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>
                    <div>{rowData.locationName}</div>
                    <div
                      style={{
                        color: "rgba(134,134,134,0.48)",
                        fontSize: "0.7em",
                      }}>
                      {rowData.address}
                    </div>
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        formGet={WorkTimeHistoryLocationConnection.form}
        load={WorkTimeHistoryLocationConnection.list}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setData}
        filterStorageKey={"workTimeHistoryLocationList"}
        elements={[
          {
            label: "Projekt",
            type: FILTER_SELECT,
            formKey: "projects",
            stateKey: "projectId",
          },
          {
            label: "Lokalizacja",
            type: FILTER_SELECT,
            formKey: "locations",
            stateKey: "locationId",
          },
          {
            label: "Użytkownik",
            type: FILTER_SELECT,
            formKey: "users",
            stateKey: "userId",
          },
          {
            label: "Data od",
            type: FILTER_INPUT_DATE,
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateFrom",
          },
          {
            label: "Data do",
            type: FILTER_INPUT_DATE,
            outputFormat: "yyyy-MM-dd",
            stateKey: "dateTo",
          },
        ]}
      />
    </>
  );
};
export default LocationWorkTimeHistory;
