import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

interface IStoreDocumentLink {
  projectId: string;
  id: string;
  name: string;
}

const StoreDocumentLink: FunctionComponent<IStoreDocumentLink> = ({
  name,
  projectId,
  id,
}) => {
  const history = useHistory();
  return (
    <span
      onClick={() => {
        history.push(`/projects/${projectId}/store-documents/${id}/details`);
      }}>
      {name}
    </span>
  );
};

export default StoreDocumentLink;
