import React, { FunctionComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { IRoot } from "./redux/models";
import styles from "./global/layout/layout.module.scss";
import Spinner from "./global/atoms/Spinner/Spinner";

const portal = document.getElementById("portal");

interface IPortal {
  children: ReactNode;
}

const Portal: FunctionComponent<IPortal> = ({ children }) => {
  return ReactDOM.createPortal(children, portal as any);
};

const Loader: FunctionComponent = () => {
  const isLoading = useSelector((state: IRoot) => state.loading.isLoading);

  return (
    <Portal>
      {isLoading && (
        <div className={styles.blackOverlay}>
          <Spinner />
        </div>
      )}
    </Portal>
  );
};

export default Loader;
