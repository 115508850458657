// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adBavB3BZTHNl8xaAp8R{display:flex;justify-content:space-between}.RA_TOlzcvS_DQLD1Bu5M{position:absolute;bottom:-3px;display:block;color:rgba(134,134,134,.78);font-size:.7em;margin-top:5px;margin-bottom:5px}.CecFBypbhpDLXvvYlhTx{position:absolute;bottom:-5px;display:block;color:rgba(134,134,134,.78);font-size:.8em;margin-top:5px;margin-bottom:5px}.wKJ7dVGpC8QB5FjeH1zC{color:rgba(134,134,134,.78);font-size:.8em}", "",{"version":3,"sources":["webpack://./src/views/projects/components/warehouse/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,2BAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,2BAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,2BAAA,CACA,cAAA","sourcesContent":[".headerContainer {\n  display: flex;\n  justify-content: space-between;\n}\n\n.style_header {\n  position: absolute;\n  bottom: -3px;\n  display: block;\n  color: rgba(134,134,134,0.78);\n  font-size: 0.7em;\n  margin-top: 5px;\n  margin-bottom: 5px\n}\n\n.style {\n  position: absolute;\n  bottom: -5px;\n  display: block;\n  color: rgba(134,134,134,0.78);\n  font-size: 0.8em;\n  margin-top: 5px;\n  margin-bottom: 5px\n}\n\n.style_top {\n  color: rgba(134,134,134,0.78);\n  font-size: 0.8em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "adBavB3BZTHNl8xaAp8R",
	"style_header": "RA_TOlzcvS_DQLD1Bu5M",
	"style": "CecFBypbhpDLXvvYlhTx",
	"style_top": "wKJ7dVGpC8QB5FjeH1zC"
};
export default ___CSS_LOADER_EXPORT___;
