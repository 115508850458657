import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Checkbox, Col, IconButton, Row, Table } from "rsuite";
import RolesConnection from "utils/connections/roles";
import { deepClone, handleToast, isSuperAdmin } from "utils/helpers";
import { IPermission } from "utils/models";
import { FormDefault, FormGroupSelect, FormGroupText } from "utils/formHelper";
import _ from "lodash";
import UniversalModal from "./atoms/UniversalModal";
import ActionsContainer from "./atoms/ActionsContainer";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
import { TypeAttributes } from "rsuite/esm/@types/common";

interface IRolePermissions {
  roleId: string;
  projectId?: string;
  size?: TypeAttributes.Size;
}

const RolePermissions: FunctionComponent<IRolePermissions> = (props) => {
  if (!isSuperAdmin()) return <></>;

  const [open, setOpen] = useState(false);

  interface IPermissionActive extends IPermission {
    active: boolean;
  }

  const [permissions, setPermissions] =
    useState<Array<IPermissionActive> | null>(null);
  const [permissionsFiltered, setPermissionsFiltered] =
    useState<Array<IPermissionActive> | null>(null);
  const [form, setForm] = useState<{
    search?: string;
    scope?: string;
    domain?: string;
    group?: string;
    visibility?: "aktywne" | "nie aktywne";
  } | null>({});
  const isProjectScopeOnly: boolean = props.projectId !== undefined;

  useEffect(() => {
    if (!open) {
      setForm(null);
      setPermissions(null);
      setPermissionsFiltered(null);
    } else {
      RolesConnection.getPermissions(props.roleId, props.projectId).then(
        (data) => {
          let parsedData = data.data.permissions.map((p) => ({
            ...p,
            active:
              data.data.active.find((a) => a.permissionId === p.id) !==
              undefined,
          }));
          if (isProjectScopeOnly) {
            parsedData = parsedData.filter((p) => p.scope === "PROJECT");
          }
          setPermissions(parsedData);
          setPermissionsFiltered(parsedData);
          setForm({});
        }
      );
    }
  }, [open]);

  // filtering
  useEffect(() => {
    let perms = _.cloneDeep(permissions);
    if (!perms) return;

    if (form?.search) {
      perms = perms.filter(
        (p) =>
          p.name
            .toLocaleLowerCase()
            .includes(form?.search?.toLocaleLowerCase() as string) ||
          p.description
            .toLocaleLowerCase()
            .includes(form?.search?.toLocaleLowerCase() as string) ||
          p.menuName
            .toLocaleLowerCase()
            .includes(form?.search?.toLocaleLowerCase() as string)
      );
    }

    if (form?.scope) {
      perms = perms.filter((p) => p.scope === form.scope);
    }

    if (form?.domain) {
      perms = perms.filter((p) => p.domain === form.domain);
    }

    if (form?.group) {
      perms = perms.filter((p) => p.group === form.group);
    }

    if (form?.visibility) {
      perms = perms.filter(
        (p) =>
          (form.visibility === "aktywne" && p.active) ||
          (form.visibility === "nie aktywne" && !p.active)
      );
    }

    setPermissionsFiltered(perms);
  }, [form]);

  const permissionsStore = () => {
    if (permissions)
      handleToast(
        RolesConnection.setPermissions(
          props.roleId,
          props.projectId,
          permissions.filter((p) => p.active).map((p) => p.id)
        )
      ).then(() => {
        setOpen(false);
        // nothing
      });
  };

  const onCheckboxAllChange = (checked: boolean) => {
    if (permissions) {
      const _permissions = deepClone(permissions).map((permission) => ({
        ...permission,
        active: checked,
      }));
      setPermissions(_permissions);
    }
  };

  const onCheckboxChange = (permissionId: string, checked: boolean) => {
    if (permissions) {
      const _permissions = deepClone(permissions).map((permission) => ({
        ...permission,
        active: permission.id === permissionId ? checked : permission.active,
      }));
      setPermissions(_permissions);
    }
  };

  const getHeader = () => {
    return (
      <>
        {form !== null && (
          <FormDefault onChange={(_form) => setForm(_form)} state={form}>
            <Row>
              <Col md={9}>
                <FormGroupText
                  size={"sm"}
                  placeholder={"Wyszukaj: nazwa, opis, menu..."}
                  fieldName={"search"}
                />
              </Col>
              <Col md={4}>
                <FormGroupSelect
                  size={"sm"}
                  disabled={isProjectScopeOnly}
                  placeholder={isProjectScopeOnly ? "PROJECT" : "Scope"}
                  fieldName={"scope"}
                  labelKey={"label"}
                  valueKey={"id"}
                  options={[
                    ...new Set(permissions?.map((p) => p.scope) ?? []),
                  ].map((p) => ({ id: p, label: p }))}
                />
              </Col>
              <Col md={4}>
                <FormGroupSelect
                  size={"sm"}
                  placeholder={"Domena"}
                  fieldName={"domain"}
                  labelKey={"label"}
                  valueKey={"id"}
                  options={[
                    ...new Set(permissions?.map((p) => p.domain) ?? []),
                  ].map((p) => ({ id: p, label: p }))}
                />
              </Col>
              <Col md={4}>
                <FormGroupSelect
                  size={"sm"}
                  placeholder={"Grupa"}
                  fieldName={"group"}
                  labelKey={"label"}
                  valueKey={"id"}
                  options={[
                    ...new Set(permissions?.map((p) => p.group) ?? []),
                  ].map((p) => ({ id: p, label: p }))}
                />
              </Col>
              <Col md={3}>
                <FormGroupSelect
                  size={"sm"}
                  placeholder={"Aktywne?"}
                  fieldName={"visibility"}
                  labelKey={"label"}
                  valueKey={"id"}
                  options={["aktywne", "nie aktywne"].map((v) => ({
                    id: v,
                    label: v,
                  }))}
                />
              </Col>
            </Row>
          </FormDefault>
        )}
      </>
    );
  };

  return (
    <>
      <IconButton
        size={props.size}
        icon={<PeopleBranchIcon />}
        appearance={"ghost"}
        onClick={() => setOpen(true)}>
        Uprawnienia grupy
      </IconButton>
      <UniversalModal
        displayFooterButtons={false}
        size={"full"}
        onClose={() => setOpen(false)}
        title={getHeader()}
        customFooterButtons={
          <ActionsContainer>
            <Button appearance="ghost" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
            <Button appearance="primary" onClick={permissionsStore}>
              Zapisz zmiany
            </Button>
          </ActionsContainer>
        }
        body={
          <Table
            virtualized
            height={600}
            loading={permissionsFiltered === null}
            data={permissionsFiltered ?? []}>
            <Table.Column fixed={"left"} width={75}>
              <Table.HeaderCell>
                {permissions && (
                  <Checkbox
                    indeterminate={
                      permissions.find((p) => p.active) !== undefined &&
                      permissions.find((p) => !p.active) !== undefined
                    }
                    checked={permissions?.find((p) => !p.active) === undefined}
                    onChange={(val, checked) => onCheckboxAllChange(checked)}
                  />
                )}
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <Checkbox
                    key={rowData.id}
                    checked={
                      permissions?.find((p) => p.id === rowData.id)?.active ??
                      false
                    }
                    onChange={(val, checked) =>
                      onCheckboxChange(rowData.id, checked)
                    }
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column
              resizable
              flexGrow={1}
              minWidth={300}
              fullText
              fixed={"left"}>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.name}</Table.Cell>
            </Table.Column>

            <Table.Column resizable flexGrow={1} minWidth={200} fullText>
              <Table.HeaderCell>Menu</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.menuName}</Table.Cell>
            </Table.Column>

            <Table.Column resizable flexGrow={1} minWidth={200} fullText>
              <Table.HeaderCell>Opis</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.description}</Table.Cell>
            </Table.Column>

            <Table.Column resizable flexGrow={1} minWidth={100}>
              <Table.HeaderCell>Scope</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.scope}</Table.Cell>
            </Table.Column>

            <Table.Column resizable flexGrow={1} minWidth={200}>
              <Table.HeaderCell>Grupa</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.group}</Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Domena</Table.HeaderCell>
              <Table.Cell>{(rowData) => rowData.domain}</Table.Cell>
            </Table.Column>
          </Table>
        }
        open={open}
      />
    </>
  );
};

export default RolePermissions;
