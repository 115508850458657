import React from "react";
import BaseComponent from "../BaseComponent";
import { IBaseComponentProps } from "../../../utils/models";
import styles from "../atoms.module.scss";
import tick from "../../../assets/svg/checkmark.svg";

interface ICheckBox extends IBaseComponentProps {
  isChecked: boolean;
  onClick: (event: any) => void;
  id?: string;
  style?: any;
  styleContainer?: any;
  children?: React.ReactNode;
}

export default class CheckBox extends BaseComponent<ICheckBox, any> {
  renderView(): JSX.Element {
    return (
      <>
        <div
          onClick={this.props.onClick}
          data-id={this.props.id}
          style={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            gridColumnGap: 0,
            ...this.props.styleContainer,
          }}>
          <span
            style={this.props.style}
            className={
              this.props.isChecked ? styles.checkboxActive : styles.checkbox
            }>
            {this.props.isChecked && <img src={tick} alt="tick" />}
          </span>
          {this.props.children}
        </div>
      </>
    );
  }
}
