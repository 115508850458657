import React, { memo } from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  SelectPicker,
  TagPicker,
} from "rsuite";

import CloseIcon from "@rsuite/icons/Close";
import { FCC, IQuestionItem } from "../../../../utils/models";
import PhotoQuestion from "../visits/visitsEdit/VisitElements/Atoms/PhotoQuestion";
import TextArea from "../visits/visitsEdit/VisitElements/Atoms/TextArea";
import {
  BOOLEAN_QUESTION,
  DATE_QUESTION,
  DECIMAL_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
  PHOTO_QUESTION,
  TEXT_QUESTION,
  TEXTAREA_QUESTION,
  TIME_QUESTION,
} from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";
import { parseToDate } from "../visits/visitsEdit/VisitElements/VisitHelpers";

interface IVisitQuestion {
  question: IQuestionItem;
  isDisabled: boolean;
}

const QuestionInput: FCC<IVisitQuestion> = ({ question, isDisabled }) => {
  const getQuestionPrimitiveValue = (
    question: IQuestionItem
  ): boolean | string | number | null => {
    const questionType = question.questionType;
    const v = question?.values;
    if (!v || !v.length || !questionType) return null;

    let val: boolean | string | number | null;

    switch (questionType as string) {
      case BOOLEAN_QUESTION:
        val = v[0].value === "1" ? "1" : "0";
        break;
      case INTEGER_QUESTION:
      case DECIMAL_QUESTION:
        val = v[0].value.split(",").join(".");
        break;
      case DATE_QUESTION:
      case TIME_QUESTION:
      case TEXTAREA_QUESTION:
      case TEXT_QUESTION:
        // @ts-ignore
        val = v[0].value;
        break;
      default:
        // @ts-ignore
        val = v[0].value;
        break;
    }

    return val ?? null;
  };

  const formValue: any = getQuestionPrimitiveValue(question);

  const renderIntegerInput = () => {
    return (
      <Form.Control
        value={formValue}
        accepter={InputNumber}
        name={question.refQuestionId}
        min={0}
        step={1}
        disabled={isDisabled}
      />
    );
  };

  const renderDecimalInput = () => {
    return (
      <Form.Control
        value={formValue as string}
        accepter={Input}
        name={question.refQuestionId}
        disabled={isDisabled}
      />
    );
  };

  const renderTextInput = () => {
    return (
      <Form.Control
        accepter={Input}
        type={"text"}
        value={formValue as string}
        placeholder={"Wpisz odpowiedź"}
        name={question.refQuestionId}
        max={question.maxValue ? question.maxValue.toString() : 10000}
        disabled={isDisabled}
      />
    );
  };

  const renderPhotoQuestion = () => {
    return (
      <PhotoQuestion
        //TODO display value of photoQuestion
        key={`photo-question-${question.refQuestionId}`}
        refQuestionId={question.refQuestionId}
        isDisabled={isDisabled}
      />
    );
  };

  const renderBooleanQuestion = () => {
    const options = [
      { value: "1", label: "Tak", refQuestionAnswerId: "" },
      { value: "0", label: "Nie", refQuestionAnswerId: "" },
    ];

    return (
      <Form.Control
        accepter={SelectPicker}
        searchable={false}
        cleanable={true}
        data={options}
        block
        value={formValue}
        labelKey={"label"}
        valueKey={"value"}
        placeholder={"Wybierz"}
        name={question.refQuestionId}
        disabled={isDisabled}
      />
    );
  };

  const renderTextareaInput = () => (
    <Form.Control
      value={formValue as string}
      accepter={TextArea}
      name={question.refQuestionId}
      disabled={isDisabled}
      placeholder={"Wpisz odpowiedź"}
      style={{ width: "100%", height: "60px" }}
      // @ts-ignore
      max={question.maxValue ? parseInt(question.maxValue.toString()) : 10000}
      rows={3}
    />
  );

  const renderDictionaryQuestion = () => {
    if (question.questionType === DICTIONARY_QUESTION) {
      return (
        <Form.Control
          accepter={SelectPicker}
          searchable={false}
          cleanable={true}
          block
          value={question?.values[0]?.value ?? []}
          style={{ minWidth: "200px" }}
          data={question.options ?? []}
          placeholder={"Wybierz odpowiedź"}
          name={question.refQuestionId}
          labelKey={"name"}
          valueKey={"id"}
          disabled={isDisabled}
        />
      );
    } else {
      // DICTIONARY_MULTIPLE_ANSWERS_QUESTION only
      console.log(question.values);
      return (
        <Form.Control
          accepter={TagPicker}
          block
          value={question.values?.map((value) => value.value) ?? []}
          name={question.refQuestionId}
          style={{ minWidth: "200px" }}
          labelKey={"name"}
          valueKey={"id"}
          data={question?.options ?? []}
          placeholder={"Wybierz odpowiedzi"}
          disabled={isDisabled}
        />
      );
    }
  };

  const renderDatePicker = () => {
    return (
      <Form.Control
        isoWeek
        showWeekNumbers
        ranges={[
          {
            label: "Dziś",
            value: new Date(),
          },
        ]}
        value={parseToDate(formValue as string | Date)}
        style={{ width: "50%" }}
        oneTap
        accepter={DatePicker}
        name={question.refQuestionId}
        format={"yyyy-MM-dd"}
        disabled={isDisabled}
      />
    );
  };

  const renderTimePicker = () => {
    return (
      <Form.Control
        accepter={DatePicker}
        format="HH:mm"
        isoWeek
        //time is stored in state as date
        value={parseToDate(formValue as string | Date)}
        name={question.refQuestionId}
        disabled={isDisabled}
        ranges={[
          {
            label: "Teraz",
            value: new Date(),
          },
        ]}
        style={{ width: "50%" }}
      />
    );
  };

  const renderElement = () => {
    switch (question.questionType) {
      case INTEGER_QUESTION:
        return renderIntegerInput();
      case DECIMAL_QUESTION:
        return renderDecimalInput();
      case TEXT_QUESTION:
        return renderTextInput();
      case PHOTO_QUESTION:
        return renderPhotoQuestion();
      case BOOLEAN_QUESTION:
        return renderBooleanQuestion();
      case TEXTAREA_QUESTION:
        return renderTextareaInput();
      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
      case DICTIONARY_QUESTION:
        return renderDictionaryQuestion();
      case DATE_QUESTION:
        return renderDatePicker();
      case TIME_QUESTION:
        return renderTimePicker();
      default:
        return <CloseIcon />;
    }
  };

  return renderElement();
};

export default memo(QuestionInput);
