import React, { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import { getProductIconByType } from "../../IconHelper";
import { IProductItem } from "../../../../utils/models";
import { DragHandle } from "global/atoms/SortableItem";
import { Tooltip, Whisper } from "rsuite";

interface IProductItemBody {
  item: IProductItem;
  checked: Array<string>;
  handleToggleCheckbox: (itemId: string) => void;
  sortable: boolean;
}
const ProductItemBody: FunctionComponent<IProductItemBody> = (props) => {
  const getCheckboxClass = (item: IProductItem) => {
    return `${styles.checkboxItem} ${
      props.checked.includes(item.id) ? styles.checked : ""
    }`;
  };

  return (
    <>
      <div className={styles.itemRow} key={props.item.id}>
        <div className={styles.wrapper}>
          <span
            data-id={props.item.id}
            onClick={props.handleToggleCheckbox.bind(null, props.item.id)}
            className={getCheckboxClass(props.item)}
          />
          {props.sortable && <DragHandle />}
          <span className={styles.itemName}>
            {getProductIconByType(props.item.groupType)}
            {props.item.name}
          </span>
          {props.item.brandName && (
            <Whisper
              placement={"left"}
              speaker={<Tooltip>Marka: {props.item.brandName}</Tooltip>}>
              <strong style={{ cursor: "help" }}>M&nbsp;</strong>
            </Whisper>
          )}
          {props.item.categoryName && (
            <Whisper
              speaker={<Tooltip>Kategoria: {props.item.categoryName}</Tooltip>}>
              <strong style={{ cursor: "help" }}>K</strong>
            </Whisper>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductItemBody;
