import React, { FunctionComponent, useEffect, useState } from "react";
import ProjectLocationUsersConnection from "../../../../utils/connections/projectLocationUsers";
import { useParams } from "react-router-dom";
import { ISelectViewResponse } from "../../../../utils/models";
import Select from "../../../../global/atoms/Select";
import Col from "rsuite/Col";
import Row from "rsuite/Row";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import { handleToast } from "../../../../utils/helpers";
import { Checkbox, DateRangePicker, Modal } from "rsuite";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import Message from "rsuite/Message";
import { ToastNotificationPush, ToastTypes } from "global/ToastNotification";

interface ILocationsOperations {
  selectedLocations: Array<any>;
  setReloadData: () => void;
  open: OperationType | null;
  onClose: () => void;
}

interface IFormData {
  usersFrom: ISelectViewResponse;
  usersTo: ISelectViewResponse;
  projectRoles: ISelectViewResponse;
  operationTypes: ISelectViewResponse;
}

interface IState {
  operation: string;
  role: string;
  userFrom: string;
  userTo: string;
  isTimeRangeReplace: boolean;
  isTimeRangeReplaceAll: boolean;
  startDate: Date | null;
  endDate: Date | null;
}

export interface ISubmitOperation {
  operationType: string;
  roleId: string;
  userFromId: string;
  userToId: string;
  positions: Array<{ locationId: string }>;
}

export type OperationType =
  | "REPLACE_ALL"
  | "REPLACE_SELECTED"
  | "ADD_SELECTED"
  | "ADD_ALL"
  | "REMOVE_SELECTED"
  | "REMOVE_ALL"
  | "REPLACE_TERM_SELECTED"
  | "REPLACE_TERM_ALL";

export const TYPE_REPLACE_ALL = "REPLACE_ALL";
export const TYPE_REPLACE_SELECTED = "REPLACE_SELECTED";

export const TYPE_ADD_SELECTED = "ADD_SELECTED";
export const TYPE_ADD_ALL = "ADD_ALL";

export const TYPE_REMOVE_SELECTED = "REMOVE_SELECTED";
export const TYPE_REMOVE_ALL = "REMOVE_ALL";

export const TYPE_REPLACE_TERM_SELECTED = "REPLACE_TERM_SELECTED";
export const TYPE_REPLACE_TERM_ALL = "REPLACE_TERM_ALL";

const LocationsOperations: FunctionComponent<ILocationsOperations> = ({
  selectedLocations,
  setReloadData,
  open,
  onClose,
}) => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<IFormData | null>(null);
  const defaultState = {
    isTimeRangeReplaceAll: false,
    isTimeRangeReplace: false,
    startDate: null,
    endDate: null,
    operation: "",
    role: "",
    userFrom: "",
    userTo: "",
  };
  const [state, setState] = useState<IState>(defaultState);

  useEffect(() => {
    if (open === null) {
      setFormData(null);
      setState(defaultState);
    } else {
      setState((cs) => ({
        ...cs,
        operation: open,
        isTimeRangeReplace: [
          TYPE_REPLACE_TERM_SELECTED,
          TYPE_REPLACE_TERM_ALL,
        ].includes(open),
        // isTimeRangeReplaceAll: ([TYPE_REPLACE_TERM_SELECTED, TYPE_REPLACE_TERM_ALL].includes(open))
      }));
      loadFromData();
    }
  }, [open]);

  const loadFromData = () => {
    ProjectLocationUsersConnection.getLocationReplaceFormData(id).then(
      (response) => {
        setFormData(response.data);

        if (response.data.projectRoles.value) {
          setState((cs) => ({
            ...cs,
            role: response.data.projectRoles.value,
          }));
        }
      }
    );
  };

  const getUsersOptionsByRole = (users: Array<any>) => {
    if (state.role === "") {
      return users.map((item) => ({
        id: item.id,
        name: item.fullName,
      }));
    }

    const usersFilteredByRole = users.filter(
      (item) => item.roleId === state.role
    );
    return usersFilteredByRole.map((item) => ({
      id: item.id,
      name: item.fullName,
    }));
  };

  const getUsersSelectsFromTo = () => {
    if (!formData) return <></>;
    return (
      <>
        <Row style={{ margin: 0 }}>
          {[
            TYPE_REMOVE_ALL,
            TYPE_REMOVE_SELECTED,
            TYPE_REPLACE_ALL,
            TYPE_REPLACE_SELECTED,
            TYPE_REPLACE_TERM_SELECTED,
            TYPE_REPLACE_TERM_ALL,
          ].includes(state.operation) && (
            <Col xs={12}>
              <Select
                style={{ zIndex: 99 }}
                placeholder={"Użytkownik do zmiany"}
                name={"userFrom"}
                value={state.userFrom}
                options={getUsersOptionsByRole(formData.usersFrom.options)}
                state={state}
                setState={setState}
              />
            </Col>
          )}
          {[
            TYPE_ADD_ALL,
            TYPE_ADD_SELECTED,
            TYPE_REPLACE_ALL,
            TYPE_REPLACE_SELECTED,
            TYPE_REPLACE_TERM_SELECTED,
            TYPE_REPLACE_TERM_ALL,
          ].includes(state.operation) && (
            <Col xs={12}>
              <Select
                style={{ zIndex: 99 }}
                placeholder={"Nowe przypisanie"}
                name={"userTo"}
                value={state.userTo}
                options={getUsersOptionsByRole(formData.usersTo.options).filter(
                  (u) => u.id !== state.userFrom
                )}
                state={state}
                setState={setState}
              />
            </Col>
          )}
        </Row>
        {[TYPE_REPLACE_ALL, TYPE_REPLACE_SELECTED].includes(
          state.operation
        ) && (
          <Message
            style={{ marginTop: "20px" }}
            showIcon={true}
            header={"Zmiany harmonogramu, będą obowiązywać od następnego dnia."}
            type={"warning"}
          />
        )}
      </>
    );
  };

  const handleSubmit = () => {
    if (
      [
        TYPE_REMOVE_SELECTED,
        TYPE_ADD_SELECTED,
        TYPE_REPLACE_SELECTED,
        TYPE_REPLACE_TERM_SELECTED,
      ].includes(state.operation) &&
      selectedLocations.length <= 0
    ) {
      ToastNotificationPush(ToastTypes.error, "Nie wybrano lokalizacji");
      return false;
    }

    const getDataPost = (): ISubmitOperation => {
      // todo: wspólny interfejs...
      const data = {
        ...state,
        operationType: state.operation,
        roleId: state.role,
        userFromId: state.userFrom,
        userToId: state.userTo,
        positions: selectedLocations.map((item) => ({ locationId: item })),
      };

      if (
        state.operation === TYPE_ADD_ALL ||
        state.operation === TYPE_ADD_SELECTED
      ) {
        data.userFromId = data.userToId; // set any user as user from just to prevent backend error
      }

      if (
        state.operation === TYPE_REMOVE_ALL ||
        state.operation === TYPE_REMOVE_SELECTED
      ) {
        data.userToId = data.userFromId; // set any user as user from just to prevent backend error
      }

      return data;
    };

    handleToast(
      ProjectLocationUsersConnection.operation(id, getDataPost())
    ).then(() => {
      setState(defaultState);
      setReloadData();
      loadFromData();
    });
  };

  const getModalTitle = (): string => {
    switch (state.operation) {
      case TYPE_REMOVE_SELECTED:
        return "Usuń przypisania użytkowników";
      case TYPE_REMOVE_ALL:
        return "Usuń przypisania użytkowników - wszystkie lokalizacje!";
      case TYPE_ADD_SELECTED:
        return "Dodaj przypisania użytkowników";
      case TYPE_ADD_ALL:
        return "Dodaj przypisania użytkowników - wszystkie lokalizacje!";
      case TYPE_REPLACE_TERM_SELECTED:
        return "Zastępstwo użytkownika";
      case TYPE_REPLACE_TERM_ALL:
        return "Zastępstwo użytkownika - wszystkie lokalizacje!";
      case TYPE_REPLACE_SELECTED:
        return "Zmień przypisania użytkowników";
      case TYPE_REPLACE_ALL:
        return "Zmień przypisania użytkowników - wszystkie lokalizacje!";
    }

    return "-";
  };

  const timePeriodUserReplace = (value: Array<Date>) => {
    if (value.length) {
      let startDate = value[0];
      startDate.setHours(0, 0, 0, 0);
      const endDate = value[1];
      endDate.setHours(0, 0, 0, 0);

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (startDate.getTime() < currentDate.getTime()) {
        alert(
          "Zakres nie może mieć miejsca w przeszłości. Data początkowa zostanie zmieniona na date dzisiejszą."
        );
        startDate = new Date();
        startDate.setHours(0, 0, 1, 0);
      }
      setState((cs) => ({
        ...cs,
        startDate: startDate,
        endDate: endDate,
      }));
    } else {
      setState((cs) => ({
        ...cs,
        startDate: null,
        endDate: null,
      }));
    }
  };

  return (
    <Modal open={open !== null} size={"sm"} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{getModalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "500px" }}>
        {[
          TYPE_REMOVE_SELECTED,
          TYPE_ADD_SELECTED,
          TYPE_REPLACE_SELECTED,
          TYPE_REPLACE_TERM_SELECTED,
        ].includes(state.operation) && (
          <div style={{ textAlign: "center" }}>
            <small>Zaznaczone lokalizacje ({selectedLocations.length})</small>
            <SeparatorEmpty />
          </div>
        )}
        <br />

        <Row style={{ margin: 0 }}>
          <Col xs={12}>
            <Select
              style={{ zIndex: 100 }}
              placeholder={"Wybierz grupę"}
              name={"role"}
              value={state.role}
              options={formData?.projectRoles.options ?? []}
              state={state}
              setState={setState}
            />
          </Col>
        </Row>
        <SeparatorEmpty />
        {getUsersSelectsFromTo()}

        {[TYPE_REPLACE_TERM_SELECTED, TYPE_REPLACE_TERM_ALL].includes(
          state.operation
        ) && (
          <>
            <SeparatorEmpty />
            <Checkbox
              checked={state.isTimeRangeReplaceAll}
              onChange={(val, checked) =>
                setState((state) => ({
                  ...state,
                  isTimeRangeReplaceAll: checked,
                }))
              }>
              Zastosuj do wszystkich projektów
            </Checkbox>
            <DateRangePicker
              format="yyyy-MM-dd"
              style={{ display: "block" }}
              cleanable={false}
              value={
                state.startDate && state.endDate
                  ? [state.startDate, state.endDate]
                  : undefined
              }
              shouldDisableDate={beforeToday()}
              // @ts-ignore
              onChange={timePeriodUserReplace}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonSubmit
          disabled={!state.role || !state.operation}
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default LocationsOperations;
