import React, { FunctionComponent, memo, useEffect, useState } from "react";
import Map, { IPoint } from "../../../../../../global/googleMap/Map";
import { ICoords, IGpsSimple, ILocation } from "../../../../../../utils/models";
import styles from "../styles.module.scss";
import { renderToStaticMarkup } from "react-dom/server";
import { TypeGpsPointType } from "../../../../../../utils/types";
import MarkerClusterWindowContent, {
  getPointTypeName,
} from "./Atoms/MarkerClusterWindowContent";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SeparatorEmpty from "../../../../../../global/atoms/separators/SeparatorEmpty";
import { Message, Panel } from "rsuite";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "../../../../../../global/googleMap/MapWrapper";
import Spinner from "../../../../../../global/atoms/Spinner/Spinner";
import { formattedDistance } from "./VisitDistances";
import { usePanelContext } from "./VisitHelpers";

interface IVisitMap {
  location: ILocation;
  gps: Array<IGpsSimple>;
}

export const visitMapErrorFallback = () => {
  return (
    <>
      <SeparatorEmpty size={1.5} />
      <Message type={"error"} showIcon>
        Wystąpił błąd podczas próby wyświetlenia mapy
      </Message>
    </>
  );
};

export const visitMapOnError = (
  error: Error,
  info: { componentStack: string }
) => {
  console.warn(error, info);
};

const VisitMap: FunctionComponent<IVisitMap> = (props) => {
  if (!props.location.address.coords && !props.gps.length) return <></>;

  const [points, setPoints] = useState<IPoint[]>([]);
  const [google, setGoogle] = useState(null);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  const { registerPanelId, panelToggle, openPanels } = usePanelContext();

  useEffect(() => {
    registerPanelId("visit-map");
  }, []);

  const mapInfoWindowInitial: google.maps.InfoWindowOptions = {
    content: null,
    position: null,
  };
  const [infoWindow, setInfoWindow] =
    useState<google.maps.InfoWindowOptions>(mapInfoWindowInitial);

  const getIconForPointType = (pointType: TypeGpsPointType): null | string => {
    // todo: ------
    switch (pointType) {
      case "ACTIVITY_START":
        return "/png/dot.png";
      case "ACTIVITY_END":
        return "/png/dot.png";
      case "WORK_START":
        return "/png/dot.png";
      case "WORK_END":
        return "/png/dot.png";
      case "PHOTO_QUESTION":
        return "/png/dot.png";
    }
    return null;
  };
  const getLocationLatLng = (): null | google.maps.LatLngLiteral => {
    const cords: ICoords | null = props.location.address.coords;
    if (!cords) return null;
    return {
      lat: cords.latitude,
      lng: cords.longitude,
    };
  };

  const getGpsLatLng = (p: IGpsSimple): google.maps.LatLngLiteral => {
    return {
      lat: p.lat,
      lng: p.lon,
    };
  };

  const getGpsPointContent = (p: IGpsSimple): JSX.Element => {
    return (
      <div className={styles.visitMapInfoWindow}>
        <h6>{getPointTypeName(p.pointType)}</h6>
        <small>
          {p.activityName} {formattedDistance(p)}
        </small>
        <div>
          <small>
            {"Użytkownik"}:&nbsp;
            <strong>{p.userName}</strong>
          </small>
        </div>
      </div>
    );
  };

  const getLocationContent = (): JSX.Element => {
    return (
      <div className={styles.visitMapInfoWindow}>
        <h6>{props.location.name}</h6>
        <div>
          {props.location.address?.city?.name}&nbsp;
          {props.location.address?.postCode?.name}
          <br />
          Ul. {props.location.address?.street?.name}&nbsp;
          {props.location.address.apartmentNumber +
            (props.location.address.houseNumber
              ? `/${props.location.address.houseNumber}`
              : "")}
        </div>
      </div>
    );
  };

  const onPointClick = (p: IPoint) => {
    let windowContent: null | JSX.Element = null;
    let latLon: null | google.maps.LatLngLiteral = null;

    if (p.id === "location_point") {
      windowContent = getLocationContent();
      latLon = getLocationLatLng();
    } else {
      const gpoint = props.gps.find((g) => g.id === p.id);
      if (!gpoint) throw "Unknown point ID";
      windowContent = getGpsPointContent(gpoint);
      latLon = getGpsLatLng(gpoint);
    }

    if (windowContent && latLon) {
      setInfoWindow({
        content: renderToStaticMarkup(windowContent),
        position: latLon,
        // @ts-ignore
        pixelOffset: new google.maps.Size(0, -20),
      });
    }
  };

  const onClusterMarkerClick = (
    clusterPos: google.maps.LatLngLiteral,
    points: Array<IPoint>
  ) => {
    setInfoWindow({
      content: renderToStaticMarkup(
        <MarkerClusterWindowContent
          gps={props.gps}
          points={points}
          location={getLocationLatLng()}
        />
      ),
      position: clusterPos,
      // @ts-ignore
      pixelOffset: new google.maps.Size(0, -50),
    });
  };

  useEffect(() => {
    if (!google) return;

    const points: Array<IPoint> = [];

    const locationCords: ICoords | null = props.location.address.coords;
    if (locationCords) {
      const locationPoint: IPoint = {
        id: "location_point",
        lat: locationCords.latitude,
        lon: locationCords.longitude,
        selected: false,
        hidden: false,
        icon: "/png/dot_active.png",
      };
      points.push(locationPoint);

      setInfoWindow({
        content: renderToStaticMarkup(getLocationContent()),
        position: getLocationLatLng(),
        // @ts-ignore
        pixelOffset: new google.maps.Size(0, -50),
      });
    }

    props.gps.forEach((g) => {
      const pointIcon = getIconForPointType(g.pointType);
      if (pointIcon !== null) {
        points.push({
          id: g.id,
          lat: g.lat,
          lon: g.lon,
          selected: false,
          hidden: false,
          icon: pointIcon,
        });
      }
    });
    setPoints(points);
  }, [google]);

  const getMapCenterPoint = (): google.maps.LatLngLiteral | undefined => {
    // @ts-ignore // location coords
    if (getLocationLatLng()) return getLocationLatLng();

    // centroid point from points
    if (points.length) {
      let sumLat = 0;
      let sumLon = 0;

      points.forEach((p) => {
        sumLat += p.lat;
        sumLon += p.lon;
      });

      return {
        lat: sumLat / points.length,
        lng: sumLon / points.length,
      };
    }

    return undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clusterMarkersCreated = (clusterer: MarkerClusterer) => {
    // pick larger cluster -> show info window
    // console.log('clusterMarkerCreated', clusterer);
  };

  return (
    <div>
      <SeparatorEmpty size={1} />
      <Panel
        header={"Mapa / GPS"}
        shaded
        collapsible
        expanded={openPanels.includes("visit-map")}
        onSelect={() => panelToggle("visit-map")}>
        {!google && <Spinner />}
        {google && points.length > 0 && (
          <Map
            center={getMapCenterPoint()}
            clusterMarkersCreated={clusterMarkersCreated}
            onPointClick={onPointClick}
            onClusterMarkerClick={onClusterMarkerClick}
            points={points}
            zoom={16}
            infoWindow={infoWindow}
          />
        )}
      </Panel>
    </div>
  );
};

export default memo(VisitMap);
