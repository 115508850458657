import React, { FunctionComponent, useEffect, useState } from "react";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import Schema from "rsuite/Schema";
import Button from "rsuite/Button";
import Col from "rsuite/Col";
import RegionConnection from "../../../utils/connections/regions";
import { useHistory, useParams } from "react-router-dom";
import WhiteCard from "../../../global/atoms/WhiteCard";
import Section from "../../../global/atoms/section/Section";
import { useDispatch } from "react-redux";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { handleToastErrorCallback } from "../../../utils/helpers";

interface IRegionEdit {}

interface IStateEdit {
  name: string;
  isDefault: string;
}

enum regionFormFields {
  name = "name",
  isDefault = "isDefault",
}

const RegionEdit: FunctionComponent<IRegionEdit> = () => {
  const { regionId } = useParams<{ regionId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const stateDefault: IStateEdit = {
    name: "",
    isDefault: "true",
  };

  const [state, setState] = useState<IStateEdit>(stateDefault);
  const [errors, setErrors] = useState<any | undefined>();
  const handleSubmit = () => {
    if (regionId) {
      handleToastErrorCallback(
        RegionConnection.updateForm(regionId, state),
        setErrors
      ).then(() => history.push("/datasets/regions"));
    } else {
      handleToastErrorCallback(RegionConnection.addForm(state), setErrors).then(
        () => history.push("/datasets/regions")
      );
    }
  };
  const handleChange = (value: any) => {
    setState({ ...state, ...value });
  };
  const fieldRequired = Schema.Types.StringType().isRequired(
    "To pole jest wymagane"
  );
  const RegionModelForm = Schema.Model({
    [regionFormFields.name]: fieldRequired,
  });

  useEffect(() => {
    if (regionId)
      RegionConnection.getForm(regionId).then((response) => {
        setState(response.data.model);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
  }, []);

  return (
    <>
      <WhiteCard>
        <Section title={"Region"}>
          <Form
            model={RegionModelForm}
            fluid
            onSubmit={handleSubmit}
            onChange={handleChange}
            formValue={state}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>{"Nazwa"}</Form.ControlLabel>
                <Form.Control
                  errorMessage={errors?.name}
                  name={regionFormFields.name}
                  accepter={Input}
                  placeholder={"Nazwa..."}
                />
              </Form.Group>
            </Col>
            <SeparatorEmpty />
            <div style={{ textAlign: "right" }}>
              <Button type={"submit"} appearance={"ghost"}>
                Zapisz
              </Button>
            </div>
          </Form>
        </Section>
      </WhiteCard>
    </>
  );
};

export default RegionEdit;
