import React, { FunctionComponent } from "react";
import Progress from "rsuite/Progress";

interface IProgressBarProps {
  completed: number;
}

const ProgressBar: FunctionComponent<IProgressBarProps> = ({ completed }) => {
  return <Progress.Line percent={completed} strokeColor="#ffaf38" />;
};

export default ProgressBar;
