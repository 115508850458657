// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RNkN88VddEyRHwfKdgSO .B3IgQvacvgyd7lztVNbf{margin-bottom:12px}", "",{"version":3,"sources":["webpack://./src/views/projects/components/visits/visitsEdit/VisitElements/Atoms/styles.module.scss"],"names":[],"mappings":"AAGA,4CACE,kBAAA","sourcesContent":[".mapActivityWrapper {\n}\n\n.mapActivityWrapper .alert {\n  margin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapActivityWrapper": "RNkN88VddEyRHwfKdgSO",
	"alert": "B3IgQvacvgyd7lztVNbf"
};
export default ___CSS_LOADER_EXPORT___;
