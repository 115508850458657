import { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "./MapWrapper";

interface IPolyLine {
  polyLine: google.maps.PolylineOptions;
  map: google.maps.Map;
}

const PolyLine: FunctionComponent<IPolyLine> = (props) => {
  const [google, setGoogle] = useState<null | any>(null);
  const [polyline, setPolyline] = useState<google.maps.Polyline>();

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  useEffect(() => {
    if (!google) return;

    if (!polyline) {
      const tmp = new google.maps.Polyline({
        ...props.polyLine,
        map: props.map,
      });
      setPolyline(tmp);
    }

    return () => {
      if (polyline) polyline.setMap(null);
    };
  }, [google]);

  return null;
};

export default PolyLine;
