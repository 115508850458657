import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Checkbox, SelectPicker, Tree } from "rsuite";
import UniversalModal from "../../UniversalModal";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { ItemDataType } from "rsuite/esm/@types/common";
import { v4 as uuid } from "uuid";

interface IProductListExtras {
  onSorted: (sorted: IRecurData[]) => void;
}

type TypeOuterCategories =
  | "types"
  | "brands"
  | "categories"
  | "abc"
  | "POS"
  | "GIFT"
  | "PRODUCT";

export interface IRecurData {
  id: string;
  label: string;
  value: TypeOuterCategories;
  selected: boolean;
  level: number;
  sortType?: "AZ" | "ZA";
  allowDeeper: boolean;
  alwaysChecked: boolean;
  children?: IRecurData[];
}

const ProductListExtras: FunctionComponent<IProductListExtras> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const outerCategories: IRecurData[] = [
    // need to stay predefined like that
    {
      id: uuid(),
      label: "Alfabetycznie",
      value: "abc",
      selected: false,
      level: 0,
      sortType: "AZ",
      allowDeeper: false,
      alwaysChecked: false,
    },
    {
      id: uuid(),
      label: "Typy",
      value: "types",
      selected: false,
      level: 0,
      allowDeeper: true,
      alwaysChecked: false,
    },
    {
      id: uuid(),
      label: "Marki",
      value: "brands",
      selected: false,
      level: 0,
      sortType: "AZ",
      allowDeeper: true,
      alwaysChecked: false,
    },
    {
      id: uuid(),
      label: "Kategorie",
      value: "categories",
      selected: false,
      level: 0,
      sortType: "AZ",
      allowDeeper: true,
      alwaysChecked: false,
    },
  ];

  const getOuterCat = (
    value: TypeOuterCategories,
    label: string,
    level: number,
    sortType?: "AZ" | "ZA",
    allowDeeper = true,
    alwaysChecked = false
  ): IRecurData => {
    const result: IRecurData = {
      id: uuid(),
      level: level,
      value: value,
      label: label,
      selected: alwaysChecked,
      allowDeeper: allowDeeper,
      sortType: sortType ?? undefined,
      alwaysChecked: alwaysChecked,
    };

    if (result.alwaysChecked) {
      const lvl = result.level + 1;
      result.children = _.cloneDeep(outerCategories)
        .filter((c) => c.value !== result.value)
        .map((c) => ({
          ...c,
          id: uuid(),
          level: lvl,
        }));
    }

    return result;
  };

  const getTypeSubCat = (): IRecurData[] => {
    return [
      getOuterCat(
        "POS",
        "Point Of Sale Materials (POSM)",
        1,
        undefined,
        true,
        true
      ),
      getOuterCat("GIFT", "Gratis", 1, undefined, true, true),
      getOuterCat("PRODUCT", "Produkt", 1, undefined, true, true),
    ];
  };

  const [recurState, setRecurState] =
    useState<Array<IRecurData>>(outerCategories);

  useEffect(() => {
    if (!modalOpen) {
      setRecurState(outerCategories);
    }
  }, [modalOpen]);

  const recurGetNode = (
    nodeId: string,
    nodes: IRecurData[]
  ): IRecurData | null => {
    let result: IRecurData | null = null;
    nodes.every((n) => {
      if (n.id === nodeId) {
        result = n;
      } else if (n.children && n.children.length > 0) {
        result = recurGetNode(nodeId, n.children);
      }
      return result === null;
    });

    return result;
  };

  const isSelected = (nodeId: string): boolean => {
    return recurGetNode(nodeId, recurState)?.selected ?? false;
  };

  // checkbox
  const onCheck = (_node: ItemDataType, checked: boolean) => {
    const stateCloned = _.cloneDeep(recurState);
    const node = recurGetNode(_node.id, stateCloned); // getting reference to node

    node.selected = checked;
    if (checked) {
      if (node.allowDeeper && !node.children) {
        // nest children
        if (node.value === "types") node.children = getTypeSubCat();
        else {
          node.children = _.cloneDeep(outerCategories)
            .filter((c) => c.value !== node.value)
            .map((c) =>
              getOuterCat(
                c.value,
                c.label,
                node.level + 1,
                c.sortType,
                c.allowDeeper,
                c.alwaysChecked
              )
            );
        }
      }
    } else {
      node.children = undefined;
    }

    setRecurState(stateCloned);
  };

  const TreeNodeRender = (nodeData: ItemDataType): JSX.Element => {
    const _isSelected = nodeData.id ? isSelected(nodeData.id) : false;

    const onChange = (value: "AZ" | "ZA") => {
      const stateCloned = _.cloneDeep(recurState);
      const node = recurGetNode(nodeData.id, stateCloned);
      if (!node) throw "Unknow node by id: " + nodeData.id;
      node.sortType = value;
      setRecurState(stateCloned);
    };

    return (
      <>
        <div style={{ opacity: _isSelected ? 1 : 0.65 }}>
          <Checkbox
            onChange={(value, checked) => onCheck(nodeData, checked)}
            readOnly={nodeData.alwaysChecked}
            checked={nodeData.selected}
          />
          &nbsp;{nodeData.label}
          {nodeData.sortType !== undefined && _isSelected && (
            <>
              &nbsp;&nbsp;
              <SelectPicker
                cleanable={false}
                searchable={false}
                size={"xs"}
                data={[
                  { value: "AZ", label: "A-Z" },
                  { value: "ZA", label: "Z-A" },
                ]}
                value={nodeData.sortType}
                onChange={onChange}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const getModalBody = (): JSX.Element => {
    return (
      <div style={{ minHeight: "400px" }}>
        <div style={{ padding: "10px" }}>
          <Tree
            valueKey={"id"}
            draggable
            onDrop={({ createUpdateDataFunction }) => {
              // @ts-ignore
              setRecurState(createUpdateDataFunction(recurState));
            }}
            renderTreeNode={TreeNodeRender}
            data={recurState}
            defaultExpandAll
          />
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    const result = _.cloneDeep(recurState);
    props.onSorted(result);
    setModalOpen(false);
  };

  return (
    <>
      <Button
        style={{ marginTop: "6px" }}
        size={"sm"}
        appearance={"ghost"}
        type={"button"}
        onClick={() => setModalOpen(!modalOpen)}>
        Sortowanie
      </Button>
      <UniversalModal
        title={"Sortowanie produktów i materiałów"}
        onClose={() => setModalOpen(false)}
        onSubmit={handleSubmit}
        body={getModalBody()}
        open={modalOpen}
      />

      <Helmet>
        <style>{`
      .rs-tree-node-label-content {
        padding: 0 !important;
      }
      .rs-checkbox-checker {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .rs-tree-node.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content,
      .rs-tree-node:hover > .rs-tree-node-label > .rs-tree-node-label-content {
        background: none !important;
      }
    `}</style>
      </Helmet>
    </>
  );
};

export default ProductListExtras;
