import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import ProjectsConnection from "utils/connections/projects";
import WhiteCard from "global/atoms/WhiteCard";
import {
  NotCancelErrorPromise,
  deepClone,
  getProjectIdFromUrl,
  handleToast,
  useQuery,
} from "utils/helpers";
import styles from "./styles.module.scss";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import {
  IOptionBase,
  IProjectRole,
  IRoleUsers,
} from "../../../../utils/models";
import { Checkbox, Button, RadioGroup, Toggle } from "rsuite";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { confirmModalCallback } from "../../../../redux/store";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { FormElementBase } from "utils/FormElement";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import Dropdown from "rsuite/Dropdown";
import CopyIcon from "@rsuite/icons/Copy";
import RolePermissions from "global/RolePermissions";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";

interface IAccess {}
interface IAssignedUsers {
  [index: string]: IRoleUsers;
}

export type ProjectRoleActionType =
  | "ACTION_NEW"
  | "ACTION_UPDATE"
  | "ACTION_DELETE"
  | "ACTION_DO_NOTHING";

const Access: FunctionComponent<IAccess> = () => {
  const { id } = useParams<{ id: string }>();
  const [rolesData, setRolesData] = useState<Array<IProjectRole> | null>(null);
  const [assignedUsers, setAssignedUsers] = useState<IAssignedUsers>();
  const [selectedRoleIds, setSelectedRoleIds] = useState<Array<string>>([]);
  const [disabledElements, setDisabledElements] = useState<Array<string>>([]);
  const roleDataChanged = useRef(false);
  const onTrip = useQuery().get("trip") !== null;
  const history = useHistory();
  const hasUserAssigned = useRef(false);
  const [loading, setLoading] = useState(false);
  const [pickerUpdate, setPickerUpdate] = useState(0);

  const load = () => {
    // /users/roles/assigned
    ProjectsConnection.getAssignedRoles(id).then((data) => {
      const tmp: IAssignedUsers = {};
      data.data.projectRoles.data.forEach((elm) => {
        // @ts-ignore
        tmp[elm.roleId] = elm.users;
        hasUserAssigned.current = true;
      });
      setAssignedUsers(tmp);
    }, NotCancelErrorPromise);

    // /roles/form
    ProjectsConnection.getFormEditRoles(id).then((data) => {
      setRolesData(data.data.projectRoles.options);
      setSelectedRoleIds(
        data.data.projectRoles.options.filter((r) => r.id).map((r) => r.id)
      );
      setDisabledElements(data.data.disabledElements);
    }, NotCancelErrorPromise);
    setLoading(false);
  };
  useEffect(load, []);

  // project role assignment changed
  useEffect(() => {
    if (roleDataChanged.current) storeRoleAssignment();
  }, [rolesData, selectedRoleIds]);

  // project role users assignment changed
  useEffect(() => {
    if (roleDataChanged.current) storeUsersAssignment();
  }, [assignedUsers]);

  const storeGetActionType = (item: IProjectRole): ProjectRoleActionType => {
    let actionType: ProjectRoleActionType = "ACTION_NEW";
    if (item.id.length > 0 && selectedRoleIds.includes(item.roleId))
      actionType = "ACTION_UPDATE";
    else if (item.id.length > 0 && !selectedRoleIds.includes(item.roleId))
      actionType = "ACTION_DELETE";
    else if (!item.id.length && !selectedRoleIds.includes(item.roleId))
      actionType = "ACTION_DO_NOTHING";
    else if (!item.id.length && selectedRoleIds.includes(item.roleId))
      actionType = "ACTION_NEW";
    return actionType;
  };

  const storeRoleAssignment = () => {
    setLoading(true);
    roleDataChanged.current = false;
    const data = {
      roles:
        rolesData?.map((r) => ({
          roleId: r.roleId,
          reporting: r.reporting,
          actionType: storeGetActionType(r),
        })) ?? [],
    };
    handleToast(
      ProjectsConnection.assignRolesToProject(data),
      undefined,
      "Błąd, nie zapisano zmian",
      "Zapisano zmiany"
    ).then(load, NotCancelErrorPromise);
  };

  const storeUsersAssignment = () => {
    if (!assignedUsers) return;
    setLoading(true);
    roleDataChanged.current = false;
    const data = {
      roles: Object.entries(assignedUsers).map((au) => ({
        roleId: au[0],
        assigned: au[1].assigned.map((u) => ({ id: u.id })),
        unassigned: au[1].unassigned.map((u) => ({ id: u.id })),
      })),
    };
    handleToast(
      ProjectsConnection.assignUsersToProject(data),
      undefined,
      "Błąd, nie zapisano zmian",
      "Zapisano zmiany"
    ).then(load);
  };

  const handleChangeIsReporting = (item: IProjectRole): void => {
    console.log("handleChangeIsReporting", item);
    // this should not happen
    if (item.id === "" || !rolesData || item.reporting) return;

    const msg = `Czy na pewno chcesz oznaczyć grupę "${item.roleName}" jako grupę raportującą?`;
    return confirmModalCallback(msg, () => {
      roleDataChanged.current = true;
      setRolesData(
        rolesData.map((rd) => ({
          ...rd,
          reporting: rd.id === item.id,
        }))
      );
    });
  };

  const handleToggleRole = (item: IProjectRole) => {
    const msg = selectedRoleIds.includes(item.roleId)
      ? `Czy na pewno chcesz usunąć grupę "${item.roleName}" z projektu?`
      : `Czy na pewno chcesz przypisać grupę "${item.roleName}" do projektu?`;

    return confirmModalCallback(msg, () => {
      if (item.reporting && selectedRoleIds.includes(item.roleId)) {
        ToastNotificationPush(
          ToastTypes.warning,
          "Uwaga!",
          "Nie możesz odłączyć od projektu grupy raportującej!",
          10000
        );
        return false;
      }

      const tmp = deepClone(selectedRoleIds);
      if (selectedRoleIds.includes(item.roleId)) {
        delete tmp[tmp.indexOf(item.roleId)];
      } else tmp.push(item.roleId);

      roleDataChanged.current = true;
      setSelectedRoleIds(tmp);
    });
  };

  const getRoleUsers = (
    item: IProjectRole,
    type: "assigned" | "unassigned"
  ): Array<IOptionBase> => {
    if (assignedUsers && assignedUsers[item.roleId])
      return assignedUsers[item.roleId][type];
    return [];
  };

  const roleUserAssigmentChange = (
    item: IProjectRole,
    assignedUserIds: Array<string>,
    unAssignedUserIds: Array<string>
  ): void => {
    const data = assignedUsers && assignedUsers[item.roleId];
    if (!data) return;

    const msg = `Czy na pewno chcesz zmienić przypisanie użytkowników w grupie "${item.roleName}"?`;
    return confirmModalCallback(msg, () => {
      roleDataChanged.current = true;
      hasUserAssigned.current = true;
      const allUsers = [...data.assigned, ...data.unassigned];

      setAssignedUsers({
        ...assignedUsers,
        [item.roleId]: {
          assigned: allUsers.filter((i) => assignedUserIds.includes(i.id)),
          unassigned: allUsers.filter((i) => unAssignedUserIds.includes(i.id)),
        },
      });

      setPickerUpdate(Date.now());
    });
  };

  const tripContinue = () => {
    if (!selectedRoleIds.length) {
      ToastNotificationPush(ToastTypes.error, "Nie wybrano żadnej grupy!");
      return;
    }

    if (!hasUserAssigned.current) {
      ToastNotificationPush(
        ToastTypes.error,
        "Nie przypisano żadnego użytkownika!"
      );
      return;
    }

    if (rolesData?.find((r) => r.reporting) === undefined) {
      ToastNotificationPush(
        ToastTypes.error,
        "Nie wybrano grupy raportującej!"
      );
      return;
    }

    history.push(
      generatePath("/projects/:id/edit/locations/?trip", {
        id: id,
      })
    );
  };

  const reporterButton = (item: IProjectRole): JSX.Element => {
    if (item.roleType !== "MERCH") return <></>;
    return (
      <Toggle
        disabled={
          (disabledElements.includes(FormElementBase.saveButton) ?? false) ||
          !item.id.length
        }
        onChange={handleChangeIsReporting.bind(null, item)}
        checked={item.reporting}
        loading={loading}
        checkedChildren="Raportująca"
        unCheckedChildren=""
      />
    );
  };

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<CopyIcon />}
            onClick={() => history.push(`/projects/${id}/edit/user-linked`)}>
            Powiązania użytkowników
          </Dropdown.Item>
          <Dropdown.Item
            icon={<CopyIcon />}
            onClick={() => history.push(`/projects/${id}/edit/access-levels`)}>
            Poziomy zatwierdzania / Widoczność ankiet
          </Dropdown.Item>
        </OptionsDropdown>
      </HeaderButtons>
      <WhiteCard
        padding={true}
        style={{ marginTop: "24px", paddingTop: "10px" }}>
        {rolesData === null ? (
          <SpinnerSmall />
        ) : (
          rolesData.length > 0 && (
            <>
              <div className={styles.basicWrapperHeader}>
                <div style={{ width: "100px" }}>Aktywna</div>
                <span style={{ flex: 1 }}>GRUPA</span>
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.15em",
                  }}>
                  RAPORTUJĄCA
                </div>
              </div>
              <div>
                <RadioGroup value={rolesData.find((r) => r.reporting)?.id}>
                  {rolesData.map((item: IProjectRole) => (
                    <div key={`key-${item.roleId}`}>
                      <div className={styles.selectedRoles}>
                        <div style={{ width: "100px" }}>
                          <Checkbox
                            checked={selectedRoleIds.includes(item.roleId)}
                            disabled={
                              (disabledElements.includes(
                                FormElementBase.saveButton
                              ) ??
                                false) ||
                              loading
                            }
                            onChange={handleToggleRole.bind(null, item)}
                          />
                        </div>
                        <span style={{ flex: 1 }}>{item.roleName}</span>
                        <div
                          style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "right",
                          }}>
                          {reporterButton(item)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: selectedRoleIds.includes(item.roleId)
                            ? "initial"
                            : "none",
                        }}>
                        {selectedRoleIds.includes(item.roleId) && (
                          <>
                            {assignedUsers && assignedUsers[item.roleId] ? (
                              <>
                                <SeparatorEmpty size={1} />
                                <DoubleSidePicker
                                  itemType={"user"}
                                  key={`role-user-selector-${item.roleId}-${pickerUpdate}`}
                                  loading={loading}
                                  onChange={(
                                    assignedUserIds,
                                    unAssignedUserIds
                                  ) => {
                                    roleUserAssigmentChange(
                                      item,
                                      assignedUserIds,
                                      unAssignedUserIds
                                    );
                                  }}
                                  assigned={getRoleUsers(item, "assigned")}
                                  unassigned={getRoleUsers(item, "unassigned")}
                                  heading={`Użytkownicy grupy ${item.roleName}`}
                                  emptyMessageAssigned={
                                    "Brak przypisanych użytkowników"
                                  }
                                  emptyMessageNotAssigned={
                                    "Brak użytkowników w projekcie"
                                  }
                                />
                                <SeparatorEmpty size={0.5} />
                              </>
                            ) : (
                              <SpinnerSmall />
                            )}
                            <div
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}>
                              <RolePermissions
                                projectId={getProjectIdFromUrl()}
                                roleId={item.roleId}
                              />
                              <SeparatorEmpty size={2} />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            </>
          )
        )}

        <br />

        {onTrip && (
          <ActionsContainer>
            <Button appearance={"ghost"} onClick={tripContinue}>
              Kontynuuj
            </Button>
          </ActionsContainer>
        )}
      </WhiteCard>
    </>
  );
};

export const AccessRoute = {
  path: "/projects/:id/edit/access",
  breadcrumb: "Prawa dostępu",
  Component: Access,
};
export default Access;
