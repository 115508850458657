import React from "react";
import { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import Select from "../../../../global/atoms/Select";
import {
  TypeComparisonFormula,
  TypeExternalComparisonValue,
  TypeQuestion,
} from "../../../../utils/types";
import { Button, Input } from "rsuite";
import { parseInt } from "lodash";
import { IDependOnQuestionValueCondition } from "../../../../utils/models";
import { deepClone } from "../../../../utils/helpers";
import {
  BOOLEAN_QUESTION,
  DECIMAL_QUESTION,
  INTEGER_QUESTION,
} from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";

interface IDependOnQuestionConditionSegment {
  questionType: TypeQuestion;
  initialState: IDependOnQuestionValueCondition;
  state: IDependOnQuestionValueCondition;
  setState: React.Dispatch<
    React.SetStateAction<IDependOnQuestionValueCondition>
  >;
  andDeepLvl: number;
}

const DependOnQuestionConditionSegment: FunctionComponent<
  IDependOnQuestionConditionSegment
> = (props) => {
  const formulaOptions: Array<{ id: TypeComparisonFormula; name: string }> = [
    { id: "<", name: "mniejsza" },
    { id: ">", name: "większa" },
    { id: "<=", name: "mniejsza, równa" },
    { id: ">=", name: "większa, równa" },
    { id: "=", name: "równa" },
  ];

  const comparisonValueOptions: Array<{
    id: TypeExternalComparisonValue | "";
    name: string;
  }> = [
    { id: "", name: "podanej wartości" },
    { id: "MIN_STAN", name: "Minimalny stan produktu" },
    { id: "MIN_ZAM", name: "Minimalna wartość zamówienia" },
  ];

  const booleanValueOptions: Array<{ id: string; name: string }> = [
    { id: "0", name: "NIE" },
    { id: "1", name: "TAK" },
  ];

  const proxySetState = (stateKey: string, stateValue: any): void => {
    const stateCloned = deepClone(props.state);

    const __recur = (
      state: IDependOnQuestionValueCondition,
      andDeep: number
    ) => {
      if (andDeep === props.andDeepLvl) {
        // @ts-ignore
        state[stateKey] = stateValue;
      } else {
        if (!state.and) throw "proxySetState unreachable deep lvl!";
        __recur(state.and, ++andDeep);
      }
    };

    __recur(stateCloned, 0);
    props.setState(stateCloned);
  };

  const proxyGetState = (): IDependOnQuestionValueCondition => {
    const __recur = (
      state: IDependOnQuestionValueCondition,
      andDeep: number
    ): IDependOnQuestionValueCondition => {
      if (andDeep === props.andDeepLvl) return state;
      if (state.and === null) throw "proxyGetState unreachable deep lvl!";
      return __recur(state.and, ++andDeep);
    };
    return __recur(props.state, 0);
  };

  const onAndClick = () => {
    proxySetState("and", props.initialState);
  };

  const isBoolType = (): boolean => {
    return props.questionType === BOOLEAN_QUESTION;
  };

  const getCustomComparisonValueFormElm = (): JSX.Element => {
    switch (props.questionType) {
      case INTEGER_QUESTION:
      case DECIMAL_QUESTION:
        return (
          <Input
            type={"number"}
            value={proxyGetState().intValue ?? ""}
            onChange={(value) => {
              proxySetState("intValue", value == "" ? null : parseInt(value));
            }}
          />
        );
      case BOOLEAN_QUESTION:
        return (
          <Select
            options={booleanValueOptions}
            value={
              proxyGetState().boolValue === null
                ? ""
                : proxyGetState().boolValue
                ? "1"
                : "0"
            }
            onChange2={(selectedOption) => {
              proxySetState("boolValue", selectedOption.value === "1");
            }}
            name={"boolean-custom-comparison-value"}
            placeholder={""}
          />
        );
    }

    return <></>;
  };

  return (
    <>
      <div className={styles.conditionBuilderUnit}>
        <div className={styles.conditionFormulaSegment}>
          <p>WARTOŚĆ ODPOWIEDZI JEST</p>
          <Select
            options={formulaOptions}
            style={{ width: "210px" }}
            disabled={isBoolType()}
            value={proxyGetState().comparisonFormula}
            name={"condition-formula"}
            placeholder={""}
            onChange2={(selected) => {
              proxySetState("comparisonFormula", selected.value);
            }}
          />
          {proxyGetState().comparisonFormula !== "=" && <p>OD</p>}
        </div>

        <div className={styles.conditionFormulaSegment}>
          <Select
            options={comparisonValueOptions}
            value={proxyGetState().externalComparisonValue ?? ""}
            disabled={isBoolType()}
            name={"comparison-value"}
            placeholder={""}
            onChange2={(selected) => {
              // @ts-ignore
              const value: TypeExternalComparisonValue =
                selected.value === "" ? null : selected.value.toString();
              proxySetState("externalComparisonValue", value);
            }}
          />
          {!proxyGetState().externalComparisonValue &&
            getCustomComparisonValueFormElm()}
        </div>

        {!isBoolType() && !proxyGetState().and && (
          <div className={styles.conditionFormulaSegment}>
            <Button appearance={"ghost"} onClick={onAndClick}>
              Oraz
            </Button>
          </div>
        )}

        {proxyGetState().and && (
          <>
            <div className={styles.andConditionSeparator}>
              <span>ORAZ</span>
            </div>
            <DependOnQuestionConditionSegment
              andDeepLvl={props.andDeepLvl + 1}
              initialState={props.initialState}
              state={props.state}
              setState={props.setState}
              questionType={props.questionType}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DependOnQuestionConditionSegment;
