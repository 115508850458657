import { IRouteEntity } from "../utils/models";
import SecurityPasswordChange from "views/security/SecurityPasswordChange";
import ErrorPage from "../ErrorPage";
import projectRoutes from "./projects";
import adminRoutes from "./admin";
import productRoutes from "./products";
import datasetRoutes from "./datasets";
import superAdminRoutes from "./superadmin";
import visitReviewRoutes from "./visitReview";
import globalReportsRoutes from "./globalReports";
import scheduleRoutes from "./schedule";
import contractServiceTypeRoutes from "./contract/serviceType/index";
import contractDocumentTemplatesRoutes from "./contract/contractDocumentTemplates/index";
import contractRoutes from "./contract/contract/index";
import contractProjectRoutes from "./contract/project/index";
import contractEquipmentRoutes from "./contract/equipment/index";
import regionRoutes from "./regions";
import Dashboard from "../views/dashboard/Dashboard";

const commonRoutes: IRouteEntity[] = [
  { path: "/" },
  { path: "/dashboard", breadcrumb: "Dashboard", Component: Dashboard },
  {
    path: "/user/change-own-password",
    breadcrumb: "Zmiana hasła",
    Component: SecurityPasswordChange,
  },
  { path: "/err/:errorCode/*", Component: ErrorPage },
  { path: "*", Component: ErrorPage },
];

// todo: webpack dynamic import
// export const getScopeRoutes = async () => {

//   // const import_test = await import(/* webpackChunkName: "project" */ './projects/index').then(m => m.default)
//   // console.log('aaa', getScope(), import_test);

//   switch(getScope()) {
//     case 'project':
//       return [
//         ...await import(/* webpackChunkName: "project" */ './projects/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'admin':
//       return [
//         ...await import(/* webpackChunkName: "admin" */ './admin/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'products':
//       return [
//         ...await import(/* webpackChunkName: "products" */ './products/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'datasets':
//       return [
//         ...await import(/* webpackChunkName: "datasets" */ './datasets/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'superadmin':
//       return [
//         ...await import(/* webpackChunkName: "superadmin" */ './superadmin/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'visitReview':
//       return [
//         ...await import(/* webpackChunkName: "visit-review" */ './visitReview/index').then(m => m.default),
//         ...commonRoutes
//       ]
//     case 'globalReports':
//       return [
//         ...await import(/* webpackChunkName: "global-reports" */ './globalReports/index').then(m => m.default),
//         ...commonRoutes
//       ]
//       case 'schedule':
//       return [
//         ...await import(/* webpackChunkName: "schedule" */ './schedule/index').then(m => m.default),
//         ...commonRoutes
//       ]
// case 'contracts':
// return [
//   ...await import(/* webpackChunkName: "contracts" */ './contract/contract/index').then(m => m.default),
//   ...commonRoutes
// ]
//   }
// }

export const allRoutes: IRouteEntity[] = [
  ...projectRoutes,
  ...adminRoutes,
  ...productRoutes,
  ...datasetRoutes,
  ...superAdminRoutes,
  ...visitReviewRoutes,
  ...globalReportsRoutes,
  ...scheduleRoutes,
  ...regionRoutes,
  ...contractDocumentTemplatesRoutes,
  ...contractRoutes,
  ...contractProjectRoutes,
  ...contractEquipmentRoutes,
  ...contractServiceTypeRoutes,
  ...commonRoutes,
];
