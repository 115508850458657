import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import WhiteCard from "../../../../../global/atoms/WhiteCard";
import { Button, Col, Input, Row } from "rsuite";
import _ from "lodash";
import VisitsConnection from "../../../../../utils/connections/visits";
import ActionsContainer from "../../../../../global/atoms/ActionsContainer";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import Section from "../../../../../global/atoms/section/Section";
import Spinner from "../../../../../global/atoms/Spinner/Spinner";
import { handleToast } from "../../../../../utils/helpers";
import { VISIT_COMMENTS_ANCHOR } from "../visitsEdit/VisitElements/VisitComments";

const VisitsFeedbackActions: FunctionComponent = () => {
  const [feedback, setFeedback] = useState<string | null>();
  const { id, visitId } = useParams<{ id: string; visitId: string }>();
  const [comment, setComment] = useState("");
  const [spinner, setSpinner] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  const redirectToCommentsList = () =>
    history.push(
      `/projects/${id}/visits/${visitId}/edit#${VISIT_COMMENTS_ANCHOR}`
    );
  const backToVisit = () =>
    history.push(`/projects/${id}/visits/${visitId}/edit`);

  const sendData = (data) => {
    setSpinner(true);
    return handleToast(
      VisitsConnection.setComments(visitId, data),
      undefined,
      "Błąd, Akcja nieudana",
      "Pomyślnie zaktualizowano status wizyty."
    ).then((resp) => {
      if (resp.status == 200) {
        redirectToCommentsList();
      }
      setSpinner(false);
    });
  };

  const sendStatusData = (data) => {
    setSpinner(true);
    return handleToast(
      VisitsConnection.setApprove(visitId, data),
      undefined,
      "Błąd, Akcja nieudana",
      "Pomyślnie zaktualizowano status wizyty."
    ).then((resp) => {
      if (resp.status == 200) {
        redirectToCommentsList();
      }
      setSpinner(false);
    });
  };

  const handleSubmitComment = () => {
    const _data = {
      visitComments: [
        {
          content: comment,
          status: "GENERAL_FEEDBACK_TO-REVIEW",
        },
      ],
    };
    sendData(_data);
  };

  urlParams.values();

  useEffect(() => {
    //feedbackStatus=GENERAL_FEEDBACK_TO-REVIEW
    if (urlParams.has("feedbackStatus")) {
      setFeedback(urlParams.get("feedbackStatus"));
      setSpinner(false);
    } else if (urlParams.has("visitStatus")) {
      const _data = {
        status: urlParams.get("visitStatus"),
      };
      sendStatusData(_data);
    } else {
      //nothing to do redirect to visit
      backToVisit();
    }
  }, []);
  if (spinner) return <Spinner />;
  return (
    <>
      {feedback == "GENERAL_FEEDBACK_TO-REVIEW" && (
        <>
          <WhiteCard>
            <Section title={"Dodaj komentarz do wizyty"}>
              <Row>
                <Col md={12} xs={24}>
                  <Input
                    as="textarea"
                    rows={3}
                    placeholder="Nowy komentarz do wizyty"
                    maxLength={255}
                    value={comment}
                    onChange={(value) => setComment(value.toString())}
                  />
                  <SeparatorEmpty />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <ActionsContainer gapSize={10}>
                    <Button
                      appearance={"ghost"}
                      onClick={() => setComment("")}
                      disabled={!comment}>
                      Wyczyść
                    </Button>
                    <Button onClick={backToVisit} appearance="ghost">
                      Idź do wizyty
                    </Button>
                    <Button
                      onClick={handleSubmitComment}
                      disabled={_.isEmpty(comment)}
                      appearance="primary">
                      Zatwierdź
                    </Button>
                  </ActionsContainer>
                </Col>
              </Row>
            </Section>
          </WhiteCard>
        </>
      )}
    </>
  );
};
export default VisitsFeedbackActions;
