import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  ICreateContractProject,
  IGetContractProject,
  IGetContractProjectDocument,
} from "../../models/contract/contractProjectModels";

export const path = `/contracts/projects`;
export const url = `${getBaseApiUrl()}/organizations/${getOrganizationId()}`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractProjectConnection = {
  getContractProject: (data: IGetContractProject) => {
    return AxiosHelper.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getContractProjectDocuments: (data: IGetContractProjectDocument) => {
    return AxiosHelper.post(`${baseUrl}/documents/templates/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContractProject: (data: ICreateContractProject) => {
    return AxiosHelper.post(baseUrl, data, {
      headers: getAuthHeader(),
    });
  },
  updateContractProject: (id: string, data: ICreateContractProject) => {
    return AxiosHelper.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersContractProject: () => {
    return AxiosHelper.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddContractProject: () => {
    return AxiosHelper.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractProject: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return AxiosHelper.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
  delete: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}`, { ids: data });
  },
  getListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  getFilterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },

  getFormAddContractProjectDocument: () => {
    return AxiosHelper.get(`${baseUrl}/documents/templates/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractProjectDocument: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/documents/templates/form`, {
      headers: getAuthHeader(),
    });
  },
  getContractProjectDocumentListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/documents/templates/filter/form`);
  },

  getContractProjectDocumentUnAssignedListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${url}/contracts/documents/templates/filter/form`);
  },

  getContractProjectAssignedDocuments: (
    id: string,
    data: IGetContractProjectDocument
  ) => {
    return AxiosHelper.post(
      `${baseUrl}/documents/templates/${id}/assigned`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getContractProjectUnAssignedDocuments: (
    id: string,
    data: IGetContractProjectDocument
  ) => {
    return AxiosHelper.post(
      `${baseUrl}/documents/templates/${id}/unassigned`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  addContractProjectDocument: (id: string, data: Array<{ id: string }>) => {
    return AxiosHelper.post(
      `${baseUrl}/${id}/documents/templates`,
      { ids: data },
      {
        headers: getAuthHeader(),
      }
    );
  },
  removeContractProjectDocument: (id: string, data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(
      `${baseUrl}/${id}/documents/templates`,
      { ids: data },
      {
        headers: getAuthHeader(),
      }
    );
  },
};

export default ContractProjectConnection;
