import React, { FunctionComponent, useRef, useState } from "react";
import { confirmModalHandler } from "../../../../../redux/store";
import { handleToast } from "../../../../../utils/helpers";
import ListingConnection from "../../../../../utils/connections/listing";
import { Button, Drawer } from "rsuite";
import { TypeListingType } from "../../../../../utils/types";
import TableUniversal, { IUniversalListing } from "./TableUniversal";
import TableItemLevels, { IItemLevelListing } from "./TableItemLevels";
import { Helmet } from "react-helmet-async";
import { AxiosPromise } from "axios";

interface IListingNetworkEdit {
  activityId: string;
  listingType: TypeListingType | null;
  onClose: () => void;
}

const ListingDrawer: FunctionComponent<IListingNetworkEdit> = (props) => {
  const [listing, setListing] = useState<Array<any>>([]);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    props.onClose();
  };

  const ListingTitle = {
    "": "",
    question: "Listing pytań",
    product: "Listing produktów",
    itemHighlight: "Wyróżnione produkty",
    "product-levels": "Stany minimalne/zamówień",
  };

  const handleSave = (): void => {
    let connectionFunc: (
      activityId: string,
      listing: Array<IUniversalListing & IItemLevelListing>
    ) => AxiosPromise<any>;
    switch (props.listingType) {
      case "question":
        connectionFunc = ListingConnection.saveQuestionListing;
        break;
      case "product":
        connectionFunc = ListingConnection.saveProductListing;
        break;
      case "itemHighlight":
        connectionFunc = ListingConnection.saveItemHighlightListing;
        break;
      case "product-levels":
        connectionFunc = ListingConnection.saveItemLevelListing;
        break;
      default:
        throw "Unknown listing type";
    }

    if (!connectionFunc) throw "Unknown listing type";
    handleToast(connectionFunc(props.activityId, listing)).then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Drawer
        placement={"bottom"}
        style={{ height: "80%" }}
        size={"lg"}
        key={"drawer-" + props.listingType}
        open={props.listingType !== null}
        onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>
            {ListingTitle[props.listingType ?? ""] ?? "Listing"}
          </Drawer.Title>
          <Drawer.Actions>
            <Button appearance={"ghost"} onClick={handleClose}>
              Zamknij
            </Button>
            <Button
              onClick={confirmModalHandler("Czy jesteś pewien?", handleSave)}
              appearance="primary">
              Zapisz
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 0 }}>
          {props.listingType === null ? (
            <></>
          ) : (
            <div
              style={{ position: "relative", height: "100%" }}
              ref={parentRef}>
              {props.listingType === "product-levels" ? (
                <TableItemLevels
                  parentRef={parentRef}
                  activityId={props.activityId}
                  state={listing}
                  setState={setListing}
                />
              ) : (
                <TableUniversal
                  parentRef={parentRef}
                  activityId={props.activityId}
                  listingType={props.listingType}
                  state={listing}
                  setState={setListing}
                />
              )}
            </div>
          )}
        </Drawer.Body>
      </Drawer>

      <Helmet>
        <style>{`
        .rs-table .rs-table-row,
        .rs-table-row .rs-table-cell-group > .rs-table-cell {
          background-color: transparent !important;
          border-bottom: 1px solid #eee;
        }



        .rs-table .rs-table-row:hover,
        .rs-table .rs-table-row:hover .rs-table-cell-group > .rs-table-cell {
          background-color: #DCDCDC !important;
        }
      `}</style>
      </Helmet>
    </>
  );
};

export default ListingDrawer;
