import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { ICreateChain, IGetChains } from "../models";
import { AxiosPromise } from "axios";

const ChainsConnection = {
  getChains: (data: IGetChains) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createChain: (data: ICreateChain) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateChain: (id: string, data: ICreateChain) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/${id}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },

  getChainsFiltersForm: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getChainsFormAdd: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getChainsFormEdit: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks`,
      {
        headers: { ...getAuthHeader() },
        data: { networks: data },
      }
    );
  },
  getFilterForm: (): AxiosPromise => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/networks/filter/form`
    );
  },
};

export default ChainsConnection;
