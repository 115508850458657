import React, { FunctionComponent, useEffect, useState } from "react";
import { IKilometersFilterForm, IRequestKilometers } from "./models";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import KilometersConnection from "../../../../utils/connections/kilometers";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { handleToast } from "../../../../utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import { Col, DatePicker, Form, InputNumber, Row, SelectPicker } from "rsuite";
import Section from "../../../../global/atoms/section/Section";

const KilometersEdit: FunctionComponent = () => {
  const [form, setForm] = useState<IKilometersFilterForm | null>(null);
  const [errors, setErrors] = useState<{
    userId: string | undefined;
    date: string | undefined;
  }>({
    userId: undefined,
    date: undefined,
  });
  const [data, setData] = useState<IRequestKilometers>({
    userId: "",
    date: new Date(),
    privateKilometers: 0,
    fuelPrice: 0,
    fuelLiters: 0,
    valueStart: 0,
    valueStop: 0,
  });

  const history = useHistory();
  const { id, kilometersId } = useParams<{
    id: string;
    kilometersId: string;
  }>();

  useEffect(() => {
    if (kilometersId !== undefined) {
      KilometersConnection.editForm(kilometersId).then((response) => {
        setForm(response.data);
        const _data = response.data.kilometers;
        _data.date = new Date(_data.date);
        setData(_data);
      });
    } else {
      KilometersConnection.addForm().then((response) => {
        setForm(response.data);
      });
    }
  }, []);

  if (form === null) return <SpinnerSmall />;

  const handleSubmit = () => {
    const dataClone: any = { ...data };
    dataClone.privateKilometers = parseInt(dataClone.privateKilometers);
    dataClone.fuelPrice = parseFloat(dataClone.fuelPrice);
    dataClone.fuelLiters = parseFloat(dataClone.fuelLiters);
    dataClone.valueStart = parseInt(dataClone.valueStart);
    dataClone.valueStop = parseInt(dataClone.valueStop);

    if (kilometersId !== undefined) {
      handleToast(
        KilometersConnection.update(kilometersId, dataClone),
        setErrors
      ).then((response) => {
        console.log(response);
        if (response.status === 200) {
          history.push(`/projects/${id}/kilometers`);
        }
      });
    } else {
      handleToast(KilometersConnection.create(dataClone), setErrors).then(
        (response) => {
          console.log(response);
          if (response.status === 200) {
            history.push(`/projects/${id}/kilometers`);
          }
        }
      );
    }
  };
  const handleFormChange = (formValue: any) => {
    setData({ ...data, ...formValue });
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form fluid onChange={handleFormChange} onSubmit={handleSubmit}>
          <Section title={"Pracownik"}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Użytkownik</Form.ControlLabel>
                <Form.Control
                  block
                  disabled={!!kilometersId}
                  name="userId"
                  data={form.users.options}
                  labelKey={"name"}
                  valueKey={"id"}
                  accepter={SelectPicker}
                  value={data.userId}
                  errorMessage={
                    errors.userId ?? !data.userId
                      ? "Pole Użytkownik jest wymagane"
                      : null
                  }
                  errorPlacement={"bottomEnd"}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Data</Form.ControlLabel>
                <Form.Control
                  isoWeek
                  oneTap
                  block
                  /*                  disabledDate={(date) => dayjs(date).isBefore(dayjs().add(-2, "day")) ||
                      dayjs(date).isAfter(dayjs().add(30, "day"))
                  }*/
                  showWeekNumbers
                  value={data.date}
                  name={"date"}
                  accepter={DatePicker}
                  ranges={[
                    {
                      label: "Dziś",
                      value: new Date(),
                    },
                  ]}
                  errorMessage={
                    errors.date ?? !data.date ? "Pole Data jest wymagane" : null
                  }
                  errorPlacement={"bottomEnd"}
                />
              </Form.Group>
            </Col>
            <SeparatorEmpty />
          </Section>
          <SeparatorEmpty size={2} />
          <Section title={"Klilometry"}>
            <Row>
              <Col xs={8}>
                <Form.Group>
                  <Form.ControlLabel>Wartość początkowa</Form.ControlLabel>
                  <Form.Control
                    style={{ width: "100%" }}
                    name={"valueStart"}
                    value={data.valueStart}
                    accepter={InputNumber}
                    postfix="km"
                    placeholder={"Wartość początkowa"}
                  />
                </Form.Group>
              </Col>
              <Col xs={8}>
                <Form.Group>
                  <Form.ControlLabel>Wartość końcowa</Form.ControlLabel>
                  <Form.Control
                    postfix="km"
                    style={{ width: "100%" }}
                    name={"valueStop"}
                    value={data.valueStop}
                    accepter={InputNumber}
                  />
                </Form.Group>
              </Col>
              <Col xs={8}>
                <Form.Group>
                  <Form.ControlLabel>
                    Kilometry przejechane prywatnie
                  </Form.ControlLabel>
                  <Form.Control
                    postfix="km"
                    style={{ width: "100%" }}
                    name={"privateKilometers"}
                    value={data.privateKilometers}
                    accepter={InputNumber}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Section>
          <SeparatorEmpty size={2} />
          <Section title={"Paliwo"}>
            <Row>
              <Col xs={8}>
                <Form.Group>
                  <Form.ControlLabel>Cena paliwa</Form.ControlLabel>
                  <Form.Control
                    postfix="PLN"
                    style={{ width: "100%" }}
                    name={"fuelPrice"}
                    step={0.01}
                    value={data.fuelPrice}
                    accepter={InputNumber}
                  />
                </Form.Group>
              </Col>
              <Col xs={8}>
                <Form.Group>
                  <Form.ControlLabel>Litry paliwa</Form.ControlLabel>
                  <Form.Control
                    postfix="L"
                    style={{ width: "100%" }}
                    name={"fuelLiters"}
                    value={data.fuelLiters}
                    accepter={InputNumber}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Section>
          <SeparatorEmpty size={2} />
          <ActionsContainer>
            <ButtonSubmit
            //disabled={!data.userId || !data.date}
            />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default KilometersEdit;
