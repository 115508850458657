import React, { FunctionComponent } from "react";
import CSS from "csstype";

interface IForm {
  children: React.ReactNode;
  handleSubmit?: (event: any) => void;
  onChange?: () => void;
  style?: CSS.Properties;
}

const Form: FunctionComponent<IForm> = ({
  children,
  handleSubmit,
  style,
  onChange,
}) => {
  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "5px",
        ...style,
      }}
      onSubmit={handleSubmit}
      onChange={onChange}>
      {children}
    </form>
  );
};

export default Form;
