import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import StoreDocumentsConnection from "../../../../utils/connections/storeDocuments";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Input from "../../../../global/atoms/Input";
import Select from "../../../../global/atoms/Select";
import { ISelectViewResponse } from "../../../../utils/models";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import {
  getAddressFullName,
  handleToastRedux,
} from "../../../../utils/helpers";
import {
  createSummaryRowData,
  getDocumentPositionsBody,
} from "../documents/DocumentDetails";
import { Col, IconButton, Row } from "rsuite";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import download from "downloadjs";
import { useDispatch } from "react-redux";

interface IStoreDocumentDetailsForm {
  model: any;
  saleTypes: ISelectViewResponse;
}

export const downloadStoreDocuments = (
  documentId: string,
  dispatch: Dispatch<any>
) => {
  handleToastRedux(StoreDocumentsConnection.getPdf(documentId), dispatch).then(
    (res) => {
      download(res.data, res.headers["x-filename"] ?? null, res.data.type);
    }
  );
};

const StoreDocumentDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState<IStoreDocumentDetailsForm | null>(null);
  const { id, documentId } = useParams<{ id: string; documentId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    StoreDocumentsConnection.editForm(documentId, id).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);
      setForm(response.data);
    });
  }, []);

  if (form === null || positions === null) return <Spinner />;
  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance="ghost"
          onClick={() => downloadStoreDocuments(documentId, dispatch)}
          icon={<FileDownloadIcon />}>
          .PDF
        </IconButton>
      </HeaderButtons>
      <WhiteCard>
        <Row>
          <Col xs={8}>
            <Input
              value={form.model.documentNumber}
              placeholder={"Numer"}
              name={"documentNumber"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.user.fullName}
              placeholder={"Wystawił"}
              name={"user"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Select
              placeholder={"Rodzaj dokumentu"}
              name={"saleTypeId"}
              value={form.model.saleType.id}
              options={form.saleTypes.options}
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={8}>
            <Input
              value={form.model.documentDate}
              placeholder={"Data wystawienia"}
              name={"documentDate"}
              disabled={true}
            />
          </Col>
          <Col xs={16}>
            <Input
              value={getAddressFullName(form.model.location.address)}
              placeholder={"Adres"}
              name={"address"}
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Input
              value={form.model.description}
              placeholder={"Uwagi"}
              name={"description"}
              disabled={true}
            />
          </Col>
        </Row>

        <SeparatorLine size={2} />

        {getDocumentPositionsBody(positions)}
      </WhiteCard>
    </>
  );
};

export default StoreDocumentDetails;
