import React, { FunctionComponent, useEffect, useState } from "react";
import Button from "rsuite/Button";
import SelectPicker from "rsuite/SelectPicker";
import Table from "rsuite/Table";
import Row from "rsuite/Row";
import Col from "rsuite/Col";
import TrashIcon from "@rsuite/icons/Trash";

import { SetState } from "../../../../utils/models";

import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { ILocationProjectRegionData } from "./_states";
import { deepClone } from "../../../../utils/helpers";

interface ILocationRegionForm {
  data: Array<ILocationProjectRegionData>;
  setData: SetState<Array<ILocationProjectRegionData>>;
  customers: { options: Array<{ id: string; name: string }> };
  regions: { options: Array<{ id: string; name: string }> };
  disabled?: boolean;
}

const LocationProjectRegionForm: FunctionComponent<ILocationRegionForm> = (
  props
) => {
  //const {locationId} = useParams<{locationId: string}>();
  const [state, setState] = useState<ILocationProjectRegionData>({
    regionId: "",
    customerId: "",
    regionName: "",
    customerName: "",
  });

  useEffect(() => {
    // console.log(props.locationForm.regions.options[0].id);
    if (props.regions.options.length === 1) {
      setState((data) => ({
        ...data,
        regionId: props.regions.options[0].id,
      }));
    }
    if (props.customers.options.length === 1) {
      setState((data) => ({
        ...data,
        customerId: props.customers.options[0].id,
      }));
    }
  }, []);

  const addNew = () => {
    const _data = deepClone(props.data);
    _data.push({
      regionId: state.regionId,
      customerId: state.customerId,
      regionName:
        props.regions.options.find((co) => co.id === state.regionId)?.name ??
        "-",
      customerName:
        props.customers.options.find((co) => co.id === state.customerId)
          ?.name ?? "-",
    });
    props.setData(_data);
  };

  const getData = (): Array<ILocationProjectRegionData> => {
    return props.data ?? [];
  };

  const filteredRegions = (): Array<any> => {
    return (
      props.regions?.options.filter(
        (c) => !(getData()?.map((d) => d.regionId) ?? []).includes(c.id)
      ) ?? []
    );
  };
  const filteredCustomers = (): Array<any> => {
    return (
      props.customers?.options.filter(
        (c) => !(getData()?.map((d) => d.customerId) ?? []).includes(c.id)
      ) ?? []
    );
  };

  const removeItem = (rowData) => {
    const _data = deepClone(props.data);
    const _filteredData = _data.filter(
      (row) =>
        row.regionId !== rowData.regionId &&
        row.customerId !== rowData.customerId
    );
    props.setData(_filteredData);
  };

  return (
    <Section title={"Region klienta dla lokalizacji"} titleSize={"1.1em"}>
      {!props.disabled && filteredCustomers().length === 0 ? (
        <div style={{ textAlign: "center", color: "#aaa" }}>
          Brak możliwości dodania przypisań regionów.
        </div>
      ) : props.disabled === true ? (
        <></>
      ) : (
        <Row>
          <Col xs={11}>
            <SelectPicker
              block
              cleanable={false}
              value={state.customerId}
              readOnly={filteredCustomers().length === 1}
              data={
                filteredCustomers().map((c) => ({
                  label: c.name,
                  value: c.id,
                })) ?? []
              }
              placeholder={"Klient..."}
              onSelect={(customerId) => {
                setState((data) => ({
                  ...data,
                  customerId: customerId,
                }));
              }}
            />
          </Col>
          <Col xs={11}>
            <SelectPicker
              block
              cleanable={false}
              value={state.regionId}
              readOnly={filteredRegions().length === 1}
              data={
                filteredRegions().map((c) => ({
                  label: c.name,
                  value: c.id,
                })) ?? []
              }
              placeholder={"Region..."}
              onSelect={(regionId) => {
                setState((data) => ({
                  ...data,
                  regionId: regionId,
                }));
              }}
            />
          </Col>
          <Col xs={2}>
            <Button
              type={"button"}
              disabled={
                Object.entries(state).filter((val) => {
                  return val[1] !== null;
                }).length < Object.keys(state).length
              }
              block
              appearance={"ghost"}
              onClick={addNew}>
              Dodaj
            </Button>
          </Col>
        </Row>
      )}
      <SeparatorEmpty size={1.5} />
      <Table autoHeight loading={getData() === null} data={getData() ?? []}>
        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell>Klient</Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="customerName" />
        </Table.Column>

        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Region
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="regionName" />
        </Table.Column>

        <Table.Column flexGrow={1} align="right">
          <Table.HeaderCell depth={0} width={0}>
            Opcje
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (props.disabled === true) return <></>;
              return (
                <TrashIcon
                  style={{ cursor: "pointer", color: "#ffaf38" }}
                  onClick={removeItem.bind(null, rowData)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Section>
  );
};

export default LocationProjectRegionForm;
