import { IRouteEntity } from "utils/models";
import ContractDocumentTemplateList from "views/contract/contractDocumentTemplates/components/contractDocumentTemplateList";
import ContractDocumentTemplateEdit from "views/contract/contractDocumentTemplates/components/contractDocumentTemplateEdit";

export const contractTemplatePath = "/contracts/templates";

const routes: IRouteEntity[] = [
  {
    path: `${contractTemplatePath}`,
    breadcrumb: "Szablony dokumentów/umów",
    Component: ContractDocumentTemplateList,
  },
  {
    path: `${contractTemplatePath}/add`,
    breadcrumb: "Dodaj szablon dokumentu/umowy",
    Component: ContractDocumentTemplateEdit,
  },
  {
    path: `${contractTemplatePath}/:id/edit`,
    breadcrumb: "Edycja szablonu dokumentu/umowy",
    Component: ContractDocumentTemplateEdit,
  },
];

export default routes;
