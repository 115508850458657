import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import ScColumn from "./ScColumn";
import { Table } from "rsuite";
import { formatDate } from "../../utils/helpers";
import styles from "../../global/atoms/atoms.module.scss";

export interface ITableMonth {
  monthTimeStamp: number;
  children: any;
  data: Array<any>;
  loading: boolean;
}

const TableMonth: FunctionComponent<ITableMonth> = (props) => {
  const daysShortName: Array<string> = [
    "ND",
    "PN",
    "WT",
    "ŚR",
    "CZ",
    "PT",
    "SB",
  ];
  const [daysHeader, setDaysHeader] = useState<
    Array<{
      elm: ReactElement;
      timestamp: number;
      date: string;
    }>
  >([]);

  const timeTableHeaderCell = (dayNumber: number, dayShortName: string) => (
    <div className={styles.TTHeaderCell}>
      <div className={styles.TTHeaderCellDay}>{dayNumber}</div>
      <div className={styles.TTHeaderCellDayName}>{dayShortName}</div>
    </div>
  );

  useEffect(() => {
    const tmp = new Date(props.monthTimeStamp);
    const date = new Date(tmp.getFullYear(), tmp.getMonth(), 1);
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    const tmpArr = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const currDate = new Date(date.getFullYear(), date.getMonth(), i);
      const weekDayNum = currDate.getDay();
      tmpArr.push({
        elm: timeTableHeaderCell(i, daysShortName[weekDayNum]),
        timestamp: currDate.getTime(),
        date: formatDate(currDate),
      });
    }
    setDaysHeader(tmpArr);
  }, [props.monthTimeStamp]);

  return (
    <Table fillHeight loading={props.loading} data={props?.data ?? []}>
      {props.children}
      {daysHeader.map((v, k) => {
        return (
          <ScColumn align="center" width={30} key={`day-key-${k}`}>
            <Table.HeaderCell>{v.elm}</Table.HeaderCell>
            <Table.Cell style={{ fontSize: ".7em" }}>
              {(rowData: {
                dayPositions: Array<{
                  date: string;
                  value: any;
                  status: string;
                }>;
              }) => {
                const dayPos = rowData.dayPositions.find(
                  (dp) => dp.date === v.date
                );
                if (dayPos) return <>{dayPos.value}</>;
              }}
            </Table.Cell>
          </ScColumn>
        );
      })}
    </Table>
  );
};

export default TableMonth;
