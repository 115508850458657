import React, { FunctionComponent, useState } from "react";
import {
  IBodyResponse,
  ILogCMDRequestData,
  ISelect,
} from "../../../../utils/models";
import { Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import LogsConnection from "../../../../utils/connections/logs";
import { jsonButton } from "./LogsList";
import { handleSortColumn } from "../../../../utils/helpers";
import dayjs from "dayjs";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { DeleteSingleButton } from "../../../../utils/toggleToDeleteHelper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ILogsCmdList {}

interface ILogRow {
  date: string;
  gid: string;
  id: string;
  memoryUsage: number;
  callback: string;
  command: string;
  name: string;
  pid: string;
  scriptTime: number;
  status: IStatus;
}

interface ILogForm {
  statuses?: ISelect;
  types?: ISelect;
}

interface IStatus {
  id: string;
  default: string;
  name: string;
  color: string;
}

const LogsCmdList: FunctionComponent<ILogsCmdList> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<IBodyResponse<ILogRow>>({
    count: 0,
    data: [],
  });
  const [form, setForm] = useState<ILogForm | null>(null);

  const defaultRequestData: ILogCMDRequestData = {
    name: "",
    dateFrom: 0,
    dateTo: 0,
    requestPaginate: {
      page: 1,
      limit: 30,
    },
    requestOrder: {
      field: "",
      order: "asc",
    },
  };
  const [requestData, setRequestData] =
    useState<ILogCMDRequestData>(defaultRequestData);

  const handleDeleteSelected = (id: string) => {
    LogsConnection.listEventDelete(id).then(() => {
      LogsConnection.listEvent(requestData).then((data) => {
        setLogData(data.data);
      });
    });
  };

  return (
    <>
      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={logData.data}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column width={120} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey={"date"}>
                {(rowData: ILogRow) => {
                  return (
                    <>
                      {rowData.date && dayjs(rowData.date).format("YYYY-MM-DD")}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column minWidth={200} flexGrow={1} sortable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>

            <Table.Column minWidth={150} flexGrow={1} sortable>
              <Table.HeaderCell>Utworzył</Table.HeaderCell>
              <Table.Cell dataKey="userName" />
            </Table.Column>

            <Table.Column width={80} sortable>
              <Table.HeaderCell>Czas [s]</Table.HeaderCell>
              <Table.Cell dataKey={"scriptTime"} />
            </Table.Column>

            <Table.Column width={80} sortable>
              <Table.HeaderCell>Pamięć [Mb]</Table.HeaderCell>
              <Table.Cell dataKey={"memoryUsage"} />
            </Table.Column>

            <Table.Column width={150} sortable>
              <Table.HeaderCell>Data startu</Table.HeaderCell>
              <Table.Cell dataKey={"scheduleDate"} />
            </Table.Column>

            <Table.Column width={100} sortable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey={"status"}>
                {(rowData: ILogRow) => {
                  return <>{rowData.status.name}</>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={80} align={"right"}>
              <Table.HeaderCell>Dane</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) =>
                  rowData.command ? jsonButton(rowData.command) : "-"
                }
              </Table.Cell>
            </Table.Column>

            <Table.Column width={80} align={"right"}>
              <Table.HeaderCell>Odpowiedź</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) =>
                  rowData.callback ? jsonButton(rowData.callback) : "-"
                }
              </Table.Cell>
            </Table.Column>

            <Table.Column fixed="right" width={70} align="left">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) => {
                  return rowData.status.id == "WAITING" ? (
                    <ActionsContainer>
                      <DeleteSingleButton
                        callback={handleDeleteSelected.bind(null, rowData.id)}
                        form={form}
                        message={"Potwierdź anulowanie zadania."}
                      />
                    </ActionsContainer>
                  ) : (
                    <></>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={logData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={LogsConnection.listEvent}
        formGet={LogsConnection.listEventForm}
        getFormCallback={(_form) => setForm(_form)}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setLogData}
        filterStorageKey={"logsEventsList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Dane",
            stateKey: "request",
          },
          {
            type: FILTER_SELECT,
            label: "Typ",
            stateKey: "type",
            formKey: "types",
          },
          {
            type: FILTER_SELECT,
            label: "Status",
            stateKey: "status",
            formKey: "statuses",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
        ]}
      />
    </>
  );
};

export default LogsCmdList;
