import React, { FunctionComponent, useEffect, useState } from "react";
import WhiteCard from "global/atoms/WhiteCard";
import { createUserState } from "utils/states";
import { ICreateUser } from "utils/models";
import UserConnection from "utils/connections/user";
import { handleToast } from "utils/helpers";
import { useHistory } from "react-router-dom";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import ActionsContainer from "global/atoms/ActionsContainer";
import CustomRoles from "../customRoles/CustomRoles";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import EditUserCommonFields from "./EditUserCommonFields";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

interface ICreateUserComponent {}

const CreateUser: FunctionComponent<ICreateUserComponent> = () => {
  const [data, setData] = useState<ICreateUser>(createUserState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    UserConnection.getFormCreateUser().then((data) => {
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  }, []);

  const handleSubmit = () => {
    handleToast(UserConnection.createUser(data), setErrors).then(() =>
      history.push("/admin/users")
    );
  };

  if (form === "loading") return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form
          fluid
          onSubmit={handleSubmit}
          onChange={(value) => setData({ ...data, ...value })}>
          <EditUserCommonFields data={data} errors={errors} form={form} />
          <SeparatorEmpty />
          <Form.Group>
            <Form.ControlLabel>{"Hasło"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"hasło..."}
              errorMessage={errors?.password}
              type={"password"}
              value={data.password}
              name={"password"}
              accepter={Input}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>{"Powtórz hasło"}</Form.ControlLabel>
            <Form.Control
              block
              placeholder={"powtórz hasło..."}
              errorMessage={errors?.repeatPassword}
              type={"password"}
              value={data.repeatPassword}
              name={"repeatPassword"}
              accepter={Input}
            />
          </Form.Group>
          <SeparatorEmpty />
          <CustomRoles
            state={data}
            setState={setData}
            options={form.roles.options}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default CreateUser;
