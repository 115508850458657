import React, { FunctionComponent, useEffect, useState } from "react";
import connection from "utils/connections/contract/contractConnection";
import { Table } from "rsuite";
import { getContractState } from "../../../utils/states/contract/contractStates";
import ScTable from "../../../global/table/ScTable";

interface IContractorHistoryList {
  contractorId: string;
}

const ContractorHistoryList: FunctionComponent<IContractorHistoryList> = (
  props
) => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.contractorId) {
      connection
        .getContract({ ...getContractState, contractorId: props.contractorId })
        //connection.getContract(getContractState)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        });
    }
  }, []);

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      {data?.count === 0 ? (
        <>Użytkownik nie posiada aktywnych umów</>
      ) : (
        <>
          <ScTable data={data?.data} loading={loading}>
            <Table.Column flexGrow={2} minWidth={100} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Numer
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => {
                  return <span>{rowData.number}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={160} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Okres umowy
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="status">
                {(rowData) => {
                  return (
                    <span>{rowData.startDate + " - " + rowData.endDate}</span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={120} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Zleceniodawca
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="contractor">
                {(rowData) => {
                  return (
                    <span>
                      {rowData.principal.firstName +
                        " " +
                        rowData.principal.lastName}
                    </span>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj Projektu
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="status">
                {(rowData) => {
                  return <span>{rowData.type.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj umowy
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="category">
                {(rowData) => {
                  return <span>{rowData.category.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Status
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="type">
                {(rowData) => {
                  return <span>{rowData.status.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        </>
      )}
    </>
  );
};

export default ContractorHistoryList;
