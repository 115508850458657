import React from "react";
import BaseComponent from "../BaseComponent";
import styles from "../atoms.module.scss";
import { IBaseComponentProps } from "../../../utils/models";

interface IRadioButton extends IBaseComponentProps {
  name?: string;
  onChange?: (event: any) => void;
  value?: number;
  id?: any;
  checked?: boolean;
}

export default class RadioButton extends BaseComponent<IRadioButton, any> {
  renderView(): JSX.Element {
    const isChecked =
      (this.props.value === this.props.id &&
        this.props.value !== undefined &&
        this.props.id !== undefined) ||
      this.props.checked;
    return (
      <>
        <div
          className={styles.radioContainer}
          onClick={this.props.onChange}
          data-id={this.props.id}
          data-name={this.props.name}
          style={{
            width: this.props.name === undefined ? "auto" : "100%",
          }}>
          <span
            className={
              isChecked ? styles.radioButtonActive : styles.radioButton
            }
          />
          {this.props.name !== undefined && (
            <label htmlFor={this.props.name} style={{ cursor: "pointer" }}>
              {this.props.name}
            </label>
          )}
        </div>
      </>
    );
  }
}
