import React, { FunctionComponent, useState } from "react";
import {
  IWarehouseAvailabilityFilters,
  IWarehouseUserAvailabilityData,
} from "utils/models";
import { _paginate30 } from "utils/states";
import WarehouseConnection from "utils/connections/warehouse";

import { Table } from "rsuite";
import Pagination from "global/pagination/Pagination";
import UniversalFilters, {
  FILTER_SELECT,
  FILTER_SEPARATOR,
} from "../../../../global/filters/UniversalFilters";
import {
  getProjectIdFromUrl,
  handleSortColumn,
} from "../../../../utils/helpers";
import styles from "./styles.module.scss";
interface IWarehouseUserList {}
interface IFilters extends IWarehouseAvailabilityFilters {
  groupId: string;
  userToId: string;
  roleToId: string;
}

const WarehouseUserList: FunctionComponent<IWarehouseUserList> = () => {
  const [data, setData] = useState<
    IWarehouseUserAvailabilityData | null | "error"
  >(null);
  const defaultFilters: IFilters = {
    brandId: "",
    itemId: "",
    groupId: "",
    userToId: "",
    roleToId: "",
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IFilters>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(true);

  if (data === "error") return null;

  return (
    <>
      <Table
        loading={loading}
        data={data?.data ?? []}
        autoHeight
        headerHeight={40}
        sortColumn={filters.requestOrder.field}
        sortType={filters.requestOrder.order}
        onSortColumn={(dataKey, sortType) =>
          handleSortColumn(dataKey, sortType, setFilters)
        }>
        <Table.Column flexGrow={2} minWidth={250} align="left" sortable>
          <Table.HeaderCell>
            <div style={{ display: "inline-block" }}>
              <div>Produkt</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell dataKey="itemName">
            {(rowData) => (
              <>
                <div>{rowData.itemName}</div>
                <div className={styles.style}>{rowData.itemGroup}</div>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={250} align="left">
          <Table.HeaderCell>
            <div>
              <div>Użytkownik</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell width={200} dataKey="userName">
            {(rowData) => (
              <>
                <div>
                  {rowData.userName}
                  <div
                    className={styles.style}
                    style={{
                      fontWeight: "bold",
                      color: rowData.roleTo.roleType.color,
                    }}>
                    {rowData.roleTo.roleName}
                  </div>
                </div>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={70} align="left">
          <Table.HeaderCell>STAN</Table.HeaderCell>
          <Table.Cell dataKey="availability" />
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell>Przychód</Table.HeaderCell>
          <Table.Cell dataKey="incoming" />
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell>
            <div>Przychód</div>
            <div className={styles.style_header}>Przesunięcie</div>
          </Table.HeaderCell>
          <Table.Cell dataKey="shiftIncoming" />
        </Table.Column>
        <Table.Column width={110} align="left">
          <Table.HeaderCell>Rozchód</Table.HeaderCell>
          <Table.Cell dataKey="outcoming" />
        </Table.Column>
        <Table.Column width={110} align="left">
          <Table.HeaderCell>
            <div>Rozchód</div>
            <div className={styles.style_header}>Przesunięcie</div>
          </Table.HeaderCell>
          <Table.Cell dataKey="shiftOutcoming" />
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell>Strata</Table.HeaderCell>
          <Table.Cell dataKey="lost" />
        </Table.Column>
      </Table>

      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={WarehouseConnection.userAvailabilityList}
        formGet={WarehouseConnection.userAvailabilityFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectWarehouseUser_${getProjectIdFromUrl()}`}
        elements={[
          {
            type: FILTER_SELECT,
            label: "Produkt",
            stateKey: "itemId",
            formKey: "items",
          },
          {
            type: FILTER_SELECT,
            label: "Marka",
            stateKey: "brandId",
            formKey: "brands",
          },
          {
            type: FILTER_SELECT,
            label: "Grupa produktu",
            stateKey: "groupId",
            formKey: "itemGroups",
          },
          {
            type: FILTER_SEPARATOR,
            label: "",
            stateKey: "",
          },
          {
            type: FILTER_SELECT,
            label: "Użytkownik (Grupa)",
            stateKey: "roleToId",
            formKey: "usersRoleTo",
          },
          {
            type: FILTER_SELECT,
            label: "Użytkownik",
            stateKey: "userToId",
            formKey: "usersTo",
            groupBy: "roleName",
            filter: ["roleToId", "roleId"],
            filterIgnoreIfEmpty: true,
          },
        ]}
      />
    </>
  );
};

export default WarehouseUserList;
