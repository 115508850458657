import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { IconButton, SelectPicker, Tooltip, Whisper } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import AddressesConnection from "utils/connections/addresses";
import { IAddress, IGetAddresses } from "utils/models";
import { getAddressesState } from "utils/states";
import PlusIcon from "@rsuite/icons/Plus";

import _ from "lodash";
import objectHash from "object-hash";
import EditIcon from "@rsuite/icons/Edit";
import FormGroup from "rsuite/FormGroup";
import styles from "./atoms/atoms.module.scss";
import { findErrorByName } from "../utils/formErrorsHelper";
import AddressAdd from "../views/datasets/components/addressAdd/AddressAdd";
import Modal from "rsuite/Modal";

interface IAddressPicker {
  label?: string;
  value?: string; // address id
  setValue?: (value: string) => void;
  onSelected: (addressId: null | string) => void;
  readonly?: boolean;
  errors?: any;
  name?: string;
  addresses?: Array<IAddress> | undefined; // optional, if not provided will request from /addresses/list
}

const AddressPicker: FunctionComponent<IAddressPicker> = (props) => {
  const pickerHash = useRef(objectHash(props.name ?? new Date()));
  const [addresses, setAddresses] = useState<undefined | Array<IAddress>>(
    props.addresses
  );
  const [selectedAddressBackup, setSelectedAddressBackup] = useState<
    undefined | IAddress
  >(undefined);
  const defaultRequest = {
    ...getAddressesState,
    requestPaginate: {
      page: 1,
      limit: 50,
    },
  };
  const [request, setRequest] = useState<IGetAddresses>(defaultRequest);
  const [loading, setLoading] = useState(false);
  const [addressPickerIsOpen, setAddressPickerIsOpen] = useState(false);
  const [requestAddressId, setRequestAddressId] = useState<
    string | undefined
  >();

  const loadAddresses = () => {
    setLoading(true);
    AddressesConnection.getAddresses(request, pickerHash.current).then(
      (res) => {
        const _data = res.data;
        if (request.id && res.data.data[0]) {
          setSelectedAddressBackup(res.data.data[0]);
        }
        if (
          res.data &&
          selectedAddressBackup &&
          !res.data.data.find((d) => d.id === selectedAddressBackup.id)
        ) {
          _data.data.push(selectedAddressBackup);
        }
        setAddresses(_data?.data ?? []);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (props.addresses !== undefined && props.readonly) return;
    if (request.search || request.id) loadAddresses();
  }, [request.search, request.id, request.trigger]);

  useEffect(() => {
    getAssignedAddress(props.value);
  }, []);

  const getAssignedAddress = (addressId: string | undefined) => {
    if (addressId) {
      setRequest({
        ...defaultRequest,
        id: addressId,
        trigger: objectHash(new Date()),
      });
    }
  };

  const renderMenu = (menu: React.ReactNode): React.ReactNode => {
    const addNewButton = (
      <div style={{ padding: "10px" }}>
        <IconButton
          size={"xs"}
          onClick={() => {
            setAddressPickerIsOpen(true);
            setRequestAddressId(undefined);
            setRequest(defaultRequest); // reset request
          }}
          icon={<PlusIcon />}
          block
          appearance={"ghost"}>
          Utwórz nowy adres
        </IconButton>
      </div>
    );

    if (addresses === undefined || loading) {
      return (
        <p style={{ padding: 15, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> Ładowanie...
        </p>
      );
    } else if (_.isEmpty(addresses)) {
      return (
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          Nie znaleziono adresu dla podanej frazy!
          {addNewButton}
        </div>
      );
    }

    return (
      <>
        {menu}
        {addNewButton}
      </>
    );
  };

  const addressAsString = (address: IAddress): React.ReactNode => {
    if (!address) return <></>;
    return (
      <>
        {address?.street?.name}&nbsp;
        {address?.houseNumber}
        {address?.apartmentNumber ? `/${address.apartmentNumber} ` : ""},&nbsp;
        {address?.postCode?.name}&nbsp;
        {address?.city?.name},&nbsp;
        {address?.community?.name},&nbsp;
        {address?.voivodeship?.name}
        &nbsp;
        <Whisper speaker={<Tooltip>Edycja adresu</Tooltip>}>
          <EditIcon
            style={{ cursor: "pointer" }}
            color={"#999"}
            onClick={() => {
              setAddressPickerIsOpen(true);
              setRequestAddressId(props.value);
            }}
          />
        </Whisper>
      </>
    );
  };

  return (
    <>
      {!addressPickerIsOpen && (
        <FormGroup>
          <SelectPicker
            block
            data={addresses ?? []}
            label={props.label ? props.label : "Adres"}
            renderMenu={renderMenu}
            labelKey={"id"}
            valueKey={"id"}
            disabled={props.readonly}
            placeholder={"Wybierz adres"}
            value={props.value}
            renderMenuItem={(label, item) => addressAsString(item as IAddress)}
            renderValue={(value: string, item) =>
              addressAsString(item as IAddress)
            }
            onChange={(value: string | null) => props.onSelected(value)}
            onOpen={() => {
              if (props.value) setRequest(defaultRequest);
              else loadAddresses();
            }}
            onSearch={(_search) =>
              setRequest((s) => ({ ...s, search: _search }))
            }
            searchBy={() => true}
          />
          {props.errors && props.name && (
            <span className={styles.errors}>
              {findErrorByName(props.name, props.errors)}
            </span>
          )}
        </FormGroup>
      )}
      <Modal
        overflow={true}
        open={addressPickerIsOpen}
        size={"full"}
        onClose={() => {
          setAddressPickerIsOpen(false);
        }}>
        <Modal.Header>
          <Modal.Title>
            {requestAddressId ? "Edycja adresu" : "Nowy adres"}
            <span style={{ fontWeight: "bolder" }}>
              {props.label ? `: ${props.label}` : ""}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddressAdd
            isLocal
            setAddressBack={() => {
              loadAddresses();
            }}
            setOpen={(open) => setAddressPickerIsOpen(open)}
            refAddressId={requestAddressId}
            setRefAddressId={(value) => {
              props.setValue && props.setValue(value);
              props.onSelected(value);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressPicker;
