import React, { FunctionComponent } from "react";
import styles from "./atoms.module.scss";

interface IBadgeStatus {
  color: string;
  borderColor?: string;
  backgroundColor?: string;
  children: any;
}

const BadgeStatus: FunctionComponent<IBadgeStatus> = (props: IBadgeStatus) => {
  return (
    <>
      <span
        style={{
          color: props.color,
          borderColor: props.borderColor ?? props.color,
          backgroundColor: props.backgroundColor ?? "transparent",
        }}
        className={styles.badgeStatus}>
        {props.children}
      </span>
    </>
  );
};

export default BadgeStatus;
