import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProjectsConnection from "../../../../utils/connections/projects";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { confirmModalHandler } from "../../../../redux/store";
import { deepClone, handleToast } from "../../../../utils/helpers";
import MoveUpIcon from "@rsuite/icons/MoveUp";
import FormControlLabel from "rsuite/FormControlLabel";
import Toggle from "rsuite/Toggle";
import Checkbox from "rsuite/Checkbox";
import Button from "rsuite/Button";
import { Helmet } from "react-helmet-async";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import Col from "rsuite/Col";
import Row from "rsuite/Row";

interface IAccessLevels {}

interface IStatus {
  id: string;
  name: string;
  status?: string;
  color: string;
  type: string;
}

export interface IFormRolesConfiguration {
  roles: {
    data: Array<{
      id: string;
      roleName: string;
      roleId: string;
      approvalVisitStatuses: Array<string>;
      visibleVisitStatuses: Array<string>;
    }>;
  };
  approvalStatuses: { data: Array<IStatus> };
  visibilityStatuses: { data: Array<IStatus> };
}

interface IData {
  roleId: string;
  approvalStatuses: Array<string>;
  visibilityStatuses: Array<string>;
  visibilityStatusesAll: boolean;
}

interface IProjectData {
  projectRoleConfigurations: Array<IData>;
}

const AccessLevels: FunctionComponent<IAccessLevels> = () => {
  const [form, setForm] = useState<IFormRolesConfiguration>(null);
  const [data, setData] = useState<IProjectData>();
  const [allDisabled, setAllDisabled] = useState(true);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const style = {
    marginRight: "10px",
    marginBottom: "10px",
    width: "200px",
    border: "1px solid",
    borderRadius: "50px",
    fontFamily: "Poppins, sans-serif",
    fontSize: "12px",
    letterSpacing: "0.6px",
    height: "25px",
    paddingTop: "0px",
    paddingRight: "10px",
    marginTop: "0px",
    display: "inline-flex",
    alignItems: "center",
  };
  useEffect(() => {
    ProjectsConnection.getFormRolesConfiguration(id).then((resp) => {
      setForm(resp.data);
      const vData = resp.data.roles.data.map((role) => ({
        roleId: role.roleId,
        approvalStatuses: role.approvalVisitStatuses
          ? role.approvalVisitStatuses.map((st) => st?.id).filter((el) => !!el)
          : [],
        visibilityStatuses: role.visibleVisitStatuses
          ? role.visibleVisitStatuses.map((st) => st?.id).filter((el) => !!el)
          : null,
        visibilityStatusesAll: role.visibleVisitStatuses === null,
      }));
      setData({ projectRoleConfigurations: vData });
    });
  }, []);
  const handleSubmit = (roleId) => {
    setAllDisabled(true);
    const sData = data.projectRoleConfigurations.find(
      (el) => el.roleId == roleId
    );
    handleToast(
      ProjectsConnection.putRolesConfiguration(id, {
        projectRoleConfigurations: [sData],
      }),
      undefined,
      "Błąd zapisu",
      "Dane zapisano"
    );
  };
  const handleSubmitAll = () => {
    setAllDisabled(true);
    handleToast(
      ProjectsConnection.putRolesConfiguration(id, data),
      undefined,
      "Błąd zapisu",
      "Dane zapisano"
    );
  };
  const getCheckedLevel = (roleId, levelId): boolean => {
    const fStatus = data.projectRoleConfigurations.find(
      (el) => el.roleId == roleId
    );
    if (fStatus == undefined) return false;
    return !!fStatus.approvalStatuses.includes(levelId);
  };
  const getCheckedStatus = (roleId, statusId): boolean => {
    const fStatus = data.projectRoleConfigurations.find(
      (el) => el.roleId == roleId
    );
    if (fStatus == undefined) return false;
    return !!fStatus?.visibilityStatuses?.includes(statusId);
  };
  const getCheckedStatusAll = (roleId): boolean => {
    const fStatus = data.projectRoleConfigurations.find(
      (el) => el.roleId == roleId
    );
    if (fStatus == undefined) return false;
    return !!fStatus.visibilityStatusesAll;
  };

  const setActiveLevel = (checked, roleId, statusId) => {
    setAllDisabled(false);
    const vStatuses = deepClone(data);
    vStatuses.projectRoleConfigurations.forEach((value, index) => {
      if (value.roleId == roleId && checked) {
        vStatuses.projectRoleConfigurations[index].approvalStatuses.push(
          statusId
        );
      }
      if (value.roleId == roleId && !checked) {
        vStatuses.projectRoleConfigurations[index].approvalStatuses =
          vStatuses.projectRoleConfigurations[index].approvalStatuses.filter(
            (el) => el !== statusId
          );
      }
    });
    setData({ ...vStatuses });
  };
  const setActiveStatus = (checked, roleId, statusId) => {
    setAllDisabled(false);
    const vStatuses = deepClone(data);
    vStatuses.projectRoleConfigurations.forEach((value, index) => {
      if (value.roleId == roleId && checked) {
        vStatuses.projectRoleConfigurations[index].visibilityStatuses.push(
          statusId
        );
        vStatuses.projectRoleConfigurations[index].visibilityStatusesAll =
          false;
      }
      if (value.roleId == roleId && !checked) {
        vStatuses.projectRoleConfigurations[index].visibilityStatuses =
          vStatuses.projectRoleConfigurations[index].visibilityStatuses.filter(
            (el) => el !== statusId
          );
      }
    });
    setData({ ...vStatuses });
  };
  const setActiveStatusAll = (checked, roleId) => {
    setAllDisabled(false);
    const vStatuses = deepClone(data);
    vStatuses.projectRoleConfigurations.forEach((value, index) => {
      if (value.roleId == roleId && checked) {
        vStatuses.projectRoleConfigurations[index].visibilityStatuses = null;
        vStatuses.projectRoleConfigurations[index].visibilityStatusesAll = true;
      }
      if (value.roleId == roleId && !checked) {
        vStatuses.projectRoleConfigurations[index].visibilityStatuses = [];
        vStatuses.projectRoleConfigurations[index].visibilityStatusesAll =
          false;
      }
    });
    setData({ ...vStatuses });
  };

  const getStatusesByGroup = (groupKey): Array<IStatus>[] => {
    const _statusesGrouped: Array<IStatus>[] = [];
    form.visibilityStatuses.data.forEach((vStatus) => {
      const _groupKeyValue = vStatus[groupKey];
      if (_statusesGrouped[_groupKeyValue] === undefined) {
        _statusesGrouped[_groupKeyValue] = [];
      }
      _statusesGrouped[_groupKeyValue].push(vStatus);
    });
    return _statusesGrouped;
  };

  return (
    <>
      <HeaderButtons>
        <Button
          appearance={"ghost"}
          startIcon={<MoveUpIcon></MoveUpIcon>}
          onClick={() => history.push(`/projects/${id}/edit/access`)}>
          Powrót: Prawa dostępu
        </Button>
        <Button
          disabled={allDisabled}
          appearance={allDisabled ? "ghost" : "primary"}
          onClick={confirmModalHandler(
            `czy zapisać zmienione dane`,
            handleSubmitAll
          )}>
          Zapisz wszystkie zmiany
        </Button>
      </HeaderButtons>
      <WhiteCard>
        {!form && !data && <SpinnerSmall />}
        {form &&
          data &&
          form.roles.data.map((role) => (
            <>
              <SeparatorEmpty />
              <Section
                key={role.id}
                title={role.roleName}
                titleSize={"20px"}
                style={{ borderWidth: "2px" }}>
                <Section title={"Poziomy zatwierdzania"} titleSize={"10px"}>
                  {form.approvalStatuses.data.map((level) => (
                    <>
                      <Checkbox
                        checked={getCheckedLevel(role.roleId, level.id)}
                        value={level.id}
                        style={{
                          ...style,
                          color: level.color,
                          borderColor: level.color,
                        }}
                        onChange={(value, checked) =>
                          setActiveLevel(checked, role.roleId, value)
                        }>
                        {level.name}
                      </Checkbox>
                    </>
                  ))}
                </Section>
                <SeparatorEmpty />
                <Section
                  title={"Widoczność ankiet według statusów:"}
                  titleSize={"10px"}>
                  <FormControlLabel
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}>
                    <Toggle
                      size={"sm"}
                      checked={getCheckedStatusAll(role.roleId)}
                      onChange={(checked) =>
                        setActiveStatusAll(checked, role.roleId)
                      }
                    />
                    <span style={{ marginLeft: "10px" }}>
                      Wszystkie domyślnie aktywne
                    </span>
                  </FormControlLabel>
                  <SeparatorEmpty />

                  {!getCheckedStatusAll(role.roleId) &&
                    Object.entries(getStatusesByGroup("type")).map(
                      ([key, groupedStatus]) => (
                        <>
                          <Row>
                            {/*<Col xs={2}>{key}</Col>*/}
                            <Col xs={22}>
                              {groupedStatus.map((vStatus) => (
                                <>
                                  <Checkbox
                                    checked={getCheckedStatus(
                                      role.roleId,
                                      vStatus.id
                                    )}
                                    style={{
                                      ...style,
                                      color: vStatus.color,
                                      borderColor: vStatus.color,
                                    }}
                                    value={vStatus.id}
                                    onChange={(value, checked) =>
                                      setActiveStatus(
                                        checked,
                                        role.roleId,
                                        value
                                      )
                                    }>
                                    {vStatus.name}
                                  </Checkbox>
                                </>
                              ))}
                            </Col>
                            <SeparatorLine />
                          </Row>
                        </>
                      )
                    )}
                </Section>
                <SeparatorEmpty />
                <div style={{ textAlign: "right" }}>
                  <Button
                    appearance={"ghost"}
                    onClick={confirmModalHandler(
                      `Czy zapisać zmienione dane: ${role.roleName}?`,
                      handleSubmit.bind(null, role.roleId)
                    )}>
                    Zapisz: {role.roleName}
                  </Button>
                </div>
              </Section>
              <SeparatorEmpty />
            </>
          ))}
      </WhiteCard>
      <Helmet>
        <style>{`
      .rs-checkbox label {
         font-weight: 500;
         font-family: "Poppins, sans-serif",
         font-size: "12px",
         letter-spacing: "0.6px",
      }
      `}</style>
      </Helmet>
    </>
  );
};

export default AccessLevels;
