import React, { FunctionComponent, useEffect, useState } from "react";
import VisitReportsConnection from "../../../../utils/connections/VisitReports";
import {
  IVisitReportCreateRequest,
  IVisitReportForm,
  IVisitReportList,
  IVisitReportListRequest,
} from "../../../../utils/models";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  TagPicker,
  Progress,
  IconButton,
  SelectPicker,
  InputGroup,
} from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import { Table } from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { VisitReportListRequest } from "../../../../utils/states";
import { handleToastRedux } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";
import download from "downloadjs";
import dayjs from "dayjs";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
} from "../../../../global/filters/UniversalFilters";
import EditIcon from "@rsuite/icons/Edit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { generatePath, useHistory } from "react-router-dom";
import InputGroupAddon from "rsuite/esm/InputGroup/InputGroupAddon";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";

interface IVisitReport {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitReport: FunctionComponent<IVisitReport> = () => {
  interface ITask {
    id: string;
    name: string;
    projectId: string;
  }

  interface IFromState {
    taskIds: Array<string>;
    projectIds: Array<string>;
    startDate: Date | null;
    endDate: Date | null;
    templateId: string;
  }

  const dispatch = useDispatch();
  const [reportLists, setReportLists] = useState<IVisitReportList | null>(null);
  const [listRequest, setListRequest] = useState<IVisitReportListRequest>(
    VisitReportListRequest
  );
  const [form, setFrom] = useState<IVisitReportForm | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<IFromState>({
    taskIds: [],
    projectIds: [],
    startDate: dayjs(new Date()).startOf("month").toDate(),
    endDate: new Date(),
    templateId: "",
  });
  const history = useHistory();
  const getReportsList = () => {
    VisitReportsConnection.list(listRequest).then((res) => {
      setReportLists(res.data);
    });
  };

  useEffect(() => {
    VisitReportsConnection.form().then((res) => {
      setFrom(res.data);
      setFormState({
        ...formState,
        templateId: res?.data?.templates?.options[0]?.id,
      });
    });
    const intervalId = setInterval(getReportsList, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const generateReport = () => {
    if (formState.startDate && formState.endDate) {
      const requestData: IVisitReportCreateRequest = {
        templateId: formState.templateId,
        startDate: (formState.startDate.getTime() / 1000).toFixed(0).toString(),
        endDate: (formState.endDate.getTime() / 1000).toFixed(0).toString(),
        taskIds: formState.taskIds.map((taskId) => ({ id: taskId })),
      };

      handleToastRedux(
        VisitReportsConnection.create(requestData),
        dispatch
      ).then((r) => {
        console.log("res", r);
        getReportsList();
      });
    }
  };

  const getTasks = (): Array<ITask> => {
    return (
      form?.tasks.options.filter((t) =>
        formState.projectIds.includes(t.projectId)
      ) ?? []
    );
  };

  const downloadReport = (reportItem): void => {
    handleToastRedux(
      VisitReportsConnection.download(reportItem.id),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"], res.data.type);
    });
  };

  const getStatusBadge = (status: string): JSX.Element => {
    switch (status) {
      case "NEW":
        return <BadgeStatus color={"#9aa4aa"}>Nowy</BadgeStatus>;
      case "PROCESSING":
      case "READY_TO_PROCESS":
        return <BadgeStatus color={"#f6b900"}>Przetwarzanie</BadgeStatus>;
      case "COMPLETED":
        return <BadgeStatus color={"#26d554"}>Kompletny</BadgeStatus>;
      default:
        return <BadgeStatus color={"#9f9f9f"}>{status}</BadgeStatus>;
    }
  };
  const handleEditTemplate = () => {
    history.push(
      generatePath("/global-reports/visit-report/templates/edit/:templateId", {
        templateId: formState.templateId,
      })
    );
  };
  const handleAddTemplate = () => {
    history.push("/global-reports/visit-report/templates/add");
  };
  return (
    <>
      <InputGroup>
        <TagPicker
          data={
            form?.projects.options.map((p) => ({
              label: p.name,
              value: p.id,
            })) ?? []
          }
          style={{ width: "100%" }}
          value={formState.projectIds}
          placeholder={"Wybierz projekt"}
          onChange={(select) => {
            setFormState({
              ...formState,
              projectIds: select ?? [],
              taskIds: [],
            });
          }}
        />
        {form?.projects &&
          formState.projectIds.length < form.projects.options.length && (
            <InputGroupAddon
              title={"Zaznacz wszystkie"}
              style={{ cursor: "pointer" }}>
              <CheckOutlineIcon
                onClick={() => {
                  setFormState({
                    ...formState,
                    projectIds: form?.projects.options.map((p) => p.id) ?? [],
                  });
                }}
              />
            </InputGroupAddon>
          )}
      </InputGroup>

      <SeparatorEmpty />

      <Table autoHeight loading={form === null} data={getTasks()}>
        <Table.Column width={80}>
          <Table.HeaderCell>
            <Checkbox
              value={"all"}
              onChange={(val, checked) => {
                if (checked)
                  setFormState({
                    ...formState,
                    taskIds: getTasks().map((t) => t.id),
                  });
                else setFormState({ ...formState, taskIds: [] });
              }}
            />
          </Table.HeaderCell>
          <Table.Cell width={80}>
            {(rowData) => {
              return (
                <>
                  <Checkbox
                    value={rowData.id}
                    checked={formState.taskIds.includes(rowData.id)}
                    onChange={(taskId, checked) => {
                      if (checked)
                        setFormState({
                          ...formState,
                          taskIds: [...formState.taskIds, rowData.id],
                        });
                      else
                        setFormState({
                          ...formState,
                          taskIds: formState.taskIds.filter(
                            (tid) => tid !== rowData.id
                          ),
                        });
                    }}
                  />
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Zadanie</Table.HeaderCell>
          <Table.Cell width={100} dataKey={"name"} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Projekt</Table.HeaderCell>
          <Table.Cell width={100}>
            {(rowData) => {
              return (
                form?.projects.options.find((p) => p.id === rowData.projectId)
                  ?.name ?? rowData.projectId
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>

      <SeparatorEmpty size={0.75} />

      <WhiteCard>
        <Row style={{ display: "flex", alignItems: "flex-end" }}>
          <Col xs={6}>
            Wybierz szablon
            <SelectPicker
              block
              data={form?.templates?.options ?? []}
              value={formState.templateId}
              valueKey={"id"}
              labelKey={"name"}
              onChange={(value) => {
                setFormState({ ...formState, templateId: value ?? "" });
              }}
            />
          </Col>
          <Col xs={4}>
            <ActionsContainer>
              <IconButton
                disabled={!formState.templateId}
                icon={<EditIcon />}
                appearance={formState.templateId ? "ghost" : "default"}
                onClick={handleEditTemplate}>
                Edytuj
              </IconButton>
              <IconButton
                icon={<AddOutlineIcon />}
                appearance={"ghost"}
                onClick={handleAddTemplate}>
                Dodaj nowy
              </IconButton>
            </ActionsContainer>
          </Col>
          <Col xs={5}>
            Data od
            <DatePicker
              format={"yyyy-MM-dd"}
              isoWeek
              showWeekNumbers
              ranges={[
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              oneTap
              onChange={(date) =>
                setFormState({ ...formState, startDate: date })
              }
              value={formState.startDate}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={5}>
            Data do
            <DatePicker
              format={"yyyy-MM-dd"}
              isoWeek
              oneTap
              showWeekNumbers
              ranges={[
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              onChange={(date) => setFormState({ ...formState, endDate: date })}
              value={formState.endDate}
              style={{ width: "100%" }}
            />
          </Col>

          <Col xs={4}>
            <Button
              style={{ width: "100%" }}
              appearance={"primary"}
              disabled={formState.taskIds.length === 0}
              onClick={generateReport}>
              Generuj raport
            </Button>
          </Col>
        </Row>
      </WhiteCard>

      <SeparatorEmpty size={2} />
      <h5>Wygenerowane raporty</h5>
      <br />
      <Table autoHeight loading={loading} data={reportLists?.data ?? []}>
        {/* <Table.Column width={300}>
          <Table.HeaderCell>Id raportu</Table.HeaderCell>
          <Table.Cell dataKey={"id"} style={{ fontSize: "0.8em" }} />
        </Table.Column>*/}
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Użytkownik</Table.HeaderCell>
          <Table.Cell dataKey={"asUserName"} style={{ fontSize: "0.8em" }} />
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Szablon</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return rowData.template.name;
            }}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Liczba elementów</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              if (rowData.allElementsCount === 0)
                return <small style={{ color: "red" }}>Brak elementów</small>;
              return (
                <>
                  {rowData.allElementsCount}/{rowData.processedElementsCount}
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Progres</Table.HeaderCell>
          <Table.Cell width={100}>
            {(rowData) => (
              <Progress.Line
                percent={rowData.progress}
                strokeColor={"#FFAF38"}
              />
            )}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell>{(rowData) => getStatusBadge(rowData.status)}</Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Data utworzenia</Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              dayjs(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss")
            }
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Raport od:do</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <>
                <div style={{ fontSize: "10px", color: "#67666666" }}>
                  <div>
                    {dayjs(rowData.startDate * 1000).format("YYYY-MM-DD")}
                  </div>
                  <div>
                    {dayjs(rowData.endDate * 1000).format("YYYY-MM-DD")}
                  </div>
                </div>
              </>
            )}
          </Table.Cell>
        </Table.Column>

        <Table.Column flexGrow={1} align={"right"}>
          <Table.HeaderCell>Opcje</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <IconButton
                disabled={
                  rowData.status !== "COMPLETED" || rowData.filePath === ""
                }
                appearance={"primary"}
                onClick={downloadReport.bind(null, rowData)}
                icon={<FileDownloadIcon />}
              />
            )}
          </Table.Cell>
        </Table.Column>
      </Table>

      <Pagination
        count={reportLists?.count ?? 0}
        page={listRequest.requestPaginate.page.toString()}
        limit={listRequest.requestPaginate.limit.toString()}
        setState={setListRequest}
      />

      <UniversalFilters
        load={VisitReportsConnection.list}
        setLoading={setLoading}
        state={listRequest}
        setState={setListRequest}
        defaultStateData={VisitReportListRequest}
        setResultData={setReportLists}
        filterStorageKey={"globalImportFilterKey"}
        elements={[
          {
            type: FILTER_INPUT_DATE,
            label: "Data Od",
            stateKey: "dateFrom",
            outputFormat: "yyyy-MM-dd",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data do",
            stateKey: "dateTo",
            outputFormat: "yyyy-MM-dd",
          },
        ]}
      />
    </>
  );
};

export default VisitReport;
