import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
// @ts-ignore
import createActivityDetector from "activity-detector";
import LogsConnection from "./utils/connections/logs";
import { toNumber } from "lodash";
import dayjs from "dayjs";
import { CustomProvider } from "rsuite";
import rsuitePl from "global/rsuitePL";

import { FCC } from "utils/models";
import ToastRequesterRedux from "global/ToastRequesterRedux";

const AppCore: FCC = (props) => {
  useEffect(() => {
    const IDLE_TIMEOUT_MINUTES = 2;
    const SECOND_PER_MINUTE = 60;
    const activityDetector = createActivityDetector();

    const _if = () => {
      const uatm = parseInt(
        localStorage.getItem("user_activity_tm") ?? dayjs().unix().toString()
      );
      if (dayjs().unix() - uatm < SECOND_PER_MINUTE * IDLE_TIMEOUT_MINUTES) {
        LogsConnection.ping(
          dayjs().unix(),
          "ACTIVITY",
          toNumber(IDLE_TIMEOUT_MINUTES)
        );
      }
    };
    const interval = setInterval(
      _if,
      IDLE_TIMEOUT_MINUTES * SECOND_PER_MINUTE * 1000
    );
    _if(); // #2yv52bf

    activityDetector.on("active", () => {
      localStorage.setItem("user_activity_tm", dayjs().unix().toString());
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CustomProvider locale={rsuitePl}>
      <HelmetProvider>{props.children}</HelmetProvider>
      <ToastRequesterRedux />
    </CustomProvider>
  );
};

export default AppCore;
