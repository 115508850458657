import React, { FunctionComponent } from "react";
import { ColumnProps, Table } from "rsuite";

interface ScColumn extends ColumnProps {
  rowExpandedHeight?: number;
}

const ScColumn: FunctionComponent<ScColumn> = (preset) => {
  return (
    <Table.Column
      width={preset.width}
      align={preset.align}
      fixed={preset.fixed}
      flexGrow={!preset.width && !preset.flexGrow ? 1 : preset.flexGrow}
      minWidth={preset.minWidth ?? 1}
      onResize={undefined}
      resizable={preset.resizable ?? false}
      sortable={false}
      treeCol={false}
      verticalAlign={preset.verticalAlign ?? "top"}
      colSpan={preset.colSpan ?? 1}
      rowSpan={preset.rowSpan}
      // @ts-ignore
      rowExpandedHeight={preset.rowExpandedHeight ?? 100}>
      {preset.children}
    </Table.Column>
  );
};

export default ScColumn;
