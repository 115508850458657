import React, { FunctionComponent, useState } from "react";
import { getContractDocumentTemplatesState } from "utils/states/contract/contractDocumentTemplateStates";
import WhiteCard from "global/atoms/WhiteCard";
import Pagination from "global/pagination/Pagination";
import ScTable from "global/table/ScTable";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "global/filters/UniversalFilters";
import ButtonListRedirect from "global/atoms/ButtonListRedirect";
import NavOptionsWrapper from "utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "utils/toggleToDeleteHelper";
import connection from "utils/connections/contract/contractDocumentTemplatesConnection";
import { IGetContractDocumentTemplate } from "utils/models/contract/contractDocumentTemplateModels";
import { contractTemplatePath } from "../../../../routes/contract/contractDocumentTemplates";

interface IContractDocumentTemplatesList {}

const ContractDocumentTemplateList: FunctionComponent<
  IContractDocumentTemplatesList
> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IGetContractDocumentTemplate>(
    getContractDocumentTemplatesState
  );
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  const handleEdit = (event: any) => {
    history.push(
      `${contractTemplatePath}/${event.currentTarget.dataset.id}/edit`
    );
  };

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: connection.delete,
            callback: () => {
              setTriggerLoad(Date.now());
            },
          }}
          addPath={`${contractTemplatePath}/add`}
        />
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>
        {data === null ? (
          <></>
        ) : (
          <ScTable data={data?.data ?? []} loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={200} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Szablon
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`${contractTemplatePath}/${rowData.id}/edit`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj dokumentu
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="category">
                {(rowData) => {
                  return <span>{rowData.category.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj danych
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="type">
                {(rowData) => {
                  return <span>{rowData.type.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align="center" fixed="right">
              <Table.HeaderCell depth={0} width={0}>
                Status
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="status">
                {(rowData) => {
                  return <span>{rowData.status.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column verticalAlign={"middle"} align="center" fixed="right">
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContractDocumentTemplate}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getContractDocumentTemplatesState}
        setResultData={setData}
        formGet={connection.getFilterForm}
        filterStorageKey={"ContractDocumentTemplates"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Szablon",
          },
          {
            type: FILTER_SELECT,
            stateKey: "category",
            label: "Rodzaj dokumentu",
            formKey: "categories",
          },
          {
            type: FILTER_SELECT,
            stateKey: "type",
            label: "Rodzaj danych",
            formKey: "types",
          },
          {
            type: FILTER_SELECT,
            stateKey: "status",
            label: "Status",
            formKey: "statuses",
          },
        ]}
      />
    </>
  );
};

export default ContractDocumentTemplateList;
