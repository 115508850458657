import React, { FunctionComponent } from "react";
import Input from "../../Input";
import { IFilter } from "../ItemsList";

interface IBasicFilter {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  maxWidth?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BasicFilter: FunctionComponent<IBasicFilter> = (props) => {
  return (
    <>
      <Input
        style={{ maxWidth: props.maxWidth ? props.maxWidth : "200px" }}
        value={props.filter.textSearch}
        placeholder={"Szukaj"}
        onChange={(event: any) =>
          props.setFilter({
            ...props.filter,
            textSearch: event.currentTarget.value,
          })
        }
        name={"search"}
      />
    </>
  );
};
export default BasicFilter;
