import { IContinuityReportRequestData, IContinuityReportRow } from "../models";
import AxiosHelper from "../AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosPromise, AxiosResponse } from "axios";
import { IGeneratePhotoReportState } from "views/globalReports/components/PhotoReport/PhotoReport";

const PHOTO_REPORT = 18;
const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}`;
const GlobalReportsConnection = {
  get: (
    requestData: IContinuityReportRequestData
  ): Promise<AxiosResponse<IContinuityReportRow>> => {
    // todo: IContinuityReportRow
    return AxiosHelper.post(
      `${baseUrl}/worked-hours-report`,
      requestData,
      undefined,
      "globalReportsGet"
    );
  },
  form: (): Promise<AxiosResponse> => {
    return AxiosHelper.get(`${baseUrl}/worked-hours-report/filter/form`);
  },
  getFileBlob: (requestData: IContinuityReportRequestData): AxiosPromise => {
    return AxiosHelper.getBlobPost(
      `${baseUrl}/worked-hours-report/get-file`,
      requestData
    );
  },
  photoReportTemplates: (): AxiosPromise => {
    return AxiosHelper.get(
      `${baseUrl}/report_template/view/generate_report/${PHOTO_REPORT}`
    );
  },
  photoReportForm: (): AxiosPromise => {
    return AxiosHelper.get(
      `${baseUrl}/report_template/view/filters/${PHOTO_REPORT}`
    );
  },
  photoReportGenerateAsync: (
    requestData: IGeneratePhotoReportState
  ): AxiosPromise<{ reportResultId: string }> => {
    return AxiosHelper.post(`${baseUrl}/report/generate-async`, requestData);
  },
  photoReportList: (): AxiosPromise => {
    return AxiosHelper.get(
      `${baseUrl}/report/latest-async-results/${PHOTO_REPORT}`
    );
  },
  photoReportGetBlob: (id: string): AxiosPromise => {
    return AxiosHelper.getBlob(`${baseUrl}/report/file/${id}`);
  },
};

export default GlobalReportsConnection;
