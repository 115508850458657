import ProjectsList from "views/projects/components/projectsList/ProjectsList";
import TasksList from "views/projects/components/tasksList/TasksList";
import AddTask from "views/projects/components/tasks/AddTask";
import EditTask from "views/projects/components/tasks/EditTask";
import VisitsList from "views/projects/components/visits/visitsList/VisitsList";
import TaskActivities from "views/projects/components/taskActivitiesEdit/TaskActivities";
import { IRouteEntity } from "../../utils/models";
import VisitsEdit from "../../views/projects/components/visits/visitsEdit/VisitEdit";
import Basic from "../../views/projects/components/editProject/Basic";
import { AccessRoute } from "../../views/projects/components/editProject/Access";
import Locations from "../../views/projects/components/editProject/Locations";
import EditLocation from "../../views/projects/components/editProject/locations/EditLocation";
import Products from "../../views/projects/components/editProject/Products";
import FilesList from "../../views/projects/components/files/FilesList";
import ImportsList from "../../views/projects/components/imports/ImportsList";
import WarehouseListHeader from "../../views/projects/components/warehouse/WarehouseListHeader";
import WarehouseAdd from "../../views/projects/components/warehouse/WarehouseAdd";
import OrdersList from "../../views/projects/components/orders/OrdersList";
import OrderDetails from "../../views/projects/components/orders/OrderDetails";
import DocumentsList from "../../views/projects/components/documents/DocumentsList";
import StoreDocumentsList from "../../views/projects/components/storeDocuments/StoreDocumentsList";
import DocumentDetails from "../../views/projects/components/documents/DocumentDetails";
import StoreDocumentDetails from "../../views/projects/components/storeDocuments/StoreDocumentDetails";
import Reports from "../../views/projects/components/reports/Reports";
import VisitsReportTemplateEdit from "../../views/projects/components/visitsReport/VisitsReportTemplateEdit";
import OrdersTemplate from "../../views/projects/components/reports/templates/OrdersTemplate";
import SalesDocumentsTemplate from "../../views/projects/components/reports/templates/SaleDocumentsTemplate";
import LocationsTemplate from "../../views/projects/components/reports/templates/LocationsTemplate";
import StorageTemplate from "../../views/projects/components/reports/templates/StorageTemplate";
import JpkTemplate from "../../views/projects/components/reports/templates/JpkTemplate";
import JpkInvoicesTemplate from "../../views/projects/components/reports/templates/JpkInvoicesTemplate";
import UserMobileAuthenticationsTemplate from "../../views/projects/components/reports/templates/UserMobileAuthenticationsTemplate";
import VisitsReport from "../../views/projects/components/visitsReport/VisitsReport";
import FileEdit from "../../views/projects/components/files/FileEdit";
import AddProject from "../../views/projects/components/addProject/AddProject";
import ProjectIdBreadcrumb from "./ProjectIdBreadcrumb";
import KilometersList from "views/projects/components/kilometers/KilometersList";
import KilometersEdit from "views/projects/components/kilometers/KilometersEdit";
import KilometersTemplate from "../../views/projects/components/reports/templates/KilometersTemplate";
import SaleResultTemplate from "../../views/projects/components/reports/templates/SaleResultTemplate";
import ProductForm from "../../views/products/components/productForm/ProductForm";
import Alerts from "../../views/projects/components/editProject/Alerts/Alerts";
import AlertForm from "../../views/projects/components/editProject/Alerts/AlertForm";
import Gps from "../../views/projects/components/gps/Gps";
import TaskBreadcrumb from "./TaskBreadcrumb";
import Schedule from "views/schedules/Schedule";
import MyLocations from "views/projects/myLocations/MyLocations";
import ProjectRedirect from "views/projects/ProjectRedirect";
import ProjectUserLinked from "../../views/projects/components/editProject/ProjectUserLinked";
import ProjectConfigRedirect from "views/projects/ProjectConfigRedirect";
import AccessLevels from "../../views/projects/components/editProject/AccessLevels";
import ScheduleViewBoard from "views/schedules/Elements/ScheduleViewBoard";
import NoCustomTemplates from "views/projects/components/reports/templates/NoCustomTemplates";
import VisitsFeedbackActions from "../../views/projects/components/visits/visitActions/VisitsFeedbackActions";
import StorageDeliveryTemplate from "../../views/projects/components/reports/templates/StorageDeliveryTemplate";
import Targets from "../../views/projects/components/targets/Targets";
import TargetsEdit from "../../views/projects/components/targets/TargetsEdit";

const projectRoutes: Array<IRouteEntity> = [
  // projects
  { path: "/projects", breadcrumb: "Projekty", Component: ProjectsList },

  // projects/:id
  { path: "/project/add", breadcrumb: "Dodaj projekt", Component: AddProject },
  {
    path: "/projects/:id",
    breadcrumb: ProjectIdBreadcrumb,
    Component: ProjectRedirect,
  },
  {
    path: "/projects/:id/edit",
    breadcrumb: "Konfiguracja",
    Component: ProjectConfigRedirect,
  },
  {
    path: "/projects/:id/edit/basic",
    breadcrumb: "Dane ogólne",
    Component: Basic,
  },
  AccessRoute,
  {
    path: "/projects/:id/edit/locations",
    breadcrumb: "Lokalizacje",
    Component: Locations,
  },
  {
    path: "/projects/:id/edit/access-levels",
    breadcrumb: "Poziomy zatwierdzania",
    Component: AccessLevels,
  },
  {
    path: "/projects/:id/edit/locations/:locationId",
    breadcrumb: "Edytuj lokalizację",
    Component: EditLocation,
  },
  {
    path: "/projects/:id/edit/products",
    breadcrumb: "Produkty",
    Component: Products,
  },
  {
    path: "/projects/:id/edit/products/:productId",
    breadcrumb: "Edytuj produkt",
    Component: ProductForm,
  }, // name: 'projects/products/edit'
  {
    path: "/projects/:id/edit/user-linked",
    breadcrumb: "Powiązania Użytkowników",
    Component: ProjectUserLinked,
  },
  {
    path: "/projects/:id/edit/imports/:shortcut",
    breadcrumb: "Importy",
    Component: ImportsList,
  },
  {
    path: "/projects/:id/edit/imports",
    breadcrumb: "Importy",
    Component: ImportsList,
  },
  { path: "/projects/:id/edit/sync", breadcrumb: "Synchronizacja" },
  { path: "/projects/:id/edit/discounts", breadcrumb: "Rabaty" },
  { path: "/projects/:id/edit/mileage", breadcrumb: "Kilometry" },
  { path: "/projects/:id/edit/charts", breadcrumb: "Wykresy" },
  { path: "/projects/:id/edit/courses", breadcrumb: "E-Learning" },
  {
    path: "/projects/:id/edit/alerts",
    breadcrumb: "Powiadomienia E-mail",
    Component: Alerts,
  },
  {
    path: "/projects/:id/edit/alerts/add",
    breadcrumb: "Dodaj powiadomienie",
    Component: AlertForm,
  },
  {
    path: "/projects/:id/edit/alerts/:alertId/edit",
    breadcrumb: "Edytuj powiadomienie",
    Component: AlertForm,
  },

  { path: "/projects/:id/files", breadcrumb: "Pliki", Component: FilesList },
  { path: "/projects/:id/targets", breadcrumb: "Targety", Component: Targets },
  {
    path: "/projects/:id/targets/add",
    breadcrumb: "Targety",
    Component: TargetsEdit,
  },
  {
    path: "/projects/:id/targets/:targetId",
    breadcrumb: "Edycja Target ",
    Component: TargetsEdit,
  },
  {
    path: "/projects/:id/warehouse",
    breadcrumb: "Magazyn",
    Component: WarehouseListHeader,
  },
  {
    path: "/projects/:id/warehouse/add",
    breadcrumb: "Przesunięcia",
    Component: WarehouseAdd,
  },
  // projects/:id/orders
  {
    path: "/projects/:id/orders",
    breadcrumb: "Zamówienia",
    Component: OrdersList,
  },
  { path: "/projects/:id/orders/:orderId", breadcrumb: undefined },
  {
    path: "/projects/:id/orders/:orderId/details",
    breadcrumb: "Szczegóły",
    Component: OrderDetails,
  },
  // projects/:id/documents
  {
    path: "/projects/:id/documents",
    breadcrumb: "Dokumenty",
    Component: DocumentsList,
  },
  { path: "/projects/:id/documents/:documentId", breadcrumb: undefined },
  {
    path: "/projects/:id/documents/:documentId/details",
    breadcrumb: "Szczegóły",
    Component: DocumentDetails,
  },
  // projects/:id/store-documents
  {
    path: "/projects/:id/store-documents",
    breadcrumb: "Dokumenty magazynowe",
    Component: StoreDocumentsList,
  },
  { path: "/projects/:id/store-documents/:documentId", breadcrumb: undefined },
  {
    path: "/projects/:id/store-documents/:documentId/details",
    breadcrumb: "Dokumenty magazynowe",
    Component: StoreDocumentDetails,
  },

  // projects/:id/visits
  {
    path: "/projects/:id/visits",
    breadcrumb: "Realizowane wizyty",
    Component: VisitsList,
  },
  { path: "/projects/:id/visits/:visitId", breadcrumb: undefined },
  {
    path: "/projects/:id/visits/:visitId/edit",
    breadcrumb: "Edycja wizyty",
    Component: VisitsEdit,
  },
  {
    path: "/projects/:id/visits/:visitId/actions",
    breadcrumb: "Edycja wizyty",
    Component: VisitsFeedbackActions,
  },
  // projects/:id/tasks
  { path: "/projects/:id/tasks", breadcrumb: "Zadania", Component: TasksList },
  {
    path: "/projects/:id/tasks/add",
    breadcrumb: "Dodaj zadanie",
    Component: AddTask,
  },
  // projects/:id/tasks
  // { path: '/projects/:id/tasks/:taskId',  breadcrumb: 'Aktywności',  Component: Task },
  {
    path: "/projects/:id/tasks/:taskId",
    breadcrumb: TaskBreadcrumb,
    Component: TaskActivities,
  },
  // { path: '/projects/:id/tasks/:taskId/activities-edit', breadcrumb: 'Edycja aktywności', Component: TaskActivitiesRefactored },
  {
    path: "/projects/:id/tasks/:taskId/edit",
    breadcrumb: "Edytuj zadanie",
    Component: EditTask,
  },
  // projects/:id/timetable
  {
    path: "/projects/:id/timetable",
    breadcrumb: "Harmonogram projektu",
    Component: Schedule,
  },
  {
    path: "/projects/:id/schedule-board",
    breadcrumb: "Trasówki",
    Component: ScheduleViewBoard,
  },

  // projects/:id/locations
  {
    path: "/projects/:id/locations",
    breadcrumb: "Twoje lokalizacje",
    Component: MyLocations,
  },
  {
    path: "/projects/:id/locations/:locationId",
    breadcrumb: "Zobacz twoją lokalizację",
    Component: EditLocation,
  },

  // report-visits
  {
    path: "/projects/:id/reports-visits/template/add",
    breadcrumb: "Dodaj szablon raportu",
    Component: VisitsReportTemplateEdit,
  },
  {
    path: "/projects/:id/reports-visits/template/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu",
    Component: VisitsReportTemplateEdit,
  },
  { path: "/projects/:id/reports-visits/template/edit", breadcrumb: undefined },
  { path: "/projects/:id/reports-visits/template", breadcrumb: undefined },

  {
    path: "/projects/:id/reports-visits",
    breadcrumb: "Raport wizyt",
    Component: VisitsReport,
  },
  {
    path: "/projects/:id/files/:fileId/edit",
    breadcrumb: "Edycja pliku",
    Component: FileEdit,
  },

  // report-template
  {
    path: "/projects/:id/data-exports",
    breadcrumb: "Raporty",
    Component: Reports,
  },
  {
    path: "/projects/:id/data-exports/:reportType",
    breadcrumb: "Raporty",
    Component: Reports,
  },
  { path: "/projects/:id/report-template", breadcrumb: undefined },
  { path: "/projects/:id/report-template/:type", breadcrumb: undefined },

  {
    path: "/projects/:id/report-template/1/add",
    breadcrumb: "Dodaj szablon raportu zamówień",
    Component: OrdersTemplate,
  },
  {
    path: "/projects/:id/report-template/2/add",
    breadcrumb: "Dodaj szablon dokumentów sprzedażowych",
    Component: SalesDocumentsTemplate,
  },
  {
    path: "/projects/:id/report-template/3/add",
    breadcrumb: "Dodaj szablon lokalizacji",
    Component: LocationsTemplate,
  },
  {
    path: "/projects/:id/report-template/4/add",
    breadcrumb: "Dodaj szablon raportu stanów magazynowych",
    Component: StorageTemplate,
  },
  {
    path: "/projects/:id/report-template/5/add",
    breadcrumb: "Dodaj szablon raportu jpk",
    Component: JpkTemplate,
  },
  {
    path: "/projects/:id/report-template/6/add",
    breadcrumb: "Dodaj szablon raportu faktur jpk",
    Component: JpkInvoicesTemplate,
  },
  {
    path: "/projects/:id/report-template/7/add",
    breadcrumb: "Dodaj szablon raportu kilometrów",
    Component: KilometersTemplate,
  },
  {
    path: "/projects/:id/report-template/8/add",
    breadcrumb: "Dodaj szablon raportu sprzedaży",
    Component: SaleResultTemplate,
  },
  {
    path: "/projects/:id/report-template/16/add",
    breadcrumb: "Dodaj szablon raportu logowań",
    Component: UserMobileAuthenticationsTemplate,
  },
  {
    path: "/projects/:id/report-template/32/add",
    breadcrumb: "Dodaj szablon raportu dostaw magazynowych",
    Component: StorageDeliveryTemplate,
  },
  {
    path: "/projects/:id/report-template/:reportType/add",
    breadcrumb: "",
    Component: NoCustomTemplates,
  },

  {
    path: "/projects/:id/report-template/1/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu zamówień",
    Component: OrdersTemplate,
  },
  {
    path: "/projects/:id/report-template/2/edit/:templateId",
    breadcrumb: "Edytuj szablon dokumentów sprzedażowych",
    Component: SalesDocumentsTemplate,
  },
  {
    path: "/projects/:id/report-template/3/edit/:templateId",
    breadcrumb: "Edytuj szablon lokalizacji",
    Component: LocationsTemplate,
  },
  {
    path: "/projects/:id/report-template/4/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu stanów magazynowych",
    Component: StorageTemplate,
  },
  {
    path: "/projects/:id/report-template/32/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu dostaw magazynowych",
    Component: StorageDeliveryTemplate,
  },
  {
    path: "/projects/:id/report-template/5/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu jpk",
    Component: JpkTemplate,
  },
  {
    path: "/projects/:id/report-template/6/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu faktur jpk",
    Component: JpkInvoicesTemplate,
  },
  {
    path: "/projects/:id/report-template/7/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu kilometrów",
    Component: KilometersTemplate,
  },
  {
    path: "/projects/:id/report-template/8/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu sprzedaży",
    Component: SaleResultTemplate,
  },
  {
    path: "/projects/:id/report-template/16/edit/:templateId",
    breadcrumb: "Edytuj szablon raportu logowań",
    Component: UserMobileAuthenticationsTemplate,
  },

  // KILOMETERS
  {
    path: "/projects/:id/kilometers",
    breadcrumb: "Kilometry",
    Component: KilometersList,
  },
  {
    path: "/projects/:id/kilometers/add",
    breadcrumb: "Dodaj",
    Component: KilometersEdit,
  },
  {
    path: "/projects/:id/kilometers/:kilometersId/edit",
    breadcrumb: "Edytuj",
    Component: KilometersEdit,
  },

  { path: "/projects/:id/gps", breadcrumb: "Gps", Component: Gps },
];

export default projectRoutes;
