import { _paginate30 } from "utils/states";
import {
  ICreateContractDocumentTemplate,
  IGetContractDocumentTemplate,
} from "../../models/contract/contractDocumentTemplateModels";

export const getContractDocumentTemplatesState: IGetContractDocumentTemplate = {
  name: "",
  status: "",
  content: "",
  category: "",
  ..._paginate30,
};

export const createContractDocumentTemplateState: ICreateContractDocumentTemplate =
  {
    name: "",
    status: "",
    category: "",
    content: "",
    type: "",
    fileName: "",
    fileBase64: "",
    isRequiredAccept: false,
    isRequiredSign: false,
  };
