import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";

const MenuConnection = {
  get: (projectId: string | null) => {
    const url = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/menus/main${
      projectId ? `/${projectId}` : ""
    }`;
    return axios.get(url, {
      headers: getAuthHeader(),
    });
  },
};

export default MenuConnection;
