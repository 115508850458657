import { _paginate30 } from "utils/states";
import {
  ICreateContractProject,
  ICreateContractProjectDocument,
  IGetContractProject,
  IGetContractProjectDocument,
} from "../../models/contract/contractProjectModels";

export const getContractProjectState: IGetContractProject = {
  name: "",
  type: "",
  status: "",
  notice: "",
  ..._paginate30,
};
export const getContractProjectDocumentState: IGetContractProjectDocument = {
  name: "",
  status: "",
  category: "",
  content: "",
  fileName: "",
  contractProjectId: "",
  ..._paginate30,
};

export const createContractProjectState: ICreateContractProject = {
  name: "",
  type: "",
  status: "",
  notice: "",
  settlement: "",
  startDate: "",
  endDate: "",
  projectId: "",
  assigned: [],
  unassigned: [],
};
export const createContractProjectDocumentState: ICreateContractProjectDocument =
  {
    name: "",
    type: "",
    status: "",
    notice: "",
    settlement: "",
    startDate: "",
    endDate: "",
  };
