import React, { FunctionComponent, useEffect, useState } from "react";
import VisitsConnection from "../../../../../utils/connections/visits";
import { useHistory, useParams } from "react-router-dom";
import {
  IGetProjectLocations,
  ILocation,
  ILocationPaginated,
  IVisitCreate,
  IVisitForm,
} from "../../../../../utils/models";
import { getProjectsLocationsState } from "../../../../../utils/states";
import {
  Button,
  Col,
  DatePicker,
  Message,
  Panel,
  Row,
  Table,
  TagPicker,
} from "rsuite";
import ButtonOutlined from "../../../../../global/atoms/ButtonOutlined";
import ButtonSubmit from "../../../../../global/atoms/ButtonSubmit";
import { getProjectIdFromUrl, handleToast } from "../../../../../utils/helpers";
import Pagination from "../../../../../global/pagination/Pagination";
import styles from "../../../../../global/common.module.scss";
import Input from "../../../../../global/atoms/Input";
import Select from "../../../../../global/atoms/Select";
import Checkbox from "../../../../../global/atoms/Checkbox";
import { beforeToday } from "rsuite/esm/DateRangePicker/disabledDateUtils";
import dayjs from "dayjs";
import UniversalModal from "global/atoms/UniversalModal";
import { RowDataType } from "rsuite/esm/Table";

interface IVisitsModalAdd {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visitAddedCB?: () => void;
  locationsId?: string;
}

const VisitsModalAdd: FunctionComponent<IVisitsModalAdd> = (props) => {
  interface IEntitySimplified {
    value: string;
    label: string;
  }

  const { id } = useParams<{ id: string }>();
  const [tasksSelectState, setTasksSelectState] = useState<
    Array<IEntitySimplified>
  >([]);
  const [usersSelectState, setUsersSelectState] = useState<{
    [index: string]: Array<IEntitySimplified>;
  }>({});
  const [locationsSelected, setLocationsSelected] = useState<
    ILocation[] | null
  >(null);

  const [locationRequestData, setLocationRequestData] =
    useState<IGetProjectLocations>(getProjectsLocationsState);
  const [locations, setLocations] = useState<ILocationPaginated | null>(null);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);
  const [visitFormState, setVisitFormState] = useState<IVisitForm | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [filterActive, setFilterActive] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [stateRoleId, setStateRoleId] = useState<{ roleId: string }>({
    roleId: "",
  });
  const [futureDate, setIsFutureDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();

  const loadLocations = () => {
    setLocationsLoading(true);
    setLocations(null);
    locationRequestData.status = "REPORTABLE";
    VisitsConnection.getLocations(id, {
      ...locationRequestData,
      roleId: stateRoleId.roleId,
    }).then((res) => {
      setLocations(res.data);
      if (props.locationsId) {
        const activeLocation: Array<ILocation> =
          res.data.data.filter(
            (location) => location.id == props?.locationsId
          ) ?? [];
        if (activeLocation) setLocationsSelected(activeLocation);
      }
      setLocationsLoading(false);
    });
  };

  useEffect(() => {
    loadLocations();
  }, [locationRequestData]);

  // select all toggle
  useEffect(() => {
    if (selectAll) {
      setLocationsSelected(locations?.data ?? []);
    } else setLocationsSelected([]);
  }, [selectAll]);

  // filter clear on close
  useEffect(() => {
    if (!filterActive) setLocationRequestData(getProjectsLocationsState);
  }, [filterActive]);

  // filter user role change
  useEffect(() => {
    if (
      stateRoleId.roleId !== "" &&
      locationRequestData.reportingUserId !== ""
    ) {
      setLocationRequestData({
        ...locationRequestData,
        reportingUserId: "",
      });
    }
  }, [stateRoleId]);

  useEffect(() => {
    if (!props.open) clearModalData();
    else {
      VisitsConnection.getForm(id).then((res) => {
        setVisitFormState(res.data);
        setTasksSelectState(
          res.data.tasks.data
            .filter((el) => el.type == "ACTIVE")
            .map((d) => ({ value: d.id, label: d.name })) ?? []
        );
      });
      loadLocations();
    }
  }, [props.open]);

  useEffect(() => {
    locationsSelected?.forEach((ls) => {
      if (ls.users.length && !usersSelectState[ls.id]) {
        setUsersSelectState({
          ...usersSelectState,
          [ls.id]: ls.users.map((u) => ({ value: u.id, label: u.name })),
        });
      }
    });
  }, [locationsSelected]);

  const createVisit = () => {
    setFormSubmitting(true);

    const data: IVisitCreate = {
      locations: locationsSelected?.map((ls) => ({ id: ls.id })) ?? [],
      tasks: tasksSelectState?.map((v) => ({ id: v.value })) ?? [],
      startDate: dayjs(startDate).unix(),
      // users: usersSelectState[ls.id]?.map(us => ({id: us.value})) ?? []
    };
    // handle toast

    handleToast(VisitsConnection.createVisit(id, data), setErrors)
      .then((request) => {
        if (futureDate && request.status === 200) {
          history.push(`/projects/${getProjectIdFromUrl()}/timetable`);
        }
        props.setOpen(false);
        if (props.visitAddedCB) props.visitAddedCB();
      })
      .catch(() => {
        setFormSubmitting(false);
      });
  };

  const clearModalData = () => {
    setTasksSelectState([]);
    setUsersSelectState({});
    setLocationsSelected(null);
    setFormSubmitting(false);
    setFilterActive(false);
    setStateRoleId({ roleId: "" });
  };

  const errorPrintMessage = (key: string) => {
    // @ts-ignore
    if (!errors || !errors[key]) return <></>;
    // @ts-ignore
    return <div className={styles.loadingSmallErr}>{errors[key]}</div>;
  };

  const handleStartDateChange = (data: Date | null) => {
    setIsFutureDate(!!data && data > new Date());
    setStartDate(data ?? new Date());
  };
  const handleSelect = (rowData: ILocation) => {
    const selected =
      locationsSelected?.find((ls) => ls.id === rowData.id) !== undefined;
    if (!selected) {
      setLocationsSelected(
        locationsSelected ? locationsSelected.concat([rowData]) : [rowData]
      );
    } else {
      setLocationsSelected(
        locationsSelected?.filter((ls) => ls.id !== rowData.id) ?? []
      );
    }
  };

  return (
    <>
      <UniversalModal
        size={"full"}
        displayFooterButtons={false}
        open={props.open}
        title={
          <Row>
            <Col md={12} xs={24}>
              <h1>
                Dodaj wizytę poza planem (
                {dayjs(startDate).format("YYYY-MM-DD")})
              </h1>
            </Col>

            <Col md={12} xs={24}>
              <div style={{ textAlign: "right", marginBottom: "15px" }}>
                <ButtonOutlined onClick={() => props.setOpen(false)}>
                  Anuluj
                </ButtonOutlined>
                &nbsp;
                <ButtonSubmit
                  disabled={
                    !locationsSelected ||
                    !locationsSelected?.length ||
                    !tasksSelectState.length ||
                    formSubmitting
                  }
                  onClick={createVisit}
                  name={"Generuj wizyty"}
                />
              </div>
            </Col>
          </Row>
        }
        body={
          <div style={{ overflowX: "hidden" }}>
            <Panel>
              <div>
                {futureDate && (
                  <Message
                    style={{ marginBottom: "8px" }}
                    showIcon
                    type="info"
                    header="Wybrano datę przyszłą! Wizyta zostanie dodana do harmonogramu."
                  />
                )}
                <Row>
                  <Col md={4} xs={24}>
                    <label>Wybierz datę wizyty</label>
                    <DatePicker
                      oneTap
                      isoWeek
                      style={{ width: "100%" }}
                      onChange={handleStartDateChange}
                      format={"yyyy-MM-dd"}
                      value={startDate ?? new Date()}
                      shouldDisableDate={beforeToday()}
                      showWeekNumbers
                      placeholder="Wybierz datę wizyty..."
                    />
                  </Col>
                  <Col md={17} xs={24}>
                    <label>Wybierz zadania</label>
                    <TagPicker
                      block
                      placeholder={"Wybierz zadania"}
                      value={tasksSelectState.map((v) => v.value)}
                      data={
                        visitFormState?.tasks.data
                          .filter((el) => el.type == "ACTIVE")
                          .map((d) => ({
                            value: d.id,
                            label: d.name,
                          })) ?? []
                      }
                      onChange={(selectedOptions) => {
                        setTasksSelectState(
                          visitFormState?.tasks.data
                            .filter(
                              (t) => selectedOptions?.find((id) => id === t.id)
                            )
                            .map((f) => ({ value: f.id, label: f.name })) ?? []
                        );
                      }}
                    />
                    {errorPrintMessage("tasks")}
                  </Col>
                  <Col md={3} xs={24}>
                    <label>&nbsp;</label>
                    <Button
                      block
                      onClick={() => setFilterActive(!filterActive)}
                      appearance={filterActive ? "primary" : "ghost"}>
                      Filtry
                    </Button>
                  </Col>
                </Row>
              </div>
            </Panel>
            <Panel hidden={!filterActive}>
              <Row style={{ margin: "0" }}>
                <Col xs={12}>
                  <Input
                    name={"name"}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={"Nazwa lokalizacji"}
                    value={locationRequestData.name}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    name={"locationCustomerCode"}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={"Kod klienta"}
                    value={locationRequestData.locationCustomerCode}
                  />
                </Col>
                <Col xs={8}>
                  <Select
                    name={"networkId"}
                    placeholder={"Sieć"}
                    options={[{ name: "Wszystkie", id: "" }].concat(
                      visitFormState?.networks.data ?? []
                    )}
                    value={locationRequestData.networkId}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                  />
                </Col>
              </Row>
              <br />
              <Row style={{ margin: "0" }}>
                <Col xs={8}>
                  <Input
                    name={"city"}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={"Miasto"}
                    value={locationRequestData.city}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    name={"street"}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={"Ulica"}
                    value={locationRequestData.street}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    name={"nip"}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={"NIP"}
                    value={locationRequestData.nip}
                  />
                </Col>
              </Row>
              <br />

              <Row style={{ margin: "0" }}>
                <Col xs={12}>
                  <Select
                    name={"roleId"}
                    placeholder={"Grupa"}
                    options={[{ name: "Wszystkie", id: "" }].concat(
                      visitFormState?.roles?.data ?? []
                    )}
                    value={stateRoleId.roleId}
                    state={stateRoleId}
                    onChange2={(selected) => {
                      // @ts-ignore
                      setStateRoleId({ roleId: selected.value ?? "" });
                      setLocationRequestData({
                        ...locationRequestData,
                        reportingUserId: "",
                      });
                    }}
                  />
                </Col>

                <Col xs={12}>
                  <Select
                    disabled={!stateRoleId.roleId}
                    name={"reportingUserId"}
                    placeholder={"Grupa -> Użytkownik"}
                    options={[{ name: "Wszystkie", id: "" }].concat(
                      visitFormState?.users.data.filter((u) => {
                        if (!stateRoleId.roleId) return true;
                        return u.roleId === stateRoleId.roleId;
                      }) ?? []
                    )}
                    value={locationRequestData.reportingUserId}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                  />
                </Col>
              </Row>
            </Panel>
            <div style={{ position: "relative", zIndex: 0 }}>
              <Panel>
                <Table
                  autoHeight
                  wordWrap="break-word"
                  data={locations?.data ?? []}
                  loading={locationsLoading}>
                  <Table.Column align={"center"} width={50}>
                    <Table.HeaderCell style={{ padding: "0" }}>
                      <Checkbox
                        isChecked={selectAll}
                        onClick={() => {
                          setSelectAll(!selectAll);
                        }}
                      />
                    </Table.HeaderCell>
                    <Table.Cell style={{ padding: "0" }}>
                      {(rowData: RowDataType<ILocation>) => (
                        <Checkbox
                          onClick={() => {
                            handleSelect(rowData as ILocation);
                          }}
                          isChecked={
                            locationsSelected?.find(
                              (ls) => ls.id === rowData.id
                            ) !== undefined
                          }
                        />
                      )}
                    </Table.Cell>
                  </Table.Column>

                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>{"Nazwa lokalizacji"}</Table.HeaderCell>
                    <Table.Cell verticalAlign={"middle"} dataKey={"name"} />
                  </Table.Column>

                  <Table.Column>
                    <Table.HeaderCell>{"Sieć"}</Table.HeaderCell>
                    <Table.Cell>
                      {(rowData: RowDataType<ILocation>) =>
                        rowData.network.name
                      }
                    </Table.Cell>
                  </Table.Column>

                  <Table.Column width={200}>
                    <Table.HeaderCell>{"Miasto"}</Table.HeaderCell>
                    <Table.Cell>
                      {(rowData: RowDataType<ILocation>) =>
                        rowData.address.city.name
                      }
                    </Table.Cell>
                  </Table.Column>

                  <Table.Column align={"right"} flexGrow={1}>
                    <Table.HeaderCell>{"Adres"}</Table.HeaderCell>
                    <Table.Cell>
                      {(rowData: RowDataType<ILocation>) => (
                        <>
                          <div>
                            {rowData.address.street?.name}&nbsp;
                            {rowData.address.houseNumber}
                            {rowData.address.apartmentNumber
                              ? ` / ${rowData.address.apartmentNumber}`
                              : ""}
                          </div>
                        </>
                      )}
                    </Table.Cell>
                  </Table.Column>

                  <Table.Column align={"right"} flexGrow={1}>
                    <Table.HeaderCell>{"Raportujący"}</Table.HeaderCell>
                    <Table.Cell>
                      {(rowData: RowDataType<ILocation>) =>
                        rowData.users.map((u) => (
                          <div key={`users-${rowData.id}-${u.id}`}>
                            {u.name}
                          </div>
                        ))
                      }
                    </Table.Cell>
                  </Table.Column>
                </Table>
              </Panel>
            </div>
          </div>
        }
        customFooterButtons={
          <Pagination
            count={locations?.count ?? 0}
            page={locationRequestData.requestPaginate.page.toString()}
            limit={locationRequestData.requestPaginate.limit.toString()}
            setState={setLocationRequestData}
            limitOptions={[30, 100, 500, 1000]}
          />
        }
      />
    </>
  );
};

export default VisitsModalAdd;
