import AxiosHelper from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import {
  IWarehouseAvailabilityFilters,
  IWarehouseDeliveryFilters,
} from "../models";
import { IWarehouseAddData } from "../../views/projects/components/warehouse/WarehouseAdd";

const WarehouseConnection = {
  availabilityList: (data: IWarehouseAvailabilityFilters) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/stores/availability/list`,
      data,
      {
        headers: getAuthHeader(),
      },
      "availabilityList"
    );
  },
  availabilityFilterForm: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/stores/availability/filter/form`
    );
  },

  userAvailabilityList: (data: IWarehouseAvailabilityFilters) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/availability/list`,
      data,
      {
        headers: getAuthHeader(),
      },
      "userAvailabilityList"
    );
  },
  userAvailabilityFilterForm: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/availability/filter/form`
    );
  },
  userShiftList: (data: IWarehouseAvailabilityFilters) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/shift/list`,
      data,
      undefined,
      "userShiftList"
    );
  },

  deliveryList: (data: IWarehouseDeliveryFilters) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/stores/incoming/list`,
      data,
      undefined,
      "deliveryList"
    );
  },

  deliveryFilterForm: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/stores/incoming/filter/form`
    );
  },
  userShiftFilterForm: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/shift/filter/form`
    );
  },
  getFormOptionsWarehouse: (projectId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/choice/form`
    );
  },
  getFormUsersAndRolesWarehouse: (projectId: string, data) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/form`,
      data,
      undefined,
      "getFormUsersAndRolesWarehouse"
    );
  },
  getItems: (projectId: string, userId: string, userRoleId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/${userId}/roles/${userRoleId}/stores/entry/items/form`,
      undefined,
      "getItems"
    );
  },
  save: (projectId: string, type: string, data: IWarehouseAddData) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/entry/${type}`,
      data
    );
  },
  ///project/{projectId}/users/stores/operations/accept
  cancelDelivery: (projectId: string, ids: Array<{ id: string }>) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/cancel`,
      { ids: ids }
    );
  },
  confirmDelivery: (projectId: string, ids: Array<{ id: string }>) => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/accept`,
      { ids: ids }
    );
  },
};

export default WarehouseConnection;
