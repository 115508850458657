import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateQuestion } from "utils/models";
import { createQuestionState } from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import QuestionsConnection from "utils/connections/questions";
import Input from "global/atoms/Input";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import { useDispatch } from "react-redux";
import Select from "global/atoms/Select";
import CheckBox from "global/atoms/checkbox/CheckBox";
import QuestionEditAnswers from "./QuestionEditAnswers";
import Spinner from "global/atoms/Spinner/Spinner";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { Col, Form, Panel, Row, TagPicker } from "rsuite";
import SelectPicker from "rsuite/SelectPicker";
import ToastNotificationPush, {
  ToastTypes,
} from "../../../../global/ToastNotification";

interface IQuestionsEdit {}

const INTEGER_QUESTION = "INTEGER_QUESTION";
const DECIMAL_QUESTION = "DECIMAL_QUESTION";
const TEXT_QUESTION = "TEXT_QUESTION";
const TEXTAREA_QUESTION = "TEXTAREA_QUESTION";
const PHOTO_QUESTION = "PHOTO_QUESTION";
const DICTIONARY_QUESTION = "DICTIONARY_QUESTION";
const DICTIONARY_MULTIPLE_ANSWERS_QUESTION =
  "DICTIONARY_MULTIPLE_ANSWERS_QUESTION";

export interface IQuestionsEditState {
  answer: string;
  isDefault: boolean;
  isAnswerToDepend: boolean;
  editId: number | null;
}

export const defaultQuestionEditState: IQuestionsEditState = {
  answer: "",
  isDefault: false,
  editId: null,
  isAnswerToDepend: false,
};

const QuestionsEdit: FunctionComponent<IQuestionsEdit> = () => {
  const [data, setData] = useState<ICreateQuestion>(createQuestionState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<IQuestionsEditState>(
    defaultQuestionEditState
  );
  const cloned = window.location.search === "?cloned";

  const [projectId, setProjectId] = useState<string>("");
  const [taskId, setTaskId] = useState<string>("");

  const load = () => {
    QuestionsConnection.getQuestionsFormEdit(id).then((data) => {
      setData({
        activityTypeId: data.data.model.activityType.id,
        questionTypeId: data.data.model.questionType.id,
        questionType: data.data.model.questionType.type,
        customerId: data.data.model.customer.id,
        description: data.data.model.description,
        code: data.data.model.code,
        name: data.data.model.name,
        isRequired: data.data.model.required,
        rate: data.data.model.rate,
        minValue: data.data.model.minValue,
        maxValue: data.data.model.maxValue,
        answers: data.data.model.answers,
        isEdit: data.data.model.edit,
        assignToActivities: data.data.model.assignToActivities,
      });
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  };

  useEffect(() => {
    load();
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const dataClone = { ...data };
    const minValue: any = dataClone.minValue;
    const maxValue: any = dataClone.maxValue;
    const rate: any = dataClone.rate;
    dataClone.minValue = minValue !== null ? parseInt(minValue) : null;
    dataClone.maxValue = maxValue !== null ? parseInt(maxValue) : null;
    dataClone.rate = rate ? parseInt(rate) : null;
    dataClone.answers.map((data, index) => {
      dataClone.answers[index].order = index + 1;
    });

    handleToast(
      QuestionsConnection.updateQuestion(id, dataClone),
      setErrors
    ).then(() => history.push("/datasets/questions"));
  };

  const handleChangeRequired = () => {
    const required = !data.isRequired;
    setData({ ...data, ...{ isRequired: required } });
  };

  const handleChangeEdit = () => {
    const isEdit = !data.isEdit;
    setData({ ...data, ...{ isEdit: isEdit } });
  };

  const ToasterMessage = (message: string) => {
    ToastNotificationPush(ToastTypes.info, message, undefined, 6000);
  };

  const checkMinMaxShouldDisplay = (type: string) => {
    const availableTypes = [
      INTEGER_QUESTION,
      DECIMAL_QUESTION,
      TEXT_QUESTION,
      TEXTAREA_QUESTION,
      PHOTO_QUESTION,
      DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
    ];
    return availableTypes.includes(type);
  };
  const getDefaultMaxValue = (): number => {
    switch (data.questionType) {
      case INTEGER_QUESTION:
      case DECIMAL_QUESTION:
        return 10000;

      case TEXT_QUESTION:
      case TEXTAREA_QUESTION:
        return 1000;
      case PHOTO_QUESTION:
        return 3;

      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
        return 10;
      default:
        return 255;
    }
  };
  const ERROR_MESSAGE = "Ustawiono wartości domyślne dla danej konfiguracji.";
  const ERROR_MESSAGE_MAX = "Wartość maksymalna musi być większa od 0.";
  useEffect(() => {
    if (!checkMinMaxShouldDisplay(data.questionType)) return;

    if (data?.minValue?.toString() == "" || (data?.minValue ?? 0) < 0) {
      ToasterMessage(`Wartość minimalna nie może być pusta. ${ERROR_MESSAGE}`);
      setData((state: any) => ({
        ...state,
        minValue: 0,
      }));
    }

    if (data.maxValue == undefined || data.maxValue <= 0) {
      ToasterMessage(`${ERROR_MESSAGE_MAX} ${ERROR_MESSAGE}`);
      setData((state: any) => ({
        ...state,
        maxValue: getDefaultMaxValue(),
      }));
    }
    if (data.maxValue && data.minValue && data.maxValue < data.minValue) {
      ToasterMessage(
        `Wartość minimalna nie może być większa od wartości maksymalnej. ${ERROR_MESSAGE}`
      );
      setData((state: any) => ({
        ...state,
        maxValue: state.minValue,
      }));
    }
  }, [data.minValue, data.maxValue]);

  if (form === "loading") return <Spinner />;
  const getProjectOptions = (): Array<{ id: string; name: string }> => {
    const modifiedOptions = form.activities.options;

    const projectIds = new Set(
      modifiedOptions.map((element) => element.projectId)
    );

    const projects = Array.from(projectIds).map((id) =>
      modifiedOptions.find((element) => element.projectId === id)
    );

    if (form.model.customer.customerType.type === "ORGANIZATION") {
      return projects;
    }
    return projects.filter((el) => el.customerId == data.customerId);
  };

  const getTaskOptions = (): Array<{ id: string; name: string }> => {
    const modifiedOptions = form.activities.options.map((el) => ({
      ...el,
      label: `${el.projectName}->${el.taskName}`,
      groupBy: `${el.projectName}`,
    }));

    const taskIds = new Set(modifiedOptions.map((element) => element.taskId));

    const tasks = Array.from(taskIds)
      .map((id) => modifiedOptions.find((element) => element.taskId === id))
      .filter((e) => (projectId ? e.projectId == projectId : true));

    if (form.model.customer.customerType.type === "ORGANIZATION") {
      return tasks;
    }
    return tasks.filter((el) => el.customerId == data.customerId);
  };

  const getActivityOptions = (): Array<{ id: string; name: string }> => {
    const modifiedOptions = form.activities.options
      .map((el) => ({
        ...el,
        label: `${el.projectName}->${el.taskName}->${el.activityName}`,
        groupBy: `${el.projectName}->${el.taskName}`,
      }))
      .filter((el) => {
        return (
          (projectId ? el.projectId == projectId : true) &&
          (taskId ? el.taskId == taskId : true)
        );
      });

    if (form.model.customer.customerType.type === "ORGANIZATION") {
      return modifiedOptions;
    }
    return modifiedOptions.filter((el) => el.customerId == data.customerId);
  };

  return (
    <>
      <Form>
        <WhiteCard padding={true}>
          <Row>
            <Col xs={24}>
              <Input
                type={"text"}
                placeholder={"Nazwa"}
                name={"name"}
                value={data.name}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={20}>
              <Input
                type={"text"}
                placeholder={"Opis"}
                name={"description"}
                value={data.description}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={4}>
              <Input
                type={"text"}
                placeholder={"Kod"}
                name={"code"}
                value={data.code}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Select
                options={form.activityTypes.options}
                disabled={true}
                placeholder={"Typ aktywności"}
                name={"activityTypeId"}
                value={data.activityTypeId}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={12}>
              <Select
                options={form.questionTypes.options}
                disabled={true}
                placeholder={"Typ pytania"}
                name={"questionTypeId"}
                value={data.questionTypeId}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
          </Row>
          <Col xs={12} style={{ paddingLeft: "0px" }}>
            <Col xs={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Select
                options={form.customers.options}
                disabled={!cloned}
                placeholder={"Klient"}
                name={"customerId"}
                value={data.customerId}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Col>
            <Col xs={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              {checkMinMaxShouldDisplay(data.questionType) && (
                <>
                  <Col xs={12} style={{ paddingLeft: "0px" }}>
                    <Input
                      type={"number"}
                      placeholder={"Min"}
                      name={"minValue"}
                      value={data.minValue}
                      min={0}
                      state={data}
                      setState={setData}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={12} style={{ paddingRight: "0px" }}>
                    <Input
                      type={"number"}
                      placeholder={"Max"}
                      name={"maxValue"}
                      value={data.maxValue}
                      min={1}
                      state={data}
                      setState={setData}
                      errors={errors}
                    />
                  </Col>
                </>
              )}
            </Col>
            <Col xs={24}>
              <SeparatorEmpty />
              <CheckBox
                isChecked={data.isRequired}
                onClick={handleChangeRequired}>
                Pytanie wymagane
              </CheckBox>
              <CheckBox isChecked={data.isEdit} onClick={handleChangeEdit}>
                Możliwość edycji
              </CheckBox>
            </Col>
          </Col>
          <SeparatorEmpty />
        </WhiteCard>
        <SeparatorEmpty />
        <Panel
          style={{ backgroundColor: "#fff" }}
          shaded
          header={
            "Wybierz aktywności, do których, pytanie zostanie automatycznie dowiązane"
          }>
          <Form.Group>
            <Col xs={12}>
              <Form.Control
                name={"projectId"}
                accepter={SelectPicker}
                block
                valueKey={"projectId"}
                labelKey={"projectName"}
                data={getProjectOptions() ?? []}
                placeholder={"Projekt filtr..."}
                errorMessage={errors.projects}
                errorPlacement={"bottomEnd"}
                onChange={(value) => {
                  setProjectId(value);
                }}
              />
            </Col>
            <Col xs={12}>
              <Form.Control
                name={"taskIds"}
                accepter={SelectPicker}
                block
                valueKey={"taskId"}
                labelKey={"taskName"}
                groupBy={"projectName"}
                data={getTaskOptions() ?? []}
                placeholder={"Zadanie filtr..."}
                errorMessage={errors.projects}
                errorPlacement={"bottomEnd"}
                onChange={(value) => {
                  setTaskId(value);
                }}
              />
            </Col>
            <SeparatorEmpty />
            <Col xs={24}>
              <Form.Control
                name={"activityIds"}
                accepter={TagPicker}
                block
                valueKey={"activityId"}
                labelKey={"label"}
                groupBy={"groupBy"}
                data={getActivityOptions() ?? []}
                placeholder={"Aktywności..."}
                errorMessage={errors.projects}
                errorPlacement={"bottomEnd"}
                onChange={(values) => {
                  setData({
                    ...data,
                    assignToActivities: values.map((el) => ({ id: el })),
                  });
                }}
              />
            </Col>
          </Form.Group>
          <SeparatorEmpty />
        </Panel>

        <SeparatorEmpty />
        <ActionsContainer>
          <ButtonSubmit onClick={handleSubmit} />
        </ActionsContainer>
      </Form>
      {(data.questionType === DICTIONARY_QUESTION ||
        data.questionType === DICTIONARY_MULTIPLE_ANSWERS_QUESTION) && (
        <QuestionEditAnswers
          state={state}
          setState={setState}
          data={data}
          setData={setData}
          triggerLoadCB={() => load()}
          handleSubmit={handleSubmit}
          form={form}
        />
      )}
    </>
  );
};

export default QuestionsEdit;
