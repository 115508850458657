import { getProjectIdFromUrl } from "utils/helpers";
import AxiosHelper, { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosResponse, AxiosPromise } from "axios";
import {
  IGetProjectLocations,
  ILocationPaginated,
  IVisitCreate,
  IVisitForm,
} from "../models";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;

export interface IQuestionComment {
  toImprove: boolean;
  comment: string;
  images: Array<string>;
  userName?: string;
  createdAt?: string;
}

const VisitsConnection = {
  getComments: (visitId: string, projectId?: string): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${baseUrl}/${
        projectId ?? getProjectIdFromUrl()
      }/visits/${visitId}/comment/list`
    );
  },
  getForm: (projectId: string): Promise<AxiosResponse<IVisitForm>> => {
    return axios.get(`${baseUrl}/${projectId}/visits/form`, {
      headers: getAuthHeader(),
    });
  },
  getLocations: (
    projectId: string,
    requestData: IGetProjectLocations
  ): Promise<AxiosResponse<ILocationPaginated>> => {
    return axios.post(
      `${baseUrl}/${projectId}/locations/assigned/list`,
      requestData,
      {
        headers: getAuthHeader(),
      },
      "getLocations"
    );
  },

  createVisit: (
    projectId: string,
    data: IVisitCreate
  ): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseUrl}/${projectId}/visits`, data, {
      headers: getAuthHeader(),
    });
  },
  setComments: (visitId: string, data: any): AxiosPromise<any> => {
    //const projectId = getProjectIdFromUrl();
    return AxiosHelper.patch(`${baseUrl}/visits/${visitId}/comments`, data);
  },
  setApprove: (visitId: string, data: any): AxiosPromise<any> => {
    //const projectId = getProjectIdFromUrl();
    return AxiosHelper.patch(`${baseUrl}/visits/${visitId}/approve`, data);
  },
  changeDedLineDate: (data: any): AxiosPromise<any> => {
    return AxiosHelper.patch(`${baseUrl}/visits/change-deadlines`, data);
  },
};

export default VisitsConnection;
