import { IRouteEntity } from "utils/models";
import ContractProjectEdit from "../../../views/contract/projects/ContractProjectEdit";
import ContractProjectList from "../../../views/contract/projects/ContractProjectList";
import ContractProjectDocumentList from "../../../views/contract/projects/ContractProjectDocumentList";
import ContractProjectIdBreadcrumb from "./ContractProjectIdBreadcrumb";
import ContractProjectSalariesList from "../../../views/contract/projects/ContractProjectSalariesList";
import ContractProjectSalariesEdit from "../../../views/contract/projects/ContractProjectSalariesEdit";

export const contractProjectPath = "/contracts/containers";
export const contractProjectSalariesPath = `${contractProjectPath}/:id/salaries`;

const routes: IRouteEntity[] = [
  {
    path: `${contractProjectPath}`,
    breadcrumb: "Projekty",
    Component: ContractProjectList,
  },
  //{path: `${path}/:id`, breadcrumb: ContractProjectIdBreadcrumb, matchOptions: {strict: true}},
  {
    path: `${contractProjectPath}/add`,
    breadcrumb: "Dodaj project",
    Component: ContractProjectEdit,
  },
  {
    path: `${contractProjectPath}/:id/edit`,
    breadcrumb: ContractProjectIdBreadcrumb,
    Component: ContractProjectEdit,
  },
  {
    path: `${contractProjectPath}/:id/documents`,
    breadcrumb: ContractProjectIdBreadcrumb,
    Component: ContractProjectDocumentList,
  },

  {
    path: `${contractProjectSalariesPath}`,
    breadcrumb: "Wynagrodzenia",
    Component: ContractProjectSalariesList,
  },
  {
    path: `${contractProjectSalariesPath}/add`,
    breadcrumb: "Wynagrodzenia dodaj",
    Component: ContractProjectSalariesEdit,
  },
  {
    path: `${contractProjectSalariesPath}/:salaryId/edit`,
    breadcrumb: "Wynagrodzenia edycja",
    Component: ContractProjectSalariesEdit,
  },
];

export default routes;
