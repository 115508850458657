import React, { FunctionComponent } from "react";
import CSS from "csstype";
import { Panel, PanelProps } from "rsuite";

interface IWhiteCard extends PanelProps {
  header?: string;
  mobileTransparent?: boolean;
  children: React.ReactNode;
  style?: CSS.Properties;
  padding?: boolean;
  marginTop?: boolean;
  className?: any;
}

const WhiteCard: FunctionComponent<IWhiteCard> = (props) => {
  const cardStyle: CSS.Properties = {
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    ...props.style,
  };

  return (
    <Panel
      header={props.header}
      style={cardStyle}
      shaded
      bodyFill={props.padding === false}>
      {props.children}
    </Panel>
  );
};

export default WhiteCard;
