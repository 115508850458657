import AxiosHelper, {
  AxiosManager,
  HttpMethod,
  default as axios,
} from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import {
  IAddLocationCustomer,
  ICreateLocation,
  IGetLocations,
} from "../models";
import { AxiosPromise } from "axios";

export const getSaveAddressData = (data: ICreateLocation) => {
  return {
    ...data,
    addressId: data.addressId.id,
    payerAddressId: data.payerAddressId.id,
    recipientAddressId: data.recipientAddressId.id,
  };
};

const LocationsConnection = {
  getLocations: (data: IGetLocations) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/list`,
      data,
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  createLocation: (data: ICreateLocation) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations`,
      getSaveAddressData(data),
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  addLocationCustomer: (
    locationId: string,
    data: IAddLocationCustomer
  ): AxiosPromise<any> => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/${locationId}/customers`,
      data
    );
  },
  getLocationCustomersForm: (locationId: string): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/${locationId}/customers/form`
    );
  },
  // getLocationCustomers: (locationId: string, data: IGetLocationCustomers): AxiosPromise<IBodyResponse<ILocationCustomer>> => {
  //   return AxiosHelper.post(`${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/${locationId}/customers/list`, data);
  // },
  removeLocationCustomerItem: (itemId: string): AxiosPromise<any> => {
    return AxiosHelper.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/customers/${itemId}`
    );
  },
  removeLocationCustomerRegionItem: (itemId: string): AxiosPromise<any> => {
    return AxiosHelper.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/customers/regions/${itemId}`
    );
  },
  editLocation: (data: ICreateLocation, id: string) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/${id}`,
      getSaveAddressData(data),
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  getFormCreateLocation: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/form`,
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  getFormEditLocation: (id: string) => {
    return AxiosManager.send(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/${id}/form`
    );
  },
  getFormFilterLocations: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations/filter/form`,
      {
        headers: { ...getAuthHeader() },
      }
    );
  },

  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/locations`,
      {
        headers: { ...getAuthHeader() },
        data: { locations: data },
      }
    );
  },
};

export default LocationsConnection;
