import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import { Col, Row, Input } from "rsuite";
import { ISelect } from "utils/models";
import { IContractEquipmentModel } from "../../../utils/models/contract/contractEquipmentModels";
import { getContractEquipmentState } from "../../../utils/states/contract/contractEquipmentStates";
import Connection from "../../../utils/connections/contract/contractEquipmentConnection";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

interface IContractEquipmentEdit {}

interface IFormContractEquipment {
  notices: ISelect;
  settlement: ISelect;
  statuses: ISelect;
  types: ISelect;
}

const ContractEquipmentEdit: FunctionComponent<IContractEquipmentEdit> = () => {
  const [data, setData] = useState<IContractEquipmentModel>(
    getContractEquipmentState
  );
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormContractEquipment | null>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const type: "add" | "edit" = id === undefined ? "add" : "edit";
  if (type === "edit") {
    useEffect(() => {
      Connection.getFormEditContractEquipment(id).then((response) => {
        setData({
          ...response.data.model,
        });
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      Connection.getFormAddContractEquipment().then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (type === "add") {
      handleToast(Connection.createContractEquipment(data), setErrors).then(
        () => history.push("/contracts/equipments")
      );
      return true;
    }
    handleToast(Connection.updateContractEquipment(id, data), setErrors).then(
      () => history.push("/contracts/equipments")
    );
  };

  if (!form) return <Spinner />;

  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Row>
          <Col xs={24}>
            <div>
              Nazwa:
              <Input
                type={"text"}
                placeholder={"wpisz..."}
                name={"number"}
                value={data.name}
                onChange={(value) =>
                  setData({ ...data, name: value?.toString() ?? "" })
                }
              />
              {errors?.name && <div style={errorStyle}>{errors?.name}</div>}
            </div>
          </Col>
        </Row>
        <ActionsContainer>
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default ContractEquipmentEdit;
