import React, { FunctionComponent, useEffect, useState } from "react";
import ListingConnection from "../../../../../utils/connections/listing";
import { Table, Tooltip, Whisper } from "rsuite";

const { Column, HeaderCell, Cell } = Table;
import { deepClone } from "../../../../../utils/helpers";
import styles from "./styles.module.scss";
import Input, { InputTypes } from "global/atoms/Input";
import { AxiosResponse } from "axios";
import { SetState } from "../../../../../utils/models";
import Spinner from "../../../../../global/atoms/Spinner/Spinner";
import Row from "rsuite/Row";
import Col from "rsuite/Col";

type idNameType = { id: string; name: string };

interface IViewData {
  entities: Array<IItem>;
  networks: Array<idNameType>;
}

export interface IItemLevelListing {
  networkId: string;
  networkName: string;
  itemId: string;
  itemName: string;
  activityId: string;
  level: number;
  levelToOrder: number;
}

interface ITableItemLevels {
  activityId: string;
  state: IItemLevelListing[];
  setState: SetState<IItemLevelListing[]>;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface IItem {
  id: string;
  name: string;
  level: number;
  levelToOrder: number;
}

const TableItemLevels: FunctionComponent<ITableItemLevels> = (props) => {
  const [viewData, setViewData] = useState<IViewData | null>(null);

  useEffect(() => {
    Promise.all([
      ListingConnection.getEditItemLevelForm(props.activityId),
      ListingConnection.getNetworkItemLevelList(props.activityId),
    ]).then((responses) => {
      setViewData(responses[0].data);
      fillMissingListingWithDefaultValues(responses);
    });
  }, []);

  const addListingData = (
    listingToUpdate: IItemLevelListing[],
    network: idNameType,
    item: IItem
  ): IItemLevelListing[] => {
    listingToUpdate.push({
      networkId: network.id,
      networkName: network.name,
      itemId: item.id,
      itemName: item.name,
      activityId: props.activityId,
      level: item.level !== undefined ? item.level : 0,
      levelToOrder: item.levelToOrder !== undefined ? item.levelToOrder : 0,
    });

    return listingToUpdate;
  };

  const fillMissingListingWithDefaultValues = (responses: AxiosResponse[]) => {
    const listingClone = deepClone(responses[1].data);
    responses[0].data.networks.map((network: idNameType) => {
      responses[0].data.entities.map((item: idNameType) => {
        const listingItem = listingClone.find((data: any) => {
          return data.networkId === network.id && data.itemId === item.id;
        });
        if (listingItem === undefined) {
          const itemClone: IItem = {
            ...item,
            level: 1,
            levelToOrder: 1,
          };
          addListingData(listingClone, network, itemClone);
        }
      });
    });

    props.setState(listingClone);
  };

  const handleChangeLevel = (
    network: idNameType,
    item: IItem,
    type: "level" | "order",
    value
  ): void => {
    const listingItemToUpdate = props.state.filter(
      (data) => !(data.networkId === network.id && data.itemId === item.id)
    );
    if (listingItemToUpdate === undefined) {
      return;
    }
    const listingItem = props.state.find(
      (data) => data.networkId === network.id && data.itemId === item.id
    );
    if (listingItem === undefined) {
      return;
    }

    if (type === "level") {
      listingItem.level = value < 1 ? 1 : value;
    } else {
      listingItem.levelToOrder = value < 1 ? 1 : value;
    }

    const itemNew: IItem = {
      id: listingItem.itemId,
      name: listingItem.itemName,
      level: listingItem.level,
      levelToOrder: listingItem.levelToOrder,
    };
    addListingData(listingItemToUpdate, network, itemNew);
    props.setState(listingItemToUpdate);
  };

  const getListingLevelValue = (network: idNameType, item: IItem): number => {
    const listingItem = props.state.find(
      (data) => data.networkId === network.id && data.itemId === item.id
    );
    if (listingItem === undefined) {
      return 0;
    }

    return listingItem.level;
  };

  const getListingLevelToOrderValue = (
    network: idNameType,
    item: IItem
  ): number => {
    const listingItem = props.state.find(
      (data) => data.networkId === network.id && data.itemId === item.id
    );
    if (listingItem === undefined) {
      return 0;
    }

    return listingItem.levelToOrder;
  };

  if (viewData === null) return <Spinner />;
  return (
    <>
      <Table
        data={viewData?.networks ?? []}
        affixHeader
        headerHeight={290}
        height={props.parentRef?.current?.offsetHeight ?? 0}
        rowHeight={100}>
        <Column width={300} fixed={"left"} resizable>
          <HeaderCell>Sieć</HeaderCell>
          <Cell dataKey={"name"} />
        </Column>

        {viewData &&
          viewData.entities.map((item) => {
            return (
              <Column width={300} key={item.id}>
                <HeaderCell align={"right"}>
                  <div style={{ height: "300px", padding: "12px 0" }}>
                    <div className={styles.entityHeader}>
                      <Whisper
                        trigger={"hover"}
                        speaker={<Tooltip>{item.name}</Tooltip>}>
                        <span className={styles.verticalText}>{item.name}</span>
                      </Whisper>
                    </div>
                  </div>
                </HeaderCell>
                <Cell>
                  {(rowData: idNameType) => {
                    return (
                      <Row>
                        <Col xs={12}>
                          Min. stan&nbsp;
                          <Input
                            style={{ width: "100%" }}
                            type={InputTypes.number}
                            onChange={(_, value) =>
                              handleChangeLevel(rowData, item, "level", value)
                            }
                            placeholder={""}
                            name={"level"}
                            value={getListingLevelValue(rowData, item)}
                          />
                        </Col>
                        <Col xs={12}>
                          Min. zamówienie&nbsp;
                          <Input
                            style={{ width: "100%" }}
                            type={InputTypes.number}
                            onChange={(_, value) =>
                              handleChangeLevel(rowData, item, "order", value)
                            }
                            placeholder={""}
                            name={"levelToOrder"}
                            value={getListingLevelToOrderValue(rowData, item)}
                          />
                        </Col>
                      </Row>
                    );
                  }}
                </Cell>
              </Column>
            );
          })}
      </Table>
    </>
  );
};

export default TableItemLevels;
