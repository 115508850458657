import DoubleSidePicker from "global/atoms/dooubleSidePicker/DoubleSidePicker";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import _ from "lodash";
import React, { FunctionComponent } from "react";
import { Form, Panel, SelectPicker, TagPicker } from "rsuite";
import {
  IAlertEntityRecipients,
  IAlertEntityStore,
  IUserMultiSelectRawEntity,
  SetState,
} from "utils/models";

interface IAlertFormUsers {
  disabled?: boolean;
  stateUsers: Array<IAlertEntityRecipients>;
  setAlertState: SetState<IAlertEntityStore>;
  formUsers: Array<IUserMultiSelectRawEntity> | null;
  error?: string;
}

const AlertFormUsers: FunctionComponent<IAlertFormUsers> = (props) => {
  const getUserGroups = (): Array<{ value: string; label: string }> => {
    // project roles
    if (!props.formUsers) return [];
    const res: Array<{ value: string; label: string }> = [];
    props.formUsers.forEach((u) => {
      if (res.find((ru) => ru.value === u.roleId) === undefined) {
        res.push({ value: u.roleId, label: u.roleName });
      }
    });
    return res;
  };

  const handleUserGroupsChange = (selected: Array<string> | null) => {
    const tmp: IAlertEntityRecipients[] = [];
    if (selected) {
      selected.forEach((roleId) => {
        tmp.push({ roleId: roleId, userRoleIds: null });
      });
    }
    props.setAlertState((s) => ({
      ...s,
      recipients: tmp,
    }));
  };

  const findRoleName = (roleId: string): string => {
    if (!props.formUsers) return roleId;
    switch (roleId) {
      case "64988d5b-67be-4da4-98dc-d5f74caf3d92":
        return "Administrator";
      case "66ca8f2d-85f2-4f67-983f-f922c726231b":
        return "Area manager";
      case "9aa23101-555a-4342-8d6c-6f857fa93f1f":
        return "Superadministrator";
      case "a08adab9-9fc5-4d71-9761-da91a3c630aa":
        return "Centrala";
      case "c8a71285-621e-4afc-bef8-faaa6ce95e7c":
        return "Przedstawiciel handlowy";
    }
    return (
      props.formUsers.find((item) => item.roleId === roleId)?.roleName ?? roleId
    );
  };

  // todo: typy
  const getUsersFromRole = (
    roleId: string
  ): { assigned: []; unassigned: [] } => {
    if (!props.formUsers) return { assigned: [], unassigned: [] };

    const mapUsers = (u) => {
      return { ...u, name: u.userName, id: u.userId };
    };

    const assignedUserIds: Array<string> =
      props.stateUsers.find((r) => r.roleId === roleId)?.userRoleIds ?? [];
    const roleUsers = props.formUsers.filter((u) => u.roleId === roleId);

    return {
      // @ts-ignore
      assigned:
        roleUsers
          .filter((u) => assignedUserIds.includes(u?.userRoleId ?? ""))
          .map(mapUsers) ?? [],
      // @ts-ignore
      unassigned:
        roleUsers
          .filter((u) => !assignedUserIds.includes(u?.userRoleId ?? ""))
          .map(mapUsers) ?? [],
    };
  };

  const groupUserPickerOnChange = (
    assignedUserIds: string[],
    roleId: string
  ) => {
    const recipients: Array<IAlertEntityRecipients> = _.cloneDeep(
      props.stateUsers
    );

    const i = recipients.findIndex((r) => r.roleId === roleId);
    const roleUsers = props.formUsers?.filter((u) => u.roleId === roleId) ?? [];
    // @ts-ignore
    const userRoleIds: Array<string> =
      roleUsers
        ?.filter((ru) => assignedUserIds.includes(ru.userId))
        ?.map((ru) => ru.userRoleId) ?? [];

    if (i > -1) {
      recipients[i].userRoleIds = userRoleIds;
    } else {
      recipients.push({
        roleId: roleId,
        userRoleIds: userRoleIds,
      });
    }
    props.setAlertState((s) => ({
      ...s,
      recipients: recipients,
    }));
  };

  return (
    <Panel header={<>{"Grupy użytkowników*"}</>} shaded>
      <Form>
        <Form.Group>
          <Form.Control
            errorMessage={props.error}
            errorPlacement={"bottomEnd"}
            name={"recipients"}
            accepter={TagPicker}
            data={getUserGroups()}
            block
            disabled={props.disabled}
            placeholder={"Wybierz grupy użytkowników"}
            cleanable={true}
            onChange={handleUserGroupsChange}
            value={props.stateUsers.map((r) => r.roleId)}
          />
        </Form.Group>
      </Form>
      <SeparatorEmpty size={2} />

      {props.stateUsers.map((r) => r.roleId).length > 0 &&
        props.stateUsers.map((recipients, index) => {
          const roleId = recipients.roleId;
          const tmp = getUsersFromRole(roleId);

          return (
            <div key={`picker-${roleId}`} style={{ marginLeft: "25px" }}>
              <SeparatorEmpty size={1} />
              <strong>{findRoleName(roleId)}</strong>&nbsp;-&nbsp;
              <SelectPicker
                size={"xs"}
                data={[
                  { value: "group", label: "Cała grupa" },
                  { value: "users", label: "Wybrani użytkownicy" },
                ]}
                cleanable={false}
                searchable={false}
                disabled={props.disabled}
                value={recipients.userRoleIds === null ? "group" : "users"}
                onChange={(selected) => {
                  const entity: IAlertEntityRecipients = {
                    roleId: roleId,
                    userRoleIds: selected === "users" ? [] : null,
                  };

                  const recipients: Array<IAlertEntityRecipients> = _.cloneDeep(
                    props.stateUsers
                  );
                  recipients[index] = entity;

                  props.setAlertState((s) => ({
                    ...s,
                    recipients: recipients,
                  }));
                }}
              />
              {recipients.userRoleIds !== null && (
                <>
                  <SeparatorEmpty size={0.5} />
                  <DoubleSidePicker
                    form={props.formUsers}
                    // style={{transform: 'scale(0.8)'}}
                    filtersHidden={true}
                    itemType={"userProject"}
                    disabled={props.disabled}
                    emptyMessageAssigned={"Brak przypisanych użytkowników"}
                    emptyMessageNotAssigned={"Brak użytkowników w systemie"}
                    assigned={tmp["assigned"]}
                    unassigned={tmp["unassigned"]}
                    onChange={(assignedIds) =>
                      groupUserPickerOnChange(assignedIds, roleId)
                    }
                  />
                </>
              )}
            </div>
          );
        })}
    </Panel>
  );
};

export default AlertFormUsers;
