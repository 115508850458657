import React, { FunctionComponent, useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { handleToast, handleToastRedux } from "utils/helpers";
import ActivitiesConnection from "utils/connections/activities";
import WhiteCard from "global/atoms/WhiteCard";
import ActivityAdd from "./ActivityAdd";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import {
  IActivityListModel,
  IFormActivityState,
  IFormTaskActivityEdit,
} from "../../../../utils/models";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { Tooltip, Whisper } from "rsuite";
import ActivityItem from "./ActivityItem";
import { SET_BREADCRUMB, SET_HEADER } from "../../../../redux/actions";
import SortableList from "global/atoms/dnd/SortableList";
import { IRoot } from "redux/models";

interface ITaskActivities {}

export interface ITaskActivityEditErrors {
  feature_proposal?: string;
}

const TaskActivities: FunctionComponent<ITaskActivities> = () => {
  const [data, setData] = useState<IActivityListModel[] | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);
  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const activityRedux = useSelector((state: IRoot) => state.activity);

  const load = () => {
    setData(null);
    ActivitiesConnection.getFormActivitiesEdit(id, taskId).then((data) => {
      setData(data.data.taskActivities.data);
      data.data?.header &&
        dispatch({ type: SET_HEADER, payload: data.data.header });
      dispatch({
        type: SET_BREADCRUMB,
        payload: { taskName: data.data.task.name },
      });
    });
  };
  useEffect(() => {
    dispatch({ type: SET_BREADCRUMB, payload: { taskName: "..." } });
    load();
  }, []);

  const handleSubmitSorted = (newData: IActivityListModel[]) => {
    const postData = newData.map((item) => ({
      activityId: item.activityId,
      active: item.active,
      locked: item.locked,
    }));
    handleToast(
      ActivitiesConnection.updateActivityOrder(id, taskId, postData)
    ).then(() => {
      history.push(
        generatePath("/projects/:id/tasks/:taskId", {
          id: id,
          taskId: taskId,
        })
      );
    });
  };

  const handleDeleteActivity = (activityId: string) => {
    if (data) {
      ActivitiesConnection.deleteActivity(id, taskId, activityId).then(() => {
        setData(
          data.filter((a: IActivityListModel) => a.activityId !== activityId)
        );
      });
    }
  };

  const handleLockItem = (event: any) => {
    if (data) {
      const newData = data.map((item: any) => {
        if (item.activityId === event.currentTarget.dataset.id)
          return { ...item, locked: !item.locked };
        else return item;
      });
      setData(newData);
      handleSubmitSorted(newData);
    }
  };

  const onSortEnd = (sortedData: Array<IActivityListModel>) => {
    setData(sortedData);
    handleSubmitSorted(sortedData);
  };

  const handleSubmitActivity = (
    stateItem: IFormActivityState,
    formItem: IFormTaskActivityEdit,
    submitCallback: () => void
  ) => {
    const dataUpdate = {
      name: stateItem.name,
      description: stateItem.description,
      activityTypeId: stateItem.activityTypeId,
      activityTypeType: stateItem.activityTypeType,
      activityGroupId: stateItem.activityGroupId,
      additionalFeatureId: stateItem.additionalFeatureId,
      scheduleDate: stateItem.scheduleDate,
      endDate: stateItem.endDate,
      questions: {
        all: false,
        assignedCount: formItem.assignedQuestions?.data?.length ?? 0,
        assigned: formItem.assignedQuestions.data.map((item: any) => ({
          ...item,
          rate: item.rate ? parseInt(item.rate) : null,
          isRequired: !!item.isRequired,
          minValue: item.minValue ? parseFloat(item.minValue) : 0,
          maxValue: item.maxValue ? parseFloat(item.maxValue) : 0,
        })),
        unassigned:
          formItem.unassignedQuestions?.data?.map((item: any) => ({
            id: item.id,
          })) ?? [],
      },
      locations: {
        all: formItem.activityProperties.options.locationAll,
        assignedCount: formItem.assignedLocations?.data?.length ?? 0,
        assigned:
          formItem.assignedLocations?.data?.map((locationId) => ({
            id: locationId,
          })) ?? [],
        unassigned: [],
      },
      items: {
        all: formItem.activityProperties.options.itemAll,
        assignedCount: formItem.activityProperties.options.itemAll
          ? (formItem.assignedItems?.data?.length ?? 0) +
            (formItem.unassignedItems?.data?.length ?? 0)
          : formItem.assignedItems?.data?.length ?? 0,
        assigned: formItem.activityProperties.options.itemAll
          ? [
              ...(formItem.assignedItems?.data?.map((item: any) => ({
                id: item.id,
              })) ?? []),
              ...(formItem.unassignedItems?.data?.map((item: any) => ({
                id: item.id,
              })) ?? []),
            ]
          : formItem.assignedItems?.data?.map((item: any) => ({
              id: item.id,
            })) ?? [],
        unassigned: formItem.activityProperties.options.itemAll
          ? []
          : formItem.unassignedItems?.data?.map((item: any) => ({
              id: item.id,
            })) ?? [],
      },
    };

    const promiseSubmit = ActivitiesConnection.updateActivity(
      id,
      taskId,
      stateItem.activityId,
      dataUpdate
    );
    handleToastRedux(promiseSubmit, dispatch, "Popraw błędy w formularzu").then(
      () => {
        if (data) {
          setData(
            data.map((d) => {
              if (d.activityId === stateItem.activityId) {
                return {
                  ...d,
                  name: stateItem.name,
                  activityId: stateItem.activityId,
                  description: stateItem.description,
                  activityGroupId: stateItem.activityGroupId,
                  additionalFeatureId: stateItem.additionalFeatureId,
                  itemsCount: (
                    formItem.assignedItems?.data.length ?? 0
                  ).toString(),
                  locationsCount: (
                    formItem.assignedLocations?.data.length ?? 0
                  ).toString(),
                };
              }
              return d;
            })
          );
          submitCallback();
          load();
        }
      }
    );
  };

  return (
    <>
      <HeaderButtons>
        <ButtonOutlined onClick={() => setAddNew(true)}>
          Dodaj aktywność
        </ButtonOutlined>
      </HeaderButtons>
      <WhiteCard padding={true} style={{ marginTop: "16px" }}>
        {addNew && (
          <ActivityAdd
            projectId={id}
            taskId={taskId}
            addedCallback={() => load()}
            handleDisableAddNew={() => setAddNew(false)}
          />
        )}
        <section className={styles.containerHeader}>
          <div style={{ width: "120px" }} />
          <span style={{ flex: 5 }}>NAZWA AKTYWNOŚCI</span>
          <span style={{ flex: 3, textAlign: "center" }}>Grupa</span>
          <span style={{ flex: 1, textAlign: "center" }}>TYP</span>
          <span style={{ flex: 1, textAlign: "center" }}>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  Liczba lokalizacji w których występuje dana aktywność
                </Tooltip>
              }>
              <span>LOK.</span>
            </Whisper>
          </span>
          <span style={{ flex: 1, textAlign: "center" }}>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>Liczba produktów przypisanych w aktywności</Tooltip>
              }>
              <span>PROD.</span>
            </Whisper>
          </span>
          <div style={{ width: "80px" }} />
        </section>

        {!data ? (
          <SpinnerSmall />
        ) : (
          <SortableList
            onSortEnd={onSortEnd}
            idKeyName={"activityId"}
            data={data}
            disabled={activityRedux.activeEditId !== null}
            dragHandleActivator={true}
            mapFunction={(item: IActivityListModel) => (
              <ActivityItem
                item={item}
                handleRemove={handleDeleteActivity}
                handleSubmit={handleSubmitActivity}
                onTriggerLoad={() => load()}
                handleLock={handleLockItem}
              />
            )}
          />
        )}
      </WhiteCard>
    </>
  );
};

export default TaskActivities;
