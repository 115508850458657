import React, { FunctionComponent, useState } from "react";
import WhiteCard from "global/atoms/WhiteCard";
import Pagination from "global/pagination/Pagination";
import ScTable from "global/table/ScTable";
import { Dropdown, Table } from "rsuite";
import { useParams } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "global/filters/UniversalFilters";
import NavOptionsWrapper from "utils/NavOptionsWrapper";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "utils/toggleToDeleteHelper";
import connection from "utils/connections/contract/contractProjectConnection";
import { IGetContractProjectDocument } from "../../../utils/models/contract/contractProjectModels";
import { getContractProjectDocumentState } from "../../../utils/states/contract/contractProjectStates";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { confirmModalCallback } from "../../../redux/store";
import { handleToast } from "../../../utils/helpers";

interface IContractProjectDocumentAssignedList {
  contractProjectId?: string;
}

const ContractProjectDocumentAssignedList: FunctionComponent<
  IContractProjectDocumentAssignedList
> = (props) => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [filters, setFilters] = useState<IGetContractProjectDocument>(
    getContractProjectDocumentState
  );
  const [loading, setLoading] = useState(true);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [chosenToAction, setChosenToAction] = useState<Array<string>>([]);

  const { id } = useParams<{ id: string }>();

  const handleRemoveSelected = () => {
    if (chosenToAction) {
      confirmModalCallback(
        `Czy usunąć zaznaczone przypisania? (${chosenToAction.length ?? 0})`,
        () => {
          handleToast(
            connection.removeContractProjectDocument(
              props.contractProjectId ?? id,
              chosenToAction.map((elId) => ({ id: elId }))
            )
          ).then(() => {
            setTriggerLoad(Date.now());
          });
        }
      );
    }
  };
  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item
            onClick={handleRemoveSelected}
            disabled={chosenToAction.length == 0}
            icon={<FileUploadIcon />}>
            Usuń przypisanie zaznaczonych szablony dokumntów (
            {chosenToAction.length})
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>
        {data === null ? (
          <></>
        ) : (
          <ScTable data={data?.data ?? []} loading={loading}>
            <Table.Column width={40} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenToAction}
                  setState={setChosenToAction}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToAction}
                    setState={setChosenToAction}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={200} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Szablon
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="type">
                {(rowData) => {
                  return <span>{rowData.documentTemplateName}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align="left" fixed="right">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj dokumentu
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="category">
                {(rowData) => {
                  return <span>{rowData.documentTemplateCategory.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align="left" fixed="right">
              <Table.HeaderCell depth={0} width={0}>
                Rodzaj danych
              </Table.HeaderCell>
              <Table.Cell width={0} depth={0} dataKey="status">
                {(rowData) => {
                  return <span>{rowData.documentTemplateType.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
          </ScTable>
        )}
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContractProjectAssignedDocuments.bind(null, id, {
          ...getContractProjectDocumentState,
          contractProjectId: props.contractProjectId ?? id,
        })}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getContractProjectDocumentState}
        setResultData={setData}
        formGet={connection.getContractProjectDocumentListForm}
        filterStorageKey={"ContractProjectDocumentList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Szablon",
          },
          {
            type: FILTER_SELECT,
            stateKey: "category",
            label: "Rodzaj dokumentu",
            formKey: "categories",
          },
          {
            type: FILTER_SELECT,
            stateKey: "type",
            label: "Rodzaj danych",
            formKey: "types",
          },
        ]}
      />
    </>
  );
};

export default ContractProjectDocumentAssignedList;
