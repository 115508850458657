import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { handleToast } from "../../../../utils/helpers";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import HintsConnection from "../../../../utils/connections/hints";
import Form from "global/form/Form";
import Input from "../../../../global/atoms/Input";
import Textarea from "../../../../global/atoms/Textarea";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";

interface IHintsAdd {}

const HintsAdd: FunctionComponent<IHintsAdd> = () => {
  const [data, setData] = useState<any>({ locale: "", field: "", text: "" });
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(HintsConnection.createHint(data), setErrors).then(() =>
      history.push("/superadmin/hints")
    );
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={"text"}
            placeholder={"Język (kod)"}
            name={"locale"}
            value={data.locale}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={"text"}
            placeholder={"Pole"}
            name={"field"}
            value={data.field}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={"Treść"}
            name={"text"}
            value={data.text}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default HintsAdd;
