import AddBrand from "views/products/components/addBrand/AddBrand";
import BrandsList from "views/products/components/brandList/BrandsList";
import EditBrand from "views/products/components/editBrand/EditBrand";
import ProductsList from "views/products/components/productsList/ProductsList";
import CategoriesList from "../../views/products/components/categoriesList/CategoriesList";
import AddCategory from "../../views/products/components/addCategory/AddCategory";
import EditCategory from "../../views/products/components/editCategory/EditCategory";
import { IRouteEntity } from "../../utils/models";
import ProductForm from "../../views/products/components/productForm/ProductForm";

const productRoutes: IRouteEntity[] = [
  {
    path: "/products",
    breadcrumb: "Produkty i materiały",
    redirectTo: "/products/product",
  },
  {
    path: "/products/product",
    breadcrumb: "Produkty",
    Component: ProductsList,
  },
  {
    path: "/products/product/add",
    breadcrumb: "Dodaj produkt",
    Component: ProductForm,
  },
  { path: "/products/product/:id", breadcrumb: null },
  {
    path: "/products/product/:id/edit",
    breadcrumb: "Edytuj produkt",
    Component: ProductForm,
  },
  { path: "/products/brand", breadcrumb: "Marki", Component: BrandsList },
  {
    path: "/products/brand/add",
    breadcrumb: "Dodaj markę",
    Component: AddBrand,
  },
  { path: "/products/brand/:id", breadcrumb: null },
  {
    path: "/products/brand/:id/edit",
    breadcrumb: "Edytuj markę",
    Component: EditBrand,
  },
  {
    path: "/products/category",
    breadcrumb: "Kategorie",
    Component: CategoriesList,
  },
  {
    path: "/products/category/add",
    breadcrumb: "Dodaj kategorie",
    Component: AddCategory,
  },
  { path: "/products/category/:id", breadcrumb: null },
  {
    path: "/products/category/:id/edit",
    breadcrumb: "Edytuj kategorie",
    Component: EditCategory,
  },
];

export default productRoutes;
