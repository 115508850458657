import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateRole } from "utils/models";
import { createRoleState } from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import RolesConnection from "utils/connections/roles";
import { handleToast } from "utils/helpers";
import { useDispatch } from "react-redux";
import RoleUpdate from "global/form/RoleUpdate";
import Spinner from "global/atoms/Spinner/Spinner";

interface IEditRole {}

const EditRole: FunctionComponent<IEditRole> = () => {
  const [data, setData] = useState<ICreateRole>(createRoleState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | "loading">("loading");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    RolesConnection.getFormEditRole(id).then((data) => {
      setLoading(false);
      setData({
        roleTypeId: data.data.model.roleType.id,
        name: data.data.model.roleName,
        isEnableWorkTimeHistory: data.data.model.isEnableWorkTimeHistory,
        hierarchyLevel: data.data.model.hierarchyLevel,
      });
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(RolesConnection.editRole(id, data), setErrors).then(() =>
      history.push("/admin/roles")
    );
  };

  if (loading) return <Spinner />;
  if (form === "loading") return null;

  return (
    <>
      <RoleUpdate
        handleSubmit={handleSubmit}
        data={data}
        form={form}
        setData={setData}
        errors={errors}
      />
    </>
  );
};

export default EditRole;
