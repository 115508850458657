import React, { FunctionComponent, useEffect, useState } from "react";
import { IAddTask } from "utils/models";
import TasksConnection from "utils/connections/tasks";
import { deepClone, handleToast } from "utils/helpers";
import { createTaskState } from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "global/atoms/Spinner/Spinner";
import FormTask from "./FormTask";

interface IAddTaskComponent {}

const AddTask: FunctionComponent<IAddTaskComponent> = () => {
  const [data, setData] = useState<IAddTask>(deepClone(createTaskState));
  const [form, setForm] = useState<any | "loading">("loading");
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<any>(null);
  const history = useHistory();

  const getDefaultTaskStatus = (form: any) => {
    return form.taskStatuses.options.find(
      (taskStatus: any) => taskStatus.isDefault
    ).id;
  };

  useEffect(() => {
    TasksConnection.getFormAddTask(id).then((response) => {
      const form = response.data;
      setForm(form);
      setData({ ...data, taskStatusId: getDefaultTaskStatus(form) });
    });
  }, []);

  const handleSubmit = () => {
    const dataClone = { ...data };
    const expirationDays: any = dataClone.expirationDays;
    dataClone.expirationDays = parseInt(expirationDays);
    handleToast(
      TasksConnection.createTaskForProject(id, { ...dataClone }),
      setErrors
    ).then(() => history.push(`/projects/${id}/tasks`));
  };
  useEffect(() => {
    if (data.isExpirationEndOfWeek) {
      setData({ ...data, expirationDays: 0 });
    }
  }, [data.isExpirationEndOfWeek]);

  if (form === "loading") return <Spinner />;
  return (
    <>
      <FormTask
        data={data}
        setData={setData}
        form={form}
        errors={errors}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AddTask;
