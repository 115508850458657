import AxiosHelper from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosPromise } from "axios";

const baseUrl = (): string => {
  //const projectId = getProjectIdFromUrl() ?? '';
  return `${getBaseApiUrl()}/organizations/${getOrganizationId()}/regions`;
};
export interface IDeleteRegions {
  regions: Array<{ id: string }>;
}
const RegionConnection = {
  list: (data: any): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl()}/list`, data);
  },
  getFormFilterRegions: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl()}/filter/form`);
  },
  getForm: (regionId: string): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl()}/${regionId}/form`);
  },
  addForm: (data: any): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl()}`, data);
  },
  updateForm: (id: string, data: any): AxiosPromise => {
    return AxiosHelper.put(`${baseUrl()}/${id}`, data);
  },
  removeRegions: (data: IDeleteRegions): AxiosPromise => {
    return AxiosHelper.delete(`${baseUrl()}`, {
      headers: getAuthHeader(),
      data,
    });
  },
};

export default RegionConnection;
