import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Button from "rsuite/Button";
import ProjectLocationUsersConnection from "../../../../utils/connections/projectRolesLinkedUsers";
import SelectPicker from "rsuite/SelectPicker";
import Row from "rsuite/Row";
import Col from "rsuite/Col";
import MoveUpIcon from "@rsuite/icons/MoveUp";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { InputGroup, Table } from "rsuite";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import Checkbox from "rsuite/Checkbox";
import { deepClone, handleToast } from "../../../../utils/helpers";
import Input from "rsuite/Input";
import SearchIcon from "@rsuite/icons/Search";

interface IUserAssigning {}

interface IRole {
  id: string;
  name: string;
  type: string;
}

interface IUser {
  id: string;
  username: string;
  fullName: string;
  type: string;
  roleId: string;
  roleName: string;
  userRoleId: string;
  assigned: boolean;
  fullNameWithLogin: string;
}

interface IForm {
  projectRoles: { data: Array<IRole> };
  projectUsers: { data: Array<IUser> };
}

const ProjectUserLinked: FunctionComponent<IUserAssigning> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [form, setForm] = useState<IForm>();
  const [roleId, setRoleId] = useState("");
  const [linkedRoleId, setLinkedRoleId] = useState("");
  const [userRoleId, setUserRoleId] = useState("");
  const [userId, setUserId] = useState("");
  const [data, setData] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    ProjectLocationUsersConnection.getProjectUsersRolesLinkedFilterForm(
      id
    ).then((response) => {
      setForm(response.data);
    });
  }, []);

  useEffect(() => {
    if (userRoleId && linkedRoleId) {
      ProjectLocationUsersConnection.getProjectUsersRolesLinkedListForm(id, {
        userRoleId: userRoleId,
        linkedRoleId: linkedRoleId,
      }).then((response) => {
        const userChosen: Array<IUser> = deepClone(data);
        userChosen.forEach((user, index, users) => {
          users[index].assigned = !!response.data.find(
            (rd) => rd.linkedUserRoleId == user.userRoleId
          );
        });
        setData(userChosen);
        setAssignedUsers(response.data);
      });
    }
  }, [userRoleId, linkedRoleId]);

  const selectUsers = (filterValue) => {
    const _data = form.projectUsers.data
      .filter((user) => user.roleId == linkedRoleId)
      .filter((user) =>
        user.fullNameWithLogin.toLowerCase().includes(filterValue.toLowerCase())
      )
      .map((user) => {
        return {
          ...user,
          assigned: !!assignedUsers.find(
            (rd) => rd.linkedUserRoleId == user.userRoleId
          ),
        };
      });

    setData(_data);
  };
  const handleSubmit = () => {
    const dataToSend = data.map((user) => ({
      assigned: user.assigned,
      userRoleId: userRoleId,
      linkedUserRoleId: user.userRoleId,
    }));
    handleToast(
      ProjectLocationUsersConnection.putProjectUsersRolesLinked(id, {
        linkedUsers: dataToSend,
      })
    );
  };
  const handleChangeLinkedRole = (value) => {
    setLinkedRoleId(value);
    setData(
      form.projectUsers.data
        .filter((user) => user.roleId == value)
        .map((user) => ({ ...user, assign: false }))
    );
  };
  const handleChangeUser = (userId) => {
    setUserId(userId);
    const user = form.projectUsers.data.find(
      (user) => user.id == userId && user.roleId == roleId
    );
    if (user) setUserRoleId(user.userRoleId);
  };
  const handleAssignedChange = (userRoleId, checked) => {
    const user = form.projectUsers.data.find(
      (user) => user.userRoleId == userRoleId
    );
    console.log(user);
    const userChosen: Array<IUser> = deepClone(data);
    userChosen.forEach((user, index, users) => {
      if (user.userRoleId == userRoleId) users[index].assigned = checked;
    });
    setData(userChosen);
  };
  console.log(data);
  const handleAssignedChangeAll = (checked) => {
    setData((data) =>
      data.map((user) => ({
        ...user,
        assigned: checked,
      }))
    );
  };
  return (
    <>
      <HeaderButtons>
        <Button
          startIcon={<MoveUpIcon />}
          appearance={"ghost"}
          onClick={() => history.push(`/projects/${id}/edit/access`)}>
          Powrót: Prawa dostępu
        </Button>
      </HeaderButtons>
      {form && (
        <WhiteCard
          padding={true}
          style={{
            marginTop: "24px",
            paddingTop: "10px",
            position: "relative",
          }}>
          <SeparatorEmpty />
          <Row>
            <Col md={12} xs={24}>
              Grupa w projekcie
              <SelectPicker
                block
                data={form.projectRoles.data}
                valueKey="id"
                labelKey="name"
                value={roleId}
                onChange={(value) => setRoleId(value)}
              />
            </Col>
          </Row>
          <SeparatorEmpty />
          <Row>
            <Col md={12} xs={24}>
              Użytkownik w projekcie
              <SelectPicker
                block
                disabled={!roleId}
                data={form.projectUsers.data.filter(
                  (role) => role.roleId == roleId
                )}
                valueKey="id"
                labelKey="fullNameWithLogin"
                value={userId}
                onChange={handleChangeUser}
              />
            </Col>
          </Row>
          <SeparatorEmpty />
          <Row>
            <Col md={12} xs={24}>
              Grupa powiązana w projekcie
              <SelectPicker
                block
                disabled={!roleId}
                data={form.projectRoles.data.filter((el) => el.id != roleId)}
                valueKey="id"
                labelKey="name"
                value={linkedRoleId}
                onChange={handleChangeLinkedRole}
              />
            </Col>
          </Row>
          <SeparatorLine />
          <Row>
            <Col md={12} xs={24}>
              <InputGroup>
                <Input
                  onChange={selectUsers}
                  placeholder={"wyszukaj użytkownika..."}
                />
                <InputGroup.Addon>
                  <SearchIcon />
                </InputGroup.Addon>
              </InputGroup>
            </Col>
            <Col md={12} xs={24}>
              <div style={{ textAlign: "right" }}>
                <Button
                  appearance={"ghost"}
                  //disabled={!data.filter(user=>user.assigned==true).length}
                  onClick={handleSubmit}>
                  Zapisz ({data.filter((user) => user.assigned == true).length})
                </Button>
              </div>
            </Col>
          </Row>
          <SeparatorEmpty />
          <Table autoHeight data={data ?? []}>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Użytkownik</Table.HeaderCell>
              <Table.Cell dataKey={"fullNameWithLogin"}></Table.Cell>
            </Table.Column>
            <Table.Column width={350}>
              <Table.HeaderCell>Rola</Table.HeaderCell>
              <Table.Cell dataKey={"roleName"}></Table.Cell>
            </Table.Column>
            <Table.Column width={350}>
              <Table.HeaderCell>
                <Checkbox
                  onChange={(value, checked) =>
                    handleAssignedChangeAll(checked)
                  }
                />{" "}
                Przypisanie (
                {data.filter((user) => user.assigned == true).length})
              </Table.HeaderCell>
              <Table.Cell dataKey={"roleId"}>
                {(rowData: IUser) => (
                  <Checkbox
                    checked={!!rowData?.assigned}
                    value={rowData.userRoleId}
                    onChange={(userRoleId, checked) =>
                      handleAssignedChange(userRoleId, checked)
                    }
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
          <SeparatorEmpty />
        </WhiteCard>
      )}
    </>
  );
};

export default ProjectUserLinked;
