// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".daBCTb9w06MJWfyTz4ug{margin-bottom:24px}@media(min-width: 767px){.daBCTb9w06MJWfyTz4ug{margin-bottom:0}}.FraPAVAFupNJcHjq90d6{display:none}@media(min-width: 767px){.FraPAVAFupNJcHjq90d6{display:block}}", "",{"version":3,"sources":["webpack://./src/views/projects/components/orders/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,yBAHF,sBAII,eAAA,CAAA,CAIJ,sBACE,YAAA,CAEA,yBAHF,sBAII,aAAA,CAAA","sourcesContent":[".col {\n  margin-bottom: 24px;\n\n  @media (min-width: 767px) {\n    margin-bottom: 0;\n  }\n}\n\n.separator {\n  display: none;\n\n  @media (min-width: 767px) {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col": "daBCTb9w06MJWfyTz4ug",
	"separator": "FraPAVAFupNJcHjq90d6"
};
export default ___CSS_LOADER_EXPORT___;
