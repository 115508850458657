import React from "react";
import SeparatorEmpty, { ISeparatorProps } from "./SeparatorEmpty";
import BaseComponent from "../BaseComponent";
import styles from "../atoms.module.scss";

interface ISeparatorLine extends ISeparatorProps {
  color?: "light" | "dark";
}

export default class SeparatorLine extends BaseComponent<ISeparatorLine, any> {
  renderView(): JSX.Element {
    let className = styles.separatorLineDark;
    if (this.props.color === undefined || this.props.color === "light") {
      className = styles.separatorLine;
    }
    return <SeparatorEmpty size={this.props.size} className={className} />;
  }
}
