import React, { FunctionComponent, useEffect, useState } from "react";
import { Table, Tooltip, Whisper } from "rsuite";
import CheckBox from "../../../../../global/atoms/checkbox/CheckBox";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import styles from "./styles.module.scss";
import {
  IQuestionItemDependOnQuestion,
  SetState,
} from "../../../../../utils/models";
import ListingConnection from "../../../../../utils/connections/listing";
import Spinner from "../../../../../global/atoms/Spinner/Spinner";
import { AxiosPromise } from "axios";
import { RowDataType } from "rsuite/esm/Table";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";

interface IProductEntity {
  id: string;
  name: string;
}

interface IQuestionEntity {
  id: string;
  name: string;
  dependsOn: IQuestionItemDependOnQuestion;
}

interface IUnifiedEntity {
  id: string;
  name: string;
}

interface INetwork {
  id: string;
  name: string;
}

interface IViewData {
  entities: Array<IProductEntity | IQuestionEntity>;
  networks: Array<INetwork>;
}

interface IProductListingSingle {
  networkId: string;
  networkName: string;
  productId: string;
  productName: string;
  activityId: string;
}

interface IQuestionListingSingle {
  networkId: string;
  networkName: string;
  questionId: string;
  questionName: string;
  activityId: string;
}

export interface IUniversalListing {
  networkId: string;
  networkName: string;
  entityId: string;
  entityName: string;
  activityId: string;
}

interface ITableUniversal {
  activityId: string;
  state: Array<IUniversalListing>;
  setState: SetState<Array<IUniversalListing>>;
  listingType: string;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

const TableUniversal: FunctionComponent<ITableUniversal> = (props) => {
  const [viewData, setViewData] = useState<IViewData | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reverseCheckboxes, setReverseCheckboxes] = useState<boolean>(false);

  useEffect(() => {
    const tmp = [] as Array<AxiosPromise<any>>;
    if (props.listingType === "question") {
      tmp.push(
        ListingConnection.getEditQuestionForm(props.activityId),
        ListingConnection.getNetworkQuestionList(props.activityId)
      );
    } else if (props.listingType === "product") {
      tmp.push(
        ListingConnection.getEditItemForm(props.activityId),
        ListingConnection.getNetworkItemList(props.activityId)
      );
    } else if (props.listingType === "itemHighlight") {
      tmp.push(
        ListingConnection.getEditItemHighlightForm(props.activityId),
        ListingConnection.getNetworkItemHighlightList(props.activityId)
      );
    }

    Promise.all(tmp).then((responses) => {
      setViewData(responses[0].data);

      const uels: Array<IUniversalListing> = [];
      responses[1].data.forEach(
        (e: IProductListingSingle | IQuestionListingSingle) => {
          uels.push(getUniversalisting(e));
        }
      );
      props.setState(uels);
    });
  }, []);

  useEffect(() => {
    if (props.listingType === "question") {
      const questions = viewData?.entities as Array<IQuestionEntity>;
      const additional = [] as Array<IUniversalListing>;
      props.state.forEach((s) => {
        const found = questions?.find((e) => e.dependsOn.id === s.entityId);
        if (
          found &&
          props.state.find(
            (e) =>
              e.entityId === found.id &&
              e.networkId === s.networkId &&
              e.activityId === s.activityId
          ) === undefined
        ) {
          additional.push({
            ...s,
            entityId: found.id,
            entityName: found.name,
          });
        }
      });

      if (additional.length > 0) {
        props.setState([...props.state, ...additional]);
        ToastNotificationPush(
          ToastTypes.warning,
          "Uwaga!",
          "Niektóre pytania zostały automatycznie odznaczone, ponieważ zależą od innych pytań.",
          5000
        );
      }
    }
  }, [props.state]);

  const isRejected = (networkId: string, entityId: string) => {
    const condition =
      props.state.find(
        (data) => data.networkId === networkId && data.entityId === entityId
      ) !== undefined;
    if (reverseCheckboxes) {
      return !condition;
    }
    return condition;
  };

  const addListingData = (
    listingToUpdate: IUniversalListing[],
    network: INetwork,
    entity: IProductEntity | IQuestionEntity
  ): IUniversalListing[] => {
    listingToUpdate.push({
      networkId: network.id,
      networkName: network.name,
      entityId: entity.id,
      entityName: entity.name,
      activityId: props.activityId,
    });

    return listingToUpdate;
  };

  const isCheckedFullNetwork = (networkId: string): boolean => {
    const foundSelectedByNetwork = props.state.filter(
      (data) => data.networkId === networkId
    );
    if (reverseCheckboxes) {
      return foundSelectedByNetwork.length >= (viewData?.entities.length ?? 0);
    }
    return foundSelectedByNetwork.length === 0;
  };

  const isRejectedFullNetwork = (networkId: string): boolean => {
    const foundSelectedByNetwork = props.state.filter(
      (data) => data.networkId === networkId
    );
    const condition =
      foundSelectedByNetwork.length === viewData?.entities.length;
    if (reverseCheckboxes) {
      return !condition;
    }
    return condition;
  };

  const isCheckedFullEntity = (eId: string): boolean => {
    const found = props.state.filter((data) => data.entityId === eId);
    if (reverseCheckboxes) {
      return found.length >= (viewData?.entities.length ?? 0);
    }

    return found.length === 0;
  };

  const isRejectedFullEntity = (eId: string): boolean => {
    const found = props.state.filter((data) => data.entityId === eId);
    const condition = found.length === viewData?.networks.length;
    if (reverseCheckboxes) {
      return !condition;
    }

    return condition;
  };

  const getUniversalisting = (
    l: IProductListingSingle | IQuestionListingSingle
  ): IUniversalListing => {
    return {
      networkId: l.networkId,
      networkName: l.networkName,
      entityId: "productId" in l ? l.productId : l.questionId,
      entityName: "productName" in l ? l.productName : l.questionName,
      activityId: l.activityId,
    };
  };

  const handleSelectAllByEntity = (entity: IUnifiedEntity): void => {
    let condition = isRejectedFullEntity(entity.id);
    if (reverseCheckboxes) {
      condition = !condition;
    }

    if (condition) {
      const newListing = props.state.filter(
        (data) => data.entityId !== entity.id
      );
      props.setState(newListing);
      return;
    }
    const newListing = props.state.filter(
      (data) => data.entityId !== entity.id
    );
    viewData?.networks.map((network) => {
      addListingData(newListing, network, entity);
    });
    props.setState(newListing);
  };

  const handleUpdateListing = (
    network: INetwork,
    obj: IUnifiedEntity
  ): void => {
    let condition = isRejected(network.id, obj.id);
    if (reverseCheckboxes) {
      condition = !condition;
    }

    if (condition) {
      const listingUpdated = props.state.filter((data) => {
        return data.networkId !== network.id || data.entityId !== obj.id;
      });
      props.setState(listingUpdated);
      return;
    }

    const listingUpdated = [...props.state];
    addListingData(listingUpdated, network, obj);
    props.setState(listingUpdated);
    return;
  };

  const handleSelectAllByNetwork = (network: INetwork): void => {
    if (isRejectedFullNetwork(network.id)) {
      const newListing = props.state.filter(
        (data) => data.networkId !== network.id
      );
      props.setState(newListing);
      return;
    }

    const newListing = props.state.filter(
      (data) => data.networkId !== network.id
    );
    viewData?.entities.map((entity) => {
      addListingData(newListing, network, entity);
    });
    props.setState(newListing);
  };

  if (!viewData) return <Spinner />;

  return (
    <>
      <Table
        data={viewData?.networks ?? []}
        affixHeader
        cellBordered={true}
        height={props.parentRef?.current?.offsetHeight ?? 0}
        headerHeight={300}
        rowHeight={35}>
        <Table.Column width={300} fixed={"left"} resizable>
          <Table.HeaderCell>Sieć</Table.HeaderCell>
          <Table.Cell>
            {(rowData: RowDataType<IUnifiedEntity>) => (
              <div key={"network_" + rowData.id}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    paddingLeft: "30px",
                    position: "relative",
                  }}>
                  <CheckBox
                    isChecked={isCheckedFullNetwork(rowData.id)}
                    onClick={() =>
                      handleSelectAllByNetwork(rowData as INetwork)
                    }
                    style={{ position: "absolute", left: 0, top: 1 }}
                  />
                  {rowData.name}
                </div>
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        {viewData &&
          viewData.entities.map((entity) => {
            return (
              <Table.Column width={60} align="left" key={entity.id}>
                <Table.HeaderCell depth={0} width={0} align={"center"}>
                  <div style={{ height: "300px", padding: "12px 0" }}>
                    <CheckBox
                      isChecked={isCheckedFullEntity(entity.id)}
                      onClick={() => handleSelectAllByEntity(entity)}
                      style={{ position: "absoulte" }}
                    />
                    <SeparatorEmpty />
                    <div title={entity.name} className={styles.entityHeader}>
                      <Whisper
                        placement="auto"
                        trigger={"hover"}
                        speaker={<Tooltip>{entity.name}</Tooltip>}>
                        <span className={styles.verticalText}>
                          {entity.name}
                        </span>
                      </Whisper>
                    </div>
                  </div>
                </Table.HeaderCell>
                <Table.Cell width={100} align={"center"}>
                  {(rowData: RowDataType<IUnifiedEntity>) => (
                    <CheckBox
                      onClick={() =>
                        handleUpdateListing(rowData as INetwork, entity)
                      }
                      isChecked={!isRejected(rowData.id, entity.id)}
                      style={{ position: "initial" }}
                    />
                  )}
                </Table.Cell>
              </Table.Column>
            );
          })}
      </Table>
    </>
  );
};

export default TableUniversal;
