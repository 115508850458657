import React, { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import { getQuestionIconByType } from "../../IconHelper";
import settings from "../../../../assets/svg/settings.svg";
import { IQuestionItem } from "../../../../utils/models";
import { DragHandle } from "global/atoms/SortableItem";
import { Tooltip, Whisper } from "rsuite";
import AbTestIcon from "@rsuite/icons/AbTest";

interface IQuestionItemBody {
  question: IQuestionItem;
  checked: Array<string>;
  pickedId: string | null;
  setPickedId: React.Dispatch<React.SetStateAction<string | null>>;
  handleToggleCheckbox: (itemId: string) => void;
  pickable: boolean;
  sortable: boolean;
}

const QuestionItemBody: FunctionComponent<IQuestionItemBody> = (props) => {
  const isQuestionPicked = (): boolean => {
    return props.pickedId === props.question.id;
  };

  return (
    <>
      <div className={styles.itemRow}>
        <div
          className={styles.wrapper}
          style={{ fontWeight: isQuestionPicked() ? 700 : "inherit" }}>
          <span
            data-id={props.question.id}
            onClick={props.handleToggleCheckbox.bind(null, props.question.id)}
            className={`${styles.checkboxItem} ${
              props.checked.includes(props.question.id) ? styles.checked : ""
            }`}
          />
          {props.sortable && <DragHandle />}
          <span
            className={styles.itemName}
            style={{
              color: props.question.isEnableForFeature ? "#ffaf38" : "initial",
            }}>
            {getQuestionIconByType(props.question.questionType)}
            {props.question.questionName}
          </span>
          {props.question.isRequired && (
            <Whisper
              placement={"auto"}
              trigger={"hover"}
              speaker={<Tooltip>Pytanie Wymagane</Tooltip>}>
              <span style={{ fontSize: "2em", color: "red", cursor: "help" }}>
                *
              </span>
            </Whisper>
          )}
          {props.question.dependOnQuestion?.id && (
            <Whisper
              placement={"auto"}
              trigger={"hover"}
              speaker={
                <Tooltip>
                  Zależne od:
                  <strong>
                    {props.question.dependOnQuestion.questionName}
                  </strong>
                  &nbsp;
                </Tooltip>
              }>
              <AbTestIcon
                rotate={135}
                style={{ fontSize: "1em", cursor: "help" }}
              />
            </Whisper>
          )}
        </div>
        {props.pickable && !isQuestionPicked() && (
          <img
            src={settings}
            style={{ cursor: "pointer" }}
            data-id={props.question.id}
            onClick={() => props.setPickedId(props.question.id)}
            alt={"Ustawienia"}
          />
        )}
      </div>
    </>
  );
};
export default QuestionItemBody;
