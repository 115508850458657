import React, { memo } from "react";
import { FunctionComponent } from "react";
import { Form, Input, Badge, Col } from "rsuite";
import { IVisitCommentItem } from "../VisitEdit";
import Comment from "./Atoms/Comment";
import { isPublicView } from "utils/helpers";
import SeparatorEmpty from "../../../../../../global/atoms/separators/SeparatorEmpty";
import PeoplesCustomizeIcon from "@rsuite/icons/PeoplesCostomize";

interface IVisitComment {
  refQuestionId: string | null;
  disabled: boolean;
  comments: IVisitCommentItem[];
}

export function CommentsBadgeWrapper(props: { len: number }) {
  return (
    <>
      <div
        style={{
          fontSize: "0.85em",
          position: "absolute",
          top: "0",
          right: "0",
        }}>
        {props.len ? <Badge color={"orange"} content={props.len} /> : ""}
      </div>
      <PeoplesCustomizeIcon
        style={{ cursor: "pointer", fontSize: "1.5em" }}
        color={"#FFAF38"}
      />
    </>
  );
}

const VisitComment: FunctionComponent<IVisitComment> = (props) => {
  if (isPublicView() && !props.comments.length) return <></>;
  return (
    <>
      <SeparatorEmpty />
      <Col sm={24}>
        {!isPublicView() && (
          <>
            <Form.Group>
              <Col sm={12} xs={12} md={8}>
                <Form.ControlLabel>Nowy komentarz</Form.ControlLabel>
              </Col>
              <Col sm={12} xs={12} md={16}>
                <Form.Control
                  name={`visitCommentQuestion.${props.refQuestionId}`}
                  accepter={Input}
                  style={{ width: "100%" }}
                  disabled={props.disabled}
                />
              </Col>
            </Form.Group>
            <SeparatorEmpty />
          </>
        )}
        <Col xs={24}>
          {props.comments.map((commentData, key) => (
            <Comment
              key={`question-comment-${key}`}
              author={commentData.userName}
              date={commentData.createdAt}
              content={commentData.comment}
            />
          ))}
        </Col>
      </Col>
    </>
  );
};

export default memo(VisitComment);
