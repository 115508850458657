import React, { memo } from "react";
import { FCC, IAddress, IVisitData } from "utils/models";
import { usePanelContext } from "./VisitHelpers";
import { Col, Form, Panel, Row, Input as RInput, Badge } from "rsuite";
import SeparatorLine from "global/atoms/separators/SeparatorLine";
import dayjs from "dayjs";

interface IVisitDetails {
  visitData: IVisitData;
}

const VisitDetails: FCC<IVisitDetails> = ({ visitData }) => {
  const getLocationAddressAsString = (address: IAddress): string => {
    return `${address.street.name} ${address.houseNumber} ${
      address.apartmentNumber ? `/${address.apartmentNumber} ` : ""
    }, ${address.postCode?.name} ${address.city?.name}, ${
      address.community.name
    }, ${address.voivodeship.name}`;
  };

  const panelCtx = usePanelContext();

  return (
    <Panel
      shaded
      collapsible
      expanded={panelCtx.openPanels.includes("visit-details")}
      onSelect={() => panelCtx.panelToggle("visit-details")}
      header={"Dane ogólne"}>
      <Form
        plaintext
        layout={window.innerWidth < 500 ? "vertical" : "horizontal"}
        formValue={visitData}>
        <Row>
          <Col lg={18} xs={24}>
            <Form.Group>
              <Form.ControlLabel>{"Lokalizacja"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"locationName"}
                value={visitData.locationName}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Opis lokalizacji"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"locationDescription"}
                value={visitData.location.description}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>{"Adres lokalizacji"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"addressId"}
                value={getLocationAddressAsString(visitData.location.address)}
              />
            </Form.Group>
          </Col>
          <Col lg={6} xs={24}>
            {visitData.location.nip && (
              <>
                <Form.Group>
                  <Form.ControlLabel>{"NIP"}</Form.ControlLabel>
                  <Form.Control
                    accepter={RInput}
                    name={"locationCode"}
                    value={visitData.location.nip}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group>
              <Form.ControlLabel>{"Kod lokalizacji"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"locationCode"}
                value={visitData.location.code}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Kod sieci lokalizacji"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"locationCode"}
                value={visitData.location.networkLocationCode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Numer porządkowy"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"incrementId"}
                value={visitData.location.incrementId}
              />
            </Form.Group>
          </Col>
        </Row>
        <SeparatorLine />
        <Row>
          <Col lg={8} xs={24}>
            <Form.Group>
              <Form.ControlLabel>{"Okres ważności [dni]"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"expirationDays"}
                value={visitData.expirationDays}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Termin realizacji"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"expirationDate"}
                value={visitData.expirationDate}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Sieć"}</Form.ControlLabel>
              <Form.Control
                accepter={RInput}
                name={"networkName"}
                value={visitData.networkName}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Status"}</Form.ControlLabel>
              <Badge
                style={{
                  background: visitData.status.color,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
                content={visitData.status.name}
              />
            </Form.Group>
          </Col>

          <Col lg={8} xs={24}>
            <Form.Group>
              <Form.ControlLabel>{"Data utworzenia wizyty"}</Form.ControlLabel>
              <Form.Control accepter={RInput} name={"createdAt"} />
              <span>
                &nbsp;|&nbsp;Tydzień: {dayjs(visitData.createdAt).week()}
              </span>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Data rozpoczęcia wizyty"}</Form.ControlLabel>
              <Form.Control accepter={RInput} name={"startDate"} />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Data zakończenia wizyty"}</Form.ControlLabel>
              <Form.Control accepter={RInput} name={"endDate"} />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{"Data wysłania wizyty"}</Form.ControlLabel>
              <Form.Control accepter={RInput} name={"insertDate"} />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>
                {"Data ostatniej aktualizacji"}
              </Form.ControlLabel>
              <Form.Control accepter={RInput} name={"updatedAt"} />
            </Form.Group>
          </Col>

          <Col lg={8}>
            {visitData.users.map((user) => (
              <Form.Group key={`visit-user-id-${user.visitUserId}`}>
                <Form.ControlLabel>
                  {user.reporting
                    ? user.userRole.role.roleName + " [raportujący]"
                    : user.userRole.role.roleName}
                </Form.ControlLabel>
                <Form.Control
                  accepter={RInput}
                  name={user.userRole.role.roleName}
                  value={user.userRole.user.fullName}
                />
              </Form.Group>
            ))}
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};

export default memo(VisitDetails);
