import React from "react";
// import process from "process";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import SpinnerSmall from "../atoms/Spinner/SpinnerSmall";
import { FCC } from "utils/models";

interface IMapWrapper {}

export const getGoogleApiKey = (): string => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY ?? "";
  if (googleApiKey === "") console.warn("GOOGLE API KEY EVN NOT FOUND!");
  return googleApiKey;
};

const MapWrapper: FCC<IMapWrapper> = (props) => {
  const mapRender = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <SpinnerSmall />;
      case Status.FAILURE:
        return <>{"błąd"}</>;
      case Status.SUCCESS:
        return props.children;
    }
  };

  // @ts-ignore
  return <Wrapper apiKey={getGoogleApiKey()} render={mapRender} />;
};

export default MapWrapper;
