import React, { FunctionComponent } from "react";
import { Button } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { useParams } from "react-router-dom";

interface IErrorPage {
  errorCode?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorPage: FunctionComponent<IErrorPage> = (props) => {
  const paramEC = useParams<{ errorCode: string }>();
  const errorCode = paramEC.errorCode ? parseInt(paramEC.errorCode) : 404;

  const homepageRedirect = () => {
    window.location.href = "/";
  };

  const explainErrorCode = (errorCode: number): string => {
    switch (errorCode) {
      case 300:
        return "Wiele możliwości";
      case 301:
        return "Zasób trwale przeniesiony";
      case 302:
        return "Found";
      case 303:
        return "";
      case 304:
        return "";
      case 305:
        return "";
      case 306:
        return "";
      case 307:
        return "Temporary Redirect";
      case 310:
        return "Zbyt wiele przekierowań";
      case 400:
        return "Nieprawidłowe zapytanie";
      case 401:
        return "Nieautoryzowany dostęp";
      case 402:
        return "Wymagana opłata";
      case 403:
        return "Zabroniony dostęp";
      case 404:
        return "Nie odnaleziono zasobu";
      case 405:
        return "Niedozwolona metoda";
      case 406:
        return "Not Acceptable";
      case 407:
        return "Wymagane uwierzytelnienie proxy";
      case 408:
        return "Request timeout";
      case 409:
        return "Konflikt - żądanie nie może być zrealizowane";
      case 410:
        return "Zniknął - żądany zasób nie jest dłużej dostępny";
      case 411:
        return "Wymagana długość";
      case 412:
        return "Warunek wstępny nie może być spełniony";
      case 413:
        return "";
      case 414:
        return "Adres URI zapytania zbyt długi";
      case 415:
        return "Nieznany sposób żądania";
      case 416:
        return "";
      case 417:
        return "";
      case 418:
        return "";
      case 421:
        return "";
      case 422:
        return "";
      case 423:
        return "";
      case 424:
        return "";
      case 425:
        return "";
      case 426:
        return "";
      case 428:
        return "";
      case 429:
        return "";
      case 431:
        return "";
      case 451:
        return "";
      case 500:
        return "Wewnętrzny błąd serwera";
      case 501:
        return "Nie zaimplementowano";
      case 502:
        return "Błąd bramy";
      case 503:
        return "Usługa niedostępna";
      case 504:
        return "Przekroczony czas bramy";
      case 505:
        return "Nieobsługiwana wersja HTTP";
      case 506:
        return "";
      case 507:
        return "";
      case 508:
        return "";
      case 509:
        return "Limit przekroczony - serwer nie dostępny";
      case 510:
        return "";
      case 511:
        return "Network Authentication Required";
      default:
        return "";
    }
  };

  return (
    <div style={{ fontSize: "3em", textAlign: "center" }}>
      <div
        style={{
          color: "#ffaf38",
          fontWeight: "bold",
          fontSize: "3.5em",
          display: "inline-block",
          lineHeight: 1,
        }}>
        <div style={{ textAlign: "left", fontSize: ".13em" }}>
          Ups, coś poszło nie tak...
        </div>
        <div>{errorCode}</div>
      </div>

      <div>{explainErrorCode(errorCode)}</div>

      <SeparatorEmpty size={3} />
      <Button onClick={homepageRedirect} appearance={"ghost"} size="lg">
        Strona główna
      </Button>
    </div>
  );
};

export default ErrorPage;
