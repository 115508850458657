import React, { FunctionComponent, useEffect, useState } from "react";
import CheckBox from "../../../../../global/atoms/checkbox/CheckBox";
import { IFeedbackDetails } from "../../../../../utils/models";
import stylesAtom from "../../../../../global/atoms/atoms.module.scss";
import { deepClone } from "../../../../../utils/helpers";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import { Radio, RadioGroup } from "rsuite";
import { isArray } from "lodash";
import FormControlLabel from "rsuite/FormControlLabel";
import SeparatorLine from "../../../../../global/atoms/separators/SeparatorLine";
import {
  BOOLEAN_QUESTION,
  DICTIONARY_QUESTION,
} from "../../../../projects/components/visits/visitsEdit/VisitElements/QuestionTypeConsts";

interface ISLAReviewForm {
  feedbackDetails: IFeedbackDetails[];
  onChange?: (feedbackDetails: IFeedbackDetails[]) => void;
  isEdit?: boolean;
  errors?: any;
  readOnly?: boolean;
}

const SLAReviewForm: FunctionComponent<ISLAReviewForm> = (props) => {
  const [posState, setPosState] = useState<IFeedbackDetails[]>(
    props.feedbackDetails
  );
  const [overlay, setOverlay] = useState<boolean>(!(props.isEdit ?? true));

  useEffect(() => {
    if (!overlay && props.onChange) {
      props.onChange(posState);
    }
  }, [overlay, posState]);

  const handleTogglePos = (item: IFeedbackDetails) => {
    if (props.readOnly) return false;

    const tmp = deepClone(posState);
    tmp.forEach((i) => {
      if (i.values?.length === 0 || !i.values) {
        i.values = [{ value: "0", refQuestionAnswerId: "" }];
      }
    });

    if (item.values?.length === 0)
      item.values = [{ value: "1", refQuestionAnswerId: "" }];
    else
      item.values =
        item.values?.map((v) => ({
          ...v,
          value: !(v.value === "1") ? "1" : "0",
        })) ?? [];

    tmp[
      posState.findIndex(
        (obj) => obj.feedbackQuestion.id === item.feedbackQuestion.id
      )
    ] = item;
    setPosState(tmp);
  };
  const handleToggleDictionaryPos = (item: IFeedbackDetails, value: string) => {
    if (!props.readOnly) {
      const tmp = deepClone(posState);
      item.values = [{ value: value, refQuestionAnswerId: "" }];
      tmp[
        posState.findIndex(
          (obj) => obj.feedbackQuestion.id === item.feedbackQuestion.id
        )
      ] = item;
      setPosState(tmp);
    }
  };

  const hasAllChecked = (): boolean => {
    return (
      posState.filter((i) => {
        if (!i.values?.length) return true;
        //@ts-ignore
        return (
          i.values[0].value !== "1" &&
          i.feedbackQuestion.questionType == BOOLEAN_QUESTION
        );
      }).length === 0
    );
  };

  const handleToggleAll = () => {
    const checked = !hasAllChecked();
    setPosState(
      posState.map((i) => ({
        ...i,
        values:
          i.feedbackQuestion.questionType == BOOLEAN_QUESTION
            ? [{ value: checked ? "1" : "0", refQuestionAnswerId: "" }]
            : i.values,
      }))
    );
  };

  return (
    <>
      {overlay && (
        <div
          onClick={() => setOverlay(false)}
          style={{
            opacity: 0.8,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 99,
            borderRadius: "8px",
            background: "#fff",
            textAlign: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div style={{ color: "rgb(255, 175, 56)" }}>
            Kliknij aby dodać ocenę
          </div>
        </div>
      )}

      <div style={{ marginBottom: "20px" }}>
        {!props.readOnly && (
          <>
            <CheckBox isChecked={hasAllChecked()} onClick={handleToggleAll} />
            <SeparatorEmpty size={0.5} />
          </>
        )}
        {posState
          .sort((i1, i2) => {
            return i1.feedbackQuestion.id >= i2.feedbackQuestion.id ? 1 : 0;
          })
          .map((item) => {
            const isRequired = item.feedbackQuestion.isRequired;
            if (item.feedbackQuestion.questionType == DICTIONARY_QUESTION) {
              const value = isArray(item.values)
                ? item.values[0]?.value
                : undefined;
              return (
                <>
                  <SeparatorLine />
                  <FormControlLabel>
                    {item.feedbackQuestion.name}&nbsp;
                    {isRequired && <sup style={{ color: "red" }}>*</sup>}
                  </FormControlLabel>
                  <RadioGroup
                    inline
                    name="radio-group-controlled"
                    value={value}
                    onChange={(value) =>
                      handleToggleDictionaryPos(item, value as string)
                    }>
                    {item.feedbackQuestion.answers.map((el) => (
                      <>
                        <Radio value={el.id}>{el.name}</Radio>
                      </>
                    ))}
                  </RadioGroup>

                  {(props.errors && props.errors[item.feedbackQuestion.id]) && (
                      <div className={stylesAtom.errors}>
                        {props.errors[item.feedbackQuestion.id]}
                      </div>
                  )}
                </>
              );
            } else {
              const value = isArray(item.values)
                ? item.values[0]?.value
                : undefined;
              return (
                <CheckBox
                  isChecked={value === "1"}
                  key={`${item.feedbackQuestion.id}_${
                    props.readOnly ? "readOnly" : ""
                  }`}
                  onClick={handleTogglePos.bind(null, item)}>
                  {item.feedbackQuestion.name}&nbsp;
                  {isRequired && <span style={{ color: "red" }}>*</span>}
                </CheckBox>
              );
            }
          })}
      </div>
    </>
  );
};

export default SLAReviewForm;
