import React, { FunctionComponent } from "react";
import { useLocation, matchPath, generatePath, Link } from "react-router-dom";
import { IMenuPosition } from "redux/models";
import styles from "./nav.module.scss";
import MoveUpIcon from "@rsuite/icons/MoveUp";
import { allRoutes } from "routes/routes";
import MainMenuProxyRedirect from "routes/MainMenuProxyRedirect";
import { isPublicScope } from "views/security/Security";
import { Popover, Tooltip, Whisper } from "rsuite";

interface IAppMenu {
  items: IMenuPosition[];
  expanded: boolean;
  onClick: () => void;
}

const AppMenu: FunctionComponent<IAppMenu> = (props) => {
  const { pathname } = useLocation();

  if (isPublicScope) return <></>;

  const breadcrumbs = allRoutes.filter(
    (r) => r.breadcrumb && matchPath(pathname, r.path) !== null
  );

  const getMenuFlat = (
    positions: Array<IMenuPosition>
  ): Array<IMenuPosition> => {
    let active: Array<IMenuPosition> = [];
    for (const position of positions) {
      active.push(position);
      if (position.positions)
        active = [...active, ...getMenuFlat(position.positions)];
    }
    return active;
  };

  // exact path from current location
  let activeMenu = getMenuFlat(props.items).find((i) =>
    matchPath(pathname, { path: i.url, exact: true })
  );

  // match menu path from breadcrumbs
  if (!activeMenu) {
    breadcrumbs.reverse().every((b) => {
      activeMenu = getMenuFlat(props.items).find((i) => i.url === b.path);
      return !activeMenu;
    });
  }

  const activeMenuParent = getMenuFlat(props.items).find(
    (i) => i.id === activeMenu?.parent
  );
  const params = matchPath(pathname, activeMenu?.url)?.params ?? {};
  const displayedMenuLevel = activeMenu?.positions.length
    ? activeMenu?.level + 1
    : activeMenu?.level ?? 0;

  const isActive = (item: IMenuPosition): boolean => {
    return matchPath(pathname, item.url) !== null;
  };
  const MenuItemIcon: FunctionComponent<{ item: IMenuPosition }> = (_props) => {
    return (
      <img
        key={`${_props.item.id}-img1`}
        className={`${styles.icon} ${
          isActive(_props.item) ? styles.iconActive : ""
        }`}
        src={`${_props.item.iconPath}.svg`}
        alt={_props.item.name}
      />
    );
  };

  const getActiveChildrenPositions = (): IMenuPosition[] => {
    const childrenPos = activeMenu?.positions?.length
      ? activeMenu?.positions ?? []
      : activeMenuParent?.positions ?? [];

    if (!childrenPos?.length) return [];

    return childrenPos.filter((subItem) => {
      let path = null;
      try {
        path = generatePath(subItem.url, params);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return path !== null;
    });
  };

  const navigationOnClick = () => {
    props.onClick();
  };

  const RootMenuItems: FunctionComponent = () => {
    const subMenuOpen = getActiveChildrenPositions()?.length !== 0;
    return (
      <ul key={"headerNavListPrimary"} className={`${styles.rootCol}`}>
        {props.items.map((item) => (
          <li
            key={`m-item-${item.id}`}
            className={`${styles.rootElement} ${styles.element}
            ${
              isActive(item)
                ? props.expanded
                  ? styles.elementExpandedActive
                  : styles.elementActive
                : styles.elementInactive
            }
            ${
              subMenuOpen && isActive(item)
                ? styles.rootElementActiveSubMenu
                : ""
            }

            `}>
            <Whisper
              enterable
              speaker={
                !props.expanded &&
                isActive(item) &&
                getActiveChildrenPositions().length ? (
                  <Popover>
                    <ChildMenuItems />
                  </Popover>
                ) : (
                  <Tooltip>{item.hint}</Tooltip>
                )
              }
              trigger={"hover"}
              placement="auto">
              <Link
                style={{ all: "unset" }}
                to={item.url ?? ""}
                onClick={navigationOnClick}>
                <MenuItemIcon item={item} />
              </Link>
            </Whisper>

            {props.expanded && !subMenuOpen && (
              <Link
                style={{ all: "unset" }}
                to={item.url ?? ""}
                onClick={navigationOnClick}>
                {item.name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const ChildMenuItems: FunctionComponent = () => {
    const childrenPos = getActiveChildrenPositions();
    if (!childrenPos?.length) return <></>;
    return (
      <ul key={"headerNavListSub"} className={`${styles.childrenCol}`}>
        {displayedMenuLevel > 1 && activeMenu.backUrl && (
          <li
            key={`move-up-${activeMenu.id}`}
            className={`${styles.moveup} ${styles.element} ${styles.elementInactive}`}>
            <Link
              onClick={navigationOnClick}
              style={{ all: "unset" }}
              to={generatePath(activeMenu.backUrl, params)}>
              <MoveUpIcon key={`move-up-icon-${activeMenu.id}`} /> Powrót
            </Link>
          </li>
        )}
        {childrenPos.map((subItem) => {
          const path = generatePath(subItem.url, params);
          return (
            <li
              key={`sub-menu-item-${subItem.id}`}
              className={`${styles.element} ${path ?? styles.elementDisabled} ${
                matchPath(pathname, subItem.url)
                  ? props.expanded
                    ? styles.elementActive
                    : styles.elementActive
                  : styles.elementInactive
              }`}>
              {path ? (
                <Link
                  style={{ all: "unset" }}
                  to={path ?? ""}
                  onClick={navigationOnClick}>
                  {subItem.name}
                </Link>
              ) : (
                subItem.name
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  function MenuRedirectProxy() {
    return (
      <>
        {props.items[0]?.url !== undefined &&
          location.pathname == "/" &&
          props.items[0].url !== "/" && (
            <MainMenuProxyRedirect redirectTo={props.items[0].url} />
          )}
      </>
    );
  }

  const getWrapperClassName = (): string => {
    return `${styles.menuWrapper} ${props.expanded ? styles.menuExpanded : ""}`;
  };

  return (
    <>
      <MenuRedirectProxy />
      <div className={getWrapperClassName()}>
        <RootMenuItems />
        {props.expanded && <ChildMenuItems />}
      </div>
    </>
  );
};

export default AppMenu;
