import { default as axios } from "../AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "../helpers";
import { AxiosPromise } from "axios";
import { IUniversalListing } from "../../views/projects/components/taskActivitiesEdit/listing/TableUniversal";
import { IItemLevelListing } from "../../views/projects/components/taskActivitiesEdit/listing/TableItemLevels";

const productListingBaseUri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/listing_product_network`;
const questionListingBaseUri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/listing_question_network`;

const itemLevelListingBaseUri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/listing_item_level_network`;

const itemHighlightListingBaseUri = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/listing_item_highlight_network`;

const ListingConnection = {
  // product
  getEditItemForm: (activityId: string): AxiosPromise => {
    return axios.get(`${productListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkItemList: (activityId: string): AxiosPromise => {
    return axios.get(`${productListingBaseUri}/view/list/${activityId}`);
  },
  saveProductListing: (
    activityId: string,
    listing: IUniversalListing[]
  ): AxiosPromise => {
    return axios.put(`${productListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
  // question
  getEditQuestionForm: (activityId: string): AxiosPromise => {
    return axios.get(`${questionListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkQuestionList: (activityId: string): AxiosPromise => {
    return axios.get(`${questionListingBaseUri}/view/list/${activityId}`);
  },
  saveQuestionListing: (
    activityId: string,
    listing: IUniversalListing[]
  ): AxiosPromise => {
    return axios.put(`${questionListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
  // item level listing
  saveItemLevelListing: (
    activityId: string,
    listing: IItemLevelListing[]
  ): AxiosPromise => {
    // todo:
    return axios.put(`${itemLevelListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
  getEditItemLevelForm: (activityId: string): AxiosPromise => {
    return axios.get(`${itemLevelListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkItemLevelList: (activityId: string): AxiosPromise => {
    return axios.get(`${itemLevelListingBaseUri}/view/list/${activityId}`);
  },
  // item highlight listing
  saveItemHighlightListing: (
    activityId: string,
    listing: IUniversalListing[]
  ): AxiosPromise => {
    return axios.put(`${itemHighlightListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
  getEditItemHighlightForm: (activityId: string): AxiosPromise => {
    return axios.get(`${itemHighlightListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkItemHighlightList: (activityId: string): AxiosPromise => {
    return axios.get(`${itemHighlightListingBaseUri}/view/list/${activityId}`);
  },
};

export default ListingConnection;
