import React, { CSSProperties } from "react";
import { Loader } from "rsuite";

interface ISpinnerProps {
  style?: CSSProperties;
  content?: string;
}

const SpinnerSmall: React.FC<ISpinnerProps> = ({ style, content }) => (
  <div style={{ textAlign: "center", padding: "25px 0" }}>
    <Loader
      style={style}
      content={content ? content : "Ładowanie..."}
      size="sm"
      vertical
    />
  </div>
);

export default SpinnerSmall;
