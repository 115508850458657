import React, { FunctionComponent, useState } from "react";
import styles from "./security.module.scss";
import UserConnection from "../../utils/connections/user";
import { useDispatch } from "react-redux";
import { SET_APP } from "../../redux/actions";
import Input, { InputTypes } from "../../global/atoms/Input";

import logo from "../../assets/images/logo.png";
import { handleToast } from "../../utils/helpers";
import { Button, Message } from "rsuite";
import SeparatorEmpty from "../../global/atoms/separators/SeparatorEmpty";

interface IChangePassword {}

const SecurityPasswordChange: FunctionComponent<IChangePassword> = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");
  const [errors, setErrors] = useState<any | null>(null);
  const dispatch = useDispatch();

  const handleChangeCurrentPassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCurrentPassword(event.target.value);
  };

  const handleChangeNewPassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNewPassword(event.target.value);
  };
  const handleChangeRepeatNewPassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setRepeatNewPassword(event.target.value);
  };

  const handleSubmitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(
      UserConnection.changeOwnPassword({
        currentPassword: currentPassword,
        repeatNewPassword: repeatNewPassword,
        newPassword: newPassword,
      })
    )
      .then(() => {
        UserConnection.logout().then(() => {
          localStorage.removeItem("user");
          dispatch({ type: SET_APP, payload: { logged: false } });
          window.location.href = "/";
        });
      })
      .catch((err) => setErrors(err.response.data));
  };

  return (
    <div
      className={styles.container}
      style={{ height: "50vh", alignItems: "start" }}>
      <form className={styles.form} onSubmit={handleSubmitForm}>
        <img src={logo} alt={"logo"} className={styles.logo} />
        <Input
          onChange={handleChangeCurrentPassword}
          value={currentPassword}
          name={"oldPassword"}
          placeholder={"Aktualne hasło"}
          autocomplete={"current-password"}
          type={InputTypes.password}
        />
        {errors?.errors?.currentPassword && (
          <span className={styles.errors}>
            {errors?.errors?.currentPassword}
          </span>
        )}
        <Input
          onChange={handleChangeNewPassword}
          value={newPassword}
          name={"newPassword"}
          placeholder={"Nowe hasło"}
          autocomplete={"new-password"}
          type={InputTypes.password}
        />
        {errors?.errors?.newPassword && (
          <span className={styles.errors}>{errors?.errors?.newPassword}</span>
        )}
        <Input
          onChange={handleChangeRepeatNewPassword}
          value={repeatNewPassword}
          name={"repeatNewPassword"}
          placeholder={"Powtórz nowe hasło"}
          autocomplete={"new-password"}
          type={InputTypes.password}
        />
        {errors?.errors?.repeatNewPassword && (
          <span className={styles.errors}>
            {errors?.errors?.repeatNewPassword}
          </span>
        )}
        {errors?.message && (
          <span className={styles.errors}>{errors.message}</span>
        )}
        <SeparatorEmpty />
        <Message showIcon={true} type={"warning"}>
          Po zmianie hasła nastąpi wylogowanie z serwisu!
        </Message>
        <div style={{ textAlign: "right" }}>
          <Button
            block
            appearance={"ghost"}
            style={{ marginTop: "20px" }}
            type={"submit"}>
            Zmień hasło
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SecurityPasswordChange;
