import React, { CSSProperties, FunctionComponent } from "react";
import { IFilter } from "../ItemsList";
import BasicFilter from "./BasicFilter";
import { SelectPicker } from "rsuite";
import { TypeProductGroupType } from "../../../../utils/types";

interface IProductFilter {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
}

interface IOptionItem {
  value: TypeProductGroupType;
  label: string;
}

const ProductFilter: FunctionComponent<IProductFilter> = (props) => {
  const wrapperStyle: CSSProperties = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "flex-end",
  };

  const options: Array<IOptionItem> = [
    { value: "POS", label: "POSM" },
    { value: "GIFT", label: "Gratis" },
    { value: "PRODUCT", label: "Produkt" },
    { value: "EXPOSE", label: "Ekspozycja" },
  ];

  const handleSelected = (value: TypeProductGroupType | null) => {
    props.setFilter({
      ...props.filter,
      productGroupType: value ?? "",
    });
  };

  return (
    <div style={wrapperStyle}>
      <SelectPicker
        block
        cleanable={true}
        searchable={false}
        placeholder={"Wybierz typ produktu"}
        name={"productGroupType"}
        style={{ width: "100%" }}
        value={props.filter.productGroupType}
        // @ts-ignore
        onChange={handleSelected}
        data={options}
      />
      <BasicFilter
        filter={props.filter}
        setFilter={props.setFilter}
        maxWidth={"100%"}
      />
    </div>
  );
};

export default ProductFilter;
