import React, { CSSProperties, FunctionComponent } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { getProjectIdFromUrl } from "../../../../utils/helpers";
import styles from "../styles.module.scss";
import { IItemTaskLocation } from "../../../../utils/models";
import settings from "../../../../assets/svg/edit.svg";

interface ILocationItemBody {
  item: IItemTaskLocation;
  checked: Array<string>;
  handleToggleCheckbox: (itemId: string) => void;
}
const TaskLocationItemBody: FunctionComponent<ILocationItemBody> = (props) => {
  const history = useHistory();
  const handleGoToEdit = () => {
    history.push(
      generatePath("/projects/:id/edit/locations/:locationId", {
        id: getProjectIdFromUrl() ?? "",
        locationId: props.item.id,
      })
    );
  };

  const getCheckboxClass = (item: { id: string }) => {
    return `${styles.checkboxItem} ${
      props.checked.includes(item.id) ? styles.checked : ""
    }`;
  };

  const _style: CSSProperties = {
    color: "rgb(144,144,144)",
    fontSize: "0.7em",
  };

  return (
    <>
      <div className={styles.itemRow} key={props.item.id}>
        <div className={styles.wrapper}>
          <span
            data-id={props.item.id}
            onClick={props.handleToggleCheckbox.bind(null, props.item.id)}
            className={getCheckboxClass(props.item)}
          />
          <div>
            <span className={styles.itemName}>{props.item.name}</span>
            {props.item.locationAddress && (
              <div style={_style}>{props.item.locationAddress}</div>
            )}
          </div>
        </div>
        <img
          src={settings}
          style={{ cursor: "pointer" }}
          alt={"Idź do"}
          data-id={props.item.id}
          onClick={handleGoToEdit}
        />
      </div>
    </>
  );
};
export default TaskLocationItemBody;
