import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import ScTable from "global/table/ScTable";
import connection from "utils/connections/contract/contractConnection";

import Table from "rsuite/Table";
import WhiteCard from "../../../global/atoms/WhiteCard";
import Pagination from "../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../global/filters/UniversalFilters";
import { _paginate30 } from "utils/states";

interface IContractDocumentAssignedList {}
interface IContractDocumentsFilter {
  category: string;
  type: string;
  signature: string;
  name: string;
  requestPaginate: {
    page: number;
    limit: number;
  };
  requestOrder: {
    field: string;
    order: string;
  };
}
const contractDocumentsFilter: IContractDocumentsFilter = {
  category: "",
  type: "",
  signature: "",
  name: "",
  ..._paginate30,
};
const ContractDocumentList: FunctionComponent<
  IContractDocumentAssignedList
> = () => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [filters, setFilters] = useState<IContractDocumentsFilter>(
    contractDocumentsFilter
  );

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      <WhiteCard>
        <ScTable data={data?.data ?? []} loading={loading}>
          <Table.Column flexGrow={2} align="left">
            <Table.HeaderCell>Szablon</Table.HeaderCell>
            <Table.Cell dataKey="name">
              {(rowData) => {
                return <span>{rowData.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} align="left" fixed="right">
            <Table.HeaderCell>Karegoria</Table.HeaderCell>
            <Table.Cell dataKey="category">
              {(rowData) => {
                return <span>{rowData.category.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} align="left" fixed="right">
            <Table.HeaderCell depth={0} width={0}>
              Typ
            </Table.HeaderCell>
            <Table.Cell dataKey="type">
              {(rowData) => {
                return <span>{rowData.type.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} align="left" fixed="right">
            <Table.HeaderCell>Podpis</Table.HeaderCell>
            <Table.Cell dataKey="signature">
              {(rowData) => {
                return <span>{rowData.signature.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>
        </ScTable>
      </WhiteCard>

      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={connection.getContractDocument.bind(null, id, filters)}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={contractDocumentsFilter}
        setResultData={setData}
        formGet={connection.getContractDocumentFilters.bind(null, id)}
        filterStorageKey={"contractDocumentList"}
        elements={[
          {
            label: "Szablon",
            stateKey: "name",
            type: FILTER_INPUT_TEXT,
          },
          {
            label: "Typ",
            stateKey: "type",
            type: FILTER_SELECT,
            formKey: "types",
          },
          {
            label: "Kategoria",
            stateKey: "category",
            type: FILTER_SELECT,
            formKey: "categories",
          },
          {
            label: "Podpis",
            stateKey: "signature",
            type: FILTER_SELECT,
            formKey: "signatures",
          },
        ]}
      />
    </>
  );
};

export default ContractDocumentList;
