import {
  IForm,
  IFormRequest,
} from "views/datasets/components/addressAdd/AddressAdd";
import { AxiosManager, HttpMethod, default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl } from "../helpers";
import {
  IAddress,
  IBodyResponse,
  ICreateAddress,
  IFindPostcode,
  IFindStreet,
  IGetAddresses,
} from "../models";

const AddressesConnection = {
  getAddresses: (data: IGetAddresses, pickerHash: string) =>
    AxiosManager.send<IBodyResponse<IAddress>>(
      HttpMethod.post,
      `${getBaseApiUrl()}/addresses/list`,
      data,
      `addressList-${pickerHash}`
    ),
  getAddress: (id: string) => {
    return axios.get(`${getBaseApiUrl()}/addresses/${id}`, {
      headers: { ...getAuthHeader() },
    });
  },
  createAddress: (data: ICreateAddress) => {
    return axios.post(`${getBaseApiUrl()}/addresses`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  updateAddress: (id: string, data: ICreateAddress) => {
    return axios.put(`${getBaseApiUrl()}/addresses/${id}`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  getFormCreateAddress: (requestData: Partial<IFormRequest>) =>
    AxiosManager.send<IForm>(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/addresses/form`,
      requestData,
      "getFormCreateAddress"
    ),
  getFormEditAddress: (id: string, requestData: Partial<IFormRequest>) =>
    AxiosManager.send<IForm>(
      HttpMethod.post,
      `${getBaseApiUrl()}/organizations/addresses/${id}/form`,
      requestData,
      "getFormEditAddress"
    ),
  getFormFilterAddress: () => {
    return axios.get(`${getBaseApiUrl()}/organizations/addresses/list/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  findStreet: (data: IFindStreet) => {
    return axios.post(`${getBaseApiUrl()}/addresses/street/find`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  findPostcode: (data: IFindPostcode) => {
    return axios.post(`${getBaseApiUrl()}/addresses/postcode/find`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${getBaseApiUrl()}/addresses`, {
      headers: { ...getAuthHeader() },
      data: { addresses: data },
    });
  },
};

export default AddressesConnection;
