import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./nav.module.scss";
import { IMenu } from "../../../redux/models";
import { clickOutsideMenu, getProjectIdFromPath } from "../../../utils/helpers";
import AppMenu from "./AppMenu";
import MenuConnection from "utils/connections/menu";
import { isPublicScope } from "views/security/Security";
import _logo from "../../../assets/images/logo.png";
import logo_min from "../../../assets/images/logo_min.png";
import { useLocation } from "react-router-dom";
import { IconButton, Nav, Navbar, Placeholder, Sidebar, Sidenav } from "rsuite";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import MenuIcon from "@rsuite/icons/Menu";
import _ from "lodash";
import ReactDOM from "react-dom";
import CloseIcon from "@rsuite/icons/Close";

const Navigation: FunctionComponent = () => {
  const ref = useRef(null);
  const [menu, setMenu] = useState<IMenu | undefined>();
  const [logo, setLogo] = useState(_logo);
  const { pathname } = useLocation();
  const [projectId, setProjectId] = useState(getProjectIdFromPath(pathname));
  const MobileMenuButtonElm = document.getElementById("mobile-menu-button");

  const [expand, setExpand] = useState(
    localStorage.getItem("navExpanded") !== "0"
  );
  const [mobileExpand, setMobileExpand] = useState(false);

  const mssInfoShow = [
    "localhost:3000",
    "mss.salescompanion.pl",
    "test-mss.salescompanion.pl",
  ].includes(window.location.host);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey && event.key === "/") {
      setExpand((s) => !s);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("navExpanded", expand ? "1" : "0");
  }, [expand]);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1365) {
        setExpand(true);
      } else {
        setExpand(false);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const menuLoader = () => {
    if (isPublicScope) return;
    MenuConnection.get(projectId ?? null).then((data) => {
      setMenu(data?.data);
      localStorage.setItem(
        "menuPositions",
        JSON.stringify(data?.data?.positions)
      );
      setLogo(data?.data.logo);
    });
  };

  useEffect(menuLoader, [projectId]);
  useEffect(() => {
    const newProjectId = getProjectIdFromPath(pathname);
    if (projectId !== newProjectId) setProjectId(newProjectId);
  }, [pathname]);

  clickOutsideMenu(ref, setExpand);

  const MssInfo: FunctionComponent = () => {
    return (
      <div className={styles.navInfo}>
        <div>
          <h6>Pomoc techniczna</h6>
          e-mail:{" "}
          <a href="mailto:wsparcie.companion@msservices.pl">
            wsparcie.companion@msservices.pl
          </a>
          <br />
          telefon: <a href="tel:+48668899800">+48 668 899 800</a>
          <br />
          Godziny pracy: 8:00 - 16:00
        </div>
      </div>
    );
  };

  const PublicMenu: FunctionComponent = () => {
    return (
      <div
        key={"nav-public-access"}
        style={{ textAlign: "center", opacity: "0.3", marginTop: "5%" }}>
        <small>Publiczny dostęp</small>
      </div>
    );
  };

  const isExpanded = expand || mobileExpand;

  const getSidebarWidth = () => {
    if (expand) return 300;
    if (mobileExpand) return "100%";
    return 56;
  };

  return (
    <>
      {MobileMenuButtonElm &&
        ReactDOM.createPortal(
          <IconButton
            className={`${styles.mobileVisible}`}
            icon={<MenuIcon />}
            onClick={() => setMobileExpand((s) => !s)}
          />,
          MobileMenuButtonElm
        )}
      <Sidebar
        className={`${styles.navSidebar} ${
          expand ? styles.navSidebarExpanded : ""
        } ${mobileExpand ? styles.navSidebarMobileExpanded : ""}`}
        width={getSidebarWidth()}
        collapsible>
        <Sidenav.Header>
          <a href="/" key={"nav-logo"} style={{ textAlign: "center" }}>
            {isExpanded && (
              <img
                src={logo}
                alt={"logo"}
                style={{
                  maxWidth: "80%",
                  maxHeight: "100px",
                  padding: "5px 20px",
                }}
              />
            )}
            {!isExpanded && (
              <img
                src={logo_min}
                alt={"logo"}
                style={{ maxWidth: "100%", maxHeight: "56px", padding: "10px" }}
              />
            )}
          </a>
          <IconButton
            onClick={() => {
              setExpand(false);
              setMobileExpand(false);
            }}
            style={{ position: "absolute", right: "10px", top: "10px" }}
            className={`${styles.mobileVisible}`}
            icon={<CloseIcon />}
          />
        </Sidenav.Header>
        <Sidenav
          expanded={isExpanded}
          appearance="subtle"
          className={styles.sidebarBody}>
          <Sidenav.Body
            style={{
              padding: isExpanded ? "0 20px" : "0",
              paddingTop: "25px",
            }}>
            {_.isEmpty(menu?.positions) && !isPublicScope ? (
              isExpanded && (
                <Placeholder
                  rows={10}
                  rowMargin={40}
                  style={{ marginTop: "30px", marginBottom: "20px" }}
                  rowHeight={20}
                  active
                />
              )
            ) : (
              <AppMenu
                items={menu?.positions ?? []}
                expanded={isExpanded}
                onClick={() => {
                  setMobileExpand(false);
                }}
              />
            )}
            {isPublicScope && <PublicMenu />}
          </Sidenav.Body>
        </Sidenav>

        <Navbar
          appearance="subtle"
          className={`${styles.navFooter} ${styles.mobileNotVisible}`}>
          {mssInfoShow && isExpanded && (
            <Nav>
              <MssInfo />
            </Nav>
          )}
          <Nav>
            <Nav.Item onClick={() => setExpand(!expand)}>
              {isExpanded ? <AngleLeftIcon /> : <AngleRightIcon />}
            </Nav.Item>
          </Nav>
        </Navbar>
      </Sidebar>
      <div className={styles.version}>v{process.env.VERSION}</div>
    </>
  );
};

export default Navigation;
