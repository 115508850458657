import arrow from "../assets/svg/arrow_down_small.svg";
import React, { FunctionComponent, PropsWithChildren } from "react";

export const handleToggleSort = (filters: any, setFilters: any, event: any) => {
  if (filters.requestOrder.field === event.currentTarget.dataset.field) {
    setFilters({
      ...filters,
      requestOrder: {
        field:
          filters.requestOrder.field === ""
            ? undefined
            : filters.requestOrder.field,
        order:
          filters.requestOrder.order === ""
            ? undefined
            : filters.requestOrder.order,
      },
    });
  } else {
    setFilters({
      ...filters,
      requestOrder: {
        field: event.currentTarget.dataset.field,
        order: undefined,
      },
    });
  }
};

interface IButtonSortProps {
  dataName: string;
  filters: any;
  setFilters: any;
}

// @deprecated todo:
export const ButtonSort: FunctionComponent<
  PropsWithChildren<IButtonSortProps>
> = ({ children, filters, setFilters, dataName }) => {
  return (
    <span
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      onClick={handleToggleSort.bind(null, filters, setFilters)}
      data-field={dataName}>
      {children}
      {filters.requestOrder.field === dataName &&
        (filters.requestOrder.order === "asc" ? (
          <img src={arrow} alt={"sort"} />
        ) : (
          <img
            src={arrow}
            alt={"sort"}
            style={{ transform: "rotate(180deg)" }}
          />
        ))}
    </span>
  );
};
