import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "rsuite/Modal";
import {
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  Nav,
  Row,
  SelectPicker,
  Toggle,
} from "rsuite";
import Button from "rsuite/Button";
import { IModalData, ScheduleInterval } from "../Schedule";
import { IUserSimple } from "../../../utils/models";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { WEEK_DAYS_OBJ } from "../../../utils/dictionaries";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import SchedulesConnection from "utils/connections/schedules";
import SpinnerSmall from "global/atoms/Spinner/SpinnerSmall";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";

dayjs.locale(localePl);

interface IScheduleModal {
  modalData: IModalData | null;
  onModalClose: () => void;
  onScheduleRemove: () => void;
  onModalSubmit: (data: IScheduleModalData) => void;
  requestData: IScheduleRequestData;
}

export interface IScheduleModalData {
  type: ScheduleInterval;
  startDate: Date | null;
  endDate: Date | null | undefined;
  days: Array<number>;
  projectId: string;
  taskId: string;
  locationId: string;
  userId: string;
  hours: number | null;
  isApproved: boolean;
  users?: Array<IUserSimple>;
}

const ScheduleModal: FunctionComponent<IScheduleModal> = (props) => {
  const [modalData, setModalData] = useState<IScheduleModalData | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.modalData && ['create', 'edit'].includes(props.modalData.type) ) {
      setOpen(true);
      SchedulesConnection.modalData(
        props.modalData.rowId,
        props.modalData.timeTableId
      ).then((res) => {
        const tmp = res.data;
        if (!props.modalData?.timeTableId && props.modalData?.date) {
          tmp.startDate = props.modalData.date;
        }
        // tmp fix - date parser not applying for some reason on that endpoint
        if (typeof tmp.startDate !== "object" && tmp.startDate)
          tmp.startDate = dayjs(tmp.startDate).toDate();
        if (typeof tmp.endDate !== "object" && tmp.endDate)
          tmp.endDate = dayjs(tmp.endDate).toDate();
        setModalData(tmp);
      });
    } else {
      setOpen(false);
      setModalData(null);
    }
  }, [props.modalData]);

  const intervals = {
    [ScheduleInterval.Weekly]: "Raz na tydzień",
    [ScheduleInterval.Weekly2]: "Raz na 2 tygodnie",
    [ScheduleInterval.Weekly3]: "Raz na 3 tygodnie",
    [ScheduleInterval.Weekly4]: "Raz na cztery tygodnie",
  };

  const isReadyToSave = (): boolean => {
    if (!modalData) return false;
    if (!modalData.userId) return false;
    if (!modalData.projectId) return false;
    if (!modalData?.taskId) return false;

    if (modalData.type !== ScheduleInterval.Single) {
      // recur schedule
      if (!modalData.days?.length) return false; // no-days selected in recur schedule
      if (!modalData.startDate) return false; // empty start date
    }

    return true;
  };

  const updateState = (value: Partial<IScheduleModalData>) => {
    setModalData((s) => {
      if (!s) return s;
      return { ...s, ...value };
    });
  };

  return (
    <>
      <Modal
        open={open}
        size={"sm"}
        onExited={props.onModalClose}
        onClose={() => setOpen(false)}>
        <Modal.Header>
          <h4>
            {props.modalData?.type === "create" && "Nowy harmonogram"}
            {props.modalData?.type === "edit" && "Podgląd harmonogramu"}{" "}
            {/*Edytuj harmonogram*/}
          </h4>
        </Modal.Header>

        <Modal.Body>
          <div>
            {!modalData ? (
              <SpinnerSmall />
            ) : (
              <>
                {props.modalData?.type !== "edit" && (
                  <Nav
                    appearance="default"
                    justified
                    onSelect={(eventKey: string) =>
                      updateState({
                        type:
                          eventKey === "single"
                            ? ScheduleInterval.Single
                            : ScheduleInterval.Weekly,
                      })
                    }>
                    {/* @ts-ignore */}
                    <Nav.Item
                      eventKey={"single"}
                      active={modalData.type === ScheduleInterval.Single}>
                      Jednorazowy
                    </Nav.Item>
                    <Nav.Item
                      eventKey={"recurrent"}
                      active={modalData.type !== ScheduleInterval.Single}>
                      Cykliczny
                    </Nav.Item>
                  </Nav>
                )}

                <SeparatorEmpty size={2} />

                <div>
                  <strong>Raportujący</strong>
                  <SelectPicker
                    data={modalData.users ?? []}
                    value={modalData.userId ?? undefined}
                    readOnly={
                      modalData.userId !== null ||
                      props.modalData?.type === "edit"
                    } //
                    valueKey={"id"}
                    labelKey={"fullName"}
                    cleanable={false}
                    searchable={true}
                    placeholder={"wybierz"}
                    block
                    onChange={(value) => updateState({ userId: value })}
                  />
                  <br />
                  <strong>Liczba godzin</strong>
                  <InputNumber
                    value={modalData.hours ?? undefined}
                    step={0.1}
                    min={0}
                    max={100}
                    readOnly={props.modalData?.type === "edit"}
                    onChange={(value: number) => {
                      updateState({ hours: value });
                    }}
                    onBlur={() => {
                      modalData.hours &&
                        updateState({
                          ...modalData,
                          hours: parseFloat(modalData.hours.toString()),
                        });
                    }}
                  />
                </div>

                {modalData.type === ScheduleInterval.Single && (
                  <>
                    <SeparatorEmpty size={2} />
                    <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                      {dayjs(modalData.startDate).format("dddd, D MMMM YYYY")}
                    </h5>
                  </>
                )}

                <SeparatorEmpty size={2} />

                {modalData.type !== ScheduleInterval.Single && (
                  <div>
                    <strong>Dni tygodnia</strong>
                    <div>
                      {WEEK_DAYS_OBJ.map((w) => (
                        <Checkbox
                          checked={(modalData.days ?? []).includes(w.key)}
                          key={`k-${w.key}`}
                          style={{
                            display: "block",
                            opacity: (modalData.days ?? []).includes(w.key)
                              ? "1"
                              : "0.5",
                            padding: 0,
                          }}
                          onChange={(value, checked) => {
                            if (props.modalData?.type === "edit") return; // todo: disabled temp
                            const daysFiltered = (modalData?.days ?? []).filter(
                              (_d) => _d !== w.key
                            );
                            updateState({
                              days: checked
                                ? daysFiltered.concat([w.key])
                                : daysFiltered,
                            });
                          }}>
                          {w.name}
                        </Checkbox>
                      ))}
                    </div>
                    <SeparatorEmpty size={2} />

                    <div>
                      <strong>Częstotliwość</strong>
                      <SelectPicker
                        data={Object.entries(intervals).map((v) => ({
                          label: v[1],
                          value: parseInt(v[0]),
                        }))}
                        block
                        readOnly={props.modalData?.type === "edit"}
                        value={modalData.type}
                        cleanable={false}
                        searchable={false}
                        placeholder={"wybierz"}
                        onChange={(value) =>
                          updateState({ type: parseInt(value.toString()) })
                        }
                      />
                    </div>

                    <SeparatorEmpty size={1.5} />

                    <div>
                      <strong>
                        Harmonogram od - do&nbsp;&nbsp;
                        <Toggle
                          size="md"
                          readOnly={props.modalData?.type === "edit"}
                          checkedChildren={"∞"}
                          unCheckedChildren={"data końcowa"}
                          checked={modalData.endDate === null}
                          onChange={(val) => {
                            updateState({ endDate: !val ? undefined : null });
                          }}
                        />
                      </strong>
                      <SeparatorEmpty size={0.5} />
                      <Row>
                        <Col xs={12}>
                          <DatePicker
                            block
                            placement={"topStart"}
                            cleanable={false}
                            isoWeek
                            shouldDisableDate={(date) =>
                              dayjs(date).isBefore(dayjs())
                            }
                            readOnly={props.modalData?.type === "edit"}
                            onChange={(date) =>
                              updateState({ startDate: date })
                            }
                            value={modalData.startDate ?? undefined}
                          />
                        </Col>
                        <Col xs={12}>
                          {modalData.endDate !== null && (
                            <DatePicker
                              block
                              placement={"topStart"}
                              isoWeek
                              cleanable={false}
                              shouldDisableDate={(date) =>
                                dayjs(date).isBefore(
                                  modalData.startDate ?? dayjs()
                                )
                              }
                              readOnly={props.modalData?.type === "edit"}
                              onChange={(date) =>
                                updateState({ endDate: date })
                              }
                              value={modalData.endDate}
                            />
                          )}
                          {modalData.endDate === null && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "8px",
                                color: "#a2a2a2",
                              }}>
                              Brak daty końcowej
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          {props.modalData?.type === "edit" &&
            dayjs(props.modalData.date).isAfter(dayjs(), "day") && (
              <Button
                appearance={"primary"}
                color={"red"}
                onClick={props.onScheduleRemove}>
                Usuń
              </Button>
            )}
          {props.modalData?.type !== "edit" && (
            <Button
              appearance={"primary"}
              disabled={!isReadyToSave()}
              onClick={() => {
                if (modalData) props.onModalSubmit(modalData);
              }}>
              Utwórz
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModal;
