import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Badge, IconButton, Tooltip, Whisper } from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import { checkedInitValue, IChecked } from "./DoubleSidePicker";
import {
  TypeDoubleSidePickerItems,
  TypeDoubleSidePickerItemType,
} from "../../../utils/types";
import QuestionItemBody from "./itemBody/QuestionItemBody";
import UserItemBody from "./itemBody/UserItemBody";
import {
  IItemTaskLocation,
  IOptionBase,
  IProductItem,
  IQuestionItem,
  IUserMultiSelectRawEntityParsed,
} from "../../../utils/models";
import ProductItemBody from "./itemBody/ProductItemBody";
import BasicFilter from "./itemFilter/BasicFilter";
import LocationFilter from "./itemFilter/LocationFilter";
import UserGlobalFilter from "./itemFilter/UserGlobalFilter";
import UserGlobalItemBody from "./itemBody/UserGlobalItemBody";
import objectHash from "object-hash";
import ProductFilter from "./itemFilter/ProductFilter";
import ProductListExtras, { IRecurData } from "./listExtras/ProductListExtras";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "../SortableItem";
import BaseItemBody from "./itemBody/BaseItemBody";
import _ from "lodash";
import TaskQuestionFilter from "./itemFilter/TaskQuestionFilter";
import TaskLocationItemBody from "./itemBody/TaskLocationItemBody";
import AngleDoubleDown from "@rsuite/icons/legacy/AngleDoubleDown";

export interface IFilter {
  textSearch: string;
  networks: Array<string>;
  networkId: string;
  projectId: string;
  roleId: string;
  productGroupType: string;
  customerId: string;
  cityName: string;
  linkedUsersOption: boolean;
  roleConnectedId: string;
  userId: string;
}

interface IItemsList {
  handleToggleCheckbox: (itemId: string) => void;
  handleToggleMainCheckbox: (ids: Array<string>) => void;
  checked: Array<string>;
  setChecked: React.Dispatch<React.SetStateAction<IChecked>>;
  data: {
    assigned: TypeDoubleSidePickerItems;
    unassigned: TypeDoubleSidePickerItems;
  };
  dataType: "assigned" | "unassigned";
  emptyMessage: string;
  itemType: TypeDoubleSidePickerItemType;
  pickedId: string | null;
  form: any;
  setPickedId: React.Dispatch<React.SetStateAction<string | null>>;
  onSortChange?: (assignedSortedData: TypeDoubleSidePickerItems) => void;
  filtersHidden?: boolean;
  triggerFilter: number;
  actionOnAssigned?: (event) => void;
  onlyUsersFromLocations?: boolean;
  onlyCurrentUser?: boolean;
}

const ItemsList: FunctionComponent<IItemsList> = (props) => {
  const emptyFilterValue: IFilter = {
    textSearch: "",
    networkId: "",
    projectId: "",
    roleId: "",
    networks: [],
    productGroupType: "",
    customerId: "",
    cityName: "",
    userId: "",
    roleConnectedId: "",
    linkedUsersOption: false,
  };

  const resultLimit = props.dataType === "unassigned" ? 999 : 0; // 0 = no limit
  const [filter, setFilter] = useState<IFilter>(emptyFilterValue);
  const [resultCapped, setResultCapped] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(0);

  useEffect(() => {
    // reset checked items
    if (props.setChecked !== undefined) props.setChecked(checkedInitValue);
  }, [filter]);

  useEffect(() => {
    // clear filter on data assigment
    // setFilter(emptyFilterValue); // #862k1zfhw
  }, [props.triggerFilter]);

  const classForCheckedCheckbox = () => {
    let cn = styles.checkboxCounter;
    if (
      props.checked?.length &&
      props.checked?.length === filteredDataMemo.length
    ) {
      cn += " " + styles.checked;
    }
    return cn;
  };

  function getMyTypeData<
    T extends TypeDoubleSidePickerItems = TypeDoubleSidePickerItems,
  >(): T {
    if (props.dataType === "assigned") return props.data.assigned as T;
    return props.data.unassigned as T;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCombinedData = (): TypeDoubleSidePickerItems => {
    return [...props.data.assigned, ...props.data.unassigned];
  };

  const getFilterForm = (): JSX.Element => {
    if (props.filtersHidden) {
      return <BasicFilter filter={filter} setFilter={setFilter} />;
    }

    switch (props.itemType) {
      case "taskQuestionAlert":
      case "locationQuestion":
      case "projectLocationQuestion":
      case "user":
        return <BasicFilter filter={filter} setFilter={setFilter} />;
      case "taskQuestion": {
        const customers: IOptionBase[] = [];
        getMyTypeData<Array<IQuestionItem>>().forEach((d) => {
          if (!customers.find((c) => c.id === d.customerId)) {
            customers.push({
              id: d.customerId,
              name: d.customerName,
            });
          }
        });

        return (
          <TaskQuestionFilter
            filter={filter}
            setFilter={setFilter}
            customers={customers}
          />
        );
      }
      case "userProject":
      case "userGlobal":
        // @ts-ignore
        return (
          <UserGlobalFilter
            //@ts-ignore
            usersData={getMyTypeData()}
            filter={filter}
            setFilter={setFilter}
            onlyUsersFromLocations={props.onlyUsersFromLocations}
            onlyCurrentUser={props.onlyCurrentUser}
          />
        );
      case "taskLocation":
        return (
          <LocationFilter
            form={props.form}
            filter={filter}
            setFilter={setFilter}
          />
        );
      case "product":
        return <ProductFilter filter={filter} setFilter={setFilter} />;
    }

    return <></>;
  };

  const isFilterActive = (): boolean =>
    objectHash(emptyFilterValue) !== objectHash(filter);

  const getExtras = (): JSX.Element => {
    switch (props.itemType) {
      case "product": {
        if (props.filtersHidden) break;
        if (props.dataType !== "assigned" || !props.onSortChange) break;

        return (
          <ProductListExtras
            onSorted={(sortGuide: IRecurData[]) => {
              setFilter(emptyFilterValue);

              // @ts-ignore
              const data: IProductItem[] = _.cloneDeep(getMyTypeData());

              const recurSort = (
                sortGuide: IRecurData,
                items: IProductItem[]
              ): IProductItem[] => {
                if (!sortGuide.selected) return items;

                let compareFunc:
                  | ((a: IProductItem, b: IProductItem) => number)
                  | null;
                const asc = sortGuide.sortType === "AZ";

                const groupByKeySortAndPassDeeper = (
                  key: string,
                  keyId: string
                ): IProductItem[] => {
                  const _items = _.cloneDeep(items);

                  compareFunc = (a, b) => {
                    // @ts-ignore
                    if (a[key].toLowerCase() > b[key].toLowerCase())
                      return asc ? 1 : -1;
                    // @ts-ignore
                    if (a[key].toLowerCase() < b[key].toLowerCase())
                      return asc ? -1 : 1;
                    return 0;
                  };

                  // @ts-ignore
                  _items.sort(compareFunc);

                  // deeper sort on each category group
                  if (
                    sortGuide.children &&
                    sortGuide.children.filter((c) => c.selected).length > 0
                  ) {
                    const group: { [gid: string]: Array<IProductItem> } = {};

                    _items.forEach((p) => {
                      // @ts-ignore
                      if (!group[p[keyId]]) {
                        // @ts-ignore
                        group[p[keyId]] = [];
                      }
                      // @ts-ignore
                      group[p[keyId]].push(p);
                    });

                    let tmp: IProductItem[] = [];
                    for (const [, _is] of Object.entries(group)) {
                      for (const sort of sortGuide.children) {
                        if (sort.selected) {
                          tmp = [...tmp, ...recurSort(sort, _is)];
                        }
                      }
                    }
                    return tmp;
                  }

                  return _items;
                };

                switch (sortGuide.value) {
                  case "abc":
                    return groupByKeySortAndPassDeeper("name", "name");
                  case "categories":
                    return groupByKeySortAndPassDeeper(
                      "categoryName",
                      "categoryId"
                    );
                  case "brands":
                    return groupByKeySortAndPassDeeper("brandName", "brandId");

                  case "types": {
                    // unusual behavior
                    const typeGroups: { [type: string]: Array<IProductItem> } =
                      {};
                    items.forEach((p) => {
                      if (!typeGroups[p.groupType]) {
                        typeGroups[p.groupType] = [];
                      }
                      typeGroups[p.groupType].push(p);
                    });

                    let tmp: IProductItem[] = [];
                    if (sortGuide.children) {
                      for (const childType of sortGuide.children) {
                        if (typeGroups[childType.value]) {
                          const tmpItems = _.cloneDeep(
                            typeGroups[childType.value]
                          );
                          tmp = [...tmp, ...recurSort(childType, tmpItems)];
                        }
                      }
                    }

                    return tmp;
                  }
                  default:
                    return items;
                }
              };

              let sortedData: IProductItem[] = data;
              sortGuide.forEach((sg) => {
                sortedData = recurSort(sg, sortedData);
              });

              if (props.onSortChange) props.onSortChange(sortedData);
            }}
          />
        );
      }
    }

    return <></>;
  };

  const getFilteredData = (filter: IFilter): TypeDoubleSidePickerItems => {
    let filteredResult = [];
    const isFilterActive = objectHash(emptyFilterValue) !== objectHash(filter);

    switch (props.itemType) {
      case "taskQuestionAlert":
      case "locationQuestion":
      case "projectLocationQuestion": {
        if (!isFilterActive) return getMyTypeData();
        // @ts-ignore
        filteredResult = (getMyTypeData() as IQuestionItem).filter(
          (item: IQuestionItem) =>
            item.questionName
              .toLowerCase()
              .indexOf(filter.textSearch.toLowerCase()) >= 0
        );
        break;
      }
      case "taskQuestion":
        if (!isFilterActive) return getMyTypeData();
        // @ts-ignore
        filteredResult = getMyTypeData().filter((item: IQuestionItem) => {
          if (filter.textSearch) {
            const valid =
              item.questionName
                .toLowerCase()
                .indexOf(filter.textSearch.toLowerCase()) >= 0;
            if (!valid) return false;
          }

          if (filter.customerId) {
            const valid = item.customerId == filter.customerId;
            if (!valid) return false;
          }

          return true;
        });
        break;
      case "product": {
        if (!isFilterActive) return getMyTypeData();
        // @ts-ignore
        let tmp: Array<IProductItem> = getMyTypeData();

        if (filter.productGroupType) {
          tmp = tmp.filter(
            (item) => item.groupType === filter.productGroupType
          );
        }

        if (filter.textSearch) {
          tmp = tmp.filter(
            (item) =>
              item.name
                .toLowerCase()
                .indexOf(filter.textSearch.toLowerCase()) >= 0 ||
              item.id.indexOf(filter.textSearch) >= 0
          );
        }
        // @ts-ignore
        filteredResult = tmp;
        break;
      }
      case "base":
      case "user": {
        if (!isFilterActive) return getMyTypeData();
        // @ts-ignore
        filteredResult = getMyTypeData().filter(
          (item: IOptionBase) =>
            item.name.toLowerCase().indexOf(filter.textSearch.toLowerCase()) >=
            0
        );
        break;
      }
      case "userGlobal":
      case "userProject": {
        const parsed: Array<IUserMultiSelectRawEntityParsed> = [];
        // @ts-ignore
        let tmp: Array<IUserMultiSelectRawEntityParsed> = getMyTypeData();

        if (isFilterActive) {
          if (filter.projectId)
            tmp = tmp.filter((u) => filter.projectId === u.projectId);
          /* filtering by network */
          if (filter.networkId)
            tmp = tmp.filter((u) => filter.networkId === u.networkId);
          /* filtering by city */
          if (filter.cityName)
            tmp = tmp.filter((u) => filter.cityName === u.cityName);

          if (filter.linkedUsersOption) {
            const locations: { [locationId: string]: string } = {};
            tmp
              .filter(
                (u) =>
                  u.locationId &&
                  (filter.roleId ? u.roleId == filter.roleId : true) &&
                  (filter.userId ? u.userId == filter.userId : true)
              )
              .forEach((u) => {
                locations[u.locationId] = u.locationId;
              });

            /* filtering by locations */
            tmp = tmp.filter(
              (u) =>
                u.locationId &&
                locations[u.locationId] &&
                (filter.roleConnectedId
                  ? filter.roleConnectedId === u.roleId
                  : true) &&
                (filter.roleId ? filter.roleId != u.roleId : true) &&
                (filter.userId ? filter.userId != u.userId : true)
            );
          } else {
            if (filter.roleId) {
              tmp = tmp.filter((u) => filter.roleId === u.roleId);
            }
          }

          /* filtering by text */
          if (filter.textSearch)
            tmp = tmp.filter(
              (u) =>
                u.userName
                  .toLowerCase()
                  .indexOf(filter.textSearch.toLowerCase()) >= 0
            );
        }

        // get rid of duplicates
        tmp.forEach((u) => {
          if (parsed.find((pu) => pu.userId === u.userId) === undefined) {
            parsed.push(u);
          }
        });
        // @ts-ignore
        filteredResult = parsed;
        break;
      }
      case "taskLocation": {
        if (!isFilterActive) return getMyTypeData();
        // @ts-ignore
        const data: Array<IItemTaskLocation> = getMyTypeData(resultLimit);

        let filtered = data.filter(
          (item: IItemTaskLocation) =>
            item.name.toLowerCase().indexOf(filter.textSearch.toLowerCase()) >=
              0 ||
            item.id.indexOf(filter.textSearch) >= 0 ||
            (item.locationAddress?.toLowerCase()?.indexOf(filter.textSearch) ??
              -1) >= 0
        );
        if (filter.networks.length > 0) {
          filtered = filtered.filter((item: IItemTaskLocation) =>
            filter.networks.includes(item.networkId)
          );
        }
        // @ts-ignore
        filteredResult = filtered;
        break;
      }
    }

    if (resultLimit > 0 && filteredResult.length > resultLimit) {
      setResultCapped(true);
      return filteredResult.splice(0, resultLimit);
    }
    setResultCapped(false);
    return filteredResult;
  };

  const filteredDataMemo = useMemo<TypeDoubleSidePickerItems>(
    () => getFilteredData(filter),
    [filter, props.triggerFilter, triggerFilter]
  );

  const dataWrapper = (children: any): JSX.Element => {
    const onSortEnd = (event: DragEndEvent) => {
      const active = event.active.id;
      const over = event.over?.id ?? 0;

      if (active !== over) {
        const oldIndex = getMyTypeData().findIndex((v) => v.id === active);
        const newIndex = getMyTypeData().findIndex((v) => v.id === over);
        if (props.onSortChange) {
          props.onSortChange(arrayMove(getMyTypeData(), oldIndex, newIndex));
        }
      }

      setTriggerFilter(Date.now());
    };

    switch (props.itemType) {
      case "product":
      case "taskQuestion":
      case "taskQuestionAlert":
      case "projectLocationQuestion":
      case "locationQuestion": {
        return (
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[
              restrictToVerticalAxis,
              restrictToWindowEdges,
              restrictToFirstScrollableAncestor,
            ]}
            onDragEnd={onSortEnd}>
            <SortableContext
              items={getMyTypeData().map((val) => val.id)}
              disabled={isFilterActive()}
              strategy={verticalListSortingStrategy}>
              {children}
            </SortableContext>
          </DndContext>
        );
      }
    }

    return <>{children}</>;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dataMapper = (
    item: TypeDoubleSidePickerItems,
    key: number
  ): JSX.Element => {
    switch (props.itemType) {
      case "base": {
        // @ts-ignore
        const baseItem: IOptionBase = item;
        return (
          <BaseItemBody
            key={`item-base-body-${baseItem.id}`}
            handleToggleCheckbox={props.handleToggleCheckbox}
            item={baseItem}
            checked={props.checked}
          />
        );
      }
      case "product": {
        // @ts-ignore
        const _item: IProductItem = item;
        const elmKey = `item-product-body-${_item.id}`;
        const tmpElm = (
          <ProductItemBody
            key={elmKey}
            handleToggleCheckbox={props.handleToggleCheckbox}
            item={_item}
            sortable={props.dataType === "assigned" && !isFilterActive()}
            checked={props.checked}
          />
        );

        if (props.dataType === "assigned") {
          return (
            <SortableItem key={elmKey} id={_item.id} dragHandleActivator={true}>
              {tmpElm}
            </SortableItem>
          );
        }
        return tmpElm;
      }
      case "userProject":
      case "userGlobal": {
        // @ts-ignore
        const userItem: IUserMultiSelectRawEntityParsed = item;
        const elmKey = `item-user-body-${userItem.userId}`;
        return (
          <UserGlobalItemBody
            key={elmKey}
            handleToggleCheckbox={props.handleToggleCheckbox}
            item={userItem}
            checked={props.checked}
          />
        );
      }
      case "user": {
        // @ts-ignore
        const userItem: IOptionBase = item;
        return (
          <UserItemBody
            key={`item-user-body-${userItem.id}`}
            handleToggleCheckbox={props.handleToggleCheckbox}
            item={userItem}
            checked={props.checked}
          />
        );
      }
      case "taskLocation": {
        // @ts-ignore
        const locationItem: IItemTaskLocation = item;
        const elmKey = `item-location-body-${locationItem.id}`;
        return (
          <TaskLocationItemBody
            key={elmKey}
            handleToggleCheckbox={props.handleToggleCheckbox}
            item={locationItem}
            checked={props.checked}
          />
        );
      }
      case "taskQuestionAlert": {
        // @ts-ignore
        const questionItem: IQuestionItem = item;
        const elmKey = `alert-item-question-body-${questionItem.id}`;
        const tmpElm = (
          <QuestionItemBody
            key={elmKey}
            checked={props.checked}
            pickedId={props.pickedId}
            setPickedId={props.setPickedId}
            handleToggleCheckbox={props.handleToggleCheckbox}
            pickable={props.dataType === "assigned"}
            sortable={false}
            question={questionItem}
          />
        );
        return tmpElm;
      }
      case "taskQuestion":
      case "projectLocationQuestion":
      case "locationQuestion": {
        // @ts-ignore
        const questionItem: IQuestionItem = item;
        const elmKey = `item-question-body-${questionItem.id}`;
        const tmpElm = (
          <QuestionItemBody
            key={elmKey}
            checked={props.checked}
            pickedId={props.pickedId}
            setPickedId={props.setPickedId}
            handleToggleCheckbox={props.handleToggleCheckbox}
            pickable={props.dataType === "assigned"}
            sortable={props.dataType === "assigned" && !isFilterActive()}
            question={questionItem}
          />
        );
        if (props.dataType === "assigned") {
          return (
            <SortableItem
              key={elmKey}
              id={questionItem.id}
              dragHandleActivator={true}>
              {tmpElm}
            </SortableItem>
          );
        }
        return tmpElm;
      }
    }
    console.warn("dataMapper - unknown type");
    return <></>;
  };

  return (
    <>
      <div style={{ marginBottom: "25px", borderColor: "#ffaf38" }}>
        {getFilterForm()}
        <div className={styles.doubleSideHeading}>
          <div className={styles.itemListWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!props.checked?.length ? (
                <span
                  className={classForCheckedCheckbox()}
                  //@ts-ignore
                  onClick={props.handleToggleMainCheckbox.bind(
                    null,
                    filteredDataMemo.map((item) => item.id)
                  )}
                />
              ) : (
                <Badge color={"orange"} content={props.checked?.length}>
                  <span
                    className={classForCheckedCheckbox()}
                    //@ts-ignore
                    onClick={props.handleToggleMainCheckbox.bind(
                      null,
                      filteredDataMemo.map((item) => item.id)
                    )}
                  />
                </Badge>
              )}
              <span className={styles.columnAndCounter}>
                {props.dataType === "assigned" ? "Przypisane" : "Nieprzypisane"}{" "}
                ({filteredDataMemo.length}
                {resultCapped && <strong>+</strong>}
                {isFilterActive() && <FunnelIcon />})
              </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "5px",
            }}>
            {typeof props.actionOnAssigned === "function" && (
              <div>
                <Whisper
                  placement={"auto"}
                  trigger={"hover"}
                  speaker={
                    <Tooltip>Edycja pytań, wartość predefiniowana</Tooltip>
                  }>
                  <IconButton
                    onClick={props.actionOnAssigned}
                    appearance={"ghost"}
                    icon={<AngleDoubleDown />}
                  />
                </Whisper>
              </div>
            )}
          </div>
        </div>

        <div
          className={styles.wrapperItemDataList}
          style={
            props.dataType === "assigned" ? { borderColor: "#ffaf38" } : {}
          }>
          {!filteredDataMemo.length ? (
            props.emptyMessage ? (
              isFilterActive() ? (
                <div className={styles.emptyMessage}>
                  <FunnelIcon />
                  &nbsp;{`Nie znaleziono elementów przy obecnym filtrowaniu`}
                </div>
              ) : (
                <div className={styles.emptyMessage}>{props.emptyMessage}</div>
              )
            ) : (
              <></>
            )
          ) : (
            // @ts-ignore
            dataWrapper(filteredDataMemo.map(dataMapper))
          )}
        </div>
        {getExtras()}
      </div>
    </>
  );
};
export default ItemsList;
