import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
} from "../../../../utils/helpers";
import axios, { AxiosResponse } from "axios";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;

const TargetConnections = {
  getTargetForm: (projectId: string) => {
    return axios.get(`${baseUrl}/${projectId}/targets/form`, {
      headers: getAuthHeader(),
    });
  },
  getTargetModel: (projectId: string, targetId: string) => {
    return axios.get(`${baseUrl}/${projectId}/targets/${targetId}/form`, {
      headers: getAuthHeader(),
    });
  },
  addTargetForm: (projectId: string, data) => {
    return axios.post(`${baseUrl}/${projectId}/targets`, data, {
      headers: getAuthHeader(),
    });
  },
  editTargetForm: (projectId: string, targetId: string, data) => {
    return axios.put(`${baseUrl}/${projectId}/targets/${targetId}`, data, {
      headers: getAuthHeader(),
    });
  },
  getTargetDefinitions: (
    projectId: string,
    data
  ): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseUrl}/${projectId}/targets/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getTargetUsersList: (
    projectId: string,
    data
  ): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseUrl}/${projectId}/targets/users/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getTargetUsersFilterForm: (
    projectId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl}/${projectId}/targets/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getTargetDefinitionsFilterForm: (
    projectId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl}/${projectId}/targets/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getTargetUsersForm: (
    projectId: string,
    targetId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl}/${projectId}/targets/${targetId}/form/filter-users`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getTargetItemsForm: (
    projectId: string,
    targetId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl}/${projectId}/targets/${targetId}/form/filter-items`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getTargetLocationsForm: (
    projectId: string,
    targetId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl}/${projectId}/targets/${targetId}/form/filter-locations`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getTargetQuestionForm: (
    projectId: string,
    targetId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl}/${projectId}/targets/${targetId}/form/filter-questions`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  removeTargetDefinition: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(`${baseUrl}/${projectId}/targets`, {
      headers: { ...getAuthHeader() },
      data: { ids: data },
    });
  },
};

export default TargetConnections;
