import React, { FunctionComponent } from "react";
import Modal from "rsuite/Modal";
import { useDispatch, useSelector } from "react-redux";
import { IRoot } from "../redux/models";
import Button from "rsuite/Button";
import { TOGGLE_CONFIRM_MODAL } from "../redux/actions";
import { confirmModalState } from "../redux/states";

const ConfirmModal: FunctionComponent = () => {
  const confirmModalData = useSelector((state: IRoot) => state.confirmModal);
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch({ type: TOGGLE_CONFIRM_MODAL, payload: confirmModalState });
    confirmModalData.callback();
  };
  const handleClose = () => {
    dispatch({ type: TOGGLE_CONFIRM_MODAL, payload: confirmModalState });
  };

  return (
    <>
      <Modal open={confirmModalData.open} onClose={handleClose}>
        {/*<Modal.Body>*/}
        <div style={{ textAlign: "center" }}>
          {typeof confirmModalData.question === "string" ? (
            <p
              style={{
                fontSize: "1.1rem",
                color: "rgba(19, 19, 21, 0.5)",
                fontWeight: 500,
              }}>
              {confirmModalData.question}
            </p>
          ) : (
            <>{confirmModalData.question}</>
          )}
        </div>
        <br />
        {/*</Modal.Body>*/}

        <Modal.Footer>
          <Button appearance={"ghost"} onClick={handleClose}>
            Anuluj
          </Button>
          <Button appearance={"primary"} onClick={handleConfirm}>
            Potwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
