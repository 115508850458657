import React, { FunctionComponent, useEffect, useState } from "react";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { useParams } from "react-router-dom";
import ReportTemplateConnection from "../../../../utils/connections/reportTemplate";
import { ISelectViewResponse } from "../../../../utils/models";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import AsyncReportResults from "./AsyncReportResults";
import { handleToast } from "../../../../utils/helpers";
import GeneralReportFilter from "./filters/GeneralReportFilter";
import MssAvailableReportFilter, {
  MssAvailableReportFilterType,
} from "./filters/MssAvailableReportFilter";
import { Col, Panel, Row, SelectPicker } from "rsuite";
import _ from "lodash";
import dayjs from "dayjs";
import LocationReportFilter from "./filters/LocationReportFilter";
const LocationReportFilterType = 3;
export interface IReportGenerateData {
  templateId: string;
  startDate: string;
  endDate: string;
  projectId: string;
  format: "xlsx" | "csv";
  type: number;
  templateOptions: any;
  reportSettings: any;
}

interface IViewData {
  templates: any;
  templateTypes: ISelectViewResponse;
  asyncTypes: number[];
}

const Reports: FunctionComponent = () => {
  const { id, reportType } = useParams<{ id: string; reportType: string }>();
  const defaultState: IReportGenerateData = {
    templateOptions: [],
    templateId: "",
    startDate: "",
    endDate: "",
    projectId: id,
    reportSettings: null,
    format: "xlsx",
    type: reportType ? parseInt(reportType) : 0,
  };
  const [state, setState] = useState<IReportGenerateData>(defaultState);
  const [form, setForm] = useState<IViewData | null>(null);

  const [reloadReports, setReloadReports] = useState<boolean>(false);

  useEffect(() => {
    ReportTemplateConnection.getGenerateReportViewData(id).then((response) => {
      setForm(response.data);
    });
  }, []);

  if (form === null) return <Spinner />;

  const handleSubmit = () => {
    if (form.asyncTypes.includes(state.type)) {
      handleToast(ReportTemplateConnection.generateAsyncReport(state)).then(
        () => {
          setReloadReports(true);
        }
      );
      return true;
    }

    const selectedType = getSelectedTypeData();
    const dateTimeNow = dayjs().format("YYYY-MM-DD");
    let fileName = "";
    fileName += selectedType !== undefined ? selectedType.name : "raport";
    fileName += " " + dateTimeNow;
    fileName += "." + state.format;
    ReportTemplateConnection.getFile(fileName, {
      ...state,
      type: parseInt(state.type.toString()),
    });
  };

  const getSelectedTypeData = (): any => {
    return form.templateTypes.options.find((item) => item.id === state.type);
  };

  const getReportFilter = () => {
    if (state.type === 0) return <></>;
    switch (state.type) {
      case MssAvailableReportFilterType:
        return (
          <MssAvailableReportFilter
            onSubmit={(reportSettings) => {
              const _state = _.cloneDeep(state);
              _state.reportSettings = reportSettings;
              _state.endDate = dayjs(reportSettings.dateTo).format(
                "YYYY-MM-DD"
              );
              _state.startDate = dayjs(reportSettings.dateFrom).format(
                "YYYY-MM-DD"
              );
              setState(_state);

              handleToast(
                ReportTemplateConnection.generateAsyncReport(_state)
              ).then(() => {
                setReloadReports(true);
              });
            }}
          />
        );
      case LocationReportFilterType:
        return (
          <>
            <LocationReportFilter
              state={state}
              setState={setState}
              handleSubmit={handleSubmit}
            />
          </>
        );
      default:
        return (
          <GeneralReportFilter
            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
          />
        );
    }
  };

  return (
    <>
      <Panel shaded style={{ overflow: "auto" }}>
        <Row>
          <Col xs={24} md={6}>
            <div>
              <label>Wybierz typ raportu</label>
              <SelectPicker
                block
                cleanable={false}
                value={parseInt(state.type.toString())}
                data={form.templateTypes.options}
                labelKey={"name"}
                valueKey={"id"}
                onChange={(selected) => {
                  setState((s) => ({
                    ...s,
                    type: Number(selected),
                  }));
                }}
              />
            </div>
            <SeparatorEmpty size={2} />
          </Col>
        </Row>
        {getReportFilter()}
      </Panel>

      <SeparatorEmpty />

      {form.asyncTypes.includes(state.type) && (
        <AsyncReportResults
          reportType={state.type}
          setReloadReports={setReloadReports}
          reloadReports={reloadReports}
        />
      )}
    </>
  );
};

export default Reports;
