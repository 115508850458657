import Spinner from "global/atoms/Spinner/Spinner";
import React, { FunctionComponent, useEffect, useState } from "react";
import ReportTemplateConnection from "utils/connections/reportTemplate";
import { ISelect } from "utils/models";
import BaseTemplate, {
  columnsDataOptionsType,
  IBaseTemplateState,
} from "./BaseTemplate";

const LocationsTemplate: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const getSellerOptions = (nipRequired = true): columnsDataOptionsType => {
    const options = [
      {
        name: "name",
        label: "Nazwa",
        active: false,
      },
      {
        name: "street",
        label: "Ulica",
        active: false,
      },
      {
        name: "houseNumber",
        label: "Numer budynku",
        active: false,
      },
      {
        name: "apartmentNumber",
        label: "Numer mieszkania",
        active: false,
      },
      {
        name: "postcode",
        label: "Kod pocztowy",
        active: false,
      },
      {
        name: "city",
        label: "Miasto",
        active: false,
      },
      {
        name: "community",
        label: "Gmina",
        active: false,
      },
      {
        name: "district",
        label: "Powiat",
        active: false,
      },
      {
        name: "voivodeship",
        label: "Województwo",
        active: false,
      },
    ];

    if (nipRequired) {
      options.push({
        name: "nip",
        label: "Nip",
        active: false,
      });
    }
    return options;
  };
  const template = {
    templateName: "",
    columnsData: [
      {
        label: "Dane punktu",
        name: "location",
        active: false,
        options: [
          {
            name: "id",
            label: "Id lokalizacji",
            active: false,
          },
          {
            name: "code",
            label: "Kod lokalizacji",
            active: false,
          },
          {
            name: "location_increment_number",
            label: "Nr porządkowy lokalizacji",
            active: false,
          },
          {
            name: "customer_code",
            label: "Kod lokalizacji (klienta)",
            active: false,
          },
          {
            name: "network",
            label: "Sieć",
            active: false,
          },
          {
            name: "type",
            label: "Typ lokalizacji",
            active: false,
          },
          {
            name: "createdAt",
            label: "Data utworzenia",
            active: false,
          },
        ],
      },
      {
        label: "Dane nabywcy",
        name: "payer",
        active: false,
        options: getSellerOptions(true),
      },
      {
        label: "Dane odbiorcy",
        name: "recipient",
        active: false,
        options: getSellerOptions(true),
      },
      {
        label: "Sekcja Pytania",
        name: "locationQuestionSection",
        active: true,
        options: [
          {
            name: "locationQuestion",
            label: "Pytania do lokalizacji",
            active: false,
          },
        ],
      },
    ],
  };

  const [state, setState] = useState<IBaseTemplateState>(template);
  const [formState, setFormState] = useState<IBaseTemplateState>(template);

  useEffect(() => {
    ReportTemplateConnection.getLocationTemplateForm().then((result) => {
      const options =
        result.data.roles?.options.map((r: ISelect) => ({
          name: r.name,
          label: r.name,
          active: false,
        })) ?? [];

      template.columnsData[0].options = [
        ...template.columnsData[0].options,
        ...options,
        ...getSellerOptions(),
      ];
      setFormState(template);
      setLoading(false);
    });
  }, []);

  if (loading || !formState) return <Spinner />;

  return (
    <>
      <BaseTemplate
        state={state}
        setState={setState}
        type={3}
        sortable
        initialState={formState}
      />
    </>
  );
};

export default LocationsTemplate;
