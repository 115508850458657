import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateChain } from "utils/models";
import { useHistory, useParams } from "react-router-dom";
import ChainsConnection from "utils/connections/chains";
import { handleToast } from "utils/helpers";
import { createChainState } from "utils/states";
import { useDispatch } from "react-redux";
import ChainsUpdate from "global/form/ChainsUpdate";
import Spinner from "global/atoms/Spinner/Spinner";

interface IChainsEdit {}

const ChainsEdit: FunctionComponent<IChainsEdit> = () => {
  const [data, setData] = useState<ICreateChain>(createChainState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | "loading">("loading");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    ChainsConnection.getChainsFormEdit(id).then((data) => {
      setData({
        name: data.data.model.name,
      });
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(ChainsConnection.updateChain(id, data), setErrors).then(() =>
      history.push("/datasets/networks")
    );
  };

  if (form === "loading") return <Spinner />;

  return (
    <ChainsUpdate
      handleSubmit={handleSubmit}
      data={data}
      setData={setData}
      errors={errors}
      form={form}
    />
  );
};

export default ChainsEdit;
