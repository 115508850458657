import { IRouteEntity } from "../../utils/models";

import RegionsList from "../../views/regions/components/RegionsList";
import RegionEdit from "../../views/regions/components/RegionsEdit";

const routes: IRouteEntity[] = [
  { path: "/datasets/regions", breadcrumb: "Regiony", Component: RegionsList },
  {
    path: "/datasets/regions/add",
    breadcrumb: "Dodaj region",
    Component: RegionEdit,
  },
  {
    path: "/datasets/regions/:regionId/edit",
    breadcrumb: "Edycja region",
    Component: RegionEdit,
  },
];

export default routes;
