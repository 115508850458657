import React from "react";
import { FCC } from "utils/models";
import Notification from "rsuite/esm/Notification";

interface IComment {
  author: string;
  date: string;
  questionName?: string;
  activityName?: string;
  content: string;
}

const Comment: FCC<IComment> = (props) => {
  return (
    <Notification
      style={{ width: "99.8%", background: "#fbfbfb" }}
      header={
        <small style={{ fontSize: "65%" }}>
          {props.author} ({props.date})
          {props.activityName && <div>Aktywność: {props.activityName}</div>}
          {props.questionName && <div>Pytanie: {props.questionName}</div>}
        </small>
      }>
      <div style={{ margin: "0 10px 0 10px" }}>{props.content}</div>
    </Notification>
  );
};

export default Comment;
