import React, { FunctionComponent } from "react";
import { useDrop } from "react-dnd";
import { IDndItem, detailedScaleVal } from "./BoardTable";
import _ from "lodash";
import dayjs from "dayjs";
import Tile from "./Tile";
import { IDay } from "../ScheduleViewBoard";
import TileContextMenu, { EnumScope } from "./TileContextMenu";

interface ITileSocket {
  scale: number;
  dndItem: IDndItem;
  dataModified: (data: IDay[]) => void;
  data: IDay[];
  dataRows: React.MutableRefObject<{ [dataId: string]: number }>;
  triggerReload: () => void;
  userId: string;
}

const TileSocket: FunctionComponent<ITileSocket> = (props) => {
  const [collectionProps, drop] = useDrop<
    IDndItem,
    unknown,
    { isOver: boolean }
  >({
    accept: "visit",
    drop: (item) => tileOnDrop(item, props.dndItem),
    collect: (monitor) => {
      return { isOver: monitor.isOver() };
    },
  });

  const tileOnDrop = (item: IDndItem, dropItem: IDndItem) => {
    const dataCloned = _.cloneDeep(props.data);
    const itemIndex = dataCloned.findIndex((d) => d.id === item.id);

    // drop area earlier than today
    if (dayjs(dropItem.date).isBefore(dayjs(), "day")) {
      alert("Nie można przypisać wizyty do przeszłości");
      return undefined;
    }

    // row contains tile with the same location
    // if (
    //   props.data
    //     .filter((d) => dropItem.date.isSame(d.date, "day") && d.id !== item.id)
    //     .find((d) => d.locationId === item.location?.id) !== undefined
    // ) {
    //   alert("Ta lokaliacja jest już przypisana w tym dniu");
    //   return undefined;
    // }

    // remove row ref for entire column (date)
    props.dataRows.current[dataCloned[itemIndex].id] = undefined;
    dataCloned.every((d) => {
      if (dayjs(d.date).isSame(dropItem.date, "day")) {
        props.dataRows.current[d.id] === undefined;
      }
    });

    // set sort for entire column (date)
    let tmp_sort_val: null | number = null;

    const tmp = dataCloned
      .filter((d) => dayjs(d.date).isSame(dropItem.date, "day"))
      .sort((a, b) => a.order - b.order);

    tmp.every((d) => {
      if (tmp_sort_val === null && d.id === dropItem.id) {
        tmp_sort_val = d.order;
      }
      if (tmp_sort_val !== null) {
        const index = dataCloned.findIndex((_d) => _d.id === d.id);
        dataCloned[index].order += 1;
      }
      return true;
    });

    dataCloned[itemIndex].date = dropItem.date.toDate(); // new date
    dataCloned[itemIndex].order =
      tmp_sort_val ?? (tmp.length === 0 ? 0 : tmp.pop().order + 1); // new sort value

    props.dataModified(dataCloned);
  };

  return (
    <td
      ref={drop}
      className={`td-week-${props.dndItem.date.week() % 2} ${
        collectionProps.isOver ? "over" : ""
      }`}>
      {props.dndItem.id && (
        <Tile
          scale={props.scale}
          data={props.data}
          dataModified={props.dataModified}
          dndItem={props.dndItem}
          triggerReload={props.triggerReload}
          userId={props.userId}
        />
      )}
      {!props.dndItem.id && props.scale === detailedScaleVal && (
        <TileContextMenu
          triggerReload={props.triggerReload}
          userId={props.userId}
          scope={EnumScope.CELL}
          data={props.data}
          dataModified={props.dataModified}
          date={props.dndItem.date}
          item={props.dndItem}>
          <div className={"empty-cell"} />
        </TileContextMenu>
      )}
    </td>
  );
};

export default TileSocket;
