// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { MarkerClustererOptions } from "@googlemaps/markerclusterer/dist/markerclusterer";
import { IPoint } from "./Map";
import { parseMarkerOptions } from "./Marker";
import { Cluster } from "@googlemaps/markerclusterer/dist/cluster";
import { ClusterMarkerRenderer } from "./ClusterMarkerRenderer";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "./MapWrapper";

interface IClusterMarker {
  map: google.maps.Map;
  points: Array<IPoint>;
  onPointClick?: (p: IPoint) => void;
  onClusterClick?: (
    pos: google.maps.LatLngLiteral,
    points: Array<IPoint>
  ) => void;
  clustersCreatedCallback?: (cluster: MarkerClusterer) => void;
}

const ClusterMarkers: FunctionComponent<IClusterMarker> = (props) => {
  const [clusters, setClusters] = useState<MarkerClusterer | null>(null);
  const [markers, setMarkers] = useState<Array<google.maps.Marker>>([]);
  const [google, setGoogle] = useState(null);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  useEffect(() => {
    if (!google) return;
    // console.info('cluster markers', props.points);

    const tmp: Array<google.maps.Marker> = [];
    props.points.forEach((point) => {
      try {
        // @ts-ignore
        const m = new google.maps.Marker(parseMarkerOptions(point, props.map));
        // @ts-ignore
        if (props.onPointClick)
          m.addListener("click", () => props.onPointClick(point));
        // @ts-ignore
        m.pointId = point.id;
        tmp.push(m);
      } catch (err) {
        //
        console.info("errrr", err);
      }
    });
    setMarkers(tmp);
  }, [google]);

  const onClusterClickHandler = (
    event: google.maps.MapMouseEvent,
    cluster: Cluster
  ): void => {
    const clusterPoints: Array<IPoint> = [];
    cluster.markers?.forEach((marker) => {
      // @ts-ignore
      const tmp = props.points.find((point) => point.id === marker.pointId);
      if (tmp) clusterPoints.push(tmp);
    });

    if (props.onClusterClick)
      props.onClusterClick(
        {
          lat: cluster.position.lat(),
          lng: cluster.position.lng(),
        },
        clusterPoints
      );
  };

  useEffect(() => {
    if (markers.length > 0) {
      const options: MarkerClustererOptions = {
        map: props.map,
        markers: markers,
        onClusterClick: onClusterClickHandler,
        renderer: new ClusterMarkerRenderer(),
      };
      const cluster = new MarkerClusterer(options);
      setClusters(cluster);
    }
  }, [markers]);

  useEffect(() => {
    if (clusters && props.clustersCreatedCallback)
      props.clustersCreatedCallback(clusters);
  }, [clusters]);

  return null;
};

export default ClusterMarkers;
