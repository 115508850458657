import { FunctionComponent, useEffect, useState } from "react";

interface IInfoWindow {
  options: google.maps.InfoWindowOptions;
  open: boolean;
  map: google.maps.Map;
}
const InfoWindow: FunctionComponent<IInfoWindow> = (props) => {
  const [window, setWindow] = useState<google.maps.InfoWindow>();
  // @ts-ignore
  // const google = window.google = window.google ? window.google : {}

  useEffect(() => {
    if (!window) setWindow(new google.maps.InfoWindow(props.options));
  }, []);

  useEffect(() => {
    if (window) {
      window.setOptions(props.options);
      if (props.open) window.open({ map: props.map });
    }
  }, [props.options, window]);

  useEffect(() => {
    if (!window) return;
    if (props.open) window.open({ map: props.map });
    else window.close();
  }, [props.open, window]);

  return null;
};

export default InfoWindow;
