import React, { FunctionComponent, useEffect, useState } from "react";
import { IAddTask, ITimeTableState } from "utils/models";
import {
  createTaskState,
  createTimeTableState,
  manualSettingsState,
  recurringSettingsState,
  singleSettingsState,
} from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import TasksConnection from "utils/connections/tasks";
import { handleToast } from "utils/helpers";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import FormTask from "./FormTask";
import { SET_BREADCRUMB } from "redux/actions";

interface IEditTask {}

const EditTask: FunctionComponent<IEditTask> = () => {
  const [data, setData] = useState<IAddTask>(createTaskState);
  const [form, setForm] = useState<any | "loading">("loading");
  const [errors, setErrors] = useState<any>(null);
  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_BREADCRUMB, payload: { taskName: "..." } });
    TasksConnection.getFormEditTask(id, taskId).then((data) => {
      setForm(data.data);
      data.data?.header &&
        dispatch({ type: "SET_HEADER", payload: data.data.header });
      dispatch({
        type: SET_BREADCRUMB,
        payload: { taskName: data.data.model.name },
      });

      let timeTableState: ITimeTableState;
      if (data.data.timeTableModel === null) {
        timeTableState = createTimeTableState;
      } else {
        timeTableState = {
          ...createTimeTableState,
          recurringSettings:
            data.data.timeTableModel.recurringSettings.length !== 0
              ? data.data.timeTableModel.recurringSettings
              : [recurringSettingsState],
          manualSettings:
            data.data.timeTableModel.manualSettings ?? manualSettingsState,
          singleSettings:
            data.data.timeTableModel.singleSettings ?? singleSettingsState,
          type: data.data.timeTableModel.type,
          isApproved: data.data.timeTableModel.isApproved,
        };
      }

      const createTaskStateFilled: IAddTask = {
        description: data.data.model.description,
        name: data.data.model.name,
        taskStatusId: data.data.model.taskStatus.id,
        expirationDays: data.data.model.expirationDays,
        isExpirationEndOfWeek: data.data.model.isExpirationEndOfWeek,
        endDate: data.data.model.endDate
          ? new Date(data.data.model.endDate)
          : undefined,
        scheduleDate: data.data.model.scheduleDate
          ? new Date(data.data.model.scheduleDate)
          : undefined,
        timeTable: timeTableState,
      };

      setData(createTaskStateFilled);
    });
  }, []);
  const handleSubmit = () => {
    const dataClone = { ...data };
    const expirationDays: any = dataClone.expirationDays;
    dataClone.expirationDays = parseInt(expirationDays);

    handleToast(
      TasksConnection.updateTaskForProject(id, taskId, {
        ...dataClone,
      }),
      setErrors
    ).then(() => {
      redirectTasksList();
    });
  };
  useEffect(() => {
    if (data.isExpirationEndOfWeek) {
      setData({ ...data, expirationDays: 0 });
    }
  }, [data.isExpirationEndOfWeek]);
  const redirectTasksList = () => history.push(`/projects/${id}/tasks`);

  if (form === "loading") return <Spinner />;
  return (
    <>
      <FormTask
        data={data}
        setData={setData}
        form={form}
        errors={errors}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default EditTask;
