import {
  IApp,
  ILoading,
  IMenu,
  IHeader,
  IFilter,
  IConfirmModal,
  ILoadingScreen,
  IActivity,
} from "./models";

export const appState: IApp = {
  logged: undefined,
  tokenExpired: false,
  responseErrorStatus: null,
};

export const loadingState: ILoading = {
  isLoading: false,
};

export const menuState: IMenu = {
  id: "",
  name: "",
  locale: "",
  positions: [],
  logo: "",
};

export const headerState: IHeader = {
  name: "",
  subName: "",
  nameUrl: "",
  subNameUrl: "",
};

export const filterState: IFilter = {
  handleToggleFilters: null,
};

export const confirmModalState: IConfirmModal = {
  open: false,
  question: "",
  callback: null,
};

export const loadingScreenState: ILoadingScreen = {
  show: false,
};

export const activityState: IActivity = {
  activeEditId: null,
};
