import React, { FunctionComponent, useState } from "react";
import Pagination from "global/pagination/Pagination";
import TranslationsConnection from "utils/connections/translations";
import { Table } from "rsuite";
import settings from "assets/svg/settings.svg";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ButtonAddNew from "global/atoms/ButtonAddNew";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import { ButtonSort } from "../../../../utils/sortHelper";
import { _paginate30 } from "utils/states";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ITranslationsList {}

const TranslationsList: FunctionComponent<ITranslationsList> = () => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<any>({
    locale: "",
    field: "",
    text: "",
    hint: "",
    ..._paginate30,
  });
  const history = useHistory();

  const handleEdit = (event: any) => {
      history.push(
        `/superadmin/translations/${event.currentTarget.dataset.id}/edit`
      );
    },
    handleRowClick = (data: any) => {
      history.push(`/superadmin/translations/${data.id}/edit`);
    };

  return (
    <>
      <HeaderButtons>
        <ButtonAddNew />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            onRowClick={handleRowClick}>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                <ButtonSort
                  dataName={"hint"}
                  filters={filters}
                  setFilters={setFilters}>
                  Podpowiedź
                </ButtonSort>
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="hint" />
            </Table.Column>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell depth={0} width={0}>
                Akcje
              </Table.HeaderCell>
              <Table.Cell depth={0} width={0}>
                {(rowData) => (
                  <img
                    src={settings}
                    style={{ cursor: "pointer" }}
                    alt={"Idź do"}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={TranslationsConnection.getTranslations}
        formGet={TranslationsConnection.getFiltersTranslationsList}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={[]}
        setResultData={setData}
        filterStorageKey={"superadminTranslationsList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locale",
            label: "Język (kod)",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "field",
            label: "Pole",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "text",
            label: "Treść",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "hint",
            label: "Podpowiedź",
          },
        ]}
      />
    </>
  );
};

export default TranslationsList;
