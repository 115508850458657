import React, { FunctionComponent, useEffect, useState } from "react";
import {
  IProjectImportEntity,
  IProjectImportRequestData,
  ISelect,
} from "../../../../utils/models";
import { projectImportRequestDataDefault } from "../../../../utils/states";
import ScColumn from "../../../../global/table/ScColumn";
import { Badge, Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import ProjectImports from "../../../../utils/connections/imports";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import ImportsModalAdd from "./ImportsModalAdd";
import IconButton from "../../../../global/atoms/IconButton";
import download from "downloadjs";
import commonStyles from "../../../../global/common.module.scss";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { useParams } from "react-router-dom";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import ExportModalAdd from "./ExportModalAdd";
import { getProjectIdFromUrl } from "utils/helpers";
import dayjs from "dayjs";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { confirmModalCallback } from "../../../../redux/store";
import ToastNotificationPush, {
  ToastTypes,
} from "../../../../global/ToastNotification";
import { RowDataType } from "rsuite/esm/Table";
import arrowRightBig from "../../../../../src/assets/svg/arrow_right_big.svg";

export const IMPORT_TIMETABLE_SHORTCUT = "timetable-shortcut";
export const EXPORT_TIMETABLE_SHORTCUT = "export-timetable-shortcut";

interface IFormData {
  import_types: ISelect;
  import_actions: ISelect;
  import_statuses: ISelect;
  disabledElements: Array<string>;
}

const ImportsList: FunctionComponent = () => {
  const INTERVAL_UPDATE_VALUE = 60000; // ms
  const [importData, setImportData] = useState<{
    count: number;
    data: IProjectImportEntity[];
    exports: any;
  } | null>(null);

  const { shortcut } = useParams<{ shortcut: string }>();
  const [requestData, setRequestData] = useState<IProjectImportRequestData>(
    projectImportRequestDataDefault
  );
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<IFormData>();
  const [modalImportOpen, setModalImportOpen] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  useEffect(() => {
    const intervalUpdate = setInterval(() => {
      setTriggerUpdate(Date.now());
    }, INTERVAL_UPDATE_VALUE);

    return () => {
      clearInterval(intervalUpdate);
    };
  }, []);

  useEffect(() => {
    if (formData && shortcut === IMPORT_TIMETABLE_SHORTCUT) {
      setModalImportOpen(true);
    }
    if (formData && shortcut === EXPORT_TIMETABLE_SHORTCUT) {
      setModalExportOpen(true);
    }
  }, [formData]);

  const handleSourceDownload = (projectImportId: string) => {
    ProjectImports.getFileSourceBlob(projectImportId).then((result) => {
      download(result.data, result.headers["x-filename"] ?? null);
    });
  };

  const handleResultDownload = (projectImportId: string) => {
    ProjectImports.getFileResultBlob(projectImportId)
      .then((result) => {
        download(result.data, result.headers["x-filename"] ?? null);
      })
      .catch(() => {
        ToastNotificationPush(
          ToastTypes.error,
          "Błąd",
          "Brak pliku lub plik wyniku nie został utworzony"
        );
      });
  };
  const handleImportClear = (projectImportId: string) => {
    ProjectImports.setImportError(projectImportId).then((result) => {
      if (result.data.status == 200) {
        setTriggerUpdate(Date.now());
        ToastNotificationPush(
          ToastTypes.success,
          result?.data?.message ?? 'Proces importu oznaczono jako "Błąd"'
        );
      }
    });
  };
  const getImportName = (type: string) => {
    return (
      formData?.import_types?.options.find((v) => v.id === type ?? "-")?.name ??
      "-"
    );
  };

  const timeToEnableTrash = 10 * 60; //10 minutes
  const isClearDisabled = (rowData) => {
    const condition =
      timeToEnableTrash + rowData.createdAt < new Date().getTime() / 1000 &&
      ["blocked", "processing", "new"].includes(rowData.importStatus);
    return !condition;
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item
            onClick={() => setModalImportOpen(true)}
            icon={<FileUploadIcon />}>
            Nowy import
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setModalExportOpen(true)}
            // @ts-ignore
            disabled={
              formData === null ||
              (formData?.disabledElements ?? []).includes("export")
            }
            icon={<FileDownloadIcon />}>
            Nowy export
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>
      <PaginatedTableViewWrapper
        table={
          <Table fillHeight loading={loading} data={importData?.data ?? []}>
            <ScColumn align="left" flexGrow={1}>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => getImportName(rowData.importType)}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" flexGrow={1}>
              <Table.HeaderCell>Akcja</Table.HeaderCell>
              <Table.Cell>
                {(rowData: RowDataType<IProjectImportEntity>) => {
                  return (
                    formData?.import_actions?.options.find(
                      (v) => v.id === rowData.importAction ?? "-"
                    )?.name ?? "-"
                  );
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" minWidth={100} flexGrow={1}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  // @ts-ignore
                  return (
                    formData?.import_statuses?.options.find(
                      (v) => v.id === rowData.importStatus ?? "-"
                    )?.name ?? "-"
                  );
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" flexGrow={1}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell align={"center"}>
                {(rowData) => {
                  return `${rowData.rowsImported}/${rowData.rowsTotal}`;
                }}
              </Table.Cell>
            </ScColumn>
            <ScColumn align="left" flexGrow={1}>
              <Table.HeaderCell>Wykonał</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return rowData.asUser;
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" minWidth={100} flexGrow={1}>
              <Table.HeaderCell>Dodano</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <>
                      {dayjs(rowData.createdAt * 1000).format("YYYY-MM-DD")}
                      <div className={commonStyles.smallMuted}>
                        {dayjs(rowData.createdAt * 1000).format("HH:mm:ss")}
                      </div>
                    </>
                  );
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="left" minWidth={100} flexGrow={1}>
              <Table.HeaderCell>Zaktualizowano</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <>
                      {dayjs(rowData.updatedAt * 1000).format("YYYY-MM-DD")}
                      <div className={commonStyles.smallMuted}>
                        {dayjs(rowData.updatedAt * 1000).format("HH:mm:ss")}
                      </div>
                    </>
                  );
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="right" flexGrow={1}>
              <Table.HeaderCell>Pobierz</Table.HeaderCell>
              <Table.Cell align={"right"}>
                {(rowData) => (
                  <ActionsContainer gapSize={0}>
                    <IconButton
                      inline={true}
                      onClick={() => handleSourceDownload(rowData.id)}
                      icon={"download"}
                    />
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        width: "40px",
                      }}
                      onClick={() => handleResultDownload(rowData.id)}>
                      {!!rowData.rowsErrorCount && (
                        <Badge
                          style={{ display: "inline-block" }}
                          color={"orange"}
                          content={rowData.rowsErrorCount}
                        />
                      )}
                      <img alt="getResult" src={arrowRightBig} />
                    </div>
                    <IconButton
                      disabled={isClearDisabled(rowData)}
                      inline={true}
                      onClick={() => {
                        confirmModalCallback(
                          <>
                            <div>
                              Operacja &quot;{getImportName(rowData.importType)}
                              &quot; blokuje inne importy w projekcie i zostanie
                              oznaczony jako błędny!
                            </div>

                            <div
                              style={{
                                fontWeight: "bold",
                                paddingTop: "10px",
                              }}>
                              {" "}
                              Czy chcesz oznaczyć import jako błędny?
                            </div>
                          </>,
                          () => {
                            handleImportClear(rowData.id);
                          }
                        );
                      }}
                      icon={"trash"}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </ScColumn>
          </Table>
        }
        pagination={
          <Pagination
            count={importData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      {formData && (
        <>
          <ImportsModalAdd
            // @ts-ignore
            formData={formData}
            open={modalImportOpen}
            setOpen={setModalImportOpen}
            addedCallback={() => setTriggerUpdate(Date.now())}
          />
          <ExportModalAdd
            open={modalExportOpen}
            setOpen={setModalExportOpen}
            exportList={importData?.exports}
          />
        </>
      )}

      <UniversalFilters
        load={ProjectImports.getAll}
        formGet={ProjectImports.getForm}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={projectImportRequestDataDefault}
        setResultData={setImportData}
        getFormCallback={(_form) => setFormData(_form)}
        filterStorageKey={`projectImportList_${getProjectIdFromUrl()}`}
        triggerLoad={triggerUpdate}
        elements={[
          {
            type: FILTER_SELECT,
            stateKey: "importType",
            label: "Typ importu",
            formKey: "import_types",
          },
          {
            type: FILTER_SELECT,
            stateKey: "importStatus",
            label: "Status",
            formKey: "import_statuses",
          },
          {
            type: FILTER_SELECT,
            stateKey: "importAction",
            label: "Akcja",
            formKey: "import_actions",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateFrom",
            outputFormat: "yyyy-MM-dd",
            label: "Data dodania od",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateTo",
            outputFormat: "yyyy-MM-dd",
            label: "Data dodania do",
          },
        ]}
      />
    </>
  );
};

export default ImportsList;
