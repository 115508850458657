import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.scss";
import { Loader, Tooltip, Whisper } from "rsuite";
import ItemsList from "./ItemsList";
import {
  TypeDoubleSidePickerItems,
  TypeDoubleSidePickerItemType,
} from "../../../utils/types";
import Section from "../section/Section";
import _ from "lodash";
import DragableIcon from "@rsuite/icons/Dragable";

interface IDoubleSidePicker {
  heading?: string;
  emptyMessageAssigned: string;
  emptyMessageNotAssigned: string;
  assigned: TypeDoubleSidePickerItems;
  unassigned: TypeDoubleSidePickerItems;
  onChange: (
    assignedIds: Array<string>,
    unAssignedIds: Array<string>,
    callback?: () => void
  ) => void;
  form?: any;
  style?: CSSProperties;
  itemType: TypeDoubleSidePickerItemType;
  pickedItemCallback?: (itemId: string) => void;
  clearPickedItemTrigger?: number;
  disabled?: boolean;
  filtersHidden?: boolean;
  appendElement?: JSX.Element;
  loading?: boolean;
  actionOnAssigned?: (event) => void;
  onlyUsersFromLocations?: boolean;
  onlyCurrentUser?: boolean;
}

export interface IChecked {
  assigned: Array<string>;
  unassigned: Array<string>;
}

export const checkedInitValue = {
  assigned: [],
  unassigned: [],
};

const DoubleSidePicker: FunctionComponent<IDoubleSidePicker> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [checked, setChecked] = useState<IChecked>(checkedInitValue);
  const [pickedId, setPickedId] = useState<string | null>(null);
  const data = {
    assigned: props.assigned,
    unassigned: props.unassigned,
  };
  const [triggerFilter, setTriggerFilter] = useState(0);

  useEffect(() => {
    if (pickedId && props.pickedItemCallback)
      props.pickedItemCallback(pickedId);
  }, [pickedId]);

  if (props.clearPickedItemTrigger !== undefined) {
    useEffect(() => {
      // @ts-ignore
      if (props.clearPickedItemTrigger > 0) {
        setPickedId(null);
      }
    }, [props.clearPickedItemTrigger]);
  }

  const handleToggleAssigned = (itemId: string) => {
    if (checked.assigned.includes(itemId)) {
      setChecked({
        ...checked,
        assigned: checked.assigned.filter((item) => item !== itemId),
      });
    } else {
      setChecked({ ...checked, assigned: [...checked.assigned, itemId] });
    }
  };

  const handleToggleAllAssigned = (ids: Array<string>) => {
    if (checked.assigned.length) {
      setChecked({
        ...checked,
        assigned: [],
      });
    } else {
      setChecked({ ...checked, assigned: ids });
    }
  };

  const handleToggleUnassigned = (itemId: string) => {
    if (checked.unassigned.includes(itemId)) {
      setChecked({
        ...checked,
        unassigned: checked.unassigned.filter((item) => item !== itemId),
      });
    } else {
      setChecked({ ...checked, unassigned: [...checked.unassigned, itemId] });
    }
  };

  const handleToggleAllUnassigned = (ids: Array<string>) => {
    if (checked.unassigned.length) {
      setChecked({
        ...checked,
        unassigned: [],
      });
    } else {
      setChecked({ ...checked, unassigned: ids });
    }
  };

  const assignmentChange = () => {
    setProcessing(true);

    setTimeout(() => {
      const allAssignedIds = [
        ...props.assigned
          .filter((i) => !checked.assigned.includes(i.id))
          .map((i) => i.id),
        ...props.unassigned
          .filter((i) => checked.unassigned.includes(i.id))
          .map((i) => i.id),
      ];
      const allUnAssignedIds = [
        ...props.unassigned
          .filter((i) => !checked.unassigned.includes(i.id))
          .map((i) => i.id),
        ...props.assigned
          .filter((i) => checked.assigned.includes(i.id))
          .map((i) => i.id),
      ];

      props.onChange(_.uniq(allAssignedIds), _.uniq(allUnAssignedIds), () => {
        setProcessing(false);
      });
      setChecked(checkedInitValue);
      setTriggerFilter(Date.now());
      setProcessing(false);
    }, 1);
  };

  const handleSortChange = (assignedSortedData: TypeDoubleSidePickerItems) => {
    props.onChange(
      // @ts-ignore
      _.uniq(assignedSortedData.map((item) => item.id)),
      // @ts-ignore
      _.uniq(props.unassigned.map((item) => item.id))
    );
  };

  return (
    <>
      <div className={styles.grandWrapper} style={props.style ?? {}}>
        <Section title={`${props.heading ?? ""}`} titleSize={"1.1em"}>
          <div className={styles.grid}>
            <ItemsList
              actionOnAssigned={props.actionOnAssigned}
              itemType={props.itemType}
              handleToggleCheckbox={handleToggleAssigned}
              handleToggleMainCheckbox={handleToggleAllAssigned}
              checked={checked.assigned}
              setChecked={setChecked}
              data={data}
              form={props.form}
              emptyMessage={props.emptyMessageAssigned}
              pickedId={pickedId}
              setPickedId={setPickedId}
              dataType={"assigned"}
              triggerFilter={triggerFilter}
              onSortChange={handleSortChange}
              filtersHidden={props.filtersHidden}
              onlyUsersFromLocations={props.onlyUsersFromLocations}
              onlyCurrentUser={props.onlyCurrentUser}
            />

            {!props.disabled && (
              <>
                <div className={styles.DSPickerSelectorNav}>
                  <button
                    style={{ backgroundColor: "transparent" }}
                    type={"button"}
                    onClick={assignmentChange}
                    disabled={
                      (!checked.assigned.length &&
                        !checked.unassigned.length) ||
                      processing ||
                      props.loading
                    }>
                    {processing || props.loading ? (
                      <Loader />
                    ) : (
                      <Whisper
                        placement="top"
                        trigger="hover"
                        disabled={
                          !checked.assigned.length && !checked.unassigned.length
                        }
                        speaker={<Tooltip>Przesuń zaznaczone</Tooltip>}>
                        <DragableIcon
                          style={{
                            transform: "rotate(90deg)",
                            fontSize: "2em",
                            color: "#ffaf38",
                          }}
                        />
                      </Whisper>
                    )}
                  </button>
                </div>

                <ItemsList
                  itemType={props.itemType}
                  handleToggleCheckbox={handleToggleUnassigned}
                  handleToggleMainCheckbox={handleToggleAllUnassigned}
                  checked={checked.unassigned}
                  setChecked={setChecked}
                  data={data}
                  form={props.form}
                  emptyMessage={props.emptyMessageNotAssigned}
                  pickedId={pickedId}
                  setPickedId={setPickedId}
                  dataType={"unassigned"}
                  triggerFilter={triggerFilter}
                  filtersHidden={props.filtersHidden}
                  onlyUsersFromLocations={props.onlyUsersFromLocations}
                  onlyCurrentUser={props.onlyCurrentUser}
                />
              </>
            )}
          </div>

          {props.appendElement !== undefined && props.appendElement}
        </Section>
      </div>
    </>
  );
};
export default DoubleSidePicker;
