import React, { FunctionComponent, Ref, useEffect } from "react";
import { IAddTask } from "utils/models";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";

import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Col from "rsuite/Col";
import Row from "rsuite/Row";
import Form from "rsuite/Form";
import Input from "rsuite/Input";
import InputNumber from "rsuite/InputNumber";
import SelectPicker from "rsuite/SelectPicker";
import Toggle from "rsuite/Toggle";
import Button from "rsuite/Button";
import { DatePicker } from "rsuite";
import dayjs from "dayjs";

const TextArea = React.forwardRef((props, ref: Ref<HTMLTextAreaElement>) => (
  <Input {...props} ref={ref} as="textarea" />
));

interface IFormTask {
  data: IAddTask;
  setData: (data: IAddTask) => void;
  onSubmit: () => void;
  errors: any;
  form: any;
}

const FormTask: FunctionComponent<IFormTask> = (props) => {
  useEffect(() => {
    if (props.data.isExpirationEndOfWeek) {
      props.setData({ ...props.data, expirationDays: 0 });
    }
  }, [props.data.isExpirationEndOfWeek]);

  return (
    <>
      <WhiteCard padding={true}>
        <Form
          onSubmit={props.onSubmit}
          fluid
          onChange={(formValue) => {
            props.setData({ ...props.data, ...formValue });
          }}>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Nazwa</Form.ControlLabel>
                <Form.Control
                  name={"name"}
                  accepter={Input}
                  value={props.data.name}
                  errorMessage={props.errors?.name}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Status zadania</Form.ControlLabel>
                <Form.Control
                  block
                  cleanable={false}
                  name={"taskStatusId"}
                  accepter={SelectPicker}
                  value={props.data.taskStatusId}
                  errorMessage={props?.errors?.taskStatusId}
                  data={props?.form?.taskStatuses?.options}
                  labelKey={"name"}
                  valueKey={"id"}
                />
              </Form.Group>
            </Col>
          </Row>
          <SeparatorEmpty />
          <Col xs={24}>
            <Form.Group>
              <Form.ControlLabel>Opis</Form.ControlLabel>
              <Form.Control
                name={"description"}
                accepter={TextArea}
                value={props.data.description}
                errorMessage={props.errors?.description}
              />
            </Form.Group>
          </Col>
          <SeparatorEmpty size={3} />
          <Col xs={12}>
            <Form.Group>
              <Form.ControlLabel>
                Zakończ wizyty na koniec tygodnia
              </Form.ControlLabel>
              <Form.Control
                style={{ width: "100%" }}
                name={"isExpirationEndOfWeek"}
                accepter={Toggle}
                checked={props.data.isExpirationEndOfWeek}
                value={props.data.isExpirationEndOfWeek}
                errorMessage={props.errors?.isExpirationEndOfWeek}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Aktywne od</Form.ControlLabel>
                <Form.Control
                  isoWeek
                  oneTap
                  placeholder={"nie wyznaczono"}
                  format={"yyyy-MM-dd"}
                  shouldDisableDate={(date) => dayjs(date).isBefore(dayjs())}
                  style={{ width: "100%" }}
                  name={"scheduleDate"}
                  accepter={DatePicker}
                  value={props.data.scheduleDate}
                  errorMessage={props.errors?.scheduleDate}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Aktywne do</Form.ControlLabel>
                <Form.Control
                  isoWeek
                  oneTap
                  placeholder={"nie wyznaczono"}
                  format={"yyyy-MM-dd"}
                  shouldDisableDate={(date) =>
                    dayjs(date).isBefore(
                      props.data.scheduleDate
                        ? dayjs(props.data.scheduleDate)
                        : dayjs()
                    )
                  }
                  style={{ width: "100%" }}
                  name={"endDate"}
                  accepter={DatePicker}
                  value={props.data.endDate}
                  errorMessage={props.errors?.endDate}
                />
              </Form.Group>
            </Col>
          </Col>
          <SeparatorEmpty />
          <Col xs={12}>
            <Form.Group>
              <Form.ControlLabel>Okres ważności [dni]</Form.ControlLabel>
              <Form.Control
                style={{ width: "100%" }}
                name={"expirationDays"}
                accepter={InputNumber}
                value={props.data.expirationDays}
                errorMessage={props.errors?.expirationDays}
                disabled={props.data.isExpirationEndOfWeek}
              />
            </Form.Group>
          </Col>
          <SeparatorEmpty />
          <ActionsContainer>
            <Button type={"submit"} appearance={"primary"}>
              Zapisz
            </Button>
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default FormTask;
