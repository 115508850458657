import React, { FunctionComponent, useEffect, useState } from "react";
import { IconButton, Progress, Table, Tooltip, Whisper } from "rsuite";
import GlobalReportsConnection from "utils/connections/globalReportsConnection";
import { handleToast } from "utils/helpers";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import download from "downloadjs";

import DrawerNewPhotoReport from "./DrawerNewPhotoReport";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface IPhotoReport {}

export enum PhotoReportFormFields {
  templateOptions = "templateOptions",
  templateId = "templateId",
  customerId = "customerId",
  projects = "projects",
  userRoles = "userRoles",
  startDate = "startDate",
  endDate = "endDate",
  networkId = "networkId",
  projectId = "projectId",
  format = "format",
  type = "type",
}

interface IList {
  id: string;
  createdDate: string;
  fileName: string;
  filePath: string;
  progress: number;
  templateName: string;
}

export interface IGeneratePhotoReportState {
  [PhotoReportFormFields.templateOptions]: [];
  [PhotoReportFormFields.templateId]: string | null;
  [PhotoReportFormFields.customerId]: string | null;
  [PhotoReportFormFields.projects]: string[] | null;
  [PhotoReportFormFields.userRoles]: string[] | null;
  [PhotoReportFormFields.startDate]: Date | null;
  [PhotoReportFormFields.endDate]: Date | null;
  [PhotoReportFormFields.networkId]: string | null;
  [PhotoReportFormFields.projectId]: string | null;
  [PhotoReportFormFields.format]: "xlsx";
  [PhotoReportFormFields.type]: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PhotoReport: FunctionComponent<IPhotoReport> = (props) => {
  const [list, setList] = useState<null | IList[]>(null);

  const loadList = () => {
    GlobalReportsConnection.photoReportList().then((res) => {
      setList(res.data);
    });
  };

  useEffect(() => {
    loadList();
    const _interval = setInterval(loadList, 10000);

    return () => {
      clearInterval(_interval);
    };
  }, []);

  const onGenerateSubmit = (generateState: IGeneratePhotoReportState) => {
    handleToast(
      GlobalReportsConnection.photoReportGenerateAsync(generateState)
    ).then((res) => {
      console.log("report generated", res.data.reportResultId);
      setList(null);
      loadList();
    });
  };

  const downloadReport = (id: string) => {
    handleToast(GlobalReportsConnection.photoReportGetBlob(id)).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
    });
  };

  return (
    <>
      <DrawerNewPhotoReport onSubmit={onGenerateSubmit} />

      <PaginatedTableViewWrapper
        beforeTableElements={
          <>
            <h5>Wygenerowane raporty</h5>
            <br />
          </>
        }
        table={
          <Table data={list ?? []} fillHeight loading={list === null}>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Nazwa pliku</Table.HeaderCell>
              <Table.Cell dataKey={"fileName"} />
            </Table.Column>

            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey={"createdDate"} />
            </Table.Column>

            <Table.Column width={300} align={"left"}>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
              <Table.Cell align={"right"}>
                {(rowData: IList) => (
                  <Progress.Line
                    percent={rowData.progress}
                    status={rowData.progress === 100 ? "success" : "active"}
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column align={"right"}>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
              <Table.Cell>
                {(rowData: IList) => (
                  <Whisper
                    placement="left"
                    speaker={<Tooltip>Pobierz plik wynikowy</Tooltip>}>
                    <IconButton
                      disabled={rowData.progress !== 100}
                      icon={<FileDownloadIcon />}
                      onClick={() => downloadReport(rowData.id)}
                    />
                  </Whisper>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={<></>}
      />
    </>
  );
};

export default PhotoReport;
