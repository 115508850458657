import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "rsuite/Modal";
import { Checkbox, DateRangePicker } from "rsuite";
import Button from "rsuite/Button";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import { handleToast } from "../../../../../utils/helpers";
import SchedulesConnection from "../../../../../utils/connections/schedules";

const { beforeToday } = DateRangePicker;

export interface IReplacedUser {
  id: string;
  roleId: string;
  endDate: string;
  startDate: string;
  userFromName: string;
  userToName: string;
}

const EditReplacement: FunctionComponent<{
  handleSave: (data: IReplacedUser) => void;
  handleClose: () => void;
  open: boolean;
  data: IReplacedUser;
  operation: string;
}> = (props) => {
  const [startDate, setStartDate] = useState(props.data.startDate);
  const [endDate, setEndDate] = useState(props.data.endDate);
  const [allProjectsState, setAllProjectState] = useState(false);

  useEffect(() => {
    if (props.open === true) {
      setStartDate(props.data.startDate);
      setEndDate(props.data.endDate);
    }
  }, [props.open]);

  const checkDates = (dates: any) => {
    setStartDate(dates[0].toISOString().slice(0, 10));
    setEndDate(dates[1].toISOString().slice(0, 10));
  };
  const handleClose = () => {
    // setStartDate(props.data.startDate);
    // setEndDate(props.data.endDate);
    props.handleClose();
  };
  const handleSave = () => {
    const sendData = {
      ...props.data,
      startDate: startDate,
      endDate: endDate,
      allProjects: allProjectsState,
      operation: props.operation,
    };
    handleToast(
      SchedulesConnection.userReplacementUpdate(props.data.id, sendData)
    ).then((request) => {
      if (request.status === 200) {
        props.handleSave(sendData);
      }
    });
  };

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {props.operation === "UPDATE"
              ? "Edycja zastępstwa"
              : "Usuń zastępstwo"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <div>
            <small>
              Zastępstwo od <strong>{props.data.startDate}</strong> do{" "}
              <strong>{props.data.endDate}</strong>
            </small>
            <div>
              <strong>{props.data.userToName}</strong> za{" "}
              <s>{props.data.userFromName}</s>
            </div>
          </div>
          <SeparatorEmpty size={1} />
          <Checkbox
            checked={allProjectsState}
            onChange={(val, checked) => setAllProjectState(checked)}>
            Zastosuj do wszystkich projektów
          </Checkbox>
          {props.operation === "UPDATE" && (
            <DateRangePicker
              style={{ display: "block" }}
              value={[new Date(startDate), new Date(endDate)]}
              onOk={checkDates}
              showWeekNumbers
              placeholder="Wybierz nowy zakres dat"
              format="yyyy-MM-dd"
              //@ts-ignore
              disabledDate={beforeToday()}
              isoWeek
            />
          )}
        </Modal.Body>
        <Modal.Footer style={{ marginTop: "10px" }}>
          <Button onClick={handleClose} appearance="subtle">
            Anuluj
          </Button>
          <Button onClick={handleSave} appearance="primary">
            {props.operation === "UPDATE" ? "Zapisz" : "Usuń"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditReplacement;
