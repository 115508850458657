import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Nav } from "rsuite";
import TargetsDefinitionsList from "./TargetsDefinitionsList";
import TargetsUsersList from "./TargetsUsersList";

const TARGETS_USERS_LIST = "TARGETS_USERS_LIST";
const TARGETS_DEFINITIONS = "TARGETS_DEFINITIONS";
const Targets: FunctionComponent = () => {
  type IViewTypes = "TARGETS_USERS_LIST" | "TARGETS_DEFINITIONS";
  const [viewType, setViewType] = useState<IViewTypes>(TARGETS_USERS_LIST);

  return (
    <>
      {" "}
      <Nav
        appearance={"subtle"}
        activeKey={viewType}
        style={{ marginBottom: "10px" }}
        onSelect={(vt) => {
          setViewType(vt);
        }}>
        <Nav.Item eventKey={TARGETS_USERS_LIST}>Poziom realizacji</Nav.Item>
        <Nav.Item eventKey={TARGETS_DEFINITIONS}>Definicje</Nav.Item>
      </Nav>
      {viewType == TARGETS_USERS_LIST && <TargetsUsersList />}
      {viewType == TARGETS_DEFINITIONS && <TargetsDefinitionsList />}
    </>
  );
};

export default Targets;
