import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRoot } from "redux/models";
import { FCC } from "./models";

interface IPermissionElementWrapper {
  name: string;
}

const PermissionElementWrapper: FCC<IPermissionElementWrapper> = (props) => {
  const [render, setRender] = useState(false);
  const disabledElements = useSelector(
    (state: IRoot) => state.disabledElements
  );

  useEffect(() => {
    const tm = setTimeout(() => {
      setRender(true);
    }, 500);
    return () => {
      clearTimeout(tm);
    };
  }, []);

  if (disabledElements?.includes(props.name) || !render) return <></>;
  return <>{props.children}</>;
};

export default PermissionElementWrapper;
