import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal, SelectPicker, TagPicker } from "rsuite";
import { SetState } from "../../../../utils/models";
import ProjectConnectionsConnection, {
  ProjectConnectionAddForm,
} from "../../../../utils/connections/projectConnectionsConnection";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";

interface ICreateProjectConnectionModal {
  onSubmit: (data: IProjectConnectionCreate) => void;
  open: boolean;
  setOpen: SetState<boolean>;
}

export interface IProjectConnectionCreate {
  projectExternalIds: Array<{ id: string }>;
  projectId: string;
}

const CreateProjectConnectionModal: FunctionComponent<
  ICreateProjectConnectionModal
> = (props) => {
  const [filterForm, setFilterForm] = useState<ProjectConnectionAddForm | null>(
    null
  );
  const stateDefault = {
    projectExternalIds: [],
    projectId: "",
  };
  const [state, setState] = useState<IProjectConnectionCreate>(stateDefault);

  useEffect(() => {
    if (!props.open) {
      setState(stateDefault);
      setFilterForm(null);
    } else {
      ProjectConnectionsConnection.filterForm().then((res) => {
        setFilterForm(res.data);
      });
    }
  }, [props.open]);

  return (
    <>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Dodaj powiązanie</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "150px" }}>
          {filterForm === null && <SpinnerSmall />}
          {filterForm && (
            <>
              <div>
                <strong>Projekt lokalny</strong>
                <SelectPicker
                  block
                  data={filterForm?.projects?.options ?? []}
                  value={state.projectId}
                  onChange={(value) =>
                    setState((s) => ({
                      ...s,
                      projectId: value ? value.toString() : "",
                    }))
                  }
                  labelKey={"name"}
                  valueKey={"id"}
                />
              </div>
              <SeparatorEmpty size={1} />
              <div>
                <strong>Projekty zewnętrzne</strong>
                <TagPicker
                  block
                  data={
                    filterForm?.external_projects?.options.map((ep) => ({
                      ...ep,
                      parsedName: `${ep.externalName} (${ep.externalId})`,
                    })) ?? []
                  }
                  value={state.projectExternalIds.map((v) => v.id)}
                  onChange={(value) =>
                    setState((s) => ({
                      ...s,
                      projectExternalIds: value
                        ? value.map((v: string) => ({ id: v }))
                        : [],
                    }))
                  }
                  labelKey={"parsedName"}
                  valueKey={"id"}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.setOpen(false)} appearance="subtle">
            Anuluj
          </Button>
          <Button
            onClick={props.onSubmit.bind(null, state)}
            disabled={!state.projectId || !state.projectExternalIds.length}
            appearance="primary">
            Utwórz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateProjectConnectionModal;
