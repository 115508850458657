import React, { FunctionComponent, useState } from "react";
import {
  IWarehouseDeliveryData,
  IWarehouseDeliveryFilters,
} from "utils/models";
import { _paginate30 } from "utils/states";
import WarehouseConnection from "utils/connections/warehouse";

import { Table } from "rsuite";

import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { getProjectIdFromUrl, handleSortColumn } from "utils/helpers";
import styles from "./styles.module.scss";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import Pagination from "../../../../global/pagination/Pagination";

interface IWarehouseList {
  chosenDelivery: Array<string>;
  setChosenDelivery: (data) => void;
  triggerLoad: number;
}

const WarehouseDeliveryList: FunctionComponent<IWarehouseList> = ({
  chosenDelivery,
  setChosenDelivery,
  triggerLoad,
}) => {
  const [data, setData] = useState<IWarehouseDeliveryData | null>(null);
  const defaultFilters = {
    brandId: "",
    itemId: "",
    itemGroupId: "",
    ..._paginate30,
  };

  const [filters, setFilters] =
    useState<IWarehouseDeliveryFilters>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      <Table
        data={data?.data ?? []}
        loading={loading}
        autoHeight
        sortColumn={filters.requestOrder.field}
        sortType={filters.requestOrder.order}
        onSortColumn={(dataKey, sortType) =>
          handleSortColumn(dataKey, sortType, setFilters)
        }>
        <Table.Column width={60} align="center">
          <Table.HeaderCell>
            <ToggleAllCheckbox
              data={data?.data}
              state={chosenDelivery}
              setState={setChosenDelivery}
              keyIdName={"id"}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <ToggleSingleCheckbox
                state={chosenDelivery}
                setState={setChosenDelivery}
                selectedId={rowData.id}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={300} align="left" sortable>
          <Table.HeaderCell> Produkt </Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Grupa</Table.HeaderCell>
          <Table.Cell dataKey="itemGroup" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>
            <div>
              <div>Przyjmujący</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell dataKey="userTo">
            {(rowData) => (
              <>
                <div>
                  {rowData.userTo}
                  <div
                    className={styles.style}
                    style={{
                      fontWeight: "bold",
                      color: rowData.roleTo.roleType.color,
                    }}>
                    {rowData.roleTo.roleName}
                  </div>
                </div>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120}>
          <Table.HeaderCell>Przychód</Table.HeaderCell>
          <Table.Cell dataKey="amount" />
        </Table.Column>
        <Table.Column width={120}>
          <Table.HeaderCell>Cena</Table.HeaderCell>
          <Table.Cell dataKey="price" />
        </Table.Column>
        <Table.Column width={120} align="left" sortable>
          <Table.HeaderCell>data</Table.HeaderCell>
          <Table.Cell dataKey="operationDate" />
        </Table.Column>
        <Table.Column width={150} align="left">
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey="status">
            {(rowData) => {
              return (
                <>
                  <div>
                    <div
                      className={styles.style_top}
                      style={{
                        fontWeight: "bold",
                        color: rowData.status.color,
                      }}>
                      {rowData.status.name}
                    </div>
                    <div className={styles.style} style={{ fontSize: "0.5em" }}>
                      {rowData.changedStatusUser ?? ""}
                    </div>
                  </div>
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>

      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={WarehouseConnection.deliveryList}
        formGet={WarehouseConnection.deliveryFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectWarehouseDelivery_${getProjectIdFromUrl()}`}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_SELECT,
            stateKey: "statusId",
            formKey: "statuses",
            label: "Status",
          },
          {
            type: FILTER_SELECT,
            stateKey: "itemId",
            formKey: "items",
            label: "Produkt",
          },
          {
            type: FILTER_SELECT,
            stateKey: "brandId",
            formKey: "brands",
            label: "Marka",
          },
          {
            type: FILTER_SELECT,
            stateKey: "groupId",
            formKey: "itemGroups",
            label: "Grupa produktu",
          },
          {
            type: FILTER_SELECT,
            label: "Przyjmujący (Grupa)",
            stateKey: "roleToId",
            formKey: "usersRoleTo",
          },
          {
            type: FILTER_SELECT,
            label: "Przyjmujący",
            stateKey: "userToId",
            formKey: "usersTo",
            groupBy: "roleName",
            filter: ["roleToId", "roleId"],
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data Od",
            stateKey: "dateOperationFrom",
            outputFormat: "yyyy-MM-dd",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data Od",
            stateKey: "dateOperationTo",
            outputFormat: "yyyy-MM-dd",
          },
        ]}
      />
    </>
  );
};

export default WarehouseDeliveryList;
