import React, { memo, useEffect, useRef } from "react";
import { Badge, Form, Input, Panel, Tooltip, Whisper } from "rsuite";
import _, { isEmpty } from "lodash";
import { FCC } from "utils/models";

import SeparatorLine from "global/atoms/separators/SeparatorLine";
import Comment from "./Atoms/Comment";
import {
  useErrorContext,
  usePanelContext,
  useStaticContext,
} from "./VisitHelpers";
import { PanelNameErrorWrapper } from "../VisitEditForm";
import { isPublicView } from "../../../../../../utils/helpers";

export const VISIT_COMMENTS_ANCHOR = "visit-comment";
const VisitComments: FCC<{ readOnly?: boolean }> = (props) => {
  const { comments } = useStaticContext();
  const { openPanels, panelToggle, registerPanelId } = usePanelContext();
  const { errors } = useErrorContext();

  const anchorDivRef = useRef<HTMLDivElement | null>(null);
  const isAnchor = window.location.hash === `#${VISIT_COMMENTS_ANCHOR}`;
  const hasErrors = !isEmpty(errors?.["comments"] ?? []);

  useEffect(() => {
    if (isAnchor && anchorDivRef.current) {
      anchorDivRef.current.scrollIntoView();
      anchorDivRef.current.focus();
    }
    registerPanelId(VISIT_COMMENTS_ANCHOR);
  }, []);

  return (
    <>
      <div ref={anchorDivRef} id={VISIT_COMMENTS_ANCHOR} />
      {(!isPublicView() || comments.length > 0) && (
        <Panel
          header={
            <PanelNameErrorWrapper hasErrors={hasErrors}>
              <Whisper
                placement="bottomStart"
                speaker={<Tooltip>Rozwiń, aby dodać nowy</Tooltip>}>
                <div
                  style={{
                    fontSize: "0.85em",
                  }}>
                  {"Komentarze"}
                  {hasErrors ? " - " + errors.comments.toString() : " "}
                  {comments.length ? (
                    <Badge color={"orange"} content={comments.length} />
                  ) : (
                    ""
                  )}
                </div>
              </Whisper>
            </PanelNameErrorWrapper>
          }
          shaded
          collapsible={!isAnchor && !hasErrors}
          expanded={
            openPanels.includes(VISIT_COMMENTS_ANCHOR) || isAnchor || hasErrors
          }
          onSelect={() => panelToggle(VISIT_COMMENTS_ANCHOR)}>
          {!props.readOnly && (
            <Form.Group>
              <Form.ControlLabel>Nowy komentarz</Form.ControlLabel>
              <Form.Control
                name={`visitComment`}
                accepter={Input}
                style={{ width: "100%" }}
              />
            </Form.Group>
          )}
          {!_.isEmpty(comments) && (
            <>
              <SeparatorLine />

              {comments.map((comm, index) => (
                <Comment
                  key={`visit-comment-${index}`}
                  author={comm.userName}
                  date={comm.createdAt}
                  activityName={comm.activityName}
                  questionName={comm.questionName}
                  content={comm.comment}
                />
              ))}
            </>
          )}
        </Panel>
      )}
    </>
  );
};

export default memo(VisitComments);
