import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import {
  Button,
  Col,
  DatePicker,
  IconButton,
  Loader,
  Panel,
  Row,
  SelectPicker,
} from "rsuite";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import VisitFreequencyLegend from "./VisitFreequencyLegend";
import BoardTable, { detailedScaleVal } from "./Board/BoardTable";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import SchedulesConnection from "utils/connections/schedules";
import { IScheduleVisit } from "utils/models";
import { getProjectIdFromUrl } from "utils/helpers";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import { iconStyle } from "../ScheduleDatePicker";
import UniversalModal from "global/atoms/UniversalModal";
import { ToastNotificationPush, ToastTypes } from "global/ToastNotification";
import FlexRows from "global/FlexRows";

enum EnumRemoveRouteOption {
  THIS_WEEKDAY_FOREVER = 3,
  ONLY_THIS_DAY = 1,
}

interface IRequestRemovedRoute {
  timeTableIds: string[];
  locationId: string;
  option: EnumRemoveRouteOption;
  date: Date;
}

interface IRequestReorderedRoute {
  locationId: string;
  userId: string;
  date: Date;
  order: number;
}

interface IRequestMovedRoute {
  timeTableIds: string[];
  locationId: string;
  dateFrom: Date;
  dateTo: Date;
  permanently: boolean;
}

interface IRequestCreateRoute {
  locationId: string;
  frequency: number;
  userId: string;
  date: Date;
  order: number;
}

export interface IRequestModify {
  moved: IRequestMovedRoute[];
  removed: IRequestRemovedRoute[];
  reordered: IRequestReorderedRoute[];
  created: IRequestCreateRoute[];
}

export interface IRequestScheduleBoardData {
  userId: string;
  projectId: string;
  dateFrom: Date;
  dateTo: Date;
}

export interface IDay {
  id: string;
  date: Date;
  isEditable: boolean;
  isMovable: boolean;
  locationId: string;
  locationName: string;
  locationAddress: string;
  locationCode: string;
  order: null | number;
  replacedUserFullName: null | string;
  replacedUserId: null | string;
  timeTableIds: string[];
  userFullName: string;
  userId: string;
  visits: IScheduleVisit[];
  frequencies: number[];
}

export const TileWidth = 65;

const ScheduleViewBoard: FunctionComponent = () => {
  const [recurActionModal, setRecurActionModal] = useState<{
    open: boolean;
    msg: string;
    resolve: (value: boolean | PromiseLike<boolean>) => void | null;
    reject: () => void | null;
  }>({
    open: false,
    msg: "",
    resolve: null,
    reject: null,
  });
  const [loaded, setLoaded] = useState(false);
  const [, setDataEmpty] = useState(false);
  const [boardData, setBoardData] = useState<IDay[]>([]);
  const [boardDataModified, setBoardDataModified] = useState<IDay[]>([]);
  const [savingData, setSavingData] = useState(false);
  const [users, setUsers] = useState<Array<{
    name: string;
    id: string;
  }> | null>(null);
  const [requestData, setRequestData] = useState<IRequestScheduleBoardData>({
    userId: "",
    projectId: getProjectIdFromUrl(),
    dateFrom: dayjs().startOf("month").subtract(1, "week").toDate(),
    dateTo: dayjs().endOf("month").toDate(),
  });
  const [scale, setScale] = useState(1);
  const panelRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  const loadData = () => {
    setLoaded(false);
    setDataEmpty(false);
    SchedulesConnection.boardData(requestData).then((res) => {
      // @ts-ignore
      const days = res.data.data?.days ?? [];
      setBoardData(days);
      setBoardDataModified(days);
      setLoaded(true);
      if (!days.length) {
        setDataEmpty(true);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (!requestData?.userId) return;
    loadData();
  }, [requestData]);

  useEffect(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    SchedulesConnection.boardUsers().then((_users) => {
      setUsers(_users);
      if (_users.length) {
        // @ts-ignore
        setRequestData((s) => ({ ...s, userId: _users[0].id }));
      }
    });
  }, []);

  const onTransformed = (
    ref: ReactZoomPanPinchRef,
    state: { scale: number; positionX: number; positionY: number }
  ) => {
    setScale(Math.floor(state.scale));
  };

  const onDataModified = (_data: IDay[]) => {
    setBoardDataModified(_data);
    storeChanges(_data);
  };

  const storeChangesCanceled = () => {
    setSavingData(false);
    setBoardDataModified(boardData); // rewriting previous data
    ToastNotificationPush(ToastTypes.info, "Zmiany wycofane.");
  };

  const storeChanges = (_data: IDay[]) => {
    const getAllFromSameDay = (affected_dates: IDay[]): IDay[] => {
      const allItemsFromDays = [];
      affected_dates.forEach((ad) => {
        _data.forEach((bd) => {
          if (dayjs(ad.date).isSame(bd.date, "day")) {
            allItemsFromDays.push(bd);
          }
        });
      });

      const resultDistict = [];
      allItemsFromDays.forEach((d) => {
        if (resultDistict.find((rd) => rd.id === d.id) === undefined) {
          resultDistict.push(d);
        }
      });

      return resultDistict;
    };

    const getRemoved = async (): Promise<IRequestRemovedRoute[]> => {
      let parsed = boardData
        .filter((d) => !_data.find((d2) => d2.id === d.id))
        .map((d) => ({
          option: EnumRemoveRouteOption.ONLY_THIS_DAY,
          timeTableIds: d.timeTableIds,
          locationId: d.locationId,
          date: d.date,
        }));

      if (parsed.length > 0) {
        const optionBool: boolean = await new Promise((resolve, reject) => {
          setRecurActionModal({
            open: true,
            msg: "Usunięcie jednorazowe, czy na stałe?",
            resolve: resolve,
            reject: reject,
          });
        });
        parsed = parsed.map((p) => ({
          ...p,
          option: optionBool
            ? EnumRemoveRouteOption.THIS_WEEKDAY_FOREVER
            : EnumRemoveRouteOption.ONLY_THIS_DAY,
        }));
      }

      return parsed;
    };

    const getReordered = (): IRequestReorderedRoute[] => {
      const d = _data.filter((d) => {
        const original = boardData.find((d2) => d2.id === d.id);
        return original && original.order !== d.order;
      });
      return getAllFromSameDay(d).map((d) => ({
        locationId: d.locationId,
        userId: d.userId,
        date: d.date,
        order: d.order,
      }));
    };

    // get moved and all from destination day
    const getMoved = async (): Promise<IRequestMovedRoute[]> => {
      let permanently = false;
      const movedSchedules = _data.filter((d) => {
        const original = boardData.find((d2) => d2.id === d.id);
        return original && !dayjs(original.date).isSame(d.date, "day");
      });

      if (
        movedSchedules &&
        movedSchedules.find(
          (s) => s.frequencies.reduce((acc, v) => (acc += v)) !== 0
        )
      ) {
        permanently = await new Promise((resolve, reject) => {
          setRecurActionModal({
            open: true,
            msg: "Przesunięcie jednorazowe, czy na stałe?",
            resolve: resolve,
            reject: reject,
          });
        });
      }

      return movedSchedules.map((d) => ({
        timeTableIds: d.timeTableIds,
        locationId: d.locationId,
        dateFrom: boardData.find((d2) => d2.id === d.id).date,
        dateTo: d.date,
        permanently: permanently,
        frequencies: d.frequencies,
      }));
    };

    getMoved()
      .then((_moved) => {
        getRemoved()
          .then((_removed) => {
            setSavingData(true);
            const requestData: IRequestModify = {
              moved: _moved,
              removed: _removed,
              reordered: getReordered(),
              created: [], //getCreated(),
            };

            setSavingData(false);

            SchedulesConnection.boardChanges(requestData)
              .then(() => {
                setSavingData(false);
                setBoardData(_data);
                // update list (need update timetable id)
                loadData();
              })
              .catch(storeChangesCanceled);
          })
          .catch(storeChangesCanceled);
      })
      .catch(storeChangesCanceled);
  };

  const setDateRange = (date: dayjs.Dayjs) => {
    setRequestData((r) => ({
      ...r,
      dateTo: date.endOf("month").toDate(),
      dateFrom: date.startOf("month").subtract(1, "week").toDate(),
    }));
  };

  return (
    <>
      <HeaderButtons>
        {savingData ||
          (!loaded && (
            <Button appearance={"ghost"} disabled>
              {savingData && <Loader content="Zapisywanie..." />}
              {!loaded && <Loader content="Ładowanie..." />}
            </Button>
          ))}
      </HeaderButtons>

      <FlexRows>
        <Row>
          <Col md={8}>
            <SelectPicker
              loading={users === null}
              data={users ?? []}
              block
              labelKey={"name"}
              valueKey={"id"}
              cleanable={false}
              value={requestData?.userId ?? undefined}
              onChange={(value: string) => {
                setRequestData((r) => ({
                  ...r,
                  userId: value ?? "",
                }));
              }}
              placeholder={"Wybierz pracownika"}
            />
          </Col>
          <Col md={14}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}>
              <IconButton
                appearance={"subtle"}
                icon={<ArrowLeftLineIcon style={iconStyle} />}
                onClick={() => {
                  setDateRange(dayjs(requestData.dateTo).subtract(1, "month"));
                }}
              />
              <DatePicker
                block
                format="MM-yyyy"
                value={requestData.dateTo}
                oneTap
                cleanable={false}
                onChange={(date) => {
                  setDateRange(dayjs(date));
                }}
              />

              <IconButton
                appearance={"subtle"}
                icon={<ArrowRightLineIcon style={iconStyle} />}
                onClick={() => {
                  setDateRange(dayjs(requestData.dateTo).add(1, "month"));
                }}
              />
            </div>
          </Col>
        </Row>

        <Panel
          ref={panelRef}
          bodyFill
          style={{
            border: "solid 1px #ddd",
            display: requestData?.userId ? "block" : "none",
            opacity: loaded ? 1 : 0.3,
            flexGrow: 1,
          }}
          key={`panel-for-height-${dimensions.height}`}>
          {!loaded && <Loader inverse center content="Ładowanie danych..." />}
          <div style={{ opacity: loaded ? 1 : 0.3 }}>
            <TransformWrapper
              initialScale={1}
              maxScale={detailedScaleVal}
              initialPositionX={0}
              initialPositionY={0}
              panning={{
                excluded: ["tile", "inner-tile", "details-view", "address"],
              }}
              onTransformed={onTransformed}
              centerOnInit={false}
              centerZoomedOut={false}
              wheel={{ wheelDisabled: true }}>
              <TransformComponent
                wrapperStyle={{ height: "100%", width: "100%" }}>
                <div
                  style={{
                    height: panelRef.current?.clientHeight ?? "100%",
                  }}>
                  <DndProvider backend={HTML5Backend}>
                    <BoardTable
                      userId={requestData.userId}
                      dateFrom={requestData.dateFrom}
                      triggerReload={() => loadData()}
                      dataModified={onDataModified}
                      dateTo={requestData.dateTo}
                      scale={scale}
                      data={boardDataModified}
                    />
                  </DndProvider>
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
          <VisitFreequencyLegend />
        </Panel>
      </FlexRows>

      <UniversalModal
        size={"xs"}
        onClose={() => {
          recurActionModal?.reject();
          setRecurActionModal((r) => ({ ...r, open: false }));
        }}
        customFooterButtons={
          <>
            <Button
              onClick={() => {
                recurActionModal?.resolve(false);
                setRecurActionModal((r) => ({ ...r, open: false }));
              }}
              appearance={"ghost"}>
              Jednorazowo
            </Button>
            <Button
              onClick={() => {
                recurActionModal?.resolve(true);
                setRecurActionModal((r) => ({ ...r, open: false }));
              }}
              appearance={"primary"}>
              Na stałe
            </Button>
          </>
        }
        displayFooterButtons={false}
        title={recurActionModal.msg}
        open={recurActionModal.open}
        body={<></>}
      />

      <Helmet>
        <style>
          {`
              :root {
                --background-color: #fff;
              }

              #schedules {
                font-family: Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
              }

              #schedules tbody {
                border: 1px solid #ddd;
              }

              #schedules td,
              #schedules th {
                border: none;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                padding: 4px 3px;
              }

              #schedules.scale-2 td {
                padding: 2px 2px 0 2px;
              }

              #schedules:not(.scale-${detailedScaleVal}) thead {
                border: 2px solid #ddd;
              }

              #schedules th {
                text-align: center;
                background-color: var(--background-color);
              }

              #schedules th.today {
                color: #ffaf38;
              }

              #schedules th .full-weekday {
                width: ${TileWidth}px;
                font-size: 8px;
                text-transform: capitalize;
              }

              #schedules td {
                height: 35px;
                width: ${TileWidth}px;
              }

              #schedules.scale-${detailedScaleVal} td {
                height: 40px;
                width: ${TileWidth}px;
              }

              #schedules.scale-${detailedScaleVal} th {
                height: 26px;
                width: ${TileWidth}px;
              }

              #months-row th {
                padding: 0;
                text-align: center;
                background-color: var(--background-color);
                color: #000;
                border-bottom: solid 1px #ddd;
                text-transform: capitalize;
              }

              #months-row th:nth-child(odd) {
                background-color: #f4f4f4;
              }

              #schedules th.th-week-0,
              #schedules th.th-week-1 {
                cursor: pointer;
              }

              #schedules th.th-week-0,
              #schedules td.td-week-0 {
                background-color: var(--background-color);
                position:relative;
              }

              #schedules th.th-week-1,
              #schedules td.td-week-1 {
                background-color: #f4f4f4;
                position:relative;
              }

              #schedules.scale-${detailedScaleVal} .empty-cell {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
              }

              #schedules.scale-${detailedScaleVal} th {
                padding: 0;
                line-height: 10px;
              }


              #schedules.scale-${detailedScaleVal} #months-row {
                display: none;
              }

              #schedules .tile {
                padding: 10px;
                position: relative;
                height: 100%;
              }

              #schedules .tile.isNotMovable.isNotEditable {
                cursor: not-allowed;
                opacity: 0.5;
              }

              #schedules .tile.isMovable {
                cursor: move;
              }



              #schedules .tile .inner-tile {
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                border-radius: 5px;
                border: 1px solid #d0d0d0;
              }

              #schedules .tile .inner-tile:hover {
                border-radius: 0px;
              }

              #schedules.scale-${detailedScaleVal} .tile .inner-tile {
                border: none;
              }


              #schedules:not(.scale-${detailedScaleVal}) .tile .details-view {
                display: none;
              }

              #schedules .tile .details-view {
                font-size: 6px;
                line-height: 6px;
                padding: 3px;
                max-height: 100%;
                overflow:hidden;
              }

              #schedules .tile .details-view .address {
                color: #888;
              }

              #schedules .tile .details-view .code {
                color: #000;
                padding-right: 3px;
              }

              td.over {
                border-top:red 3px solid !important;
              }



              .ui-worktime-oneweek {
                background-color: #f5f2ad;
              }
              .ui-worktime-twomonth {
                background-color: #daec9f;
              }
              .ui-worktime-threeweeks {
                background-color: #cae8f5;
              }
              .ui-worktime-onemonth {
                background-color: #f9ddc6;
              }
              .ui-worktime-once {
                background-color: #e6e6e6;
              }
              .ui-is-not-reporting {
                border: 5px solid #FFEF32;
              }
          `}
        </style>
      </Helmet>
    </>
  );
};

export default ScheduleViewBoard;
