import React, { FunctionComponent, useState } from "react";
import VisitsConnection from "../../../../../utils/connections/visits";
import { useHistory } from "react-router-dom";
import { DatePicker, IconButton, Message } from "rsuite";
import { beforeToday } from "rsuite/esm/DateRangePicker/disabledDateUtils";
import dayjs from "dayjs";
import EditIcon from "@rsuite/icons/Edit";
import CloseIcon from "@rsuite/icons/Close";
import Modal from "rsuite/Modal";
import { getProjectIdFromUrl } from "../../../../../utils/helpers";
import SeparatorLine from "../../../../../global/atoms/separators/SeparatorLine";
import Form from "rsuite/Form";

interface IVisitsModalDeadlineChange {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  visitId: string;
}

const VisitsModalChangeDeadline: FunctionComponent<
  IVisitsModalDeadlineChange
> = (props) => {
  const [errors, setErrors] = useState<any>();

  const [newDeadLineDate, setNewDeadLineDate] = useState<Date | null>();

  const history = useHistory();

  const handleDeadLineDateChange = () => {
    const _requestDate = {
      visitDeadlines: [
        {
          visitId: props.visitId,
          dateTo: dayjs(newDeadLineDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
      ],
    };
    VisitsConnection.changeDedLineDate(_requestDate)
      .then((resp) => {
        if (resp.status === 200) {
          history.push(
            `/projects/${getProjectIdFromUrl()}/visits/${props.visitId}/edit`
          );
        }
      })
      .catch((error) => {
        if (error?.response.status === 400) {
          setErrors(error?.response?.data?.errors?.visitDeadlines[0]);
        }
        if (error?.response.status === 403) {
          setErrors(error?.response?.data);
        }
      });
  };

  return (
    <Modal
      size={"sm"}
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}>
      <Modal.Header>
        <Modal.Title>Zmień termin realizacji wizyty</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <Form.ControlLabel>Termin realizacji</Form.ControlLabel>
          <Form.ErrorMessage show={errors?.dateTo} placement={"topEnd"}>
            {errors?.dateTo}
          </Form.ErrorMessage>
          <Form.ErrorMessage show={errors?.message} placement={"topEnd"}>
            {errors?.message}
          </Form.ErrorMessage>
          <Form.Control
            name={"dateTo"}
            accepter={DatePicker}
            oneTap
            isoWeek
            style={{ width: "50%" }}
            onChange={(value) => {
              setErrors(null);
              setNewDeadLineDate(value);
            }}
            format={"yyyy-MM-dd"}
            value={newDeadLineDate}
            shouldDisableDate={beforeToday()}
            showWeekNumbers
            placeholder="Wybierz datę..."
            ranges={[
              {
                label: "Dziś",
                value: new Date(),
              },
              {
                label: "Jutro",
                value: dayjs().add(1, "day").toDate(),
              },
              {
                label: "Koniec tego tygodnia",
                value: dayjs().endOf("week").toDate(),
              },
            ]}
            errorMessage={errors?.dateTo}
            errorPlacement={"bottomStart"}
          />
        </Form>
        {errors?.message && (
          <Message style={{ marginTop: 10 }} showIcon type={"error"}>
            {errors.message}
          </Message>
        )}
      </Modal.Body>
      <Modal.Footer>
        <>
          <SeparatorLine />
          <IconButton
            icon={<CloseIcon />}
            onClick={() => {
              setNewDeadLineDate(null);
              setErrors(undefined);
              props.setIsOpen(false);
            }}>
            Anuluj
          </IconButton>
          <IconButton
            appearance={"ghost"}
            icon={<EditIcon />}
            disabled={!newDeadLineDate}
            onClick={handleDeadLineDateChange}>
            Zapisz
          </IconButton>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default VisitsModalChangeDeadline;
