import React, { FunctionComponent } from "react";
import Section from "../../../../global/atoms/section/Section";
import { SetState } from "../../../../utils/models";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";
import AddressPicker from "global/AddressPicker";
import { Col, Form } from "rsuite";
import { ILocationAddress, ILocationProjectForm } from "./_states";
import { inputMessage } from "./_helper";

interface IAddressSelectSection {
  errors: any;
  data: ILocationAddress;
  setData: SetState<ILocationAddress>;
  saveExitCallback?: () => Promise<any>;
  form?: ILocationProjectForm;
  disabled?: boolean;
}

const AddressSelectSection: FunctionComponent<IAddressSelectSection> = (
  props
) => {
  const projectSettings = useSelector(
    (root: IRoot) => root.project?.projectSettings
  );

  const setAddress = (key: string, addressId: string | null) => {
    props.setData((d) => ({
      ...d,
      [key]: addressId,
    }));
  };
  const isFormDisabled =
    props.form?.disabledElements?.includes("SAVE_LOCATION_CHANGES") ?? true;
  const getAddress = (key: string): string | undefined => {
    if (props.data && props.data[key]) return props.data[key];
    return undefined;
  };

  return (
    <>
      <Form.Group>
        <Form.ControlLabel>Nazwa lokalizacji</Form.ControlLabel>
        <AddressPicker
          errors={props.errors}
          name={"addressId"}
          readonly={props.disabled === true}
          addresses={
            props?.form?.model?.address && props.disabled === true
              ? [props.form.model.address]
              : undefined
          }
          key={`address-picker-${getAddress("addressId") ?? "none"}`}
          value={getAddress("addressId")}
          onSelected={(addressId) => setAddress("addressId", addressId)}
        />
      </Form.Group>
      {(projectSettings === undefined ||
        (projectSettings.isEnableInvoice &&
          (projectSettings.isEnablePayer ||
            projectSettings.isEnableRecipient))) && (
        <Section title={"Opcje sprzedaży"}>
          {(projectSettings === undefined || projectSettings.isEnablePayer) && (
            <>
              <Col xs={12}>
                <Form.Group controlId="payerName">
                  <Form.ControlLabel>Nazwa nabywcy</Form.ControlLabel>
                  <Form.Control
                    name="payerName"
                    value={props.data.payerName}
                    disabled={isFormDisabled}
                    onChange={(value) => {
                      props.setData({ ...props.data, payerName: value });
                    }}
                  />
                  {inputMessage("payerName", props.errors)}
                </Form.Group>
                <AddressPicker
                  key={`address-payer-picker-${
                    getAddress("payerAddressId") ?? "none"
                  }`}
                  label={"Adres nabywcy"}
                  value={getAddress("payerAddressId")}
                  addresses={
                    props.form?.model.payerAddress && props.disabled === true
                      ? [props.form.model.payerAddress]
                      : undefined
                  }
                  onSelected={(addressId) =>
                    setAddress("payerAddressId", addressId)
                  }
                  readonly={props.disabled === true}
                />
              </Col>
            </>
          )}
          {(projectSettings === undefined ||
            projectSettings.isEnableRecipient) && (
            <>
              <Col xs={12}>
                <Form.Group>
                  <Form.ControlLabel>Nazwa odbiorcy</Form.ControlLabel>
                  <Form.Control
                    name="recipientName"
                    value={props.data.recipientName}
                    disabled={isFormDisabled}
                    onChange={(value) => {
                      props.setData({ ...props.data, recipientName: value });
                    }}
                  />
                  {inputMessage("recipientName", props.errors)}
                </Form.Group>
                <AddressPicker
                  key={`address-receipment-picker-${
                    getAddress("recipientAddressId") ?? "none"
                  }`}
                  addresses={
                    props.form?.model.recipientAddress &&
                    props.disabled === true
                      ? [props.form.model.recipientAddress]
                      : undefined
                  }
                  label={"Adres odbiorcy"}
                  value={getAddress("recipientAddressId")}
                  onSelected={(addressId) =>
                    setAddress("recipientAddressId", addressId)
                  }
                  readonly={props.disabled === true}
                />
              </Col>
            </>
          )}
        </Section>
      )}
    </>
  );
};

export default AddressSelectSection;
