// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oqRV3JtfiTrcqz2rjZxE{padding:24px 32px 0;position:relative}@media(max-width: 1365px){.oqRV3JtfiTrcqz2rjZxE{padding:24px 24px}}@media(max-width: 575px){.oqRV3JtfiTrcqz2rjZxE{padding:24px 0}}.txRje1YMZvWwT9SiJEZ3{display:flex;flex-flow:column;height:100%;padding-bottom:1rem}@media(max-width: 1365px){.txRje1YMZvWwT9SiJEZ3{padding-top:.7rem}}", "",{"version":3,"sources":["webpack://./src/global/layout/layout.module.scss"],"names":[],"mappings":"AAGA,sBACE,mBAAA,CACA,iBAAA,CAEA,0BAJF,sBAKI,iBAAA,CAAA,CAGF,yBARF,sBASI,cAAA,CAAA,CAIJ,sBACE,YAAA,CACA,gBAAA,CACA,WAAA,CACA,mBAAA,CACA,0BALF,sBAMI,iBAAA,CAAA","sourcesContent":["@import '../../styles/variable.scss';\n\n\n.appContainer {\n  padding: 24px 32px 0;\n  position: relative;\n\n  @media (max-width: $lowResScreen) {\n    padding: 24px 24px;\n  }\n\n  @media (max-width: 575px) {\n    padding: 24px 0;\n  }\n}\n\n.contentWrapper {\n  display: flex;\n  flex-flow: column;\n  height: 100%;\n  padding-bottom: 1rem;\n  @media (max-width: $lowResScreen) {\n    padding-top: .7rem;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "oqRV3JtfiTrcqz2rjZxE",
	"contentWrapper": "txRje1YMZvWwT9SiJEZ3"
};
export default ___CSS_LOADER_EXPORT___;
