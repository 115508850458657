import React, { FunctionComponent, useState } from "react";
import { defaultQuestionEditState, IQuestionsEditState } from "./QuestionsEdit";
import {
  ICreateQuestion,
  ICreateQuestionAnswer,
} from "../../../../utils/models";
import styles from "./styles.module.scss";
import Input from "../../../../global/atoms/Input";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import IconButton from "../../../../global/atoms/IconButton";
import RadioButton from "../../../../global/atoms/radio/RadioButton";
import Row from "rsuite/Row";
import Col from "rsuite/Col";
import QuestionsConnection from "../../../../utils/connections/questions";
import { useParams } from "react-router-dom";
import { deepClone, handleToast } from "../../../../utils/helpers";
import commonStyles from "../../../../global/common.module.scss";
import Form from "../../../../global/form/Form";
import { Button } from "rsuite";
import SortableList from "global/atoms/dnd/SortableList";
import { DragHandle } from "global/atoms/SortableItem";

interface IQuestionEditAnswers {
  state: IQuestionsEditState;
  setState: any;
  data: ICreateQuestion;
  setData: any;
  handleSubmit: any;
  form: any;
  triggerLoadCB: () => void;
}

const QuestionEditAnswers: FunctionComponent<IQuestionEditAnswers> = ({
  state,
  setState,
  data,
  setData,
  handleSubmit,
  triggerLoadCB,
}) => {
  const [addErrors, setAddErrors] = useState();
  const { id } = useParams<{ id: string }>();
  const [showSubmit, setShowSubmit] = useState(false);

  const handleAddNewAnswer = () => {
    if (state.answer.length < 1) return false;
    const dataClone = deepClone(data);

    dataClone.answers.push({
      isAnswerToDepend: state.isAnswerToDepend,
      isDefault: false,
      name: state.answer,
      isDeleted: false,
      id: "",
      order: dataClone.answers.length + 1,
    });

    handleToast(
      QuestionsConnection.updateQuestion(id, dataClone),
      setAddErrors
    ).then(() => {
      // setData(dataClone);
      setState(defaultQuestionEditState);
      triggerLoadCB();
    });
  };

  const handleChangeIsDefault = (index: number) => {
    const dataClone = { ...data };
    dataClone.answers.forEach((data, i2) => {
      if (i2 !== index) dataClone.answers[i2].isDefault = false;
    });
    dataClone.answers[index].isDefault = !dataClone.answers[index].isDefault;
    setData(dataClone);
    setShowSubmit(true);
  };

  const handleChangeIsDeleted = (index: number) => {
    const dataClone = { ...data };
    if (dataClone.answers[index].id === "") {
      delete dataClone.answers[index];
      setData(dataClone);
      return true;
    }

    dataClone.answers[index].isDeleted = true;
    setData(dataClone);
    setShowSubmit(true);
  };

  const handleEditButtonClick = (index: number) => {
    const stateClone = { ...state };
    stateClone.editId = stateClone.editId === null ? index : null;
    setState(stateClone);
  };

  const handleChangeName = (index: number, event: any) => {
    const dataClone = { ...data };
    dataClone.answers[index].name = event.currentTarget.value;
    setData(dataClone);
    setShowSubmit(true);
  };

  const onSortEnd = (sortedAnswers: Array<ICreateQuestionAnswer>) => {
    setData((data: any) => ({
      ...data,
      answers: sortedAnswers,
    }));
    setShowSubmit(true);
  };

  return (
    <>
      <WhiteCard padding={true} style={{ marginTop: "24px" }}>
        <div className={styles.draggableHeaderContainer}>
          <div style={{ width: "50px" }} />
          <span style={{ flex: 1 }}>Nazwa</span>
          <div
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "center",
            }}>
            Odpowiedź domyślna
          </div>
          <div style={{ width: "70px" }}>Akcje</div>
        </div>
        <Form
          handleSubmit={handleSubmit}
          style={{ rowGap: 0, marginBottom: "25px" }}>
          <SortableList
            onSortEnd={onSortEnd}
            idKeyName={"id"}
            data={data.answers}
            dragHandleActivator={true}
            mapFunction={(item: ICreateQuestionAnswer, itemKey: number) => {
              // const itemKey = item.id;
              return (
                <div className={styles.draggableContainer}>
                  <DragHandle />
                  &nbsp; &nbsp;
                  <span style={{ flex: 1 }}>
                    {state.editId === itemKey ? (
                      <>
                        <Input
                          placeholder={""}
                          name={"changeName"}
                          value={item.name}
                          onChange={handleChangeName.bind(this, itemKey)}
                        />
                      </>
                    ) : (
                      item.name
                    )}
                  </span>
                  <div
                    style={{
                      width: "300px",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <RadioButton
                      value={item.isDefault ? itemKey : -1}
                      id={itemKey}
                      onChange={handleChangeIsDefault.bind(null, itemKey)}
                    />
                  </div>
                  <div className={styles.draggableActionButtonsContainer}>
                    <IconButton
                      size={24}
                      onClick={handleEditButtonClick.bind(null, itemKey)}
                      icon={"edit"}
                    />
                    <IconButton
                      size={24}
                      onClick={handleChangeIsDeleted.bind(null, itemKey)}
                      icon={"trash"}
                    />
                  </div>
                </div>
              );
            }}
          />

          {showSubmit && (
            <Button type={"submit"} appearance={"primary"}>
              Zapisz zmiany
            </Button>
          )}
        </Form>

        <div
          className={styles.draggableContainer}
          style={{
            padding: "30px 12px",
            height: "auto",
            display: "block",
          }}>
          <Row>
            <Col xs={20}>
              <Input
                style={{ display: "inline-block" }}
                placeholder={"Treść odpowiedzi"}
                name={"answer"}
                value={state.answer}
                state={state}
                setState={setState}
              />
              {
                // todo: state name sie nie zgadza przez co nie moze isc do inputa....
                // @ts-ignore
                addErrors?.answers &&
                  // @ts-ignore
                  addErrors.answers[data.answers.length] && (
                    <>
                      <div className={commonStyles.err}>
                        {
                          // @ts-ignore
                          addErrors?.answers[data.answers.length].name
                        }
                      </div>
                    </>
                  )
              }
            </Col>
            <Col xs={4} style={{ textAlign: "right", paddingTop: "26px" }}>
              <Button
                type={"button"}
                block
                appearance={"primary"}
                onClick={handleAddNewAnswer}>
                Dodaj nową odpowiedź
              </Button>
            </Col>
          </Row>
        </div>
      </WhiteCard>
    </>
  );
};

export default QuestionEditAnswers;
