import React from "react";
import BaseComponent from "../BaseComponent";
import { IBaseComponentProps } from "../../../utils/models";
import styles from "../atoms.module.scss";

export interface ISeparatorProps extends IBaseComponentProps {
  size?: number;
  className?: string;
}

export default class SeparatorEmpty extends BaseComponent<
  ISeparatorProps,
  any
> {
  renderView(): JSX.Element {
    const size = this.props?.size ?? 1;
    const margin = size * 9;
    return (
      <div
        className={this.props.className ?? styles.separator}
        style={{ margin: `${margin}px 0` }}
      />
    );
  }
}
