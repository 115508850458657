// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dnRmN_eH8eVNNW28Weob{position:absolute;right:10px;top:10px}.cTAx1tYM2mCokaGErvs9{width:100%;height:100%;position:absolute;background-color:rgba(0,0,0,.2);left:0;top:0;border-radius:8px}.gdJE0mC0FJ9GGIXigSe5{height:100%}", "",{"version":3,"sources":["webpack://./src/views/projects/components/visitsReport/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,QAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,+BAAA,CACA,MAAA,CACA,KAAA,CACA,iBAAA,CAGF,sBACE,WAAA","sourcesContent":[".topRightCornerCheckbox {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n\n.sectionDisabled {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  background-color: rgba(0, 0, 0, 0.2);\n  left: 0;\n  top: 0;\n  border-radius: 8px;\n}\n\n.sectionContainer {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRightCornerCheckbox": "dnRmN_eH8eVNNW28Weob",
	"sectionDisabled": "cTAx1tYM2mCokaGErvs9",
	"sectionContainer": "gdJE0mC0FJ9GGIXigSe5"
};
export default ___CSS_LOADER_EXPORT___;
