import React, { FunctionComponent, useEffect, useState } from "react";
import Connection from "utils/connections/contract/contractSalaryConnection";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import { Col, Input, Row, SelectPicker } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { ISelect } from "utils/models";

import {
  contractSalaryState,
  rate,
  salary,
} from "../../../utils/states/contract/contractSalaryStates";
import {
  IContractSalaryModel,
  IRate,
  ISalary,
} from "../../../utils/models/contract/contractSalaryModels";
import Section from "../../../global/atoms/section/Section";
import ContractProjectSalariesRateEdit from "./ContractProjectSalariesRateEdit";
import { contractProjectPath } from "../../../routes/contract/project";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

interface IContractProjectSalaries {}

export interface IContractProjectSalariesForm {
  rateSettlement: ISelect;
  rateTypes: ISelect;
  salaryTypes: ISelect;
}

const ContractProjectEdit: FunctionComponent<IContractProjectSalaries> = () => {
  const [data, setData] = useState<IContractSalaryModel>(contractSalaryState);
  const [salaryData, setSalaryData] = useState<ISalary>(salary);
  const [baseRateData, setBaseRateData] = useState<IRate>(rate);
  const [bonusRateData, setBonusRateData] = useState<IRate>(rate);
  const [extraRateData, setExtraRateData] = useState<IRate>(rate);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IContractProjectSalariesForm>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { salaryId } = useParams<{ salaryId: string }>();

  const path = `${contractProjectPath}/${id}/salaries`;

  const type: "add" | "edit" = salaryId === undefined ? "add" : "edit";
  if (type === "edit") {
    useEffect(() => {
      Connection.getFormEditContractSalary(id, salaryId).then((response) => {
        setData(response.data.model);
        setSalaryData({
          ...response.data.model.salary,
          type: response.data.model.salary.type.id,
        });
        setBaseRateData(salaryData.baseRate);
        setBonusRateData(salaryData.bonusRate);
        setExtraRateData(salaryData.extraRate);
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      Connection.getFormAddContractSalary(id).then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const dataToSend = {
      ...data,
      //id: id??null,
      salary: {
        ...salaryData,
        baseRate: { ...baseRateData, type: "BASE" },
        bonusRate: { ...bonusRateData, type: "BONUS" },
        extraRate: { ...extraRateData, type: "EXTRA" },
      },
    };
    if (type === "add") {
      handleToast(
        Connection.createContractProjectSalary(id, dataToSend),
        setErrors
      ).then(() => history.push(path));
      return true;
    }
    handleToast(
      Connection.updateContractProjectSalary(id, dataToSend),
      setErrors
    ).then(() => history.push(path));
  };
  if (!form) return <Spinner />;

  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div>
              Typ wynagrodzenia:
              <SelectPicker
                block
                data={form.salaryTypes.options ?? []}
                value={salaryData.type}
                onChange={(value) =>
                  setSalaryData({
                    ...salaryData,
                    type: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
            </div>
            {errors?.type && <div style={errorStyle}>{errors?.type}</div>}
            <SeparatorEmpty size={0.75} />
            <div>
              Nazwa:
              <Input
                type={"text"}
                placeholder={"wpisz nazwę..."}
                name={"name"}
                value={salaryData.name}
                onChange={(value) =>
                  setSalaryData({
                    ...salaryData,
                    name: value?.toString() ?? "",
                  })
                }
              />
              {errors?.name && <div style={errorStyle}>{errors?.name}</div>}
            </div>
            <SeparatorEmpty size={0.75} />
            <div>
              Opis:
              <Input
                type={"text"}
                placeholder={"wpisz opis..."}
                name={"name"}
                value={salaryData.description}
                onChange={(value) =>
                  setSalaryData({
                    ...salaryData,
                    description: value?.toString() ?? "",
                  })
                }
              />
              {errors?.description && (
                <div style={errorStyle}>{errors?.description}</div>
              )}
            </div>
            <SeparatorEmpty size={0.75} />
          </Col>
        </Row>
        <Section title={"Stawki"}>
          <ContractProjectSalariesRateEdit
            value={"BASE"}
            header={true}
            form={form}
            errors={errors}
            rateState={baseRateData}
            setRateState={setBaseRateData}
          />
          <SeparatorEmpty size={0.75} />
          <ContractProjectSalariesRateEdit
            value={"BONUS"}
            header={false}
            form={form}
            errors={errors}
            rateState={bonusRateData}
            setRateState={setBonusRateData}
          />
          <SeparatorEmpty size={0.75} />
          <ContractProjectSalariesRateEdit
            value={"EXTRA"}
            header={false}
            form={form}
            errors={errors}
            rateState={extraRateData}
            setRateState={setExtraRateData}
          />
          <SeparatorEmpty size={0.75} />
        </Section>
        <ActionsContainer>
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default ContractProjectEdit;
