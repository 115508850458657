import AxiosHelper, { default as axios } from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
  parseDateForBackend,
} from "../helpers";
import {
  ICreateLocation,
  ICreateProduct,
  ICreateProject,
  IGetProjectLocations,
  IGetProjectProducts,
  IGetProjects,
  IProjectProductFormData,
  IProjectRole,
} from "../models";
import { getSaveAddressData } from "./locations";
import { AxiosPromise, AxiosResponse } from "axios";
import { ProjectRoleActionType } from "../../views/projects/components/editProject/Access";
import { createUpdateData } from "./products";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects`;

export interface IAssignUsersToProject {
  roles: Array<{
    roleId: string;
    assigned: Array<{ id: string }>;
    unassigned: Array<{ id: string }>;
  }>;
}

const ProjectsConnection = {
  getProjects: (data: IGetProjects) => {
    return axios.post(
      `${baseUrl}/list`,
      {
        ...data,
        startDate: parseDateForBackend(data.startDate),
        endDate: parseDateForBackend(data.endDate),
      },
      {
        headers: { ...getAuthHeader() },
      }
    );
  },
  getProject: (id: string) => {
    return axios.get(`${baseUrl}/${id}`, {
      headers: getAuthHeader(),
    });
  },
  createProject: (data: ICreateProject) => {
    return axios.post(`${baseUrl}`, data, { headers: getAuthHeader() });
  },
  updateProject: (data: ICreateProject, id: string) => {
    return axios.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  assignUsersToProject: (data: IAssignUsersToProject) => {
    const projectId = getProjectIdFromUrl();
    return AxiosHelper.put(
      `${baseUrl}/${projectId}/users/roles`,
      data,
      undefined,
      "assignUsersToProject"
    );
  },
  unassignUsersToProject: (
    projectId: string,
    roleId: string,
    data: Array<{ userId: string }>
  ) => {
    return axios.delete(
      `${baseUrl}/${projectId}/users/roles/${roleId}/detach`,
      { headers: getAuthHeader(), data: { users: data } }
    );
  },
  getAssignedProducts: (data: IGetProjectProducts) => {
    const id = getProjectIdFromUrl() ?? "";
    return axios.post(`${baseUrl}/${id}/items/assigned/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getUnassignedProducts: (data: IGetProjectProducts) => {
    const id = getProjectIdFromUrl() ?? "";
    return axios.post(`${baseUrl}/${id}/items/unassigned/list`, data, {
      headers: getAuthHeader(),
    });
  },
  assignProductsToProject: (data: Array<{ itemId: string }>) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.patch(
      `${baseUrl}/${projectId}/items/assign`,
      { items: data },
      { headers: getAuthHeader() }
    );
  },
  unassignProductsFromProject: (data: Array<{ itemId: string }>) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.delete(`${baseUrl}/${projectId}/items/detach`, {
      headers: getAuthHeader(),
      data: { items: data },
    });
  },
  getFiltersFormProducts: () => {
    return axios.get(`${baseUrl}/items/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getAssignedLocations: (data: IGetProjectLocations) => {
    const id = getProjectIdFromUrl();
    return axios.post(`${baseUrl}/${id}/locations/assigned/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getUnassignedLocations: (data: IGetProjectLocations) => {
    const id = getProjectIdFromUrl();
    return axios.post(`${baseUrl}/${id}/locations/unassigned/list`, data, {
      headers: getAuthHeader(),
    });
  },
  assignLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/assign`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      }
    );
  },
  unassignLocations: (id: string, data: Array<string>) => {
    return axios.delete(`${baseUrl}/${id}/locations/detach`, {
      headers: getAuthHeader(),
      data: { locations: data.map((item: string) => ({ locationId: item })) },
    });
  },
  disableLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/reporting/disable`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      }
    );
  },
  disableAllLocations: (id: string) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/reporting/all/disable`,
      {},
      {
        headers: getAuthHeader(),
      }
    );
  },
  enableLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/reporting/enable`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      }
    );
  },
  enableAllLocations: (id: string) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/reporting/all/enable`,
      {},
      {
        headers: getAuthHeader(),
      }
    );
  },
  detachAllLocations: (id: string) => {
    return axios.patch(
      `${baseUrl}/${id}/locations/reporting/all/detach`,
      {},
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormCreateProject: () => {
    return axios.get(`${baseUrl}/form`);
  },
  getFormFilterProjects: () => {
    return axios.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditProject: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getFormFilterLocations: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.get(`${baseUrl}/${projectId}/locations/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getAssignedRoles: (
    projectId: string
  ): Promise<
    AxiosResponse<{ projectRoles: { data: Array<IProjectRole> } }>
  > => {
    return axios.get(`${baseUrl}/${projectId}/users/roles/assigned`, {
      headers: getAuthHeader(),
    });
  },
  getAssignedUsers: (projectId: string, roleId: string) => {
    return axios.get(`${baseUrl}/${projectId}/users/roles/${roleId}/assigned`, {
      headers: getAuthHeader(),
    });
  },
  getUnassignedUsers: (projectId: string, roleId: string) => {
    return axios.get(
      `${baseUrl}/${projectId}/users/roles/${roleId}/unassigned`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getAssignedRolesToLocation: (projectId: string, locationId: string) => {
    return axios.get(
      `${baseUrl}/${projectId}/locations/${locationId}/users/roles/assigned`,
      { headers: getAuthHeader() }
    );
  },
  getFormLocationAssignedUsers: (
    projectId: string,
    locationId: string,
    roleId: string
  ) => {
    return axios.get(
      `${baseUrl}/${projectId}/locations/${locationId}/users/roles/${roleId}/assigned`,
      { headers: getAuthHeader() }
    );
  },
  getFormLocationUnassignedUsers: (
    projectId: string,
    locationId: string,
    roleId: string
  ) => {
    return axios.get(
      `${baseUrl}/${projectId}/locations/${locationId}/users/roles/${roleId}/unassigned`,
      { headers: getAuthHeader() }
    );
  },
  assignUsersToLoaction: (
    projectId: string,
    locationId: string,
    data: {
      roles: Array<{
        roleId: string;
        assigned: Array<{ id: string }>;
        unassigned: Array<{ id: string }>;
      }>;
    }
  ) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/locations/${locationId}/users/roles`,
      data,
      { headers: getAuthHeader() }
    );
  },
  unassignUsersFromLoaction: (
    projectId: string,
    locationId: string,
    roleId: string,
    data: Array<{ userId: string }>
  ) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/locations/${locationId}/users/roles/${roleId}/detach`,
      { headers: getAuthHeader(), data: { users: data } }
    );
  },

  getFormEditProduct: (
    productId: string
  ): Promise<AxiosResponse<IProjectProductFormData>> => {
    const url = `${baseUrl}/${getProjectIdFromUrl()}/items/${productId}/form`;
    return AxiosHelper.get(url);
  },
  getFormEditLocation: (locationId?: string): AxiosPromise<any> => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.get(`${baseUrl}/${projectId}/locations/${locationId}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditRoles: (
    projectId: string
  ): Promise<
    AxiosResponse<{
      disabledElements: [];
      projectRoles: { options: Array<IProjectRole> };
    }>
  > => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/roles/form`
    );
  },
  getFormRolesConfiguration: (projectId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/roles/configuration/form`
    );
  },
  putRolesConfiguration: (projectId: string, data) => {
    return AxiosHelper.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/roles/configuration`,
      data
    );
  },
  assignRolesToProject: (data: {
    roles: Array<{
      roleId: string;
      reporting: boolean;
      actionType: ProjectRoleActionType;
    }>;
  }) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosHelper.patch(
      `${baseUrl}/${projectId}/roles/assign`,
      data,
      undefined,
      "projectRoleAssign"
    );
  },
  updateProjectLocation: (
    projectId: string,
    locationId: string,
    data: ICreateLocation
  ) => {
    return AxiosHelper.put(
      `${baseUrl}/${projectId}/locations/${locationId}`,
      getSaveAddressData(data)
    );
  },

  updateProjectProduct: (productId: string, data: ICreateProduct) => {
    const url = `${baseUrl}/${getProjectIdFromUrl()}/items/${productId}`;

    // @ts-ignore
    return AxiosHelper.put(url, createUpdateData(data));
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { projects: data },
    });
  },
  cloneProject: (projectId: string, data): AxiosPromise<any> => {
    return AxiosHelper.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/clone`,
      data
    );
  },
};

export default ProjectsConnection;
