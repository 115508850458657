import React, { FunctionComponent } from "react";
import Checkbox from "../global/atoms/Checkbox";
// import Button from '../global/atoms/Button';
import { confirmModalHandler } from "../redux/store";
import IconButton from "../global/atoms/IconButton";
import { Dropdown, Button } from "rsuite";
import { FormElementBase } from "./FormElement";

interface IToggleSingleCheckbox {
  state: any;
  setState: any;
  selectedId: string;
}

export const ToggleSingleCheckbox: FunctionComponent<IToggleSingleCheckbox> = ({
  state,
  setState,
  selectedId,
}) => {
  const handleToggleItem = (itemId: string) => {
    if (state.includes(itemId)) {
      setState(state.filter((item: string) => item !== itemId));
    } else {
      setState([...state, itemId]);
    }
  };
  return (
    <>
      <Checkbox
        isChecked={state.includes(selectedId)}
        onClick={handleToggleItem.bind(null, selectedId)}
      />
    </>
  );
};

interface IToggleAllCheckbox {
  state: any;
  setState: any;
  data: any;
  keyIdName?: string;
}

export const ToggleAllCheckbox: FunctionComponent<IToggleAllCheckbox> = ({
  state,
  setState,
  data,
  keyIdName = "id",
}) => {
  const handleToggleAllItems = () => {
    if (data === null) {
      return;
    }

    if (state.length) {
      setState([]);
    } else {
      setState(data.map((item: any) => item[keyIdName]));
    }
  };
  return (
    <>
      <Checkbox
        isChecked={state.length > 0 && state.length === data.length}
        onClick={handleToggleAllItems}
      />
    </>
  );
};

interface IDeleteSelectedButton {
  callback: any;
  chosenToDelete: Array<string>;
  form?: any;
}

export const DeleteSelectedButton: FunctionComponent<IDeleteSelectedButton> = ({
  callback,
  chosenToDelete,
  form,
}) => {
  const isDisabled =
    !chosenToDelete.length ||
    form?.disabledElements?.includes(FormElementBase.deleteSelectedButton);
  return (
    <>
      <Button
        appearance={"subtle"}
        onClick={confirmModalHandler(
          `Czy usunąć zaznaczone (${chosenToDelete.length})?`,
          callback
        )}
        disabled={isDisabled}>
        Usuń zaznaczone
      </Button>
    </>
  );
};

interface IBatchActionButton {
  callback: any;
  chosenRows: Array<string>;
  title: string;
  message: string;
  icon?: any;
  disabled?: boolean;
}

export const ActionButton: FunctionComponent<IBatchActionButton> = ({
  callback,
  title,
  message,
}) => {
  return (
    <>
      <Button onClick={confirmModalHandler(message, callback)}>{title}</Button>
    </>
  );
};

export const ActionDropdownItem: FunctionComponent<IBatchActionButton> = (
  props
) => {
  return (
    <>
      <Dropdown.Item
        onClick={confirmModalHandler(props.message, props.callback)}
        disabled={props?.disabled ?? false}>
        {props.title}
      </Dropdown.Item>
    </>
  );
};

export const BatchActionButton: FunctionComponent<IBatchActionButton> = ({
  callback,
  chosenRows,
  title,
  message,
}) => {
  return (
    <>
      <Button
        onClick={confirmModalHandler(
          message + ` (${chosenRows.length})?`,
          callback
        )}
        disabled={!chosenRows.length}>
        {title}
      </Button>
    </>
  );
};

export const BatchActionDropdownItem: FunctionComponent<IBatchActionButton> = (
  props
) => {
  return (
    <>
      <Dropdown.Item
        onClick={confirmModalHandler(
          props.message + ` (${props.chosenRows.length})?`,
          props.callback
        )}
        icon={props.icon}
        disabled={!props.chosenRows.length || props.disabled}>
        {props.title}
      </Dropdown.Item>
    </>
  );
};

interface IDeleteSingleButton {
  callback: any;
  form?: any;
  message?: string;
}

export const DeleteSingleButton: FunctionComponent<IDeleteSingleButton> = ({
  callback,
  form,
  message,
}) => {
  if (!message) message = "Potwierdź żądanie usunięcia";
  return (
    <>
      <IconButton
        icon={"trash"}
        onClick={confirmModalHandler(message, callback)}
        disabled={form?.disabledElements?.includes(
          FormElementBase.deleteButton
        )}
      />
    </>
  );
};

interface ISingleActionButton {
  callback: any;
  message: string;
}

export const SingleActionButton: FunctionComponent<ISingleActionButton> = ({
  callback,
  message,
}) => {
  return (
    <>
      <IconButton
        icon={"trash"}
        onClick={confirmModalHandler(message, callback)}
      />
    </>
  );
};
