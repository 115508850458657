import React, { FunctionComponent, useState } from "react";
import {
  IWarehouseAvailabilityFilters,
  IWarehouseUserShiftData,
} from "utils/models";
import { _paginate30 } from "utils/states";
import WarehouseConnection from "utils/connections/warehouse";
import Pagination from "global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { Tooltip, Whisper, Table } from "rsuite";
import {
  getProjectIdFromUrl,
  handleSortColumn,
} from "../../../../utils/helpers";
import styles from "./styles.module.scss";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";

interface IWarehouseList {
  chosenDelivery: Array<string>;
  setChosenDelivery: (data) => void;
  triggerLoad: number;
}

interface IFilters extends IWarehouseAvailabilityFilters {
  groupId: string;
  userToId: string;
  userFromId: string;
  roleFromId: string;
  roleToId: string;
}

const WarehouseShiftList: FunctionComponent<IWarehouseList> = ({
  chosenDelivery,
  setChosenDelivery,
  triggerLoad,
}) => {
  const [data, setData] = useState<IWarehouseUserShiftData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const defaultFilters: IFilters = {
    brandId: "",
    itemId: "",
    groupId: "",
    userToId: "",
    userFromId: "",
    roleFromId: "",
    roleToId: "",
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IFilters>(defaultFilters);

  return (
    <>
      <Table
        loading={loading}
        // @ts-ignore
        data={data?.data ?? []}
        autoHeight
        sortColumn={filters.requestOrder.field}
        sortType={filters.requestOrder.order}
        onSortColumn={(dataKey, sortType) =>
          handleSortColumn(dataKey, sortType, setFilters)
        }>
        <Table.Column width={60} align="center">
          <Table.HeaderCell>
            <ToggleAllCheckbox
              data={data?.data}
              state={chosenDelivery}
              setState={setChosenDelivery}
              keyIdName={"id"}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <ToggleSingleCheckbox
                state={chosenDelivery}
                setState={setChosenDelivery}
                selectedId={rowData.id}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} minWidth={150} sortable>
          <Table.HeaderCell>
            <div style={{ display: "inline-block" }}>
              <div>Produkt</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell dataKey="name">
            {(rowData) => (
              <>
                <div>{rowData.name}</div>
                <div className={styles.style}>{rowData.itemGroup}</div>
              </>
            )}
          </Table.Cell>
          {/*<Table.Cell width={100} dataKey="name"/>*/}
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>
            <div>
              <div>Wydający</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell dataKey="userFrom">
            {(rowData) => (
              <>
                <div>
                  {rowData.userFrom}
                  <div
                    className={styles.style}
                    style={{
                      fontWeight: "bold",
                      color: rowData.roleFrom.roleType.color,
                    }}>
                    {rowData.roleFrom.roleName}
                  </div>
                </div>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={300}>
          <Table.HeaderCell>
            <div>
              <div>Przyjmujący</div>
              <div className={styles.style_header}>Grupa</div>
            </div>
          </Table.HeaderCell>
          <Table.Cell dataKey="userTo">
            {(rowData) => (
              <>
                <div>
                  {rowData.userTo}
                  <div
                    className={styles.style}
                    style={{
                      fontWeight: "bold",
                      color: rowData.roleTo.roleType.color,
                    }}>
                    {rowData.roleTo.roleName}
                  </div>
                </div>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120}>
          <Table.HeaderCell>Ilość</Table.HeaderCell>
          <Table.Cell dataKey="incoming" />
        </Table.Column>
        <Table.Column width={120} sortable>
          <Table.HeaderCell>data</Table.HeaderCell>
          <Table.Cell dataKey="operationDate">
            {(rowData) => {
              return (
                <>
                  <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={
                      <Tooltip>
                        Wykonana przez:
                        <br />
                        <strong>{rowData.operationUser}</strong>
                      </Tooltip>
                    }>
                    <span style={{ cursor: "help" }}>
                      {rowData.operationDate}
                    </span>
                  </Whisper>
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey="status.name">
            {(rowData) => {
              return (
                <div>
                  <div
                    className={styles.style_top}
                    style={{
                      fontWeight: "bold",
                      color: rowData.status.color,
                    }}>
                    {rowData.status.name}
                  </div>
                  <div className={styles.style} style={{ fontSize: "0.5em" }}>
                    {rowData.changedStatusUser ?? ""}
                  </div>
                </div>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>

      <Pagination
        count={data?.count ?? 0}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={WarehouseConnection.userShiftList}
        formGet={WarehouseConnection.userShiftFilterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectWarehouseShift_${getProjectIdFromUrl()}`}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_SELECT,
            label: "Status",
            stateKey: "statusId",
            formKey: "statuses",
          },
          {
            type: FILTER_SELECT,
            label: "Produkt",
            stateKey: "itemId",
            formKey: "items",
          },
          {
            type: FILTER_SELECT,
            label: "Marka",
            stateKey: "brandId",
            formKey: "brands",
          },
          {
            type: FILTER_SELECT,
            label: "Grupa produktu",
            stateKey: "groupId",
            formKey: "itemGroups",
          },
          {
            type: FILTER_SELECT,
            label: "Wydający (Grupa)",
            stateKey: "roleFromId",
            formKey: "usersRoleFrom",
          },
          {
            type: FILTER_SELECT,
            label: "Wydający",
            stateKey: "userFromId",
            formKey: "usersFrom",
            groupBy: "roleName",
            filter: ["roleFromId", "roleId"],
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_SELECT,
            label: "Przyjmujący (Grupa)",
            stateKey: "roleToId",
            formKey: "usersRoleTo",
          },
          {
            type: FILTER_SELECT,
            label: "Przyjmujący",
            stateKey: "userToId",
            formKey: "usersTo",
            groupBy: "roleName",
            filter: ["roleToId", "roleId"],
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data Od",
            stateKey: "dateOperationFrom",
            outputFormat: "yyyy-MM-dd",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data Od",
            stateKey: "dateOperationTo",
            outputFormat: "yyyy-MM-dd",
          },
        ]}
      />
    </>
  );
};

export default WarehouseShiftList;
