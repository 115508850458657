import React, { FunctionComponent, useEffect, useState } from "react";
import FileUpload from "../../../../global/atoms/FileUpload";
import {
  getBaseApiUrl,
  getOrganizationId,
  handleToast,
} from "../../../../utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import ProjectFilesConnection from "../../../../utils/connections/projectFiles";
import Select from "../../../../global/atoms/Select";
import { Col, Row, Table } from "rsuite";
import IconButton from "../../../../global/atoms/IconButton";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { confirmModalHandler } from "../../../../redux/store";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import { FormElementBase } from "utils/FormElement";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { validate } from "uuid";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";

interface IFilesListForm {
  types: any;
  disabledElements: Array<any>;
}

interface IProjectFilesReadModel {
  createdAt: string;
  extension: string;
  id: string;
  name: string;
  size: string;
  type: string;
}

const TYPE_ALL = "TYPE_ALL";

const FilesList: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<{
    selectedType: string;
  }>({
    selectedType: TYPE_ALL,
  });
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<IFilesListForm>({
    types: [],
    disabledElements: [],
  });
  const [data, setData] = useState<Array<IProjectFilesReadModel>>([]);
  const history = useHistory();

  useEffect(() => {
    Promise.all([
      ProjectFilesConnection.uploadViewData(id),
      ProjectFilesConnection.getAllProjectFiles(id),
    ]).then((responses) => {
      setForm(responses[0].data);
      setData(responses[1].data);
      setLoading(false);
    });
  }, []);

  const reloadData = () => {
    handleToast(ProjectFilesConnection.getAllProjectFiles(id)).then(
      (response) => {
        setData(response.data);
      }
    );
  };

  const handleFileUploadResponse = (resp) => {
    if (validate(resp.projectFilesId)) {
      history.push(`/projects/${id}/files/${resp.projectFilesId}/edit`);
    }
    reloadData();
  };

  const saveUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${id}/files`;

  const getFilteredData = () => {
    if (!data) return [];
    if (state.selectedType === TYPE_ALL) {
      return data;
    }

    return data.filter((item) => {
      return item.type === state.selectedType;
    });
  };

  const handleDownloadFile = (fileId: string, name: string) => {
    handleToast(ProjectFilesConnection.download(name, fileId, id));
  };

  const handleRemoveFile = (fileId: string) => {
    handleToast(ProjectFilesConnection.removeFile(fileId, id)).then(() => {
      reloadData();
    });
  };

  const handleRedirectToFileEdit = (fileId: string) => {
    history.push(`/projects/${id}/files/${fileId}/edit`);
  };

  return (
    <>
      <PaginatedTableViewWrapper
        beforeTableElements={
          <>
            <Row>
              <Col xs={12}>
                <Select
                  style={{ zIndex: 100 }}
                  placeholder={"Typ pliku"}
                  name={"selectedType"}
                  value={state.selectedType}
                  options={form?.types?.options ?? []}
                  state={state}
                  setState={setState}
                />
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <FileUpload
                  label={"Dodaj pliki"}
                  saveUrl={saveUrl}
                  savedFileCallback={handleFileUploadResponse}
                  disabled={form?.disabledElements?.includes(
                    FormElementBase.addNewButton
                  )}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1} />
          </>
        }
        table={
          <Table fillHeight loading={loading} data={getFilteredData()}>
            <Table.Column flexGrow={1} minWidth={300} align="left">
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <>
                    {!form.disabledElements.includes(
                      FormElementBase.editButton
                    ) ? (
                      <ButtonListRedirect
                        redirectUrl={`/projects/${id}/files/${rowData.id}/edit`}>
                        {rowData.name}
                      </ButtonListRedirect>
                    ) : (
                      <>{rowData.name}</>
                    )}
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200} align="left">
              <Table.HeaderCell>Kategoria</Table.HeaderCell>
              <Table.Cell width={150} dataKey="categoryName" />
            </Table.Column>
            <Table.Column width={200} align="left">
              <Table.HeaderCell>Odbiorcy</Table.HeaderCell>
              <Table.Cell width={150}>
                {(rowData) => {
                  return (rowData.users ?? []).length ? (
                    <BadgeStatus color={"green"}>Wybrani</BadgeStatus>
                  ) : (
                    <BadgeStatus color={"gray"}>Wszyscy</BadgeStatus>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={300} align="left">
              <Table.HeaderCell>Opis</Table.HeaderCell>
              <Table.Cell width={100} dataKey="description" />
            </Table.Column>
            <Table.Column width={150} align="left">
              <Table.HeaderCell>Wielkość</Table.HeaderCell>
              <Table.Cell width={100} dataKey="size" />
            </Table.Column>
            <Table.Column width={180} align="left">
              <Table.HeaderCell>Data utworzenia</Table.HeaderCell>
              <Table.Cell width={100} dataKey="createdAt" />
            </Table.Column>
            <Table.Column width={120} align="right">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell width={120} style={{ textAlign: "right" }}>
                {(rowData) => {
                  return (
                    <ActionsContainer align={"right"}>
                      <IconButton
                        onClick={handleDownloadFile.bind(
                          null,
                          rowData.id,
                          rowData.name
                        )}
                        icon={"download"}
                        size={24}
                      />
                      {!form.disabledElements.includes(
                        FormElementBase.editButton
                      ) && (
                        <IconButton
                          onClick={handleRedirectToFileEdit.bind(
                            null,
                            rowData.id
                          )}
                          icon={"settings"}
                        />
                      )}
                      {!form.disabledElements.includes(
                        FormElementBase.deleteButton
                      ) && (
                        <IconButton
                          onClick={confirmModalHandler(
                            "Czy na pewno chcesz usunąć ten plik?",
                            handleRemoveFile.bind(null, rowData.id)
                          )}
                          icon={"trash"}
                          size={30}
                        />
                      )}
                    </ActionsContainer>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={<></>}
      />
    </>
  );
};

export default FilesList;
