import React, { CSSProperties, FunctionComponent } from "react";
import Button from "rsuite/Button";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import { FormElementBase } from "utils/FormElement";

interface IButtonSubmit {
  onClick?: any;
  name?: string;
  form?: any;
  className?: string;
  attrName?: string;
  disabled?: boolean;
  appearance?: TypeAttributes.Appearance;
  style?: CSSProperties;
}

const ButtonSubmit: FunctionComponent<IButtonSubmit> = ({
  onClick,
  name = "",
  form,
  className,
  disabled = false,
  attrName = "button",
  appearance = "primary",
  style = {},
}) => {
  return (
    <Button
      style={style}
      appearance={appearance}
      className={className ?? ""}
      onClick={onClick}
      type={onClick ? "button" : "submit"}
      name={attrName}
      disabled={
        form?.disabledElements?.includes(FormElementBase.saveButton) || disabled
      }>
      {name ? name : "Zapisz"}
    </Button>
  );
};

export default ButtonSubmit;
