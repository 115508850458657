import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import Connection from "utils/connections/contract/contractConnection";

import { useHistory } from "react-router-dom";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import {
  Col,
  Row,
  SelectPicker,
  Input,
  Steps,
  DatePicker,
  Panel,
} from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import {
  ICreateContract,
  ICreateContractUser,
  IFormContract,
} from "../../../utils/models/contract/contractModels";
import {
  createContractState,
  createContractUserState,
  isValidPesel,
} from "../../../utils/states/contract/contractStates";
import Section from "global/atoms/section/Section";
import { handleToast } from "../../../utils/helpers";
import ContractProjectConnection from "../../../utils/connections/contract/contractProjectConnection";
import { ICreateContractProject } from "../../../utils/models/contract/contractProjectModels";
import { getContractProjectState } from "../../../utils/states/contract/contractProjectStates";
import ContractDocumentAssignedList from "./ContractDocumentAssignedList";
import ContractorHistoryList from "./ContractorHistoryList";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { ArrowDownLine, ArrowUpLine } from "@rsuite/icons";
import dayjs from "dayjs";
import { contractDocumentPath } from "../../../routes/contract/contract";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

const _style: CSSProperties = {
  marginTop: "20px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 12px 32px 0 rgba(93, 92, 92, 0.12)",
  border: "none",
};

interface IContractEdit {}

const ContractCreate: FunctionComponent<IContractEdit> = () => {
  const [data, setData] = useState<ICreateContract>(createContractState);
  const [projectData, setProjectData] = useState<ICreateContractProject[]>();
  const [documentsList, setDocumentsList] = useState<{ id: string }[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectFilter = getContractProjectState;

  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormContract | null>(null);

  const [userData, setUserData] = useState<ICreateContractUser>(
    createContractUserState
  );
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [contractsPanelOpened, setContractsPanelOpened] = useState(false);
  const [contractsDocumentsPanelOpened, setContractsDocumentsPanelOpened] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Connection.getFormAddContract().then((response) => {
      setForm(response.data);
      setData({
        ...response.data.model,
        category: response.data.categories.value,
        status: response.data.statuses.value,
        type: response.data.types.value,
      });
      response.data?.header &&
        dispatch({ type: "SET_HEADER", payload: response.data.header });
    });
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    const dataToSend = {
      ...data,
      contractor: userData,
      projectDocumentTemplates: documentsList,
    };
    event.preventDefault();
    handleToast(Connection.createContract(dataToSend), setErrors).then(() =>
      history.push(contractDocumentPath)
    );
    return true;
  };
  const handleChangePesel = (value: string) => {
    setErrors((e: any) => ({ ...e, contractor: {} }));
    if (isValidPesel(value)) {
      getUserData(value);
    } else {
      setErrors((e: any) => ({
        ...e,
        contractor: {
          ...e.contractor,
          pesel: "Niepoprawny format numeru PESEL",
        },
      }));
    }
    setUserData((data) => ({ ...data, pesel: value?.toString() ?? "" }));
  };

  const getUserData = (value: string) => {
    Connection.getContractUserData(value)
      .then((response) => {
        if (response.data) {
          setUserData({
            ...userData,
            ...response.data,
            customerId: response.data.customer.id,
          });
        } else {
          setErrors({ contractor: { pesel: "Pesel nie został znaleziony" } });
          setUserData({ ...createContractUserState, pesel: value });
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.errors);
      });
  };

  const handleTypeChange = (value: string) => {
    ContractProjectConnection.getContractProject({
      ...projectFilter,
      type: value === "SINGLE" ? "SINGLE" : "CONSOLIDATED",
    }).then((response) => {
      setProjectData(response.data.data);
    });
    setData({ ...data, type: value?.toString() ?? "" });
  };
  const handleContractorValid = () => {
    Connection.getContractSectionOneValid(userData)
      .then((response) => {
        if (response.status === 200) {
          setErrors({ ...errors, contractor: {} });
          setStep(1);
        }
      })
      .catch((error) => {
        setErrors({ ...errors, contractor: error?.response?.data?.errors });
      });
  };
  const handleContractValid = () => {
    Connection.getContractSectionContractValid(data)
      .then((response) => {
        if (response.status === 200) {
          setErrors({});
          setStep(2);
        }
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
      });
  };
  const handleProjectValid = () => {
    Connection.getContractSectionProjectValid(data)
      .then((response) => {
        if (response.status === 200) {
          setErrors({});
          setStep(3);
        }
      })
      .catch((error) => {
        setErrors({ ...errors, project: error?.response?.data?.errors });
      });
  };
  const handleDocumentValid = () => {
    Connection.getContractSectionDocumentValid({
      projectDocumentTemplates: documentsList,
    })
      .then((response) => {
        if (response.status === 200) {
          setErrors({});
          setStep(4);
        }
      })
      .catch((error) => {
        setErrors({ ...errors, project: error?.response?.data?.errors });
      });
  };
  const handleDatesValid = () => {
    setStep(5);
  };
  const handleRoleChange = (value: any) => {
    setData((data) => ({
      ...data,
      roleId: value?.toString() ?? "",
    }));
    setUserData((data) => ({
      ...data,
      roleId: value?.toString() ?? "",
    }));
  };
  const disabledContractorStep = step > 0;
  const disabledContractStep = step > 1;
  const disabledProjectStep = step > 2;
  const disabledDocumentStep = step > 3;
  const disabledDatesStep = step > 4;
  const sentDisabled = step < 5;

  const _arrowStyle = {
    width: "30px",
    height: "30px",
  };
  const _headerStyle = {
    fontSize: "14px",
  };
  const _headerWrapperStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "20px",
  };
  const _arrowToggle: CSSProperties = {
    position: "relative",
    textAlign: "right",
    color: "#ffaf38",
    opacity: 0.4,
    cursor: "pointer",
    transition: "top 260ms",
    top: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleContractsPanelOpened = () => (
    <>
      <div style={_headerWrapperStyle}>
        <span style={_headerStyle}>Umowy użytkownika:</span>
        <div
          onClick={() => setContractsPanelOpened(!contractsPanelOpened)}
          style={_arrowToggle}>
          {contractsPanelOpened ? (
            <ArrowUpLine style={_arrowStyle} />
          ) : (
            <ArrowDownLine style={_arrowStyle} />
          )}
        </div>
      </div>
    </>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleContractsDocumentsPanelOpened = () => (
    <>
      <div style={_headerWrapperStyle}>
        <span style={_headerStyle}></span>
        <div
          onClick={() =>
            setContractsDocumentsPanelOpened(!contractsDocumentsPanelOpened)
          }
          style={_arrowToggle}>
          {contractsDocumentsPanelOpened ? (
            <ArrowUpLine style={_arrowStyle} />
          ) : (
            <ArrowDownLine style={_arrowStyle} />
          )}
        </div>
      </div>
    </>
  );

  if (!form) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Steps
            current={step}
            style={{
              position: "sticky",
              top: "50px",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}>
            <Steps.Item
              title="Kontraktor"
              onClick={() => {
                setStep(0);
              }}
            />
            <Steps.Item
              title="Umowa"
              onClick={() => {
                setStep(1);
              }}
            />
            <Steps.Item
              title="Projekt"
              onClick={() => {
                setStep(2);
              }}
            />
            <Steps.Item
              title="Dokumenty"
              onClick={() => {
                setStep(3);
              }}
            />
            <Steps.Item
              title="Daty"
              onClick={() => {
                setStep(4);
              }}
            />
            <Steps.Item
              title="Zapis"
              onClick={() => {
                setStep(5);
              }}
            />
          </Steps>
          <SeparatorEmpty size={0.75} />
          <Section title={"Kontraktor"}>
            <Row>
              <Col xs={12}>
                <div>
                  Pesel:
                  <Input
                    disabled={disabledContractorStep}
                    type={"text"}
                    placeholder={"wpisz..."}
                    value={userData.pesel}
                    onChange={handleChangePesel}
                  />
                  {errors?.contractor?.pesel && (
                    <div style={errorStyle}>{errors.contractor.pesel}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              {isValidPesel(userData.pesel) && (
                <>
                  <SeparatorEmpty size={0.75} />
                  <Col xs={12}>
                    <div>
                      Imię:
                      <Input
                        disabled={disabledContractorStep}
                        type={"text"}
                        placeholder={"wpisz..."}
                        value={userData.firstName}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            firstName: value?.toString() ?? "",
                          }))
                        }
                      />
                      {errors?.contractor?.firstName && (
                        <div style={errorStyle}>
                          {errors.contractor.firstName}
                        </div>
                      )}
                    </div>
                    <SeparatorEmpty size={0.75} />
                    <div>
                      Nazwisko:
                      <Input
                        disabled={disabledContractorStep}
                        type={"text"}
                        placeholder={"wpisz..."}
                        value={userData.lastName}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            lastName: value?.toString() ?? "",
                          }))
                        }
                      />
                      {errors?.contractor?.lastName && (
                        <div style={errorStyle}>
                          {errors.contractor.lastName}
                        </div>
                      )}
                    </div>
                    <SeparatorEmpty size={0.75} />
                    <div>
                      Dokument tożsamości:
                      <Input
                        disabled={disabledContractorStep}
                        type={"text"}
                        placeholder={"wpisz..."}
                        value={userData.idCard}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            idCard: value?.toString() ?? "",
                          }))
                        }
                      />
                      {errors?.contractor?.idCard && (
                        <div style={errorStyle}>{errors.contractor.idCard}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div>
                      Email:
                      <Input
                        disabled={disabledContractorStep}
                        type={"text"}
                        placeholder={"wpisz..."}
                        value={userData.email}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            email: value?.toString() ?? "",
                          }))
                        }
                      />
                      {errors?.contractor?.email && (
                        <div style={errorStyle}>{errors.contractor.email}</div>
                      )}
                    </div>
                    <SeparatorEmpty size={0.75} />
                    <div>
                      Telefon:
                      <Input
                        disabled={disabledContractorStep}
                        type={"text"}
                        placeholder={"wpisz..."}
                        value={userData.phone}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            phone: value?.toString() ?? "",
                          }))
                        }
                      />
                      {errors?.contractor?.phone && (
                        <div style={errorStyle}>{errors.contractor.phone}</div>
                      )}
                    </div>
                    <SeparatorEmpty size={0.75} />
                    <div>
                      Klient:
                      <SelectPicker
                        block
                        disabled={
                          disabledContractorStep || !isEmpty(userData.id)
                        }
                        data={form.customers.options ?? []}
                        value={userData.customerId}
                        onChange={(value) =>
                          setUserData((data) => ({
                            ...data,
                            customerId: value?.toString() ?? "",
                          }))
                        }
                        labelKey={"name"}
                        valueKey={"id"}
                      />
                      {errors?.contractor?.customer && (
                        <div style={errorStyle}>
                          {errors.contractor.customer}
                        </div>
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col xs={24}>
                <SeparatorEmpty size={0.75} />
                {userData?.id && (
                  <>
                    <Panel
                      header={"Umowy użytkownika:"}
                      bordered
                      style={_style}
                      collapsible={true}
                      defaultExpanded={false}
                      onEntered={() => setContractsPanelOpened(true)}
                      onExited={() => setContractsPanelOpened(false)}>
                      <ContractorHistoryList contractorId={userData.id} />
                    </Panel>
                  </>
                )}
              </Col>
            </Row>
            <SeparatorEmpty size={0.75} />
            <Row>
              <ActionsContainer>
                <ButtonSubmit
                  form={form}
                  name={"Dalej"}
                  disabled={disabledContractorStep}
                  onClick={handleContractorValid}
                />
              </ActionsContainer>
            </Row>
          </Section>

          {disabledContractorStep && (
            <Section title={"Umowa"}>
              <Row>
                <Col xs={12}>
                  <div>
                    Rodzaj projektu:
                    <SelectPicker
                      block
                      disabled={disabledContractStep}
                      data={form.types.options ?? []}
                      value={data.type}
                      //@ts-ignore
                      onChange={handleTypeChange}
                      labelKey={"name"}
                      valueKey={"id"}
                    />
                  </div>
                  {errors?.type && <div style={errorStyle}>{errors.type}</div>}
                  <SeparatorEmpty size={0.75} />
                  <div>
                    Rodzaj umowy:
                    <SelectPicker
                      block
                      disabled={disabledContractStep}
                      data={form.categories.options ?? []}
                      value={data.category}
                      onChange={(value) =>
                        setData((data) => ({
                          ...data,
                          category: value?.toString() ?? "",
                        }))
                      }
                      labelKey={"name"}
                      valueKey={"id"}
                    />
                    {errors?.category && (
                      <div style={errorStyle}>{errors.category}</div>
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    Status:
                    <SelectPicker
                      block
                      disabled={disabledContractStep}
                      data={form.statuses.options ?? []}
                      value={data.status}
                      onChange={(value) =>
                        setData((data) => ({
                          ...data,
                          status: value?.toString() ?? "",
                        }))
                      }
                      labelKey={"name"}
                      valueKey={"id"}
                    />
                    {errors?.status && (
                      <div style={errorStyle}>{errors.status}</div>
                    )}
                  </div>
                  <SeparatorEmpty size={0.75} />
                  <div>
                    Stanowisko:
                    <SelectPicker
                      block
                      disabled={disabledContractStep}
                      data={form.projectRoles.options ?? []}
                      value={data.roleId}
                      onChange={handleRoleChange}
                      labelKey={"name"}
                      valueKey={"id"}
                    />
                    {errors?.roleId && (
                      <div style={errorStyle}>{errors.roleId}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <SeparatorEmpty size={0.75} />
              <Row>
                <ActionsContainer>
                  <ButtonSubmit
                    form={form}
                    name={"Dalej"}
                    disabled={disabledContractStep}
                    onClick={handleContractValid}
                  />
                </ActionsContainer>
              </Row>
            </Section>
          )}

          {disabledContractStep && (
            <Section title={"Projekt"}>
              {(projectData || form.projects) && (
                <Col xs={12}>
                  Projekt:
                  <SelectPicker
                    block
                    disabled={disabledProjectStep}
                    data={projectData ?? form.projects.options}
                    value={data.contractProjectId}
                    onChange={(value) =>
                      setData((data) => ({
                        ...data,
                        contractProjectId: value?.toString() ?? "",
                      }))
                    }
                    labelKey={"name"}
                    valueKey={"id"}
                  />
                  {errors?.project?.contractProjectId && (
                    <div style={errorStyle}>
                      {errors.project.contractProjectId}
                    </div>
                  )}
                </Col>
              )}
              <SeparatorEmpty size={0.75} />
              <Row>
                <ActionsContainer>
                  <ButtonSubmit
                    form={form}
                    name={"Dalej"}
                    disabled={disabledProjectStep}
                    onClick={handleProjectValid}
                  />
                </ActionsContainer>
              </Row>
            </Section>
          )}
          {disabledProjectStep && (
            <Section title={"Dokumenty"}>
              <Col xs={24}>
                {data.contractProjectId && (
                  <Panel
                    header={"Dokumenty projektu"}
                    bordered
                    style={_style}
                    collapsible={true}
                    defaultExpanded={false}
                    onEntered={() => setContractsDocumentsPanelOpened(true)}
                    onExited={() => setContractsDocumentsPanelOpened(false)}>
                    <ContractDocumentAssignedList
                      documentsList={setDocumentsList}
                      disabled={disabledDocumentStep}
                      contractProjectId={data.contractProjectId ?? "--"}
                    />
                  </Panel>
                )}
                {errors?.project?.projectDocumentTemplates && (
                  <div style={errorStyle}>
                    {errors.project.projectDocumentTemplates}
                  </div>
                )}
              </Col>
              <SeparatorEmpty size={0.75} />
              <Row>
                <ActionsContainer>
                  <ButtonSubmit
                    form={form}
                    name={"Dalej"}
                    disabled={disabledDocumentStep}
                    onClick={handleDocumentValid}
                  />
                </ActionsContainer>
              </Row>
            </Section>
          )}
          {disabledDocumentStep && (
            <Section title={"Daty"}>
              <Row>
                <SeparatorEmpty size={0.75} />
                <Col xs={12}>
                  Data rozpoczęcia:
                  <DatePicker
                    format={"yyyy-MM-dd"}
                    isoWeek
                    oneTap
                    placement="autoVerticalEnd"
                    disabled={disabledDatesStep}
                    onChange={(date: Date) =>
                      setData({
                        ...data,
                        startDate: format(date, "yyyy-MM-dd"),
                      })
                    }
                    value={
                      new Date(
                        isEmpty(data.startDate)
                          ? dayjs().valueOf()
                          : data.startDate
                      )
                    }
                    style={{ width: "98%" }}
                    shouldDisableDate={(date?: Date) =>
                      date !== undefined &&
                      date < new Date(format(new Date(), "yyyy-MM-dd"))
                    }
                  />
                </Col>
                <Col xs={12}>
                  Data zakończenia:
                  <DatePicker
                    format={"yyyy-MM-dd"}
                    isoWeek
                    oneTap
                    placement="autoVerticalEnd"
                    disabled={disabledDatesStep}
                    onChange={(date: Date) =>
                      setData({ ...data, endDate: format(date, "yyy-MM-dd") })
                    }
                    value={
                      !isEmpty(data.endDate)
                        ? new Date(data.endDate)
                        : undefined
                    }
                    style={{ width: "100%" }}
                    shouldDisableDate={(date?: Date) =>
                      date !== undefined &&
                      date < new Date(format(new Date(), "yyyy-MM-dd"))
                    }
                  />
                </Col>
              </Row>
              <SeparatorEmpty size={0.75} />
              <Row>
                <ActionsContainer>
                  <ButtonSubmit
                    form={form}
                    name={"Dalej"}
                    disabled={disabledDatesStep}
                    onClick={handleDatesValid}
                  />
                </ActionsContainer>
              </Row>
            </Section>
          )}

          <ActionsContainer>
            <ButtonSubmit form={form} disabled={sentDisabled} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default ContractCreate;
