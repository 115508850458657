import React, { FunctionComponent } from "react";
import Input from "../../../../global/atoms/Input";
import Section from "../../../../global/atoms/section/Section";
import { ICreateLocation, SetState } from "../../../../utils/models";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";
import AddressPicker from "global/AddressPicker";
import _ from "lodash";
import { Col } from "rsuite";
import { ILocationForm } from "./LocationForm";

interface IAddressSelectSection {
  errors: any;
  data: ICreateLocation;
  setData: SetState<ICreateLocation>;
  saveExitCallback?: () => Promise<any>;
  form?: ILocationForm;
  disabled?: boolean;
}

const AddressSelectSection: FunctionComponent<IAddressSelectSection> = (
  props
) => {
  const projectSettings = useSelector(
    (root: IRoot) => root.project?.projectSettings
  );

  const setAddress = (key: string, addressId: string | null) => {
    props.setData((d) => ({
      ...d,
      [key]: { id: addressId ?? "", name: "" },
    }));
  };

  const getAddress = (key: string): string | undefined => {
    return _.isEmpty(props.data[key].id) ? null : props.data[key].id;
  };

  return (
    <>
      <AddressPicker
        errors={props.errors}
        name={"addressId"}
        readonly={props.disabled === true}
        addresses={
          props.form?.model.address && props.disabled === true
            ? [props.form.model.address]
            : undefined
        }
        key={`address-picker-${getAddress("addressId") ?? "none"}`}
        value={getAddress("addressId")}
        onSelected={(addressId) => setAddress("addressId", addressId)}
      />
      {(projectSettings === undefined ||
        (projectSettings.isEnableInvoice &&
          (projectSettings.isEnablePayer ||
            projectSettings.isEnableRecipient))) && (
        <Section title={"Opcje sprzedaży"}>
          {(projectSettings === undefined || projectSettings.isEnablePayer) && (
            <>
              <Col xs={12}>
                <Input
                  value={props.data.payerName}
                  placeholder={"Nazwa nabywcy"}
                  name={"payerName"}
                  errors={props.errors}
                  state={props.data}
                  setState={props.setData}
                  disabled={props.disabled === true}
                />
                <AddressPicker
                  key={`address-payer-picker-${
                    getAddress("payerAddressId") ?? "none"
                  }`}
                  label={"Adres nabywcy"}
                  name={"payerAddressId"} //for cancel tag
                  value={getAddress("payerAddressId")}
                  addresses={
                    props.form?.model.payerAddress && props.disabled === true
                      ? [props.form.model.payerAddress]
                      : undefined
                  }
                  onSelected={(addressId) =>
                    setAddress("payerAddressId", addressId)
                  }
                  readonly={props.disabled === true}
                />
              </Col>
            </>
          )}
          {(projectSettings === undefined ||
            projectSettings.isEnableRecipient) && (
            <>
              <Col xs={12}>
                <Input
                  value={props.data.recipientName}
                  placeholder={"Nazwa odbiorcy"}
                  name={"recipientName"}
                  errors={props.errors}
                  state={props.data}
                  setState={props.setData}
                  disabled={props.disabled === true}
                />
                <AddressPicker
                  key={`address-recipient-picker-${
                    getAddress("recipientAddressId") ?? "none"
                  }`}
                  name={"recipientAddressId"}
                  addresses={
                    props.form?.model.recipientAddress &&
                    props.disabled === true
                      ? [props.form.model.recipientAddress]
                      : undefined
                  }
                  label={"Adres odbiorcy"}
                  value={getAddress("recipientAddressId")}
                  onSelected={(addressId) =>
                    setAddress("recipientAddressId", addressId)
                  }
                  readonly={props.disabled === true}
                />
              </Col>
            </>
          )}
          <SeparatorEmpty />
        </Section>
      )}
    </>
  );
};

export default AddressSelectSection;
