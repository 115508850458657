import AxiosHelper, { default as axios } from "../AxiosHelper";
import { getOrganizationId, getAuthHeader, getBaseApiUrl } from "../helpers";
import { ICreateCustomer, IGetCustomers } from "../models";
import { AxiosPromise } from "axios";

const CustomersConnection = {
  getCustomers: (data: IGetCustomers) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  createCustomer: (data: ICreateCustomer) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  updateCustomer: (id: string, data: ICreateCustomer) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/${id}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  deleteCustomers: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers`,
      { customers: data }
    );
  },

  getCustomersFiltersForm: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/filter/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getCustomersFormAdd: () => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getCustomersFormEdit: (id: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/${id}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  getListForm: (): AxiosPromise => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/customers/list/form`
    );
  },
};

export default CustomersConnection;
