import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateContractDocumentTemplate } from "utils/models/contract/contractDocumentTemplateModels";
import { createContractDocumentTemplateState } from "utils/states/contract/contractDocumentTemplateStates";
import Connection from "utils/connections/contract/contractDocumentTemplatesConnection";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import { Checkbox, Col, Row, SelectPicker, Uploader, Input } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { ISelect } from "utils/models";
import { CKEditor } from "ckeditor4-react";
import { FileType } from "rsuite/esm/Uploader/Uploader";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

interface IContractDocumentTemplateEdit {}

interface IFormContractDocumentTemplateEdit {
  types: ISelect;
  statuses: ISelect;
  categories: ISelect;
  contents: ISelect;
}

const ContractDocumentTemplateEdit: FunctionComponent<
  IContractDocumentTemplateEdit
> = () => {
  const [data, setData] = useState<ICreateContractDocumentTemplate>(
    createContractDocumentTemplateState
  );
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormContractDocumentTemplateEdit | null>(
    null
  );
  const [fileList, setFileList] = useState<FileType[]>([]);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const type: "add" | "edit" = id === undefined ? "add" : "edit";

  useEffect(() => {
    if (data.type === "TEXT") {
      setData({
        ...data,
        fileName: "",
        fileBase64: "",
      });
      setFileList([]);
    }
    if (data.type === "FILE") {
      setData({
        ...data,
        content: "",
      });
    }
  }, [data.type]);

  if (type === "edit") {
    useEffect(() => {
      Connection.getFormEditContractDocumentTemplate(id).then((response) => {
        setData({
          ...response.data.model,
          category: response.data.model.category.id,
          status: response.data.model.status.id,
          type: response.data.model.type.id,
        });
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      Connection.getFormAddContractDocumentTemplate().then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (type === "add") {
      handleToast(
        Connection.createContractDocumentTemplate(data),
        setErrors
      ).then(() => history.push("/contracts/templates"));
      return true;
    }
    handleToast(
      Connection.updateContractDocumentTemplate(id, data),
      setErrors
    ).then(() => history.push("/contracts/templates"));
  };

  if (!form) return <Spinner />;

  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div>
              Nazwa szablonu:
              <Input
                type={"text"}
                placeholder={"wpisz..."}
                name={"name"}
                value={data.name}
                onChange={(value) =>
                  setData({ ...data, name: value?.toString() ?? "" })
                }
              />
              {errors?.name && <div style={errorStyle}>{errors.name}</div>}
            </div>
            <SeparatorEmpty size={0.75} />
            <div>
              Rodzaj dokumentu:
              <SelectPicker
                block
                data={form.categories.options ?? []}
                disabled={form.categories.disabled || type === "edit"}
                value={data.category ?? form.categories.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    category: value?.toString() ?? "",
                    type: value?.toString() === "CONTRACT" ? "TEXT" : data.type,
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.category && (
                <div style={{ color: "#a23531" }}>{errors.category}</div>
              )}
            </div>
            <SeparatorEmpty size={0.75} />
            <div>
              Rodzaj danych:
              <SelectPicker
                block
                disabled={
                  form.types.disabled ||
                  data.category === "CONTRACT" ||
                  type === "edit"
                }
                data={form.types.options ?? []}
                value={data.type ?? form.types.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    type: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.type && <div style={errorStyle}>{errors.type}</div>}
            </div>
          </Col>
          <Col xsOffset={1} xs={11}>
            <div>
              Status:
              <SelectPicker
                block
                disabled={form.statuses.disabled}
                data={form.statuses.options ?? []}
                value={data.status ?? form.statuses.value}
                onChange={(value) =>
                  setData({ ...data, status: value?.toString() ?? "" })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.status && <div style={errorStyle}>{errors.status}</div>}
            </div>
            <SeparatorEmpty size={1.5} />
            Opcje dukumentu:
            <Row>
              <Checkbox
                checked={data.isRequiredAccept}
                onChange={(value, chk) =>
                  setData((d) => ({ ...d, isRequiredAccept: chk }))
                }>
                Akceptacja wymagana
              </Checkbox>
            </Row>
            <Row>
              {" "}
              <Checkbox
                checked={data.isRequiredSign}
                onChange={(value, chk) =>
                  setData((d) => ({ ...d, isRequiredSign: chk }))
                }>
                Podpis wymagany
              </Checkbox>{" "}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {data.type === "FILE" && data.category !== "CONTRACT" && (
              <div>
                <Input
                  disabled={true}
                  type={"text"}
                  placeholder={""}
                  name={"fileName"}
                  value={data.fileName}
                  onChange={(value) =>
                    setData({ ...data, fileName: value?.toString() ?? "" })
                  }
                />
                <Uploader
                  action={""}
                  draggable
                  fileList={fileList}
                  accept="image/*, application/pdf"
                  autoUpload={false}
                  multiple={false}
                  onRemove={() => {
                    setData((data) => ({
                      ...data,
                      fileName: "",
                      fileBase64: "",
                    }));
                  }}
                  onChange={(fileList) => {
                    const file = fileList.pop();
                    const uf: File | undefined = file?.blobFile;
                    if (file !== undefined && uf !== undefined) {
                      const reader = new FileReader();
                      reader.readAsDataURL(uf);
                      reader.onload = function () {
                        setData((data) => ({
                          ...data,
                          fileName: uf.name,
                          fileBase64: reader.result?.toString(),
                        }));
                        setFileList([file]);
                      };
                      reader.onerror = function (error) {
                        console.log("Error: ", error);
                      };
                    }
                  }}
                  data={data}>
                  <div
                    style={{
                      height: 200,
                      paddingLeft: "20%",
                      paddingRight: "20%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <p>
                      Przeciągnij pliki lub kliknij aby dodać plik dokumentu{" "}
                    </p>
                  </div>
                </Uploader>
              </div>
            )}
            {data.type == "TEXT" && (
              <div>
                <CKEditor
                  name={"content"}
                  initData={data.content}
                  config={{
                    removePlugins: ["image", "link"],
                  }}
                  onChange={(ev) => {
                    setData((data) => ({
                      ...data,
                      content: ev.editor.getData(),
                    }));
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <ActionsContainer>
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default ContractDocumentTemplateEdit;
