// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aZpKW9D2dHB73xd3NzMF{width:100%;height:100vh;padding:24px;display:flex;justify-content:center;align-items:center;background:#fafafa}.sFApGefZE8Kki6GAe4xO{width:100%;max-width:512px;padding:64px 32px;background-color:#fff;box-shadow:0 12px 32px 0 rgba(93,92,92,.12);border-radius:16px}.QhBIwcqOqWdMgeplVVP3{display:block;color:red;font-size:.9rem;margin-top:15px}.CIT6ibQtou75iWDGMW9I{margin:0 auto;display:block;margin-bottom:64px;width:220px;max-width:100%}", "",{"version":3,"sources":["webpack://./src/views/security/security.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,eAAA,CACA,iBAAA,CACA,qBAAA,CACA,2CAAA,CACA,kBAAA,CAGF,sBACE,aAAA,CACA,SAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,aAAA,CACA,aAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100vh;\n  padding: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #fafafa;\n}\n\n.form {\n  width: 100%;\n  max-width: 512px;\n  padding: 64px 32px;\n  background-color: #ffffff;\n  box-shadow: 0 12px 32px 0 rgba(93, 92, 92, 0.12);\n  border-radius: 16px;\n}\n\n.errors {\n  display: block;\n  color: red;\n  font-size: 0.9rem;\n  margin-top: 15px;\n}\n\n.logo {\n  margin: 0 auto;\n  display: block;\n  margin-bottom: 64px;\n  width: 220px;\n  max-width: calc(100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "aZpKW9D2dHB73xd3NzMF",
	"form": "sFApGefZE8Kki6GAe4xO",
	"errors": "QhBIwcqOqWdMgeplVVP3",
	"logo": "CIT6ibQtou75iWDGMW9I"
};
export default ___CSS_LOADER_EXPORT___;
