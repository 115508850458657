import React from "react";
import CSS from "csstype";
import Button from "rsuite/Button";
import { FCC } from "utils/models";

interface IButtonOutlined {
  style?: CSS.Properties;
  onClick?: () => void;
  type?: "submit" | "button" | "reset" | undefined;
  disabled?: boolean;
  attrName?: string;
}

const ButtonOutlined: FCC<IButtonOutlined> = ({
  children,
  style,
  onClick,
  type = "button",
  disabled = false,
  attrName = "button",
}) => {
  const onCLickEvent: any = disabled ? null : onClick;

  return (
    <Button
      onClick={onCLickEvent}
      appearance={"ghost"}
      type={type}
      style={style}
      name={attrName}
      disabled={disabled}>
      {children}
    </Button>
    // <button onClick={onCLickEvent} type={type} className={classNames} style={style} disabled={disabled}>
    //   {children}
    // </button>
  );
};

export default ButtonOutlined;
