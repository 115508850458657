import React from "react";
import ButtonOutlined from "./ButtonOutlined";
import { useHistory } from "react-router-dom";
import { FCC } from "../../utils/models";

interface IButtonGoBack {
  onClick?: any;
}

const ButtonGoBack: FCC<IButtonGoBack> = ({ onClick, ...props }) => {
  const history = useHistory();

  const handleClick = () => history.goBack();

  return (
    <ButtonOutlined onClick={onClick ? onClick : handleClick} type={"button"}>
      {props.children ?? "Powrót"}
    </ButtonOutlined>
  );
};

export default ButtonGoBack;
