import React, { FunctionComponent, useEffect, useState } from "react";

import ScTable from "global/table/ScTable";

import connection from "utils/connections/contract/contractProjectConnection";
// import {IGetContractProjectDocument} from "../../../utils/models/contract/contractProjectModels";
import { getContractProjectDocumentState } from "../../../utils/states/contract/contractProjectStates";
import { Table } from "rsuite";
import { isEmpty } from "lodash";

interface IContractDocumentAssignedList {
  contractProjectId: string;
  disabled?: boolean;
  documentsList: any;
}

const ContractDocumentAssignedList: FunctionComponent<
  IContractDocumentAssignedList
> = (props) => {
  const [data, setData] = useState<any | "null" | "error">(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    connection
      .getContractProjectAssignedDocuments(props.contractProjectId, {
        ...getContractProjectDocumentState,
        contractProjectId: props.contractProjectId,
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        const docList: any = [];
        response?.data?.data?.map((el: any) => {
          docList.push({ id: el.documentTemplateId });
        });
        props.documentsList(docList);
      });
  }, []);

  if (data === "error") return <div>Błąd pobierania danych</div>;

  return (
    <>
      {isEmpty(data?.data) ? (
        <>Brak dołączonych dokumentów</>
      ) : (
        <ScTable data={data?.data ?? []} loading={loading}>
          <Table.Column flexGrow={2} align="left">
            <Table.HeaderCell depth={0} width={0}>
              Szablon
            </Table.HeaderCell>
            <Table.Cell width={0} depth={0} dataKey="type">
              {(rowData) => {
                return <span>{rowData.documentTemplateName}</span>;
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} align="left" fixed="right">
            <Table.HeaderCell depth={0} width={0}>
              Rodzaj dokumentu
            </Table.HeaderCell>
            <Table.Cell width={0} depth={0} dataKey="category">
              {(rowData) => {
                return <span>{rowData.documentTemplateCategory.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} align="left" fixed="right">
            <Table.HeaderCell depth={0} width={0}>
              Rodzaj danych
            </Table.HeaderCell>
            <Table.Cell width={0} depth={0} dataKey="status">
              {(rowData) => {
                return <span>{rowData.documentTemplateType.name}</span>;
              }}
            </Table.Cell>
          </Table.Column>
        </ScTable>
      )}
    </>
  );
};

export default ContractDocumentAssignedList;
