import React, { FunctionComponent, useState } from "react";
import { Dropdown, Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import { _paginate30 } from "../../../../utils/states";
import KilometersConnection from "../../../../utils/connections/kilometers";
import { IRequestKilometersFilter } from "./models";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { generatePath, useHistory } from "react-router-dom";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import {
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import { getProjectIdFromUrl, handleSortColumn } from "utils/helpers";
import dayjs from "dayjs";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import PlusIcon from "@rsuite/icons/Plus";

const KilometersList: FunctionComponent = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const defaultFilters: IRequestKilometersFilter = {
    userId: "",
    dateFrom: 0,
    dateTo: 0,
    ..._paginate30,
  };
  const [filters, setFilters] =
    useState<IRequestKilometersFilter>(defaultFilters);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const projectId = getProjectIdFromUrl() ?? "";
  if (data === "error") return null;

  const getEditUrl = (kilometersId: string): string => {
    return generatePath("/projects/:id/kilometers/:kilometersId/edit", {
      kilometersId: kilometersId,
      id: projectId ?? "",
    });
  };
  const history = useHistory();
  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper
          deleteSelected={{
            chosenToDelete: chosenToDelete,
            promiseFunctionDelete: KilometersConnection.batchDelete,
            callback: () => {
              setTriggerUpdate(Date.now());
              setChosenToDelete([]);
            },
          }}>
          <Dropdown.Item
            disabled={!projectId}
            onClick={() =>
              history.push(
                generatePath("/projects/:id/kilometers/add", {
                  id: projectId ?? "",
                })
              )
            }
            icon={<PlusIcon />}>
            Dodaj nowy
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            data={data?.data ?? []}
            loading={loading}
            bordered
            fillHeight
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="center">
              <Table.HeaderCell depth={0} width={0}>
                <ToggleAllCheckbox
                  data={
                    // @ts-ignore
                    data.data
                  }
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                />
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2} align="left" sortable>
              <Table.HeaderCell>Użytkownik</Table.HeaderCell>
              <Table.Cell dataKey="userName">
                {(rowData) => (
                  <ButtonListRedirect redirectUrl={getEditUrl(rowData.id)}>
                    {rowData.userName}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="left" sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey="date">
                {(rowData) => {
                  return <>{dayjs(rowData.date).format("YYYY-MM-DD")}</>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="right" sortable>
              <Table.HeaderCell>Prywatne km</Table.HeaderCell>
              <Table.Cell dataKey="privateKilometers" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right" sortable>
              <Table.HeaderCell>Cena paliwa</Table.HeaderCell>
              <Table.Cell dataKey="fuelPrice" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right" sortable>
              <Table.HeaderCell>Litry paliwa</Table.HeaderCell>
              <Table.Cell dataKey="fuelLiters" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right" sortable>
              <Table.HeaderCell>Wartość początkowa</Table.HeaderCell>
              <Table.Cell dataKey="valueStart" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right" sortable>
              <Table.HeaderCell>Wartość końcowa</Table.HeaderCell>
              <Table.Cell dataKey="valueStop" />
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={KilometersConnection.list}
        formGet={KilometersConnection.filterForm.bind(null, projectId)}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectKilometers_${getProjectIdFromUrl()}`}
        triggerLoad={triggerUpdate}
        elements={[
          {
            type: FILTER_SELECT,
            label: "Użytkownik",
            stateKey: "userId",
            formKey: "users",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data od",
            stateKey: "dateFrom",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data do",
            stateKey: "dateTo",
          },
        ]}
      />
    </>
  );
};

export default KilometersList;
