import { IGetPaginationBase } from "utils/models";
import AxiosHelper from "utils/AxiosHelper";
import { getBaseApiUrl, getOrganizationId } from "utils/helpers";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/global_reports/work_time_history`;

export interface IWTHListRequestData extends IGetPaginationBase {
  customerId: string;
  dateFrom: string;
  dateTo: string;
  roleId: string;
  userId: string;
}
export interface IWTHFileRequestData extends IWTHListRequestData {}

const WorkTimeHistoryConnection = {
  form: () => AxiosHelper.get(`${baseUrl}/form`),
  list: (requestData: IWTHListRequestData) =>
    AxiosHelper.post(`${baseUrl}/list`, requestData),
  getFile: (requestData: IWTHFileRequestData) =>
    AxiosHelper.getBlobPost(`${baseUrl}/get-file`, requestData),
};

export default WorkTimeHistoryConnection;
