import React, { FunctionComponent, useEffect, useState } from "react";
import Connection from "utils/connections/contract/contractProjectConnection";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import { Col, Row, SelectPicker, Input, DatePicker } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { IOptionBase, ISelect } from "utils/models";
import { ICreateContractProject } from "../../../utils/models/contract/contractProjectModels";
import { createContractProjectState } from "../../../utils/states/contract/contractProjectStates";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import DoubleSidePicker from "../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import { contractProjectPath } from "../../../routes/contract/project";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

interface IContractProjectEdit {}

interface IFormContractProjectEdit {
  types: ISelect;
  statuses: ISelect;
  notices: ISelect;
  settlement: ISelect;
  assignedProjects: ISelect;
  projects: ISelect;
  externalProjects: ISelect;
}

const ContractProjectEdit: FunctionComponent<IContractProjectEdit> = () => {
  const [data, setData] = useState<ICreateContractProject>(
    createContractProjectState
  );
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormContractProjectEdit>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const type: "add" | "edit" = id === undefined ? "add" : "edit";
  if (type === "edit") {
    useEffect(() => {
      Connection.getFormEditContractProject(id).then((response) => {
        setData({
          ...response.data.model,
          settlement: response.data.model.settlement.id,
          notice: response.data.model.notice.id,
          status: response.data.model.status.id,
          type: response.data.model.type.id,
        });
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      Connection.getFormAddContractProject().then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }
  const clearData = () => {
    if (data.type === "CONSOLIDATED") {
      setData((data) => ({ ...data, externalProject: "" }));
    } else {
      setData((data) => ({ ...data, assigned: [], unassigned: [] }));
    }
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    clearData();
    if (type === "add") {
      handleToast(Connection.createContractProject(data), setErrors).then(() =>
        history.push(contractProjectPath)
      );
      return true;
    }
    handleToast(Connection.updateContractProject(id, data), setErrors).then(
      () => history.push(contractProjectPath)
    );
  };
  const handleAssignedProjectChange = (
    assignedIds: string[],
    unAssignedIds: string[]
  ) => {
    const items: Array<IOptionBase> = [
      ...(form?.projects.options ?? []),
      ...(form?.assignedProjects.options ?? []),
    ];
    const toAssign = items.filter((el: IOptionBase) =>
      assignedIds.includes(el.id)
    );
    if (toAssign !== undefined) {
      setForm((data: any) => ({
        ...data,
        assignedProjects: { ...data.assignedProjects, options: toAssign },
      }));
      setData((data) => ({
        ...data,
        assigned: toAssign.map((el: IOptionBase) => ({ id: el.id })),
      }));
    }
    const unAssigned = items.filter((el: IOptionBase) =>
      unAssignedIds.includes(el.id)
    );
    if (unAssigned !== undefined) {
      setForm((data: any) => ({ ...data, projects: { options: unAssigned } }));
      setData((data) => ({
        ...data,
        unassigned: unAssigned.map((el: IOptionBase) => ({ id: el.id })),
      }));
    }
  };
  if (!form) return <Spinner />;
  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div>
              Nazwa:
              <Input
                type={"text"}
                placeholder={"wpisz..."}
                name={"number"}
                value={data.name}
                onChange={(value) =>
                  setData({ ...data, name: value?.toString() ?? "" })
                }
              />
              {errors?.name && <div style={errorStyle}>{errors?.name}</div>}
            </div>
            <SeparatorEmpty size={0.75} />
            <div>
              Rodzaj projektu:
              <SelectPicker
                block
                data={form.types.options ?? []}
                value={data.type}
                onChange={(value) =>
                  setData({
                    ...data,
                    type: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
            </div>
            {errors?.type && <div style={errorStyle}>{errors?.type}</div>}
            <SeparatorEmpty size={0.75} />
            <Col xs={12} style={{ paddingLeft: 0 }}>
              Data rozpoczęcia:
              <DatePicker
                format={"yyyy-MM-dd"}
                isoWeek
                onChange={(date: Date) =>
                  setData({ ...data, startDate: format(date, "yyyy-MM-dd") })
                }
                value={
                  !isEmpty(data.startDate)
                    ? new Date(data.startDate)
                    : undefined
                }
                style={{ width: "98%" }}
                shouldDisableDate={(date?: Date) =>
                  date !== undefined &&
                  date < new Date(format(new Date(), "yyyy-MM-dd"))
                }
              />
              {errors?.startDate && (
                <div style={errorStyle}>{errors?.startDate}</div>
              )}
            </Col>
            <Col xs={12} style={{ paddingRight: 0 }}>
              Data zakończenia:
              <DatePicker
                format={"yyyy-MM-dd"}
                isoWeek
                onChange={(date: Date) =>
                  setData({ ...data, endDate: format(date, "yyy-MM-dd") })
                }
                value={
                  !isEmpty(data.endDate) ? new Date(data.endDate) : undefined
                }
                style={{ width: "100%" }}
                shouldDisableDate={(date?: Date) =>
                  date !== undefined &&
                  date < new Date(format(new Date(), "yyyy-MM-dd"))
                }
              />
              {errors?.endDate && (
                <div style={errorStyle}>{errors?.endDate}</div>
              )}
            </Col>
          </Col>
          <Col xs={12}>
            <div>
              Status:
              <SelectPicker
                block
                data={form.statuses.options ?? []}
                value={data.status ?? form.statuses.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    status: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.status && <div style={errorStyle}>{errors.status}</div>}
            </div>
            <SeparatorEmpty size={0.75} />
            <div>
              Okres rozliczeniowy:
              <SelectPicker
                block
                data={form.settlement.options ?? []}
                value={data.settlement ?? form.settlement.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    settlement: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
            </div>
            {errors?.settlement && (
              <div style={errorStyle}>{errors?.settlement}</div>
            )}
            <SeparatorEmpty size={0.75} />

            <div>
              Okres wypowiedzenia:
              <SelectPicker
                block
                data={form.notices.options ?? []}
                value={data.notice ?? form.notices.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    notice: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.notice && <div style={errorStyle}>{errors.notice}</div>}
            </div>
          </Col>
        </Row>
        {data.type === "CONSOLIDATED" && (
          <Row>
            <Col xs={24}>
              <DoubleSidePicker
                emptyMessageAssigned={"Brak Przypisanych projektów"}
                emptyMessageNotAssigned={"Nic do przypisania"}
                assigned={form.assignedProjects.options}
                unassigned={form.projects.options}
                onChange={handleAssignedProjectChange}
                form={form}
                itemType={"base"}
                filtersHidden={true}
              />
            </Col>
          </Row>
        )}
        {data.type === "SINGLE" && (
          <Row>
            <Col xs={12}>
              Projekt zewnętrzny:
              <SelectPicker
                block
                data={form?.externalProjects?.options ?? []}
                value={data?.projectId ?? form.externalProjects.value}
                onChange={(value) =>
                  setData({
                    ...data,
                    projectId: value?.toString() ?? "",
                  })
                }
                labelKey={"name"}
                valueKey={"id"}
              />
              {errors?.projectId && (
                <div style={errorStyle}>{errors.projectId}</div>
              )}
            </Col>
          </Row>
        )}

        <ActionsContainer>
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default ContractProjectEdit;
