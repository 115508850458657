import React, { FunctionComponent } from "react";
import { SetState } from "../../utils/models";

interface IColorPicker {
  stateKey: string;
  state: any;
  setState: SetState;
  title?: string;
  defaultColor?: string;
}

const ColorPicker: FunctionComponent<IColorPicker> = (props) => {
  const setColor = (value: string) => {
    props.setState({
      ...props.state,
      [props.stateKey]: value,
    });
  };

  const getValue = (): string => {
    if (props.state[props.stateKey]) return props.state[props.stateKey];
    return props.defaultColor ? props.defaultColor : "#ffaf38";
  };

  return (
    <>
      <div>
        <label>{props.title ? props.title : "Kolor"}</label>
        <input
          type="color"
          value={getValue()}
          onChange={(e) => setColor(e.target.value)}
          style={{ width: "100px" }}
        />
      </div>
    </>
  );
};

export default ColorPicker;
