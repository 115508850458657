import React, { FunctionComponent } from "react";

import { Checkbox, Col, InputNumber, Row, SelectPicker } from "rsuite";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { IRate } from "../../../utils/models/contract/contractSalaryModels";
import { IFormContractService } from "./ContractServiceEdit";
import { isNumber } from "lodash";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

const formatFloat = (value: number | string): number => {
  //if(isNumber(value.toString))
  let _value = parseFloat(value.toString());
  if (_value < 0 || !isNumber(_value) || isNaN(_value)) _value = 0;
  return _value;
};

interface IContractSalaries {
  value?: string;
  rateState: IRate;
  setRateState: (state: IRate) => void;
  errors: any;
  form: IFormContractService;
  header: boolean;
}

const ContractProjectSalariesRateEdit: FunctionComponent<IContractSalaries> = (
  props
) => {
  const errors = props.errors;
  return (
    <>
      {props.header && (
        <>
          <Row>
            <Col xs={6}>Typ wynagrodzenia:</Col>
            <Col xs={6}>Rodzaj:</Col>
            <Col xs={4}>Stawka:</Col>
            <Col xs={4}>Podwyższenie:</Col>
            <Col xs={4}>Brutto:</Col>
          </Row>
          <SeparatorEmpty size={0.75} />
        </>
      )}
      <Row>
        <Col xs={6}>
          <SelectPicker
            block
            disabled={true}
            searchable={false}
            data={props.form.rateTypes.options ?? []}
            value={props.value ?? props.rateState.type}
            onChange={(value) =>
              props.setRateState({
                ...props.rateState,
                type: value?.toString() ?? "",
              })
            }
            labelKey={"name"}
            valueKey={"id"}
          />
          {errors?.type && <div style={errorStyle}>{errors?.type}</div>}
        </Col>
        <Col xs={6}>
          <SelectPicker
            block
            searchable={false}
            cleanable={false}
            data={props.form.rateSettlements.options ?? []}
            value={props.rateState.settlement}
            onChange={(value) =>
              props.setRateState({
                ...props.rateState,
                settlement: value?.toString() ?? "",
              })
            }
            labelKey={"name"}
            valueKey={"id"}
          />
          {errors?.settlement && (
            <div style={errorStyle}>{errors?.settlement}</div>
          )}
        </Col>
        <Col xs={4}>
          <InputNumber
            prefix="PLN"
            defaultValue={0.0}
            step={0.01}
            name={"amount"}
            value={props.rateState.amount ?? 0}
            onChange={(value) =>
              props.setRateState({
                ...props.rateState,
                amount: formatFloat(value),
              })
            }
          />
          {errors?.amount && <div style={errorStyle}>{errors?.amount}</div>}
        </Col>
        <Col xs={4}>
          <InputNumber
            name={"extraAmount"}
            value={props.rateState.extraAmount ?? 0}
            onChange={(value) =>
              props.setRateState({
                ...props.rateState,
                extraAmount: formatFloat(value),
              })
            }
          />
          {errors?.extraAmount && (
            <div style={errorStyle}>{errors?.extraAmount}</div>
          )}
        </Col>
        <Col xs={4}>
          <Checkbox
            name={"isGross"}
            checked={props.rateState.isGross}
            onChange={(value, checked) => {
              props.setRateState({ ...props.rateState, isGross: checked });
            }}
          />
          {errors?.isGross && (
            <div style={errorStyle}>{errors?.extraAmount}</div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ContractProjectSalariesRateEdit;
