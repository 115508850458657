import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";
interface IState extends IBaseTemplateState {}

const SalesDocumentsTemplate: FunctionComponent = () => {
  const initialState = {
    templateName: "",
    columnsData: [
      {
        label: "Dokument",
        name: "document",
        active: true,
        options: [
          {
            name: "documentNumber",
            label: "Numer dokumentu",
            active: true,
          },
          {
            name: "customer",
            label: "Dystrybutor",
            active: true,
          },
          {
            name: "user",
            label: "Wystawił",
            active: true,
          },
          {
            name: "documentDate",
            label: "Data dokumentu",
            active: true,
          },
          {
            name: "deliveryDate",
            label: "Data dostarczenia",
            active: true,
          },
          {
            name: "paymentDate",
            label: "Data płatności",
            active: true,
          },
          {
            name: "status",
            label: "Status",
            active: true,
          },
          {
            name: "paymentType",
            label: "Rodzaj płatności",
            active: true,
          },
          {
            name: "terminalId",
            label: "ID urządzenia",
            active: false,
          },
          {
            name: "documentType",
            label: "Rodzaj dokumentu",
            active: false,
          },
          {
            name: "nip",
            label: "Nip nabywcy",
            active: false,
          },
          {
            name: "dailyReport",
            label: "Czy wykonano raport dobowy Tak/Nie",
            active: false,
          },
          {
            name: "isPaid",
            label: "Zapłacona",
            active: true,
          },
          {
            name: "countPrinted",
            label: "Liczba wydrukowanych",
            active: true,
          },
        ],
      },
      {
        label: "Lokalizacja",
        name: "location",
        active: true,
        options: [
          {
            name: "name",
            label: "Nazwa lokalizacji",
            active: true,
          },
          {
            name: "location_increment_number",
            label: "Nr porządkowy lokalizacji",
            active: false,
          },
          {
            name: "networkName",
            label: "Nazwa sieci",
            active: true,
          },
          {
            name: "street",
            label: "Ulica",
            active: true,
          },
          {
            name: "houseNumber",
            label: "Numer budynku",
            active: true,
          },
          {
            name: "apartmentNumber",
            label: "Numer mieszkania",
            active: true,
          },
          {
            name: "city",
            label: "Miejscowość",
            active: true,
          },
          {
            name: "postcode",
            label: "Kod pocztowy",
            active: true,
          },
          {
            name: "community",
            label: "Gmina",
            active: true,
          },
          {
            name: "district",
            label: "Powiat",
            active: true,
          },
          {
            name: "voivodeship",
            label: "Województwo",
            active: true,
          },
        ],
      },
      {
        label: "Sumy",
        name: "priceTotal",
        active: true,
        options: [
          {
            name: "priceAmountTotal",
            label: "Suma netto",
            active: true,
          },
          {
            name: "priceTaxTotal",
            label: "Suma VAT",
            active: true,
          },
          {
            name: "priceAmountGrossTotal",
            label: "Suma brutto",
            active: true,
          },
        ],
      },
      {
        label: "Sprzedaż",
        name: "productSale",
        active: true,
        options: [
          {
            name: "productSaleAmount",
            label: "Sprzedaż - Ilość",
            active: true,
          },
          {
            name: "productSalePriceNetto",
            label: "Sprzedaż - Wartość netto",
            active: true,
          },
          {
            name: "productSalePriceTax",
            label: "Sprzedaż - Wartość VAT",
            active: true,
          },
          {
            name: "productSalePriceGross",
            label: "Sprzedaż - Wartość brutto",
            active: true,
          },
        ],
      },
    ],
  };
  const [state, setState] = useState<IState>(initialState);
  return (
    <>
      <BaseTemplate
        sortable
        initialState={initialState}
        state={state}
        setState={setState}
        type={2}
      />
    </>
  );
};

export default SalesDocumentsTemplate;
