import React, { FunctionComponent, useEffect, useState } from "react";
import { IAddress, ICoords, ICreateAddress, IOptionsTyped } from "utils/models";
import { createAddressState } from "utils/states";
import { useHistory } from "react-router-dom";
import AddressesConnection from "utils/connections/addresses";
import { deepClone, getPartIdFromUrl, handleToast } from "utils/helpers";
import ActionsContainer from "global/atoms/ActionsContainer";
import Spinner from "global/atoms/Spinner/Spinner";
import { AxiosPromise, AxiosResponse } from "axios";
import Row from "rsuite/Row";
import {
  Button,
  Checkbox,
  Col,
  Form,
  IconButton,
  Input,
  InputGroup,
  Message,
  Panel,
} from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { FormGroupSelect, FormGroupText } from "utils/formHelper";
import AddressCoordsModal from "./AddressCoordsModal";
import { isEmpty } from "lodash";
import TrashIcon from "@rsuite/icons/Trash";
import { validate } from "uuid";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

interface IFormModel {
  id: string;
  apartmentNumber: string;
  houseNumber: string;
  city: {
    id: string;
    name: string;
    community: ICommunity;
  } | null;
  community: {
    id: string;
    name: string;
    district: IDistrict;
  } | null;
  coords: null | ICoords;
  country: {
    id: string;
    name: string;
  } | null;
  district: {
    id: string;
    name: string;
    voivodeship: IVoivodeship;
  } | null;
  postCode: {
    id: string;
    name: string;
    city: ICity;
  } | null;
  street: {
    id: string;
    name: string;
  } | null;
  voivodeship: {
    id: string;
    name: string;
  } | null;
  verified: boolean;
}

interface IParent {
  id: string;
  name: string;
}

interface IVoivodeship extends IParent {
  country: IParent;
}

interface IDistrict extends IParent {
  voivodeship: IVoivodeship;
}

interface ICommunity extends IParent {
  district: IDistrict;
}

interface ICity extends IParent {
  community: ICommunity;
}

export interface IForm {
  model: IFormModel;
  countries: IOptionsTyped<{
    id: string;
    name: string;
    code: string;
  }>;
  voivodeships: IOptionsTyped<{
    id: string;
    name: string;
    countryId: string;
  }>;
  cities: IOptionsTyped<{
    id: string;
    name: string;
    community: ICommunity;
  }>;

  postCodes: IOptionsTyped<{
    id: string;
    name: string;
    city: ICity;
  }>;
  communities: IOptionsTyped<{
    id: string;
    name: string;
    districtId: string;
    district: IDistrict;
  }>;
  districts: IOptionsTyped<{
    id: string;
    name: string;
    voivodeshipId: string;
    voivodeship: IVoivodeship;
  }>;
  streets: IOptionsTyped<{
    id: string;
    name: string;
    cityId: string;
    communityId: string;
    districtId: string;
  }>;
}

interface IFormState extends ICreateAddress {
  postCode: string;
  city: string;
  street: string;
  community: string;
  district: string;
}

export interface IFormRequest {
  country: string;
  voivodeship: string;
  postCode: string;
  city: string;
  street: string;
  district: string;
  community: string;
}

export interface IAddressNewCheck {
  postCode: boolean;
  city: boolean;
  street: boolean;
  community: boolean;
  district: boolean;
}

interface IAddressProps {
  refAddressId?: string;
  setRefAddressId?: (id) => void;
  isLocal?: boolean;
  setOpen?: (close: boolean) => void;
  addressBack?: IAddress;
  setAddressBack?: (address: IAddress) => void;
}

const AddressAdd: FunctionComponent<IAddressProps> = (props) => {
  const initFormState = {
    ...createAddressState,
    postCode: "",
    city: "",
    street: "",
    community: "",
    district: "",
  };

  const [data, setData] = useState<ICreateAddress>(createAddressState);
  const [formState, setFormState] = useState<IFormState>(initFormState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IForm | null>(null);
  const [mapShow, setMapShow] = useState(false);
  const [filterRequest, setFilterRequest] = useState<Partial<IFormRequest>>({});

  const [addressNewPartForm, setAddressNewPartForm] = useState<
    Partial<IFormRequest>
  >({});
  const [addressNewPartFormChange, setAddressNewPartChange] = useState<
    Partial<IAddressNewCheck>
  >({
    street: false,
    postCode: false,
    city: false,
    community: false,
  });

  const [formLoading, setFormLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [coordsString, setCoordsString] = useState("");

  const history = useHistory();
  const id = getPartIdFromUrl("addresses");
  const addressId = props.refAddressId ?? id ?? "";
  const isEdit = !!addressId;
  const isLocal = props.isLocal;

  useEffect(() => {
    if (
      (filterRequest.postCode && filterRequest.postCode.length < 2) ||
      (filterRequest.street && filterRequest.street.length < 3) ||
      (filterRequest.city && filterRequest.city.length < 3)
    ) {
      return;
    }

    setFormLoading(true);
    let promise: AxiosPromise<IForm>;
    if (isEdit)
      promise = AddressesConnection.getFormEditAddress(
        addressId,
        filterRequest
      );
    else promise = AddressesConnection.getFormCreateAddress(filterRequest);

    promise.then((data) => {
      if (data !== undefined) {
        if (isEdit && !isChanged) {
          const _model = data.data.model as IFormModel;
          const _data: ICreateAddress = {
            ..._model,
            street: _model?.street?.id ?? "",
            postCode: _model?.postCode?.id ?? "",
            city: _model?.city?.id ?? "",
            country: _model?.country?.id ?? "",
            voivodeship: _model?.voivodeship?.id ?? "",
            district: _model?.district?.id ?? "",
            community: _model?.community?.id ?? "",
          };
          setData(_data);
          // @ts-ignore
          setFormState((f) => ({
            ...f,
            ..._data,
            postCode: _model?.postCode?.id ?? "",
            city: _model?.city?.id ?? "",
            street: _model?.street?.id ?? "",
            community: _model?.community?.id ?? "",
            district: _model?.district?.id ?? "",
          }));
          if (!coordsString && _data.coords) {
            setCoordsString(
              _data.coords.latitude + ":" + _data.coords.longitude
            );
          }
          const _form: IForm = data.data;

          setForm({
            ..._form,
            //@ts-ignore
            cities: { options: [{ ..._model?.city }] },
            //@ts-ignore
            postCodes: { options: [{ ..._model?.postCode }] },
            //@ts-ignore
            streets: { options: [{ ..._model?.street }] },
          });
        } else {
          setForm((f) => ({ ...f, ...data.data }));
        } // append not replace}
      }

      setFormLoading(false);
    });
  }, [filterRequest]);
  const handleSubmit = (asNew = false) => {
    const _data = { ...data, ...formState };

    _data.district = formState.district;
    _data.community = addressNewPartFormChange.community
      ? addressNewPartForm.community ?? ""
      : formState.community;
    _data.city = addressNewPartFormChange.city
      ? addressNewPartForm.city ?? ""
      : formState.city;
    _data.street = addressNewPartFormChange.street
      ? addressNewPartForm.street ?? ""
      : formState.street;
    _data.postCode = addressNewPartFormChange.postCode
      ? addressNewPartForm.postCode ?? ""
      : formState.postCode;
    if (coordsString)
      _data.coords = {
        latitude: parseFloat(coordsString.split(":")[0]),
        longitude: parseFloat(coordsString.split(":")[1]),
      };

    let savePromise: Promise<void | AxiosResponse<any>>;
    if (isEdit && !asNew) {
      savePromise = AddressesConnection.updateAddress(addressId, _data);
    } else {
      savePromise = AddressesConnection.createAddress(_data);
    }
    handleToast(savePromise, setErrors).then((res) => {
      if (isLocal) {
        props.setOpen && props.setOpen(false);
        props.setRefAddressId &&
          props.setRefAddressId(isEdit && !asNew ? addressId : res.data.id);
        props.setAddressBack && props.setAddressBack(res.data);
      } else {
        history.push("/datasets/addresses");
      }
    });
  };

  if (form === null) return <Spinner />;
  const NOT_RELATED = { id: "", name: "Brak", group: "" };
  /*  const postCodeIsValid = (postCode?: string): boolean => {
    if (!postCode) return false;
    const polishPostalCodeRegex = /^[0-9]{2}-[0-9]{3}$/;
    return polishPostalCodeRegex.test(postCode.replace("_", ""));
  };*/

  const getCitiesOptions = () => {
    if (isEmpty(form?.cities?.options)) return [NOT_RELATED];
    let searchCities = deepClone(form?.cities?.options) ?? [];
    if (formState.community)
      searchCities = searchCities.filter(
        (city) =>
          !formState.community || city?.community?.id == formState.community
      );
    if (!searchCities) return [{ id: "", name: "dodaj nowy" }];
    return searchCities.map((c) => ({
      id: c.id,
      name: `${c.name} (gm: ${c?.community?.name ?? "nie ustalono"})`,
      group: `(pow: ${c?.community?.district?.name ?? ""})`,
    }));
  };
  const getPostCodesOptions = () => {
    if (isEmpty(form?.postCodes?.options)) return [NOT_RELATED];
    let searchPostCodes = deepClone(form?.postCodes?.options) ?? [];
    if (formState.community)
      searchPostCodes =
        searchPostCodes.filter(
          (pc) =>
            !formState.community ||
            pc?.city?.community?.id == formState.community
        ) ?? [];
    if (!searchPostCodes) return [{ id: "", name: "dodaj nowy" }];
    return searchPostCodes.map((p) => ({
      id: p.id,
      name: p.name,
      group: `(pow: ${p?.city?.community?.district?.name ?? "nie ustalono"})`,
    }));
  };
  const getCommunityOptions = () => {
    if (isEmpty(form?.communities?.options)) return [NOT_RELATED];
    let searchCommunities = deepClone(form?.communities?.options) ?? [];
    searchCommunities =
      searchCommunities.filter(
        (community) =>
          !formState.district || community?.district?.id == formState.district
      ) ?? [];
    if (!searchCommunities) return [{ id: "", name: "dodaj nowy" }];
    return (
      searchCommunities.map((c) => ({
        id: c.id,
        name: `${c.name} (pow: ${c?.district?.name})`,
        group: `(woj: ${c?.district?.voivodeship?.name ?? "nie wybrano"})`,
      })) ?? [{ id: "", name: "dodaj nowy" }]
    );
  };
  const clearErrors = () => {
    const _errors: Array<any> = [];
    Object.entries({ formState }).forEach(([name, value]) => {
      if (value) {
        _errors.push({ [name]: "" });
      }
    });
    setErrors((e) => ({ ...e, ..._errors }));
  };
  const formHandleChange = (state: Partial<IFormState>) => {
    setIsChanged(true);
    setFormState((s) => ({ ...s, ...state }));
    clearErrors();
  };
  const getAddressPartName = (id: string, section: string) => {
    return form?.[section]?.options.find((d) => d.id == id)?.name;
  };
  const newAddressPartInput = (dataPartName: string, label: string | null) => {
    return (
      <Form.Group>
        <Form.ControlLabel>{label ?? "Nowa wartość"}</Form.ControlLabel>
        <InputGroup style={{ width: "100%" }}>
          <Form.Control
            name={`newAddress${dataPartName}`} //prevent on form change
            errorMessage={errors?.[dataPartName]}
            value={addressNewPartForm?.[dataPartName]}
            onChange={(value) => {
              setAddressNewPartForm((a) => ({
                ...a,
                [dataPartName]: value,
              }));
            }}
            accepter={Input}
          />
          {addressNewPartFormChange?.[dataPartName] && (
            <InputGroup.Addon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAddressNewPartForm((a) => ({
                  ...a,
                  [dataPartName]: "",
                }));
                setAddressNewPartChange((a) => ({
                  ...a,
                  [dataPartName]: false,
                }));
              }}>
              <TrashIcon />
            </InputGroup.Addon>
          )}
        </InputGroup>
      </Form.Group>
    );
  };

  const renderEmptyOption = (item, datePartName: string) => {
    if (!item?.props?.data) {
      return (
        <div className={"rs-picker-none"}>
          <IconButton
            style={{ width: "100%" }}
            appearance={"ghost"}
            onClick={() => {
              setAddressNewPartChange((s) => ({ ...s, [datePartName]: true }));
              if (filterRequest?.[datePartName]) {
                setAddressNewPartForm((s) => ({
                  ...s,
                  [datePartName]: filterRequest?.[datePartName],
                }));
                setIsChanged(true);
              }
            }}
            startIcon={<AddOutlineIcon />}>
            Dodaj nowy
          </IconButton>
        </div>
      );
    }
    return <>{item}</>;
  };
  const editPartAddon = (value: string, datePart: string) => {
    const handleClick = (section: string) => {
      setIsChanged(true);
      setAddressNewPartChange((a) => ({
        ...a,
        [section]: true,
      }));
    };
    return (
      <IconButton
        icon={<AddOutlineIcon />}
        onClick={handleClick.bind(null, datePart)}
      />
    );
  };
  const setPresetFilterRequest = (searchString: string, section: string) => {
    const city = getAddressPartName(formState.city, "cities");
    const community = getAddressPartName(formState.community, "communities");
    const postCode = getAddressPartName(formState.postCode, "postCodes");
    const district = getAddressPartName(formState.district, "districts");
    const _filter = {
      ...filterRequest,
      city: city,
      community: community,
      postCode: postCode,
      district: district,
    };
    if (searchString)
      setFilterRequest((f) => ({
        ...f,
        ..._filter,
        [section]: searchString,
      }));
  };

  return (
    <>
      <Panel shaded={!isLocal}>
        {isEdit && (
          <>
            <Message showIcon type="warning" header="Uwaga">
              To jest edycja istniejącego adresu. Wszelkie zmiany będą dotyczyć{" "}
              <strong>WSZYSTKICH</strong> obiektów powiązanych z tym
              adresem!&nbsp;
              <strong>
                Edycja adresu powinna dotyczyć wyłącznie błędnych danych!
              </strong>
            </Message>
            <SeparatorEmpty />
          </>
        )}
        <SeparatorEmpty />
        <Form
          onSubmit={handleSubmit}
          onChange={formHandleChange}
          formValue={formState}>
          <Panel bordered style={{ backgroundColor: "white" }}>
            <Row>
              <Col md={6} xs={24}>
                <FormGroupSelect
                  errorMessage={errors?.country}
                  placement={"autoVerticalStart"}
                  label={"Kraj"}
                  fieldName={"country"}
                  labelKey={"name"}
                  valueKey={"id"}
                  options={form.countries.options}
                />
              </Col>
              <Col md={6} xs={24}>
                <FormGroupSelect
                  errorMessage={errors?.voivodeship}
                  label={"Województwo"}
                  fieldName={"voivodeship"}
                  labelKey={"name"}
                  placement={"autoVerticalStart"}
                  onChange={(value: string) => {
                    setFilterRequest(() => ({
                      voivodeship: value,
                      district: "",
                      community: "",
                      city: "",
                      postCode: "",
                      street: "",
                    }));
                    if (value) {
                      const countryId = form?.voivodeships.options.find(
                        (v) => v.id == value
                      )?.countryId;
                      if (countryId) {
                        setFormState((s) => ({
                          ...s,
                          voivodeship: value,
                          country: countryId,
                        }));
                      }
                    } else {
                      setFormState(initFormState);
                    }
                  }}
                  valueKey={"id"}
                  options={
                    form.voivodeships.options.filter(
                      (p) => !data.country || p.countryId === data.country
                    ) ?? []
                  }
                />
              </Col>
              <Col md={6} xs={24}>
                {addressNewPartFormChange.district ? (
                  newAddressPartInput("district", "Nowy powiat")
                ) : (
                  <FormGroupSelect
                    loading={formLoading}
                    label={"Powiat"}
                    errorMessage={errors?.district}
                    placement={"autoVerticalStart"}
                    fieldName={"district"}
                    onSearch={(searchString) => {
                      setPresetFilterRequest(searchString, "district");
                    }}
                    onChange={(districtId) => {
                      const district = form?.districts?.options.find(
                        (d) => d.id == districtId
                      );
                      if (district) {
                        setFilterRequest((d) => ({
                          ...d,
                          district: district.name,
                          community: "",
                          city: "",
                        }));
                        setFormState((s) => ({
                          ...s,
                          district: districtId,
                          voivodeship: district?.voivodeship?.id ?? "",
                          country: district?.voivodeship?.country?.id ?? "",
                          community: "",
                        }));
                      } else {
                        setFormState((s) => ({
                          ...s,
                          district: districtId,
                          community: "",
                          city: "",
                          street: "",
                          postCode: "",
                        }));
                        setFilterRequest((f) => ({ ...f, district: "" }));
                      }
                    }}
                    options={form.districts?.options ?? []}
                  />
                )}
              </Col>
              <Col md={6} xs={24}>
                {addressNewPartFormChange.community ? (
                  newAddressPartInput("community", "Nowa gmina")
                ) : (
                  <>
                    <FormGroupSelect
                      label={"Gmina"}
                      errorMessage={errors?.community}
                      loading={formLoading}
                      placement={"autoVerticalStart"}
                      fieldName={"community"}
                      //@ts-ignore
                      onSearch={(searchString) => {
                        setPresetFilterRequest(searchString, "community");
                      }}
                      renderMenu={(item) =>
                        renderEmptyOption(item, "community")
                      }
                      onChange={(communityId) => {
                        const community = form?.communities?.options.find(
                          (c) => c.id == communityId
                        );
                        if (community) {
                          setFilterRequest((d) => ({
                            ...d,
                            community: community.name,
                          }));
                          setFormState((s) => ({
                            ...s,
                            community: communityId,
                            district: community?.district?.id,
                            voivodeship: community?.district?.voivodeship?.id,
                            country:
                              community?.district?.voivodeship?.country?.id,
                          }));
                        } else {
                          setFormState((s) => ({
                            ...s,
                            community: communityId,
                          }));
                          setFilterRequest((f) => ({ ...f, community: "" }));
                        }
                      }}
                      groupBy={
                        isEmpty(form?.communities?.options)
                          ? undefined
                          : "group"
                      }
                      options={getCommunityOptions() ?? []}>
                      {editPartAddon(
                        validate(formState.community)
                          ? getAddressPartName(
                              formState.community,
                              "communities"
                            )
                          : formState.community,
                        "community"
                      )}
                    </FormGroupSelect>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={24}>
                {addressNewPartFormChange.postCode ? (
                  newAddressPartInput("postCode", "Nowy kod pocztowy")
                ) : (
                  <FormGroupSelect
                    disabled={false}
                    placement={"autoVerticalStart"}
                    label={"Kod pocztowy"}
                    fieldName={"postCode"}
                    errorMessage={errors?.postCode}
                    loading={formLoading}
                    onSearch={(searchString) => {
                      setPresetFilterRequest(searchString, "postCode");
                    }}
                    renderMenu={(item) => renderEmptyOption(item, "postCode")}
                    groupBy={
                      isEmpty(form?.postCodes?.options) ? undefined : "group"
                    }
                    options={getPostCodesOptions() ?? []}
                    onChange={(postCodeId) => {
                      const postCode = form?.postCodes?.options.find(
                        (c) => c.id == postCodeId
                      );
                      if (postCode) {
                        setFilterRequest((d) => ({
                          ...d,
                          postCode: postCode.name,
                        }));
                        setFormState((s) => ({
                          ...s,
                          postCode: postCodeId,
                          city: postCode?.city?.id,
                          community: postCode?.city?.community?.id,
                          district: postCode?.city?.community.district.id,
                          voivodeship:
                            postCode?.city?.community?.district?.voivodeship
                              ?.id,
                          country:
                            postCode?.city?.community?.district?.voivodeship
                              ?.country.id,
                          street: "",
                        }));
                      } else {
                        setFormState((s) => ({ ...s, postCode: postCodeId }));
                        setFilterRequest((f) => ({ ...f, postCode: "" }));
                      }
                    }}>
                    {editPartAddon(
                      validate(formState.postCode)
                        ? getAddressPartName(formState.postCode, "postCodes")
                        : formState.postCode,
                      "postCode"
                    )}
                  </FormGroupSelect>
                )}
              </Col>
              <Col md={8} xs={24}>
                {addressNewPartFormChange.city ? (
                  newAddressPartInput("city", "Nowa miejscowość")
                ) : (
                  <FormGroupSelect
                    placement={"autoVerticalStart"}
                    label={"Miejscowość"}
                    fieldName={"city"}
                    errorMessage={errors?.city}
                    onSearch={(searchString) => {
                      setPresetFilterRequest(searchString, "city");
                    }}
                    loading={formLoading}
                    renderMenu={(item) => renderEmptyOption(item, "city")}
                    groupBy={form?.cities?.options ? undefined : "group"}
                    options={getCitiesOptions() ?? []}
                    onChange={(cityId) => {
                      const city = form?.cities?.options.find(
                        (c) => c.id == cityId
                      );
                      if (city) {
                        setFilterRequest((d) => ({
                          ...d,
                          city: city.name,
                          community: city.community.name,
                          district: city.community.district.name,
                          voivodeship: city.community.district.voivodeship.id,
                        }));
                        setFormState((s) => ({
                          ...s,
                          city: cityId,
                          community: city.community.id,
                          district: city.community.district.id,
                          voivodeship:
                            city.community.district.voivodeship?.id ?? "",
                          country:
                            city.community.district.voivodeship.country.id ??
                            "",
                          street: "",
                          postCode: "",
                        }));
                      } else {
                        setFormState((s) => ({ ...s, city: cityId }));
                        setFilterRequest((f) => ({ ...f, city: "" }));
                      }
                    }}>
                    {editPartAddon(
                      validate(formState.city)
                        ? getAddressPartName(formState.city, "cities")
                        : formState.city,
                      "city"
                    )}
                  </FormGroupSelect>
                )}
              </Col>
              <Col md={8} xs={24}>
                {addressNewPartFormChange.street ? (
                  newAddressPartInput("street", "Nowa ulica")
                ) : (
                  <FormGroupSelect
                    loading={formLoading}
                    placement={"autoVerticalStart"}
                    label={"Ulica"}
                    fieldName={"street"}
                    onSearch={(searchString) => {
                      setPresetFilterRequest(searchString, "street");
                    }}
                    renderMenu={(item) => renderEmptyOption(item, "street")}
                    options={form?.streets?.options ?? []}>
                    {editPartAddon(
                      validate(formState.street)
                        ? getAddressPartName(formState.street, "streets")
                        : formState.street,
                      "street"
                    )}
                  </FormGroupSelect>
                )}
              </Col>
              <Col md={2} xs={24}>
                <FormGroupText
                  fieldName={"houseNumber"}
                  placeholder={""}
                  label={"Budynek"}
                  errorMessage={errors?.houseNumber}
                />
              </Col>
              <Col md={2} xs={24}>
                <FormGroupText
                  fieldName={"apartmentNumber"}
                  placeholder={""}
                  label={"Lokal"}
                  errorMessage={errors?.apartmentNumber}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1} />
            <Row>
              <Col md={8} xs={24}>
                <Form.Group>
                  <Form.ControlLabel>
                    {"Współrzędne geograficzne"}
                  </Form.ControlLabel>
                  <InputGroup style={{ width: "100%" }}>
                    <Form.Control
                      name={"coords"}
                      value={coordsString}
                      onChange={(value) => setCoordsString(value)}
                      placeholder={""}
                      accepter={Input}
                    />
                    <InputGroup.Addon
                      style={{ paddingTop: "0", paddingBottom: "0px" }}>
                      <Button
                        size={"xs"}
                        appearance={"subtle"}
                        onClick={() => {
                          setMapShow(true);
                        }}>
                        Wybierz z mapy
                      </Button>
                    </InputGroup.Addon>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Checkbox
                disabled={isEdit ? false : !formState.street}
                checked={data.verified}
                onChange={(val, check) =>
                  setData((d) => ({ ...d, verified: check }))
                }>
                {"Adres zweryfikowany"}
              </Checkbox>
            </Form.Group>
          </Panel>
          <SeparatorEmpty />
          {isEdit && isChanged && (
            <>
              <Message
                showIcon
                type={"info"}
                header={"Uwaga"}
                style={{ width: "50%" }}>
                Zmieniasz istniejący adres. Czy chcesz zapisać go jako nowy?
                <ActionsContainer>
                  <Button
                    onClick={() => {
                      handleSubmit(true);
                    }}
                    appearance={"ghost"}>
                    Zapisz jako nowy adres
                  </Button>
                </ActionsContainer>
              </Message>
            </>
          )}
          <ActionsContainer>
            <Button
              onClick={() => {
                setData(createAddressState);
                setFormState(initFormState);
                setFilterRequest({});
                setAddressNewPartChange({});
                setAddressNewPartForm({});
              }}
              appearance={"ghost"}>
              Resetuj formularz
            </Button>
            {isLocal && isEdit && (
              <Button
                onClick={() => {
                  setIsChanged(false);
                  setErrors({});
                  if (typeof props.setOpen === "function") props.setOpen(false);
                }}
                appearance={"ghost"}>
                Anuluj
              </Button>
            )}
            <Button
              disabled={!isChanged}
              type={"submit"}
              appearance={"primary"}>
              Zapisz
            </Button>
          </ActionsContainer>
        </Form>
      </Panel>

      <AddressCoordsModal
        show={mapShow}
        onClose={() => setMapShow(false)}
        coords={data.coords}
        onSubmit={(_coords) => {
          setMapShow(false);
          setData((d) => ({ ...d, coords: _coords }));
          setCoordsString(_coords.latitude + ":" + _coords.longitude);
          setIsChanged(true);
        }}
      />
    </>
  );
};

export default AddressAdd;
