import React, { FunctionComponent, useEffect, useState } from "react";
import WarehouseConnection from "../../../../utils/connections/warehouse";
import { useHistory, useParams } from "react-router-dom";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import Section from "../../../../global/atoms/section/Section";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { Col, IconButton, Input, InputGroup, InputNumber, Row } from "rsuite";
import { confirmModalHandler } from "../../../../redux/store";
import {
  IWarehouseAddData,
  TYPE_INCOMING,
  TYPE_LOST,
  TYPE_OUTCOMING,
  TYPE_SHIFT,
} from "./WarehouseAdd";
import CloseIcon from "@rsuite/icons/Close";
import { handleToast } from "../../../../utils/helpers";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";

interface IPosition {
  id: string;
  availability: number;
  description: string;
  itemGroup: string;
  name: string;
}

interface IWarehouseAddItems {
  data: IWarehouseAddData;
  setData: (data: IWarehouseAddData) => void;
  reloadItems: boolean;
  setReloadItems: (r: boolean) => void;
  setErrors: (r: any) => void;
}

const WarehouseAddItems: FunctionComponent<IWarehouseAddItems> = ({
  data,
  setData,
  setErrors,
  reloadItems,
  setReloadItems,
}) => {
  const [items, setItems] = useState<Array<IPosition>>([]);
  const [itemSearch, setItemSearch] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const getItemsList = () => {
    let roleFromId = data.roleFromId;
    let userFromId = data.userFromId;
    if ([TYPE_INCOMING].includes(data.type)) {
      roleFromId = data.roleToId;
      userFromId = data.userToId;
    }
    if (!!roleFromId && !!userFromId && reloadItems) {
      WarehouseConnection.getItems(id, userFromId, roleFromId).then(
        (response) => {
          setItems(response.data.availabilityEntryItems.data);
          setReloadItems(false);
        }
      );
    }
    if (!roleFromId || !userFromId) setReloadItems(false);
  };

  useEffect(() => {
    getItemsList();
  }, [reloadItems, data]);

  const handleChangeAmount = (item: IPosition, value) => {
    let amount = parseInt(value);
    if (isNaN(amount) || amount < 0) {
      amount = 0;
    }
    if (
      item.availability < amount &&
      [TYPE_OUTCOMING, TYPE_LOST, TYPE_SHIFT].includes(data.type)
    ) {
      amount = item.availability;
    }
    const positions = data.positions.filter(
      (itemLoop) => itemLoop.itemId !== item.id
    );
    const position = data.positions.find(
      (itemLoop) => itemLoop.itemId == item.id
    );
    positions.push({
      ...position,
      itemId: item.id,
      amount: amount,
    });
    setData({ ...data, positions: positions });
  };

  const getPrice = (id: string) => {
    return data.positions.find((itemLoop) => itemLoop.itemId == id)?.price ?? 0;
  };
  const handleChangePrice = (item: IPosition, value) => {
    let price = parseFloat(value.toString().replace(",", "."));
    if (isNaN(price) || price < 0.01) {
      price = 0;
    }

    const positions = data.positions.filter((el) => el.itemId !== item.id);
    const position = data.positions.find((el) => el.itemId == item.id);
    positions.push({
      ...position,
      itemId: item.id,
      price: price,
    });

    setData({ ...data, positions: positions });
  };

  const getItemMoveAmount = (itemId: string) => {
    const items = data.positions.filter(
      (itemLoop) => itemLoop.itemId === itemId
    );
    if (items.length <= 0) {
      return 0;
    }
    return items[0].amount;
  };

  const getItemsFilteredByGroupType = (): Array<IPosition> => {
    if (!data.groupType && !itemSearch) {
      return items;
    }
    return items.filter(
      (itemLoop) =>
        itemLoop.itemGroup === (data.groupType ?? itemLoop.itemGroup) &&
        itemLoop.name.toLocaleLowerCase().includes(itemSearch.toLowerCase())
    );
  };

  const positionsToSend = () => {
    return data.positions.filter((el) => el.amount > 0);
  };
  const positionToSend = (item: IPosition) => {
    return data.positions?.find((el) => el.itemId == item.id && el.amount > 0);
  };
  const handleSave = () => {
    const _positions = data.positions.filter((el) => el.amount > 0);
    handleToast(
      WarehouseConnection.save(id, data.type, {
        ...data,
        positions: _positions,
      }),
      setErrors,
      "Dane nie zostały zapisane...",
      "Dane zapisane"
    ).then(() => history.push(`/projects/${id}/warehouse`));
  };
  const availableItemsCount = () =>
    items.filter((item) => item.availability > 0).length;
  const header = () => (
    <>
      <Row>
        <Col xs={12}>
          <span style={{ fontSize: "0.8em" }}>
            Produkty{" "}
            {!!positionsToSend().length && (
              <>do wysłania ({positionsToSend().length})</>
            )}
          </span>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <IconButton
            disabled={data.type == TYPE_INCOMING || !availableItemsCount()}
            appearance={positionsToSend().length ? "ghost" : "primary"}
            size="xs"
            onClick={confirmModalHandler(moveItemsQuestion(), () =>
              setData({
                ...data,
                //@ts-ignore
                positions: items.map((item) => ({
                  itemId: item.id,
                  amount: item.availability,
                  //price: 0,
                })),
              })
            )}
            icon={<ArrowRightLineIcon />}>
            Przesuń wszystko
            {availableItemsCount() ? <>&nbsp;({availableItemsCount()})</> : ""}
          </IconButton>
          <IconButton
            //appearance={positionsToSend().length ? "primary" : "ghost"}
            appearance={"ghost"}
            size="xs"
            disabled={!positionsToSend().length}
            onClick={confirmModalHandler(clearItemsQuestion(), () =>
              setData({ ...data, positions: [] })
            )}
            icon={<CloseIcon />}>
            Wyczyść formularz
          </IconButton>
        </Col>
      </Row>
    </>
  );

  const clearItemsQuestion = () =>
    `czy na pewno chcesz wyczyścić formularz operacji? ${
      positionsToSend().length ? `(${positionsToSend().length})` : ""
    }`;
  const moveItemsQuestion = () =>
    `czy na pewno chcesz przepisać wartości wg. stanów magazynowych? ${
      items.length ? `(${availableItemsCount()})` : ""
    }`;

  return (
    <>
      {reloadItems && (
        <SpinnerSmall
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            bottom: "200px",
            zIndex: 20,
            height: "20%",
          }}
        />
      )}
      <SeparatorEmpty />
      <Row>
        <Col xs={24}>
          <Section
            title={header()}
            style={{ paddingTop: "0", overflow: "hidden" }}>
            <Row
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                marginBottom: "10px",
                borderBottom: "1px solid rgba(93, 92, 92, 0.16)",
                backgroundColor: "#FBFBFB",
                fontWeight: "bold",
                color: "#13131552",
              }}>
              <Col xs={12}>
                {/*Nazwa*/}
                <InputGroup inside style={{ fontWeight: "normal" }}>
                  <Input
                    size={"sm"}
                    placeholder={"Wyszukaj"}
                    value={itemSearch}
                    onChange={(value: string) => {
                      setItemSearch(value);
                    }}
                  />

                  {!!itemSearch && (
                    <InputGroup.Button>
                      <CloseIcon onClick={() => setItemSearch("")} />
                    </InputGroup.Button>
                  )}
                </InputGroup>
              </Col>
              {data.type !== TYPE_INCOMING && <Col xs={6}>Stan</Col>}
              <Col xs={6}>
                {data.type !== TYPE_INCOMING
                  ? "Do przesunięcia"
                  : "Do przyjęcia"}
              </Col>

              {data.type == TYPE_INCOMING && <Col xs={6}>Cena dostawy</Col>}
            </Row>
            {data.type &&
              getItemsFilteredByGroupType().length == 0 &&
              `Brak produktów do wyświetlenia`}
            {getItemsFilteredByGroupType().map((item, index) => {
              return (
                <div key={index}>
                  <Row>
                    <Col xs={12}>
                      <InputGroup>
                        <Input
                          disabled={true}
                          type={"text"}
                          placeholder={""}
                          name={"name"}
                          value={item.name}
                        />
                        {!!positionToSend(item) && (
                          <InputGroup.Addon>
                            <CloseIcon
                              title={"Wyczyść dane pozycji"}
                              style={{ cursor: "pointer", color: "#E09616" }}
                              onClick={() =>
                                setData({
                                  ...data,
                                  positions: data.positions.filter(
                                    (el) => el.itemId !== item.id
                                  ),
                                })
                              }
                            />
                          </InputGroup.Addon>
                        )}
                      </InputGroup>
                    </Col>
                    {data.type !== TYPE_INCOMING && (
                      <Col xs={6}>
                        <Input
                          disabled={true}
                          type={"text"}
                          placeholder={"Stan"}
                          name={"availability"}
                          value={item.availability}
                        />
                      </Col>
                    )}
                    <Col xs={6}>
                      <InputNumber
                        type={"number"}
                        placeholder={"0"}
                        name={"amount"}
                        value={getItemMoveAmount(item.id)}
                        onChange={(value) => {
                          handleChangeAmount(item, value);
                        }}
                        id={item.id}
                        disabled={
                          item.availability <= 0 && data.type !== TYPE_INCOMING
                        }
                      />
                    </Col>
                    {data.type == TYPE_INCOMING && (
                      <Col xs={6}>
                        <InputNumber
                          disabled={getItemMoveAmount(item.id) < 1}
                          step={0.01}
                          placeholder={"0"}
                          name={"price"}
                          value={getPrice(item.id)}
                          onChange={(value) => {
                            handleChangePrice(item, value);
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                  {items.length - 1 !== index && <SeparatorEmpty />}
                </div>
              );
            })}
          </Section>
        </Col>
      </Row>
      <SeparatorEmpty size={1.5} />
      <ActionsContainer>
        <ButtonSubmit
          appearance={!positionsToSend().length ? "ghost" : "primary"}
          onClick={handleSave}
          disabled={!positionsToSend().length}
        />
      </ActionsContainer>
    </>
  );
};
export default WarehouseAddItems;
