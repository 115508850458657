import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";
import {
  ICreateContractDocumentTemplate,
  IGetContractDocumentTemplate,
} from "../../models/contract/contractDocumentTemplateModels";
import { AxiosPromise } from "axios";

export const path = `/contracts/documents/templates`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractDocumentTemplatesConnection = {
  getContractDocumentTemplate: (data: IGetContractDocumentTemplate) => {
    return AxiosHelper.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContractDocumentTemplate: (data: ICreateContractDocumentTemplate) => {
    return AxiosHelper.post(baseUrl, data, {
      headers: getAuthHeader(),
    });
  },
  updateContractDocumentTemplate: (
    id: string,
    data: ICreateContractDocumentTemplate
  ) => {
    return AxiosHelper.put(`${baseUrl}/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersContractDocumentTemplate: () => {
    return AxiosHelper.get(`${baseUrl}/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddContractDocumentTemplate: () => {
    return AxiosHelper.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractDocumentTemplate: (id: string) => {
    return AxiosHelper.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return AxiosHelper.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
  delete: (data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}`, { ids: data });
  },
  getListForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/form`);
  },
  getFilterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/filter/form`);
  },
};

export default ContractDocumentTemplatesConnection;
