import UniversalModal from "global/atoms/UniversalModal";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import React, { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import UserConnection from "utils/connections/user";
import { FCC, IOptionBase } from "utils/models";

interface IUsersAssignMobileVersion {
  open: boolean;
  onSubmit: (versionId: string) => void;
  onClose: (open: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UsersAssignMobileVersion: FCC<IUsersAssignMobileVersion> = (props) => {
  const [versionId, setVersionId] = useState<string | null>(null);
  const [versions, setVersions] = useState<Array<IOptionBase> | null>(null);

  useEffect(() => {
    UserConnection.getMobileAppVersions().then((res) => {
      setVersions(res.data);
    });
  }, []);

  const getBody = () => {
    return (
      <>
        <SelectPicker
          block
          placeholder={"Wybierz wersje"}
          cleanable={false}
          data={versions ?? []}
          labelKey="name"
          valueKey="id"
          searchable={false}
          loading={versions === null}
          onChange={(selected: string | null) => {
            setVersionId(selected);
          }}
        />
        <SeparatorEmpty />
      </>
    );
  };

  return (
    <UniversalModal
      title={"Przypisz wersje aplikacji mobilnej"}
      body={getBody()}
      open={props.open}
      onClose={props.onClose.bind(null, false)}
      onSubmit={props.onSubmit.bind(null, versionId ?? "")}
      submitButtonDisabled={!versionId}
    />
  );
};

export default UsersAssignMobileVersion;
