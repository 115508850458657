import { default as axios } from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { AxiosPromise } from "axios";
import {
  IRequestKilometers,
  IRequestKilometersFilter,
} from "../../views/projects/components/kilometers/models";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${getProjectIdFromUrl()}/kilometers`;

const KilometersConnection = {
  create: (data: IRequestKilometers): AxiosPromise<any> => {
    return axios.post(baseUrl, data);
  },
  update: (
    kilometersId: string,
    data: IRequestKilometers
  ): AxiosPromise<any> => {
    return axios.put(baseUrl + `/${kilometersId}`, data);
  },
  list: (data: IRequestKilometersFilter): AxiosPromise<any> => {
    return axios.post(baseUrl + "/view/list", data);
  },
  filterForm: (projectId: string): AxiosPromise<any> => {
    const baseProjectUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/kilometers`;
    return axios.get(baseProjectUrl + "/view/filter/form");
  },
  editForm: (kilometersId: string): AxiosPromise<any> => {
    return axios.get(baseUrl + `/view/form/${kilometersId}`);
  },
  addForm: (): AxiosPromise<any> => {
    return axios.get(baseUrl + `/view/form`);
  },
  batchDelete: (data: Array<{ id: string }>): AxiosPromise<any> => {
    return axios.delete(baseUrl, {
      headers: { ...getAuthHeader() },
      data: { kilometers: data },
    });
  },
};

export default KilometersConnection;
