import React, { FunctionComponent, useEffect, useState } from "react";
import {
  IFormBodyResponse,
  IGetAssignedLocations,
  IGetProjectLocations,
  IProjectLocation,
} from "utils/models";
import ProjectsConnection from "utils/connections/projects";
import { generatePath, useHistory, useParams } from "react-router-dom";
import Pagination from "global/pagination/Pagination";
import { getProjectsLocationsState } from "utils/states";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import { Table } from "rsuite";
import {
  getAddressFullName,
  getProjectIdFromUrl,
  handleSortColumn,
} from "../../../../utils/helpers";
import IconButton from "../../../../global/atoms/IconButton";
import Checkbox from "rsuite/Checkbox";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import styles from "../projectsList/styles.module.scss";
import {
  ActionDropdownItem,
  BatchActionDropdownItem,
} from "../../../../utils/toggleToDeleteHelper";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
  IUniversalFilterElement,
} from "../../../../global/filters/UniversalFilters";
import { Dropdown, Nav } from "rsuite";
import LocationsOperations, {
  OperationType,
  TYPE_ADD_ALL,
  TYPE_ADD_SELECTED,
  TYPE_REMOVE_ALL,
  TYPE_REMOVE_SELECTED,
  TYPE_REPLACE_ALL,
  TYPE_REPLACE_SELECTED,
  TYPE_REPLACE_TERM_ALL,
  TYPE_REPLACE_TERM_SELECTED,
} from "./LocationsOperations";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";
import { IconSvg } from "../../../../global/atoms/IconHelper";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { RowDataType } from "rsuite/esm/Table";

interface IProjectLocationsComponent {
  fixedFilters?: IGetProjectLocations;
  hiddenFilters?: Array<string>;
  filterStorageKey?: string;
  locationUrl?: string;
}

const VIEW_ASSIGNED = "VIEW_ASSIGNED";
const VIEW_UNASSIGNED = "VIEW_UNASSIGNED";
type ViewType = "VIEW_ASSIGNED" | "VIEW_UNASSIGNED";

const Locations: FunctionComponent<IProjectLocationsComponent> = (props) => {
  const MANAGE_ASSIGNED_ALL = "MANAGE_ASSIGNED_ALL";
  const MANAGE_ASSIGNED_SELECTED = "MANAGE_ASSIGNED_SELECTED";

  const BUTTON_OPTIONS = "BUTTON_OPTIONS";
  const MANAGE_STATUSES_OPTION = "MANAGE_STATUSES_OPTION";
  const MANAGE_ASSIGNED_OPTION = "MANAGE_ASSIGNED_OPTION";
  const MANAGE_USER_ASSIGNMENT_OPTION = "MANAGE_USER_ASSIGNMENT_OPTION";

  const [data, setData] = useState<IGetAssignedLocations | null>(null);
  const [filters, setFilters] = useState<IGetProjectLocations>(
    props.fixedFilters ?? getProjectsLocationsState
  );
  const [chosenLocations, setChosenLocations] = useState<Array<string>>([]);
  const [view, setView] = useState<"VIEW_ASSIGNED" | "VIEW_UNASSIGNED">(
    VIEW_ASSIGNED
  );
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [locOperationModal, setLocOperationModal] =
    useState<OperationType | null>(null);
  const loadFunc =
    view === VIEW_ASSIGNED
      ? ProjectsConnection.getAssignedLocations
      : ProjectsConnection.getUnassignedLocations;
  const [form, setForm] = useState<IFormBodyResponse>();
  const projectSettings = useSelector(
    (state: IRoot) => state.project?.projectSettings
  );

  useEffect(() => {
    setTriggerLoad(Date.now());
  }, [view]);

  const handleChangeView = (viewType: ViewType) => {
      setView(viewType);
      setChosenLocations([]); // clear selected
    },
    handleToggleLocation = (locationId: string) => {
      if (chosenLocations.includes(locationId)) {
        setChosenLocations(
          chosenLocations.filter((item: string) => item !== locationId)
        );
      } else {
        setChosenLocations([...chosenLocations, locationId]);
      }
    },
    handleToggleAllLocations = () => {
      if (data !== null) {
        if (chosenLocations.length) {
          setChosenLocations([]);
        } else {
          setChosenLocations(data.data.map((item) => item.id));
        }
      }
    },
    handleAssignLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.assignLocations(id, chosenLocations).then(() => {
        setView(VIEW_ASSIGNED);
        setChosenLocations([]);
        setTriggerLoad(Date.now());
      });
    },
    handleUnassignLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.unassignLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        setTriggerLoad(Date.now());
      });
    },
    handleDisableLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.disableLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        setTriggerLoad(Date.now());
      });
    },
    handleDisableAllLocations = () => {
      ProjectsConnection.disableAllLocations(id).then(() => {
        setTriggerLoad(Date.now());
      });
    },
    handleEnableLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.enableLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        setTriggerLoad(Date.now());
      });
    },
    handleEnableAllLocations = () => {
      ProjectsConnection.enableAllLocations(id).then(() => {
        setTriggerLoad(Date.now());
      });
    },
    handleDetachAllLocations = () => {
      ProjectsConnection.detachAllLocations(id).then(() => {
        setTriggerLoad(Date.now());
      });
    };

  const handleChangeToEdit = (locationId: string) => {
    history.push(
      generatePath(
        props.locationUrl ?? "/projects/:id/edit/locations/:locationId",
        {
          locationId: locationId,
          id: getProjectIdFromUrl() ?? "",
        }
      )
    );
  };

  const handleModalOperationInit = (type: OperationType) => {
    setLocOperationModal(type);
  };

  const getDisabledElements = (): Array<string> => {
    return form?.disabledElements ?? [];
  };

  const isDisabled = (elm: string): boolean => {
    return getDisabledElements().includes(elm);
  };

  const getUserRoles = (): { [roleId: string]: string } => {
    const rolesWithHierarchy: {
      [roleId: string]: { roleName: string; hierarchyLevel: number };
    } = {};
    data?.data.forEach((d) => {
      if (d.roleUsers) {
        d.roleUsers.forEach((u) => {
          rolesWithHierarchy[u.roleId] = {
            roleName: u.roleName,
            hierarchyLevel: u.hierarchyLevel,
          };
        });
      }
    });

    const sortedRoleNamesArray = Object.entries(rolesWithHierarchy).sort(
      (a, b) => {
        if (a[1].hierarchyLevel !== b[1].hierarchyLevel) {
          return b[1].hierarchyLevel - a[1].hierarchyLevel;
        } else {
          return a[1].roleName.localeCompare(b[1].roleName);
        }
      }
    );

    const sortedRoles: { [roleId: string]: string } = {};
    sortedRoleNamesArray.forEach((entry) => {
      const [roleId, { roleName }] = entry;
      sortedRoles[roleId] = roleName;
    });

    return sortedRoles;
  };

  const getRowHeight = (rowData: IProjectLocation): number => {
    if (!rowData || _.isEmpty(rowData)) return 0;
    let maxNumberOfUsersInRole = 2;
    Object.keys(getUserRoles()).forEach((userRoleId) => {
      const tmp = rowData.roleUsers?.filter((ru) => ru.roleId === userRoleId)
        .length;
      if (tmp > maxNumberOfUsersInRole) {
        maxNumberOfUsersInRole = tmp;
      }
    });
    return maxNumberOfUsersInRole * 28;
  };

  const getFilterElements = (): Array<IUniversalFilterElement> => {
    const tmp: Array<IUniversalFilterElement> = [
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "name",
        label: "Nazwa",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "incrementId",
        label: "Numer porządkowy",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "locationCustomerCode",
        label: "Kod klienta dla lokalizacji",
      },
      {
        type: FILTER_SELECT,
        stateKey: "code",
        formKey: "locationCodes",
        label: "Zewnętrzny kod lokalizacji",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "nip",
        label: "Nip",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "street",
        label: "Ulica",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "city",
        label: "Miasto",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "community",
        label: "Gmina",
      },
      {
        type: FILTER_INPUT_TEXT,
        stateKey: "district",
        label: "Powiat",
      },
      {
        type: FILTER_SELECT,
        stateKey: "voivodeshipId",
        label: "Województwo",
        formKey: "voivodeships",
      },
      {
        type: FILTER_SELECT,
        stateKey: "networkId",
        label: "Sieć",
        formKey: "networks",
      },
      {
        type: FILTER_SELECT,
        stateKey: "roleId",
        formKey: "projectMerchRoles",
        label: "Grupa",
      },
      {
        type: FILTER_SELECT,
        stateKey: "reportingUserId",
        formKey: "users",
        label: "Grupa -> Użytkownik",
        filter: "roleId",
      },
      {
        type: FILTER_SELECT,
        stateKey: "status",
        formKey: "status",
        label: "Status",
      },
    ];

    if (_.isEmpty(props.hiddenFilters)) return tmp;
    // @ts-ignore
    return tmp.filter((v) => !props.hiddenFilters?.includes(v.stateKey));
  };

  const getRowProxy = (
    elm: React.ReactNode,
    loc: IProjectLocation
  ): React.ReactNode => {
    if (!loc.isActive && view === VIEW_ASSIGNED)
      return <div style={{ opacity: "0.25" }}>{elm}</div>;
    return elm;
  };

  return (
    <>
      <HeaderButtons>
        {form && !isDisabled(BUTTON_OPTIONS) && (
          <OptionsDropdown>
            {view === VIEW_ASSIGNED && (
              <>
                {!isDisabled(MANAGE_STATUSES_OPTION) ? (
                  <>
                    <Dropdown.Menu title="Przywróć do raportowania">
                      <BatchActionDropdownItem
                        chosenRows={chosenLocations}
                        callback={handleEnableLocations}
                        title={`Zaznaczone (${chosenLocations.length})`}
                        message={"Czy zmienić status lokalizacji"}
                      />
                      <ActionDropdownItem
                        chosenRows={chosenLocations}
                        callback={handleEnableAllLocations}
                        title={"Wszystkie"}
                        message={
                          "Czy zmienić status wszystkich lokalizacji projektu na raportowane?"
                        }
                      />
                    </Dropdown.Menu>
                    <Dropdown.Menu title="Wyklucz z raportowania">
                      <BatchActionDropdownItem
                        chosenRows={chosenLocations}
                        callback={handleDisableLocations}
                        title={`Zaznaczone (${chosenLocations.length})`}
                        message={"Czy zmienić status lokalizacji "}
                      />
                      <ActionDropdownItem
                        chosenRows={chosenLocations}
                        callback={handleDisableAllLocations}
                        title={"Wszystkie"}
                        message={
                          "Czy zmienić status wszystkich lokalizacji projektu na nieraportowane?"
                        }
                      />
                    </Dropdown.Menu>
                  </>
                ) : null}
                {!isDisabled(MANAGE_ASSIGNED_ALL) ||
                !isDisabled(MANAGE_ASSIGNED_SELECTED) ? (
                  <Dropdown.Menu title="Odłącz lokalizacje od projektu">
                    <BatchActionDropdownItem
                      chosenRows={chosenLocations}
                      callback={handleUnassignLocations}
                      disabled={isDisabled(MANAGE_ASSIGNED_SELECTED)}
                      title={`Zaznaczone (${chosenLocations.length})`}
                      message={"Czy odłączyć lokalizacje "}
                    />
                    <ActionDropdownItem
                      chosenRows={chosenLocations}
                      callback={handleDetachAllLocations}
                      disabled={isDisabled(MANAGE_ASSIGNED_ALL)}
                      title={"Wszystkie"}
                      message={"Czy odłączyć wszystkie lokalizacje projektu?"}
                    />
                  </Dropdown.Menu>
                ) : null}
              </>
            )}
            {view === VIEW_UNASSIGNED &&
              !isDisabled(MANAGE_ASSIGNED_OPTION) && (
                <Dropdown.Item
                  disabled={!chosenLocations.length}
                  onClick={handleAssignLocations}>
                  Przypisz zaznaczone lokalizacje ({chosenLocations.length})
                </Dropdown.Item>
              )}

            {view === VIEW_ASSIGNED &&
              !isDisabled(MANAGE_USER_ASSIGNMENT_OPTION) && (
                <>
                  <Dropdown.Separator />
                  <Dropdown.Menu title={"Przypisz zastępstwo dla użytkownika"}>
                    <Dropdown.Item
                      disabled={!chosenLocations.length}
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REPLACE_TERM_SELECTED
                      )}>
                      Zaznaczone ({chosenLocations.length})
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REPLACE_TERM_ALL
                      )}>
                      Wszystkie
                    </Dropdown.Item>
                  </Dropdown.Menu>

                  <Dropdown.Menu title="Zamień przypisania użytkowników">
                    <Dropdown.Item
                      disabled={!chosenLocations.length}
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REPLACE_SELECTED
                      )}>
                      Zaznaczone ({chosenLocations.length})
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REPLACE_ALL
                      )}>
                      Wszystkie
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  <Dropdown.Menu title="Dodaj przypisania użytkowników">
                    <Dropdown.Item
                      disabled={!chosenLocations.length}
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_ADD_SELECTED
                      )}>
                      Zaznaczone ({chosenLocations.length})
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_ADD_ALL
                      )}>
                      Wszystkie
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  <Dropdown.Menu title="Usuń przypisania użytkowników">
                    <Dropdown.Item
                      disabled={!chosenLocations.length}
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REMOVE_SELECTED
                      )}>
                      Zaznaczone ({chosenLocations.length})
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleModalOperationInit.bind(
                        null,
                        TYPE_REMOVE_ALL
                      )}>
                      Wszystkie
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </>
              )}
          </OptionsDropdown>
        )}
      </HeaderButtons>

      <PaginatedTableViewWrapper
        beforeTableElements={
          <div style={{ minHeight: "53px" }}>
            {form &&
              !(
                isDisabled(MANAGE_ASSIGNED_ALL) &&
                isDisabled(MANAGE_ASSIGNED_SELECTED)
              ) && (
                <Nav
                  appearance={"default"}
                  activeKey={view}
                  onSelect={handleChangeView}>
                  <Nav.Item eventKey={VIEW_ASSIGNED}>Przypisane</Nav.Item>
                  <Nav.Item eventKey={VIEW_UNASSIGNED}>Nieprzypisane</Nav.Item>
                </Nav>
              )}
            <SeparatorEmpty />
          </div>
        }
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            // @ts-ignore
            rowHeight={(rowData) => getRowHeight(rowData)}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <Checkbox
                  checked={
                    chosenLocations.length > 0 &&
                    chosenLocations.length === data?.data.length
                  }
                  onClick={handleToggleAllLocations}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) =>
                  getRowProxy(
                    <Checkbox
                      checked={chosenLocations.includes(rowData.id)}
                      onClick={handleToggleLocation.bind(null, rowData.id)}
                    />,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>

            {view === VIEW_ASSIGNED &&
              projectSettings?.isEnableOneTimeVisits && (
                <Table.Column width={120} align="left">
                  <Table.HeaderCell>Wizyta?</Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: RowDataType<IProjectLocation>) =>
                      getRowProxy(
                        <span
                          className={styles.status}
                          style={{
                            color: rowData.isActive ? "#26D554" : "#9AA4AA",
                            borderColor: rowData.isActive
                              ? "#26D554"
                              : "#9AA4AA",
                          }}>
                          {rowData.isActive ? "Nie" : "Tak"}
                        </span>,
                        rowData as IProjectLocation
                      )
                    }
                  </Table.Cell>
                </Table.Column>
              )}

            <Table.Column
              flexGrow={1}
              minWidth={300}
              align="left"
              sortable
              resizable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) =>
                  getRowProxy(
                    <>
                      {view === VIEW_ASSIGNED ? (
                        <ButtonListRedirect
                          redirectUrl={generatePath(
                            props.locationUrl ??
                              "/projects/:id/edit/locations/:locationId",
                            {
                              locationId: rowData.id,
                              id: getProjectIdFromUrl() ?? "",
                            }
                          )}>
                          {(rowData.userReplacements?.length ?? 0) > 0 && (
                            <strong>
                              {IconSvg(
                                faExclamationTriangle,
                                "Aktywne zastępstwa",
                                false,
                                "rgba(255,175,56,0.9)"
                              )}
                            </strong>
                          )}
                          {rowData.name}
                        </ButtonListRedirect>
                      ) : (
                        <>{rowData.name}</>
                      )}
                    </>,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable resizable>
              <Table.HeaderCell>Sieć</Table.HeaderCell>
              <Table.Cell dataKey="networkName">
                {(rowData) =>
                  getRowProxy(
                    <span>
                      {rowData?.projectLocationNetwork?.name ??
                        rowData?.network?.name}
                    </span>,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} resizable sortable>
              <Table.HeaderCell>Kod lokalizacji</Table.HeaderCell>
              <Table.Cell dataKey="code" />
            </Table.Column>
            <Table.Column width={150} align="left" resizable>
              <Table.HeaderCell title={"Kod klienta dla lokalizacji"}>
                Kod klienta d.lokalizacji
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData: RowDataType<IProjectLocation>) =>
                  getRowProxy(
                    <span>
                      {rowData.locationCustomers.map((el) => el.code)}
                    </span>,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="left" sortable resizable>
              <Table.HeaderCell>Miasto</Table.HeaderCell>
              <Table.Cell dataKey={"city"}>
                {(rowData: RowDataType<IProjectLocation>) =>
                  getRowProxy(
                    <span>{rowData.address.city.name}</span>,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={300} align="left" sortable resizable>
              <Table.HeaderCell>Adres</Table.HeaderCell>
              <Table.Cell dataKey={"locationAddress"}>
                {(rowData: RowDataType<IProjectLocation>) =>
                  getRowProxy(
                    <span>{getAddressFullName(rowData.address)}</span>,
                    rowData as IProjectLocation
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={75} align="left">
              <Table.HeaderCell>Koord.</Table.HeaderCell>
              <Table.Cell>
                {(rowData: RowDataType<IProjectLocation>) => (
                  <Checkbox checked={!!rowData.address.coords} disabled />
                )}
              </Table.Cell>
            </Table.Column>
            {view === VIEW_ASSIGNED &&
              Object.entries(getUserRoles()).map(([roleId, roleName]) => (
                <Table.Column key={`role-col-${roleId}`} width={200}>
                  <Table.HeaderCell>{roleName}</Table.HeaderCell>
                  <Table.Cell>
                    {(rowData: RowDataType<IProjectLocation>, i) =>
                      getRowProxy(
                        rowData.roleUsers
                          .filter((ru) => ru.roleId === roleId)
                          ?.map((u) => (
                            <div key={`ru-${view}-${roleId}-${i}-${u.id}`}>
                              {u.name}
                            </div>
                          )) ?? (
                          <small style={{ color: "#ddd" }}>
                            nie przypisany
                          </small>
                        ),
                        rowData as IProjectLocation
                      )
                    }
                  </Table.Cell>
                </Table.Column>
              ))}
            {view === VIEW_ASSIGNED && (
              <Table.Column width={60} align="left">
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.Cell>
                  {(rowData: RowDataType<IProjectLocation>) =>
                    getRowProxy(
                      <IconButton
                        icon={"settings"}
                        onClick={handleChangeToEdit.bind(null, rowData.id)}
                      />,
                      rowData as IProjectLocation
                    )
                  }
                </Table.Cell>
              </Table.Column>
            )}
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      {/* Modal */}
      <LocationsOperations
        selectedLocations={chosenLocations}
        open={locOperationModal}
        onClose={() => {
          setLocOperationModal(null);
        }}
        setReloadData={() => {
          setLocOperationModal(null);
          setTriggerLoad(Date.now());
        }}
      />

      <UniversalFilters
        load={loadFunc}
        formGet={ProjectsConnection.getFormFilterLocations}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getProjectsLocationsState}
        getFormCallback={(_from) => setForm(_from)}
        setResultData={setData}
        filterStorageKey={
          props.filterStorageKey ?? `projectsLocations_${getProjectIdFromUrl()}`
        }
        triggerLoad={triggerLoad}
        elements={getFilterElements()}
      />
    </>
  );
};

export default Locations;
