import { SET_MENU } from "../actions";
import { menuState } from "../states";

const menuReducer = (state = menuState, action: any) => {
  switch (action.type) {
    case SET_MENU:
      return action.payload;
    default:
      return state;
  }
};

export default menuReducer;
