import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Input, Row, SelectPicker } from "rsuite";

import { SetState } from "../../../../utils/models";
import Table from "rsuite/Table";

import TrashIcon from "@rsuite/icons/Trash";
import Section from "../../../../global/atoms/section/Section";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { ILocationProjectCustomerData } from "./_states";
import { deepClone } from "../../../../utils/helpers";
import { RowDataType } from "rsuite/esm/Table";

interface ILocationProjectCustomerForm {
  data: Array<ILocationProjectCustomerData>;
  setData: SetState<Array<ILocationProjectCustomerData>>;
  customers: { options: Array<{ id: string; name: string }> };
  disabled?: boolean;
}

const LocationProjectCustomerForm: FunctionComponent<
  ILocationProjectCustomerForm
> = (props) => {
  const [state, setAddState] = useState<ILocationProjectCustomerData>({
    customerId: "",
    code: "",
    customerName: "",
  });

  useEffect(() => {
    if (props.customers.options.length === 1) {
      setAddState({
        ...state,
        customerId: props.customers.options[0].id,
      });
    }
  }, []);

  const addNew = () => {
    setAddState({
      ...state,
      code: "",
    });
    const _data = deepClone(props.data);
    _data.push({
      customerId: state.customerId ?? "",
      customerName:
        props.customers.options.find((co) => co.id === state.customerId)
          ?.name ?? "-",
      code: state.code ?? "",
    });
    props.setData(_data);
  };

  const getData = () => {
    return props.data ?? [];
  };

  const filteredCustomers = (): Array<any> => {
    return (
      props.customers.options.filter(
        (c) =>
          !((getData() ?? [])?.map((d) => d.customerId) ?? []).includes(c.id)
      ) ?? []
    );
  };
  const removeItem = (rowData: ILocationProjectCustomerData) => {
    const _data = deepClone(props.data);
    const _filteredData = _data.filter(
      (row) => row.customerId !== rowData.customerId
    );
    props.setData(_filteredData);
  };
  return (
    <>
      <>
        <Section title={"Kod klienta dla lokalizacji"} titleSize={"1.1em"}>
          {!props.disabled && filteredCustomers().length === 0 ? (
            <div style={{ textAlign: "center", color: "#aaa" }}>
              Brak możliwości dodania nowych kodów.
            </div>
          ) : props.disabled === true ? (
            <></>
          ) : (
            <Row>
              <Col xs={11}>
                <SelectPicker
                  block
                  cleanable={false}
                  value={state.customerId?.toString() ?? ""}
                  readOnly={filteredCustomers().length === 1}
                  data={
                    filteredCustomers().map((c) => ({
                      label: c.name,
                      value: c.id,
                    })) ?? []
                  }
                  placeholder={"Klient"}
                  onSelect={(customerId) => {
                    setAddState({
                      ...state,
                      customerId: customerId,
                    });
                  }}
                />
              </Col>
              <Col xs={11}>
                <Input
                  type={"text"}
                  placeholder={"Wewnętrzny kod klienta"}
                  name={"code"}
                  value={state.code?.toString() ?? ""}
                  onChange={(value) =>
                    setAddState({
                      ...state,
                      code: value,
                    })
                  }
                />
              </Col>
              <Col xs={2}>
                <Button
                  type={"button"}
                  disabled={
                    Object.entries(state).filter((val) => {
                      return val[1] !== null;
                    }).length < Object.keys(state).length
                  }
                  block
                  appearance={"ghost"}
                  onClick={addNew}>
                  Dodaj
                </Button>
              </Col>
            </Row>
          )}

          <SeparatorEmpty size={1.5} />

          <Table autoHeight loading={getData() === null} data={getData() ?? []}>
            <Table.Column flexGrow={1} align="left">
              <Table.HeaderCell depth={0} width={0}>
                Klient
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="customerName" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell depth={0} width={0}>
                Wewnętrzny kod klienta
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0} dataKey="code" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <Table.HeaderCell depth={0} width={0}>
                Opcje
              </Table.HeaderCell>
              <Table.Cell width={100} depth={0}>
                {(rowData: RowDataType<ILocationProjectCustomerData>) => {
                  if (props.disabled === true) return <></>;
                  return (
                    <TrashIcon
                      style={{ cursor: "pointer", color: "#ffaf38" }}
                      onClick={removeItem.bind(
                        null,
                        rowData as ILocationProjectCustomerData
                      )}
                    />
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </Section>
      </>
    </>
  );
};

export default LocationProjectCustomerForm;
