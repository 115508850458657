import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const KilometersTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Kilometry",
        name: "kilometers",
        active: false,
        options: [
          {
            name: "lp",
            label: "lp",
            active: false,
          },
          {
            name: "userName",
            label: "Nazwisko i imię",
            active: false,
          },
          {
            name: "date",
            label: "Data",
            active: false,
          },
          {
            name: "start",
            label: "Start",
            active: false,
          },
          {
            name: "stop",
            label: "Stop",
            active: false,
          },
          {
            name: "kilometersCount",
            label: "Km przejechane",
            active: false,
          },
          {
            name: "fuelPrice",
            label: "Paliwo (kwota)",
            active: false,
          },
          {
            name: "fuelLiters",
            label: "Paliwo (litry)",
            active: false,
          },
          {
            name: "privateKilometers",
            label: "Paliwo (litry)",
            active: false,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={7} />
    </>
  );
};

export default KilometersTemplate;
