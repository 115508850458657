import React, { FunctionComponent, useEffect, useState } from "react";
import OrderConnection from "utils/connections/orders";
import { useParams } from "react-router-dom";
import WhiteCard from "global/atoms/WhiteCard";
import Select from "global/atoms/Select";
import { ISelectViewResponse } from "utils/models";
import Input from "global/atoms/Input";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { Col, Row, Table } from "rsuite";
import { getAddressFullName } from "utils/helpers";
import styles from "./styles.module.scss";
import Spinner from "global/atoms/Spinner/Spinner";
import {
  createSummaryRowData,
  getCellComponentWithPriceValue,
  SUMMARY_TITLE,
} from "../documents/DocumentDetails";

interface IOrderDetailsForm {
  customers: ISelectViewResponse;
  orderStatuses: ISelectViewResponse;
  model: any;
}

const OrderDetails: FunctionComponent = () => {
  const [form, setForm] = useState<IOrderDetailsForm | null>(null);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams<{ orderId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    OrderConnection.editForm(orderId).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);

      setForm(response.data);
      setLoading(false);
    });
  }, []);

  if (loading || form === null || positions === null) return <Spinner />;

  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={8} sm={8}>
            <Input
              value={form.model.documentNumber}
              placeholder={"Numer"}
              name={"documentNumber"}
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={8} className={styles.col}>
            <Input
              value={form.model.documentDate}
              placeholder={"Data zamówienia"}
              name={"documentDate"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={8} className={styles.col}>
            <Input
              value={form.model.user.fullName}
              placeholder={"Wystawił"}
              name={"user"}
              type={"text"}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col xs={24} sm={8} className={styles.col}>
            <Select
              placeholder={"Dystrybutor"}
              name={"customerId"}
              value={form.customers.value}
              options={form.customers.options}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={getAddressFullName(form.model.customer.address)}
              placeholder={"Adres"}
              name={"addressId"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.customer?.email}
              placeholder={"Email dystrybutora"}
              name={"customerEmail"}
              type={"text"}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col sm={8} className={styles.col}>
            <Input
              value={form.model.location.name}
              placeholder={"Odbiorca"}
              name={"name"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={getAddressFullName(form.model.location.address)}
              placeholder={"Adres"}
              name={"addressId"}
              type={"text"}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={form.model.location?.email}
              placeholder={"Email odbiorcy"}
              name={"locationEmail"}
              type={"text"}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col xs={24}>
            <Input
              value={form.model.description}
              placeholder={"Uwagi"}
              name={"description"}
              disabled={true}
            />
          </Col>
        </Row>
      </WhiteCard>
      <SeparatorEmpty />
      <Table autoHeight data={positions ?? []}>
        <Table.Column flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Nazwa
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey={"itemName"}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <b>{rowData.itemName}</b>;
              }
              return <span>{rowData.itemName}</span>;
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Netto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(
                rowData.price.amount,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Vat
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(rowData.price.tax, rowData);
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Brutto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(
                rowData.price.amountGross,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Ilość
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return (
                <span>
                  {rowData.itemAmount} {rowData.itemUnit.name}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Netto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.amountTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Vat
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.taxTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="left">
          <Table.HeaderCell depth={0} width={0}>
            W. Brutto
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(
                rowData.price.amountGrossTotal,
                rowData
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </>
  );
};

export default OrderDetails;
