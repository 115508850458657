import React, { FunctionComponent, useEffect } from "react";

interface IUniversalWebComponent {
  html: string;
}

const UniversalWebComponent: FunctionComponent<IUniversalWebComponent> = (
  props
) => {
  const name = "universal-web-component";

  class UWC extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: "open" });
      const template = document.createElement("template");
      template.innerHTML = props.html;
      if (this.shadowRoot) {
        this.shadowRoot.appendChild(template.content.cloneNode(true));
      }
    }

    static get observedAttributes() {
      return ["html"];
    }

    attributeChangedCallback(name: "html", oldValue: string, newValue: string) {
      if (name == "html" && this.shadowRoot) {
        this.shadowRoot.innerHTML = newValue;
      }
    }
  }

  useEffect(() => {
    if (window.customElements.get(name) === undefined) {
      window.customElements.define(name, UWC);
    }
  }, []);

  // @ts-ignore
  return <universal-web-component html={props.html} />;
};

export default UniversalWebComponent;
