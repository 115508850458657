import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import { IUpdateFileData } from "../../views/projects/components/files/FileEdit";

const ProjectFilesConnection = {
  uploadViewData: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files/view/upload`
    );
  },
  editFormViewData: (fileId: string, projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files/view/form/edit/${fileId}`
    );
  },
  updateFile: (fileId: string, projectId: string, data: IUpdateFileData) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files/${fileId}`,
      data
    );
  },
  getAllProjectFiles: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files`
    );
  },
  removeFile: (fileId: string, projectId: string) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files/${fileId}`
    );
  },
  download: (name: string, projectFilesId: string, projectId: string) => {
    return axios
      .get(
        `${getBaseApiUrl()}/organizations/${getOrganizationId()}/projects/${projectId}/files/download/${projectFilesId}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((response) => {
        const csvURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", name);
        tempLink.click();
      });
  },
};

export default ProjectFilesConnection;
