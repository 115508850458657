import React, { FunctionComponent, useState } from "react";
import {
  IBodyResponse,
  ILogForm,
  ILogMobileRequestData,
} from "../../../../utils/models";
import { Table } from "rsuite";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import LogsConnection from "../../../../utils/connections/logs";
import { jsonButton } from "./LogsList";
import { handleSortColumn } from "../../../../utils/helpers";
import { _paginate30 } from "utils/states";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";

interface ILogsAppList {}

interface ILogRow {
  appVersion: string;
  createdAt: string;
  deviceId: string;
  deviceName: string;
  deviceSystem: string;
  deviceToken: string;
  deviceType: string;
  event: string;
  id: string;
  log: string;
  userId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogsAppList: FunctionComponent<ILogsAppList> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<IBodyResponse<ILogRow>>({
    count: 0,
    data: [],
  });
  const [form, setForm] = useState<ILogForm | null>(null);

  const defaultRequestData: ILogMobileRequestData = {
    userId: "",
    event: "",
    dateFrom: 0,
    dateTo: 0,
    device: "",
    ..._paginate30,
  };
  const [requestData, setRequestData] =
    useState<ILogMobileRequestData>(defaultRequestData);

  return (
    <>
      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={logData.data}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey={"createdAt"} />
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Użytkownik</Table.HeaderCell>
              <Table.Cell dataKey="userName">
                {(rowData: ILogRow) => {
                  return (
                    <>
                      {form?.users?.options?.find(
                        (eo) => eo.id === rowData.userId
                      )?.name ?? rowData.userId}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Zdarzenie</Table.HeaderCell>
              <Table.Cell dataKey="eventName">
                {(rowData: ILogRow) => {
                  return (
                    <>
                      {form?.events?.options?.find(
                        (eo) => eo.id === rowData.event
                      )?.name ?? rowData.event}
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1} align={"right"}>
              <Table.HeaderCell>log</Table.HeaderCell>
              <Table.Cell>
                {(rowData: ILogRow) => jsonButton(rowData.log)}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={3} align={"right"} sortable>
              <Table.HeaderCell>Informacje o urządzeniu</Table.HeaderCell>
              <Table.Cell dataKey={"deviceName"}>
                {(rowDate: ILogRow) => {
                  return (
                    <>
                      Device: <strong>{rowDate.deviceName}</strong>&nbsp; Sys:
                      <strong>
                        {" "}
                        {rowDate.deviceType} {rowDate.deviceSystem}
                      </strong>
                      &nbsp; App:<strong> {rowDate.appVersion}</strong>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={logData?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={LogsConnection.listApp}
        formGet={LogsConnection.listAppForm}
        getFormCallback={(_form) => setForm(_form)}
        setLoading={setLoading}
        state={requestData}
        setState={setRequestData}
        defaultStateData={defaultRequestData}
        setResultData={setLogData}
        filterStorageKey={"logsAppList"}
        elements={[
          {
            type: FILTER_SELECT,
            label: "Użytkownik",
            stateKey: "userId",
            formKey: "users",
          },
          {
            type: FILTER_SELECT,
            label: "Zdarzenie",
            stateKey: "event",
            formKey: "events",
          },
          {
            type: FILTER_INPUT_TEXT,
            label: "Informacje o urządzeniu",
            stateKey: "device",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data do",
            stateKey: "dateTo",
            outputFormat: "phpTimestamp",
          },
        ]}
      />
    </>
  );
};

export default LogsAppList;
