import React, { FunctionComponent, useEffect, useState } from "react";
import { DatePicker, Form } from "rsuite";
import styles from "./atoms.module.scss";
import flatten, { unflatten } from "flat";
import { findErrorByName } from "../../utils/formErrorsHelper";
import { TypeAttributes } from "rsuite/esm/@types/common";

export type localDatePickerOutputType =
  | "yyyy-MM-dd"
  | "phpTimestamp"
  | "jsTimestamp"
  | "Date"
  | "Dayjs";

interface ILocalDatePicker {
  label: string;
  state: any;
  stateKey: string;
  emptyValue: any;
  setState: any;
  format?: "yyyy-MM-dd HH:mm:ss" | "yyyy-MM-dd";
  errors?: any;
  outputFormat?: localDatePickerOutputType;
  placement?: TypeAttributes.Placement;
  disabled?: boolean;
  cleanable?: boolean;
  disabledDate?: (date: Date) => boolean;
  value?: Date | undefined;
}

const LocalDatePicker: FunctionComponent<ILocalDatePicker> = (props) => {
  const [localDateState, setLocalDateState] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    // todo: dear god

    const flatState = flatten(props.state);
    let anyDateValue: null | number | string | undefined = null;

    //@ts-ignore
    if (flatState[props.stateKey] === props.emptyValue) {
      setLocalDateState(undefined);
      //@ts-ignore
    } else anyDateValue = flatState[props.stateKey];

    if (anyDateValue && typeof anyDateValue === "number") {
      // js timestamp
      if (anyDateValue.toString().length > 12)
        setLocalDateState(new Date(anyDateValue));
      // php timestamp
      else if (anyDateValue.toString().length < 12)
        setLocalDateState(new Date(anyDateValue * 1000));
    } else if (anyDateValue && typeof anyDateValue === "string") {
      // @ts-ignore  yyyy-mm-dd
      if (anyDateValue.split("-").length > 0) {
        setLocalDateState(new Date(anyDateValue));
      } else {
        // @ts-ignore js timestamp from string
        if (anyDateValue.length > 12)
          setLocalDateState(new Date(parseInt(anyDateValue)));
        // @ts-ignore php timestamp from string
        else if (anyDateValue.length < 12)
          setLocalDateState(new Date(parseInt(anyDateValue) * 1000));
      }
    } else if (anyDateValue && typeof anyDateValue === "object") {
      setLocalDateState(anyDateValue);
    }

    // @ts-ignore
  }, [flatten(props.state)[props.stateKey]]);

  const handleChange = (date: Date | null) => {
    let outputValue: any = date;

    if (date !== null) {
      switch (props.outputFormat ?? "phpTimestamp") {
        case "Date":
          outputValue = date;
          break;
        case "phpTimestamp":
          outputValue = parseInt((date.getTime() / 1000).toFixed(0));
          break;
        case "jsTimestamp":
          outputValue = date.getTime();
          break;
        case "yyyy-MM-dd":
          // eslint-disable-next-line no-case-declarations
          let buildDate = date.getFullYear() + "-";
          if (date.getMonth() < 9) {
            buildDate += "0";
          }
          buildDate += `${date.getMonth() + 1}-`;
          if (date.getDate() < 10) {
            buildDate += "0";
          }
          buildDate += `${date.getDate()}`;
          outputValue = buildDate;
          break;
      }
    }
    const flatState = flatten(props.state);
    //@ts-ignore
    flatState[props.stateKey] = outputValue ?? props.emptyValue;
    props.setState(unflatten(flatState));

    if (date !== localDateState) setLocalDateState(date ?? undefined);
  };

  return (
    <>
      <Form.Group>
        <Form.ControlLabel>{props.label}</Form.ControlLabel>
        <DatePicker
          isoWeek
          oneTap
          cleanable={props.cleanable ?? true}
          onChange={handleChange}
          value={props.value ?? localDateState}
          placement={props.placement ?? "auto"}
          format={props.format ?? "yyyy-MM-dd"}
          style={{ width: "100%" }}
          disabled={props.disabled ?? false}
          shouldDisableDate={props?.disabledDate}
          ranges={[
            {
              label: "Dziś",
              value: new Date(),
            },
          ]}
        />
        {props.errors && (
          <span className={styles.errors}>
            {findErrorByName(props.stateKey, props.errors)}
          </span>
        )}
      </Form.Group>
    </>
  );
};

export default LocalDatePicker;
