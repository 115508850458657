import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SchedulesConnection from "utils/connections/schedules";
import { IFilterOrder, IFilterPaginate } from "utils/models";
import { TypeVisitStatus } from "utils/types";
import { ESource, ViewType } from "../Schedule";
import { NotCancelErrorPromise } from "utils/helpers";

export interface IScheduleRequestData extends IFilterPaginate, IFilterOrder {
  locations: null | string[];
  networkId: string | null;
  projects: null | string[];
  taskId: string | null;
  userId: string | null;
  customerId: string | null;
  dateFrom: Date;
  dateTo: Date;
  statuses: Array<TypeVisitStatus | null>;
  source: Array<ESource>;
  viewType: ViewType;
  presetId: string | null;
  presets: Array<{ [id: string]: string }>;
  availableViews: Array<ViewType>;
}

function useRequestData(): [
  IScheduleRequestData,
  (
    value: Partial<IScheduleRequestData>,
    callback: (pageReset: boolean, _requestData: IScheduleRequestData) => void
  ) => IScheduleRequestData,
  () => void,
  boolean,
] {
  const { id } = useParams<{ id: string }>();
  const defaultRequest: IScheduleRequestData = {
    requestOrder: {
      field: undefined,
      order: undefined,
    },
    requestPaginate: {
      page: 1,
      limit: 50,
    },
    locations: null,
    customerId: null,
    userId: null,
    networkId: null,
    projects: id ? [id] : null,
    taskId: null,
    dateFrom: dayjs().startOf("week").toDate(),
    dateTo: dayjs().endOf("week").toDate(),
    statuses: [],
    source: [ESource.schedule, ESource.manual],
    viewType: ViewType.view,
    presetId: null,
    presets: [],
    availableViews: [],
  };

  const [requestData, setRequestData] =
    useState<IScheduleRequestData>(defaultRequest);
  const [presetLoaded, setPresetLoaded] = useState(false);

  function loadRequestDataUserPreset() {
    SchedulesConnection.requestUserPreset().then((res) => {
      setRequestData({
        ...res.data,
        dateFrom: new Date(res.data.dateFrom),
        dateTo: new Date(res.data.dateTo),
      });

      setTimeout(() => setPresetLoaded(true), 100); // end of stack bypass fixme:
    }, NotCancelErrorPromise);
  }

  const updateRequest = (
    value: Partial<IScheduleRequestData>,
    callback: (pageReset: boolean, _requestData: IScheduleRequestData) => void
  ): IScheduleRequestData => {
    let nothingChanged = true;
    let presetChanged = false;
    let pageChanged = false;
    for (const [key, v] of Object.entries(value)) {
      // @ts-ignore
      if (JSON.stringify(requestData[key]) !== JSON.stringify(v)) {
        if (key === "presetId" && requestData[key] !== v) {
          presetChanged = true;
        }
        if (key === "requestPaginate") {
          pageChanged = true;
        }
        nothingChanged = false;
      }
    }

    if (nothingChanged === false) {
      const tmp: IScheduleRequestData = {
        ..._.cloneDeep(requestData),
        ...value,
      };
      if (!presetChanged) tmp.presetId = ""; // change to custom preset
      if (!pageChanged) {
        // reset page
        tmp.requestPaginate = {
          ...tmp.requestPaginate,
          page: 1,
        };
      }
      setRequestData(tmp);
      callback(tmp.requestPaginate.page === 1, tmp);
      return tmp;
    }

    return requestData;
  };

  return [requestData, updateRequest, loadRequestDataUserPreset, presetLoaded];
}

export default useRequestData;
