import React, { FunctionComponent, useState } from "react";
import { Dropdown, Popover } from "rsuite";
import Whisper from "rsuite/esm/Whisper";
import TrashIcon from "@rsuite/icons/Trash";
import DragableIcon from "@rsuite/icons/Dragable";
import PlusIcon from "@rsuite/icons/Plus";
import { IDndItem } from "./BoardTable";
import { IDay } from "../ScheduleViewBoard";
import dayjs from "dayjs";
import MoveAllModal from "./MoveAllModa";
import FormModal from "./FormModal";

export enum EnumScope {
  VISIT = "1", // visit inside cell
  COLUMN = "2", // column
  CELL = "3", // empty cell
}

enum EnumTileContextMenu {
  EDIT = "1",
  DELETE = "2",
  GO_TO_VISIT = "3",
  MOVE = "4",
  ADD = "5",
}

interface ITileContextMenu {
  children: JSX.Element;
  scope: EnumScope;
  item: IDndItem | null;
  data: IDay[];
  date: dayjs.Dayjs;
  userId: string;
  dataModified: (data: IDay[]) => void;
  triggerReload: () => void;
}

const TileContextMenu: FunctionComponent<ITileContextMenu> = (props) => {
  const [moveModal, setMoveModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const _TileContextMenu = ({ onClose, className }, ref, scope: EnumScope) => {
    const handleSelect = (eventKey: EnumTileContextMenu) => {
      switch (eventKey) {
        case EnumTileContextMenu.DELETE: {
          if (props.item) {
            props.dataModified(
              props.data.filter((item) => item.id !== props.item?.id)
            );
          } else {
            props.dataModified(
              props.data.filter((item) => !props.date.isSame(item.date, "day"))
            );
          }
          break;
        }
        case EnumTileContextMenu.MOVE: {
          if (!props.item) {
            setMoveModal(true);
          }
          break;
        }
        case EnumTileContextMenu.EDIT: {
          setEditModal(true);
          break;
        }
        case EnumTileContextMenu.ADD: {
          setCreateModal(true);
          break;
        }
      }
      onClose();
    };

    return (
      <Popover ref={ref} className={className} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {scope === EnumScope.VISIT && (
            <>
              {/* <Dropdown.Item
                disabled={!props.item?.isEditable}
                icon={<EditIcon />}
                eventKey={EnumTileContextMenu.EDIT}>
                Edytuj
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                disabled
                icon={<PageIcon />}
                eventKey={EnumTileContextMenu.GO_TO_VISIT}>
                Przejdź do wizyty
              </Dropdown.Item> */}
            </>
          )}
          <Dropdown.Item icon={<PlusIcon />} eventKey={EnumTileContextMenu.ADD}>
            Dodaj nowy
          </Dropdown.Item>
          {props.date.isAfter(dayjs().subtract(1, "day"), "day") &&
            scope === EnumScope.COLUMN && (
              <Dropdown.Item
                disabled={!(props.item?.isEditable ?? true)}
                icon={<DragableIcon style={{ transform: "rotate(90deg)" }} />}
                eventKey={EnumTileContextMenu.MOVE}>
                Przesuń {scope === EnumScope.COLUMN && "wszystkie"}
              </Dropdown.Item>
            )}
          {props.date.isAfter(dayjs().subtract(1, "day"), "day") &&
            [EnumScope.COLUMN, EnumScope.VISIT].includes(scope) && (
              <Dropdown.Item
                disabled={!(props.item?.isEditable ?? true)}
                style={
                  props.item?.isEditable ?? true ? { color: "red" } : undefined
                }
                icon={<TrashIcon />}
                eventKey={EnumTileContextMenu.DELETE}>
                Usuń {scope === EnumScope.COLUMN && "wszystkie"}
              </Dropdown.Item>
            )}
        </Dropdown.Menu>
      </Popover>
    );
  };

  if (props.date.isBefore(new Date(), "day")) return <>{props.children}</>;
  return (
    <>
      <Whisper
        placement="bottomStart"
        trigger="contextMenu"
        speaker={(_props, ref) => _TileContextMenu(_props, ref, props.scope)}>
        {props.children}
      </Whisper>
      {moveModal && (
        <MoveAllModal
          onClose={() => setMoveModal(false)}
          onSubmit={(date) => {
            // todo: validation (location)
            props.dataModified([
              ...props.data.filter(
                (d) => !dayjs(d.date).isSame(props.date, "day")
              ),
              ...props.data
                .filter((d) => dayjs(d.date).isSame(props.date, "day"))
                .map((d) => ({ ...d, date: date.toDate() })),
            ]);
            setMoveModal(false);
          }}
          date={props.date}
        />
      )}
      {editModal ||
        (createModal && (
          <FormModal
            userId={props.userId}
            onClose={(reload) => {
              setEditModal(false);
              setCreateModal(false);
              if (reload) props.triggerReload();
            }}
            date={props.date}
            order={0}
            dataModified={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        ))}
    </>
  );
};

export default TileContextMenu;
