import React, { FunctionComponent, useState } from "react";
import { ICreateLocation } from "utils/models";
import { useHistory } from "react-router-dom";
import LocationsConnection from "utils/connections/locations";
import LocationForm from "./LocationForm";
import toastNotification, {
  ToastTypes,
} from "../../../../global/ToastNotification";

interface ILocationAdd {
  returnUrl?: string;
}
const LocationAdd: FunctionComponent<ILocationAdd> = (props) => {
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleSubmit = (data: ICreateLocation) => {
    LocationsConnection.createLocation(data)
      .then((resp) => {
        if (resp?.status == 201) {
          toastNotification(ToastTypes.error, "Lokalizacja została dodana");
          history.push(props.returnUrl ?? "/datasets/locations");
        }
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          toastNotification(
            ToastTypes.warning,
            "Nie udało się dodać lokalizacji, formularz zawiera błędy."
          );
          setErrors(err.response.data.errors);
        } else {
          setErrors({});
        }
        if (err.response?.data?.message) {
          toastNotification(
            ToastTypes.warning,
            "Formularz zawiera błędy.",
            err.response.data.message
          );
        }
      });
  };

  return (
    <>
      <LocationForm
        errors={errors}
        isEdit={false}
        isAdd={true}
        dataPromise={LocationsConnection.getFormCreateLocation}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default LocationAdd;
