import {
  ClusterStats,
  Renderer,
} from "@googlemaps/markerclusterer/dist/renderer";
import { Cluster } from "@googlemaps/markerclusterer/dist/cluster";

export class ClusterMarkerRenderer implements Renderer {
  getSvgIcon(): string {
    return window.btoa(`<svg fill="#FFAF38E6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                <circle cx="120" cy="120" opacity=".8" r="70" />    
                              </svg>`);
  }

  getMarkerOptions(cluster: Cluster): google.maps.MarkerOptions {
    const count = cluster.markers?.length ?? 0;
    return {
      position: cluster.position,
      icon: {
        url: `data:image/svg+xml;base64,${this.getSvgIcon()}`,
        scaledSize: new google.maps.Size(65, 65),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,1)",
        fontSize: "18px",
        fontWeight: "600",
      },
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(cluster: Cluster, stats: ClusterStats): google.maps.Marker {
    return new google.maps.Marker(this.getMarkerOptions(cluster));
  }
}
