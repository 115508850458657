import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./atoms.module.scss";
import { FCC } from "utils/models";

interface IButtonListRedirectProps {
  redirectUrl: string;
}

const ButtonListRedirect: FCC<IButtonListRedirectProps> = ({
  redirectUrl,
  children,
}) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push(redirectUrl);
  };
  return (
    <>
      <a
        className={styles.buttonListName}
        href={redirectUrl}
        onClick={handleRedirect}>
        {children}
      </a>
    </>
  );
};

export default ButtonListRedirect;
