import React from "react";
import { FCC } from "utils/models";

interface IFlexRows {
  gap?: number;
}

const FlexRows: FCC<IFlexRows> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        rowGap: `${props.gap ?? 1}em`,
      }}>
      {props.children}
    </div>
  );
};

export default FlexRows;
