import { FunctionComponent, useEffect, useState } from "react";
import { IPoint } from "./Map";
import { Loader } from "@googlemaps/js-api-loader";
import { getGoogleApiKey } from "./MapWrapper";

interface IMarker {
  point: IPoint;
  map: google.maps.Map;
  onPointMouseOver?: (p: IPoint) => void;
  onPointMouseOut?: (p: IPoint) => void;
  onPointClick?: (p: IPoint) => void;
}

export const parseMarkerOptions = (
  point: IPoint,
  map: google.maps.Map
): google.maps.MarkerOptions => {
  return {
    map: map,
    icon: point.icon ?? "/png/dot.png",
    label: point?.label,
    position: {
      lat: point.lat,
      lng: point.lon,
    },
    title: point?.title,
  };
};

const Marker: FunctionComponent<IMarker> = (props) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [google, setGoogle] = useState(null);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
  });

  loader
    .load()
    .then((google: any) => {
      setGoogle(google);
    })
    .catch((e) => {
      console.warn(e);
    });

  useEffect(() => {
    if (!google) return;

    if (!marker) {
      // @ts-ignore
      const tmp = new google.maps.Marker(
        parseMarkerOptions(props.point, props.map)
      );
      if (props.onPointClick) {
        // @ts-ignore
        tmp.addListener("click", () => props.onPointClick(props.point));
      }

      if (props.onPointMouseOver) {
        // @ts-ignore
        tmp.addListener("mouseover", () => props.onPointMouseOver(props.point));
      }

      if (props.onPointMouseOut) {
        // @ts-ignore
        tmp.addListener("mouseout", () => props.onPointMouseOut(props.point));
      }

      setMarker(tmp);
    }
    return () => {
      if (marker) marker.setMap(null);
    };
  }, [marker, google]);

  return null;
};

export default Marker;
