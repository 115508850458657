import AxiosHelper from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { IOrderFilters } from "../../views/projects/components/orders/OrdersList";
import { AxiosPromise } from "axios";

const baseUrl = (): string => {
  const projectId = getProjectIdFromUrl() ?? "";
  return `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}`;
};

// todo: typy response...

const OrderConnection = {
  list: (data: IOrderFilters): AxiosPromise => {
    return AxiosHelper.post(`${baseUrl()}/sales/orders/list`, data);
  },
  filterForm: (): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl()}/sales/orders/filter/form`);
  },
  editForm: (orderId: string): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl()}/sales/orders/${orderId}/form`);
  },
  sendOrders: (data: { orders: Array<{ id: string }> }): AxiosPromise => {
    return AxiosHelper.patch(`${baseUrl()}/sales/orders`, data);
  },
  removeOrders: (data: { orders: Array<{ id: string }> }): AxiosPromise => {
    return AxiosHelper.delete(`${baseUrl()}/sales/orders`, {
      headers: getAuthHeader(),
      data,
    });
  },
  downloadPdfBlob: (orderId: string): AxiosPromise => {
    return AxiosHelper.getBlob(`${baseUrl()}/sales/orders/${orderId}/preview`);
  },
};

export default OrderConnection;
