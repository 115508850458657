import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const JpkTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Jpk",
        name: "jpk",
        active: false,
        options: [
          {
            name: "documentNumber",
            label: "Identyfikator faktury",
            active: false,
          },
          {
            name: "itemName",
            label: "Nazwa (rodzaj) towaru lub usługi",
            active: false,
          },
          {
            name: "itemQuantity",
            label: "Jednostka miary",
            active: false,
          },
          {
            name: "itemAmount",
            label: "Ilość",
            active: false,
          },
          {
            name: "itemPriceAmount",
            label: "Cena jednostkowa netto",
            active: false,
          },
          {
            name: "itemPriceAmountGross",
            label: "Cena jednostkowa brutto",
            active: false,
          },
          {
            name: "priceDiscountTotal",
            label: "Upusty",
            active: false,
          },
          {
            name: "amountTotal",
            label: "Wartość sprzedaży netto",
            active: false,
          },
          {
            name: "amountGrossTotal",
            label: "Wartość sprzedaży brutto",
            active: false,
          },
          {
            name: "vatRate",
            label: "Stawka podatku VAT",
            active: false,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={5} />
    </>
  );
};

export default JpkTemplate;
