import ScheduleViewBoard from "views/schedules/Elements/ScheduleViewBoard";
import { IRouteEntity } from "../../utils/models";
import Schedule from "views/schedules/Schedule";

const scheduleRoutes: IRouteEntity[] = [
  { path: "/schedule", breadcrumb: "Harmonogram", Component: Schedule },
  {
    path: "/schedule-board",
    breadcrumb: "Tablica harmonogram",
    Component: ScheduleViewBoard,
  },
];

export default scheduleRoutes;
