import { Message, Popover, Whisper } from "rsuite";
import React, { FunctionComponent } from "react";
import RemindFillIcon from "@rsuite/icons/RemindFill";

interface IErrorData {
  error?: {
    questionId: string;
    questionName: string;
    reasons: Array<{
      projectName: string;
      projectActive: string;
      taskName: string;
      taskActive: string;
      activityName: string;
      activityActive: string;
    }>;
  };
}

const QuestionErrorToolTip: FunctionComponent<IErrorData> = (props) => {
  let reasons = props?.error?.reasons ?? [];

  if (typeof props?.error?.reasons == "object") {
    reasons = Object.values(props?.error?.reasons) ?? [];
  }
  const message = () => {
    return (
      <Popover
        full
        arrow={false}
        style={{
          borderRadius: "5px",
          backgroundColor: "#ffffff",
          color: "#afafaf",
          border: "1px solid #afafaf",
          boxShadow: "1px 2px 15px silver",
          padding: "0px",
        }}>
        {props?.error?.questionId && (
          <Message
            style={{
              maxHeight: "300px",
              overflow: "hidden",
            }}
            header={
              <>
                <strong>Błąd!</strong>&nbsp;{props?.error?.questionName}
              </>
            }
            type="warning"
            showIcon
            closable>
            <div style={{ maxHeight: "250px", overflow: "auto" }}>
              {props?.error?.reasons &&
                reasons.map((reason, index) => (
                  <div key={index}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "30px",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}>
                      {index + 1}.
                    </span>
                    Projekt: &quot;
                    <span
                      style={{
                        textDecoration: !reason.projectActive
                          ? "line-through"
                          : "none",
                      }}>
                      {reason.projectName}
                    </span>
                    &quot;&nbsp; Zadanie: &quot;
                    <span
                      style={{
                        textDecoration: !reason.taskActive
                          ? "line-through"
                          : "none",
                      }}>
                      {reason.taskName}
                    </span>
                    &quot;&nbsp; Aktywność: &quot;
                    <span
                      style={{
                        textDecoration: !reason.activityActive
                          ? "line-through"
                          : "none",
                      }}>
                      {reason.activityName}&quot;
                    </span>
                  </div>
                ))}
            </div>
          </Message>
        )}
      </Popover>
    );
  };
  if (props.error?.reasons.length === 0) return <> </>;
  return (
    <Whisper
      placement={"autoHorizontal"}
      controlId={`control-id-hover-${props?.error?.questionId}`}
      trigger="hover"
      enterable
      speaker={message()}>
      <div
        id={`control-id-hover-${props?.error?.questionId}`}
        style={{ color: "#E09616" }}>
        <RemindFillIcon />
      </div>
    </Whisper>
  );
};
export default QuestionErrorToolTip;
