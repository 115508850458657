import React, { useEffect, useRef, useState } from "react";
import { FunctionComponent } from "react";
import { IDependOnQuestionValueCondition } from "../../../../utils/models";
import { deepClone } from "../../../../utils/helpers";
import styles from "./styles.module.scss";
import DependOnQuestionConditionSegment from "./DependOnQuestionConditionSegment";
import objectHash from "object-hash";
import { TypeQuestion } from "../../../../utils/types";
import { BOOLEAN_QUESTION } from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";

interface IDependOnQuestionConditionsBuilder {
  questionType: TypeQuestion;
  valueConditions: Array<IDependOnQuestionValueCondition>;
  onChange: (conditions: Array<IDependOnQuestionValueCondition>) => void;
}

const DependOnQuestionConditionsBuilder: FunctionComponent<
  IDependOnQuestionConditionsBuilder
> = (props) => {
  const stateHash = useRef("");
  const stateInitValue = {
    intValue: null,
    boolValue: null,
    stringValue: null,
    comparisonFormula: props.questionType === BOOLEAN_QUESTION ? "=" : ">",
    externalComparisonValue: null,
    and: null,
    or: null,
  };
  const [state, setState] =
    useState<IDependOnQuestionValueCondition>(stateInitValue);

  useEffect(() => {
    const _state =
      props.valueConditions.length > 0
        ? props.valueConditions[0]
        : stateInitValue;
    setState(_state);
  }, [props.valueConditions]);

  useEffect(() => {
    // instant submit on change
    if (!stateHash.current) stateHash.current = objectHash(state);
    else if (stateHash.current !== objectHash(state)) {
      const _state = deepClone(state);
      stateHash.current = objectHash(_state);
      props.onChange([_state]);
    }
  }, [state]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const noValue = () => {
    return (
      state.externalComparisonValue === null &&
      state.intValue === null &&
      state.boolValue === null &&
      state.stringValue === null
    );
  };

  return (
    <div className={styles.conditionBuilderWrapper}>
      {/*<div style={{textAlign: 'right'}}>*/}
      {/*<Button onClick={() => setState(stateInitValue)} appearance={'ghost'}>Wyczyść formularz</Button>*/}
      {/*<Button onClick={onSubmit} appearance={'primary'} disabled={noValue() || submitted}>OK</Button>*/}
      {/*</div>*/}

      <DependOnQuestionConditionSegment
        state={state}
        setState={setState}
        initialState={stateInitValue}
        questionType={props.questionType}
        andDeepLvl={0}
      />
    </div>
  );
};

export default DependOnQuestionConditionsBuilder;
