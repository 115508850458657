import { getProjectIdFromUrl } from "utils/helpers";
import { getOrganizationId } from "../helpers";
import AxiosHelper, { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, handleToast } from "../helpers";
import { IReportTemplateData } from "../../views/projects/components/reports/templates/BaseTemplate";
import { IReportGenerateData } from "../../views/projects/components/reports/Reports";
import { AxiosPromise } from "axios";
import dayjs from "dayjs";

const ReportTemplateConnection = {
  saveTemplate: (data: IReportTemplateData) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template`,
      data
    );
  },
  updateTemplate: (templateId: string, data: IReportTemplateData) => {
    return axios.put(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/${templateId}`,
      data
    );
  },
  removeTemplate: (templateId: string) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/${templateId}`
    );
  },
  getTemplateById: (templateId: string) => {
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/${templateId}`
    );
  },
  getGenerateReportViewData: (projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/view/generate_report/${projectId}`
    );
  },
  getFile: (fileName: string, data: IReportGenerateData) => {
    handleToast(
      axios.post(
        `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report/generate`,
        data,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
    ).then((response) => {
      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", fileName);
      tempLink.click();
    });
  },
  getAsyncReportResults: (
    projectId: string,
    reportType: number
  ): AxiosPromise => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report/${projectId}/latest-async-results/${reportType}`
    );
  },
  generateAsyncReport: (data: IReportGenerateData): AxiosPromise => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report/generate-async`,
      data
    );
  },
  getAsyncFile: (reportResultId: string, fileName: string) => {
    axios
      .get(
        `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report/file/${reportResultId}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((response) => {
        const csvURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName);
        tempLink.click();
      });
  },
  getLocationTemplateForm: (): AxiosPromise<any> =>
    AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report/location-template-form`
    ),
  getReportOptions: (
    reportType: number,
    dateFrom?: Date,
    dateTo?: Date
  ): AxiosPromise<any> => {
    if (dateFrom && dateTo) {
      const f = "YYYY-MM-DD";
      return AxiosHelper.get(
        `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/view/${getProjectIdFromUrl()}/filters/${reportType}/${dayjs(
          dateFrom
        ).format(f)}/${dayjs(dateTo).format(f)}`
      );
    }
    return AxiosHelper.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/report_template/view/${getProjectIdFromUrl()}/filters/${reportType}`
    );
  },
};

export default ReportTemplateConnection;
