import { _paginate30 } from "utils/states";
import {
  IContractServiceTypeFilterFormModel,
  IContractServiceTypeModel,
} from "../../models/contract/contractServiceTypeModels";

export const getContractServiceTypeState: IContractServiceTypeFilterFormModel =
  {
    name: "",
    ..._paginate30,
  };

export const createContractServiceTypeState: IContractServiceTypeModel = {
  name: "",
};
