import React, { FunctionComponent } from "react";
import { Table } from "rsuite";
import SpinnerSmall from "../atoms/Spinner/SpinnerSmall";

export declare type SortType = "desc" | "asc" | undefined;
interface ITable {
  data: Array<any>;
  children: React.ReactNode;
  rowClick?: (data: any, event?: any) => void;
  rowClassName?: any;
  defaultExpandAllRows?: boolean;
  expandedRowKeys?: Array<string>;
  renderRowExpanded?: any;
  rowExpandedHeight?: number;
  rowHeight?: number | ((rowData: any) => number);
  count?: any; // todo: out
  loading?: boolean;
  sortType?: any; //todo: SortType for all requestOrder.order
  sortColumn?: string;
  onSortColumn?: (dataKey: string, sortType?: SortType) => void;
  fixedHeight?: number;
  wordWrap?: boolean;
}

// @depracated
const ScTable: FunctionComponent<ITable> = (props) => {
  let rowClassNameSet: any = props.rowClick ? `tablePointer` : "";
  if (props.rowClassName !== undefined) {
    rowClassNameSet = props.rowClassName;
  }

  const getEmpty = (): JSX.Element => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}>
        <div>Brak rekordów do pokazania</div>
      </div>
    );
  };

  const getLoading = (): JSX.Element => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          zIndex: 9999,
          position: "relative",
          background: "rgba(255,255,255, .9)",
        }}>
        <div>
          <SpinnerSmall />
          <div>Ładowanie danych</div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Table
        loading={props?.loading ?? false}
        fillHeight
        height={props.fixedHeight}
        minHeight={300}
        headerHeight={56}
        // rowHeight={props.rowHeight === undefined ? 56 : props.rowHeight}
        data={props.data}
        rowKey={"id"}
        wordWrap={props.wordWrap ?? false}
        onRowClick={props.rowClick}
        rowClassName={rowClassNameSet}
        renderEmpty={getEmpty}
        renderLoading={getLoading}
        rowHeight={props.rowHeight}
        rowExpandedHeight={props.rowExpandedHeight ?? 100}
        defaultExpandAllRows={props.defaultExpandAllRows ?? false}
        renderRowExpanded={props.renderRowExpanded ?? undefined}
        expandedRowKeys={props.expandedRowKeys ?? undefined}
        sortColumn={props.sortColumn ?? undefined}
        sortType={props.sortType}
        onSortColumn={props.onSortColumn}>
        {props.children}
      </Table>
    </div>
  );
};

export default ScTable;
