import React, { FunctionComponent, memo, useEffect } from "react";
import { IVisitOrder } from "../../../../../../utils/models";
import styles from "../styles.module.scss";
import OrderLink from "../OrderLink";
import { Panel } from "rsuite";
import { usePanelContext } from "./VisitHelpers";
import SeparatorEmpty from "../../../../../../global/atoms/separators/SeparatorEmpty";

interface IVisitOrdersComponent {
  orders: Array<IVisitOrder>;
}

const VisitOrders: FunctionComponent<IVisitOrdersComponent> = (props) => {
  if (!props.orders.length) return <></>;

  const { registerPanelId, panelToggle, openPanels } = usePanelContext();

  useEffect(() => {
    registerPanelId("orders");
  }, []);

  return (
    <>
      <SeparatorEmpty />
      <Panel
        shaded
        header={"Zamówienia"}
        collapsible
        expanded={openPanels.includes("orders")}
        onSelect={() => panelToggle("orders")}>
        {props.orders.map((item, idx: number) => (
          <div
            className={`${styles.container} ${
              idx === 0 ? styles.radiusTopContainer : ""
            }`}
            key={item.id}>
            <OrderLink
              projectId={item.projectId}
              id={item.id}
              name={item.documentNumber}
            />
          </div>
        ))}
      </Panel>
    </>
  );
};

export default memo(VisitOrders);
