import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const JpkInvoicesTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Jpk",
        name: "jpk",
        active: false,
        options: [
          {
            name: "documentId",
            label: "Identyfikator faktury",
            active: false,
          },
          {
            name: "documentNumber",
            label: "Numer faktury",
            active: false,
          },
          {
            name: "createdDate",
            label: "Data wystawienia",
            active: false,
          },
          {
            name: "sellDate",
            label: "Data sprzedaży",
            active: false,
          },
          {
            name: "paymentDate",
            label: "Termin płatności",
            active: false,
          },
          {
            name: "locationName",
            label: "Nazwa nabywcy",
            active: false,
          },
          {
            name: "locationFullAddress",
            label: "Adres nabywcy",
            active: false,
          },
          {
            name: "locationNip",
            label: "NIP nabywcy",
            active: false,
          },
          {
            name: "amountTotal23",
            label: "Netto 23%",
            active: false,
          },
          {
            name: "vatTotal23",
            label: "Vat 23%",
            active: false,
          },
          {
            name: "amountTotal8",
            label: "Netto 8%",
            active: false,
          },
          {
            name: "vatTotal8",
            label: "Vat 8%",
            active: false,
          },
          {
            name: "amountTotal5",
            label: "Netto 5%",
            active: false,
          },
          {
            name: "vatTotal5",
            label: "Vat 5%",
            active: false,
          },
          {
            name: "amountTotal0",
            label: "Netto 0%",
            active: false,
          },
          {
            name: "taxExempt",
            label: "Zwolniona",
            active: false,
          },
          {
            name: "amountGrossTotal",
            label: "Kwota należności ogółem",
            active: false,
          },
          {
            name: "corrective",
            label: "Korygująca",
            active: false,
          },
          {
            name: "correctionReason",
            label: "Przyczyna korekty",
            active: false,
          },
          {
            name: "correctionNumber",
            label: "Nr faktury korygowanej",
            active: false,
          },
          {
            name: "correctionInvoiceDate",
            label: "Data wystawienia faktury korygowanej",
            active: false,
          },
          {
            name: "advanceInvoice",
            label: "Zaliczkowa",
            active: false,
          },
          {
            name: "reverseCharge",
            label: "Odwrotne obciążenie",
            active: false,
          },
          {
            name: "tourismServices",
            label: "Usługi turystyki",
            active: false,
          },
          {
            name: "comments",
            label: "Uwagi",
            active: false,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={6} />
    </>
  );
};

export default JpkInvoicesTemplate;
