import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Spinner from "global/atoms/Spinner/Spinner";
import { Row, Col, DatePicker, SelectPicker } from "rsuite";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { IContractServiceModel } from "../../../utils/models/contract/contractServiceModels";
import { createContractServiceState } from "../../../utils/states/contract/contractServiceStates";
import Connection from "../../../utils/connections/contract/contractServiceConnection";
import { format } from "date-fns";
import { isEmpty, isString } from "lodash";
import { ISelect } from "../../../utils/models";
import Section from "../../../global/atoms/section/Section";
import ContractServiceSalariesRateEdit from "./ContractServiceSalariesRateEdit";
import {
  IRate,
  ISalary,
} from "../../../utils/models/contract/contractSalaryModels";
import {
  rate,
  salary,
} from "../../../utils/states/contract/contractSalaryStates";

const errorStyle = {
  display: "block",
  color: "#ff0033",
};

interface IContractServiceEdit {}

export interface IFormContractService {
  projects: ISelect;
  locations: ISelect;
  rateSettlements: ISelect;
  rateTypes: ISelect;
  salaryTypes: ISelect;
  serviceTypes: ISelect;
}

const ContractServiceEdit: FunctionComponent<IContractServiceEdit> = () => {
  const [data, setData] = useState<IContractServiceModel>(
    createContractServiceState
  );
  const [salaryData, setSalaryData] = useState<ISalary>(salary);
  const [baseRateData, setBaseRateData] = useState<IRate>(rate);
  const [bonusRateData, setBonusRateData] = useState<IRate>(rate);
  const [extraRateData, setExtraRateData] = useState<IRate>(rate);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormContractService>();
  const history = useHistory();
  const { serviceId } = useParams<{ serviceId: string }>();
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const type: "add" | "edit" = serviceId === undefined ? "add" : "edit";
  if (type === "edit") {
    useEffect(() => {
      Connection.getFormEditContractService(id, serviceId).then((response) => {
        setData({
          ...response.data.model,
        });
        setBaseRateData(salaryData.baseRate);
        setBonusRateData(salaryData.bonusRate);
        setExtraRateData(salaryData.extraRate);
        setSalaryData({
          ...response.data.model.salary,
          type: response.data.model.salary.type.id,
        });

        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      Connection.getFormAddContractService(id).then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const dataToSend = {
      ...data,
      //id: id??null,
      salary: {
        ...salaryData,
        baseRate: { ...baseRateData, type: "BASE" },
        bonusRate: { ...bonusRateData, type: "BONUS" },
        extraRate: { ...extraRateData, type: "EXTRA" },
      },
    };
    if (type === "add") {
      handleToast(
        Connection.createContractService(id, dataToSend),
        setErrors
      ).then(() => history.push(`/contracts/${id}/services`));
      return true;
    }
    handleToast(
      Connection.updateContractService(id, serviceId, dataToSend),
      setErrors
    ).then(() => history.push(`/contracts/${id}/services`));
  };

  if (!form) return <Spinner />;

  return (
    <WhiteCard padding={true}>
      <Form handleSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            Projekt:
            <SelectPicker
              block
              cleanable={false}
              data={form.projects.options}
              value={data.projectId}
              onChange={(value) =>
                setData((data) => ({
                  ...data,
                  projectId: value?.toString() ?? "",
                }))
              }
              labelKey={"name"}
              valueKey={"id"}
            />
            {errors?.projectId && (
              <div style={errorStyle}>{errors.projectId}</div>
            )}
            <SeparatorEmpty size={0.75} />
            Lokalizacja:
            <SelectPicker
              block
              cleanable={false}
              data={form.locations.options}
              value={data.locationId}
              onChange={(value) =>
                setData((data) => ({
                  ...data,
                  locationId: value?.toString() ?? "",
                }))
              }
              labelKey={"name"}
              valueKey={"id"}
            />
            {errors?.locationId && (
              <div style={errorStyle}>{errors.locationId}</div>
            )}
          </Col>
          <Col xs={12}>
            Typ wynagrodzenia:
            <SelectPicker
              block
              cleanable={false}
              data={form.salaryTypes.options}
              value={salaryData.type}
              onChange={(value) =>
                setSalaryData((data) => ({
                  ...data,
                  type: value?.toString() ?? "",
                }))
              }
              labelKey={"name"}
              valueKey={"id"}
            />
            {errors?.salaryType && (
              <div style={errorStyle}>{errors.salaryType}</div>
            )}
            <SeparatorEmpty size={0.75} />
            Rodzaj usługi:
            <SelectPicker
              block
              cleanable={false}
              data={form.serviceTypes.options}
              value={data.contractServiceTypeId}
              onChange={(value) =>
                setData((data) => ({
                  ...data,
                  contractServiceTypeId: value?.toString() ?? "",
                }))
              }
              labelKey={"name"}
              valueKey={"id"}
            />
            {errors?.contractServiceTypeId && (
              <div style={errorStyle}>{errors.contractServiceTypeId}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            Data rozpoczęcia:
            <DatePicker
              format={"yyyy-MM-dd"}
              isoWeek
              oneTap
              onChange={(date: Date) =>
                setData((data) => ({
                  ...data,
                  expirationDates: {
                    ...data.expirationDates,
                    startDate: format(date, "yyyy-MM-dd"),
                  },
                }))
              }
              value={
                !isEmpty(data?.expirationDates?.startDate)
                  ? new Date(data.expirationDates.startDate)
                  : undefined
              }
              style={{ width: "100%" }}
              shouldDisableDate={(date?: Date) =>
                date !== undefined &&
                date < new Date(format(new Date(), "yyyy-MM-dd"))
              }
            />
            {errors?.expirationDates?.startDate && (
              <div style={errorStyle}>{errors.expirationDates.startDate}</div>
            )}
            {errors?.expirationDates && isString(errors.expirationDates) && (
              <div style={errorStyle}>{errors.expirationDates}</div>
            )}
          </Col>
          <Col xs={12}>
            Data zakończenia:
            <DatePicker
              format={"yyyy-MM-dd"}
              isoWeek
              oneTap
              onChange={(date: Date) =>
                setData({
                  ...data,
                  expirationDates: {
                    ...data.expirationDates,
                    endDate: format(date, "yyyy-MM-dd"),
                  },
                })
              }
              value={
                !isEmpty(data?.expirationDates?.endDate)
                  ? new Date(data.expirationDates.endDate)
                  : undefined
              }
              style={{ width: "100%" }}
              shouldDisableDate={(date?: Date) =>
                date !== undefined &&
                date < new Date(format(new Date(), "yyyy-MM-dd"))
              }
            />
            {errors?.expirationDates?.endDate && (
              <div style={errorStyle}>{errors.expirationDates.endDate}</div>
            )}
          </Col>
        </Row>
        <Section title={"Stawki"}>
          <ContractServiceSalariesRateEdit
            value={"BASE"}
            header={true}
            form={form}
            errors={errors}
            rateState={baseRateData}
            setRateState={setBaseRateData}
          />
          <SeparatorEmpty size={0.75} />
          <ContractServiceSalariesRateEdit
            value={"BONUS"}
            header={false}
            form={form}
            errors={errors}
            rateState={bonusRateData}
            setRateState={setBonusRateData}
          />
          <SeparatorEmpty size={0.75} />
          <ContractServiceSalariesRateEdit
            value={"EXTRA"}
            header={false}
            form={form}
            errors={errors}
            rateState={extraRateData}
            setRateState={setExtraRateData}
          />
          <SeparatorEmpty size={0.75} />
        </Section>
        <ActionsContainer>
          <ButtonSubmit form={form} />
        </ActionsContainer>
      </Form>
    </WhiteCard>
  );
};

export default ContractServiceEdit;
