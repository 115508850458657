import React, { FunctionComponent, useEffect, useState } from "react";
import DashboardConnection from "../../../utils/connections/dashboard";
import Map, { IPoint } from "../../../global/googleMap/Map";

import {
  visitMapErrorFallback,
  visitMapOnError,
} from "../../projects/components/visits/visitsEdit/VisitElements/VisitMap";
import { ErrorBoundary } from "react-error-boundary";
import {
  ICoords,
  IPagination,
  ISelect,
  ISort,
  IVisitStatus,
} from "../../../utils/models";
import WhiteCard from "../../../global/atoms/WhiteCard";
import SelectPicker from "rsuite/SelectPicker";
import SpinnerSmall from "../../../global/atoms/Spinner/SpinnerSmall";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "../../projects/components/visits/visitsEdit/styles.module.scss";
import DatePicker from "rsuite/DatePicker";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import IconSvg from "../../../global/atoms/IconHelper";
import { random } from "lodash";
import FormControlLabel from "rsuite/FormControlLabel";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { NotCancelErrorPromise } from "utils/helpers";
import { Panel, Toggle } from "rsuite";
import Row from "rsuite/Row";

interface IFiltersVisitsList {
  projectId: string | null;
  startDate: Date | null;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

interface IPointExtended extends IPoint {
  selected: boolean;
  hidden: boolean;
  status: { id: string };
}

export interface IDashboardFilter {
  projects: ISelect;
  visitsStatuses: { options: Array<IVisitStatus> };
}

const internalStatusStyle = {
  padding: "2px 10px",
  fontFamily: "Poppins, sans-serif",
  fontSize: "6x",
  fontWeight: 500,
  letterSpacing: "0.6px",
  width: "90%",
  display: "block",
  marginBottom: "3px",
};
const DashboardVisitsStatusMap: FunctionComponent = () => {
  const [points, setPoints] = useState<IPointExtended[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IDashboardFilter>();
  const [projectId, setProjectId] = useState<string | null>("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [gpsOption, setGpsOption] = useState<boolean>(false);

  const mapInfoWindowInitial: google.maps.InfoWindowOptions = {
    content: null,
    position: null,
  };

  const [mapInfoWindow, setMapInfoWindow] =
    useState<google.maps.InfoWindowOptions>(mapInfoWindowInitial);

  const visitsFilter: IFiltersVisitsList = {
    projectId: projectId,
    startDate: startDate,
    requestPaginate: { page: 0, limit: 100 },
    requestOrder: { field: undefined, order: undefined },
  };
  const latLon = (d) => {
    return gpsOption && d.locationVisitCoords
      ? {
          lat: d.locationVisitCoords.latitude, // + random(-0.001, +0.001),
          lon: d.locationVisitCoords.longitude, // + random(-0.001, +0.001),
        }
      : !gpsOption && d.locationAddressCoords
      ? {
          lat: d.locationAddressCoords.latitude + random(-0.001, +0.001),
          lon: d.locationAddressCoords.longitude + random(-0.001, +0.001),
        }
      : { lat: null, lon: null };
  };
  useEffect(() => {
    if (projectId) {
      setLoading(true);
      DashboardConnection.getPoints(visitsFilter)
        .then((result) => {
          setPoints(
            result.data.data.map((d) => ({
              ...d,
              ...latLon(d),
              selected: false,
              icon: {
                path: faDotCircle.icon[4] as string,
                fillColor: d.status.color, //"#9e9eec",
                fillOpacity: 0.7,
                strokeWeight: 0.1,
                strokeColor: "#e09616",
                scale: 0.035,
              },
              title: `${d.locationName} ${d.locationAddress}`,
              /*              label: {
                              text: d.networkName,
                              color: 'gray',
                              fontSize: '16px',
                              x: '200',
                              y: '100'
                            },*/
              hidden: false,
            }))
          );
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [projectId, startDate]);

  useEffect(() => {
    if (startDate) {
      setLoading(true);
      DashboardConnection.getFilters(visitsFilter).then((response) => {
        setLoading(false);
        setFilters(response.data);
        if (!projectId) setProjectId(response.data.projects.options[0].id);
      }, NotCancelErrorPromise);
    }
  }, [startDate]);

  useEffect(() => {
    getPoints();
  }, [gpsOption]);
  const getLocationLatLng = (p): null | google.maps.LatLngLiteral => {
    const cords: ICoords | null = gpsOption
      ? p.locationVisitCoords
      : p.locationAddressCoords;
    if (!cords) return null;
    return {
      lat: cords.latitude,
      lng: cords.longitude,
    };
  };

  const getLocationContent = (p): JSX.Element => {
    return (
      <div className={styles.visitMapInfoWindow}>
        <h6>{p.networkName}</h6>
        <div>
          {p.locationAddress}&nbsp;
          <br />
          {p.locationName}&nbsp;
          <br />
          <span style={{ color: p.status.color }}>{p.status.name}</span>
          <div>Sieć: {p.networkName}</div>
          <div>
            wykonawca:{" "}
            {p.users.find((e) => e.roleName === "wykonawca")?.userName}
          </div>
          <div>
            Supervisor:{" "}
            {p.users.find((e) => e.roleName === "supervisor")?.userName}
          </div>
          <div>
            {" "}
            {p.startDate} : {p.expirationDate}
          </div>
          <a
            className={"rs-btn-default"}
            style={{
              color: "white",
              borderColor: p.status.color,
              backgroundColor: p.status.color,
              position: "absolute",
              right: "5px",
              bottom: "5px",
              padding: "5px",
              borderRadius: "10px",
              fontWeight: "500",
              textDecoration: "none",
            }}
            href={`/projects/${projectId}/visits/${p.id}/edit`}
            target={"_blank"}
            rel="noreferrer">
            wizyta
          </a>
        </div>
      </div>
    );
  };
  const onMapClick = (p) => {
    const lat = p.latLng.toJSON();
    console.log(lat);
  };
  const getPoints = () => {
    const newPoints = points.map((_p) => ({
      ..._p,
      ...latLon(_p),
    }));
    setPoints(newPoints);
  };

  const onPointClick = (p: IPoint) => {
    const windowContent: null | JSX.Element = getLocationContent(p);
    const latLon: null | google.maps.LatLngLiteral = getLocationLatLng(p);

    if (windowContent && latLon) {
      setMapInfoWindow({
        content: renderToStaticMarkup(windowContent),
        position: latLon,
        pixelOffset: new google.maps.Size(0, -20),
      });
    }
  };

  const getVisitStatusCount = (status) => {
    const count = points?.filter((p) => p.status.id == status.id)?.length;
    return count ? (
      <span
        key={status.id}
        style={{
          fontSize: "8px",
          fontWeight: 700,
          backgroundColor: status.color,
          border: "1px solid",
          borderColor: status.color,
          position: "absolute",
          top: "2px",
          right: "2px",
          borderRadius: "10px",
          padding: "1px 5px 0px 5px",
          color: "white",
        }}>
        {count >= 100 ? "+99" : count}
      </span>
    ) : (
      <></>
    );
  };
  return (
    <>
      <WhiteCard style={{ position: "relative" }}>
        <ErrorBoundary
          FallbackComponent={visitMapErrorFallback}
          onError={visitMapOnError}>
          <Map
            points={points.filter((el) => !!el.lon)}
            //onPointMouseOver={p => onPointMouseHover(p, false)}
            //onPointMouseOut={p => onPointMouseHover(p, true)}
            onPointClick={onPointClick}
            onMapClick={onMapClick}
            infoWindow={mapInfoWindow}
            allowPointsCluster={false}
          />
        </ErrorBoundary>
        <div
          style={{
            position: "absolute",
            width: "20%",
            height: "100%",
            top: "10px",
            right: "10px",
            background: "#fff",
          }}>
          <Panel bordered style={{ backgroundColor: "white" }}>
            <div style={{ fontSize: "12px" }}>Kryteria</div>
            <Row>
              <Toggle
                size={"md"}
                checked={gpsOption}
                onChange={(checked) => {
                  setGpsOption(checked);
                }}
                checkedChildren={"Wizyty wg. logowań GPS"}
                unCheckedChildren={"Wizyty wg. adresu lokalizacji"}
              />
            </Row>

            <Row>
              {" "}
              <FormControlLabel>Data</FormControlLabel>
              <DatePicker
                isoWeek
                oneTap
                showWeekNumbers
                cleanable={false}
                value={startDate}
                onChange={(value) => setStartDate(value)}
                style={{ width: "100%" }}
                format={"yyyy-MM-dd"}
              />
            </Row>
            <Row>
              <FormControlLabel>Projekt</FormControlLabel>
              <SelectPicker
                data={filters?.projects?.options ?? []}
                valueKey={"id"}
                labelKey={"name"}
                loading={loading}
                style={{ width: "100%" }}
                value={projectId}
                placeholder={"wybierz projekt.."}
                onChange={(value) => {
                  setProjectId(value);
                }}
              />
            </Row>
          </Panel>
          <SeparatorEmpty />
          <Panel
            bodyFill
            bordered
            style={{
              minHeight: "200px",
            }}>
            {loading ? (
              <SpinnerSmall />
            ) : (
              <>
                <div style={{ fontSize: "12px", margin: "10px 10px" }}>
                  Legenda
                </div>
                <div
                  style={{
                    width: "95%",
                    overflow: "auto",
                    margin: "10px 10px",
                  }}>
                  {filters?.visitsStatuses?.options?.map((el) => {
                    return (
                      <div
                        key={`xx-${el.id}`}
                        style={{
                          position: "relative",
                          right: "10px",
                          top: "10px",
                        }}>
                        <span
                          style={{
                            ...internalStatusStyle,
                            color: el.color,
                            borderColor: el.color,
                          }}>
                          {IconSvg(faDotCircle, el.name, false, el.color)}
                          {el.name}
                        </span>
                        {getVisitStatusCount(el)}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Panel>
        </div>
      </WhiteCard>
    </>
  );
};

export default DashboardVisitsStatusMap;
