import React, { CSSProperties, FunctionComponent } from "react";
import { IFilter } from "../ItemsList";
import { ISelect } from "../../../../utils/models";
import BasicFilter from "./BasicFilter";
import { TagPicker } from "rsuite";

interface ILocationFilter {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  form: {
    networks: ISelect;
  };
}
const LocationFilter: FunctionComponent<ILocationFilter> = (props) => {
  const wrapperStyle: CSSProperties = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "flex-end",
  };

  return (
    <div style={wrapperStyle}>
      <TagPicker
        placeholder={"Wybierz sieć"}
        style={{ width: "450px" }}
        labelKey="name"
        valueKey="id"
        data={props.form.networks.options}
        value={props.filter.networks}
        onChange={(selected) =>
          props.setFilter({
            ...props.filter,
            networks: selected ?? [],
          })
        }
      />

      {/* <SelectMulti
          style={{ width: "450px" }}
          value={props.filter.networks.map((networkId) => ({
            value: networkId,
            label:
              props.form.networks.options.find((o) => o.id === networkId)
                ?.name ?? "-",
          }))}
          onChange={(
            selectedItems: Array<{ value: string; label: string }> | null
          ) => {
            props.setFilter({
              ...props.filter,
              networks: selectedItems?.map((o) => o.value) ?? [],
            });
          }}
          options={props.form.networks.options}
          placeholder={"Wybierz sieć"}
          name={"network"}
        /> */}
      <BasicFilter filter={props.filter} setFilter={props.setFilter} />
    </div>
  );
};
export default LocationFilter;
