import React from "react";
import ReactDOM from "react-dom";

interface IHeaderButtons {
  children: React.ReactNode;
}

const HeaderButtons: React.FC<IHeaderButtons> = ({ children }) => {
  const hb = document.getElementById("header-buttons");

  return <>{hb ? ReactDOM.createPortal(children, hb) : null}</>;
};

export default HeaderButtons;
