import React, { FunctionComponent, useEffect, useState } from "react";
import { Nav } from "rsuite";
import NotificationList from "./NotificationsList";
import NotificationMailer from "./NotificationMailer";
import AutoNotifications from "./AutoNotifications";
import { useHistory, useLocation } from "react-router-dom";
import NotificationsConnection from "../../../../utils/connections/notifications";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
interface INotifications {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Notifications: FunctionComponent<INotifications> = () => {
  enum _view {
    manual = "/notifications/manual",
    auto = "/notifications/auto",
    mailer = "/notifications/mailer",
  }

  const { pathname } = useLocation();
  const history = useHistory();
  const [disabledElements, setDisabledElements] = useState<Array<string>>();

  const isNotDisabled = (key: string): boolean => {
    return !!disabledElements && !(disabledElements ?? [])?.includes(key);
  };
  useEffect(() => {
    NotificationsConnection.getFormRightsGet().then((response) => {
      setDisabledElements(response.data.disabledElements);
    });
  }, []);
  /* default redirect is not served in routes */
  if (!!disabledElements && pathname == "/notifications") {
    if (isNotDisabled("NOTIFICATION_MAILER")) {
      history.push(_view.mailer);
    } else if (isNotDisabled("NOTIFICATION_MANUAL")) {
      history.push(_view.manual);
    } else if (isNotDisabled("NOTIFICATION_AUTO")) {
      history.push(_view.auto);
    }
  }

  const handleChangeView = (newView: _view) => {
    history.push(newView);
  };

  if (!disabledElements) return <Spinner />;
  return (
    <>
      <Nav
        appearance={"default"}
        activeKey={pathname}
        onSelect={handleChangeView}
        style={{ marginBottom: "10px" }}>
        {isNotDisabled("NOTIFICATION_MAILER") && (
          <Nav.Item eventKey={_view.mailer}>Skrzynka nadawcza</Nav.Item>
        )}
        {isNotDisabled("NOTIFICATION_MANUAL") && (
          <Nav.Item eventKey={_view.manual}>Ręczne</Nav.Item>
        )}
        {isNotDisabled("NOTIFICATION_AUTO") && (
          <Nav.Item eventKey={_view.auto}>Automatyczne</Nav.Item>
        )}
      </Nav>
      {isNotDisabled("NOTIFICATION_MANUAL") && pathname === _view.manual && (
        <NotificationList />
      )}
      {isNotDisabled("NOTIFICATION_AUTO") && pathname === _view.auto && (
        <AutoNotifications />
      )}
      {isNotDisabled("NOTIFICATION_MAILER") && pathname === _view.mailer && (
        <NotificationMailer />
      )}
    </>
  );
};

export default Notifications;
