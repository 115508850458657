import { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl } from "../helpers";

const CountriesConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCountries: (data: any) => {
    return axios.post(`${getBaseApiUrl()}/countries/list`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createCountry: (data: any) => {
    return axios.post(`${getBaseApiUrl()}/countries`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateCountry: (id: string, data: any) => {
    return axios.put(`${getBaseApiUrl()}/countries/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersCountriesList: () => {
    return axios.get(`${getBaseApiUrl()}/countries/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddCountry: () => {
    return axios.get(`${getBaseApiUrl()}/countries/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditCountry: (id: string) => {
    return axios.get(`${getBaseApiUrl()}/countries/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
};

export default CountriesConnection;
