import Spinner from "global/atoms/Spinner/Spinner";
import React, { FunctionComponent } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { getProjectIdFromUrl } from "utils/helpers";

interface IProjectProxyRedirect {
  redirectTo: string;
}

const ProjectProxyRedirect: FunctionComponent<IProjectProxyRedirect> = (
  props
) => {
  const history = useHistory();
  const projectId = getProjectIdFromUrl();
  if (projectId) {
    history.push(generatePath(props.redirectTo, { id: projectId }));
  }
  return <Spinner />;
};

export default ProjectProxyRedirect;
