import { SET_TOAST } from "../actions";

// ToastRequesterRedux.tsx
const toastReducer = (state = null, action: any) => {
  switch (action.type) {
    case SET_TOAST:
      return action.payload;
    default:
      return state;
  }
};

export default toastReducer;
