import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  IconButton,
  InputGroup,
  SelectPicker,
  Steps,
} from "rsuite";
import CloseIcon from "@rsuite/icons/Close";
import TargetConnections from "./TargetConnections";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import Section from "../../../../global/atoms/section/Section";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { validate } from "uuid";
import { handleToast } from "../../../../utils/helpers";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import { ISelect, IUserMultiSelectRawEntity } from "../../../../utils/models";
import EditIcon from "@rsuite/icons/Edit";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import TargetUsers from "./TargetUsers";

interface ITargetsEditProps {
  targetDefinitionId?: string;
}

export interface ITargetModel {
  targetId: string;
  name: string;
  description: string;
  type: string;
  status: string;
  startDate: Date;
  endDate: Date;
  users: Array<{ userId: string; value: string }>;
  items: Array<string>;
  locations: Array<string>;
  questions: Array<string>;
}

interface ITargetModelRequest {
  targetId: string;
  name: string;
  description: string;
  type: { id: string };
  status: { id: string };
  startDate: Date;
  endDate: Date;
}

export interface ITargetForm {
  types: ISelect;
  statuses: ISelect;
  users: Array<IUserMultiSelectRawEntity>;
  items: Array<{ itemName: string; itemId: string }>;
  locations: Array<{ locationName: string; locationId: string }>;
  questions: Array<{ questionName: string; questionId: string }>;
}

const TargetsEdit: FunctionComponent<ITargetsEditProps> = () => {
  const history = useHistory();
  const { id, targetId } = useParams<{ id: string; targetId: string }>();
  const [_targetId, setTargetId] = useState<string>(targetId);

  const TargetModelDefault: ITargetModel = {
    targetId: _targetId,
    name: "",
    description: "",
    type: "",
    status: "",
    startDate: new Date(),
    endDate: new Date(),
    users: [],
    items: [],
    locations: [],
    questions: [],
  };

  const [targetModel, setTargetModel] =
    useState<Partial<ITargetModel>>(TargetModelDefault);
  const [targetForm, setTargetForm] = useState<Partial<ITargetForm>>();
  const [errors, setErrors] = useState<any>(null);

  const mode = _targetId ? "edit" : "add";
  const getItemsForm = () => {
    TargetConnections.getTargetItemsForm(id, _targetId).then((response) => {
      setTargetForm((s) => ({ ...s, items: response.data.items.data }));
    });
  };
  const getLocationsForm = () => {
    TargetConnections.getTargetLocationsForm(id, _targetId).then((response) => {
      setTargetForm((s) => ({ ...s, locations: response.data.locations.data }));
    });
  };
  const getQuestionsForm = () => {
    TargetConnections.getTargetQuestionForm(id, _targetId).then((response) => {
      setTargetForm((s) => ({ ...s, questions: response.data.questions.data }));
    });
  };

  useEffect(() => {
    if (_targetId) {
      getItemsForm();
      getLocationsForm();
      getQuestionsForm();
    }
  }, [_targetId]);
  useEffect(() => {
    if (mode == "edit") {
      TargetConnections.getTargetModel(id, _targetId).then(
        (response: AxiosResponse<any>) => {
          const model = response.data.model as ITargetModelRequest;
          setTargetModel({
            ...model,
            type: model.type.id,
            status: model.status.id,
            startDate: dayjs(model.startDate).toDate(),
            endDate: dayjs(model.endDate).toDate(),
          });
          setTargetForm((s) => ({ ...s, ...response.data }));
        }
      );
    } else {
      TargetConnections.getTargetForm(id).then(
        (response: AxiosResponse<any>) => {
          setTargetForm((s) => ({ ...s, ...response.data }));
        }
      );
    }
  }, []);

  const nextStep = () => {
    if (step < lastStep) {
      setStep(step + 1);
    }
  };
  const funcItemMap = (item: {
    itemName: string;
    itemId: string;
  }): { name: string; id: string } => {
    return {
      ...item,
      name: item.itemName,
      id: item.itemId,
    };
  };
  const funcLocationMap = (location: {
    locationName: string;
    locationId: string;
  }): { name: string; id: string } => {
    return {
      ...location,
      name: location.locationName,
      id: location.locationId,
    };
  };
  const funcQuestionMap = (location: {
    questionName: string;
    questionId: string;
  }): { name: string; id: string } => {
    return {
      ...location,
      name: location.questionName,
      id: location.questionId,
    };
  };
  const getItemAssigned = () => {
    return (targetForm?.items ?? [])
      .filter((p) => (targetModel.items ?? []).indexOf(p.itemId) >= 0)
      .map(funcItemMap);
  };
  const getItemUnAssigned = () => {
    return (targetForm?.items ?? [])
      .filter((p) => (targetModel.items ?? []).indexOf(p.itemId) < 0)
      .map(funcItemMap);
  };
  const getLocationsAssigned = () => {
    return (targetForm?.locations ?? [])
      .filter((p) => (targetModel.locations ?? []).indexOf(p.locationId) >= 0)
      .map(funcLocationMap);
  };
  const getLocationsUnAssigned = () => {
    return (targetForm?.locations ?? [])
      .filter((p) => (targetModel.locations ?? []).indexOf(p.locationId) < 0)
      .map(funcLocationMap);
  };
  const getQuestionsAssigned = () => {
    return (targetForm?.questions ?? [])
      .filter((p) => (targetModel.questions ?? []).indexOf(p.questionId) >= 0)
      .map(funcQuestionMap);
  };
  const getQuestionsUnAssigned = () => {
    return (targetForm?.questions ?? [])
      .filter((p) => (targetModel.questions ?? []).indexOf(p.questionId) < 0)
      .map(funcQuestionMap);
  };
  const handleSubmit = () => {
    const users = targetModel?.users
      ?.map((u) => ({ userId: u.userId, value: parseInt(u.value) }))
      ?.filter((u) => u.value > 0);
    const toSend = {
      ...targetModel,
      startDate: dayjs(targetModel.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(targetModel.endDate).format("YYYY-MM-DD"),
      users: users,
    };
    if (mode == "add") {
      handleToast(TargetConnections.addTargetForm(id, toSend), setErrors).then(
        (response) => {
          if (validate(response.data.targetId)) {
            setTargetId(response.data.targetId);
            nextStep();
            //history.push(`/projects/${id}/targets/${response.data.targetId}`);
          }
        }
      );
    } else {
      handleToast(
        TargetConnections.editTargetForm(id, _targetId, toSend),
        setErrors
      ).then(() => {
        history.push(`/projects/${id}/targets`);
      });
    }
  };
  const [step, setStep] = useState(0);
  const baseData = 0;
  const usersDataStep = 1;
  const itemDataStep = 2;
  const locationDataStep = 3;
  const questionDataStep = 4;
  const lastStep = questionDataStep;

  const baseStepHasErrors = () => {
    return !!errors?.length;
  };
  console.log(targetModel.users);
  if (!targetForm) return <Spinner content={"Ładowanie..."} />;
  return (
    <>
      <WhiteCard
        style={{
          position: "relative",
          overflow: "hidden",
          height: "calc(100vh - 100px)",
        }}>
        <Col xs={3}>
          <Steps vertical current={step} style={{ opacity: ".6" }}>
            <Steps.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                setStep(baseData);
              }}
              title="Dane podstawowe"
              description={
                errors &&
                errors?.length > 0 && (
                  <div style={{ color: baseStepHasErrors() ? "red" : "" }}>
                    dane podstawowe zawierają błędy{" "}
                    <span>{errors?.length > 0 ? errors?.length : ""}</span>
                  </div>
                )
              }
            />
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisania użytkowników"
                onClick={() => {
                  setStep(usersDataStep);
                }}
              />
            )}
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisania produktów"
                onClick={() => {
                  setStep(itemDataStep);
                }}
              />
            )}
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisanie lokalizacji"
                onClick={() => {
                  setStep(locationDataStep);
                }}
              />
            )}
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisanie pytań"
                onClick={() => {
                  setStep(questionDataStep);
                }}
              />
            )}
          </Steps>
          {errors?.message && <span>errors.message</span>}
          {mode == "edit" && (
            <IconButton
              onClick={() => {
                if (step == lastStep) handleSubmit();
                else nextStep();
              }}
              appearance={"ghost"}
              icon={step == lastStep ? <EditIcon /> : <ArrowRightLineIcon />}>
              {step == lastStep ? "Zapisz konfigurację" : "Dalej"}
            </IconButton>
          )}
        </Col>
        <Col
          xs={21}
          style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
          {step == baseData && (
            <Section
              title={"Dane podstawowe"}
              style={{ backgroundColor: "white" }}>
              <Form
                fluid
                onChange={(form: Partial<ITargetModel>) => {
                  setTargetModel((model) => ({ ...model, ...form }));
                  Object.keys(form).forEach((key) => {
                    if (form[key] && errors && errors[key])
                      errors[key] = undefined;
                  });
                }}>
                <Col xs={24}>
                  <Form.Group controlId={"name"}>
                    <Form.ControlLabel>Nazwa</Form.ControlLabel>
                    <InputGroup style={{ width: "100%" }}>
                      <Form.Control
                        size={"md"}
                        value={targetModel?.name}
                        name={"name"}
                        errorMessage={errors?.name}
                        errorPlacement={"bottomEnd"}
                      />
                      {targetModel?.name && (
                        <InputGroup.Addon
                          onClick={() => {
                            setTargetModel((s) => ({ ...s, name: "" }));
                          }}>
                          <CloseIcon style={{ cursor: "pointer" }} />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>{" "}
                <Col xs={24}>
                  <Form.Group controlId={"description"}>
                    <Form.ControlLabel>Opis</Form.ControlLabel>
                    <InputGroup style={{ width: "100%" }}>
                      <Form.Control
                        size={"md"}
                        value={targetModel?.description}
                        name={"description"}
                        errorMessage={errors?.description}
                        errorPlacement={"bottomEnd"}
                      />
                      {targetModel?.description && (
                        <InputGroup.Addon
                          onClick={() => {
                            setTargetModel((s) => ({ ...s, description: "" }));
                          }}>
                          <CloseIcon style={{ cursor: "pointer" }} />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>{" "}
                <Col xs={24}>
                  <Form.Group controlId={"type"}>
                    <Form.ControlLabel>Typ</Form.ControlLabel>
                    <Form.Control
                      disabled={mode == "edit"}
                      style={{ width: "100%" }}
                      size={"md"}
                      value={targetModel?.type}
                      cleanable
                      accepter={SelectPicker}
                      data={targetForm?.types?.options ?? []}
                      labelKey={"name"}
                      valueKey={"id"}
                      name={"type"}
                      errorMessage={errors?.type}
                      errorPlacement={"bottomEnd"}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col xs={24}>
                  <Form.Group controlId={"status"}>
                    <Form.ControlLabel>Status</Form.ControlLabel>
                    <Form.Control
                      style={{ width: "100%" }}
                      size={"md"}
                      value={targetModel?.status}
                      cleanable
                      accepter={SelectPicker}
                      data={targetForm?.statuses?.options ?? []}
                      labelKey={"name"}
                      valueKey={"id"}
                      name={"status"}
                      errorMessage={errors?.status}
                      errorPlacement={"bottomEnd"}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId={"startDate"}>
                    <Form.ControlLabel>Data obowiązywania od</Form.ControlLabel>
                    <Form.Control
                      style={{ width: "100%" }}
                      isoWeek
                      showWeekNumbers
                      size={"md"}
                      value={targetModel?.startDate}
                      cleanable
                      name={"startDate"}
                      accepter={DatePicker}
                      oneTap
                      block
                      ranges={[
                        {
                          label: "Dziś",
                          value: new Date(),
                        },
                      ]}
                      errorMessage={errors?.startDate}
                      errorPlacement={"bottomEnd"}
                    />
                    {/*<Form.HelpText style={{padding: 0}}>Data obowiązywania</Form.HelpText>*/}
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId={"endDate"}>
                    <Form.ControlLabel>Data do</Form.ControlLabel>
                    <Form.Control
                      style={{ width: "100%" }}
                      isoWeek
                      showWeekNumbers
                      size={"md"}
                      value={targetModel?.endDate}
                      cleanable
                      name={"endDate"}
                      accepter={DatePicker}
                      oneTap
                      block
                      ranges={[
                        {
                          label: "Dziś",
                          value: new Date(),
                        },
                      ]}
                      errorMessage={errors?.endDate}
                      errorPlacement={"bottomEnd"}
                    />
                    {/*<Form.HelpText style={{padding: 0}}>Data obowiązywania</Form.HelpText>*/}
                  </Form.Group>
                </Col>
                <SeparatorEmpty />
              </Form>
            </Section>
          )}
          <SeparatorEmpty />
          {mode == "edit" && step == usersDataStep && (
            <>
              <TargetUsers
                projectId={id}
                targetId={_targetId}
                targetModel={targetModel}
                setTargetModel={setTargetModel}
                targetForm={targetForm}
                setTargetForm={setTargetForm}
              />
            </>
          )}
          {mode == "edit" && step == itemDataStep && (
            <DoubleSidePicker
              loading={!targetForm?.items}
              heading={"Przypisania produktów"}
              emptyMessageAssigned={"Dostępny dla wszystkich produktów"}
              emptyMessageNotAssigned={"Brak produktów w systemie"}
              assigned={getItemAssigned()}
              unassigned={getItemUnAssigned()}
              form={targetForm}
              onChange={(assignedIds) => {
                setTargetModel((s) => ({ ...s, items: assignedIds }));
              }}
              itemType={"product"}
              filtersHidden
            />
          )}
          {mode == "edit" && step == locationDataStep && (
            <DoubleSidePicker
              loading={!targetForm?.locations}
              heading={"Przypisania lokalizacji"}
              emptyMessageAssigned={"Dostępny dla wszystkich lokalizacji"}
              emptyMessageNotAssigned={"Brak lokalizacji w systemie"}
              assigned={getLocationsAssigned()}
              unassigned={getLocationsUnAssigned()}
              form={targetForm}
              onChange={(assignedIds) => {
                setTargetModel((s) => ({ ...s, locations: assignedIds }));
              }}
              itemType={"base"}
            />
          )}
          {mode == "edit" && step == questionDataStep && (
            <DoubleSidePicker
              loading={!targetForm?.questions}
              heading={"Przypisania pytań"}
              emptyMessageAssigned={"Dostępny dla wszystkich pytań"}
              emptyMessageNotAssigned={"Brak pytań w systemie"}
              assigned={getQuestionsAssigned()}
              unassigned={getQuestionsUnAssigned()}
              form={targetForm}
              onChange={(assignedIds) => {
                setTargetModel((s) => ({ ...s, questions: assignedIds }));
              }}
              itemType={"base"}
            />
          )}
        </Col>
        <SeparatorEmpty size={2} />
        <ActionsContainer>
          <Button
            appearance={"ghost"}
            onClick={() => history.push(`/projects/${id}/targets`)}>
            Anuluj
          </Button>
          <IconButton
            onClick={() => {
              handleSubmit();
            }}
            appearance={"ghost"}
            icon={<EditIcon />}>
            Zapisz konfigurację
          </IconButton>
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default TargetsEdit;
