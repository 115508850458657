import { _paginate30 } from "utils/states";
import {
  ICreateContract,
  ICreateContractUser,
  IGetContract,
} from "../../models/contract/contractModels";

export const getContractState: IGetContract = {
  contractorId: "",
  number: "",
  status: "",
  category: "",
  content: "",
  ..._paginate30,
};

export const createContractState: ICreateContract = {
  id: "",
  //@ts-ignore
  contractor: null,
  number: "",
  status: "",
  type: "",
  category: "",
  startDate: "",
  endDate: "",
  contractProjectId: "",
  roleId: "",
};
export const createContractUserState: ICreateContractUser = {
  id: "",
  firstName: "",
  lastName: "",
  pesel: "",
  email: "",
  phone: "",
  idCard: "",
  customerId: "",
};

export const isValidPesel = (pesel: string): boolean => {
  if (pesel.length !== 11) return false;
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  const controlNumber = parseInt(pesel.substring(10, 11));
  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
};
