import React, { useEffect, useState } from "react";
import TargetConnections from "./TargetConnections";
import { FCC, IUserMultiSelectRawEntity } from "../../../../utils/models";
import { ITargetForm, ITargetModel } from "./TargetsEdit";
import Section from "../../../../global/atoms/section/Section";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { AxiosResponse } from "axios";

import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import UserGlobalFilter from "../../../../global/atoms/dooubleSidePicker/itemFilter/UserGlobalFilter";
import { IFilter } from "../../../../global/atoms/dooubleSidePicker/ItemsList";
import { Col, Form, InputGroup, Row } from "rsuite";
import CloseIcon from "@rsuite/icons/Close";

interface ITargetUsersProps {
  children?: React.ReactNode;
  projectId: string;
  targetId: string;
  targetModel: Partial<ITargetModel>;
  setTargetModel: (s: (s) => any) => void;
  targetForm: Partial<ITargetForm>;
  setTargetForm: (s: (s) => any) => void;
}

interface IUserFilter {
  textSearch: string;
  networkId: string;
  projectId: string;
  roleId: string;
  cityName: string;
  userId: string;
  roleConnectedId: string;
  linkedUsersOption: boolean;
}

const TargetUsers: FCC<ITargetUsersProps> = (props) => {
  const [targetCommonValue, setTargetCommonValue] = useState<string>("");
  const [userFilterModel, setUserFilterModel] = useState<IUserFilter>({
    textSearch: "",
    projectId: "",
    networkId: "",
    cityName: "",
    roleId: "",
    userId: "",
    roleConnectedId: "",
    linkedUsersOption: false,
  });
  useEffect(() => {
    if (!props?.targetForm?.users)
      TargetConnections.getTargetUsersForm(
        props.projectId,
        props.targetId
      ).then((response: AxiosResponse<any>) => {
        props.setTargetForm((s) => ({ ...s, users: response.data.users.data }));
      });
  }, []);

  const handleTargetChange = (userId: string, value: string) => {
    const _users =
      props?.targetModel?.users?.filter((u) => u.userId !== userId) ?? [];
    _users.push({ userId: userId, value: value });

    props.setTargetModel((s) => ({
      ...s,
      users: _users,
    }));
  };
  const getUsers = () => {
    if (!props?.targetForm?.users) return [];
    const parsed: Array<IUserMultiSelectRawEntity> = [];
    let tmp = props?.targetForm?.users;
    const filter = userFilterModel;
    if (filter.projectId)
      tmp = tmp.filter((u) => filter.projectId === u.projectId);
    /* filtering by network */
    if (filter.networkId)
      tmp = tmp.filter((u) => filter.networkId === u.networkId);
    /* filtering by city */
    if (filter.cityName)
      tmp = tmp.filter((u) => filter.cityName === u.cityName);

    if (filter.linkedUsersOption) {
      const locations: { [locationId: string]: string } = {};
      tmp
        .filter(
          (u) =>
            u.locationId &&
            (filter.roleId ? u.roleId == filter.roleId : true) &&
            (filter.userId ? u.userId == filter.userId : true)
        )
        .forEach((u) => {
          locations[u.locationId] = u.locationId;
        });

      /* filtering by locations */
      tmp = tmp.filter(
        (u) =>
          u.locationId &&
          locations[u.locationId] &&
          (filter.roleConnectedId
            ? filter.roleConnectedId === u.roleId
            : true) &&
          (filter.roleId ? filter.roleId != u.roleId : true) &&
          (filter.userId ? filter.userId != u.userId : true)
      );
    } else {
      if (filter.roleId) {
        tmp = tmp.filter((u) => filter.roleId === u.roleId);
      }
    }

    /* filtering by text */
    if (filter.textSearch)
      tmp = tmp.filter(
        (u) =>
          u.userName.toLowerCase().indexOf(filter.textSearch.toLowerCase()) >= 0
      );

    // get id of duplicates
    tmp.forEach((u) => {
      if (parsed.find((pu) => pu.userId === u.userId) === undefined) {
        parsed.push(u);
      }
    });
    // @ts-ignore
    return parsed ?? [];
  };

  if (!props?.targetForm?.users) return <Spinner />;
  return (
    <>
      <Section title={"Pracownicy"}>
        <UserGlobalFilter
          usersData={props?.targetForm?.users ?? []}
          filter={userFilterModel as IFilter}
          setFilter={
            setUserFilterModel as React.Dispatch<React.SetStateAction<IFilter>>
          }
        />
        <SeparatorLine />
        <Form fluid>
          <Row
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
              marginBottom: "10px",
              borderBottom: "1px solid rgba(93, 92, 92, 0.16)",
              backgroundColor: "#FBFBFB",
              fontWeight: "bold",
              color: "#13131552",
            }}>
            <Col
              xs={12}
              style={{
                fontWeight: "normal",
                textAlign: "right",
                paddingTop: "15px",
              }}>
              Ustaw wartość dla wszystkich
            </Col>
            <Col xs={12}>
              <Form.Group controlId={"name"}>
                <InputGroup style={{ width: "100%" }}>
                  <Form.Control
                    name={"all_update"}
                    value={targetCommonValue}
                    onChange={(value: string) => {
                      if (!value) return;
                      const cleaned_value = value.replace(/\D/g, "");
                      setTargetCommonValue(cleaned_value);
                      const _users = getUsers().map((user) => ({
                        userId: user.userId,
                        value: cleaned_value,
                      }));
                      props.setTargetModel((s) => ({
                        ...s,
                        users: _users,
                      }));
                    }}
                  />
                  {targetCommonValue && (
                    <InputGroup.Addon
                      onClick={() => {
                        setTargetCommonValue("");
                      }}>
                      <CloseIcon style={{ cursor: "pointer" }} />
                    </InputGroup.Addon>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          {getUsers().map((user, key) => {
            const inputValue = props?.targetModel?.users?.find(
              (u) => u?.userId === user.userId
            )?.value;
            return (
              <>
                <Row
                  style={{
                    paddingBottom: "5px",
                    backgroundColor: key % 2 == 0 ? "#FBFBFB" : "white",
                  }}>
                  <Col
                    xs={12}
                    style={{ paddingTop: "15px", paddingLeft: "5px" }}>
                    {user.userName}
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <InputGroup style={{ width: "100%" }}>
                        <Form.Control
                          name={`target-${user.userId}-${key}`}
                          value={inputValue}
                          onChange={(value: string) => {
                            const cleaned_value = value.replace(/\D/g, "");
                            handleTargetChange(user.userId, cleaned_value);
                          }}
                        />
                        {inputValue && (
                          <InputGroup.Addon
                            onClick={() => {
                              handleTargetChange(user.userId, "");
                            }}>
                            <CloseIcon style={{ cursor: "pointer" }} />
                          </InputGroup.Addon>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}
        </Form>
      </Section>
    </>
  );
};

export default TargetUsers;
