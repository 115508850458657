import React, { useEffect, useState } from "react";
import { FCC } from "utils/models";
import { useSelector } from "react-redux";
import { IRoot, IToast } from "redux/models";
import { ToastTypes } from "global/ToastNotification";
import { useToaster, Message } from "rsuite";
import { cloneDeep } from "lodash";

interface IToastRequesterRedux {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToastRequesterRedux: FCC<IToastRequesterRedux> = (props) => {
  const toastStorage = useSelector((state: IRoot) => state.toast);
  const cancelToastId = useSelector((state: IRoot) => state.cancelToast);

  const toaster = useToaster();
  const [toasts, setToasts] = useState<
    Array<{
      internalToastId: string;
      toast: IToast;
    }>
  >([]);

  useEffect(() => {
    if (!toastStorage) return;

    const res = toaster.push(
      <Message
        showIcon
        header={toastStorage.header ?? ""}
        type={
          toastStorage.type === ToastTypes.loading
            ? ToastTypes.info
            : toastStorage.type
        }
        style={{ zIndex: 1001, minWidth: "200px" }}
        closable>
        {toastStorage.message ?? ""}
      </Message>,
      {
        duration: toastStorage.duration ?? 0,
        placement: toastStorage.placement ?? "bottomEnd",
      }
    );

    if (typeof res === "string") updateToast(res);
    else res.then((_tid) => updateToast(_tid));
  }, [toastStorage]);

  useEffect(() => {
    if (!cancelToastId) return;
    removeToast(cancelToastId);
    setToasts((ts) =>
      ts.filter((_ts) => _ts.internalToastId !== cancelToastId)
    );
  }, [cancelToastId]);

  const updateToast = (internalToastId: string) => {
    const toastIndex = toasts.findIndex((t) => t.toast.id === toastStorage.id);
    if (toastIndex <= -1) {
      // new toast
      setToasts((ts) => [
        ...ts,
        { toast: toastStorage, internalToastId: internalToastId },
      ]);
    } else {
      // "update" toast
      const toastsCloned = cloneDeep(toasts);
      toastsCloned[toastIndex].toast = toastStorage;
      removeToast(toastsCloned[toastIndex].internalToastId);
      toastsCloned[toastIndex].internalToastId = internalToastId;
      setToasts(toastsCloned);
    }
  };

  const removeToast = (internalToastId: string) => {
    setTimeout(() => {
      toaster.remove(internalToastId);
    }, 200);
  };

  return <></>;
};

export default ToastRequesterRedux;
