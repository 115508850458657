import React, { FunctionComponent, useState } from "react";
import {
  ICustomer,
  IHeader,
  IOrderPosition,
  IPagination,
  IProductPrice,
  ISelectViewResponse,
  ISort,
} from "utils/models";
import { _paginate30, selectViewResponseState } from "utils/states";
import {
  getProjectIdFromUrl,
  handleSortColumn,
  handleToast,
} from "utils/helpers";
import { generatePath, useParams } from "react-router-dom";

import BadgeStatus from "global/atoms/badge/BadgeStatus";
import Pagination from "global/pagination/Pagination";
import OrderConnection from "utils/connections/orders";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import {
  BatchActionDropdownItem,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import { confirmModalHandler } from "../../../../redux/store";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";

import Table from "rsuite/Table";
import TrashIcon from "@rsuite/icons/Trash";
import SendIcon from "@rsuite/icons/Send";
import IconButton from "../../../../global/atoms/IconButton";
import download from "downloadjs";
import { Dropdown } from "rsuite";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import styles from "../warehouse/styles.module.scss";

export interface IOrderFilters {
  customerId: string;
  locationId: string;
  userId: string;
  statusId: string;
  documentNumber: string;
  dateFrom: number;
  dateTo: number;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

interface IOrderData {
  customer: any;
  deliveryDate: string;
  documentDate: string;
  orderId: string;
  documentNumber: string;
  isPaid: boolean;
  isPrinted: boolean;

  paymentDate: string;
  positions: Array<IOrderPosition>;
  location: any;
  status: {
    color: string;
    id: string;
    isDefault: boolean;
    name: string;
    status: string;
  };
  user: {
    customer: ICustomer;
    fullName: string;
  };
  price: IProductPrice;
}

interface IOrdersData {
  columns: any;
  header: IHeader;
  data: Array<IOrderData>;
  count: number;
}

const OrdersList: FunctionComponent = () => {
  const [data, setData] = useState<IOrdersData | null>(null);
  const defaultFilters: IOrderFilters = {
    customerId: "",
    locationId: "",
    userId: "",
    statusId: "",
    documentNumber: "",
    dateFrom: 0,
    dateTo: 0,
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IOrderFilters>(defaultFilters);
  const { id } = useParams<{ id: string }>();
  const [chosenVisits, setChosenVisits] = useState<Array<string>>([]);
  const [form, setForm] = useState<{
    customers: ISelectViewResponse;
    locations: ISelectViewResponse;
    orderStatuses: ISelectViewResponse;
    users: ISelectViewResponse;
  }>({
    customers: selectViewResponseState,
    locations: selectViewResponseState,
    orderStatuses: selectViewResponseState,
    users: selectViewResponseState,
  });
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleSend = () => {
    if (!chosenVisits.length) return;
    OrderConnection.sendOrders({
      orders: chosenVisits.map((item) => ({ id: item })),
    }).then(() => {
      setTriggerLoad(Date.now());
      setChosenVisits([]);
    });
  };

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenVisits.map((id) => ({ id: id }));
    if (deleteId !== undefined) toDelete = [{ id: deleteId }];
    handleToast(OrderConnection.removeOrders({ orders: toDelete })).then(() => {
      setChosenVisits([]);
      setTriggerLoad(Date.now());
    });
  };

  const handleDownloadFile = (order: IOrderData) => {
    handleToast(OrderConnection.downloadPdfBlob(order.orderId)).then((res) => {
      download(res.data, `${order.documentNumber}.pdf`);
    });
  };
  const locationFullAddressName = (address) => {
    return `${address?.street?.name} ${address?.houseNumber}${
      address?.apartmentNumber ? `/${address?.apartmentNumber}` : ""
    }, ${address?.postCode?.name} ${address?.city?.name}`;
  };
  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<SendIcon />}
            onClick={confirmModalHandler(
              "Czy wysłać zaznaczone zamówienia?",
              handleSend
            )}
            disabled={!chosenVisits.length}>
            Wyślij zaznaczone
          </Dropdown.Item>
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            icon={<TrashIcon />}
            chosenRows={chosenVisits}
            title={`Usuń zaznaczone zamówienia (${chosenVisits.length})`}
            message={"Czy na pewno chcesz usunąć zaznaczone zamówienia?"}
          />
        </OptionsDropdown>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenVisits}
                  setState={setChosenVisits}
                  keyIdName={"orderId"}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenVisits}
                    setState={setChosenVisits}
                    selectedId={rowData.orderId}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2} sortable>
              <Table.HeaderCell>Numer</Table.HeaderCell>
              <Table.Cell dataKey="documentNumber">
                {(rowData: IOrderData) => (
                  <ButtonListRedirect
                    redirectUrl={generatePath(
                      "/projects/:id/orders/:orderId/details",
                      {
                        id: id,
                        orderId: rowData.orderId,
                      }
                    )}>
                    {rowData.documentNumber}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Odbiorca</Table.HeaderCell>
              <Table.Cell dataKey="locationName">
                {(rowData: IOrderData) => {
                  return (
                    <>
                      <div>{rowData.location.name}</div>
                      <div className={styles.style}>
                        {locationFullAddressName(rowData.location?.address)}
                      </div>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Dystrybutor</Table.HeaderCell>
              <Table.Cell dataKey="customerName">
                {(rowData: IOrderData) => {
                  return <span>{rowData.customer.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Wystawił</Table.HeaderCell>
              <Table.Cell dataKey="userName">
                {(rowData: IOrderData) => {
                  return <span>{rowData.user.fullName}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={170}>
              <Table.HeaderCell>Netto</Table.HeaderCell>
              <Table.Cell>
                {(rowData: IOrderData) => {
                  return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={140} align="center" sortable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="status">
                {(rowData: IOrderData) => (
                  <BadgeStatus color={rowData.status.color}>
                    {rowData.status.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={120} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey="documentDate" />
            </Table.Column>

            <Table.Column fixed="right" width={70} align="center">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData: IOrderData) => (
                  <ActionsContainer>
                    <IconButton
                      onClick={handleDownloadFile.bind(null, rowData)}
                      icon={"download"}
                      size={24}
                    />
                    <DeleteSingleButton
                      callback={handleDeleteSelected.bind(
                        null,
                        rowData.orderId
                      )}
                      form={form}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={OrderConnection.list}
        formGet={OrderConnection.filterForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectsOrdersList_${getProjectIdFromUrl()}`}
        getFormCallback={(_form) => setForm(_form)}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "documentNumber",
            label: "Numer",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data od",
            stateKey: "dateFrom",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data do",
            stateKey: "dateTo",
          },
          {
            type: FILTER_SELECT,
            stateKey: "statusId",
            formKey: "orderStatuses",
            label: "Status",
          },
          {
            type: FILTER_SELECT,
            stateKey: "customerId",
            formKey: "customers",
            label: "Dystrybutor",
          },
          {
            type: FILTER_SELECT,
            stateKey: "locationId",
            formKey: "locations",
            label: "Odbiorca",
          },
          {
            type: FILTER_SELECT,
            stateKey: "userId",
            formKey: "users",
            label: "Wystawił",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "postCodeName",
            label: "Kod pocztowy",
          },
          {
            type: FILTER_SELECT,
            stateKey: "voivodeshipId",
            formKey: "voivodeships",
            label: "Województwo",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "cityName",
            label: "Miejscowość",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "streetName",
            label: "Ulica",
          },

          {
            type: FILTER_INPUT_TEXT,
            stateKey: "districtName",
            label: "Powiat",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "communityName",
            label: "Gmina",
          },
        ]}
      />
    </>
  );
};

export default OrdersList;
