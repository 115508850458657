import AxiosHelper from "../../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../../helpers";

import { AxiosPromise } from "axios";
import {
  IContractServiceFilterFormModel,
  IContractServiceModel,
} from "../../models/contract/contractServiceModels";

export const path = `/contracts`;
export const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}${path}`;

const ContractServiceTypeConnection = {
  getContractServiceList: (
    contractId: string,
    data: IContractServiceFilterFormModel
  ) => {
    return AxiosHelper.post(`${baseUrl}/${contractId}/services/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createContractService: (contractId: string, data: IContractServiceModel) => {
    return AxiosHelper.post(`${baseUrl}/${contractId}/services`, data, {
      headers: getAuthHeader(),
    });
  },
  updateContractService: (
    contractId: string,
    serviceId: string,
    data: IContractServiceModel
  ) => {
    return AxiosHelper.put(
      `${baseUrl}/${contractId}/services/${serviceId}`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },

  getFormAddContractService: (contractId: string) => {
    return AxiosHelper.get(`${baseUrl}/${contractId}/services/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditContractService: (contractId: string, id: string) => {
    return AxiosHelper.get(`${baseUrl}/${contractId}/services/${id}/form`, {
      headers: getAuthHeader(),
    });
  },

  delete: (contractId: string, data: Array<{ id: string }>) => {
    return AxiosHelper.batchDelete(`${baseUrl}/${contractId}/services`, {
      ids: data,
    });
  },

  getListForm: (contractId: string): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/${contractId}/services/form`);
  },

  getFilterForm: (contractId: string): AxiosPromise => {
    return AxiosHelper.get(`${baseUrl}/${contractId}/services/filter/form`);
  },
};

export default ContractServiceTypeConnection;
