import { _paginate30 } from "utils/states";
import {
  IContractEquipmentFilterFormModel,
  IContractEquipmentModel,
} from "../../models/contract/contractEquipmentModels";

export const getContractEquipmentState: IContractEquipmentFilterFormModel = {
  name: "",
  ..._paginate30,
};

export const createContractState: IContractEquipmentModel = {
  name: "",
};
