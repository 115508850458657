import React, { FunctionComponent, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Layout from "./global/layout/Layout";
import AppCore from "AppCore";
import { allRoutes } from "routes/routes";
import { FCC, IRouteEntity } from "utils/models";
import AxiosHelper, { AxiosManager } from "utils/AxiosHelper";

interface IApp {}

const App: FunctionComponent<IApp> = () => {
  const location = useLocation();

  const ProxyComponent: FCC = (props) => {
    useEffect(() => {
      return () => {
        // unload
        AxiosHelper.abort();
        AxiosManager.abort();
      };
    }, []);

    return <>{props.children}</>;
  };

  const routeMap = (route: IRouteEntity, key: number) => (
    <Route path={route.path} exact key={key}>
      {route.Component && (
        <ProxyComponent>
          <route.Component />
        </ProxyComponent>
      )}
      {/* todo: nie dziala z parametrami (from -> to) */}
      {route.redirectTo && (
        <Redirect
          to={{ pathname: route.redirectTo, state: { referrer: location } }}
        />
      )}
    </Route>
  );

  return (
    <AppCore>
      <Layout>
        <Switch>{allRoutes.map(routeMap)}</Switch>
      </Layout>
    </AppCore>
  );
};

export default App;
