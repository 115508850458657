import React, { FunctionComponent, useState } from "react";
import { Nav } from "rsuite";
import ContractServiceTypeList from "../serviceType/ContractServiceTypeList";
import ContractEquipmentList from "../equipments/ContractEquipmentList";

interface IContractDictionaryNav {}

const Notifications: FunctionComponent<IContractDictionaryNav> = () => {
  const VIEW_EQUIPMENT_LIST = "view_equipment_list";
  const VIEW_SERVICE_TYPE_LIST = "view_service_type_list";
  const [view, setView] = useState<
    "view_equipment_list" | "view_service_type_list"
  >(VIEW_EQUIPMENT_LIST);

  const handleChangeView = (
    view: "view_equipment_list" | "view_service_type_list"
  ) => {
    setView(view);
  };

  return (
    <>
      <Nav
        appearance={"default"}
        activeKey={view}
        onSelect={handleChangeView}
        style={{ marginBottom: "10px" }}>
        <Nav.Item eventKey={VIEW_EQUIPMENT_LIST}>Wyposażenie</Nav.Item>
        <Nav.Item eventKey={VIEW_SERVICE_TYPE_LIST}>Typy serwisów</Nav.Item>
      </Nav>

      {view === VIEW_EQUIPMENT_LIST && <ContractEquipmentList />}
      {view === VIEW_SERVICE_TYPE_LIST && <ContractServiceTypeList />}
    </>
  );
};

export default Notifications;
