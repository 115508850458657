import React, { FunctionComponent, useState } from "react";
import BaseTemplate, { IBaseTemplateState } from "./BaseTemplate";

interface IState extends IBaseTemplateState {}

const StorageTemplate: FunctionComponent = () => {
  const [state, setState] = useState<IState>({
    templateName: "",
    columnsData: [
      {
        label: "Dane podstawowe",
        name: "basic",
        active: true,
        options: [
          {
            name: "id",
            label: "Id magazynu",
            active: true,
          },
          {
            name: "storageUser",
            label: "Aktualny użytkownik",
            active: true,
          },
          {
            name: "available",
            label: "Dostępność",
            active: true,
          },
          {
            name: "income",
            label: "Przychód",
            active: true,
          },
          {
            name: "expenditure",
            label: "Rozchód",
            active: true,
          },
          {
            name: "shiftIncome",
            label: "Przesunięcie magazynowe +",
            active: true,
          },
          {
            name: "shiftExpenditure",
            label: "Przesunięcie magazynowe -",
            active: true,
          },
          {
            name: "productColumns",
            label: "Kolumny produktowe",
            active: true,
          },
        ],
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={4} />
    </>
  );
};

export default StorageTemplate;
