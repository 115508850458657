import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import VisitsReportConnection from "../../../../utils/connections/visitsReport";
import { useParams } from "react-router-dom";
import { Table } from "rsuite";
import ProgressBar from "../../../../global/atoms/ProgressBar";
import IconButton from "../../../../global/atoms/IconButton";
import dayjs from "dayjs";

interface ILatestReports {
  reloadReports: boolean;
  setReloadReports: any;
}

const LatestReports: FunctionComponent<ILatestReports> = ({
  reloadReports,
  setReloadReports,
}) => {
  const { id } = useParams<{ id: string }>();
  const [reports, setReports] = useState<Array<any>>([]);
  const interval = useRef<any>(null);

  const loadLatestReports = () => {
    VisitsReportConnection.getLatestReports(id).then((response) => {
      setReports(response.data);
    });
  };

  useEffect(() => {
    if (reloadReports) {
      loadLatestReports();
      setReloadReports(false);
    }
  }, [reloadReports]);

  useEffect(() => {
    loadLatestReports();
    interval.current = setInterval(() => {
      loadLatestReports();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleDownloadFile = (
    visitReportResultId: string,
    format: string,
    templateName: string
  ) => {
    const dateNow = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const fileName = `Raport_Wizyt_${templateName.replace(
      " ",
      "_"
    )}_${dateNow}.${format}`;

    VisitsReportConnection.getFile(visitReportResultId).then((response) => {
      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", fileName);
      tempLink.click();
    });
  };

  return (
    <>
      <h5>Ostatnie raporty wizyt</h5>
      <Table data={reports} autoHeight>
        <Table.Column minWidth={180} flexGrow={1} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Szablon
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="templateName" />
        </Table.Column>
        <Table.Column width={100} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Format
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="format" />
        </Table.Column>
        <Table.Column width={180} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Progres
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="progress">
            {(rowData) => <ProgressBar completed={rowData.progress} />}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={180} align="left">
          <Table.HeaderCell depth={0} width={0}>
            Data utworzenia
          </Table.HeaderCell>
          <Table.Cell width={100} depth={0} dataKey="createdDate" />
        </Table.Column>
        <Table.Column width={120} align="right">
          <Table.HeaderCell depth={0} width={0}>
            Akcje
          </Table.HeaderCell>
          <Table.Cell width={120} depth={0} dataKey="progress" align={"right"}>
            {(rowData) => (
              <>
                {rowData.progress >= 100 && rowData.filePath !== null && (
                  <IconButton
                    inline={true}
                    size={24}
                    onClick={handleDownloadFile.bind(
                      null,
                      rowData.id,
                      rowData.format,
                      rowData.templateName
                    )}
                    icon={"download"}
                  />
                )}
                {rowData.progress >= 100 && rowData.filePath === null && (
                  <span>Brak danych</span>
                )}
              </>
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
    </>
  );
};

export default LatestReports;
